import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field } from 'react-final-form';
import {
  deleteDepartmentGroup,
  fetchDepartmentList,
} from './department-actions';
import { getMealTypes } from '../catering/catering-actions';
import { fetchCredentialTypeCategoryList } from '../credential/type/credential-type-actions';
import { getCredentialTypes } from '../credential/type/credential-type-selectors';
import { getStatusForButton } from '../common/forms/FormHelper';
import { getEnabledEventSections } from '../event/event-selectors';
import { setPageSettings } from '../navigation/nav-actions';
import { showNotification } from '../notification/notification-actions';
import departmentTypeMap from '../lib/department-type-map';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import SelectAllCheckboxGroup from '../common/forms/SelectAllCheckboxGroup';
import StatusButton from '../common/StatusButton';
import DeleteButton from '../common/DeleteButton';
import SelectAllMultiSelect from '../common/forms/SelectAllMultiSelect';

const DepartmentGroupForm = ({
  departmentGroup,
  departmentGroupsUrl,
  eventId,
  handleSubmit,
  submitting,
  submitFailed,
  submitSucceeded,
  pristine,
  title,
  values,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const editing = !!departmentGroup;

  const enabledSections = useSelector(state =>
    getEnabledEventSections(state, { params }),
  );

  const departmentList = useSelector(state =>
    state.department.departmentList.getIn([eventId, values.section, 'data']),
  );

  const departmentsOptions = !departmentList
    ? []
    : Array.from(
        departmentList
          .map(dep => ({
            value: String(dep.get('id')),
            label: dep.get('name'),
          }))
          .values(),
      );

  const credentialTypes = Object.values(
    useSelector(state => getCredentialTypes(state)),
  );

  const credentialTypeOptions = credentialTypes
    .filter(ct =>
      ct
        .get('attendeePickupTypes')
        .some(apt => apt.get('attendee_type') === values.section),
    )
    .map(ct => ({ value: ct.get('id'), label: ct.get('name') }));

  const mealTypeList = useSelector(state =>
    state.catering.mealTypeList.get('data'),
  );

  const mealTypeOptions = mealTypeList
    .filter(mt =>
      mt.get('sections').some(sec => sec.get('name') === values.section),
    )
    .map(mt => ({ value: mt.get('id'), label: mt.get('name') }))
    .toArray();

  useEffect(() => {
    if (!eventId) return;
    dispatch(fetchCredentialTypeCategoryList(eventId));
    dispatch(getMealTypes(eventId));
  }, [dispatch, eventId]);

  useEffect(() => {
    if (!eventId) return;
    dispatch(fetchDepartmentList(eventId, values.section));
    dispatch(
      setPageSettings({
        url: departmentGroupsUrl,
        values: { section: values.section },
      }),
    );
  }, [departmentGroupsUrl, dispatch, eventId, values.section]);

  const renderSectionOptions = () =>
    !enabledSections
      ? null
      : enabledSections.map(section => (
          <option key={section} value={section}>
            {departmentTypeMap[section].label.plural}
          </option>
        ));

  const renderStatusButton = () => (
    <StatusButton
      buttonText={editing ? 'Save' : 'Create Group'}
      completeText="Department Group Created!"
      disabled={
        submitFailed ||
        submitting ||
        pristine ||
        !values.name ||
        !values.departments?.length ||
        !(values.credentialTypes?.length || values.mealTypes?.length)
      }
      status={getStatusForButton({
        pristine,
        submitting,
        submitFailed,
        submitSucceeded,
      })}
      type="submit"
    />
  );

  const handleDeleteClick = () => {
    dispatch(
      deleteDepartmentGroup(eventId, values.section, departmentGroup.get('id')),
    ).then(action => {
      if (!action.response.ok) {
        dispatch(
          showNotification({
            message: 'Error deleting department group',
            status: 'error',
          }),
        );
      } else {
        dispatch(
          showNotification({
            message: `${departmentGroup.get(
              'name',
            )} group successfully deleted`,
            status: 'success',
          }),
        );
        navigate(departmentGroupsUrl, { replace: true });
      }
    });
  };

  return (
    <div>
      <Paper>
        <PaperHeader backTo={departmentGroupsUrl} title={title} />
        <form className="generic-form paper__wrapper" onSubmit={handleSubmit}>
          <div className="generic-form__body ">
            <div className="input-group">
              <Field
                name="section"
                label="Section"
                component={ReduxFormsField}
                required
                disabled={editing}
              >
                <select>{renderSectionOptions()}</select>
              </Field>
              <Field
                name="name"
                component={ReduxFormsField}
                label="Department Group Name"
                placeholder="name"
                required
              >
                <input type="text" />
              </Field>
              <Field
                name="departments"
                component={ReduxFormsField}
                label="Departments"
                required
              >
                <SelectAllCheckboxGroup
                  className="checkboxes-horizontal"
                  options={departmentsOptions}
                />
              </Field>
              <Field
                name="credentialTypes"
                component={ReduxFormsField}
                label="Credential Types"
                required
              >
                <SelectAllMultiSelect
                  name="credentialTypes"
                  options={credentialTypeOptions}
                />
              </Field>
              <Field
                name="mealTypes"
                component={ReduxFormsField}
                label="Meal Types"
                required
              >
                <SelectAllMultiSelect
                  name="mealTypes"
                  options={mealTypeOptions}
                />
              </Field>
            </div>
          </div>
          {!editing && (
            <div className="generic-form__footer">
              {renderStatusButton()}{' '}
              <Link
                className="button button--plain"
                disabled={submitting}
                to={departmentGroupsUrl}
              >
                Cancel
              </Link>
            </div>
          )}
          {editing && (
            <div className="generic-form__footer">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  {renderStatusButton()}
                  <Link
                    className="button button--plain"
                    disabled={submitting}
                    to={departmentGroupsUrl}
                  >
                    Cancel
                  </Link>
                </div>
                <DeleteButton
                  onConfirmDelete={handleDeleteClick}
                  buttonText={'Delete Group'}
                  confirmText="Are you sure you want to delete this group?"
                />
              </div>
            </div>
          )}
        </form>
      </Paper>
    </div>
  );
};

DepartmentGroupForm.propTypes = {
  departmentGroup: PropTypes.object,
  departmentGroupsUrl: PropTypes.string.isRequired,
  eventId: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
};

export default DepartmentGroupForm;
