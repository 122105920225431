import { Map } from 'immutable';
import PropTypes from 'prop-types';

const CredentialCategoryName = ({ category }) => (
  <div className="contact-credentials__name--category">
    <span
      className="credential-category__dot"
      style={{ backgroundColor: category.get('color') }}
    />
    <span className="credential-category__category">
      {category.get('name')}
    </span>
  </div>
);

CredentialCategoryName.propTypes = {
  category: PropTypes.instanceOf(Map).isRequired,
};

export default CredentialCategoryName;
