import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { defaultEventSettings } from './EventSettings';
import { getCurrentPromoter } from '../promoter/promoter-selectors';
import { getCurrentFestival } from '../festival/festival-selectors';
import { fetchFestivalList } from '../festival/festival-actions';
import { submitEvent } from './event-actions';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import recursiveTrim from '../common/utils/recursive-trim';
import EventForm from './EventForm';

const EventFormAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const festival = useSelector(state => getCurrentFestival(state, { params }));

  const backTo = `/promoters/${params.promoterSlug}/festivals`;

  const initialValues = {
    festival_id: festival?.get('id'),
    settings: defaultEventSettings(),
    logo: {},
  };

  const handleSubmit = values =>
    dispatch(submitEvent(recursiveTrim(values))).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      } else {
        navigate(backTo);
        dispatch(fetchFestivalList(promoter.get('id')));
        dispatch(
          showNotification({
            message: 'Event successfully added',
            status: 'success',
          }),
        );
      }
    });

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={props => (
        <EventForm title="Add Event" backTo={backTo} {...props} />
      )}
    />
  );
};

export default EventFormAdd;
