import { useEffect } from 'react';
import { login } from './auth-redirects';

const LoginPage = () => {
  useEffect(() => {
    login();
  });

  return <div />;
};

export default LoginPage;
