import { combineReducers } from 'redux';
import Immutable from 'immutable';
import getApiReducer, { defaultState } from '../../common/utils/getApiReducer';
import { FETCH_CREDENTIAL_TYPE_CATEGORY_LIST } from './credential-type-actions';

const categoryListReducer = (state = defaultState, action) => {
  let newState = getApiReducer(FETCH_CREDENTIAL_TYPE_CATEGORY_LIST)(
    state,
    action,
  );

  if (action.type === 'ADD_CREDENTIAL_TYPE_CATEGORY_SUCCESS') {
    const categoryData = { ...action.json };
    categoryData.credentialTypes = [];
    newState = newState.set(
      'data',
      newState
        .get('data')
        .push(Immutable.fromJS(categoryData))
        .sortBy(category => category.get('name')),
    );
  }

  if (action.type === 'EDIT_CREDENTIAL_TYPE_CATEGORY_SUCCESS') {
    const categoryIndex = newState
      .get('data')
      .findIndex(category => category.get('id') === action.json.id);
    if (categoryIndex > -1) {
      const categoryPath = ['data', categoryIndex];
      const category = newState.getIn(categoryPath).toJS();
      category.name = action.json.name;
      category.lock_after_date = action.json.lock_after_date;
      category.color = action.json.color;
      newState = newState.setIn(categoryPath, Immutable.fromJS(category));
    }
  }

  if (action.type === 'ADD_CREDENTIAL_TYPE_SUCCESS') {
    const categoryIndex = newState
      .get('data')
      .findIndex(
        category =>
          parseInt(category.get('id'), 10) ===
          parseInt(action.json.category_id, 10),
      );
    if (categoryIndex > -1) {
      const typesPath = ['data', categoryIndex, 'credentialTypes'];
      newState = newState.setIn(
        typesPath,
        newState
          .getIn(typesPath)
          .push(Immutable.fromJS(action.json))
          .sortBy(type => type.get('name')),
      );
    }
  }

  if (action.type === 'EDIT_CREDENTIAL_TYPE_SUCCESS') {
    const findCredTypeCallback = credentialType =>
      credentialType.get('id') === action.json.id;
    const categoryIndex = newState
      .get('data')
      .findIndex(
        category =>
          category.get('credentialTypes').findIndex(findCredTypeCallback) > -1,
      );
    if (categoryIndex > -1) {
      const credTypeIndex = newState
        .getIn(['data', categoryIndex, 'credentialTypes'])
        .findIndex(findCredTypeCallback);
      newState = newState.setIn(
        ['data', categoryIndex, 'credentialTypes', credTypeIndex],
        Immutable.fromJS(action.json),
      );
    }
  }

  return newState;
};

export default combineReducers({
  categoryList: categoryListReducer,
});
