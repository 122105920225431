import DraftJSEditor from './DraftJSEditor';
import Renderer from './renderer';
import imgBlock from './blocks/img';

import { convertFromRaw, convertToRaw, Entity } from 'draft-js';

const defaultBlocks = {
  IMAGE: imgBlock,
};

export default DraftJSEditor;

export {
  DraftJSEditor,
  Renderer,
  defaultBlocks,
  convertFromRaw,
  convertToRaw,
  Entity,
};
