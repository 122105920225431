import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { useDispatch, useSelector } from 'react-redux';
import { getPeriodDateRange } from '../common/utils/credentials/credential-utils';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from './department-selectors';
import {
  addDepartmentCredentialType,
  removeDepartmentCredentialType,
  updateCredentialTypesDepartments,
  upsertDepartmentCredentialMaximum,
} from './department-actions';
import {
  Container,
  PeriodHeader,
  Row,
  HeaderColumn,
  Column,
} from './DepartmentCredentialList.styles';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import LoadingIndicator from '../common/LoadingIndicator';
import ExpandableRow from '../common/ExpandableRow';
import Tag from '../common/Tag';
import SwitchOn from '../common/icons/SwitchOn';
import SwitchOff from '../common/icons/SwitchOff';
import ContactCredentialDateRange from '../contact/ContactCredentialDateRange';
import EditCredentialQuantity from '../contact/EditCredentialQuantity';

const aclRules = {
  canManage: [resource.DEPARTMENT, 'edit'],
};

const DepartmentCredentialList = () => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const categoryList = useSelector(state =>
    state.credentialRequest.requestsForDepartment.get('data'),
  );

  const categoryListLoaded = useSelector(state =>
    state.credentialRequest.requestsForDepartment.get('loaded'),
  );

  const [activeMaximum, setActiveMaximum] = useState(null);
  const [editingMaximum, setEditingMaximum] = useState(0);
  const [expandedCredentialTypeId, setExpandedCredentialTypeId] =
    useState(null);

  const _getMaximumRequestable = credential => {
    let toReturn = null;

    const index = credential
      .get('requestMaximums')
      .findIndex(rm => rm.get('_pivot_credential_id') === credential.get('id'));

    if (index > -1) {
      toReturn = credential
        .get('requestMaximums')
        .get(index)
        .get('_pivot_maximum_requestable');
    }

    return toReturn;
  };

  const getPeriods = credentialType => {
    const credentialTypes = [credentialType];
    const periods = new Map();

    credentialTypes.forEach(credentialType => {
      const issueFrequency = credentialType.get('issue_frequency');
      const credentials = credentialType.get('credentials');

      credentials.forEach(credential => {
        if (issueFrequency === 'ONE_TIME') {
          const oneTimePeriods = credential.get('oneTimePeriods');

          oneTimePeriods.forEach(period => {
            const periodId = period.get('id');

            if (!periods.has(periodId)) {
              periods.set(periodId, {
                id: periodId,
                name: period.get('name'),
                startDate: period.get('start_date'),
                endDate: period.get('end_date'),
                dateRange: getPeriodDateRange(period),
                credential: credential,
                maximumRequestable: _getMaximumRequestable(credential),
              });
            }
          });
        } else {
          const period = credential.get('period');
          const periodId = period.get('id');

          if (!periods.has(periodId)) {
            periods.set(periodId, {
              id: periodId,
              name: period.get('name'),
              startDate: period.get('start_date'),
              endDate: period.get('end_date'),
              dateRange: getPeriodDateRange(period),
              credential: credential,
              maximumRequestable: _getMaximumRequestable(credential),
            });
          }
        }
      });
    });

    return Array.from(periods.values()).sort((period1, period2) =>
      period1.startDate <= period2.startDate ? -1 : 1,
    );
  };

  const handleMaximumClick = credential => {
    let editingMaximum = credential.get('maximumRequestable');

    if (
      editingMaximum === undefined ||
      editingMaximum === null ||
      editingMaximum === ''
    ) {
      editingMaximum = 0;
    }

    setActiveMaximum(credential.get('id'));
    setEditingMaximum(editingMaximum);
  };

  const handleUpdateMaximum = credential => {
    if (credential.get('maximumRequestable') !== editingMaximum) {
      const payload = {
        maximum_requestable:
          editingMaximum === undefined ? null : editingMaximum,
      };
      dispatch(
        upsertDepartmentCredentialMaximum(department, credential, payload),
      );
      setActiveMaximum(null);
    } else {
      setActiveMaximum(null);
    }
  };

  const handleChange = event => {
    if (event.target.value === '') {
      setEditingMaximum(undefined);
      return;
    }

    const amount = parseInt(event.target.value, 10);
    if (isNaN(amount)) {
      return;
    }

    setEditingMaximum(amount);
  };

  const handleAddition = () => {
    setEditingMaximum(prevEditingMaxim => prevEditingMaxim + 1);
  };

  const handleSubtraction = () => {
    setEditingMaximum(prevEditingMaxim =>
      prevEditingMaxim < 1 ? 0 : prevEditingMaxim - 1,
    );
  };

  const renderMaximumsString = credentialType => {
    let maximumDisplayString = '';

    if (credentialType.get('enabled')) {
      credentialType.get('credentials').forEach(cred => {
        const mr = _getMaximumRequestable(cred);
        const appendString = Number.isInteger(mr) ? mr.toString() : '∞';
        maximumDisplayString += appendString + ',';
      });
    }

    return (
      <div className="expandable-row__switch">
        <span>{maximumDisplayString.replace(/,\s*$/, '')}</span>
      </div>
    );
  };

  const renderRequestableSwitch = (editable, type) => {
    if (!acl.canManage) return null;

    return (
      <div className="expandable-row__switch">
        <button
          className="switch__button"
          onClick={e => {
            e.stopPropagation();
            if (type.get('enabled')) {
              dispatch(
                removeDepartmentCredentialType(department, type.get('id')),
              );
            } else {
              dispatch(
                addDepartmentCredentialType(department, type.get('id')),
              ).then(() =>
                dispatch(
                  updateCredentialTypesDepartments(department, type.get('id'), {
                    lock_date_override: false,
                  }),
                ),
              );
            }
          }}
          disabled={!editable}
        >
          {type.get('enabled') ? <SwitchOn /> : <SwitchOff />}
        </button>
        <span>Enabled For Department</span>
      </div>
    );
  };

  const renderLockDateOverrideSwitch = type => {
    if (!acl.canManage) return null;

    const credentialTypeId = type.get('id');
    const isCredentialTypeEnabled = type.get('enabled');
    if (!isCredentialTypeEnabled) return null;

    const credentialTypeSettings =
      department.getIn(['credentialTypeSettings']) &&
      department.getIn(['credentialTypeSettings']).toJS();

    const isLockDateOverriden =
      isCredentialTypeEnabled &&
      credentialTypeSettings &&
      credentialTypeSettings.some(
        item => item.credential_type_id === credentialTypeId,
      ) &&
      credentialTypeSettings.find(
        item => item.credential_type_id === credentialTypeId,
      ).lock_date_override;

    return (
      <div className="expandable-row__switch">
        <button
          className="switch__button"
          onClick={e => {
            e.stopPropagation();
            if (isCredentialTypeEnabled) {
              dispatch(
                updateCredentialTypesDepartments(department, credentialTypeId, {
                  lock_date_override: !isLockDateOverriden,
                }),
              );
            }
          }}
        >
          {isLockDateOverriden ? <SwitchOn /> : <SwitchOff />}
        </button>
        <span>Deadline Date Override</span>
      </div>
    );
  };

  const renderCredentialMaximumsByPeriod = (type, period) => {
    const credentials = type
      .get('credentials')
      .filter(
        cred =>
          cred.get('period_id') === period.id ||
          cred
            .get('oneTimePeriods')
            .some(otp => otp.get('_pivot_period_id') === period.id),
      )
      .map(cred =>
        cred.setIn(['maximumRequestable'], _getMaximumRequestable(cred)),
      )
      .map(cred => cred.setIn(['credentialType'], type));

    return (
      <Column key={`period-${period.id}`}>
        {credentials.map(credential => (
          <div
            className="contact-credentials__item contact-credentials__display"
            key={`ccd-${credential.get('id')}`}
          >
            <div className="contact-credentials__display-item contact-credentials__date">
              <ContactCredentialDateRange credential={credential} />
            </div>
            {!type.get('enabled') ? (
              <span>0 max</span>
            ) : activeMaximum === credential.get('id') ? (
              <div className="contact-credentials__edit-quantity maximum-requestable">
                <div className="contact-credentials__edit-quantity maximum-requestable">
                  <EditCredentialQuantity
                    value={editingMaximum}
                    handleChange={handleChange}
                    handleAddition={handleAddition}
                    handleSubtraction={handleSubtraction}
                    hidePlusSign={false}
                  />
                  <div className="button-group">
                    <button
                      className="button-green"
                      type="button"
                      onClick={() => handleUpdateMaximum(credential)}
                    >
                      <Icon icon="CheckCircle" />
                    </button>
                    <button
                      type="button"
                      className="button-red"
                      onClick={() => {
                        setActiveMaximum(null);
                      }}
                    >
                      <Icon icon="CloseCircle" />
                    </button>
                  </div>
                </div>
              </div>
            ) : credential.get('maximumRequestable') === null ? (
              <span onClick={() => handleMaximumClick(credential)}>
                No limit
              </span>
            ) : (
              <span onClick={() => handleMaximumClick(credential)}>
                {credential.get('maximumRequestable')} max
              </span>
            )}
          </div>
        ))}
      </Column>
    );
  };

  const renderCategoryList = () => {
    const credentialTypes = [];
    categoryList.forEach(category =>
      category.get('credentialTypes').forEach(credentialType => {
        credentialTypes.push(credentialType);
      }),
    );

    return (
      <div>
        {credentialTypes.map(type => {
          const periods = type.get('enabled') ? getPeriods(type) : [];
          return (
            <ExpandableRow
              key={type.get('id')}
              columns={[
                <span className="expandable-row__title">
                  {type.get('name')}
                </span>,
                <Tag
                  backgroundColor={type.getIn(['category', 'color'])}
                  size="medium"
                >
                  {type.getIn(['category', 'name'])}
                </Tag>,
                renderMaximumsString(type),
                <span style={{ opacity: 0.7 }}>
                  {!!event.get('groups_enabled') &&
                  type
                    .get('departmentGroupSettings')
                    .find(
                      s =>
                        s.get('credential_type_id') === type.get('id') &&
                        s.get('department_id') === department.get('id'),
                    ) &&
                  Boolean(
                    type
                      .get('departmentGroupSettings')
                      .find(
                        s =>
                          s.get('credential_type_id') === type.get('id') &&
                          s.get('department_id') === department.get('id'),
                      )
                      .get('enabled'),
                  ) !== Boolean(type.get('enabled')) ? (
                    <Icon
                      icon="ErrorTriangle"
                      style={{ float: 'right', marginRight: '10px' }}
                    />
                  ) : null}
                </span>,
                renderRequestableSwitch(true, type),
                renderLockDateOverrideSwitch(type),
              ]}
              isExpandable={type.get('enabled')}
              isExpanded={type.get('id') === expandedCredentialTypeId}
              onToggleExpansion={() =>
                type.get('id') === expandedCredentialTypeId
                  ? setExpandedCredentialTypeId(null)
                  : setExpandedCredentialTypeId(type.get('id'))
              }
              caretSpace
            >
              <div className="contact-details-section">
                <Container>
                  <Row columns={periods.length}>
                    <HeaderColumn className="contact-details-section__header">
                      <Icon icon="Ticket" />
                      Maximum Requestable
                    </HeaderColumn>
                    {periods.map(period => (
                      <PeriodHeader key={`period-${period.id}`}>
                        <div>{period.name}</div>
                        <div>{period.dateRange}</div>
                      </PeriodHeader>
                    ))}
                  </Row>
                  <Row
                    key={`credential-type-${type.get('id')}`}
                    columns={periods.length}
                  >
                    <HeaderColumn
                      className="contact-details-section__header"
                      style={{ minHeight: '90px' }}
                    />
                    {periods.map(period =>
                      renderCredentialMaximumsByPeriod(type, period),
                    )}
                  </Row>
                </Container>
              </div>
            </ExpandableRow>
          );
        })}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <Paper>
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>No credentials have been added yet.</p>
        </div>
      </Paper>
    );
  };

  if (!categoryListLoaded) {
    return <LoadingIndicator />;
  }

  const hasCredentials = categoryList.some(
    category => !category.get('credentialTypes').isEmpty(),
  );

  return (
    <div id="DepartmentCredentialList">
      <Paper>
        <PaperHeader title="Credentials" />
      </Paper>
      {hasCredentials ? renderCategoryList(categoryList) : renderEmpty()}
    </div>
  );
};

export default DepartmentCredentialList;
