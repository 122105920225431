import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { v1 as keygen } from 'uuid';
import { get } from 'lodash';
import { Tooltip } from 'react-tooltip';
import { resource, suffix, useACL } from '../common/ACL';
import { trimUriSegments } from '../common/uri';
import { showNotification } from '../notification/notification-actions';
import { getCredentialName } from '../credential-request/credential-request-helpers';
import {
  getCategories,
  getCredentialTypes,
  getCredentials,
} from '../credential/type/credential-type-selectors';
import {
  approveCredentials,
  changeCredential,
  rejectCredentials,
  updateCredentialQuantities,
} from '../credential-request/credential-request-actions';
import sortContacts from '../contact/sort-contacts';
import Immutable, { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import Render from '../common/Render';
import Icon from '../common/icons/Icon';
import QuantityField from '../common/forms/QuantityField';
import StatusButton from '../common/StatusButton';
import CredentialCategoryName from '../credential-request/CredentialCategoryName';
import RequestStatusIndicator from '../contact/RequestStatusIndicator';
import ApproveAllConfirmationBox from './ApproveAllConfirmationBox';
import DeclineAllConfirmationBox from './DeclineAllConfirmationBox';

const aclRules = {
  canViewDepartment: [
    resource.EVENT,
    { action: 'view-department', suffix: suffix.DEPARTMENT_TYPE },
  ],
};
const DepartmentApprovals = ({
  event,
  departmentData,
  periods,
  pendingOnly = false,
}) => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const location = useLocation();

  const categories = useSelector(getCategories);
  const credentialTypes = useSelector(getCredentialTypes);
  const credentials = useSelector(getCredentials);

  const [showBreakdown, setShowBreakdown] = useState(false);
  const [credentialExpanded, setCredentialExpanded] = useState({});

  const [approving, setApproving] = useState(false);
  const [approvingRequest, setApprovingRequest] = useState({});
  const [approveAll, setApproveAll] = useState({
    open: false,
    department: null,
    contact: null,
    period: null,
    requests: null,
  });

  const [declining, setDeclining] = useState(false);
  const [decliningRequest, setDecliningRequest] = useState({});
  const [declineAll, setDeclineAll] = useState({
    open: false,
    department: null,
    contact: null,
    period: null,
    requests: null,
  });

  const approveDeclineAllPeriod =
    periods?.size === 1
      ? {
          id: periods.first().get('id'),
          name: periods.first().get('name'),
        }
      : null;

  const [editingQuantity, setEditingQuantity] = useState(false);
  const [unsavedQuantity, setUnsavedQuantity] = useState(null);
  const [savingQuantity, setSavingQuantity] = useState(false);

  const [editingPass, setEditingPass] = useState(false);
  const [changePassValue, setChangePassValue] = useState('');
  const [savingChangePass, setSavingChangePass] = useState(false);

  const requestsByContact = useMemo(() => {
    const credentialRequests = departmentData.get('credentialRequests');

    const groupLevelRequests = {
      id: 'group',
      first_name: 'Group Level',
      last_name: 'Requests',
      requests: credentialRequests
        .filter(
          request =>
            !request.get('requestedFor') ||
            request.get('requestedFor').size === 0,
        )
        .toJS(),
    };

    const uniqueIdsFromRequests = [
      ...new Set(
        credentialRequests
          .map(request => request.get('requested_for'))
          .filter(request => request),
      ),
    ];

    const requestsByUniqueId = uniqueIdsFromRequests.map(id => ({
      ...credentialRequests
        .find(request => request.get('requested_for') === id)
        .get('requestedFor')
        .toJS(),
      requests: credentialRequests
        .filter(request => request.get('requested_for') === id)
        .toJS(),
    }));

    const contacts = [groupLevelRequests, ...requestsByUniqueId];

    return sortContacts(Immutable.fromJS(contacts)).sort(
      // Put group level requests at the top
      (a, b) => {
        if (a.get('id') === 'group') {
          return -1;
        } else if (b.get('id') === 'group') {
          return 1;
        }
        return 0;
      },
    );
  }, [departmentData]);

  const hasQuantityAfterFilter = requestsByContact.size > 0;

  const staffCount = useMemo(() => {
    const contacts = new Set();
    departmentData.get('credentialRequests').forEach(request => {
      if (request.get('requested_for')) {
        contacts.add(request.get('requested_for'));
      }
    });

    return contacts.size;
  }, [departmentData]);

  const departmentHasPulseTags = useMemo(() => {
    let pulseOrders = departmentData.get('pulseOrders');
    if (!pulseOrders) {
      return false;
    }

    return pulseOrders.some(
      pulseOrder =>
        pulseOrder.get('pulseTags') && pulseOrder.get('pulseTags').size > 0,
    );
  }, [departmentData]);

  const areAllApproved = useMemo(
    () =>
      departmentData
        .get('credentialRequests')
        .reduce(
          (acc, req) =>
            acc &&
            req.get('quantity_approved') >= 0 &&
            req.get('quantity_pending') === 0 &&
            req.get('quantity_rejected') === 0,
          true,
        ),
    [departmentData],
  );

  const areAllRejected = useMemo(
    () =>
      departmentData
        .get('credentialRequests')
        .reduce(
          (acc, req) =>
            acc &&
            req.get('quantity_rejected') >= 0 &&
            req.get('quantity_pending') === 0 &&
            req.get('quantity_approved') === 0,
          true,
        ),
    [departmentData],
  );

  const isSubmitting =
    savingChangePass || savingQuantity || approving || declining;

  const isRequestInPeriod = (request, periodId) => {
    const credential = credentials[request.get('credential_id')];
    return (
      String(credential.get('period_id')) === String(periodId) ||
      credential
        .get('oneTimePeriods')
        .find(period => String(period.get('id')) === String(periodId))
    );
  };

  const areAllApprovedForContact = contactWithRequests =>
    contactWithRequests
      .get('requests')
      .reduce(
        (acc, req) =>
          acc &&
          req.get('quantity_approved') >= 0 &&
          req.get('quantity_pending') === 0 &&
          req.get('quantity_rejected') === 0,
        true,
      );

  const areAllRejectedForContact = contactWithRequests =>
    contactWithRequests
      .get('requests')
      .reduce(
        (acc, req) =>
          acc &&
          req.get('quantity_rejected') >= 0 &&
          req.get('quantity_pending') === 0 &&
          req.get('quantity_approved') === 0,
        true,
      );

  const areAllApprovedForContactPeriod = (contactWithRequests, periodId) =>
    contactWithRequests
      .get('requests')
      .reduce(
        (acc, req) =>
          isRequestInPeriod(req, periodId)
            ? acc &&
              req.get('quantity_approved') >= 0 &&
              req.get('quantity_pending') === 0 &&
              req.get('quantity_rejected') === 0
            : acc,
        true,
      );

  const areAllRejectedForContactPeriod = (contactWithRequests, periodId) =>
    contactWithRequests
      .get('requests')
      .reduce(
        (acc, req) =>
          isRequestInPeriod(req, periodId)
            ? acc &&
              req.get('quantity_rejected') >= 0 &&
              req.get('quantity_pending') === 0 &&
              req.get('quantity_approved') === 0
            : acc,
        true,
      );

  const areAllApprovedForRequest = requestId => {
    const request = getRequest(requestId);

    return (
      request.get('quantity_approved') >= 0 &&
      request.get('quantity_pending') === 0 &&
      request.get('quantity_rejected') === 0
    );
  };

  const areAllRejectedForRequest = requestId => {
    const request = getRequest(requestId);

    return (
      request.get('quantity_rejected') > 0 &&
      request.get('quantity_pending') === 0 &&
      request.get('quantity_approved') === 0
    );
  };

  const isCredentialExpanded = (requestId, periodId) =>
    !!credentialExpanded[getRequestPeriodStateKey(requestId, periodId)];

  const displayRejectedNotifications = action => {
    const rejected = get(action, 'json.rejected');
    if (rejected?.length) {
      const message = rejected
        .map(({ error }) =>
          error.isBoom ? get(error, 'output.payload.message') : null,
        )
        .reduce((acc, error) => (error ? `${acc}${error}.\n` : acc), '');
      dispatch(showNotification({ message, duration: 60000, status: 'error' }));
    }
  };

  const getDepartmentCredentialRequests = () => {
    let credentialRequests = departmentData.get('credentialRequests');

    if (pendingOnly)
      credentialRequests = credentialRequests.filter(
        request => request.get('quantity_pending') > 0,
      );

    return credentialRequests;
  };

  const getContactCredentialRequests = (contactId, periodId = null) => {
    let credentialRequests = requestsByContact
      .find(contact => String(contact.get('id')) === String(contactId))
      ?.get('requests');

    if (periodId || pendingOnly)
      credentialRequests = credentialRequests.filter(
        request =>
          !(
            (pendingOnly && request.get('quantity_pending') <= 0) ||
            (periodId && !isRequestInPeriod(request, periodId))
          ),
      );

    return credentialRequests;
  };

  const approveAllDepartmentCredentials = () => {
    setApproving(true);
    setApproveAll({
      open: true,
      department: {
        name: departmentData.get('name'),
      },
      period: approveDeclineAllPeriod,
      requests: getDepartmentCredentialRequests(),
    });
  };

  const declineAllDepartmentCredentials = () => {
    setDeclining(true);
    setDeclineAll({
      open: true,
      department: {
        name: departmentData.get('name'),
      },
      period: approveDeclineAllPeriod,
      requests: getDepartmentCredentialRequests(),
    });
  };

  const approveAllContactCredentials = contact => {
    const contactId = String(contact.get('id'));

    setApproving(true);
    setApproveAll({
      open: true,
      department: {
        name: departmentData.get('name'),
      },
      contact: {
        id: contactId,
        name: getContactName(contact),
      },
      period: approveDeclineAllPeriod,
      requests: getContactCredentialRequests(contactId),
    });
  };

  const declineAllContactCredentials = contact => {
    const contactId = String(contact.get('id'));

    setDeclining(true);
    setDeclineAll({
      open: true,
      department: {
        name: departmentData.get('name'),
      },
      contact: {
        id: contactId,
        name: getContactName(contact),
      },
      period: approveDeclineAllPeriod,
      requests: getContactCredentialRequests(contactId),
    });
  };

  const approveAllContactPeriodCredentials = (contact, period) => {
    const contactId = String(contact.get('id'));
    const periodId = String(period.get('id'));

    setApproving(true);
    setApproveAll({
      open: true,
      department: {
        name: departmentData.get('name'),
      },
      contact: {
        id: contactId,
        name: getContactName(contact),
      },
      period: {
        id: periodId,
        name: period.get('name'),
      },
      requests: getContactCredentialRequests(contactId, periodId),
    });
  };

  const declineAllContactPeriodCredentials = (contact, period) => {
    const contactId = String(contact.get('id'));
    const periodId = String(period.get('id'));

    setDeclining(true);
    setDeclineAll({
      open: true,
      department: {
        name: departmentData.get('name'),
      },
      contact: {
        id: contactId,
        name: getContactName(contact),
      },
      period: {
        id: periodId,
        name: period.get('name'),
      },
      requests: getContactCredentialRequests(contactId, periodId),
    });
  };

  const approveCredentialsForRequest = requestId => {
    const request = getRequest(requestId);

    setApprovingRequest(prevApprovingRequest => {
      const newApprovingRequest = { ...prevApprovingRequest };
      newApprovingRequest[requestId] = true;
      return newApprovingRequest;
    });

    dispatch(
      approveCredentials(event.get('id'), departmentData.get('type'), request),
    ).then(action => {
      displayRejectedNotifications(action);
      setApprovingRequest(prevApprovingRequest => {
        const newApprovingRequest = { ...prevApprovingRequest };
        delete newApprovingRequest[requestId];
        return newApprovingRequest;
      });
    });
  };

  const declineCredentialsForRequest = requestId => {
    const request = getRequest(requestId);

    setDecliningRequest(prevRejectingRequest => {
      const newRejectingRequest = { ...prevRejectingRequest };
      newRejectingRequest[requestId] = true;
      return newRejectingRequest;
    });

    dispatch(
      rejectCredentials(event.get('id'), departmentData.get('type'), request),
    ).then(action => {
      displayRejectedNotifications(action);
      setDecliningRequest(prevRejectingRequest => {
        const newRejectingRequest = { ...prevRejectingRequest };
        delete newRejectingRequest[requestId];
        return newRejectingRequest;
      });
    });
  };

  const handleApproveAll = (approveAll, approvePendingOnly) => {
    let requests = approveAll.requests;

    if (!pendingOnly && approvePendingOnly)
      requests = requests.filter(
        request => request.get('quantity_pending') > 0,
      );

    if (requests.isEmpty()) {
      setApproving(false);
    } else {
      dispatch(
        approveCredentials(
          event.get('id'),
          departmentData.get('type'),
          requests,
        ),
      ).then(action => {
        displayRejectedNotifications(action);
        setApproving(false);
      });
    }
  };

  const handleDeclineAll = (declineAll, declinePendingOnly) => {
    let requests = declineAll.requests;

    if (!pendingOnly && declinePendingOnly)
      requests = requests.filter(
        request => request.get('quantity_pending') > 0,
      );

    if (requests.isEmpty()) {
      setDeclining(false);
    } else {
      dispatch(
        rejectCredentials(
          event.get('id'),
          departmentData.get('type'),
          requests,
        ),
      ).then(action => {
        displayRejectedNotifications(action);
        setDeclining(false);
      });
    }
  };

  const saveQuantity = (requestId, quantity) => {
    const request = getRequest(requestId);

    let approved = request.get('quantity_approved');
    let pending = request.get('quantity_pending');
    let rejected = request.get('quantity_rejected');

    const hasRejected = rejected > 0;
    const previousCount = approved + pending;

    if (!hasRejected && (quantity === null || quantity === previousCount)) {
      return toggleEditingQuantity(requestId);
    }

    setSavingQuantity(requestId);

    quantity = quantity ?? 0;
    rejected = 0;

    if (quantity > previousCount) {
      pending = pending + quantity - previousCount;
    }

    if (quantity < previousCount) {
      const pendingDiff = Math.min(previousCount - quantity, pending);
      const approvedDiff = Math.min(
        previousCount - quantity - pendingDiff,
        approved,
      );

      pending = pending - pendingDiff;
      approved = approved - approvedDiff;
    }

    return dispatch(
      updateCredentialQuantities(requestId, {
        quantity_approved: approved,
        quantity_rejected: rejected,
        quantity_pending: pending,
      }),
    ).then(() => {
      setSavingQuantity(false);
      setEditingQuantity(null);
    });
  };

  const saveChangePassValue = (requestId, credentialId) => {
    setSavingChangePass(requestId);
    dispatch(changeCredential(requestId, credentialId)).then(() => {
      setSavingChangePass(false);
      setChangePassValue('');
    });
  };

  const toggleEditingQuantity = requestId => {
    if (editingQuantity === requestId) {
      setEditingQuantity(false);
    } else {
      setEditingQuantity(requestId);
      setUnsavedQuantity(null);
    }
  };

  const toggleEditingPass = requestId => {
    if (editingPass === requestId) {
      setEditingPass(false);
      setChangePassValue('');
    } else {
      setEditingPass(requestId);
    }
  };

  const toggleContactCredentialExpansion = (requestId, periodId) => {
    const stateKey = getRequestPeriodStateKey(requestId, periodId);

    setCredentialExpanded(prevCredentialExpanded => {
      const newCredentialExpanded = { ...prevCredentialExpanded };
      newCredentialExpanded[stateKey] = !prevCredentialExpanded[stateKey];
      return newCredentialExpanded;
    });

    if (credentialExpanded[stateKey]) {
      setEditingPass(false);
      setEditingQuantity(false);
      setChangePassValue('');
    }
  };

  const closeApproveAllConfirmation = useCallback(() => {
    setApproving(false);
    setApproveAll({
      open: false,
      department: null,
      contact: null,
      period: null,
      requests: null,
    });
  }, []);

  const closeDeclineAllConfirmation = useCallback(() => {
    setDeclining(false);
    setDeclineAll({
      open: false,
      department: null,
      contact: null,
      period: null,
      requests: null,
    });
  }, []);

  const getContactName = contactWithRequests =>
    `${contactWithRequests.get('first_name') || 'Guest'} ${
      contactWithRequests.get('last_name') || ''
    }`;

  const getRequest = requestId => {
    const request = departmentData
      .get('credentialRequests')
      .find(req => req.get('id') === requestId);

    if (!request) {
      throw new Error(`Could not find request: ${requestId}`);
    }

    return request;
  };

  const getRequestPeriodStateKey = (contactId, credentialId) =>
    `${contactId}-${credentialId}`;

  const getRequestPulseTagsCount = request => {
    const pulseCredentialId = request.getIn([
      'credential',
      'pulse_credential_id',
    ]);

    let pulseOrders = departmentData.get('pulseOrders');
    if (!pulseOrders) {
      return 0;
    }

    pulseOrders = pulseOrders.filter(pulseOrder =>
      request.get('requested_for')
        ? pulseOrder.get('contact_id') === request.get('requested_for')
        : pulseOrder.get('department_id') === request.get('department_id') &&
          !pulseOrder.get('contact_id'),
    );

    let pulseTags = pulseOrders.flatMap(pulseOrder =>
      pulseOrder.get('pulseTags'),
    );

    if (!pulseTags) {
      return 0;
    }

    return pulseTags.filter(
      pulseTag => pulseTag.get('pulse_credential_id') === pulseCredentialId,
    ).size;
  };

  const getTotalsByCategory = (requests, periodId, key) => {
    const totalsByCategory = [];
    requests.forEach(request => {
      if (isRequestInPeriod(request, periodId)) {
        const credId = request.get('credential_id');
        const credential = credentials[credId];
        const credTypeId = credential.get('credential_type_id');
        const credentialType = credentialTypes[credTypeId];
        let typeGroup = totalsByCategory.find(
          total =>
            total.category.get('id') === credentialType.get('category_id'),
        );
        if (!typeGroup) {
          typeGroup = {
            category: categories[credentialType.get('category_id')],
            totals: [],
          };
          totalsByCategory.push(typeGroup);
        }
        let total = typeGroup.totals.find(
          totalFromGroup => totalFromGroup.credentialId === credId,
        );
        if (!total) {
          total = {
            key: key,
            requestIds: [],
            credentialId: credId,
            name: getCredentialName(credential),
            pending: 0,
            approved: 0,
            rejected: 0,
            issued: 0,
          };
          typeGroup.totals.push(total);
        }
        total.key = `${key}-${request.get('id')}`;
        total.requestIds.push(request.get('id'));
        total.pending += request.get('quantity_pending');
        total.approved += request.get('quantity_approved');
        total.rejected += request.get('quantity_rejected');
        total.issued += getRequestPulseTagsCount(request);
      }
    });
    return totalsByCategory;
  };

  const getCredentialRequestTotalsForPeriod = periodId =>
    getTotalsByCategory(
      departmentData.get('credentialRequests'),
      periodId,
      departmentData.get('id'),
    );

  const getCredentialRequestTotalsForContactAndPeriod = (
    contactWithRequests,
    periodId,
  ) => {
    const filteredRequests = contactWithRequests.get('requests').filter(req => {
      const credential = credentials[req.get('credential_id')];
      return (
        credential.get('period_id') === periodId ||
        credential
          .get('oneTimePeriods')
          .find(oneTimePeriod => oneTimePeriod.get('id') === periodId)
      );
    });

    return getTotalsByCategory(
      filteredRequests,
      periodId,
      contactWithRequests.get('id'),
    );
  };

  const getCredentialTypesAvailableToChangeTo = requestId => {
    const deptCredTypes = departmentData.get('credentialTypes');
    const request = getRequest(requestId);
    const categoryId = credentials[request.get('credential_id')].getIn([
      'credentialType',
      'category_id',
    ]);
    const requestedFor = request.get('requested_for');

    // Filter out cred types that are disabled for this section
    return deptCredTypes
      .filter(
        credType =>
          credentialTypes[credType.get('id')]
            .get('attendeePickupTypes')
            .find(
              type => type.get('attendee_type') === departmentData.get('type'),
            ) &&
          // Only allow credentials from the same category as just requested
          credType.get('category_id') === categoryId &&
          // Restrict to group level cred types if request is group level
          (requestedFor || credType.get('group_level_available')),
      )
      .map(credType => credentialTypes[credType.get('id')]);
  };

  const renderQuantityField = requestId => {
    const request = getRequest(requestId);
    const pulseTagsCount = getRequestPulseTagsCount(request);

    return (
      <div onClick={event => event.stopPropagation()}>
        <QuantityField
          value={
            unsavedQuantity !== null
              ? unsavedQuantity
              : request.get('quantity_pending') +
                request.get('quantity_approved')
          }
          onChange={setUnsavedQuantity}
          min={pulseTagsCount}
        />
      </div>
    );
  };

  const renderCredentialDropdown = requestId => {
    const request = getRequest(requestId);

    const credentials = [];

    getCredentialTypesAvailableToChangeTo(requestId)
      .toJS()
      .forEach(credentialType => {
        credentialType.credentials.forEach(credential => {
          // Exclude the credential that it is already set to
          if (credential.id !== request.get('credential_id')) {
            credentials.push({
              id: credential.id,
              categoryId: credentialType.category_id,
              name: getCredentialName(credential),
            });
          }
        });
      });

    return (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <select
          onClick={event => event.stopPropagation()}
          onChange={event => setChangePassValue(event.target.value)}
          value={changePassValue}
        >
          <option value="" className="select-field-null-value">
            Select Credential
          </option>
          {credentials.map(credential => (
            <option key={credential.id} value={credential.id}>
              {credential.name}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderEditQuantityForm = requestId => {
    if (editingQuantity !== requestId) return null;

    return (
      <div className="credential-approval__change-qty-action">
        {renderQuantityField(requestId)}
        <div className="credential-approval__change-qty-action--buttons">
          <StatusButton
            buttonText="Save"
            className="credential--expanded__button"
            disabled={isSubmitting}
            onClick={event => {
              event.stopPropagation();
              saveQuantity(requestId, unsavedQuantity);
            }}
            status={savingQuantity === requestId ? 'loading' : 'default'}
          />
          <button
            className="credential--expanded__button"
            onClick={event => {
              event.stopPropagation();
              toggleEditingQuantity(requestId);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const renderChangePassForm = requestId => {
    if (editingPass !== requestId) {
      return null;
    }

    return (
      <div className="credential-approval__change-pass-action">
        {renderCredentialDropdown(requestId)}
        <div className="credential-approval__change-pass-action--buttons">
          <StatusButton
            disabled={changePassValue === '' || isSubmitting}
            buttonText="Save Change Pass"
            className="credential--expanded__button"
            status={savingChangePass === requestId ? 'loading' : 'default'}
            onClick={event => {
              event.stopPropagation();
              saveChangePassValue(requestId, changePassValue);
            }}
          />
          <button
            className="credential--expanded__button"
            onClick={event => {
              event.stopPropagation();
              toggleEditingPass(requestId);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const renderExpandedCredential = requestId => {
    if (editingQuantity === requestId) return renderEditQuantityForm(requestId);

    if (editingPass === requestId) return renderChangePassForm(requestId);

    const request = getRequest(requestId);
    const pulseTagsCount = getRequestPulseTagsCount(request);

    return (
      <div className="credential-approval__change-qty-action">
        {editingQuantity !== requestId && (
          <div className="credential-approval__change-qty-action--buttons">
            {!areAllApprovedForRequest(requestId) && (
              <StatusButton
                disabled={isSubmitting}
                type="button"
                className="credential--expanded__button"
                onClick={event => {
                  event.stopPropagation();
                  approveCredentialsForRequest(requestId);
                }}
                buttonText="Approve"
                status={approvingRequest?.[requestId] ? 'loading' : 'default'}
              />
            )}
            {!pulseTagsCount && !areAllRejectedForRequest(requestId) && (
              <StatusButton
                disabled={isSubmitting}
                type="button"
                className="credential--expanded__button"
                onClick={event => {
                  event.stopPropagation();
                  declineCredentialsForRequest(requestId);
                }}
                buttonText="Decline"
                status={decliningRequest[requestId] ? 'loading' : 'default'}
              />
            )}
            <button
              className="credential--expanded__button"
              onClick={event => {
                event.stopPropagation();
                toggleEditingQuantity(requestId);
              }}
            >
              Change Qty
            </button>
            {!pulseTagsCount && (
              <button
                className="credential--expanded__button"
                onClick={event => {
                  event.stopPropagation();
                  toggleEditingPass(requestId);
                }}
              >
                Change Pass
              </button>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderCredentialTotal = total => (
    <div key={total.key} className="credential-approvals__credential-request">
      {!!total.issued && (
        <div
          className="credential-tooltip"
          data-tooltip-id={`credential-tooltip-${total.key}`}
        >
          <Icon icon="IssuedCredential" />
          <Tooltip
            id={`credential-tooltip-${total.key}`}
            content={`Issued: ${total.issued}`}
          />
        </div>
      )}
      <div className="credential-name">{total.name}</div>
      <div className="credential-status">
        <Render if={total.pending > 0}>
          <RequestStatusIndicator
            status="pending"
            leftContent={total.pending}
          />
        </Render>
        <Render if={total.approved > 0}>
          <RequestStatusIndicator
            status="approved"
            leftContent={total.approved}
          />
        </Render>
        <Render if={total.rejected > 0}>
          <RequestStatusIndicator
            status="rejected"
            leftContent={total.rejected}
          />
        </Render>
      </div>
    </div>
  );

  const renderCredentialTypeTotals = total => (
    <div
      key={total.category.get('id')}
      className="credential-approvals__category-group"
    >
      <div className="credential-approvals__credential-type">
        <CredentialCategoryName category={total.category} />
      </div>
      {total.totals.map(renderCredentialTotal)}
    </div>
  );

  const renderCredentialTypeTotalsForContact = (
    contactWithRequests,
    periodId,
    total,
  ) => {
    const contactId = contactWithRequests.get('id');
    return (
      <div key={total.category.get('id')}>
        <div className="credential-approvals__credential-type">
          <CredentialCategoryName category={total.category} />
        </div>
        {total.totals.map(mappedTotal => {
          if (mappedTotal.requestIds.length !== 1) {
            throw new Error(
              'There should only be one request per contact credential',
            );
          }
          const requestId = mappedTotal.requestIds[0];

          return (
            <div
              key={mappedTotal.credentialId}
              onClick={() =>
                toggleContactCredentialExpansion(requestId, periodId)
              }
              className={
                isCredentialExpanded(contactId, mappedTotal.credentialId)
                  ? 'credential--expanded'
                  : null
              }
            >
              {renderCredentialTotal(mappedTotal)}
              <Render if={isCredentialExpanded(requestId, periodId)}>
                {renderExpandedCredential(requestId)}
              </Render>
            </div>
          );
        })}
      </div>
    );
  };

  const renderPeriod = period => {
    const totals = getCredentialRequestTotalsForPeriod(period.get('id'));
    return (
      <div key={period.get('id')} className="credential-approvals__period">
        <div className="credential-approvals__period-card">
          <div className="credential-approvals__period-name">
            {period.get('name')}
          </div>
          <div className="credential-approvals__requests">
            <Render if={totals.length > 0}>
              {totals.map(renderCredentialTypeTotals)}
            </Render>
            <Render if={totals.length === 0}>
              <div className="credential-approvals__requests--zerocontent">
                No credentials requested for this period
              </div>
            </Render>
          </div>
        </div>
      </div>
    );
  };

  const renderPeriods = () => (
    <div className="credential-approvals__periods">
      {periods.map(renderPeriod)}
    </div>
  );

  const renderPeriodForContact = (contactWithRequests, period) => {
    const contactId = String(contactWithRequests.get('id'));
    const periodId = String(period.get('id'));

    const totals = getCredentialRequestTotalsForContactAndPeriod(
      contactWithRequests,
      period.get('id'),
    );

    const totalIssued = totals
      .reduce((acc, total) => acc.concat(total.totals), [])
      .reduce((totalIssued, total) => totalIssued + total.issued, 0);

    const areAllApproved = areAllApprovedForContactPeriod(
      contactWithRequests,
      period.get('id'),
    );
    const areAllRejected = areAllRejectedForContactPeriod(
      contactWithRequests,
      period.get('id'),
    );

    return (
      <div key={period.get('id')} className="credential-approvals__period">
        <div
          key={period.get('id')}
          className="credential-approvals__period-card"
        >
          <div className="credential-approvals__period-name">
            {period.get('name')}
          </div>
          <div className="credential-approvals__requests">
            <Render if={totals.length > 0}>
              {totals.map(total =>
                renderCredentialTypeTotalsForContact(
                  contactWithRequests,
                  period.get('id'),
                  total,
                ),
              )}
            </Render>
            <Render if={totals.length === 0}>
              <div className="credential-approvals__requests--zerocontent">
                No credentials requested for this period
              </div>
            </Render>
          </div>
          <Render
            if={
              totals.length > 0 &&
              (!areAllApproved || (!totalIssued && !areAllRejected))
            }
          >
            <div className="credential-approvals__period-card-actions">
              <Render if={!areAllApproved}>
                <StatusButton
                  disabled={isSubmitting}
                  type="button"
                  className="text-link"
                  buttonText="Approve All"
                  completeText="Approved"
                  status={
                    approveAll.contact?.id === contactId &&
                    approveAll.period?.id === periodId
                      ? 'loading'
                      : 'default'
                  }
                  onClick={() =>
                    approveAllContactPeriodCredentials(
                      contactWithRequests,
                      period,
                    )
                  }
                />
              </Render>
              <Render if={!totalIssued && !areAllRejected}>
                <StatusButton
                  disabled={isSubmitting}
                  type="button"
                  className="text-link"
                  buttonText="Decline All"
                  completeText="Declined"
                  status={
                    declineAll.contact?.id === contactId &&
                    declineAll.period?.id === periodId
                      ? 'loading'
                      : 'default'
                  }
                  onClick={() =>
                    declineAllContactPeriodCredentials(
                      contactWithRequests,
                      period,
                    )
                  }
                />
              </Render>
            </div>
          </Render>
        </div>
      </div>
    );
  };

  const renderPeriodsForContact = contactWithRequests => (
    <div className="credential-approvals__periods">
      {periods.map(period =>
        renderPeriodForContact(contactWithRequests, period),
      )}
    </div>
  );

  const renderRequestsForContact = (contactWithRequests, index) => {
    const contactId = String(contactWithRequests.get('id'));
    let credentialRequestsNotes;

    if (
      contactId === 'group' &&
      departmentData.get('credentialRequestsNotes')
    ) {
      credentialRequestsNotes = departmentData.getIn([
        'credentialRequestsNotes',
        'notes',
      ]);
    }
    const key = keygen();

    const hasPulseTags = contactWithRequests
      .get('requests')
      .some(request => getRequestPulseTagsCount(request) > 0);

    return (
      <div key={key} className="credential-approvals__department-staff">
        <div className="credential-approvals__credentials">
          <div className="credential-approvals__column1">
            <div className="credential-approvals__contact-name">
              {index === 0 && (
                <strong>{getContactName(contactWithRequests)}</strong>
              )}
              {index > 0 && (
                <>
                  {acl.canViewDepartment && (
                    <Link
                      className="text-link"
                      to={
                        trimUriSegments(location.pathname, 3) +
                        `/${departmentData.get('type')}/${departmentData.get(
                          'slug',
                        )}/contacts/list?contact=${contactWithRequests.get(
                          'id',
                        )}`
                      }
                    >
                      {`${index}.`}
                      {getContactName(contactWithRequests)}
                    </Link>
                  )}
                  {!acl.canViewDepartment && (
                    <strong>
                      {`${index}.`}
                      {getContactName(contactWithRequests)}
                    </strong>
                  )}

                  {!!contactWithRequests.get('is_group') && (
                    <div data-tooltip-id={`group-contact-tooltip-${contactId}`}>
                      <Icon
                        icon="Clipboard"
                        className="group-contact-clipboard"
                      />
                      <Tooltip
                        id={`group-contact-tooltip-${contactId}`}
                        content="Group Contact"
                        style={{ marginLeft: '5px' }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="credential-approvals__contact-relationship">
              {contactWithRequests.get('relationship')}
            </div>
            <Render if={!contactWithRequests.get('requests').isEmpty()}>
              <div className="credential-approvals__contact-actions">
                <Render if={!areAllApprovedForContact(contactWithRequests)}>
                  <StatusButton
                    disabled={isSubmitting}
                    type="button"
                    className="text-link"
                    buttonText="Approve All"
                    completeText="Approved"
                    status={
                      approveAll.contact?.id === contactId && !approveAll.period
                        ? 'loading'
                        : 'default'
                    }
                    onClick={() =>
                      approveAllContactCredentials(contactWithRequests)
                    }
                  />
                </Render>
                <Render
                  if={
                    !hasPulseTags &&
                    !areAllRejectedForContact(contactWithRequests)
                  }
                >
                  <StatusButton
                    disabled={isSubmitting}
                    type="button"
                    className="text-link"
                    buttonText="Decline All"
                    completeText="Declined"
                    status={
                      declineAll.contact?.id === contactId && !declineAll.period
                        ? 'loading'
                        : 'default'
                    }
                    onClick={() =>
                      declineAllContactCredentials(contactWithRequests)
                    }
                  />
                </Render>
              </div>
            </Render>
          </div>
          {renderPeriodsForContact(contactWithRequests)}
        </div>

        {contactId === 'group' && credentialRequestsNotes && (
          <div className="credential-approvals__notes">
            <div className="title">Notes</div>
            {credentialRequestsNotes}
          </div>
        )}
      </div>
    );
  };

  const renderBreakdown = () =>
    showBreakdown ? (
      <div className="credential-approvals__department-breakdown">
        {requestsByContact.map(renderRequestsForContact)}
      </div>
    ) : null;

  return (
    <div className="credential-approvals__department-row">
      <div className="credential-approvals__department">
        <div className="credential-approvals__column1">
          <div className="credential-approvals__department-name">
            {acl.canViewDepartment && (
              <Link
                className="text-link"
                to={
                  trimUriSegments(location.pathname, 3) +
                  `/${departmentData.get('type')}/${departmentData.get('slug')}`
                }
              >
                {departmentData.get('name')}
              </Link>
            )}
            {!acl.canViewDepartment && (
              <strong>{departmentData.get('name')}</strong>
            )}
          </div>
          <div className="credential-approvals__staff-count">
            Staff: {staffCount}
          </div>
          <Render if={!areAllApproved}>
            <StatusButton
              disabled={isSubmitting}
              type="button"
              className="text-link"
              buttonText="Approve All"
              completeText="Approved"
              status={
                approveAll.department && !approveAll.contact
                  ? 'loading'
                  : 'default'
              }
              onClick={approveAllDepartmentCredentials}
            />
          </Render>
          <Render if={!departmentHasPulseTags && !areAllRejected}>
            <StatusButton
              disabled={isSubmitting}
              type="button"
              className="text-link"
              buttonText="Decline All"
              completeText="Declined"
              status={
                declineAll.department && !declineAll.contact
                  ? 'loading'
                  : 'default'
              }
              onClick={declineAllDepartmentCredentials}
            />
          </Render>
          {hasQuantityAfterFilter && (
            <button
              className="text-link"
              type="button"
              onClick={() => setShowBreakdown(!showBreakdown)}
            >
              View Breakdown
            </button>
          )}
        </div>
        {renderPeriods()}
      </div>
      {renderBreakdown()}
      <ApproveAllConfirmationBox
        approving={approving}
        approveAll={approveAll}
        pendingOnly={pendingOnly}
        closeModal={closeApproveAllConfirmation}
        handleApproveAll={handleApproveAll}
      />
      <DeclineAllConfirmationBox
        declining={declining}
        declineAll={declineAll}
        pendingOnly={pendingOnly}
        closeModal={closeDeclineAllConfirmation}
        handleDeclineAll={handleDeclineAll}
      />
    </div>
  );
};

DepartmentApprovals.propTypes = {
  event: PropTypes.instanceOf(Map).isRequired,
  departmentData: PropTypes.instanceOf(Map).isRequired,
  periods: PropTypes.instanceOf(List).isRequired,
  pendingOnly: PropTypes.bool,
};

export default DepartmentApprovals;
