import { DISMISS_ERROR } from './error-actions';

const errorReducers = (state = null, action) => {
  if (action.type === 'UNEXPECTED_ERROR') {
    return (
      action.json?.message ??
      action.error?.message ??
      'An unexpected error occurred'
    );
  } else if (action.type === DISMISS_ERROR) {
    return null;
  }
  return state;
};

export default errorReducers;
