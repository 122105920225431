import { Map } from 'immutable';
import {
  setDropdownResults,
  setNextPageGroup,
  searching,
} from '../search-actions';
import storage from 'store2';

export const streamPump = async ({ apiUrl, eventId, dispatch, caller }) => {
  try {
    let resultMap = new Map({
      contacts: new Map(),
      departments: new Map(),
    });

    const jwtToken = storage.session('idToken');
    const headers = new Headers({
      authorization: jwtToken,
    });

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers,
    });

    const reader = response.body.getReader();

    dispatch(searching(true));

    new ReadableStream({
      start(controller) {
        let remainder = '';
        pump();

        function pump() {
          reader.read().then(({ done, value }) => {
            const decodedString = new TextDecoder('utf-8').decode(value);

            if (decodedString) {
              (remainder += decodedString.substring(
                0,
                decodedString.lastIndexOf('\n'),
              ))
                .split('\n')
                .forEach(result_1 => {
                  const resultObj = JSON.parse(result_1);
                  if (resultObj.table_name === 'person') {
                    let personObj = new Map();
                    personObj = personObj.set(resultObj.id, resultObj);
                    resultMap = resultMap.mergeIn(['contacts'], personObj);
                  } else if (resultObj.table_name === 'department') {
                    let departmentObj = new Map();
                    departmentObj = departmentObj.set(resultObj.id, resultObj);
                    resultMap = resultMap.mergeIn(
                      ['departments'],
                      departmentObj,
                    );
                  }
                });

              remainder = decodedString.substring(
                decodedString.lastIndexOf('\n') + 1,
              );
            }

            if (done) {
              if (caller === 'ResultsContainer') {
                dispatch(setNextPageGroup(eventId, resultMap));
              } else {
                dispatch(setDropdownResults(eventId, resultMap));
              }
              dispatch(searching(false));
              controller.close();
              return;
            }

            controller.enqueue(value);
            pump();
          });
        }
      },
    });
    return response;
  } catch (err) {
    console.error(err);
  }
};
