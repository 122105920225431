import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { fetchLoggedInUser } from '../auth/auth-actions';
import { logout } from '../auth/auth-redirects';
import { dismissError } from '../error/error-actions';
import storage from 'store2';
import Icon from '../common/icons/Icon';
import FullPageMessage from '../common/FullPageMessage';
import NotificationBanner from '../notification/NotificationBanner';
import OnsiteHeader from './OnsiteHeader';
import OnsiteTopbar from './OnsiteTopbar';

const OnsiteLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const error = useSelector(state => state.error);
  const loggedInUser = useSelector(state => state.auth.loggedInUser);

  const [isAccountNavOpen, setAccountNavOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchLoggedInUser());
  }, [dispatch]);

  useEffect(() => {
    if (loggedInUser?.get('error')) {
      storage.session.clearAll();
      navigate('/login');
    }
  }, [loggedInUser, navigate]);

  useEffect(() => {
    setAccountNavOpen(false);
  }, [location]);

  const isAccessRevoked = () => {
    const user = loggedInUser.get('data');
    return !!user.get('deleted_at');
  };

  const isAccessDisabled = () => {
    const user = loggedInUser.get('data');
    if (user.get('roles').indexOf('strata_admin') > -1) {
      return false;
    }

    let revoked = true;

    if (
      user.get('roles').indexOf('department_user') > -1 &&
      user.get('departments').size > 0
    ) {
      // allows department_user(s)
      revoked = false;
    } else if (
      user.get('roles').indexOf('promoter_admin') > -1 &&
      user.get('promoterRoles').size > 0
    ) {
      // allows promoter users (they don't really exist except as promoter admin)
      revoked = false;
    } else if (user.get('eventRoles').size > 0) {
      // allows event users
      revoked = false;
    }

    return revoked;
  };

  if (!loggedInUser.get('loaded')) {
    return null;
  }

  if (isAccessRevoked()) {
    return (
      <FullPageMessage
        icon="Sad"
        message="Your access to Strata has been revoked."
      >
        <button className="button" onClick={logout}>
          Logout
        </button>
      </FullPageMessage>
    );
  }

  if (isAccessDisabled()) {
    return (
      <FullPageMessage
        icon="Sad"
        message="This account has been disabled. Please contact your system administrator."
      >
        <button className="button" onClick={logout}>
          Logout
        </button>
      </FullPageMessage>
    );
  }

  const toggleAccountNav = () => {
    setAccountNavOpen(!isAccountNavOpen);
  };

  const renderGlobalError = () => {
    if (!error) {
      return null;
    }

    return (
      <div className="global-error-message">
        <Icon icon="ErrorTriangle" />
        <span>{error}</span>
        <button onClick={() => dispatch(dismissError())}>
          <Icon icon="Close" />
        </button>
      </div>
    );
  };

  return (
    <div className="onsite-layout">
      <div className="onsite-layout__body">
        <NotificationBanner />
        <OnsiteTopbar
          isAccountNavOpen={isAccountNavOpen}
          toggleAccountNav={toggleAccountNav}
        />
        {renderGlobalError()}
        <div className="onsite-layout__children">
          <OnsiteHeader />
          <Outlet />
        </div>
        {isAccountNavOpen ? (
          <button className="underlay" onClick={toggleAccountNav} />
        ) : null}
      </div>
    </div>
  );
};

export default OnsiteLayout;
