import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { logout } from '../auth/auth-redirects';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import SearchBar from '../search/SearchBar/SearchBarContainer';

const Topbar = ({ isAccountNavOpen, toggleAccountNav, toggleMobileNav }) => {
  const location = useLocation();
  const params = useParams();

  const isMobile = useSelector(state => state.browser.lessThan.desktop);
  const loggedInUser = useSelector(state =>
    state.auth.loggedInUser?.get('data'),
  );
  const nav = useSelector(state => state.nav);

  const renderBreadcrumbs = () => {
    const pageTitle = nav?.title ?? '';

    if (isMobile) {
      return (
        <div className="topbar__breadcrumbs">
          <button onClick={toggleMobileNav}>
            <Icon icon="Hamburger" />
          </button>
          <span>{pageTitle}</span>
        </div>
      );
    }
    return (
      <div className="topbar__breadcrumbs">
        <span>{pageTitle}</span>
      </div>
    );
  };

  const renderAccountProfile = () => {
    const firstName = loggedInUser?.get('first_name');
    const lastName = loggedInUser?.get('last_name');
    const email = loggedInUser?.get('email');
    return (
      <div className="topbar__account">
        <button className="topbar__account--image" onClick={toggleAccountNav}>
          <div>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</div>
        </button>
        {isAccountNavOpen ? (
          <div className="topbar__account--dropdown">
            <div className="topbar__account--account">
              <div className="topbar__account--welcome">Logged in as</div>
              <div className="topbar__account--name">
                {`${firstName} ${lastName}`}
              </div>
              <div className="topbar__account--email">{email}</div>
            </div>
            <nav className="topbar__account--nav">
              <button onClick={logout}>
                <Icon icon="Logout" />
                Logout
              </button>
            </nav>
          </div>
        ) : null}
      </div>
    );
  };

  const showSearchBar = () => {
    const regex = RegExp('/events/', 'i');
    return regex.test(location.pathname);
  };

  return (
    <div className="topbar">
      {renderBreadcrumbs()}
      <div className="topbar__divider">
        {showSearchBar() && <SearchBar params={params} />}
        {renderAccountProfile()}
      </div>
    </div>
  );
};

Topbar.propTypes = {
  isAccountNavOpen: PropTypes.bool.isRequired,
  toggleAccountNav: PropTypes.func.isRequired,
  toggleMobileNav: PropTypes.func.isRequired,
};

export default Topbar;
