const ResourceContants = {
  CONTACT: 'contact',
  DEPARTMENT: 'department',
  EVENT: 'event',
  FESTIVAL: 'festival',
  PROMOTER: 'promoter',
  USER: 'user',
  STRATA_ADMIN: 'strata_admin',
};

export default ResourceContants;
