import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Map } from 'immutable';
import { resource, useACL } from '../common/ACL';
import { approveApplication, denyApplication } from '../intern/intern-actions';
import { getDepartmentSettings } from '../department/department-selectors';
import {
  getCredentialName,
  getSortableDateForCredential,
  hasAvailableCredentials,
} from '../credential-request/credential-request-helpers';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import ContactNameEdit from './ContactNameEdit';
import ContactInfo from './ContactInfo';
import ExpandableRow from '../common/ExpandableRow';
import CredentialAssignmentForm from './CredentialAssignmentForm';
import CredentialTag from './CredentialTag';
import StatusButton from '../common/StatusButton';

const aclRules = {
  canEditContact: [resource.DEPARTMENT, 'edit'],
  canRequestCredential: [resource.DEPARTMENT, 'request-credential'],
};

const ContactDetails = ({ backTo, department, contact }) => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const deptSettings = useSelector(state =>
    getDepartmentSettings(state, {
      params,
      departmentType: department.get('type'),
    }),
  );

  const [credentialFormExpanded, setCredentialFormExpanded] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [approvingIntern, setApprovingIntern] = useState(false);
  const [denyingIntern, setDenyingIntern] = useState(false);

  const isIntern = department.get('type') === 'intern';
  const isReadOnly = department.get('status') === 'rejected';
  const credentialsEnabled = deptSettings?.get('credentials');
  const relationships = deptSettings?.get('relationships');

  const approveIntern = () => {
    setApprovingIntern(true);
    dispatch(
      approveApplication(department.get('event_id'), department.get('id')),
    ).then(() => {
      setApprovingIntern(false);
      navigate(backTo);
    });
  };

  const denyIntern = () => {
    setDenyingIntern(true);
    dispatch(
      denyApplication(department.get('event_id'), department.get('id')),
    ).then(() => {
      setDenyingIntern(false);
      navigate(backTo);
    });
  };

  const renderCloseButton = () => (
    <Link to={backTo} className="contact-details-close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    </Link>
  );

  const renderEditNameButton = () => {
    if (!acl.canEditContact || isReadOnly) {
      return null;
    }

    return (
      <button className="text-link" onClick={() => setEditingName(true)}>
        <Icon icon="Pencil" />
      </button>
    );
  };

  const renderProfilePic = () => (
    <div className="profile-pic">
      <Icon icon="Person" />
    </div>
  );

  const renderName = () => {
    if (editingName) {
      return (
        <ContactNameEdit
          contact={contact}
          onCancel={() => setEditingName(false)}
          onSuccess={() => setEditingName(false)}
        />
      );
    }

    return (
      <h1>
        {`${contact.get('first_name')} ${contact.get('last_name')}`}
        {renderEditNameButton()}
      </h1>
    );
  };

  const renderInternApplicationLink = () =>
    isIntern ? (
      <div className="app-link">
        <Link
          to={`/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/approvals/intern/${params.departmentSlug}/application`}
        >
          View Application
        </Link>
      </div>
    ) : null;

  const renderAddCredentialsForm = () =>
    isReadOnly ||
    !acl.canRequestCredential ||
    !credentialsEnabled ||
    !hasAvailableCredentials(department) ? null : (
      <div style={{ margin: '20px' }}>
        <ExpandableRow
          columns={[
            <span className="assign-creds-dropdown">
              <Icon icon="Ticket" />
              Request Credentials
            </span>,
          ]}
          onToggleExpansion={() =>
            setCredentialFormExpanded(!credentialFormExpanded)
          }
          isExpandable
          isExpanded={credentialFormExpanded}
        >
          <CredentialAssignmentForm
            department={department}
            contact={contact}
            requireApproval
          />
        </ExpandableRow>
      </div>
    );

  const renderCredentials = (type, label) => {
    const creds = [];
    contact.get('credentialRequests').forEach(credReq => {
      if (credReq.get(`quantity_${type}`)) {
        let credPeriod = credReq.getIn(['credential', 'period', 'name']);
        if (!credPeriod) {
          credPeriod = credReq
            .getIn(['credential', 'oneTimePeriods'])
            .map(period => period.get('name'))
            .join(', ');
        }
        creds.push({
          requestId: credReq.get('id'),
          name: getCredentialName(credReq.get('credential')),
          color: credReq.getIn([
            'credential',
            'credentialType',
            'category',
            'color',
          ]),
          quantity: credReq.get(`quantity_${type}`),
          period: credPeriod,
          sortField: getSortableDateForCredential(credReq.get('credential')),
        });
      }
    });

    if (!creds.length) {
      return null;
    }

    return (
      <div className={`staff-details__${type}-credentials`}>
        <h2>
          <Icon icon="Placeholder" />
          {label}
        </h2>
        <div>
          {creds
            .sort((a, b) => a.sortField - b.sortField)
            .map(cred => (
              <CredentialTag
                key={cred.requestId}
                credential={cred}
                type={type}
                canDelete={acl.canRequestCredential && !isReadOnly}
              />
            ))}
        </div>
      </div>
    );
  };

  const renderPendingCredentials = () => {
    return renderCredentials('pending', 'Requested Credentials');
  };

  const renderApprovedCredentials = () => {
    return renderCredentials('approved', 'Approved Credentials');
  };

  const renderInternApproval = () =>
    isIntern ? (
      <div className="contact-details__footer">
        <StatusButton
          type="button"
          onClick={approveIntern}
          buttonText="Approve Intern"
          disabled={approvingIntern || denyingIntern}
          status={approvingIntern ? 'loading' : 'default'}
        />
        <button
          type="button"
          className="button button--plain"
          disabled={approvingIntern || denyingIntern}
          onClick={denyIntern}
        >
          {denyingIntern && (
            <div className="deny">
              <div className="spinner">
                <div className="bounce1" />
                <div className="bounce2" />
                <div className="bounce3" />
              </div>
            </div>
          )}
          {!denyingIntern && 'Deny Intern'}
        </button>
      </div>
    ) : null;

  return (
    <div className="staff-details">
      {renderCloseButton()}
      <div className="staff-details-header">
        {renderProfilePic()}
        <div>
          {renderName()}
          <ContactInfo
            contact={contact}
            relationships={relationships}
            readOnly={!acl.canEditContact || isReadOnly}
          />
          {renderInternApplicationLink()}
        </div>
      </div>
      {renderAddCredentialsForm()}
      {renderPendingCredentials()}
      {renderApprovedCredentials()}
      {renderInternApproval()}
    </div>
  );
};

ContactDetails.propTypes = {
  backTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  department: PropTypes.instanceOf(Map).isRequired,
  contact: PropTypes.instanceOf(Map).isRequired,
};

export default ContactDetails;
