import { Field } from 'react-final-form';
import { Map } from 'immutable';
import { composeValidators, isRequired } from 'revalidate';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import isValidEmail from '../../validate/is-valid-email';
import PropTypes from 'prop-types';
import ReduxFormsField from '../../forms/ReduxFormsField';

const draftRenderer = new Renderer(defaultBlocks);

const ApplicationInfoSection = ({ className, section }) => (
  <section className={className}>
    <header>
      <div className={`${className}--title`}>
        Person Filling Out This Application
      </div>
    </header>

    <article>
      <div className={`${className}--desc`}>
        <div
          className="draftjs-output"
          dangerouslySetInnerHTML={{
            __html: draftRenderer.convertRawToHTML(
              section.get('content').toJS(),
            ),
          }}
        />
      </div>

      <Field
        name="person_info_name"
        component={ReduxFormsField}
        label="Name of Person Filling Out This Application"
        placeholder="Enter Name"
        required
        validate={isRequired('Name')}
      >
        <input type="text" />
      </Field>

      <Field
        name="person_info_email"
        component={ReduxFormsField}
        label="Email of Person Filling Out This Application"
        placeholder="Enter Email"
        required
        validate={composeValidators(isRequired, isValidEmail)('Email')}
      >
        <input type="text" />
      </Field>
    </article>
  </section>
);

ApplicationInfoSection.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
};

export default ApplicationInfoSection;
