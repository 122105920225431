import { useLayoutEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import StatusButton from '../common/StatusButton';

const ApprovalFilters = ({
  periods,
  filters,
  filtering = false,
  onSubmitFilter,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const departmentList = useSelector(state =>
    state.credentialRequest.requestsForEvent.get('data'),
  );

  const categoryList = useSelector(state =>
    state.credentialType.categoryList.get('data'),
  );

  const filtersApplied = Object.values(filters).some(
    filter => (filter ?? '') !== '',
  );

  useLayoutEffect(() => {
    if (!filtering) setIsExpanded(false);
  }, [filtering]);

  const renderOptions = options =>
    options.map(option => (
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    ));

  const renderDepartmentOptions = () => {
    const departments = [];

    departments.push({
      label: 'All Departments',
      value: '',
    });

    if (departmentList.size) {
      departments.push(
        ...departmentList
          .map(dept => ({ label: dept.get('name'), value: dept.get('id') }))
          .toJS(),
      );
    }

    return renderOptions(departments);
  };

  const renderPeriodOptions = () => {
    const options = [{ label: 'All Periods', value: '' }];

    options.push(
      ...periods.map(period => ({
        label: period.get('name'),
        value: period.get('id'),
      })),
    );

    return renderOptions(options);
  };

  const renderCategoryOptions = () => {
    const options = [{ label: 'All Categories', value: '' }];

    options.push(
      ...categoryList
        .map(category => ({
          label: category.get('name'),
          value: category.get('id'),
        }))
        .toJS(),
    );

    return renderOptions(options);
  };

  const renderCredentialTypeOptions = values => {
    const selectedCategory = values.category;
    const credentialTypes = [];

    if (selectedCategory) {
      const category = categoryList.find(
        cat => cat.get('id') === parseInt(selectedCategory, 10),
      );

      credentialTypes.push({
        label: `All ${category.get('name')}`,
        value: '',
      });

      credentialTypes.push(
        ...category
          .get('credentialTypes')
          .map(type => ({
            label: type.get('name'),
            value: type.get('id'),
          }))
          .toJS(),
      );
    } else {
      credentialTypes.push({
        label: 'All Credential Types',
        value: '',
      });

      categoryList.forEach(category => {
        credentialTypes.push(
          ...category
            .get('credentialTypes')
            .map(type => ({
              label: type.get('name'),
              value: type.get('id'),
            }))
            .toJS(),
        );
      });
    }

    return renderOptions(credentialTypes);
  };

  const renderStatusOptions = () => {
    const options = [
      { value: '', label: 'All' },
      { value: 'approved', label: 'Approved' },
      { value: 'pending', label: 'Pending' },
      { value: 'declined', label: 'Declined' },
    ];

    return renderOptions(options);
  };

  return (
    <div className="approval-filters">
      <div className="approval-filters__header">
        <div className="approval-filters__header--title">Filters</div>
        {filtersApplied ? (
          <div className="approval-filters__header--filter-on">
            Filters Applied
          </div>
        ) : null}
        <div className="approval-filters__header--toggle">
          <button
            className="approval-filters__toggle-switch"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <Icon icon="CloseSquare" />
            ) : (
              <Icon icon="OpenSquare" />
            )}
          </button>
        </div>
      </div>

      <div
        className={`approval-filters__body${isExpanded ? '' : '--collapsed'}`}
      >
        <Form
          onSubmit={onSubmitFilter}
          initialValues={filters}
          render={({
            handleSubmit,
            modifiedSinceLastSubmit,
            pristine,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="department"
                label="Department"
                component={ReduxFormsField}
                required
              >
                <select>{renderDepartmentOptions()}</select>
              </Field>
              <Field
                name="period"
                label="Period"
                component={ReduxFormsField}
                required
              >
                <select>{renderPeriodOptions()}</select>
              </Field>
              <Field
                name="category"
                label="Credential Category"
                component={ReduxFormsField}
                required
              >
                <select>{renderCategoryOptions()}</select>
              </Field>
              <Field
                name="credentialType"
                label="Credential Type"
                component={ReduxFormsField}
                required
              >
                <select>{renderCredentialTypeOptions(values)}</select>
              </Field>
              <Field
                name="status"
                label="Status"
                component={ReduxFormsField}
                required
              >
                <select>{renderStatusOptions()}</select>
              </Field>
              <div className="approval-filters__body--actions">
                <StatusButton
                  type="submit"
                  buttonText="Apply"
                  completeText="Applied"
                  disabled={pristine || filtering}
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

ApprovalFilters.propTypes = {
  periods: PropTypes.instanceOf(List).isRequired,
  filters: PropTypes.shape({
    category: PropTypes.string,
    credentialType: PropTypes.string,
    department: PropTypes.string,
    status: PropTypes.string,
    period: PropTypes.string,
  }),
  filtering: PropTypes.bool,
  onSubmitFilter: PropTypes.func.isRequired,
};

export default ApprovalFilters;
