import React from 'react';
import PropTypes from 'prop-types';
import Icon from './icons/Icon';
import Paper from './paper/Paper';

const Confirmation = ({
  warningText,
  actionText,
  onConfirm,
  onDismiss,
  disableButtons,
}) => {
  return (
    <Paper className="confirmation">
      <div className="confirmation__icon">
        <Icon icon="Warning" />
      </div>
      <div className="confirmation__warning-text">{warningText}</div>
      <div className="confirmation__confirmation-text">
        Are you sure you want to <strong>{actionText}</strong>?
      </div>
      <button
        className="button button--red"
        onClick={onConfirm}
        disabled={disableButtons}
      >
        Yes
      </button>
      <button
        className="button button--red button--outline"
        onClick={onDismiss}
        disabled={disableButtons}
      >
        No
      </button>
    </Paper>
  );
};
Confirmation.propTypes = {
  warningText: PropTypes.string,
  actionText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  disableButtons: PropTypes.bool,
};

export default Confirmation;
