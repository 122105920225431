import { DebounceInput } from 'react-debounce-input';
import styled from 'styled-components';

export const Input = styled(DebounceInput)`
  width: 100%;
  height: 100%;
  border: none;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 18px;
  &:focus {
    outline: none;
  }
`;
