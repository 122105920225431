import React from 'react';
import PropTypes from 'prop-types';
import keys from 'lodash/keys';
import CheckboxDisplay from './CheckboxDisplay';
import DateDisplay from './DateDisplay';
import FileDisplay from './FileDisplay';
import MoneyDisplay from './MoneyDisplay';
import TextareaDisplay from './TextareaDisplay';
import TextDisplay from './TextDisplay';
import TimeDisplay from './TimeDisplay';

const COMPONENTS = {
  checkbox: CheckboxDisplay,
  date: DateDisplay,
  file: FileDisplay,
  money: MoneyDisplay,
  number: TextDisplay,
  select: TextDisplay,
  text: TextDisplay,
  textarea: TextareaDisplay,
  time: TimeDisplay,
};

const DisplayFields = ({ label, type, value }) =>
  React.createElement(COMPONENTS[type], { label, value });

DisplayFields.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(keys(COMPONENTS)),
  value: PropTypes.any,
};

export default DisplayFields;
