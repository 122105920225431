import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { v1 as keygen } from 'uuid';
import { streamPump } from '../common/streamPump';
import {
  getSearchDepartmentSlugs,
  getSearchResults,
} from '../search-selectors';
import { Container, Footer, Header } from './SearchResultsContainer.styles';
import PaginationButtons from './SearchResultsComponents/PaginationButtons';
import Result from './SearchResultsComponents/Result';
import config from '../../config';
import Paper, { PaperHeader } from '../../common/paper/Paper';
import Icon from '../../common/icons/Icon';

const SearchResultsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const departmentSlugs = useSelector(getSearchDepartmentSlugs);
  const results = useSelector(getSearchResults);

  const search = searchParams.get('search');

  const [currentPage, setCurrentPage] = useState(1);
  const [currentSet, setCurrentSet] = useState(0);

  const eventId = params.eventId;

  const pagesPerSet = 4;
  const maxServerResponseSize = 100;
  const pageSize = maxServerResponseSize / pagesPerSet;
  const pageRange = currentPage * pageSize;
  const serverResponseRange = currentSet * maxServerResponseSize;
  const pageStart = pageRange - pageSize - serverResponseRange;
  const pageEnd = pageRange - serverResponseRange;
  const page = results.slice(pageStart, pageEnd);

  const navigateToResult = ({ table_name, id, department, type }) => {
    navigate(
      {
        pathname: `/promoters/${params.promoterSlug}/festivals/${
          params.festivalSlug
        }/events/${params.eventSlug}/${type}/${departmentSlugs.get(
          department,
        )}/contacts/list`,
        search:
          table_name === 'person' ? `?contact=${encodeURIComponent(id)}` : null,
      },
      { replace: true },
    );
  };

  const fetchPreviousSetFromServer = () => {
    const previousSet = currentSet - 1;
    const apiUrl = `${config.apiUrl}search/${eventId}/${search}/${previousSet}`;
    const caller = 'ResultsContainer';

    streamPump({ apiUrl, eventId, dispatch, caller }).then(response => {
      if (response.ok) {
        setCurrentSet(previousSet);
        setCurrentPage(previousSet * pagesPerSet + 1);
      }
    });
  };

  const fetchNextSetFromServer = () => {
    const nextSet = currentSet + 1;
    const apiUrl = `${config.apiUrl}search/${eventId}/${search}/${nextSet}`;
    const caller = 'ResultsContainer';

    streamPump({ apiUrl, eventId, dispatch, caller }).then(response => {
      if (response.ok) {
        setCurrentSet(nextSet);
        setCurrentPage(nextSet * pagesPerSet + 1);
      }
    });
  };

  return (
    <Container>
      <Header>
        <Paper>
          <PaperHeader title={`Search for ${search}`} />
          {results.length === 0 ? (
            <div className="generic-list--empty">
              <Icon icon="Sad" />
              <p>Your search has no results</p>
            </div>
          ) : null}
        </Paper>
      </Header>
      {page.map(data => (
        <Result
          key={keygen()}
          data={data}
          navigateToResult={navigateToResult}
        />
      ))}
      <Footer>
        <PaginationButtons
          results={results}
          pageSize={pageSize}
          goToPage={setCurrentPage}
          currentPage={currentPage}
          currentSet={currentSet}
          maxServerResponseSize={maxServerResponseSize}
          fetchNextSetFromServer={fetchNextSetFromServer}
          fetchPreviousSetFromServer={fetchPreviousSetFromServer}
        />
      </Footer>
    </Container>
  );
};

export default SearchResultsContainer;
