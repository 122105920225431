import React from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from 'react-router-dom';

import { resource } from './common/ACL';
import { ACL404 } from './common/ACL404';

import RequireAuth from './auth/RequireAuth';
import TitleLoader from './title';
import eventRoutes from './eventRoutes';
import onsiteRoutes from './onsiteRoutes';

import AdminForm from './admin/AdminForm';
import AdminList from './admin/AdminList';
import AdvanceApplicationPublicPage from './advance-application/AdvanceApplicationPublicPage';
import FestivalFormAdd from './festival/FestivalFormAdd';
import FestivalFormEdit from './festival/FestivalFormEdit';
import FestivalList from './festival/FestivalList';
import FestivalLoader from './festival/FestivalLoader';
import InternApplicationPublicPage from './intern/InternApplicationPublicPage';
import GroupCredentialAssignmentForm from './group-credential/GroupCredentialAssignmentForm';
import Layout from './navigation/Layout';
import LoginPage from './auth/LoginPage';
import MediaApplicationPublicPage from './media/MediaApplicationPublicPage';
import NotFound from './common/NotFound';
import PostLoginPage from './auth/PostLoginPage';
import PromoterAdminForm from './promoter/PromoterAdminForm';
import PromoterAdminList from './promoter/PromoterAdminList';
import PromoterFormContainer from './promoter/PromoterFormContainer';
import PromoterList from './promoter/PromoterList';
import PromoterLoader from './promoter/PromoterLoader';
import TableContainer from './reports/Table/TableContainer';

const routes = createRoutesFromElements(
  <React.Fragment>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/post-login" element={<PostLoginPage />} />
    <Route
      path="/advance/:applicationToken"
      element={<AdvanceApplicationPublicPage />}
    />
    <Route
      path="/advance/:applicationToken/preview"
      element={<AdvanceApplicationPublicPage isPreview />}
    />
    <Route
      path="/advance/:applicationToken/:departmentAdvanceLinkToken"
      element={<AdvanceApplicationPublicPage />}
    />
    <Route
      path="/intern/:applicationToken"
      element={<InternApplicationPublicPage />}
    />
    <Route
      path="/intern/:applicationToken/preview"
      element={<InternApplicationPublicPage isPreview />}
    />
    <Route
      path="/media/:applicationToken"
      element={<MediaApplicationPublicPage />}
    />
    <Route
      path="/media/:applicationToken/preview"
      element={<MediaApplicationPublicPage isPreview />}
    />
    <Route
      path="/group-credentials/:token"
      element={<GroupCredentialAssignmentForm />}
    />
    <Route element={<RequireAuth />}>
      {onsiteRoutes}
      <Route
        path="/report/:resource/:resourceId/:title"
        element={<TableContainer />}
      />
      <Route element={<TitleLoader />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="promoters/~list" replace />} />
          <Route path="superadmins" handle={{ title: 'Strata Admin' }}>
            <Route
              index
              element={
                <ACL404
                  key="superadmins"
                  action={[resource.STRATA_ADMIN, 'view']}
                >
                  <AdminList />
                </ACL404>
              }
            />
            <Route
              path="~add"
              element={
                <ACL404
                  key="superadmins-add"
                  action={[resource.STRATA_ADMIN, 'create']}
                >
                  <AdminForm />
                </ACL404>
              }
            />
          </Route>
          <Route
            path="promoters"
            element={<PromoterLoader />}
            handle={{ title: 'Promoters' }}
          >
            <Route index element={<Navigate to="~list" replace />} />
            <Route path="~list" element={<PromoterList />} />
            <Route
              path="~add"
              element={
                <ACL404
                  key="promoters-add"
                  action={[resource.PROMOTER, 'edit']}
                >
                  <PromoterFormContainer />
                </ACL404>
              }
            />
            <Route path=":promoterSlug" element={<FestivalLoader />}>
              <Route index element={<Navigate to="festivals" />} />
              <Route
                path="edit"
                element={
                  <ACL404
                    key="promoter-edit"
                    action={[resource.PROMOTER, 'edit']}
                  >
                    <PromoterFormContainer />
                  </ACL404>
                }
              />
              <Route
                path="admins"
                element={
                  <ACL404
                    key="promoter-admins"
                    action={[resource.PROMOTER, 'view-promoter-admins']}
                  >
                    <PromoterAdminList />
                  </ACL404>
                }
              />
              <Route
                path="admins/~add"
                element={
                  <ACL404
                    key="promoter-admins-add"
                    action={[resource.PROMOTER, 'create-promoter-admin']}
                  >
                    <PromoterAdminForm />
                  </ACL404>
                }
              />
              <Route path="festivals" handle={{ title: 'Festivals' }}>
                <Route index element={<FestivalList />} />
                <Route
                  path="~add"
                  element={
                    <ACL404
                      key="festivals-add"
                      action={[resource.PROMOTER, 'edit']}
                    >
                      <FestivalFormAdd />
                    </ACL404>
                  }
                />
                <Route path=":festivalSlug">
                  <Route
                    path="edit"
                    element={
                      <ACL404
                        key="festival-edit"
                        action={[resource.PROMOTER, 'edit']}
                      >
                        <FestivalFormEdit />
                      </ACL404>
                    }
                  />
                  {eventRoutes}
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
    <Route path="*" element={<NotFound />} />
  </React.Fragment>,
);

export default createBrowserRouter(routes);
