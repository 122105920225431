import merge from './common/utils/merge';

const defaults = {
  apiTimeout: 180000,
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    scope: 'openid email app_metadata profile',
    responseType: 'code id_token',
  },
  includeContactCatering: false,
  validationMessages: {
    'any.required': 'This field is required.',
    'any.empty': 'Not allowed to be empty',
    'array.min': 'Please add an option',
    'string.email': 'Must be a valid email',
    'string.emails.invalid': 'Must only contain valid emails',
    'string.empty': 'Not allowed to be empty',
    'string.max': 'Value is too long',
    'date.base': 'Value must be a date',
    'date.min': 'Date is too far in the past',
    'number.base': 'Value must be a number',
  },
};

const environments = {
  local: {
    apiUrl:
      process.env.REACT_APP_FRONTEND_API_URL ||
      'https://localhost.test:3000/api/',
    socketUrl:
      process.env.REACT_APP_FRONTEND_SOCKET_URL ||
      'wss://localhost.test:3000/api/',
    auth: {
      redirectUri:
        process.env.REACT_APP_FRONT_END_REDIRECT_URI ||
        'https://localhost.test:3000/post-login',
      postLogoutRedirectUri:
        process.env.REACT_APP_FRONTEND_POST_LOGOUT_REDIRECT_URI ||
        'https://localhost.test:3000',
      domain: 'http://localhost.test:7000',
    },
  },
  dev: {
    apiUrl: 'https://strata.dev-festivaloperations.com/api/',
    socketUrl: 'wss://strata.dev-festivaloperations.com/api/',
    auth: {
      redirectUri: 'https://strata.dev-festivaloperations.com/post-login',
      postLogoutRedirectUri: 'https://strata.dev-festivaloperations.com',
      domain: 'https://accounts.dev-festivaloperations.com',
    },
  },
  stage: {
    apiUrl: 'https://stage-strata.festivaloperations.com/api/',
    socketUrl: 'wss://stage-strata.festivaloperations.com/api/',
    auth: {
      redirectUri: 'https://stage-strata.festivaloperations.com/post-login',
      postLogoutRedirectUri: 'https://stage-strata.festivaloperations.com',
      domain: 'https://stage-oidc.festivaloperations.com',
    },
  },
  prod: {
    apiUrl: 'https://strataadvance.com/api/',
    socketUrl: 'wss://strataadvance.com/api/',
    auth: {
      redirectUri: 'https://strataadvance.com/post-login',
      postLogoutRedirectUri: 'https://strataadvance.com',
      domain: 'https://accounts.strataadvance.com',
    },
  },
};

export default merge(defaults, environments[process.env.REACT_APP_ENV]);
