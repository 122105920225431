import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PaperSwitch = ({
  name,
  title,
  activated,
  disabled = false,
  performAction,
}) => {
  const [switchedOn, setSwitchedOn] = useState(activated);

  const handleClick = () => {
    performAction(!switchedOn);
    setSwitchedOn(prevSwitchedOn => !prevSwitchedOn);
  };

  return (
    <div className="paper-switch__parent">
      {title ? (
        <div className="paper-switch__toggle-group--title">
          <p>{title}</p>
        </div>
      ) : null}
      <div className="paper-switch__toggle-group--slider" onClick={handleClick}>
        <div
          name={name}
          className={`paper-switch__input ${switchedOn ? ' switchedOn' : ''}`}
          type="checkbox"
          disabled={disabled}
        />
        <span className="slider round" />
      </div>
    </div>
  );
};

PaperSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  activated: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  performAction: PropTypes.func.isRequired,
};

export default PaperSwitch;
