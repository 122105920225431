import { v1 as keygen } from 'uuid';
import {
  Container,
  Header,
  Title,
  SearchTerm,
  Pagination,
} from './ResultsDropDown.styles';
import { Result } from './Result';
import PropTypes from 'prop-types';

const ResultsDropDown = ({ results, inputValue, handleClose }) => {
  const firstTenResults = Array.isArray(results) && results.slice(0, 10);

  return (
    <div>
      {inputValue && (
        <Container>
          <Header>
            <Title>Search for </Title>
            <SearchTerm>{`"${inputValue}"`}</SearchTerm>
          </Header>
          {inputValue && results.length > 0 ? (
            <div>
              {firstTenResults.map(data => (
                <Result
                  key={keygen()}
                  data={data}
                  inputValue={inputValue}
                  handleClose={handleClose}
                />
              ))}
              <Pagination>
                Showing {firstTenResults.length} of {firstTenResults[0].total}
              </Pagination>
            </div>
          ) : null}
        </Container>
      )}
    </div>
  );
};

ResultsDropDown.propTypes = {
  results: PropTypes.array,
  inputValue: PropTypes.string,
  handleClose: PropTypes.func,
};

export default ResultsDropDown;
