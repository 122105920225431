import { createContext, useContext, useReducer } from 'react';
import { createSelector } from 'reselect';
import { Map } from 'immutable';

const UnsavedCredentialsContext = createContext(null);
const UnsavedCredentialsDispatchContext = createContext(null);

export const UnsavedCredentialsProvider = ({ children }) => {
  const [unsavedCredentials, dispatch] = useReducer(
    unsavedCredentialsReducer,
    Map(),
  );

  return (
    <UnsavedCredentialsContext.Provider value={unsavedCredentials}>
      <UnsavedCredentialsDispatchContext.Provider value={dispatch}>
        {children}
      </UnsavedCredentialsDispatchContext.Provider>
    </UnsavedCredentialsContext.Provider>
  );
};

export const useUnsavedCredentialsDispatch = () =>
  useContext(UnsavedCredentialsDispatchContext);

export const useUnsavedCredentials = () =>
  useContext(UnsavedCredentialsContext);

export const useUnsavedCredentialQuantity = credentialId => {
  const unsavedCredentials = useContext(UnsavedCredentialsContext);
  return unsavedCredentials.get(credentialId) ?? 0;
};

export const selectUnsavedCredentials = createSelector(
  unsavedCredentials => unsavedCredentials,
  unsavedCredentials =>
    unsavedCredentials
      .entrySeq()
      .map(([id, quantity]) => ({
        id: parseInt(id, 10),
        quantity,
      }))
      .toJS(),
);

export const selectUnsavedCredentialIds = createSelector(
  unsavedCredentials => unsavedCredentials,
  unsavedCredentials => unsavedCredentials.keySeq(),
);

export const selectUnsavedCredentialsWithQuantity = createSelector(
  selectUnsavedCredentials,
  unsavedCredentials =>
    unsavedCredentials.filter(
      unsavedCredential => unsavedCredential.quantity > 0,
    ),
);

const unsavedCredentialsReducer = (unsavedCredentials, action) => {
  switch (action.type) {
    case 'change':
      return handleChange(unsavedCredentials, action);

    case 'clear':
      return handleClear(unsavedCredentials, action);

    default:
      return unsavedCredentials;
  }
};

const handleChange = (unsavedCredentials, action) => {
  const { changedQuantities } = action;

  if (!changedQuantities || changedQuantities.length === 0) {
    return unsavedCredentials;
  }

  var newUnsavedCredentials = unsavedCredentials;
  changedQuantities.forEach(({ credentialId, quantity }) => {
    newUnsavedCredentials = newUnsavedCredentials.set(credentialId, quantity);
  });

  return newUnsavedCredentials;
};

const handleClear = unsavedCredentials => unsavedCredentials.clear();
