import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from '../Tag';

const CropperScaffold = ({
  children,
  className,
  label,
  required,
  validation,
}) => {
  const scaffoldClasses = {
    'input-scaffold': true,
    'cropper-scaffold': true,
    [`${className}`]: className,
  };

  return (
    <div className={classNames(scaffoldClasses)}>
      {label ? (
        <span className="input-label">
          <span className="input-label--label">{label}</span>
          {!required ? <Tag colorTheme="optional">optional</Tag> : null}
        </span>
      ) : null}
      {children}
      {validation ? <div className="input-validation">{validation}</div> : null}
    </div>
  );
};

CropperScaffold.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  validation: PropTypes.any,
};

export default CropperScaffold;
