import { createSelector } from 'reselect';

const loggedInUser = state => state.auth.loggedInUser;

export const getLoggedInUser = createSelector(loggedInUser, loggedInUser =>
  loggedInUser.get('data'),
);

export const getUserIsStrataAdmin = createSelector(
  getLoggedInUser,
  loggedInUser => !!loggedInUser.getIn(['permissionsMap', 'strataAdmin']),
);
