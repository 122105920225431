import React from 'react';

function SwitchOff() {
  /* eslint-disable max-len */
  return (
    <svg
      width="50"
      height="24"
      viewBox="0 0 50 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <rect fill="#CCC" width="50" height="24" rx="12" />
        <rect fill="#FFF" x="2" y="2" width="20" height="20" rx="10" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default SwitchOff;
