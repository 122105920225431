import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getBulkSendEmailTypes } from '../event/event-selectors';
import { setPageSettings } from '../navigation/nav-actions';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import PaperSwitch from '../common/paper/PaperSwitch';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  margin-bottom: 20px;
  flex-direction: row;
`;

const UploadContainer = styled.div`
  display: flex;
  width: 100%;
  height: 54px;
  border: 1px solid #ccc;
  margin-bottom: 12px;
  background-color: #fff;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
  margin-left: 20px;
`;

const Description = styled.div`
  display: flex;
  opacity: 0.5;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 16px;
`;

const Button = styled.div`
  cursor: pointer;
`;

const EmailType = ({ emailType, includeDisabled }) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate({
          pathname: emailType.get('type'),
          search: includeDisabled ? `?includeDisabled` : null,
        });
      }}
    >
      <UploadContainer>
        <Icon icon="Megaphone" />
        <Row>
          <Title>{emailType.get('label')}</Title>
          <Description>{emailType.get('description')}</Description>
        </Row>
      </UploadContainer>
    </Button>
  );
};

EmailType.propTypes = {
  emailType: PropTypes.object.isRequired,
  includeDisabled: PropTypes.bool.isRequired,
};

const BulkSendContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const pageSettings = useSelector(state =>
    state.nav.pageSettings.get(location.pathname),
  );

  const includeDisabled = pageSettings?.includeDisabled;

  const emailTypes = useSelector(state =>
    getBulkSendEmailTypes(state, { params, includeDisabled }),
  );

  const changeIncludeDisabled = value => {
    dispatch(
      setPageSettings({
        url: location.pathname,
        values: { includeDisabled: value },
      }),
    );
  };
  return (
    <Container>
      <Header>
        <Paper>
          <PaperHeader
            title="Bulk Send Emails"
            actions={[
              <div key="enable-email-template" style={{ marginRight: '15px' }}>
                <PaperSwitch
                  name="enabled"
                  title="Include disabled templates"
                  activated={includeDisabled}
                  performAction={changeIncludeDisabled}
                />
              </div>,
            ]}
          />
          {!emailTypes?.isEmpty() ? null : (
            <div className="generic-list--empty">
              <Icon icon="Sad" />
              <p>No Email Templates are available</p>
            </div>
          )}
        </Paper>
      </Header>
      {emailTypes?.map(emailType => (
        <EmailType
          key={emailType.get('id')}
          emailType={emailType}
          includeDisabled={includeDisabled}
        />
      ))}
    </Container>
  );
};

export default BulkSendContainer;
