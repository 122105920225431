import { Map } from 'immutable';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { editContact } from './contact-actions';
import PropTypes from 'prop-types';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import StatusButton from '../common/StatusButton';

const ContactNameEdit = ({ contact, onCancel, onSuccess }) => {
  const dispatch = useDispatch();

  const initialValues = {
    first_name: contact.get('first_name'),
    last_name: contact.get('last_name'),
  };

  const handleSubmit = values =>
    dispatch(editContact(contact.get('id'), values)).then(action => {
      if (action.response.ok) {
        onSuccess();
      } else {
        return formatValidationErrors(action.json).toJS();
      }
    });

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field name="first_name" required component={ReduxFormsField}>
            <input type="text" />
          </Field>
          <Field name="last_name" required component={ReduxFormsField}>
            <input type="text" />
          </Field>
          <StatusButton
            type="submit"
            buttonText="Submit"
            disabled={pristine || submitting}
            status={submitting ? 'loading' : 'default'}
          />
          <button
            type="button"
            className="button button--plain"
            disabled={submitting}
            onClick={onCancel}
          >
            Cancel
          </button>
        </form>
      )}
    />
  );
};

ContactNameEdit.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ContactNameEdit;
