import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Map, List } from 'immutable';
import { isRequired } from 'revalidate';
import { getStatusForButton } from '../common/forms/FormHelper';
import { requestDeleteContact } from './onsite-actions';
import { showNotification } from '../notification/notification-actions';
import PropTypes from 'prop-types';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import StatusButton from '../common/StatusButton';
import Checkbox from '../common/forms/CheckboxBoolean';
import SelectContact from './SelectContact';

const DeleteContactForm = ({
  department,
  eventOnsiteRequestTypes,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const [contact, setContact] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  if (!department) {
    return null;
  }

  const handleSubmit = values => {
    const contactDisplayName = contact
      ? `${contact.get('first_name')} ${contact.get('last_name')}`
      : '';

    dispatch(requestDeleteContact(contact, values.notes)).then(action => {
      if (action.response.ok) {
        onSubmit();

        const requestType = eventOnsiteRequestTypes.find(
          type => type.get('onsite_request_type') === 'Remove Person',
        );
        if (
          requestType.get('auto_approve') &&
          !requestType.get('send_reviewed_email')
        ) {
          dispatch(
            showNotification({
              message: `Successfully removed ${contactDisplayName} and all their credentials.`,
              status: 'success',
            }),
          );
        } else {
          dispatch(
            showNotification({
              message: `Request to remove ${contactDisplayName} has been received.  We will contact you when it is approved.`,
              status: 'success',
            }),
          );
        }
      } else {
        dispatch(
          showNotification({
            message: `Error removing ${contactDisplayName}`,
            status: 'error',
          }),
        );
      }
    });
  };

  return (
    <div>
      <SelectContact
        type="Remove"
        department={department}
        onContactSelected={setContact}
        disableIssuedContacts={true}
      />
      {contact && (
        <Form
          onSubmit={handleSubmit}
          initialValues={{ notes: '' }}
          render={({
            handleSubmit,
            pristine,
            submitting,
            submitFailed,
            valid,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="notes"
                component={ReduxFormsField}
                label="Reason"
                required={true}
                validate={isRequired('Reason')}
              >
                <textarea />
              </Field>
              <div className="onsite-checkbox__delete-contact">
                <Checkbox
                  labelClasses="onsite-checkbox-label__delete-contact"
                  label="Confirm removal. All requested or assigned credentials will also be removed."
                  value={deleteConfirmed}
                  onChange={setDeleteConfirmed}
                />
              </div>
              <div className="onsite__status-button">
                <StatusButton
                  className="button button--small"
                  type="submit"
                  buttonText="Remove"
                  completeText="Removed!"
                  disabled={
                    submitting || pristine || !valid || !deleteConfirmed
                  }
                  status={getStatusForButton({
                    pristine,
                    submitting,
                    submitFailed,
                  })}
                />
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
};

DeleteContactForm.propTypes = {
  department: PropTypes.instanceOf(Map).isRequired,
  eventOnsiteRequestTypes: PropTypes.instanceOf(List),
  onSubmit: PropTypes.func.isRequired,
};

export default DeleteContactForm;
