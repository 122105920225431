import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import { resource, useACL } from '../common/ACL';
import { defaultBlocks } from '../lib/draftjs-editor';
import { showNotification } from '../notification/notification-actions';
import { deleteEmailTypeSample, updateEmailTypeSample } from './email-actions';
import { getEmailTypeSamples } from '../event/event-selectors';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputScaffold from '../common/forms/InputScaffold';
import StatusButton from '../common/StatusButton';
import ReduxSynapseDraft from '../common/forms/ReduxSynapseDraft';
import DeleteButton from '../common/DeleteButton';

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  line-height: 1;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -40px;
  padding: 20px 50px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
`;

const aclRules = {
  canManageGlobalSampleEmailTemplate: [
    resource.STRATA_ADMIN,
    'manage-sample-email-template',
  ],
  canManagePromoterSampleEmailTemplate: [
    resource.PROMOTER,
    'manage-sample-email-template',
  ],
};

const ViewSampleEmailTemplatesModal = ({ emailType, onApply, trigger }) => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const params = useParams();

  const sampleEmailTemplates = useSelector(state =>
    getEmailTypeSamples(state, { params, emailType }),
  );

  const [isOpen, setIsOpen] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [selectedSampleId, setSelectedSampleId] = useState(
    sampleEmailTemplates.first()?.get('id') ?? '',
  );

  const getSample = sampleId =>
    sampleEmailTemplates.find(
      sample => String(sample.get('id')) === String(sampleId),
    );

  const selectedSample = getSample(selectedSampleId);

  const [subject, setSubject] = useState(selectedSample?.get('subject') ?? '');
  const [subjectError, setSubjectError] = useState(null);

  const [body, setBody] = useState(selectedSample?.get('body')?.toJS() ?? null);
  const [bodyError, setBodyError] = useState(null);

  const canManageSampleEmailTemplate =
    !!selectedSample &&
    ((acl.canManagePromoterSampleEmailTemplate &&
      (!!selectedSample.get('promoter_id') ||
        !!selectedSample.get('festival_id'))) ||
      (acl.canManageGlobalSampleEmailTemplate &&
        !selectedSample.get('promoter_id') &&
        !selectedSample.get('festival_id')));

  const open = e => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setSubject(selectedSample?.get('subject') ?? '');
    setSubjectError(null);
    setBody(selectedSample?.get('body')?.toJS() ?? null);
    setBodyError(null);
  };

  const setSelectedSample = sampleId => {
    const sample = getSample(sampleId);
    setSelectedSampleId(sampleId);
    setSubject(sample?.get('subject') ?? '');
    setBody(sample?.get('body')?.toJS() ?? null);
  };

  const selectSample = e => setSelectedSample(e.target.value);

  const applySample = async () => {
    onApply(subject, body);
    close();
  };

  const updateSample = () => {
    setUpdating(true);
    setSubjectError(null);
    setBodyError(null);

    dispatch(
      updateEmailTypeSample(emailType, selectedSampleId, {
        subject,
        body,
      }),
    ).then(action => {
      setUpdating(false);
      if (action.response.ok) {
        dispatch(
          showNotification({
            message: 'Sample Email Template successfully updated',
            status: 'success',
          }),
        );
      } else if (action.json.validationErrors) {
        action.json.validationErrors.forEach(error => {
          if (error.key[0] === 'subject') setSubjectError(error.message);
          if (error.key[0] === 'body') setBodyError(error.message);
        });
      }
    });
  };

  const onDelete = () => {
    const deleteSampleId = selectedSampleId;
    return dispatch(deleteEmailTypeSample(emailType, deleteSampleId)).then(
      action => {
        if (action.response.ok) {
          const newId = emailType
            .get('samples')
            .find(sample => String(sample.get('id')) !== String(deleteSampleId))
            ?.get('id');
          setSelectedSample(newId);
          dispatch(
            showNotification({
              message: 'Sample Email Template successfully deleted',
              status: 'success',
            }),
          );

          return true;
        } else {
          return false;
        }
      },
    );
  };

  return (
    <Modal
      closeIcon
      open={isOpen}
      onOpen={open}
      onClose={close}
      trigger={trigger}
    >
      <Modal.Header>Apply Sample Email Template</Modal.Header>
      <Modal.Content>
        <div className="modal__body">
          <Body className="generic-form__body">
            <InputScaffold label="Sample Template" required>
              <select value={selectedSampleId} onChange={selectSample}>
                {sampleEmailTemplates.map(sample => (
                  <option value={sample.get('id')} key={sample.get('id')}>
                    {sample.get('name')}
                  </option>
                ))}
              </select>
            </InputScaffold>
            <InputScaffold label="Subject" required validation={subjectError}>
              <input
                type="text"
                value={subject}
                onChange={e => setSubject(e.target.value)}
              />
            </InputScaffold>
            <InputScaffold label="Body" required validation={bodyError}>
              <ReduxSynapseDraft
                key={selectedSampleId}
                value={body}
                onChange={setBody}
                //containerStyle={{ maxHeight: '500px', overflowY: 'auto' }}
                inlineControls={['Bold', 'Italic', 'Underline', 'Link']}
                blockControls={[
                  'UL',
                  'OL',
                  'H1',
                  'H2',
                  'H3',
                  'H4',
                  'H5',
                  'H6',
                  'Blockquote',
                ]}
                controlDisplay="inline"
                customBlocks={defaultBlocks}
                customBlockControls={Object.keys(defaultBlocks)}
              />
            </InputScaffold>
          </Body>
        </div>
      </Modal.Content>
      <Footer>
        <StatusButton
          buttonText="Apply"
          completeText="Applied"
          status="default"
          onClick={applySample}
        />
        {canManageSampleEmailTemplate && (
          <Row>
            <StatusButton
              buttonText="Update"
              completeText="Updated"
              status={updating ? 'loading' : 'default'}
              onClick={updateSample}
            />
            <DeleteButton
              buttonText="Delete Sample"
              onConfirmDelete={() => onDelete()}
            />
          </Row>
        )}
      </Footer>
    </Modal>
  );
};

ViewSampleEmailTemplatesModal.propTypes = {
  emailType: PropTypes.object.isRequired,
  onApply: PropTypes.func.isRequired,
  trigger: PropTypes.node,
};

export default ViewSampleEmailTemplatesModal;
