import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useMatches, useParams } from 'react-router-dom';
import { getCurrentEvent } from './event/event-selectors';
import { getCurrentDepartment } from './department/department-selectors';
import departmentTypeMap from './lib/department-type-map';
import { setTopbarTitle } from './navigation/nav-actions';

const TitleLoader = () => {
  const dispatch = useDispatch();
  const matches = useMatches();
  const params = useParams();

  const currentTitle = useRef();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  useEffect(() => {
    let title;
    const departmentType = params.departmentType;

    if (department) {
      title = `${
        departmentTypeMap[departmentType].label.plural
      } - ${department.get('name')}`;
    } else if (departmentType) {
      title = departmentTypeMap[departmentType].label.plural;
    } else if (event) {
      title = `Events - ${event.get('name')}`;
    } else {
      title = matches?.findLast(match => !!match.handle?.title)?.handle.title;
    }

    if (title && title !== currentTitle.current) {
      currentTitle.current = title;
      dispatch(setTopbarTitle(title));
    }
  }, [department, dispatch, event, matches, params.departmentType]);

  return <Outlet />;
};

export default TitleLoader;
