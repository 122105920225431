import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { composeValidators, isRequired } from 'revalidate';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import isValidEmail from '../common/validate/is-valid-email';
import recursiveTrim from '../common/utils/recursive-trim';
import PropTypes from 'prop-types';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import Paper from '../common/paper/Paper';
import PaperHeader from '../common/paper/PaperHeader';
import StatusButton from '../common/StatusButton';

const InviteForm = ({
  backToRoute,
  clearAction,
  inviteAction,
  userType = 'User',
  instructions,
  additionalFields,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = values =>
    dispatch(inviteAction(recursiveTrim(values))).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      }

      dispatch(clearAction());
      dispatch(
        showNotification({
          message: `${userType} successfully saved`,
          status: 'success',
        }),
      );

      navigate(backToRoute);
    });

  return (
    <Form onSubmit={handleSubmit}>
      {({
        handleSubmit,
        hasSubmitErrors,
        hasValidationErrors,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
        submitFailed,
      }) => (
        <Paper>
          <PaperHeader
            backTo={backToRoute}
            backToDisabled={submitting}
            title={`Invite ${userType}`}
          />
          {instructions && (
            <div className="alert alert--working">
              <p>{instructions}</p>
            </div>
          )}
          <form className="generic-form" onSubmit={handleSubmit}>
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                <Field
                  name="first_name"
                  component={ReduxFormsField}
                  label="First Name"
                  placeholder="First Name"
                  required
                  validate={isRequired('First Name')}
                >
                  <input type="text" />
                </Field>

                <Field
                  name="last_name"
                  component={ReduxFormsField}
                  label="Last Name"
                  placeholder="Last Name"
                  required
                  validate={isRequired('Last Name')}
                >
                  <input type="text" />
                </Field>

                <Field
                  name="email"
                  component={ReduxFormsField}
                  label="Email"
                  placeholder="user@email.com"
                  required
                  validate={composeValidators(
                    isRequired,
                    isValidEmail,
                  )('Email')}
                >
                  <input type="text" />
                </Field>

                {additionalFields}
              </div>
            </div>

            <div className="generic-form__footer">
              <StatusButton
                type="submit"
                disabled={
                  submitting ||
                  pristine ||
                  hasValidationErrors ||
                  (hasSubmitErrors && !modifiedSinceLastSubmit)
                }
                status={
                  submitFailed && pristine
                    ? 'error'
                    : submitting
                    ? 'loading'
                    : 'default'
                }
              />
              <Link
                className="button button--plain"
                to={backToRoute}
                disabled={submitting}
              >
                Cancel
              </Link>
            </div>
          </form>
        </Paper>
      )}
    </Form>
  );
};

InviteForm.propTypes = {
  backToRoute: PropTypes.string.isRequired,
  clearAction: PropTypes.func.isRequired,
  inviteAction: PropTypes.func.isRequired,
  userType: PropTypes.string,
  instructions: PropTypes.string,
  additionalFields: PropTypes.node,
};

export default InviteForm;
