import client, { publicClient } from '../client';

export const fetchAllEmails = departmentId =>
  new Promise((resolve, reject) => {
    client.get(`department/${departmentId}/get-emails`).then(response => {
      if (!response.ok) {
        reject(new Error('Failed to fetch emails from server.'));
      }
      resolve(response);
    });
  });

export const getApplicationData = (appId, token) =>
  new Promise((resolve, reject) => {
    publicClient.get(`applications/${appId}/${token}`).then(response => {
      if (!response.ok) {
        reject(new Error('Failed to get application data.'));
      }
      resolve(response);
    });
  });

export const submitApplication = (appId, token, payload) =>
  new Promise((resolve, reject) => {
    publicClient
      .post(`applications/${appId}/${token}`, payload)
      .then(response => {
        if (!response.ok) {
          reject(new Error('Failed to get application data.'));
        }
        resolve(response);
      });
  });

export const submitFile = (appId, token, type, file) =>
  new Promise((resolve, reject) => {
    const data = new FormData();
    data.append(type, file);
    fetch(`/api/applications/${appId}/${token}/upload`, {
      method: 'POST',
      body: data,
    }).then(response => {
      if (!response.ok) {
        reject(new Error('Failed to submit file.'));
      }
      resolve(response);
    });
  });

export const removeFile = (appId, token, fileType) =>
  new Promise((resolve, reject) => {
    publicClient
      .delete(`applications/${appId}/${token}/upload/${fileType}`)
      .then(response => {
        if (!response.ok) {
          reject(new Error('Failed to remove file.'));
        }
        resolve(response);
      });
  });

export const fetchSignedLink = (appId, token, param) =>
  new Promise((resolve, reject) => {
    publicClient
      .get(`applications/${appId}/${token}/file/${param}`)
      .then(response => {
        if (!response.ok) {
          reject(new Error('Failed to fetch link'));
        }
        resolve(response);
      });
  });

export const SEND_ADVANCE_FORM_EMAIL = 'SEND_ADVANCE_FORM_EMAIL';
export const sendAdvanceFormEmail = (departmentId, payload) => ({
  type: SEND_ADVANCE_FORM_EMAIL,
  promise: client.post(
    `departments/${departmentId}/advance-form-email`,
    payload,
  ),
});

export const LINK_DEPARTMENT_TO_APPLICATION = 'LINK_DEPARTMENT_TO_APPLICATION';
export const linkDepartmentToApplication = (department, applicationId) => ({
  type: LINK_DEPARTMENT_TO_APPLICATION,
  eventId: department.get('event_id'),
  departmentType: department.get('type'),
  departmentId: department.get('id'),
  applicationId,
  promise: client.post(`departments/${department.get('id')}/applications`, {
    application: applicationId,
  }),
});
