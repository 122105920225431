import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { composeValidators } from 'revalidate';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { getCurrentEvent } from '../event/event-selectors';
import { updateEventEmailSettings } from '../event/event-actions';
import { showNotification } from '../notification/notification-actions';
import isValidEmailList from '../common/validate/is-valid-email-list';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import Paper, { PaperHeader } from '../common/paper/Paper';
import FormStatusButton from '../common/FormStatusButton';

const EmailSettingsForm = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const initialValues = {};

  if (event?.get('default_bcc'))
    initialValues.defaultBcc = event?.get('default_bcc');

  if (event?.get('error_notification_email'))
    initialValues.errorNotificationEmail = event?.get(
      'error_notification_email',
    );

  const handleSubmit = values => {
    return dispatch(updateEventEmailSettings(event, values)).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      }

      dispatch(
        showNotification({
          message: 'Event Email Settings successfully updated',
          status: 'success',
        }),
      );
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form className="generic-form paper__wrapper" onSubmit={handleSubmit}>
          <div>
            <Paper>
              <PaperHeader title="Email Settings" />
              <div className="generic-form__body">
                <Field
                  name="defaultBcc"
                  label="Bcc"
                  component={ReduxFormsField}
                  validate={composeValidators(isValidEmailList())('Bcc')}
                >
                  <input type="text"></input>
                </Field>
                <Field
                  name="errorNotificationEmail"
                  label="Error Notification Email"
                  component={ReduxFormsField}
                  validate={composeValidators(isValidEmailList())(
                    'Error Notification Email',
                  )}
                >
                  <input type="text"></input>
                </Field>
                <FormStatusButton />
              </div>
            </Paper>
          </div>
        </form>
      )}
    />
  );
};

export default EmailSettingsForm;
