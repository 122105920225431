import { createValidator } from 'revalidate';

export default createValidator(
  message => csv => {
    const values = csv
      .split(',')
      .map(val => val.trim())
      .filter(val => !!val);
    if (values.length === 0) {
      return;
    }
    let isInvalid = false;
    values.forEach(value => {
      if (!/^.+@.+$/.test(value)) {
        isInvalid = true;
      }
    });
    if (isInvalid) {
      return message;
    }
  },
  field => `${field} must be a list of comma separated email addresses`,
);
