import PropTypes from 'prop-types';

export const YES = 'yes';
export const NO = 'no';

const YesNoFilter = ({ onChange }) => (
  <select onChange={event => onChange(event.target.value)}>
    <option />
    <option value={YES}>Yes</option>
    <option value={NO}>No</option>
  </select>
);

YesNoFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default YesNoFilter;
