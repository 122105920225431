import { createSelector } from 'reselect';
import { getCurrentEvent } from '../../event/event-selectors';
import FormSchema from '../../lib/form-schema';

export const getCurrentApplication = createSelector(
  getCurrentEvent,
  (state, props) => state[props.type].applications,
  (_state, props) => props.params.applicationId,
  (event, applications, applicationId) =>
    event && applications
      ? applications
          .getIn([event.get('id'), 'data'])
          ?.find(application => application.get('id') === Number(applicationId))
      : null,
);

export const getCurrentApplicationRequest = createSelector(
  (state, props) => state[props.type].applicationRequests.get('data'),
  (_, props) => props.params.departmentSlug,
  (requests, departmentSlug) =>
    requests?.find(department => department.get('slug') === departmentSlug),
);

function getCustomValues(sections) {
  return sections
    .filter(section => !section.get('key'))
    .map(section => {
      const customFields = new FormSchema(
        section.get('custom_fields'),
      ).getSchemaObject();

      if (customFields?.fields) {
        customFields.fields.forEach(field => (field.error = false));
      }

      return {
        id: section.get('id'),
        fields: customFields,
      };
    })
    .toJS();
}

export function getAdvanceApplicationValues(values, sections) {
  const customValues = getCustomValues(sections);
  delete values.terms_and_conditions_accepted;
  //get catering values
  const fieldNamePrefix = 'mealCount_';
  const cateringValues = Object.entries(values['catering'] || {}).map(
    ([key, value]) => {
      const mealTypeDateId = key.substring(fieldNamePrefix.length);
      return { mealTypeDateId: mealTypeDateId, mealCount: value };
    },
  );
  delete values.catering;
  values.department_info_contact_name =
    values.department_info_contact_name.trim();
  values.department_info_name = values.department_info_name.trim();
  values.department_info_contact_email =
    values.department_info_contact_email.trim();
  return Object.assign(
    {},
    values,
    { customValues },
    { availability: cateringValues },
  );
}

export function getMediaValues(values, sections) {
  const customValues = getCustomValues(sections);
  return Object.assign({}, values, { customValues });
}

export function getInternValues(values, sections) {
  const customValues = getCustomValues(sections);

  const name = `${values.first_name} ${values.last_name}`;

  const personnel = [
    {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
    },
  ];

  delete values.first_name;
  delete values.last_name;
  delete values.email;

  return Object.assign({}, values, {
    name,
    personnel,
    customValues,
  });
}

const getCredentials = createSelector(
  (state, props) => state[props.type].credentials,
  (_, props) => props.event?.get('id'),
  (credentials, eventId) =>
    eventId ? credentials.getIn([eventId, 'data']) : null,
);

export const getAvailableCategories = createSelector(
  getCredentials,
  credentials => {
    const categoriesMap = new Map();

    if (credentials && credentials.size) {
      credentials.forEach(credential => {
        const category = credential.getIn(['credentialType', 'category']);
        categoriesMap.set(category.get('id'), category);
      });

      return [...categoriesMap.values()].sort((category1, category2) =>
        category1.get('name').localeCompare(category2.get('name')),
      );
    }

    return [];
  },
);

export const getAvailableCategoryOptions = createSelector(
  getAvailableCategories,
  availableCategories =>
    availableCategories.map(category => ({
      label: category.get('name'),
      value: category.get('id'),
    })),
);

export const getAvailableCredentialPeriods = createSelector(
  getCredentials,
  credentials => {
    const periodsMap = new Map();

    if (credentials && credentials.size) {
      credentials.forEach(credential => {
        if (
          credential.getIn(['credentialType', 'issue_frequency']) === 'ONE_TIME'
        ) {
          credential.get('oneTimePeriods').forEach(period => {
            periodsMap.set(period.get('id'), period);
          });
        } else {
          periodsMap.set(credential.get('period_id'), credential.get('period'));
        }
      });

      return [...periodsMap.values()].sort((period1, period2) => {
        const date1 = period1.get('dates').minBy(date => date.get('date'));
        const date2 = period2.get('dates').minBy(date => date.get('date'));
        return date1 - date2;
      });
    }

    return [];
  },
);

export const getAvailableCredentialPeriodOptions = createSelector(
  getAvailableCredentialPeriods,
  availableCredentialPeriods =>
    availableCredentialPeriods.map(period => ({
      label: period.get('name'),
      value: period.get('id'),
    })),
);

export const getAvailableCredentials = createSelector(
  getCredentials,
  credentials => {
    if (credentials && credentials.size) {
      credentials = credentials.sort((credential1, credential2) => {
        const credentialType1 = credential1.get('credentialType');
        const credentialType2 = credential2.get('credentialType');

        let compareValue = credentialType1
          .getIn(['category', 'name'])
          .localeCompare(credentialType2.getIn(['category', 'name']));
        if (compareValue !== 0) {
          return compareValue;
        }

        compareValue = credentialType1
          .get('name')
          .localeCompare(credentialType2.get('name'));
        if (compareValue !== 0) {
          return compareValue;
        }

        const periodDate1 =
          credentialType1.get('issue_frequency') === 'ONE_TIME'
            ? credential1
                .get('oneTimePeriods')
                .minBy(period =>
                  period.get('dates').minBy(date => date.get('date')),
                )
            : credential1
                .getIn(['period', 'dates'])
                .minBy(date => date.get('date'));

        const periodDate2 =
          credentialType2.get('issue_frequency') === 'ONE_TIME'
            ? credential1
                .get('oneTimePeriods')
                .minBy(period =>
                  period.get('dates').minBy(date => date.get('date')),
                )
            : credential1
                .getIn(['period', 'dates'])
                .minBy(date => date.get('date'));

        return periodDate1 - periodDate2;
      });
    }

    return credentials;
  },
);

export const getApplicationEmailTypes = createSelector(
  (state, props) => state[props.type].emailTypes,
  emailTypes => emailTypes.get('data'),
);
