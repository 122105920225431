import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Grid, Table } from 'semantic-ui-react';
import { parse } from 'csv-parse/browser/esm/sync';
import {
  getCateringAvailability,
  getDepartmentCateringUploadTemplate,
  updateCateringAvailability,
} from './catering-actions';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from '../department/department-selectors';
import { showNotification } from '../notification/notification-actions';
import { Column, Header, Row } from './DepartmentCateringUpload.styles';
import { StyledButton } from '../common/StyledButton';
import config from '../config';
import moment from 'moment-timezone';
import Dropzone from 'react-dropzone';
import FileSaver from 'file-saver';
import bytesToSize from '../common/utils/bytesToSize';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import StatusButton from '../common/StatusButton';

class ParseError extends Error {
  constructor(message, lines, index, value) {
    super(message);
    this.name = 'ParseError';
    this.lines = lines;
    this.index = index;
    this.code = value;
  }
}

const DepartmentCateringUpload = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const mealTypes = useSelector(state =>
    state.department.departmentMealTypes.get('data'),
  );

  const today = moment.utc();
  const periods = event?.get('periods');

  const [validationErrors, setValidationErrors] = useState([]);

  const getTemplate = () => {
    getDepartmentCateringUploadTemplate(department.get('id'))
      .then(response => response.blob())
      .then(blob => {
        FileSaver.saveAs(blob, `${department.get('slug')}_catering_upload.csv`);
      });
  };

  const onDropAccepted = onChange => files => {
    if (files && files.length > 0) {
      onChange(files[0]);
      setValidationErrors([]);
    }
  };

  const CsvDropzone = ({ value, onChange, submitSucceeded }) => (
    <Dropzone
      accept={{ 'text/csv': [] }}
      maxFiles={1}
      maxSize={1024 * 1024 * 3}
      multiple={false}
      onDropAccepted={onDropAccepted(onChange)}
    >
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
        <div
          {...getRootProps({
            className: `dropzone${isDragAccept ? ' dropzone--active' : ''}`,
            style: isDragReject
              ? {
                  background: 'rgba(255, 0, 0, 0.4)',
                  borderColor: 'rgb(255, 0, 0)',
                }
              : {},
          })}
        >
          <input {...getInputProps()} />
          <div className="dropzone-instructions">
            <div className="dropzone-instructions--main">
              Drag-n-drop or click to upload a file
            </div>
            <div className="dropzone-instructions--sub">
              <p>Accepted file type: .csv</p>
            </div>
            <div className="dropzone-instructions--sub">
              <p>Max file size: 3MB</p>
            </div>
            {value && (
              <aside
                style={{
                  textAlign: 'left',
                  borderTop: '2px dashed #ddd',
                  marginTop: '10px',
                  paddingTop: '10px',
                }}
              >
                <h4>{submitSucceeded ? 'Uploaded File' : 'File to Upload'}</h4>
                <p style={{ paddingTop: '5px' }}>
                  {value.name} ({bytesToSize(value.size)})
                </p>
              </aside>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );

  const uploadColumns = {};
  const fileColumns = {
    Period: 'period',
    Date: 'date',
  };

  const parseFile = async file => {
    const requiredColumns = {
      Period: false,
      Date: false,
    };

    const parsedFile = await parse(await file.text(), {
      skip_empty_lines: true,
      skip_records_with_error: true,
      skip_records_with_empty_values: false,
      trim: true,
      cast_date: true,
      columns: header => {
        const newHeader = [];
        for (const column of header) {
          if (column in fileColumns) {
            uploadColumns[fileColumns[column]] = column;
            requiredColumns[column] = true;
            newHeader.push(fileColumns[column]);
          } else {
            const mealType = mealTypes.find(mt => mt.get('name') === column);

            if (mealType) {
              const uploadColumn = `meal_type_id-${mealType.get('id')}`;
              uploadColumns[uploadColumn] = column;
              requiredColumns[uploadColumn] = true;
              newHeader.push(uploadColumn);
            } else throw new Error(`File contains invalid column "${column}"`);
          }
        }
        return newHeader;
      },
      cast: (value, { header, lines, column }) => {
        if (header) return value;
        else if (column === 'period') {
          const period = periods.find(
            p => p.get('name').toLowerCase() === value.toLowerCase(),
          );
          if (period) return period;
          else
            throw new ParseError(
              'Invalid Period',
              lines,
              uploadColumns[column],
              value,
            );
        } else if (column === 'date') {
          return moment.utc(value);
        } else {
          if (value === '') return undefined;
          if (isNaN(value))
            throw new ParseError(
              `Invalid Meal Count`,
              lines,
              uploadColumns[column],
              value,
            );
          return parseFloat(value);
        }
      },
    });

    const missingColumns = [];
    let missingMealTypeColumn = true;

    for (const [column, isIncluded] of Object.entries(requiredColumns)) {
      if (!isIncluded) missingColumns.push(column);
      if (column.startsWith('meal_type_id-')) missingMealTypeColumn = false;
    }

    if (missingColumns.length > 0)
      throw new Error(
        `Missing required columns: ${missingColumns
          .map(column => `'${column}'`)
          .join(', ')}`,
      );

    if (missingMealTypeColumn)
      throw new Error('Missing at least one Meal Type column');

    return parsedFile;
  };

  const handleSubmit = async values => {
    const payload = { availability: [], replace: false };
    const newValidationErrors = [];

    let rows;
    try {
      rows = await parseFile(values.file);
    } catch (error) {
      console.error(error);
      setValidationErrors([
        {
          row: error.lines,
          field: error.index,
          value: error.code,
          message: error.message,
        },
      ]);
      return {
        file: error.message,
      };
    }

    rows?.forEach((row, index) => {
      const periodDate = row.period
        .get('dates')
        .find(date => moment.utc(date.get('date')).isSame(row.date));

      if (!periodDate) {
        newValidationErrors.push({
          row: index + 2,
          field: 'Date',
          value: row.date.format('YYYY-MM-DD'),
          message: `Date is not valid for Period '${row.period.get('name')}'`,
        });

        return;
      }

      if (today.isAfter(row.date, 'day')) return;

      for (const [key, value] of Object.entries(row)) {
        if (value === undefined) continue;
        if (key.startsWith('meal_type_id-')) {
          const mealTypeId = Number(key.replace('meal_type_id-', ''));
          const mealType = mealTypes.find(
            mt => Number(mt.get('id')) === mealTypeId,
          );
          const mealTypeDate = mealType
            .get('dates')
            .find(mtd => mtd.get('id') === periodDate.get('id'));

          if (!mealTypeDate) {
            newValidationErrors.push({
              row: index + 2,
              field: 'Date',
              value: row.date.format('YYYY-MM-DD'),
              message: `Date is not valid for Meal type '${mealType.get(
                'name',
              )}'`,
            });

            return;
          }

          payload.availability.push({
            mealTypeDateId: mealTypeDate.get('_pivot_id'),
            mealCount: value,
          });
        }
      }
    });

    if (newValidationErrors.length > 0) {
      setValidationErrors(newValidationErrors);
      return { [FORM_ERROR]: 'Validation Errors' };
    }

    if (!payload.availability.length) return;

    dispatch(updateCateringAvailability(department.get('id'), payload)).then(
      action => {
        if (action.response.ok) {
          dispatch(getCateringAvailability(department.get('id')));
          dispatch(
            showNotification({
              message: 'Department catering availability successfully uploaded',
              status: 'success',
            }),
          );
        } else {
          const errors = [];
          if (action.json.validationErrors) {
            action.json.validationErrors?.forEach(validationError => {
              let error = {
                message:
                  config.validationMessages[validationError.type] ??
                  validationError.message,
              };
              errors.push(error);
            });
            setValidationErrors(errors);
            return { [FORM_ERROR]: 'Validation Errors' };
          }
        }
      },
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({
        handleSubmit,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
        submitFailed,
        submitSucceeded,
      }) => (
        <div>
          <Paper>
            <PaperHeader backTo=".." title="Upload Department Catering" />
            <form
              onSubmit={handleSubmit}
              className="generic-form paper__wrapper"
            >
              <div className="generic-form__body">
                <Column>
                  <Header>1. Export Template:</Header>
                  <Row>
                    <StyledButton
                      type="button"
                      title="Download .csv Template"
                      color="blue"
                      onClick={getTemplate}
                      style={{
                        width: '250px',
                        alignSelf: 'end',
                      }}
                    />
                  </Row>
                </Column>
                <Column>
                  <Header>2. Upload CSV file to Strata :</Header>
                  <Row>
                    <Field
                      name="file"
                      component={ReduxFormsFieldNoLabel}
                      label=""
                      required
                    >
                      <CsvDropzone submitSucceeded={submitSucceeded} />
                    </Field>
                  </Row>
                </Column>
                {validationErrors.length > 0 && (
                  <Column>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Header style={{ paddingLeft: '10px' }}>
                            CSV FILE NOT PROCESSED - Please correct the errors
                            and re-upload:
                          </Header>
                          <Table collapsing padded selectable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Row #</Table.HeaderCell>
                                <Table.HeaderCell>Field</Table.HeaderCell>
                                <Table.HeaderCell>Value</Table.HeaderCell>
                                <Table.HeaderCell>
                                  Error Message
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {validationErrors.map((ve, idx) => (
                                <Table.Row negative key={idx}>
                                  <Table.Cell>{ve.row}</Table.Cell>
                                  <Table.Cell>{ve.field}</Table.Cell>
                                  <Table.Cell>{ve.value}</Table.Cell>
                                  <Table.Cell>{ve.message}</Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Column>
                )}
              </div>
              <div className="generic-form__footer">
                <StatusButton
                  buttonText="Upload Catering"
                  completeText="Catering Uploaded"
                  disabled={
                    pristine ||
                    submitting ||
                    (submitFailed && !modifiedSinceLastSubmit)
                  }
                  status={
                    modifiedSinceLastSubmit
                      ? 'default'
                      : submitSucceeded
                      ? 'success'
                      : submitFailed
                      ? 'error'
                      : submitting
                      ? 'loading'
                      : 'default'
                  }
                  type="submit"
                />
              </div>
            </form>
          </Paper>
        </div>
      )}
    />
  );
};

export default DepartmentCateringUpload;
