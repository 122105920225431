import { composeValidators, createValidator, isRequired } from 'revalidate';

const atLeastOneAttendeeTypeRequestable = createValidator(
  message => value => {
    const values = Object.keys(value).map(type => value[type]);
    if (values.filter(val => val && val !== 'not-requestable').length === 0) {
      return message;
    }
  },
  field => `${field} must include at least one requestable attendee type`,
);

export default composeValidators(
  isRequired,
  atLeastOneAttendeeTypeRequestable,
)('Attendee Types');
