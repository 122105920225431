import { List } from 'immutable';
import { createSelector } from 'reselect';

const getCategoryList = state => state.credentialType.categoryList.get('data');

export const getCategories = createSelector(getCategoryList, categoryList => {
  const categories = {};
  categoryList.forEach(category => {
    categories[category.get('id')] = category;
  });
  return categories;
});

export const getCredentialTypes = createSelector(
  getCategoryList,
  categoryList => {
    const credentialTypes = {};
    categoryList.forEach(category => {
      category.get('credentialTypes')?.forEach(credentialType => {
        credentialTypes[credentialType.get('id')] = credentialType;
      });
    });
    return credentialTypes;
  },
);

export const getCredentialTypesList = createSelector(
  getCredentialTypes,
  credentialTypes => List(Object.values(credentialTypes)),
);

export const getCredentials = createSelector(
  getCredentialTypes,
  credentialTypes => {
    const credentials = {};
    Object.values(credentialTypes).forEach(credentialType => {
      credentialType.get('credentials')?.forEach(credential => {
        credentials[credential.get('id')] = credential;
      });
    });
    return credentials;
  },
);

export const getCredentialsByPulseCredentialId = createSelector(
  getCredentialTypes,
  credentialTypes => {
    const credentials = {};
    Object.values(credentialTypes).forEach(credentialType => {
      credentialType.get('credentials')?.forEach(credential => {
        credentials[credential.get('pulse_credential_id')] = credential;
      });
    });
    return credentials;
  },
);

export const getCurrentCredentialTypeCategory = createSelector(
  getCategoryList,
  (_state, props) => props.params.categoryId,
  (categoryList, categoryId) =>
    categoryList.find(
      category => Number(category.get('id')) === Number(categoryId),
    ),
);

export const getCurrentCredentialType = createSelector(
  getCredentialTypes,
  (_state, props) => parseInt(props.params.credentialTypeId, 10),
  (credentialTypes, credentialTypeId) => credentialTypes[credentialTypeId],
);
