import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { fetchPromoterAdminList } from './promoter-actions';
import { getCurrentPromoter } from './promoter-selectors';
import {
  deleteUser,
  disablePromoterAdmin,
  enablePromoterAdmin,
  resendInvite,
} from '../user/user-actions';
import UserList from '../user/UserList';

const aclRules = {
  canInvite: [resource.PROMOTER, 'invite-promoter-admins'],
  canEnable: [resource.USER, 'enable'],
  canDisable: [resource.USER, 'disable'],
  canDelete: [resource.USER, 'delete'],
};

const PromoterAdminList = () => {
  const acl = useACL(aclRules);
  const location = useLocation();
  const params = useParams();

  const promoterId = useSelector(state =>
    getCurrentPromoter(state, { params })?.get('id'),
  );
  const userList = useSelector(state => state.promoter.adminList.get('data'));
  const loading = useSelector(state => state.promoter.adminList.get('loading'));
  const deleting = useSelector(state => state.user.deleteUser.get('loading'));

  const _fetchPromoterAdminList = useCallback(() => {
    return fetchPromoterAdminList(promoterId);
  }, [promoterId]);

  const isDisabled = user =>
    !user
      .get('promoterRoles')
      .find(role => role.get('promoter_id') === promoterId);

  if (!promoterId) return null;

  return (
    <UserList
      fetchAction={_fetchPromoterAdminList}
      deleteAction={acl.canDelete ? deleteUser : null}
      enableAction={
        acl.canEnable ? userId => enablePromoterAdmin(promoterId, userId) : null
      }
      disableAction={
        acl.canDisable
          ? userId => disablePromoterAdmin(promoterId, userId)
          : null
      }
      isDisabled={isDisabled}
      resendInviteAction={resendInvite}
      userType="Admin"
      userList={userList}
      loading={loading}
      deleting={deleting}
      addNewRoute={`${location.pathname}/~add`}
      canInvite={acl.canInvite ?? false}
    />
  );
};

export default PromoterAdminList;
