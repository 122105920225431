import React from 'react';

function DateRangeArrow({ className }) {
  /* eslint-disable max-len */
  return (
    <svg width="50" height="10" viewBox="0 0 50 10">
      <g fillRule="evenodd">
        <circle cx="2" cy="5" r="2" />
        <circle cx="12" cy="5" r="2" />
        <circle cx="22" cy="5" r="2" />
        <circle cx="32" cy="5" r="2" />
        <path d="M50 5l-10 5V0z" />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default DateRangeArrow;
