import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cropper from 'react-cropper';
import { useDropzone } from 'react-dropzone';
import bytesToSize from '../utils/bytesToSize';

const DropNCrop = ({
  allowedFileTypes = {
    'image/jpeg': ['.jpeg'],
    'image/jpg': ['.jpg'],
    'image/png': ['.png'],
  },
  canvasHeight = '360px',
  canvasWidth = '100%',
  className,
  cropperOptions = {
    guides: true,
    viewMode: 0,
    autoCropArea: 1,
  },
  instructions,
  maxFileSize = 3145728,
  removeImageText = 'Clear Image',
  showRemoveImage = false,
  onChange,
  value = {},
}) => {
  let cropperRef = useRef(null);

  const dropNCropClasses = {
    'drop-n-crop': true,
    [`${className}`]: className,
  };

  const removeImage = () => {
    onChange({});
  };

  const onCrop = () => {
    const cropper = cropperRef?.current?.cropper;
    if (typeof cropper.getCroppedCanvas() !== 'undefined') {
      onChange({
        ...value,
        result: cropper.getCroppedCanvas().toDataURL(value.filetype),
      });
    }
  };

  const onDropAccepted = files => {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      onChange({
        src: reader.result,
        filename: file.name,
        filetype: file.type,
        result: reader.result,
        error: null,
      });
    };
    reader.readAsDataURL(file);
  };

  const onDropRejected = files => {
    onChange({ error: files[0].errors[0].message });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: allowedFileTypes,
    maxFiles: 1,
    maxSize: maxFileSize,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <div className={classNames(dropNCropClasses)}>
      {value && value.src ? (
        <>
          <Cropper
            ref={cropperRef}
            src={value.src}
            style={{
              height: canvasHeight,
              width: canvasWidth,
            }}
            cropend={onCrop} // Only use the cropend method- it will reduce the callback/setState lag while cropping
            {...cropperOptions}
          />
          {showRemoveImage && (
            <div className="cropper-scaffold__controls">
              <button
                type="button"
                className="button button--red"
                onClick={removeImage}
              >
                {removeImageText}
              </button>
            </div>
          )}
        </>
      ) : (
        <div
          {...getRootProps({
            className: `dropzone ${isDragActive ? 'dropzone--active' : ''}`,
            style: {
              height: canvasHeight,
              width: canvasWidth,
            },
          })}
        >
          <input {...getInputProps()} />
          <div key="dropzone-instructions">
            {!instructions ? (
              <div className="dropzone-instructions">
                <div className="dropzone-instructions--main">
                  Drag-n-drop a file or click to add an image
                </div>
                <div className="dropzone-instructions--sub">
                  Accepted file types:{' '}
                  {Object.values(allowedFileTypes)
                    .map(fileTypes => fileTypes.join(', '))
                    .join(', ')}
                </div>
                <div className="dropzone-instructions--sub">
                  Max file size: {bytesToSize(maxFileSize)}
                </div>
              </div>
            ) : (
              instructions
            )}
          </div>
          {value && value.error ? (
            <div key="dropzone-validation" className="dropzone-validation">
              {value && value.error}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

DropNCrop.propTypes = {
  allowedFileTypes: PropTypes.object,
  canvasHeight: PropTypes.string,
  canvasWidth: PropTypes.string,
  className: PropTypes.string,
  cropperOptions: PropTypes.object,
  instructions: PropTypes.node,
  maxFileSize: PropTypes.number,
  showRemoveImage: PropTypes.bool,
  removeImageText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    src: PropTypes.string,
    result: PropTypes.string,
    filename: PropTypes.string,
    filetype: PropTypes.string,
    error: PropTypes.string,
  }),
};

export default DropNCrop;
