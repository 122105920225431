import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { List } from 'immutable';
import { DisabledBanner } from '../common/DisabledBanner';
import { StyledButton } from '../common/StyledButton';
import { getCredentialStartDate } from '../common/utils/credentials/credential-utils';
import { getGroupCredentials } from './group-credential-actions';
import {
  Body,
  ErrorMessage,
  Header,
  PageContainer,
} from './GroupCredentialAssignmentForm.styles';
import moment from 'moment';
import config from '../config';
import LoadingIndicator from '../common/LoadingIndicator';
import NotificationBanner from '../notification/NotificationBanner';
import FileSaver from 'file-saver';
import CredentialTags from './components/CredentialTags';
import Personnel from './components/Personnel';

const GroupCredentialAssignmentForm = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const data = useSelector(state =>
    state.groupCredential.requests.getIn(['data']),
  );
  const department = data?.get('department');
  const contacts =
    data?.get('contacts')?.filter(c => c.get('is_group')) ?? List();
  const requests = data?.get('requests');
  const closeDate = data?.get('closeDate');
  const relationships = data?.get('relationships');
  const optionalFields = data?.get('optionalFields');

  const [closeDatePassed, setCloseDatePassed] = useState(false);
  const [error, setError] = useState(null);
  const globalError = useSelector(state => state.error);

  useEffect(() => {
    dispatch(getGroupCredentials(params.token)).then(action => {
      const {
        json: { closeDate },
      } = action;
      if (moment().isAfter(moment(closeDate))) {
        setCloseDatePassed(true);
      } else if (!action.response.ok) {
        setError(action.json.message);
      }
    });
  }, [dispatch, params.token]);

  const createCSV = () => {
    fetch(`${config.apiUrl}group-credentials/csv/${params.token}`, {
      method: 'GET',
    })
      .then(response => response.blob())
      .then(blob => {
        FileSaver.saveAs(
          blob,
          `available_credentials_${department.get('slug')}.csv`,
        );
      });
  };

  // Can edit contact if no tags have been issued yet
  const canEdit = contact =>
    Boolean(contact.get('is_group')) &&
    !contact
      .get('pulseOrders')
      .reduce((acc, order) => acc || order.get('pulseTags').size > 0, false);

  const sortRequests = (request1, request2) => {
    const category1 = request1.getIn([
      'credential',
      'credentialType',
      'category',
      'name',
    ]);
    const category2 = request2.getIn([
      'credential',
      'credentialType',
      'category',
      'name',
    ]);

    if (category1 < category2) {
      return -1;
    }
    if (category1 > category2) {
      return 1;
    }

    const credentialType1 = request1.getIn([
      'credential',
      'credentialType',
      'name',
    ]);
    const credentialType2 = request2.getIn([
      'credential',
      'credentialType',
      'name',
    ]);

    if (credentialType1 < credentialType2) {
      return -1;
    }
    if (credentialType1 > credentialType2) {
      return 1;
    }

    const credentialStartDate1 = getCredentialStartDate(
      request1.get('credential'),
    );
    const credentialStartDate2 = getCredentialStartDate(
      request2.get('credential'),
    );

    if (credentialStartDate1 < credentialStartDate2) {
      return -1;
    }
    if (credentialStartDate1 > credentialStartDate2) {
      return 1;
    }

    return 0;
  };

  if (globalError || error) {
    return (
      <ErrorMessage>
        <h1>{globalError ?? error}</h1>
        <h3>Please contact your administrator</h3>
      </ErrorMessage>
    );
  }

  if (!requests) {
    return <LoadingIndicator />;
  }

  const event = department.get('event');

  return (
    <PageContainer>
      <NotificationBanner />
      {closeDatePassed && (
        <DisabledBanner message="The lock date has passed; no further updates can be submitted. Please contact the event coordinator for assistance." />
      )}
      <Header>
        <h1>{event.get('name')}</h1>
        {closeDate && (
          <h2>Due by {moment(closeDate).format('MM/DD/YYYY h:mm A')}</h2>
        )}
      </Header>
      <Body>
        <CredentialTags
          department={department}
          requests={requests}
          contacts={contacts}
          sortRequests={sortRequests}
        />
        <StyledButton
          title="Download as CSV "
          color="blue"
          onClick={createCSV}
          style={{ marginLeft: 'auto' }}
        />
        <Personnel
          token={params.token}
          department={department}
          contacts={contacts}
          requests={requests}
          optionalFields={optionalFields}
          relationships={relationships}
          canEdit={canEdit}
          closeDatePassed={closeDatePassed}
        />
      </Body>
    </PageContainer>
  );
};

export default GroupCredentialAssignmentForm;
