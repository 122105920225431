import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 250px repeat(${props => props.columns}, 320px);
`;

export const HeaderColumn = styled.div`
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.color ? `${props.color}33` : 'transparent'};
  margin: 5px 10px;

  > div.contact-credentials__display > span:hover {
    cursor: pointer;
    background-color: #4183d720;
  }
`;

export const PeriodHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: middle;
  align-items: center;
  background: #8c99a8;
  line-height: 1.2;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 5px 10px;
  margin: 10px;

  > div:nth-child(1) {
    text-transform: uppercase;
  }

  > div:nth-child(2) {
    font-size: smaller;
    font-weight: normal;
    padding-top: 2px;
  }
`;
