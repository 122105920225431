import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import { getMediaValues } from '../common/application/application-selectors';
import { showNotification } from '../notification/notification-actions';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { renderApplicationPublicFormStatus } from '../common/application/application-helpers';
import {
  getMediaApplicationPublic,
  saveMediaApplicationPublic,
  updateMediaApplicationSectionPublic,
} from './media-actions';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DEFAULT_TIMEZONE from '../lib/default-timezone';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import LegalNotice from '../common/application/LegalNotice';
import NotificationBanner from '../notification/NotificationBanner';
import ApplicationPublicForm from '../common/application/ApplicationPublicForm';

const MediaApplicationPublicPage = ({ isPreview = false }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const application = useSelector(state =>
    state.media.applicationPublic.get('loaded')
      ? state.media.applicationPublic.get('data')
      : null,
  );

  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(getMediaApplicationPublic(params.applicationToken)).then(
      action => {
        if (!action.response.ok) setError(true);
      },
    );
  }, [dispatch, params.applicationToken]);

  const handleSubmit = values => {
    const payload = getMediaValues(values, sections);

    return dispatch(
      saveMediaApplicationPublic(params.applicationToken, payload),
    ).then(action => {
      setSubmitSucceeded(action.response.ok);
      if (!action.response.ok) {
        if (action.json.validationErrors) {
          const errors = formatValidationErrors({
            validationErrors: action.json.validationErrors.filter(
              ({ type }) => !type || type !== 'customFields',
            ),
          }).toJS();

          if (!!errors.personnel) {
            errors.personnel = { _error: errors.personnel[0] };
          }

          return errors;
        } else {
          dispatch(
            showNotification({
              status: 'error',
              message: `Error submitting Media Application: ${action.json.message}`,
            }),
          );
          return { [FORM_ERROR]: action.json.message };
        }
      }
    });
  };

  if (error) {
    return <NotFound />;
  }

  if (!application) {
    return <LoadingIndicator />;
  }

  const title = 'Media Outlets';
  const event = application.get('event');
  const sections = application.get('sections');

  const legalNotice = sections?.find(
    section => section.get('key') === 'legal-notice',
  );

  const closeDate = moment(
    params.applicationToken === application.get('override_link_token')
      ? application.get('override_close_date')
      : application.get('close_date'),
  ).tz(event ? event.get('time_zone') : DEFAULT_TIMEZONE);

  if (!isPreview) {
    const applicationStatus = renderApplicationPublicFormStatus(
      title,
      event,
      application,
      params.applicationToken,
      submitSucceeded,
    );
    if (applicationStatus) {
      return applicationStatus;
    }
  }

  const headerImageLocation = application.getIn(['headerImage', 'location']);

  const backgroundStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  if (application.get('backgroundImage')) {
    backgroundStyle.backgroundImage = `url(${application.getIn([
      'backgroundImage',
      'location',
    ])}`;

    backgroundStyle.backgroundRepeat = application.get('background_image_tiled')
      ? 'repeat'
      : 'no-repeat';

    if (!application.get('background_image_tiled')) {
      backgroundStyle.backgroundSize = 'cover';
    }
  }

  return (
    <div style={backgroundStyle}>
      <NotificationBanner />
      {application && (
        <h1 className="public-application__header">{event.get('name')}</h1>
      )}
      <div className="public-application__body">
        <section className="public-application__section public-application__section--textcenter">
          <header style={{ margin: '0px' }}>
            <div className="public-application__section--title">
              {`Application for ${title}${
                isPreview ? ' - PREVIEW VERSION' : ''
              }`}
            </div>
            <div className="public-application__section--closedate">
              Due by {closeDate.format('MM/DD/YYYY hh:mmA z')}
            </div>
          </header>
        </section>

        {headerImageLocation && (
          <section className="public-application__section">
            <figure className="public-application__image">
              <img src={headerImageLocation} alt="Header" />
            </figure>
          </section>
        )}

        {legalNotice && !disclaimerAccepted ? (
          <LegalNotice
            legalNotice={legalNotice}
            onViewApplication={() => setDisclaimerAccepted(true)}
          />
        ) : (
          <ApplicationPublicForm
            event={event}
            application={application}
            applicationToken={params.applicationToken}
            disabled={isPreview}
            onSubmit={handleSubmit}
            updateSection={(...args) =>
              dispatch(updateMediaApplicationSectionPublic(...args))
            }
          />
        )}
      </div>
    </div>
  );
};

MediaApplicationPublicPage.propTypes = {
  isPreview: PropTypes.bool,
};

export default MediaApplicationPublicPage;
