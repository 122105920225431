import PropTypes from 'prop-types';
import Icon from '../icons/Icon';
import CheckboxBoolean from './CheckboxBoolean';
import QuantityField from './QuantityField';

const CredentialQuantityField = ({
  id,
  value,
  max,
  skipCheckbox = false,
  onChange,
}) => {
  const addOne = () => {
    const newValue = max ? Math.min(max, value + 1) : value + 1;
    onChange(newValue);
  };

  const renderCheckbox = () => (
    <div className="quantity-field">
      <div className="quantity-button__spacer" />
      <CheckboxBoolean
        id={id}
        onChange={onChange}
        value={!!value}
        checkedIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
          </svg>
        }
        uncheckedIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
          </svg>
        }
      />
      {value === 1 ? (
        <button className="quantity-button" onClick={addOne}>
          <Icon icon="Plus" />
        </button>
      ) : (
        <div className="quantity-button__spacer" />
      )}
    </div>
  );

  const renderQuantityField = () => (
    <QuantityField
      max={max}
      value={value}
      maxHidden={max === null}
      maxValueTemplate="Available: {value}"
      onChange={onChange}
    />
  );

  return (
    <div className="credential-quantity-field">
      {(value === 0 || value === 1) && !skipCheckbox
        ? renderCheckbox()
        : renderQuantityField()}
    </div>
  );
};

CredentialQuantityField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  skipCheckbox: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default CredentialQuantityField;
