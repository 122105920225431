import client from '../client';

export const SUBMIT_PROMOTER = 'SUBMIT_PROMOTER';
export function submitPromoter(promoter) {
  const data = new FormData();
  if (promoter.file_id) data.append('file_id', promoter.file_id);
  if (promoter.file) data.append('file', promoter.file, promoter.file.name);
  if (promoter.pulseClientManagerUserId)
    data.append(
      'pulse_client_manager_user_id',
      promoter.pulseClientManagerUserId,
    );
  if (promoter.pulse_client_id)
    data.append('pulse_client_id', promoter.pulse_client_id);
  data.append('name', promoter.name);

  return {
    type: SUBMIT_PROMOTER,
    promise: promoter.id
      ? client.put(`promoters/${promoter.id}`, data)
      : client.post('promoters', data),
  };
}

export const FETCH_PROMOTER_LIST = 'FETCH_PROMOTER_LIST';
export function fetchPromoterList() {
  return {
    type: FETCH_PROMOTER_LIST,
    promise: client.get('promoters'),
  };
}

export const INVITE_PROMOTER_ADMIN = 'INVITE_PROMOTER_ADMIN';
export function invitePromoterAdmin(promoterId, payload) {
  return {
    type: INVITE_PROMOTER_ADMIN,
    promise: client.post(`users/promoter-admin/${promoterId}`, payload),
  };
}

export const INVITE_PROMOTER_ADMIN_RESET = 'INVITE_PROMOTER_ADMIN_RESET';
export function clearNewPromoterAdmin() {
  return {
    type: INVITE_PROMOTER_ADMIN_RESET,
  };
}

export const FETCH_PROMOTER_ADMIN_LIST = 'FETCH_PROMOTER_ADMIN_LIST';
export function fetchPromoterAdminList(promoterId) {
  return {
    type: FETCH_PROMOTER_ADMIN_LIST,
    promise: client.get(`users/promoter-admin/${promoterId}`),
  };
}

export const FETCH_PROMOTER_ADMIN_LIST_RESET =
  'FETCH_PROMOTER_ADMIN_LIST_RESET';
export function clearPromoterAdminList() {
  return {
    type: FETCH_PROMOTER_ADMIN_LIST_RESET,
  };
}

export const FETCH_PULSE_CLIENT_LIST = 'FETCH_PULSE_CLIENT_LIST';
export function fetchPulseClientList() {
  return {
    type: FETCH_PULSE_CLIENT_LIST,
    promise: client.get('pulse/clients'),
  };
}

export const FETCH_PULSE_CLIENT_LIST_RESET = 'FETCH_PULSE_CLIENT_LIST_RESET';
export function clearPulseClientList() {
  return {
    type: FETCH_PULSE_CLIENT_LIST_RESET,
  };
}
