import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getCurrentPromoter } from '../promoter/promoter-selectors';
import { getCurrentFestival } from '../festival/festival-selectors';
import Icon from '../common/icons/Icon';
import Paper from '../common/paper/Paper';
import PaperHeader from '../common/paper/PaperHeader';
import PaperBody from '../common/paper/PaperBody';
import LoadingIndicator from '../common/LoadingIndicator';

const EventList = () => {
  const params = useParams();

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const festival = useSelector(state => getCurrentFestival(state, { params }));

  const eventList = useMemo(() => festival?.get('events'), [festival]);

  const renderList = () => {
    return (
      <span>
        <Paper className="generic-list">
          <PaperHeader
            backTo={`/promoters/${promoter.get('slug')}/festivals`}
            title={`${festival.get('name')} Events`}
          />
          <PaperBody>
            <p>Select an event to view from the list below.</p>
          </PaperBody>
        </Paper>
        <div className="generic-list__body">
          <div>
            {eventList.map(event => (
              <Link
                key={event.get('id')}
                className="generic-list__item generic-list__item--link"
                to={`/promoters/${promoter.get(
                  'slug',
                )}/festivals/${festival.get('slug')}/events/${event.get(
                  'slug',
                )}`}
              >
                <div className="content">
                  <span>{event.get('name')}</span>
                </div>
                <div className="action">
                  <Icon icon="ArrowLeft" />
                </div>
              </Link>
            ))}
          </div>
        </div>
      </span>
    );
  };

  const renderEmpty = () => {
    return (
      <Paper className="generic-list--empty">
        <Icon icon="Sad" />
        <p>You have not yet added any events for this festival.</p>
        <Link
          className="button"
          to={`/promoters/${promoter.get('slug')}/festivals`}
        >
          Back
        </Link>
      </Paper>
    );
  };

  return !eventList ? (
    <LoadingIndicator />
  ) : eventList.size ? (
    renderList()
  ) : (
    renderEmpty()
  );
};

export default EventList;
