import moment from 'moment';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import InputScaffold from '../../forms/InputScaffold';
import translateErrors from '../translateErrors';

const Time = ({ field, onBlur, onChange }) => {
  const _onChange = (key, value) => {
    if (key === 'value' && moment.isMoment(value)) {
      onChange(field.set(key, value.unix()));
    } else {
      onChange(field.set(key, value));
    }
  };

  return (
    <InputScaffold
      label={field.get('label')}
      validation={translateErrors(field.getIn(['error', 'value']))}
      required={!!field.get('required')}
    >
      <div className="timepicker-container">
        <TimePicker
          name="value"
          value={
            (field.get('value') && moment.unix(field.get('value'))) || null
          }
          onBlur={onBlur}
          onChange={momentDate => {
            _onChange('value', momentDate);
          }}
          prefixCls="timepicker"
          showSecond={false}
          use12Hours
        />
      </div>
    </InputScaffold>
  );
};

Time.propTypes = {
  field: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default Time;
