import get from 'lodash/get';
import roleConstants from '../../../lib/acl/role-constants';
import resourceConstants from '../../../lib/acl/resource-constants';

const departmentUserAccess = acl => {
  acl.allow(
    roleConstants.DEPARTMENT_USER,
    resourceConstants.EVENT,
    ['view', 'view-credential-requests-summary'],
    (err, user, resource, action, result, next) => {
      if (err) {
        throw err;
      }

      if (
        user.permissionsMap.departments.some(d => d.event_id === resource.id)
      ) {
        result(null, true);
      } else {
        next();
      }
    },
  );

  acl.allow(
    roleConstants.DEPARTMENT_USER,
    resourceConstants.EVENT,
    [
      'view-artist',
      'view-food_vendor',
      'view-guestlist',
      'view-intern',
      'view-media',
      'view-production',
      'view-sponsor',
      'view-vendor',
      'view-department-artist',
      'view-department-food_vendor',
      'view-department-guestlist',
      'view-department-intern',
      'view-department-media',
      'view-department-production',
      'view-department-sponsor',
      'view-department-vendor',
    ],
    (err, user, resource, action, result, next) => {
      if (err) {
        throw err;
      }
      const eventId = resource.id;
      const deptType = action.split('-').pop();

      if (user.roles.indexOf(roleConstants.DEPARTMENT_USER) !== -1) {
        const hasAccessToDeptType = user.permissionsMap.departments.reduce(
          (hasAccess, { event_id, type }) =>
            hasAccess || (event_id === eventId && type === deptType),
          false,
        );
        if (hasAccessToDeptType) {
          result(null, true);
        } else {
          next();
        }
      } else {
        next();
      }
    },
  );

  acl.allow(
    roleConstants.DEPARTMENT_USER,
    resourceConstants.DEPARTMENT,
    [
      'view',
      'admin-view',
      'edit',
      'request-credential',
      'request-catering',
      'view-reports-credential',
      'view-reports-catering',
    ],
    (err, user, resource, _action, result, next) => {
      if (err) {
        throw err;
      }
      const departments = get(user, 'permissionsMap.departments', []);

      if (
        departments.reduce(
          (hasAccess, { id }) => hasAccess || id === resource.id,
          false,
        )
      ) {
        result(null, true);
      } else {
        next();
      }
    },
  );
};

export default departmentUserAccess;
