import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import { resource, useACL } from '../common/ACL';
import {
  getCategories,
  getCredentialTypes,
  getCredentials,
} from '../credential/type/credential-type-selectors';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactTable from '../lib/react-table';
import Icon from '../common/icons/Icon';
import StatusButton from '../common/StatusButton';
import ApproveOnsiteRequestModal from '../common/ApproveOnsiteRequestModal';
import DeclineOnsiteRequestModal from '../common/DeclineOnsiteRequestModal';
import OnsiteRequestDetails from '../onsite/OnsiteRequestDetails';

const aclRules = {
  canApproveOnsiteRequests: [resource.DEPARTMENT, 'approve-credential'],
};

const ContactOnsiteRequests = ({ contact, disabled = false }) => {
  const acl = useACL(aclRules);

  const categories = useSelector(getCategories);
  const credentialTypes = useSelector(getCredentialTypes);
  const credentials = useSelector(getCredentials);

  const onsiteRequests = contact.get('onsiteRequests');

  const [expanded, setExpanded] = useState({});

  const renderRequestsTable = () => {
    const contactJS = contact.toJS();

    const data = onsiteRequests.toJS().map(request => {
      const data = request;
      data['requestedFor'] = contactJS;

      return {
        data,
        id: request.id,
        type: request.type,
        status: request.status,
        canApprove: Boolean(request.can_approve),
        canDecline: Boolean(request.can_decline),
        requestedOn: request.requested_on,
        requestedBy: `${request.requestedBy.first_name} ${request.requestedBy.last_name}`,
        requestData: request.request_data,
        requestNotes: request.request_notes,
        adminNotes: request.admin_notes,
        reviewedOn: request.reviewed_on,
        reviewedBy: request.reviewedBy
          ? `${request.reviewedBy.first_name} ${request.reviewedBy.last_name}`
          : null,
      };
    });

    const columns = [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Requested On',
        accessor: 'requestedOn',
        defaultSortDesc: true,
        sortMethod: (date1, date2) => {
          const date1Moment = moment(date1);
          const date2Moment = moment(date2);

          if (date1Moment.isBefore(date2Moment)) {
            return -1;
          }

          if (date1Moment.isAfter(date2Moment)) {
            return 1;
          }

          return 0;
        },
        Cell: row => (
          <span>{`${moment(row.value).format('MM/DD/YYYY h:mm A')}`}</span>
        ),
      },
      {
        Header: 'Requested By',
        accessor: 'requestedBy',
      },
      {
        Header: () => null,
        Cell: ({ original: request }) => {
          if (request.status !== 'Pending') return null;
          return (
            <div className="onsite-request__action-buttons">
              <ApproveOnsiteRequestModal
                onsiteRequest={request}
                trigger={
                  <StatusButton
                    disabled={disabled || !request.canApprove}
                    type="button"
                    buttonText="Approve"
                    className="onsite-request__action-button"
                  />
                }
              />
              <DeclineOnsiteRequestModal
                onsiteRequest={request}
                trigger={
                  <StatusButton
                    disabled={disabled || !request.canDecline}
                    type="button"
                    buttonText="Decline"
                    className="onsite-request__action-button"
                  />
                }
              />
            </div>
          );
        },
      },
    ];

    return (
      <ReactTable
        data={data}
        columns={columns}
        minRows={0}
        defaultPageSize={100}
        defaultSorted={[
          {
            id: 'requestedOn',
            desc: true,
          },
        ]}
        showPagination={data.length > 100}
        showPageSizeOptions
        expanded={expanded}
        onExpandedChange={newExpanded => setExpanded(newExpanded)}
        SubComponent={({ original: request }) => (
          <OnsiteRequestDetails
            onsiteRequest={request}
            categories={categories}
            credentialTypes={credentialTypes}
            credentials={credentials}
          />
        )}
        NoDataComponent={() => (
          <div className="generic-list--empty">
            <Icon icon="Sad" />
            <p>There are no results to display.</p>
          </div>
        )}
      />
    );
  };

  if (
    !acl.canApproveOnsiteRequests ||
    !onsiteRequests ||
    onsiteRequests.size === 0
  )
    return null;

  return (
    <div className="contact-details-section">
      <div className="contact-details-section__header">
        <Icon icon="ListCheck" />
        Onsite Requests
      </div>
      <div className="contact-details-section__content">
        <div style={{ padding: '10px' }}>{renderRequestsTable()}</div>
      </div>
    </div>
  );
};

ContactOnsiteRequests.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  disabled: PropTypes.bool,
};
export default ContactOnsiteRequests;
