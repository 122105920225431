import { combineReducers } from 'redux';
import Immutable from 'immutable';
import getApplicationReducers from '../common/application/get-application-reducers';
import getApiReducer, { defaultState } from '../common/utils/getApiReducer';

const applicationReducers = getApplicationReducers('advance');

const getCateringAvailability = (state = Immutable.Map(), action) => {
  let newState = state;

  if (action.originalType === `GET_ADVANCE_APPLICATION_CATERING_AVAILABILITY`) {
    newState = getApiReducer(action.originalType)(newState, action);
  }
  return newState;
};

const departmentInfoReducer = (state = defaultState, action) => {
  let newState = state;

  if (action.originalType === `GET_ADVANCE_APPLICATION_DEPARTMENT_INFO`) {
    newState = getApiReducer(`GET_ADVANCE_APPLICATION_DEPARTMENT_INFO`)(
      newState,
      action,
    );
  }
  return newState;
};

export default combineReducers({
  ...applicationReducers,
  catering: getCateringAvailability,
  departmentInfo: departmentInfoReducer,
});
