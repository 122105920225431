import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchPromoterList } from './promoter-actions';

const PromoterLoader = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPromoterList());
  }, [dispatch]);

  return <Outlet />;
};

export default PromoterLoader;
