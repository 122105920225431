import client, { publicClient } from '../client';

export const SUBMIT_FILE = 'SUBMIT_FILE';
export function submitFile(file) {
  const data = new FormData();
  data.append('file', file);

  return {
    type: SUBMIT_FILE,
    promise: client.post('files', data),
  };
}

export function applicationUpload(applicationToken, file) {
  const data = new FormData();
  data.append('file', file);

  return {
    type: SUBMIT_FILE,
    promise: publicClient.post(`application-uploads/${applicationToken}`, data),
  };
}

export const SUBMIT_FILE_RESET = 'SUBMIT_FILE_RESET';
export function resetFileSubmit() {
  return {
    type: SUBMIT_FILE_RESET,
  };
}
