import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from './icons/Icon';

const ExpandableRow = ({
  caretSpace = false,
  children,
  className,
  columns,
  expandedHeaderStyle,
  hasBoxShadow = false,
  headerStyle,
  isExpandable,
  isExpanded,
  onToggleExpansion,
  renderChildrenWhenNotExpanded = false,
}) => {
  const expandableRowClasses = {
    [className]: !!className,
    'expandable-row': true,
  };

  const dropdownClasses = {
    'expandable-row__dropdown': true,
    'expandable-row__dropdown--expanded': isExpanded,
  };

  return (
    <div className="expandable-row__container">
      <div
        onClick={isExpandable ? onToggleExpansion : null}
        style={
          hasBoxShadow ? { boxShadow: 'inset 8px 0px 0px 0px #4183D7' } : null
        }
      >
        <div
          className={classNames(expandableRowClasses)}
          style={isExpanded ? expandedHeaderStyle : headerStyle}
        >
          {columns.map((column, i) => (
            <div key={i} className="expandable-row__column">
              {column}
            </div>
          ))}
          {isExpandable ? (
            <div className={classNames(dropdownClasses)}>
              <Icon icon="Caret" />
            </div>
          ) : caretSpace ? (
            <div className={classNames(dropdownClasses)} />
          ) : null}
        </div>
      </div>
      {isExpanded ? (
        <div className="expandable-row__expanded-content">{children}</div>
      ) : renderChildrenWhenNotExpanded ? (
        <div
          style={{ display: 'none' }}
          className="expandable-row__expanded-content"
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

ExpandableRow.propTypes = {
  caretSpace: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.node),
  expandedHeaderStyle: PropTypes.object,
  hasBoxShadow: PropTypes.bool,
  headerStyle: PropTypes.object,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  onToggleExpansion: PropTypes.func,
};

export default ExpandableRow;
