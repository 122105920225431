import React from 'react';

function Megaphone() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M19.143 15.842V5.292a17.463 17.463 0 0 1-8.57 3.793v2.986a17.51 17.51 0 0 1 8.57 3.772zm1.43-6.68a1.383 1.383 0 0 1 1.01.415 1.406 1.406 0 0 1 0 2 1.383 1.383 0 0 1-1.01.415v4.248a1.354 1.354 0 0 1-.425 1 1.38 1.38 0 0 1-1 .42q-4.654-3.837-9.063-4.2a1.94 1.94 0 0 0-1.016.73 1.767 1.767 0 0 0-.347 1.11 1.5 1.5 0 0 0 .446 1.024 1.687 1.687 0 0 0-.258.724 1.486 1.486 0 0 0 .067.64 2.05 2.05 0 0 0 .373.61 4.834 4.834 0 0 0 .536.552q.26.227.685.558a1.913 1.913 0 0 1-1.244.918 4.21 4.21 0 0 1-1.88.127 2.79 2.79 0 0 1-1.473-.614q-.078-.255-.33-.97t-.356-1.044q-.105-.332-.256-.984a6.06 6.06 0 0 1-.167-1.117A8.858 8.858 0 0 1 4.9 14.63a5.7 5.7 0 0 1 .246-1.22h-1.36a1.727 1.727 0 0 1-1.26-.52A1.7 1.7 0 0 1 2 11.64V9.515a1.7 1.7 0 0 1 .525-1.25 1.727 1.727 0 0 1 1.26-.52h5.358q4.855 0 10-4.247a1.38 1.38 0 0 1 1 .42 1.354 1.354 0 0 1 .424 1z" />
    </svg>
  );
  /* eslint-enable */
}

export default Megaphone;
