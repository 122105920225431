const formatTitleFromSnakeCase = field =>
  field
    .replace(/_/g, ' ')
    .split(' ')
    .map(word => word.substr(0, 1).toUpperCase() + word.substr(1))
    .join(' ');

export const generateTitleAndColumns = (report_type, dataLabels) => {
  const title = formatTitleFromSnakeCase(report_type);
  const sortedColumns = [];
  Object.entries(dataLabels).forEach(([key, value]) => {
    sortedColumns[+key] = value;
  });
  const mappedColumns = sortedColumns.map(column => {
    const [key, value] = Object.entries(column)[0];
    return {
      Header: value,
      accessor: key,
    };
  });
  return {
    title,
    mappedColumns,
  };
};
