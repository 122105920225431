import { Toggle } from 'synfrastructure';
import ToggleScaffold from '../forms/ToggleScaffold';
import SwitchOn from '../icons/SwitchOn';
import SwitchOff from '../icons/SwitchOff';

const Switch = props => (
  <ToggleScaffold label={props.label} className="toggle--switch">
    <Toggle
      id={props.input.name}
      type="checkbox"
      checked={!!props.input.value}
      onChange={() => {
        props.input.onChange(!props.input.value);
      }}
      disabled={props.disabled || props.input.disabled}
      checkedIcon={<SwitchOn />}
      uncheckedIcon={<SwitchOff />}
    />
  </ToggleScaffold>
);

export default Switch;
