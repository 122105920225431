import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { resource, useACL } from '../common/ACL';
import { getCurrentEventSettings } from '../event/event-selectors';
import styled from 'styled-components';
import departmentIconMap from '../department/department-icon-map';
import departmentTypeList from '../lib/department-types';
import departmentTypeMap from '../lib/department-type-map';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  margin-bottom: 20px;
  flex-direction: row;
`;

const UploadContainer = styled.div`
  display: flex;
  width: 100%;
  height: 54px;
  border: 1px solid #ccc;
  margin-bottom: 12px;
  background-color: #fff;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
  margin-left: 20px;
`;

const Description = styled.div`
  display: flex;
  opacity: 0.5;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 16px;
`;

const Button = styled.div`
  cursor: pointer;
`;

const aclRules = {
  canManageCatering: [resource.EVENT, 'manage-catering'],
};

departmentTypeList.forEach(section => {
  aclRules[`canBulkUpload-${section}`] = [
    resource.EVENT,
    `bulkcreate-${section}`,
  ];
});

const Upload = ({ url, icon, title, description }) => {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => {
        navigate(url);
      }}
    >
      <UploadContainer>
        <Icon icon={icon} />
        <Row>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Row>
      </UploadContainer>
    </Button>
  );
};

const DepartmentUpload = ({ section }) => {
  const acl = useACL(aclRules);

  const params = useParams();

  const eventSettings = useSelector(state =>
    getCurrentEventSettings(state, { params }),
  );

  const sectionEnabled = eventSettings?.getIn([
    section,
    'settings',
    'section_enabled',
  ]);

  if (!sectionEnabled || !acl[`canBulkUpload-${section}`]) return null;

  return (
    <Upload
      icon={departmentIconMap[section]}
      title={departmentTypeMap[section].label.plural}
      description={`Upload ${departmentTypeMap[section].label.singular} Departments`}
      url={`../../${section}/~upload`}
    />
  );
};

const UploadsContainer = () => {
  const acl = useACL(aclRules);

  const uploads = [];

  if (acl.canManageCatering)
    uploads.push(
      <Upload
        key="catering"
        url="../../catering/upload"
        icon="Food"
        title="Catering"
        description="Upload Catering"
      />,
    );

  departmentTypeList.forEach(section => {
    const departmentUpload = <DepartmentUpload section={section} />;
    if (departmentUpload) uploads.push(departmentUpload);
  });

  return (
    <Container>
      <Header>
        <Paper>
          <PaperHeader title="Event Uploads" />
          {uploads.length === 0 ? (
            <div className="generic-list--empty">
              <Icon icon="Sad" />
              <p>No Event Uploads are available</p>
            </div>
          ) : null}
        </Paper>
      </Header>
      {uploads}
    </Container>
  );
};

export default UploadsContainer;
