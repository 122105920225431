import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Map } from 'immutable';
import {
  combineValidators,
  composeValidators,
  isRequired,
  isRequiredIf,
} from 'revalidate';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import { getStatusForButton } from '../common/forms/FormHelper';
import { submitContact } from './contact-actions';
import {
  UnsavedCredentialsProvider,
  selectUnsavedCredentialsWithQuantity,
  useUnsavedCredentials,
  useUnsavedCredentialsDispatch,
} from './UnsavedCredentials';
import { useDepartmentUnsavedCredentialsDispatch } from './DepartmentUnsavedCredentials';
import { NO_PREFERENCE, VEGAN, VEGETARIAN } from '../lib/meal-preferences';
import PropTypes from 'prop-types';
import isValidPhone from '../common/validate/is-valid-phone';
import isValidEmail from '../common/validate/is-valid-email';
import phoneFilter from '../common/filters/phone-filter';
import Icon from '../common/icons/Icon';
import StatusButton from '../common/StatusButton';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import ContactCredentials from './ContactCredentials';
import ContactInfoForm from './ContactInfoForm';

const filters = {
  email: value => value.trim(),
  phone: phoneFilter,
  relationship: value => value.trim(),
};

const ContactCreateForm = ({
  department,
  deptSettings,
  onSuccess,
  onCancel,
}) => {
  const dispatch = useDispatch();

  const departmentUnsavedCredentialsDispatch =
    useDepartmentUnsavedCredentialsDispatch();

  const unsavedCredentialsDispatch = useUnsavedCredentialsDispatch();
  const unsavedCredentials = selectUnsavedCredentialsWithQuantity(
    useUnsavedCredentials(),
  );

  const initialValues = {
    first_name: '',
    last_name: '',
    is_primary: false,
    email: null,
    phone: null,
    other_role: null,
    relationship: null,
    meal_preference: NO_PREFERENCE,
  };

  const validate = combineValidators({
    first_name: isRequired('First Name'),
    last_name: isRequired('Last Name'),
    phone: isValidPhone('Phone'),
    email: composeValidators(
      department.get('require_contact_emails')
        ? isRequired
        : isRequiredIf(values => values.is_primary),
      isValidEmail,
    )('Email'),
  });

  const isSubmitDisabled = (pristine, invalid, submitting, submitFailed) =>
    pristine ||
    invalid ||
    submitting ||
    submitFailed ||
    unsavedCredentials.length === 0;

  const clearDepartmentUnsavedCredentials = () => {
    departmentUnsavedCredentialsDispatch({
      type: 'subtract',
      changedQuantities: unsavedCredentials.map(unsavedCredential => ({
        credentialId: unsavedCredential.id,
        quantity: unsavedCredential.quantity,
      })),
    });
  };

  const onClose = () => {
    onCancel();
    clearDepartmentUnsavedCredentials();
  };

  const displayErrors = action => {
    const errors = action.json.errors;
    if (errors?.length) {
      const message = errors
        .map(error => error.error)
        .reduce((acc, error) => (error ? `${acc}${error}.\n` : acc), '');
      dispatch(showNotification({ message, duration: 60000, status: 'error' }));
    }
  };

  const handleSubmit = (values, { change }) => {
    const payload = {};
    Object.keys(values).forEach(key => {
      if (filters[key] && values[key]) {
        payload[key] = filters[key](values[key]);
        change(key, payload[key]);
      } else {
        payload[key] = values[key];
      }
    });

    payload.credentials = unsavedCredentials;

    if (payload.credentials.length === 0) {
      const errorMessage = 'One credential must be requested';
      dispatch(showNotification({ message: errorMessage, status: 'error' }));
      return { [FORM_ERROR]: errorMessage };
    }

    return dispatch(submitContact(department.get('id'), payload)).then(
      action => {
        if (action.response.ok) {
          onSuccess(action.json.id);
          displayErrors(action);
          clearDepartmentUnsavedCredentials();
          unsavedCredentialsDispatch({ type: 'clear' });
        } else {
          return formatValidationErrors(action.json).toJS();
        }
      },
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({
        handleSubmit,
        invalid,
        pristine,
        submitting,
        submitFailed,
      }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <div className="add-new-contact">
              <div className="add-new-contact__inputs">
                <span className="add-new-contact__name">
                  <Field
                    name="first_name"
                    placeholder="First Name"
                    required
                    component={ReduxFormsFieldNoLabel}
                  >
                    <input type="text" autoFocus />
                  </Field>
                </span>
                <span className="add-new-contact__name">
                  <Field
                    name="last_name"
                    placeholder="Last Name"
                    required
                    component={ReduxFormsFieldNoLabel}
                  >
                    <input type="text" />
                  </Field>
                </span>
              </div>
              <div className="add-new-contact__cancel">
                <button type="button" onClick={onClose}>
                  <Icon icon="Close" />
                </button>
              </div>
            </div>
            <div
              className="expandable-row__expanded-content"
              style={{ backgroundColor: 'white' }}
            >
              <div className="contact-details-section">
                <div className="contact-details-section__header">
                  <Icon icon="Person" />
                  Contact Information
                </div>
                <div className="contact-details-section__content">
                  <ContactInfoForm
                    department={department}
                    relationships={deptSettings?.get('relationships')}
                  />
                </div>
              </div>
              {deptSettings?.get('catering') && (
                <div className="contact-details-section">
                  <div className="contact-details-section__header contact-details-section__subtext">
                    <div>
                      <Icon icon="Food" />
                      Catering
                    </div>
                  </div>
                  <div className="contact-details-section__content">
                    <div className="contact-catering catering-row">
                      <div className="catering-row__meal">
                        <div className="contact-credentials__select">
                          <Field
                            name="meal_preference"
                            component={ReduxFormsFieldNoLabel}
                            required
                          >
                            <select>
                              <option value={NO_PREFERENCE}>
                                No Preference
                              </option>
                              <option value={VEGAN}>Vegan</option>
                              <option value={VEGETARIAN}>Vegetarian</option>
                            </select>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <ContactCredentials
                department={department}
                style={{ backgroundColor: 'white' }}
              />
              <div
                className="contact-details-section__footer"
                style={{ backgroundColor: 'white' }}
              >
                <StatusButton
                  type="submit"
                  buttonText="Add"
                  disabled={isSubmitDisabled(
                    pristine,
                    invalid,
                    submitting,
                    submitFailed,
                  )}
                  status={getStatusForButton({
                    pristine,
                    submitting,
                    submitFailed,
                  })}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

ContactCreateForm.propTypes = {
  department: PropTypes.instanceOf(Map).isRequired,
  deptSettings: PropTypes.instanceOf(Map).isRequired,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const ContactCreateFormWrapper = props => (
  <UnsavedCredentialsProvider>
    <ContactCreateForm {...props} />
  </UnsavedCredentialsProvider>
);

export default ContactCreateFormWrapper;
