import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { isEmpty } from 'lodash';

const aclRules = {
  canEditEvent: [resource.EVENT, 'edit'],
};

const EventSettingsRedirector = () => {
  const acl = useACL(aclRules);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const redirect = () => {
      const { promoterSlug, festivalSlug, eventSlug } = params;
      let to = `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/settings/reports`;

      if (acl.canEditEvent)
        to = `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/settings/edit`;

      navigate(to, { replace: true });
    };

    if (!isEmpty(acl)) redirect();
  }, [acl, navigate, params]);

  return null;
};

export default EventSettingsRedirector;
