import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCurrentEvent } from './event-selectors';
import { resource, useACL } from '../common/ACL';
import UserList from '../user/UserList';
import {
  deleteUser,
  disableEventAdmin,
  enableEventAdmin,
  resendInvite,
} from '../user/user-actions';

const aclRules = {
  canEdit: [resource.EVENT, 'edit-user'],
  canDelete: [resource.USER, 'delete'],
};

const EventAdminList = () => {
  const acl = useACL(aclRules);
  const location = useLocation();
  const params = useParams();

  const eventId = useSelector(state =>
    getCurrentEvent(state, { params })?.get('id'),
  );
  const userList = useSelector(state => state.event.adminList.get('data'));
  const deleting = useSelector(state => state.user.deleteUser.get('loading'));

  const isDisabled = user =>
    !user.get('eventRoles').find(role => role.get('event_id') === eventId);

  if (!eventId) return null;

  return (
    <UserList
      linkTo={id => `${location.pathname}/${id}`}
      resendInviteAction={resendInvite}
      userType="Section Admin"
      userList={userList}
      deleting={deleting}
      addNewRoute={`${location.pathname}/invite`}
      canInvite={acl.canEdit ?? false}
      isDisabled={isDisabled}
      disableAction={
        acl.canEdit ? userId => disableEventAdmin(eventId, userId) : null
      }
      enableAction={
        acl.canEdit ? userId => enableEventAdmin(eventId, userId) : null
      }
      deleteAction={acl.canDelete ? deleteUser : null}
    />
  );
};

export default EventAdminList;
