import { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { StyledButton } from './StyledButton';
import styled from 'styled-components';

const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 16px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const DeleteContactModal = ({ contact, onDelete, trigger }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const open = e => {
    e.stopPropagation();
    setIsOpen(true);
    setSubmitting(false);
  };

  const close = e => {
    if (e) e.stopPropagation();
    setIsOpen(false);
  };

  const handleDelete = async e => {
    e.stopPropagation();
    setSubmitting(true);
    if (onDelete) await onDelete(contact);
    close();
  };

  return (
    <Modal
      open={isOpen}
      onOpen={open}
      onClose={() => close()}
      size="tiny"
      trigger={trigger}
    >
      <Modal.Header>Are you sure you want to remove this staff?</Modal.Header>
      <Modal.Content>
        <Body>
          <Header>
            {contact.get('is_group')
              ? 'Any assigned credentials will be made available for reassignment.'
              : 'Any requested or assigned credentials will be deleted.'}
          </Header>

          <Footer>
            <StyledButton
              title="Cancel"
              color="gray"
              margin="right"
              handleClick={close}
            />
            <StyledButton
              submitting={submitting}
              title="Confirm"
              color="blue"
              handleClick={handleDelete}
            />
          </Footer>
        </Body>
      </Modal.Content>
    </Modal>
  );
};

export default DeleteContactModal;
