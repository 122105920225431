import { Navigate, Route } from 'react-router-dom';
import { resource } from './common/ACL';
import { ACL404 } from './common/ACL404';

import departmentRoutes from './departmentRoutes';
import departmentTypeList from './lib/department-types';
import departmentTypeMap from './lib/department-type-map';

import { EventReportsContainer } from './reports/ReportsContainer';
import ActionLogPage from './activity/ActionLogPage';
import AdvanceApplicationForm from './advance-application/AdvanceApplicationForm';
import AdvanceApplicationList from './advance-application/AdvanceApplicationList';
import AdvanceApplicationLoader from './advance-application/AdvanceApplicationLoader';
import ApprovalsRedirector from './credential-approvals/ApprovalsRedirector';
import BulkSend from './email/BulkSend';
import BulkSendContainer from './email/BulkSendContainer';
import CateringUpload from './catering/CateringUpload';
import CredentialApprovalsRedirector from './credential-approvals/CredentialApprovalsRedirector';
import CredentialPeriodAdd from './credential/period/PeriodAdd';
import CredentialPeriodEdit from './credential/period/PeriodEdit';
import CredentialPeriodList from './credential/period/PeriodList';
import CredentialRequestApprovalsPage from './credential-approvals/CredentialRequestApprovalsPage';
import CredentialTypeCategoryForm from './credential/type/CredentialTypeCategoryForm';
import CredentialTypeCreate from './credential/type/CredentialTypeCreate';
import CredentialTypeEdit from './credential/type/CredentialTypeEdit';
import CredentialTypeList from './credential/type/CredentialTypeList';
import CredentialTypeLoader from './credential/type/CredentialTypeLoader';
import DepartmentGroupAdd from './department/DepartmentGroupAdd';
import DepartmentGroupEdit from './department/DepartmentGroupEdit';
import DepartmentGroups from './department/DepartmentGroups';
import EmailSettingsForm from './email/EmailSettingsForm';
import EmailSettingsLoader from './email/EmailSettingsLoader';
import EmailTemplate from './email/EmailTemplate';
import EmailTemplateList from './email/EmailTemplateList';
import EventAdminEdit from './event/EventAdminEdit';
import EventAdminInvite from './event/EventAdminInvite';
import EventAdminList from './event/EventAdminList';
import EventAdminListLoader from './event/EventAdminListLoader';
import EventDashboard from './event/Dashboard';
import EventDepartmentSettingsForm from './event/EventDepartmentSettingsForm';
import EventFormAdd from './event/EventFormAdd';
import EventFormEdit from './event/EventFormEdit';
import EventList from './event/EventList';
import EventLoader from './event/EventLoader';
import EventOnsiteSettingsForm from './event/EventOnsiteSettingsForm';
import EventSettingsRedirector from './event/EventSettingsRedirector';
import EventWrapper from './event/EventWrapper';
import InternApplicationForm from './intern/InternApplicationForm';
import InternApplicationList from './intern/InternApplicationList';
import InternApplicationLoader from './intern/InternApplicationLoader';
import InternApprovalApplication from './intern/InternApprovalApplication';
import InternApprovalQueue from './intern/InternApprovalQueue';
import InternRequestsForEventLoader from './intern/InternRequestsForEventLoader';
import MealTickets from './catering/MealTickets';
import MealTypeForm from './catering/MealTypeForm';
import MealTypeList from './catering/MealTypeList';
import MealTypeLoader from './catering/MealTypeLoader';
import MediaApplicationForm from './media/MediaApplicationForm';
import MediaApplicationList from './media/MediaApplicationList';
import MediaApplicationLoader from './media/MediaApplicationLoader';
import MediaApprovalQueue from './media/MediaApprovalQueue';
import MediaRequestApproval from './media/MediaRequestApproval';
import MediaRequestsForEventLoader from './media/MediaRequestsForEventLoader';
import OnsiteRequestsApprovalQueue from './onsite/OnsiteRequestsApprovalQueue';
import SearchResultsContainer from './search/SearchResults/SearchResultsContainer';
import UploadsContainer from './uploads/UploadsContainer';
import EventApplicationsContainer from './event/EventApplicationsContainer';
import ReconcileList from './reconcile/ReconcileList';

const getCredentialApprovalRoutes = () => (
  <Route
    path="credentials"
    element={
      <ACL404
        key="event-approve-credentials"
        action={[resource.EVENT, 'abstractapprove']}
      ></ACL404>
    }
  >
    <Route index element={<CredentialApprovalsRedirector />} />
    {departmentTypeList.map(key => {
      const departmentType = departmentTypeMap[key];
      return (
        <Route
          key={key}
          path={departmentType.singular}
          element={
            <CredentialRequestApprovalsPage departmentType={departmentType} />
          }
        />
      );
    })}
  </Route>
);

export default (
  <Route path="events">
    <Route index element={<EventList />} />
    <Route
      path="~add"
      element={
        <ACL404 key="event-add" action={[resource.FESTIVAL, 'create-event']}>
          <EventFormAdd />
        </ACL404>
      }
    />
    <Route path=":eventSlug" element={<EventLoader />}>
      <Route index element={<Navigate to="dashboard" replace />} />
      <Route
        element={
          <ACL404 key="event" action={[resource.EVENT, 'view']}>
            <EventWrapper />
          </ACL404>
        }
      >
        <Route path="dashboard" element={<EventDashboard />} />
        <Route
          path="search-results/:eventId"
          element={<SearchResultsContainer />}
        />
        <Route path="approvals">
          <Route index element={<ApprovalsRedirector />} />
          <Route
            element={
              <ACL404
                key="event-approve-intern"
                action={[resource.EVENT, 'approve-intern']}
              />
            }
          >
            <Route path="intern" element={<InternRequestsForEventLoader />}>
              <Route index element={<InternApprovalQueue />} />
              <Route path=":departmentSlug" element={<InternApprovalQueue />} />
              <Route
                path=":departmentSlug/application"
                element={<InternApprovalApplication />}
              />
            </Route>
          </Route>
          <Route
            element={
              <ACL404
                key="event-approve-media"
                action={[resource.EVENT, 'approve-media']}
              />
            }
          >
            <Route path="media" element={<MediaRequestsForEventLoader />}>
              <Route index element={<MediaApprovalQueue />} />
              <Route
                path=":departmentSlug"
                element={<MediaRequestApproval />}
              />
              <Route
                path=":departmentSlug/contact/:contactId"
                element={<MediaRequestApproval />}
              />
            </Route>
          </Route>
          <Route path="onsite" element={<CredentialTypeLoader />}>
            <Route
              index
              element={
                <ACL404
                  key="event-approve-onsite"
                  action={[resource.EVENT, 'abstractapprove']}
                >
                  <OnsiteRequestsApprovalQueue />
                </ACL404>
              }
            />
          </Route>
          {getCredentialApprovalRoutes()}
        </Route>
        <Route path="credentials">
          <Route index element={<Navigate to="types" replace />} />
          <Route path="types" element={<CredentialTypeLoader />}>
            <Route index element={<CredentialTypeList />} />
            <Route
              path=":credentialTypeId"
              element={
                <ACL404
                  key="event-credentials-edit"
                  action={[resource.EVENT, 'edit-credential']}
                >
                  <CredentialTypeEdit />
                </ACL404>
              }
            />
            <Route
              path="categories"
              element={
                <ACL404
                  key="event-credentials-categories"
                  action={[resource.EVENT, 'edit-credential']}
                />
              }
            >
              <Route path="add" element={<CredentialTypeCategoryForm />} />
              <Route
                path="edit/:categoryId"
                element={<CredentialTypeCategoryForm />}
              />
              <Route
                path=":categoryId/add"
                element={<CredentialTypeCreate />}
              />
            </Route>
          </Route>
          <Route path="periods">
            <Route index element={<CredentialPeriodList />} />
            <Route
              element={
                <ACL404
                  key="event-periods"
                  action={[resource.EVENT, 'edit-credential']}
                />
              }
            >
              <Route path="add" element={<CredentialPeriodAdd />} />
              <Route path=":periodId" element={<CredentialPeriodEdit />} />
            </Route>
          </Route>
        </Route>
        <Route
          element={
            <ACL404
              key="event-catering"
              action={[resource.EVENT, 'manage-catering']}
            />
          }
        >
          <Route path="catering" element={<MealTypeLoader />}>
            <Route index element={<Navigate to="types" replace />} />
            <Route path="tickets" element={<MealTickets />} />
            <Route path="types">
              <Route index element={<MealTypeList />} />
              <Route path="add" element={<MealTypeForm />} />
              <Route path=":mealTypeId" element={<MealTypeForm />} />
            </Route>
            <Route path="upload" element={<CateringUpload />} />
          </Route>
        </Route>
        <Route path="settings">
          <Route index element={<EventSettingsRedirector />} />
          <Route
            path="edit"
            element={
              <ACL404 key="event-edit" action={[resource.EVENT, 'edit-event']}>
                <EventFormEdit />
              </ACL404>
            }
          />
          <Route
            path="applications"
            element={
              <ACL404
                key="event-applications"
                action={[resource.EVENT, 'edit-event']}
              >
                <EventApplicationsContainer />
              </ACL404>
            }
          />
          <Route
            path="email"
            element={
              <ACL404
                key="event-email"
                action={[resource.EVENT, 'edit-event-admin']}
              />
            }
          >
            <Route index element={<EmailSettingsForm />} />
            <Route element={<EmailSettingsLoader />}>
              <Route path="templates">
                <Route index element={<EmailTemplateList />} />
                <Route path="~add" element={<EmailTemplate />} />
                <Route
                  path="~edit/:emailTemplateId"
                  element={<EmailTemplate editing />}
                />
              </Route>
              <Route path="bulksend">
                <Route index element={<BulkSendContainer />} />
                <Route path=":emailType" element={<BulkSend />} />
              </Route>
            </Route>
          </Route>
          <Route
            path="department-groups"
            element={
              <ACL404
                key="event-department-groups"
                action={[resource.EVENT, 'edit']}
              />
            }
          >
            <Route index element={<DepartmentGroups />} />
            <Route path="add" element={<DepartmentGroupAdd />} />
            <Route
              path="edit/:departmentGroupId"
              element={<DepartmentGroupEdit />}
            />
          </Route>
          <Route path="section-admin" element={<EventAdminListLoader />}>
            <Route
              index
              element={
                <ACL404
                  key="event-section-admin"
                  action={[resource.EVENT, 'view']}
                >
                  <EventAdminList />
                </ACL404>
              }
            />
            <Route
              path="invite"
              element={
                <ACL404
                  key="event-section-admin-invite"
                  action={[resource.EVENT, 'edit-user']}
                >
                  <EventAdminInvite />
                </ACL404>
              }
            />
            <Route
              path=":userId"
              element={
                <ACL404
                  key="event-section-admin-edit"
                  action={[resource.EVENT, 'edit-event-admin']}
                >
                  <EventAdminEdit />
                </ACL404>
              }
            />
          </Route>
          <Route
            path="uploads"
            element={
              <ACL404 key="event-uploads" action={[resource.EVENT, 'edit']}>
                <UploadsContainer />
              </ACL404>
            }
          />
          <Route
            path="onsite"
            element={
              <ACL404 key="event-onsite" action={[resource.EVENT, 'edit']}>
                <EventOnsiteSettingsForm />
              </ACL404>
            }
          />
          <Route
            path="reconcile"
            element={
              <ACL404
                key="event-reconcile"
                action={[resource.EVENT, 'reconcile-order']}
              >
                <ReconcileList />
              </ACL404>
            }
          />
          <Route
            path="action-log"
            element={
              <ACL404
                key="event-action-log"
                action={[resource.EVENT, 'view-activity-log']}
              >
                <ActionLogPage />
              </ACL404>
            }
          />
          <Route
            path="reports"
            element={
              <ACL404
                key="event-reports"
                action={[resource.EVENT, 'view-reports']}
              >
                <EventReportsContainer />
              </ACL404>
            }
          />
          ;
          <Route
            path=":departmentSettingsType"
            element={
              <ACL404
                key="event-department-settings"
                action={[resource.EVENT, 'edit']}
              >
                <EventDepartmentSettingsForm />
              </ACL404>
            }
          />
          <Route
            element={
              <ACL404
                key="event-media-application"
                action={[resource.EVENT, 'manage-media-application']}
              />
            }
          >
            <Route
              path="media/applications"
              element={<MediaApplicationLoader />}
            >
              <Route index element={<MediaApplicationList />} />
              <Route path="~add" element={<MediaApplicationForm />} />
              <Route path=":applicationId" element={<MediaApplicationForm />} />
            </Route>
          </Route>
          <Route
            element={
              <ACL404
                key="event-intern-application"
                action={[resource.EVENT, 'manage-intern-application']}
              />
            }
          >
            <Route
              path="intern/applications"
              element={<InternApplicationLoader />}
            >
              <Route index element={<InternApplicationList />} />
              <Route path="~add" element={<InternApplicationForm />} />
              <Route
                path=":applicationId"
                element={<InternApplicationForm />}
              />
            </Route>
          </Route>
          <Route
            path=":departmentType/applications"
            element={
              <ACL404
                key="event-advance-application"
                action={[resource.EVENT, 'edit']}
              />
            }
          >
            <Route element={<AdvanceApplicationLoader />}>
              <Route index element={<AdvanceApplicationList />} />
              <Route path="~add" element={<AdvanceApplicationForm />} />
              <Route
                path=":applicationId"
                element={<AdvanceApplicationForm />}
              />
            </Route>
          </Route>
        </Route>
      </Route>
      {departmentRoutes}
    </Route>
  </Route>
);
