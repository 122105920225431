import { Column, Row, StyledQtyField, NumberBox } from './Credential.styles';
import moment from 'moment';
import QuantityField from '../../common/forms/QuantityField';
import ReduxFormsField from '../../common/forms/ReduxFormsFieldNoLabel';

export const CREDENTIAL_FORM_KEY = 'credential_';

const getPulseTagsCount = (holder, request) => {
  if (!request) {
    return 0;
  }

  let pulseOrders = holder.get('pulseOrders');
  if (!pulseOrders) {
    return 0;
  }

  let pulseTags = pulseOrders.flatMap(pulseOrder =>
    pulseOrder.get('pulseTags'),
  );
  if (!pulseTags) {
    return 0;
  }

  return pulseTags.filter(
    pulseTag =>
      pulseTag.get('pulse_credential_id') ===
      request.getIn(['credential', 'pulse_credential_id']),
  ).size;
};

const MultipleCredentials = ({
  department,
  contact,
  departmentRequests,
  credentialRequests,
  issueFrequency,
}) => {
  const dates = [];

  if (departmentRequests) {
    departmentRequests.forEach(request => {
      const credentialDate = request.getIn(['credential', 'date']);
      const credentialDateId = credentialDate.get('id');
      const dateEntry = dates[credentialDateId] ? dates[credentialDateId] : {};
      dateEntry['id'] = credentialDateId;
      dateEntry['date'] = credentialDate.get('date');
      dateEntry['departmentRequest'] = request;
      dates[credentialDateId] = dateEntry;
    });
  }

  if (credentialRequests) {
    credentialRequests.forEach(request => {
      const credentialDate = request.getIn(['credential', 'date']);
      const credentialDateId = credentialDate.get('id');
      const dateEntry = dates[credentialDateId] ? dates[credentialDateId] : {};
      dateEntry['id'] = credentialDateId;
      dateEntry['date'] = credentialDate.get('date');
      dateEntry['credentialRequest'] = request;
      dates[credentialDateId] = dateEntry;
    });
  }

  return (
    <Column>
      {dates.map(dateEntry => (
        <Row key={dateEntry.id}>
          <Column>
            <span>{moment.utc(dateEntry.date).format('ddd MMM D')}</span>
            <SingleCredential
              department={department}
              contact={contact}
              departmentRequest={dateEntry.departmentRequest}
              credentialRequest={dateEntry.credentialRequest}
              issueFrequency={issueFrequency}
            />
          </Column>
        </Row>
      ))}
    </Column>
  );
};

const SingleCredential = ({
  department,
  contact,
  departmentRequest,
  credentialRequest,
}) => {
  const id = credentialRequest
    ? credentialRequest.get('credential_id')
    : departmentRequest.get('credential_id');
  const pulseTagsCount = getPulseTagsCount(contact, credentialRequest);
  const deptPulseTagsCount = getPulseTagsCount(department, departmentRequest);
  const max =
    (departmentRequest
      ? departmentRequest.get('quantity_approved') - deptPulseTagsCount
      : 0) +
    (credentialRequest ? credentialRequest.get('quantity_approved') : 0);
  const canEdit = departmentRequest && max > pulseTagsCount;

  if (canEdit) {
    return (
      <StyledQtyField
        name={`${CREDENTIAL_FORM_KEY}${id}`}
        component={ReduxFormsField}
      >
        <QuantityField min={pulseTagsCount} max={max} maxHidden />
      </StyledQtyField>
    );
  }

  if (credentialRequest) {
    return <NumberBox>{credentialRequest.get('quantity_approved')}</NumberBox>;
  }

  return null;
};

const Credential = ({
  department,
  contact,
  departmentRequests,
  credentialRequests,
  issueFrequency,
}) => {
  if (
    !(departmentRequests && departmentRequests.size > 0) &&
    !(credentialRequests && credentialRequests.size > 0)
  ) {
    return null;
  }

  if (
    (departmentRequests && departmentRequests.size > 1) ||
    (credentialRequests && credentialRequests.size > 1)
  ) {
    return (
      <MultipleCredentials
        department={department}
        contact={contact}
        departmentRequests={departmentRequests}
        credentialRequests={credentialRequests}
        issueFrequency={issueFrequency}
      />
    );
  }

  if (issueFrequency === 'DAILY') {
    const credentialDate =
      credentialRequests && credentialRequests.first()
        ? credentialRequests.first().getIn(['credential', 'date', 'date'])
        : departmentRequests.first().getIn(['credential', 'date', 'date']);

    return (
      <Column>
        <Row>
          <Column>
            <span>{moment.utc(credentialDate).format('ddd MMM D')}</span>
            <SingleCredential
              department={department}
              contact={contact}
              departmentRequest={
                departmentRequests && departmentRequests.first()
              }
              credentialRequest={
                credentialRequests && credentialRequests.first()
              }
            />
          </Column>
        </Row>
      </Column>
    );
  }

  return (
    <Column>
      <Row>
        <SingleCredential
          department={department}
          contact={contact}
          departmentRequest={departmentRequests && departmentRequests.first()}
          credentialRequest={credentialRequests && credentialRequests.first()}
        />
      </Row>
    </Column>
  );
};

export default Credential;
