import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getResourceDataMap } from './ActionLogResourceDataFactory';
import { getActivityLog } from './activity-actions';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from '../department/department-selectors';
import PropTypes from 'prop-types';
import ReactTable from '../lib/react-table';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';

const DEFAULT_PAGE_SIZE = 50;

const sortMap = new Map([
  ['user', 'last_name'],
  ['action', 'action'],
  ['resource', 'resource'],
  ['details', 'resource_name'],
  ['department', 'department_name'],
  ['time', 'created_at'],
]);

const ActionLogPage = ({ forDepartment = false }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const department = useSelector(state =>
    forDepartment ? getCurrentDepartment(state, { params }) : null,
  );

  const [data, setData] = useState([]);
  const [pages, setPages] = useState(-1);
  const [loading, setLoading] = useState(false);

  const fetchData = (page, pageSize, sorted) => {
    if (event) {
      setLoading(true);
      dispatch(
        getActivityLog(
          event.get('id'),
          forDepartment && department ? department.get('id') : null,
          page,
          pageSize,
          sorted.map(s => ({
            field: sortMap.get(s.id),
            descending: s.desc,
          })),
        ),
      ).then(action => {
        setLoading(false);
        if (action.response.ok) {
          const resourceData = getResourceDataMap({
            forDepartment,
            location,
          });
          setData(
            action.json.rows.map(item => {
              const getResourceData = resourceData[item.resource];
              return getResourceData ? getResourceData(item) : null;
            }),
          );
          setPages(Math.ceil(action.json.count / pageSize));
        }
      });
    }
  };

  const renderLogTable = () => {
    const columns = [
      {
        Header: 'User',
        accessor: 'user',
      },
      {
        Header: 'Resource',
        accessor: 'resource',
      },
      {
        Header: 'Action',
        accessor: 'action',
      },
      {
        Header: 'Details',
        accessor: 'details',
      },
    ];

    if (!forDepartment) {
      columns.push({
        Header: 'Department',
        accessor: 'department',
      });
    }

    columns.push({
      Header: 'Time',
      accessor: 'time',
    });

    return (
      <ReactTable
        className="credential-requests-by-department-list"
        data={data}
        pages={pages}
        columns={columns}
        minRows={0}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        showPagination={true}
        showPageSizeOptions
        sortable={true}
        loading={loading}
        manual
        onFetchData={state =>
          fetchData(state.page, state.pageSize, state.sorted)
        }
        NoDataComponent={() => (
          <div className="generic-list--empty">
            <Icon icon="Sad" />
            <p>There are no results to display.</p>
          </div>
        )}
      />
    );
  };

  return (
    <Paper>
      <PaperHeader title="Action Log" />
      <span className="activity-log">{renderLogTable()}</span>
    </Paper>
  );
};

ActionLogPage.propTypes = {
  forDepartment: PropTypes.bool,
};

export default ActionLogPage;
