import PropTypes from 'prop-types';
import ReduxReactSelectField from './ReduxReactSelectField';

const SelectAllMultiSelect = ({ onChange, options, value, ...rest }) => {
  const selectAll = event => {
    event.preventDefault();
    const newValue = !(value.length === options.length) ? options : [];
    onChange(newValue);
  };

  return (
    <div>
      <button
        className="text-link"
        onClick={event => selectAll(event)}
        type="button"
      >
        <span>Select All</span>
      </button>
      <ReduxReactSelectField
        isMulti
        closeMenuOnSelect={true}
        options={options}
        onChange={onChange}
        value={value}
        {...rest}
      />
    </div>
  );
};

SelectAllMultiSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  value: PropTypes.any,
};

export default SelectAllMultiSelect;
