import { combineReducers } from 'redux';
import Immutable from 'immutable';
import getApiReducer, { defaultState } from '../common/utils/getApiReducer';
import { getUserListReducer } from '../user/user-reducers';
import {
  FETCH_EVENT_ADMIN_LIST,
  FETCH_EVENT_SETTINGS,
  FETCH_EMAIL_TYPES,
  FETCH_EMAIL_TEMPLATES,
  FETCH_EVENT_ONSITE_SETTINGS,
  FETCH_EVENT_ONSITE_REQUEST_TYPES,
  FETCH_SECTIONS_WITH_PENDING_REQUESTS_BY_EVENT,
  NEW_EMAIL_SETTINGS,
  SUBMIT_EMAIL_SETTINGS,
  ENABLE_EMAIL_TEMPLATE,
  DISABLE_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  ADD_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
} from './event-actions';
import {
  ADD_EMAIL_TYPE_SAMPLE,
  DELETE_EMAIL_TYPE_SAMPLE,
  UPDATE_EMAIL_TYPE_SAMPLE,
} from '../email/email-actions';

const eventSettings = (state = Immutable.Map(), action) => {
  let newState = state;
  if (action.originalType === FETCH_EVENT_SETTINGS) {
    newState = newState.set(
      action.eventId,
      getApiReducer(FETCH_EVENT_SETTINGS)(newState.get(action.eventId), action),
    );
  }

  return newState;
};

const emailTypes = (state = defaultState, action) => {
  let newState = state;

  if (action.originalType === FETCH_EMAIL_TYPES) {
    newState = getApiReducer(FETCH_EMAIL_TYPES)(newState, action);
  }

  if (action.applicationType) return newState;

  const getEmailTypeSamplesPath = action => {
    const path = ['data'];

    const index = newState
      .getIn(path)
      .findIndex(emailType => emailType.get('id') === action.emailTypeId);

    return path.concat([index, 'samples']);
  };

  const getEmailTypesSamplePath = action => {
    const path = getEmailTypeSamplesPath(action);

    const index = newState
      .getIn(path)
      .findIndex(
        sample => String(sample.get('id')) === String(action.emailTypeSampleId),
      );

    return path.concat(index);
  };

  if (action.type === `${ADD_EMAIL_TYPE_SAMPLE}_SUCCESS`) {
    const path = getEmailTypeSamplesPath(action);
    if (newState.hasIn(path))
      newState = newState.updateIn(path, samples =>
        samples.push(Immutable.fromJS(action.json)),
      );
  }

  if (action.type === `${UPDATE_EMAIL_TYPE_SAMPLE}_SUCCESS`) {
    const path = getEmailTypesSamplePath(action);
    if (newState.hasIn(path))
      newState = newState.setIn(path, Immutable.fromJS(action.json));
  }

  if (action.type === `${DELETE_EMAIL_TYPE_SAMPLE}_SUCCESS`) {
    const path = getEmailTypesSamplePath(action);
    if (newState.hasIn(path)) newState = newState.deleteIn(path);
  }

  return newState;
};

const emailTemplates = (state = Immutable.Map(), action) => {
  let newState = state;

  if (action.originalType === FETCH_EMAIL_TEMPLATES) {
    newState = newState.set(
      action.eventId,
      getApiReducer(action.originalType)(newState.get(action.eventId), action),
    );
  }

  const setEmailTemplateEnabled = isEnabled => {
    let path = [action.eventId, 'data'];

    const index = newState
      .getIn(path)
      .findIndex(
        emailTemplate => emailTemplate.get('id') === action.emailTemplateId,
      );

    path = path.concat([index, 'enabled']);

    if (newState.hasIn(path)) newState = newState.setIn(path, isEnabled);
  };

  if (action.type === `${ENABLE_EMAIL_TEMPLATE}_SUCCESS`)
    setEmailTemplateEnabled(true);

  if (action.type === `${DISABLE_EMAIL_TEMPLATE}_SUCCESS`)
    setEmailTemplateEnabled(false);

  if (action.type === `${DELETE_EMAIL_TEMPLATE}_SUCCESS`) {
    let path = [action.eventId, 'data'];

    const index = newState
      .getIn(path)
      .findIndex(
        emailTemplate => emailTemplate.get('id') === action.emailTemplateId,
      );

    path = path.concat([index]);

    if (newState.hasIn(path)) newState = newState.deleteIn(path);
  }

  if (action.type === `${ADD_EMAIL_TEMPLATE}_SUCCESS`) {
    newState = newState.updateIn([action.eventId, 'data'], list =>
      list.push(Immutable.fromJS(action.json)),
    );
  }

  if (action.type === `${UPDATE_EMAIL_TEMPLATE}_SUCCESS`) {
    let path = [action.eventId, 'data'];

    const index = newState
      .getIn(path)
      .findIndex(
        emailTemplate => emailTemplate.get('id') === action.emailTemplateId,
      );

    path = path.concat([index]);

    if (newState.hasIn(path))
      newState = newState.setIn(path, Immutable.fromJS(action.json));
  }

  if (action.type === `${NEW_EMAIL_SETTINGS}_SUCCESS`) {
    newState = newState.updateIn([action.eventId, 'data'], list =>
      list.concat(Immutable.fromJS(action.json)),
    );
  }

  if (action.type === `${SUBMIT_EMAIL_SETTINGS}_SUCCESS`) {
    action.json.forEach(setting => {
      const index = newState
        .getIn([action.eventId, 'data'])
        .findIndex(emailSetting => emailSetting.get('id') === setting.id);
      if (index !== -1) {
        newState = newState.setIn(
          [action.eventId, 'data', index],
          Immutable.fromJS(setting),
        );
      }
    });
  }

  return newState;
};

const eventOnsiteRequestTypes = (state = Immutable.Map(), action) => {
  let newState = state;
  if (action.originalType === FETCH_EVENT_ONSITE_REQUEST_TYPES) {
    newState = newState.set(
      action.eventId,
      getApiReducer(FETCH_EVENT_ONSITE_REQUEST_TYPES)(
        newState.get(action.eventId),
        action,
      ),
    );
  }

  if (action.type === 'UPDATE_EVENT_ONSITE_REQUEST_TYPE_SUCCESS') {
    const dataPath = [action.eventId, 'data'];
    const requestTypes = newState.getIn(dataPath);
    const requestTypeIndex = requestTypes.findIndex(
      requestType => requestType.get('id') === action.requestTypeId,
    );
    if (requestTypeIndex !== -1) {
      newState = newState.setIn(
        dataPath.concat(requestTypeIndex),
        Immutable.fromJS(action.json),
      );
    }
  }

  return newState;
};

const eventOnsiteSettings = (state = Immutable.Map(), action) => {
  let newState = state;
  if (action.originalType === FETCH_EVENT_ONSITE_SETTINGS) {
    newState = newState.set(
      action.eventId,
      getApiReducer(FETCH_EVENT_ONSITE_SETTINGS)(
        newState.get(action.eventId),
        action,
      ),
    );
  }

  if (action.type === 'UPDATE_EVENT_ONSITE_SETTINGS_SUCCESS') {
    newState = newState.setIn(
      [action.eventId, 'data'],
      Immutable.fromJS(action.json),
    );
  }

  return newState;
};

const pendingRequestsForEvent = (state = Immutable.Map(), action) => {
  let newState = state;
  if (action.originalType === FETCH_SECTIONS_WITH_PENDING_REQUESTS_BY_EVENT) {
    newState = newState.set(
      action.eventId,
      getApiReducer(FETCH_SECTIONS_WITH_PENDING_REQUESTS_BY_EVENT)(
        newState.get(action.eventId),
        action,
      ),
    );
  }
  return newState;
};

const reducers = combineReducers({
  adminList: getUserListReducer(FETCH_EVENT_ADMIN_LIST),
  emailTypes,
  emailTemplates,
  eventSettings,
  eventOnsiteRequestTypes,
  eventOnsiteSettings,
  pendingRequestsForEvent,
});

export default reducers;
