import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  removeLastUriSegment,
  stripTrailingSlash,
  trimUriSegments,
} from '../common/uri';
import { approveApplication, denyApplication } from './media-actions';
import { showNotification } from '../notification/notification-actions';
import { getCurrentApplicationRequest } from '../common/application/application-selectors';
import departmentTypeMap from '../lib/department-type-map';
import joinElements from '../common/utils/joinElements';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import ReactTable from '../lib/react-table';
import DisplayFields from '../common/custom-forms/display-fields';
import ContactDetails from '../contact/ContactDetails';
import StatusButton from '../common/StatusButton';
import CheckboxBoolean from '../common/forms/CheckboxBoolean';

const MediaRequestApproval = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const department = useSelector(state =>
    getCurrentApplicationRequest(state, { type: 'media', params }),
  );

  const [showTableFilter, setShowTableFilter] = useState(false);
  const [approving, setApproving] = useState(false);
  const [denying, setDenying] = useState(false);
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(true);

  const backTo = {
    pathname: `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/approvals/media`,
    search: '?keepFilter',
  };

  const responses = department?.get('applicationResponses');

  const contactList = department
    ?.get('contacts')
    .filterNot(contact => contact.get('is_guest'));

  const getContactRoute = contactId => {
    if (!params.contactId) {
      navigate(`${stripTrailingSlash(location.pathname)}/contact/${contactId}`);
    } else {
      navigate(`${removeLastUriSegment(location.pathname)}/${contactId}`);
    }
  };

  const handleResponse = (action, message) => {
    if (action.response.ok) {
      setApproving(false);
      setDenying(false);

      dispatch(
        showNotification({
          message: message,
          status: 'success',
        }),
      );

      navigate(backTo);
    }
  };

  const approve = () => {
    setApproving(true);
    dispatch(
      approveApplication(
        department.get('event_id'),
        department.get('id'),
        isNotificationEnabled,
      ),
    ).then(action => handleResponse(action, 'Media application approved'));
  };

  const deny = () => {
    setDenying(true);
    dispatch(
      denyApplication(
        department.get('event_id'),
        department.get('id'),
        isNotificationEnabled,
      ),
    ).then(action => handleResponse(action, 'Media application denied'));
  };

  const renderEmpty = () => {
    return (
      <div className="generic-list--empty">
        <Icon icon="Sad" />
        <p>
          No staff have been added for this{' '}
          {departmentTypeMap.media.label.singular}
        </p>
      </div>
    );
  };

  const renderContactList = () => (
    <ReactTable
      className="contact-list"
      data={contactList
        .map((contact, index) => ({
          id: contact.get('id'),
          name: {
            firstName: contact.get('first_name'),
            lastName: contact.get('last_name'),
          },
          index,
        }))
        .toJS()}
      columns={[
        {
          id: 'name',
          Header: 'Personnel Name',
          accessor: 'name',
          filterMethod: (filter, row) =>
            `${row.name.firstName} ${row.name.lastName}`
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) > -1,
          sortMethod: (a, b) => {
            if (a.lastName < b.lastName) {
              return -1;
            } else if (a.lastName > b.lastName) {
              return 1;
            } else if (a.firstName < b.firstName) {
              return -1;
            } else if (a.firstName > b.firstName) {
              return 1;
            }
            return 0;
          },
          Cell: row => (
            <span>{`${row.value.firstName} ${row.value.lastName}`}</span>
          ),
        },
        {
          filterable: false,
          Header: 'Credentials',
          Cell: row => {
            let pendingCreds = 0;
            let approvedCreds = 0;
            contactList
              .getIn([row.original.index, 'credentialRequests'])
              .forEach(credReq => {
                pendingCreds += credReq.get('quantity_pending');
                approvedCreds += credReq.get('quantity_approved');
              });
            if (pendingCreds === 0 && approvedCreds === 0) {
              return null;
            }
            const elements = [];
            if (approvedCreds) {
              elements.push(
                <span key="approved-count" className="approved-color">
                  {approvedCreds} Approved
                </span>,
              );
            }
            if (pendingCreds) {
              elements.push(
                <span key="pending-count" className="pending-color">
                  {pendingCreds} Requested
                </span>,
              );
            }
            return joinElements(
              elements,
              <span key="spacer-creds">&nbsp;&nbsp;</span>,
            );
          },
        },
      ]}
      defaultSorted={[
        {
          id: 'name',
          asc: true,
        },
      ]}
      minRows={0}
      defaultPageSize={20}
      showPagination={contactList.size > 20}
      showPageSizeOptions={false}
      NoDataComponent={() => (
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>There are no results to display.</p>
        </div>
      )}
      filterable={showTableFilter}
      getTrGroupProps={(_state, rowInfo) => ({
        onClick: () => getContactRoute(rowInfo.row._original.id),
      })}
    />
  );

  const getResponse = key =>
    responses?.find(
      response => response.getIn(['applicationSection', 'key']) === key,
    );

  const getCustomResponses = () =>
    responses?.filter(
      response => !response.getIn(['applicationSection', 'key']),
    );

  const renderApplicantInfo = () => {
    const applicantInfo = getResponse('applicant-info');

    if (!applicantInfo) return null;

    return (
      <Paper className="applicant-info">
        <h3>Applicant</h3>
        <div className="fields-container">
          <div className="fields-container-item">
            <span className="fields-container--label">Applicant Name:</span>
            {applicantInfo.getIn(['content', 'fields', 'person_info_name'])}
          </div>
          <div className="fields-container-item">
            <span className="fields-container--label">Applicant Email:</span>
            {applicantInfo.getIn(['content', 'fields', 'person_info_email'])}
          </div>
        </div>
      </Paper>
    );
  };

  const renderMediaOutletDetails = () => {
    const mediaOutletDetails = getResponse('media-outlet-details');

    if (!mediaOutletDetails) return null;

    return (
      <Paper className="applicant-info">
        <h3>Media Outlet Details</h3>
        <div className="fields-container">
          <div className="fields-container-item">
            <span className="fields-container--label">
              Publication Link / Website Outlet Type:
            </span>
            {mediaOutletDetails.getIn(['content', 'fields', 'publicationLink'])}
          </div>
          <div className="fields-container-item">
            <span className="fields-container--label">
              Unique Visitors Per Month:
            </span>
            {mediaOutletDetails.getIn(['content', 'fields', 'uniqueVisitors'])}
          </div>
          <div className="fields-container-item">
            <span className="fields-container--label">
              Link to Past Festival Coverage:
            </span>
            {mediaOutletDetails.getIn([
              'content',
              'fields',
              'festivalCoverageLink',
            ])}
          </div>
          <div className="fields-container-item">
            <span className="fields-container--label">Social Media Links:</span>
            {mediaOutletDetails.getIn(['content', 'fields', 'socialMediaLink'])}
          </div>
          <div className="fields-container-item">
            <span className="fields-container--label">
              Has Been Granted Press Accreditation To Past Festivals?
            </span>
            {mediaOutletDetails.getIn([
              'content',
              'fields',
              'hasPastAccreditation',
            ])}
          </div>
          <div className="fields-container-item">
            <span className="fields-container--label">
              Has Already Purchased Festival Passes?
            </span>
            {mediaOutletDetails.getIn([
              'content',
              'fields',
              'hasPurchasedPasses',
            ])}
          </div>
        </div>
      </Paper>
    );
  };

  const renderEditorInfo = () => {
    const editorInfo = getResponse('editor-info');

    if (!editorInfo) return null;

    return (
      <Paper className="applicant-info">
        <h3>Editor or Program Director Information</h3>
        <div className="fields-container">
          <div className="fields-container-item">
            <span className="fields-container--label">Editor Full Name:</span>
            {editorInfo.getIn(['content', 'fields', 'name'])}
          </div>
          <div className="fields-container-item">
            <span className="fields-container--label">
              Editor Email Address:
            </span>
            {editorInfo.getIn(['content', 'fields', 'email'])}
          </div>
          <div className="fields-container-item">
            <span className="fields-container--label">
              Editor Phone Number:
            </span>
            {editorInfo.getIn(['content', 'fields', 'phone'])}
          </div>
        </div>
      </Paper>
    );
  };

  const renderPersonnel = personnel => (
    <div>
      <dt>
        {personnel.first_name} {personnel.last_name}
      </dt>
      <dd>
        {personnel.errors.map(error => (
          <div style={{ color: 'red' }}>{error.error}</div>
        ))}
      </dd>
    </div>
  );

  const renderPersonnelSection = () => {
    const response = getResponse('personnel');
    const personnel = response
      ?.getIn(['content', 'fields', 'personnel'])
      ?.toJS();

    if (!personnel?.length) return null;

    const personnelWithErrors = personnel.filter(p => p.errors?.length > 0);

    if (personnelWithErrors.length === 0) return null;

    return (
      <Paper className="applicant-info">
        <h3>Personnel Errors</h3>
        <div className="media-app-std-res">
          <dl>{personnelWithErrors?.map(renderPersonnel)}</dl>
        </div>
      </Paper>
    );
  };

  const renderCustomResponses = () => {
    const customResponses = getCustomResponses();

    if (customResponses?.isEmpty() ?? true) return null;

    return customResponses.map(response => {
      const section = response.get('applicationSection');
      const fields = response.getIn(['content', 'fields']);

      if (fields?.isEmpty() ?? true) return null;

      return (
        <Paper className="custom-field-info" key={section.get('id')}>
          <h3>{section.get('name')}</h3>
          {fields.map((field, index) => (
            <DisplayFields
              key={index}
              label={field.get('label')}
              type={field.get('type')}
              value={field.get('value')}
            />
          ))}
        </Paper>
      );
    });
  };

  const renderSelectedContact = () => {
    if (!params.contactId || !contactList) {
      return null;
    }
    const contact = contactList.find(
      contact => contact.get('id') === parseInt(params.contactId, 10),
    );
    if (!contact) {
      return null;
    }

    return (
      <ContactDetails
        backTo={trimUriSegments(location.pathname, 2)}
        contact={contact}
        department={department}
      />
    );
  };

  let title = department?.get('name');
  if (department?.get('subtype')) title += ` (${department.get('subtype')})`;

  return (
    <div className="media-approval-page__wrapper">
      <div className="media-approval-page">
        <Paper>
          <PaperHeader
            backTo={backTo}
            title={title}
            actions={[
              !contactList?.isEmpty() && (
                <button
                  key="contact-list-filter-action"
                  className="button button--plain button--icon"
                  onClick={() => setShowTableFilter(!showTableFilter)}
                >
                  <Icon icon="Filter" />
                  <span>{showTableFilter ? 'Clear' : 'Advanced'} Filter</span>
                </button>
              ),
            ]}
          />
          {(!contactList && <LoadingIndicator />) ||
            (contactList?.isEmpty() && renderEmpty()) ||
            renderContactList()}
        </Paper>
        {!responses?.isEmpty() && (
          <>
            <Paper>
              <PaperHeader title="Form Answers" />
            </Paper>
            <div className="media-approval-page__form-answers">
              {renderApplicantInfo()}
              {renderMediaOutletDetails()}
              {renderEditorInfo()}
              {renderPersonnelSection()}
              {renderCustomResponses()}
            </div>
          </>
        )}
        <Paper>
          <PaperHeader title="Approve This Media Department" />
          <div className="approve-media__desc">
            Approving this department will automatically approve all personnel
            listed above, unless they have been previously rejected, with his or
            her assigned credentials. You can continue to make changes to this
            Media Outlet once approved, but changes will require re-approval.
          </div>
          <div className="approve-button__footer">
            <div>
              <StatusButton
                type="button"
                onClick={approve}
                buttonText="Approve Department"
                disabled={approving || denying}
                status={approving ? 'loading' : 'default'}
              />
              <button
                type="button"
                className="button button--plain"
                disabled={approving || denying}
                onClick={deny}
              >
                {denying && (
                  <div className="deny">
                    <div className="spinner">
                      <div className="bounce1" />
                      <div className="bounce2" />
                      <div className="bounce3" />
                    </div>
                  </div>
                )}
                {!denying && 'Deny Department'}
              </button>
            </div>
            <CheckboxBoolean
              label="Notify Applicant by Email"
              value={isNotificationEnabled}
              onChange={setIsNotificationEnabled}
            />
          </div>
        </Paper>
      </div>
      {renderSelectedContact()}
    </div>
  );
};

export default MediaRequestApproval;
