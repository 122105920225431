import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

const ReduxTimepickerField = ({
  convertToUtc = false,
  defaultOpenValue = '00:00:00',
  allowEmpty = true,
  onChange,
  value,
}) => {
  if (!value) {
    value = null;
  } else {
    value = moment(`${moment().format(DATE_FORMAT)} ${value}`);
    if (convertToUtc) {
      const offset = new Date().getTimezoneOffset();
      value.subtract(offset, 'minute');
    }
  }

  return (
    <div className="timepicker-container">
      <TimePicker
        name="value"
        value={value}
        onChange={momentDate => {
          if (!momentDate) {
            onChange(null);
            return;
          }

          let time = momentDate && momentDate.seconds(0);
          if (convertToUtc) {
            const offset = new Date().getTimezoneOffset();
            time.add(offset, 'minute');
          }
          onChange(time.format('HH:mm:ss'));
        }}
        prefixCls="timepicker"
        showSecond={false}
        use12Hours
        defaultOpenValue={moment(
          `${moment().format(DATE_FORMAT)} ${defaultOpenValue}`,
        )}
        placeholder="Time"
        allowEmpty={allowEmpty}
      />
    </div>
  );
};

ReduxTimepickerField.propTypes = {
  convertToUtc: PropTypes.bool,
  defaultOpenValue: PropTypes.string,
  allowEmpty: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default ReduxTimepickerField;
