import roleConstants from './role-constants';

const {
  ARTIST_EDIT,
  ARTIST_VIEW,
  FOOD_VENDOR_EDIT,
  FOOD_VENDOR_VIEW,
  GUESTLIST_EDIT,
  GUESTLIST_VIEW,
  INTERN_EDIT,
  INTERN_VIEW,
  MEDIA_EDIT,
  MEDIA_VIEW,
  PRODUCTION_EDIT,
  PRODUCTION_VIEW,
  SPONSOR_EDIT,
  SPONSOR_VIEW,
  VENDOR_EDIT,
  VENDOR_VIEW,
} = roleConstants;

const RoleParents = {
  [ARTIST_EDIT]: ARTIST_VIEW,
  [FOOD_VENDOR_EDIT]: FOOD_VENDOR_VIEW,
  [GUESTLIST_EDIT]: GUESTLIST_VIEW,
  [INTERN_EDIT]: INTERN_VIEW,
  [MEDIA_EDIT]: MEDIA_VIEW,
  [PRODUCTION_EDIT]: PRODUCTION_VIEW,
  [SPONSOR_EDIT]: SPONSOR_VIEW,
  [VENDOR_EDIT]: VENDOR_VIEW,
};

export default RoleParents;
