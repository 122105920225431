import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { isRequired } from 'revalidate';
import { getStatusForButton } from '../common/forms/FormHelper';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import { linkDepartmentToApplication } from './advance-form-actions';
import { getAdvanceApplications } from '../advance-application/advance-actions';
import {
  getDepartmentAdvanceApplication,
  getDepartmentAvailableAdvanceApplications,
} from '../advance-application/advance-selectors';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from '../department/department-selectors';
import Paper, { PaperHeader } from '../common/paper/Paper';
import LoadingIndicator from '../common/LoadingIndicator';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import StatusButton from '../common/StatusButton';

const AdvanceFormLinkPage = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const applicationList = useSelector(state =>
    getDepartmentAvailableAdvanceApplications(state, { params }),
  );

  const application = useSelector(state =>
    getDepartmentAdvanceApplication(state, { params }),
  );

  const initialValues = {
    application: application?.get('id') ?? '',
  };

  useEffect(() => {
    if (event) dispatch(getAdvanceApplications(event.get('id')));
  }, [dispatch, event]);

  if (!department || !applicationList) return <LoadingIndicator />;

  const customizedUrl = application
    ? `${window.location.origin}/advance/${application.get(
        'link_token',
      )}/${department.getIn(['advanceFormLink', 'link_token'])}`
    : null;

  const handleSubmit = ({ application }) =>
    dispatch(
      linkDepartmentToApplication(department, parseInt(application, 10)),
    ).then(action => {
      if (action.response.ok) {
        dispatch(
          showNotification({ message: 'Link saved', status: 'success' }),
        );
      } else {
        return formatValidationErrors(action.json).toJS();
      }
    });

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        pristine,
        submitting,
        submitFailed,
        submitSucceeded,
        invalid,
      }) => (
        <Paper>
          <PaperHeader title="Advance Form" />
          <form onSubmit={handleSubmit}>
            <div className="generic-form__body">
              <div style={{ margin: '30px 0' }}>
                <Field
                  name="application"
                  component={ReduxFormsField}
                  label="Application"
                  required
                  validate={isRequired('Application')}
                  disabled={!!application}
                >
                  <select>
                    {[
                      <option key="blank" value={''}>
                        Select Application
                      </option>,
                    ].concat(
                      applicationList.map(application => (
                        <option
                          key={application.get('id')}
                          value={application.get('id')}
                        >
                          {application.get('name')}
                        </option>
                      )),
                    )}
                  </select>
                </Field>
              </div>
            </div>
            <div className="generic-form__footer assignment-email">
              {customizedUrl && (
                <div className="assignment-email__row">
                  <div className="assignment-email__column">
                    <span>
                      <strong>Link:</strong>{' '}
                      <a target="_blank" href={customizedUrl} rel="noreferrer">
                        {customizedUrl}
                      </a>
                    </span>
                  </div>
                </div>
              )}
              {!application && (
                <div className="assignment-email__row__button">
                  <StatusButton
                    disabled={pristine || invalid || submitting}
                    status={getStatusForButton({
                      pristine,
                      submitting,
                      submitFailed,
                      submitSucceeded,
                    })}
                    buttonText="Create Link"
                    type="submit"
                  />
                </div>
              )}
            </div>
          </form>
        </Paper>
      )}
    />
  );
};

export default AdvanceFormLinkPage;
