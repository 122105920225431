import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { renderErrorMessage } from './onsite-utils';
import {
  getDepartmentList,
  getDepartmentListLoaded,
} from '../department/department-selectors';
import departmentTypeMap from '../lib/department-type-map';
import InputScaffold from '../common/forms/InputScaffold';
import ReduxReactSelectField from '../common/forms/ReduxReactSelectField';

const aclRules = {
  canViewArtist: [resource.EVENT, 'view-artist'],
  canViewFoodVendor: [resource.EVENT, 'view-food_vendor'],
  canViewGuestlist: [resource.EVENT, 'view-guestlist'],
  canViewIntern: [resource.EVENT, 'view-intern'],
  canViewMedia: [resource.EVENT, 'view-media'],
  canViewProduction: [resource.EVENT, 'view-production'],
  canViewSponsor: [resource.EVENT, 'view-sponsor'],
  canViewVendor: [resource.EVENT, 'view-vendor'],
};

const SelectDepartment = () => {
  const acl = useACL(aclRules);
  const navigate = useNavigate();
  const params = useParams();

  const departmentList = useSelector(state =>
    getDepartmentList(state, { params }),
  );
  const departmentListLoaded = useSelector(state =>
    getDepartmentListLoaded(state, { params }),
  );

  const departmentTypes = useMemo(() => {
    const departmentTypes = [];

    if (acl.canViewArtist) {
      departmentTypes.push(departmentTypeMap.artist);
    }

    if (acl.canViewFoodVendor) {
      departmentTypes.push(departmentTypeMap.food_vendor);
    }

    if (acl.canViewGuestlist) {
      departmentTypes.push(departmentTypeMap.guestlist);
    }
  
    if (acl.canViewIntern) {
      departmentTypes.push(departmentTypeMap.intern);
    }

    if (acl.canViewMedia) {
      departmentTypes.push(departmentTypeMap.media);
    }

    if (acl.canViewProduction) {
      departmentTypes.push(departmentTypeMap.production);
    }

    if (acl.canViewSponsor) {
      departmentTypes.push(departmentTypeMap.sponsor);
    }

    if (acl.canViewVendor) {
      departmentTypes.push(departmentTypeMap.vendor);
    }

    return departmentTypes;
  }, [
    acl.canViewArtist,
    acl.canViewFoodVendor,
    acl.canViewIntern,
    acl.canViewMedia,
    acl.canViewProduction,
    acl.canViewGuestlist,
    acl.canViewSponsor,
    acl.canViewVendor,
  ]);

  const departmentTypeOptions = useMemo(
    () =>
      departmentTypes?.map(departmentType => ({
        label: departmentType.label.singular,
        value: departmentType.singular,
      })),
    [departmentTypes],
  );

  const departmentOptions = useMemo(
    () =>
      departmentList?.map(department => ({
        label: department.get('name'),
        value: department.get('id'),
      })),
    [departmentList],
  );

  const getDepartmentTypeUrl = departmentType =>
    `/onsite/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/${departmentType}`;

  const selectDepartmentType = selectedDepartmentType => {
    navigate(getDepartmentTypeUrl(selectedDepartmentType));
  };

  const getDepartmentUrl = department =>
    `/onsite/promoters/${params.promoterSlug}/festivals/${
      params.festivalSlug
    }/events/${params.eventSlug}/${params.departmentType}/${department.get(
      'slug',
    )}`;

  const selectDepartment = departmentId => {
    navigate(
      getDepartmentUrl(
        departmentList.find(
          department => department.get('id') === parseInt(departmentId, 10),
        ),
      ),
    );
  };

  if (!departmentTypes) {
    return null;
  }

  if (
    departmentTypes.length === 0 ||
    (departmentListLoaded && departmentList.size === 0)
  ) {
    return renderErrorMessage('You do not have access to any departments.');
  }

  if (!departmentListLoaded && departmentTypes.length === 1) {
    return <Navigate to={getDepartmentTypeUrl(departmentTypes[0].singular)} />;
  }

  if (departmentListLoaded && departmentList.size === 1) {
    return <Navigate to={getDepartmentUrl(departmentList.first())} />;
  }

  return (
    <div>
      <InputScaffold label="Department Type" required>
        <ReduxReactSelectField
          autoFocus={!params.departmentType}
          className="onsite-select onsite-select__department-type"
          placeholder="Select department type..."
          value={departmentTypeOptions.find(
            option => option.value === params.departmentType,
          )}
          options={departmentTypeOptions}
          onChange={selectedDepartmentType =>
            selectDepartmentType(selectedDepartmentType.value)
          }
        />
      </InputScaffold>
      {departmentListLoaded && (
        <InputScaffold label="Department" required>
          <ReduxReactSelectField
            autoFocus={!!params.departmentType}
            className="onsite-select"
            placeholder="Select department..."
            options={departmentOptions}
            onChange={selectedDepartment =>
              selectDepartment(selectedDepartment.value)
            }
          />
        </InputScaffold>
      )}
    </div>
  );
};

export default SelectDepartment;
