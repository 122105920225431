import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getAdvanceApplicationsList } from './advance-selectors';
import { getCurrentEvent } from '../event/event-selectors';
import { deleteAdvanceApplication } from './advance-actions';
import Icon from '../common/icons/Icon';
import Paper, { PaperHeader } from '../common/paper/Paper';
import LoadingIndicator from '../common/LoadingIndicator';
import Confirmation from '../common/Confirmation';

const AdvanceApplicationList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const applicationList = useSelector(state =>
    getAdvanceApplicationsList(state, { params }),
  );

  const [applicationToDelete, setApplicationToDelete] = useState(null);

  const hideDeleteConfirmation = () => {
    setApplicationToDelete(null);
  };

  const onDeleteApplication = () => {
    dispatch(
      deleteAdvanceApplication(event.get('id'), applicationToDelete.get('id')),
    ).then(() => {
      hideDeleteConfirmation();
    });
  };

  const renderEmpty = () => (
    <div className="generic-list--empty">
      <Icon icon="Sad" />
      <p>You have no advance applications</p>
    </div>
  );

  const renderApplicationList = () => (
    <div>
      {applicationList.map((application, index) => (
        <Link
          key={index}
          className="generic-list__item generic-list__item--link"
          to={`${location.pathname}/${application.get('id')}`}
        >
          <div className="content">
            <span>{application.get('name')}</span>
          </div>
          <button
            className="dropdown__item"
            key={`delete-application-${application.get('id')}`}
            onClick={e => {
              e.preventDefault();
              setApplicationToDelete(application);
            }}
            type="button"
          >
            <div className="dropdown__item__children">
              <Icon icon="Trash" />
            </div>
          </button>

          <div className="action">
            <Icon icon="ArrowLeft" />
          </div>
        </Link>
      ))}
    </div>
  );

  if (applicationToDelete) {
    return (
      <Confirmation
        warningText={`Deleting ${applicationToDelete.get(
          'name',
        )} will remove them from all access to Strata`}
        actionText={`delete ${applicationToDelete.get('name')}}`}
        onConfirm={onDeleteApplication}
        onDismiss={hideDeleteConfirmation}
      />
    );
  }

  const actions = [];
  actions.push(
    <Link
      key="add-application-action"
      className="button button--plain button--icon"
      to="~add"
    >
      <Icon icon={'AddCircle'} />
      <span>Add Application</span>
    </Link>,
  );

  return (
    <Paper>
      <PaperHeader title="Advance Applications" actions={actions} />
      {!applicationList ? (
        <LoadingIndicator />
      ) : !applicationList.size ? (
        renderEmpty()
      ) : (
        renderApplicationList()
      )}
    </Paper>
  );
};

export default AdvanceApplicationList;
