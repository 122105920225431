import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { fetchSectionsWithPendingCredentialRequestsByEvent } from './event-actions';
import {
  getCurrentEvent,
  getEventPendingRequests,
} from '../event/event-selectors';
import departmentTypeMap from '../lib/department-type-map';
import Paper from '../common/paper/Paper';
import PaperHeader from '../common/paper/PaperHeader';
import PaperBody from '../common/paper/PaperBody';

const aclRules = {
  canApprove: [resource.EVENT, 'abstractapprove'],
};

const EventDashboard = () => {
  const acl = useACL(aclRules);

  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const sectionsWithRequests = useSelector(state =>
    getEventPendingRequests(state, { params }),
  );

  useEffect(() => {
    if (event && acl.canApprove)
      dispatch(
        fetchSectionsWithPendingCredentialRequestsByEvent(event.get('id')),
      );
  }, [acl.canApprove, dispatch, event]);

  const renderLinks = () => {
    const { promoterSlug, festivalSlug, eventSlug } = params;

    return sectionsWithRequests?.size > 0 ? (
      <Paper>
        <PaperHeader title="You have pending credentials in the following sections" />
        <PaperBody>
          <ul>
            {sectionsWithRequests.map(section => (
              <li key={`li_${section}`}>
                <Link
                  to={`/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/approvals/credentials/${section}`}
                >
                  {departmentTypeMap[section].label.plural}
                </Link>
              </li>
            ))}
          </ul>
        </PaperBody>
      </Paper>
    ) : null;
  };

  return (
    <div>
      <Paper>
        <PaperHeader title="Event Dashboard" />
        <PaperBody>Welcome!</PaperBody>
      </Paper>
      <div className="generic-list__body">{renderLinks()}</div>
    </div>
  );
};

export default EventDashboard;
