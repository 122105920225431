import { Map } from 'immutable';
import { cloneDeep } from 'lodash';
import {
  getCredentialName,
  sortCredentialsOfTheSameType,
} from '../credential-request/credential-request-helpers';
import PropTypes from 'prop-types';
import Tag from '../common/Tag';
import CheckboxBoolean from '../common/forms/CheckboxBoolean';
import CredentialQuantityField from '../common/forms/CredentialQuantityField';

const CredentialAssignmentField = ({
  department,
  requireApproval,
  skippedCredentials = [],
  value = [],
  onChange,
}) => {
  const credentialTypes = department.get('credentialTypes').toJS();

  const _onChange = (credTypeId, credTypeValue) => {
    const qty =
      typeof credTypeValue === 'boolean'
        ? credTypeValue
          ? 1
          : 0
        : parseInt(credTypeValue, 10);

    const newValue = cloneDeep(value);
    const existingCredIndex = newValue.findIndex(
      cred => cred.id === credTypeId,
    );

    if (existingCredIndex > -1) {
      newValue[existingCredIndex].quantity = qty;
    } else {
      newValue.push({
        id: credTypeId,
        quantity: qty,
      });
    }

    onChange(newValue);
  };

  const getQuantityForCredentialType = id =>
    (value.find(credQty => credQty.id === id) || {}).quantity || 0;

  const renderField = credentialType => {
    const color = credentialType.category.color;
    const elements = [];

    if (credentialType.attendeePickupTypes.length === 0) return null;

    credentialType.credentials
      .filter(cred => skippedCredentials.indexOf(cred.id) === -1)
      .sort(sortCredentialsOfTheSameType)
      .forEach(credential => {
        let max = 0;
        if (
          requireApproval &&
          department.get('require_approval_pre_assignment')
        ) {
          department.get('credentialRequests').forEach(credReq => {
            if (
              credReq.get('credential_id') === credential.id &&
              credReq.get('quantity_approved') > 0
            ) {
              max += credReq.get('quantity_approved');
            }
          });
        } else {
          max = null;
        }

        if (max === 0) return null;

        const credRequest = department
          .get('credentialRequests')
          .find(credReq => credReq.get('credential_id') === credential.id);

        const alreadyRequested =
          credRequest &&
          (credRequest.get('quantity_pending') > 0 ||
            credRequest.get('quantity_approved') > 0);

        const id = `cred-field-${credential.id}`;
        elements.push(
          // If it's individual pickup and the user already has on assigned, don't show the field
          <div key={credential.id} className="cred-row">
            <label htmlFor={id}>
              <Tag size="medium" backgroundColor={color}>
                {getCredentialName(credential)}
              </Tag>
            </label>
            {credentialType.attendeePickupTypes[0] &&
            credentialType.attendeePickupTypes[0].pickup_type ===
              'individual' ? (
              <div className="quantity-field">
                <div className="quantity-button__spacer" />
                <CheckboxBoolean
                  id={id}
                  onChange={value => _onChange(credential.id, value)}
                  value={!!getQuantityForCredentialType(credential.id)}
                  checkedIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                    </svg>
                  }
                  uncheckedIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
                    </svg>
                  }
                />
                <div className="quantity-button__spacer" />
              </div>
            ) : (
              <CredentialQuantityField
                id={id}
                value={getQuantityForCredentialType(credential.id)}
                max={max}
                skipCheckbox={alreadyRequested}
                onChange={value => _onChange(credential.id, value)}
              />
            )}
          </div>,
        );
      });
    return elements;
  };

  return (
    <div className="credential-assignment-form">
      {credentialTypes.map(renderField)}
    </div>
  );
};

CredentialAssignmentField.propTypes = {
  department: PropTypes.instanceOf(Map).isRequired,
  requireApproval: PropTypes.bool.isRequired,
  skippedCredentials: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

export default CredentialAssignmentField;
