import { useState } from 'react';
import { v1 as keygen } from 'uuid';
import { OrderedMap } from 'immutable';
import { Modal } from 'semantic-ui-react';
import moment from 'moment';
import parse from 'html-react-parser';
import purify from 'dompurify';
import PropTypes from 'prop-types';
import ExpandableRow from '../common/ExpandableRow';
import InputScaffold from '../common/forms/InputScaffold';

const EmailHistory = ({ emails }) => {
  const [expanded, setExpanded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openedEmail, setOpenedEmail] = useState(null);

  const open = email => {
    setIsOpen(true);
    setOpenedEmail(email);
  };

  const close = () => {
    setIsOpen(false);
    setOpenedEmail(null);
  };

  if (emails.isEmpty()) return null;

  return (
    <ExpandableRow
      isExpandable
      isExpanded={expanded}
      onToggleExpansion={() => setExpanded(!expanded)}
      columns={['Email History']}
    >
      <div className="generic-form__body">
        <div className="assignment-email">
          <div className="assignment-email__row">
            <div className="assignment-email__column">
              {emails.valueSeq().map(recipient => (
                <div key={keygen()} className="group-credential-email-history">
                  {'Emails sent to '}
                  {recipient.first().get('recipients').split(',').join(', ')}
                  {recipient.map(email => (
                    <div
                      key={email.get('id')}
                      className="group-credential-email-history__time-sent"
                    >
                      <button type="button" onClick={() => open(email)}>
                        <span>
                          {moment(email.get('created_at')).format(
                            'MM/DD/YYYY h:mm a',
                          )}
                        </span>
                      </button>
                    </div>
                  ))}
                </div>
              ))}
              {isOpen && (
                <Modal closeIcon open={isOpen} onClose={() => close()}>
                  <Modal.Content>
                    <div className="input-group">
                      <InputScaffold label="Date" required>
                        <input
                          readOnly
                          value={moment(openedEmail.get('created_at')).format(
                            'MM/DD/YYYY h:mm a',
                          )}
                        />
                      </InputScaffold>
                      <InputScaffold label="Recipients" required>
                        <input readOnly value={openedEmail.get('recipients')} />
                      </InputScaffold>
                      <InputScaffold label="Subject" required>
                        <input readOnly value={openedEmail.get('subject')} />
                      </InputScaffold>
                      <InputScaffold label="Message" required>
                        <div className="parsed-html">
                          {parse(purify.sanitize(openedEmail.get('message')))}
                        </div>
                      </InputScaffold>
                    </div>
                  </Modal.Content>
                </Modal>
              )}{' '}
            </div>
          </div>
        </div>
      </div>
    </ExpandableRow>
  );
};

EmailHistory.propTypes = {
  emails: PropTypes.instanceOf(OrderedMap),
};

export default EmailHistory;
