import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

function Icon({
  icon = 'Placeholder',
  className = null,
  style = null,
  dataTooltipId = null,
}) {
  const IconComponent = require(`./${icon}`).default;

  return (
    <span
      className={classNames(['icon', className])}
      style={style}
      data-tooltip-id={dataTooltipId}
    >
      <IconComponent />
    </span>
  );
}

Icon.displayName = 'Icon';
Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  dataTooltipId: PropTypes.string,
};

export default Icon;
