import { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import StatusButton from '../common/StatusButton';

const Content = styled.div`
  margin-bottom: 16px;
`;

const ApproveAllConfirmationBox = ({
  approveAll,
  approving,
  pendingOnly = false,
  closeModal,
  handleApproveAll,
}) => {
  const [dispatching, setDispatching] = useState(null);

  useEffect(() => {
    if (!approving) {
      setDispatching(null);
      closeModal();
    }
  }, [approving, closeModal]);

  const hasPending = approveAll?.requests?.some(
    req => req.get('quantity_pending') > 0,
  );

  const hasRejected = approveAll?.requests?.some(
    req => req.get('quantity_rejected') > 0,
  );

  const getContent = () => {
    const requestsLabel = pendingOnly || !hasRejected ? 'all pending' : 'all';
    const periodsLabel = approveAll.period ? (
      <>
        period <strong>"{approveAll.period.name}"</strong>
      </>
    ) : (
      <>
        <strong>all</strong> periods
      </>
    );

    if (approveAll.department && !approveAll.contact) {
      return (
        <Content>
          You have requested to approve <strong>{requestsLabel}</strong>{' '}
          credential requests for department{' '}
          <strong>"{approveAll.department.name}"</strong> for {periodsLabel}.
        </Content>
      );
    }

    if (approveAll.contact?.id === 'group') {
      return (
        <Content>
          You have requested to approve <strong>{requestsLabel}</strong> group
          level credential requests for department{' '}
          <strong>"{approveAll.department.name}"</strong> for {periodsLabel}.
        </Content>
      );
    }

    if (approveAll.contact) {
      return (
        <Content>
          You have requested to approve <strong>{requestsLabel}</strong>{' '}
          credential requests for staff{' '}
          <strong>"{approveAll.contact.name}"</strong> for {periodsLabel}.
        </Content>
      );
    }

    return null;
  };

  const _handleApproveAll = () => {
    setDispatching('all');
    handleApproveAll(approveAll, false);
  };

  const _handleApprovePending = () => {
    setDispatching('pending');
    handleApproveAll(approveAll, true);
  };

  return (
    <Modal closeIcon open={approveAll.open} onClose={closeModal}>
      <Modal.Header>
        <div className="confirmation__icon" style={{ display: 'flex' }}>
          <Icon icon="Warning" style={{ marginBottom: '0px' }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            <span>
              Approve All {pendingOnly || !hasRejected ? 'Pending' : ''}{' '}
              Credentials
            </span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="modal__body">
          <div>
            {getContent()}
            <h4 style={{ color: '#E74C3C' }}>TIMEOUT WARNING:</h4>
            <Content>
              Please be aware that approving multiple credential requests in a
              single submission may cause your browser to timeout while they are
              being processed. Strata will continue processing all submitted
              credential requests even if your browser experiences a timeout
              during this process. Please refresh the approvals page in a short
              while to confirm that all credentials have been approved.
            </Content>
            {!pendingOnly && hasRejected && hasPending && (
              <Content>
                <strong>
                  Do you want to approve all "Pending and Declined Requests" or
                  only "Pending Requests"?
                </strong>
              </Content>
            )}
          </div>
        </div>
        <div className="modal__footer">
          {!pendingOnly && hasRejected && (
            <StatusButton
              buttonText="Approve All Requests"
              disabled={!!dispatching}
              status={dispatching === 'all' ? 'loading' : 'default'}
              onClick={_handleApproveAll}
            />
          )}
          {(pendingOnly || hasPending) && (
            <StatusButton
              buttonText="Approve Pending Requests"
              disabled={!!dispatching}
              status={dispatching === 'pending' ? 'loading' : 'default'}
              onClick={_handleApprovePending}
            />
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

ApproveAllConfirmationBox.propTypes = {
  approveAll: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    department: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    contact: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    period: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  approving: PropTypes.bool.isRequired,
  pendingOnly: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  handleApproveAll: PropTypes.func.isRequired,
};

export default ApproveAllConfirmationBox;
