import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { getCredentialName } from '../credential-request/credential-request-helpers';
import {
  getCategories,
  getCredentialTypes,
  getCredentials,
  getCredentialsByPulseCredentialId,
} from '../credential/type/credential-type-selectors';
import { showNotification } from '../notification/notification-actions';
import { reconcilePulseOrder } from './reconcile-actions';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactTable from '../lib/react-table';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import FormStatusButton from '../common/FormStatusButton';
import NoDataComponent from '../common/table/NoDataComponent';

const reconcileActions = {
  NO_ACTION: 'No Action',
  ADD_STRATA: 'Add Strata',
  ADD_PULSE: 'Add Pulse',
  UPDATE_STRATA: 'Update Strata',
  UPDATE_PULSE: 'Update Pulse',
  DELETE_STRATA: 'Delete Strata',
  DELETE_PULSE: 'Delete Pulse',
};

const CenterContent = { justifyContent: 'center' };

export const CategoryHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
`;

const Category = ({ value: category }) => (
  <CategoryHeader>
    <span
      className="credential-category-color"
      style={{ backgroundColor: `${category.color}` }}
    />
    <span>{category.name}</span>
  </CategoryHeader>
);

Category.propTypes = {
  value: PropTypes.object.isRequired,
};

const Action = ({ original: entry }) => {
  if (entry.inSync) return null;

  const options = [reconcileActions.NO_ACTION];
  if (entry.strata.credentialRequestId && entry.pulse.credentialId) {
    options.push(reconcileActions.UPDATE_STRATA);
    options.push(reconcileActions.UPDATE_PULSE);
  } else if (entry.strata.credentialRequestId) {
    options.push(reconcileActions.DELETE_STRATA);
    options.push(reconcileActions.ADD_PULSE);
  } else {
    options.push(reconcileActions.ADD_STRATA);
    options.push(reconcileActions.DELETE_PULSE);
  }

  return (
    <Field name={`entry_${entry.index}`} component={ReduxFormsFieldNoLabel}>
      <select>
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </Field>
  );
};

Action.propTypes = {
  original: PropTypes.object.isRequired,
};

const ReconcileOrder = ({ order, onReconciled }) => {
  const dispatch = useDispatch();

  const categories = useSelector(getCategories);
  const credentialTypes = useSelector(getCredentialTypes);
  const credentials = useSelector(getCredentials);
  const credentialsByPulseCredentialId = useSelector(
    getCredentialsByPulseCredentialId,
  );

  const initialValues = useMemo(() => {
    const initialValues = {};
    order.entries.forEach((entry, index) => {
      if (!entry.inSync)
        initialValues[`entry_${index}`] = reconcileActions.NO_ACTION;
    });
    return initialValues;
  }, [order]);

  const data = useMemo(() => {
    const data = [];
    order.entries.forEach((entry, index) => {
      const credential =
        credentials[entry.strata?.credentialRequest?.credential_id] ??
        credentialsByPulseCredentialId[entry.pulse?.credential?.credential_id];

      if (!credential) return;

      const credentialType =
        credentialTypes[credential.get('credential_type_id')];
      const category = categories[credentialType.get('category_id')];

      data.push({
        index,
        inSync: entry.inSync,
        category: {
          name: category.get('name'),
          color: category.get('color'),
        },
        strata: {
          credentialRequestId: entry.strata?.credentialRequest?.id,
          credential: entry.strata?.credentialRequest
            ? getCredentialName(credential)
            : null,
          approved: entry.strata?.credentialRequest?.quantity_approved ?? null,
          pending: entry.strata?.credentialRequest?.quantity_pending ?? null,
        },
        pulse: {
          credentialId: entry.pulse?.credential?.credential_id,
          credential: entry.pulse?.credential?.credential_name ?? null,
          approved:
            entry.pulse?.orderItems
              ?.filter(item => !item.hold_reason)
              .reduce(
                (quantity, orderItem) => quantity + Number(orderItem.qty),
                0,
              ) ?? null,
          pending:
            entry.pulse?.orderItems
              ?.filter(item => item.hold_reason === 'pending_approval')
              .reduce(
                (quantity, orderItem) => quantity + Number(orderItem.qty),
                0,
              ) ?? null,
        },
      });
    });

    return data;
  }, [
    categories,
    credentialTypes,
    credentials,
    credentialsByPulseCredentialId,
    order,
  ]);

  const columns = [
    {
      accessor: 'category',
      Header: 'Category',
      Cell: Category,
    },
    {
      Header: 'Strata',
      headerStyle: { fontSize: 'large' },
      columns: [
        {
          accessor: 'strata.credential',
          Header: 'Credential',
        },
        {
          accessor: 'strata.approved',
          Header: 'Approved',
          headerStyle: CenterContent,
          style: CenterContent,
          width: 75,
        },
        {
          accessor: 'strata.pending',
          Header: 'Pending',
          headerStyle: CenterContent,
          style: CenterContent,
          width: 75,
        },
      ],
    },
    {
      Header: 'Pulse',
      headerStyle: { fontSize: 'large' },
      columns: [
        {
          accessor: 'pulse.credential',
          Header: 'Credential',
        },
        {
          accessor: 'pulse.approved',
          Header: 'Approved',
          headerStyle: CenterContent,
          style: CenterContent,
          width: 75,
        },
        {
          accessor: 'pulse.pending',
          Header: 'Pending',
          headerStyle: CenterContent,
          style: CenterContent,
          width: 75,
        },
      ],
    },
    {
      accessor: 'inSync',
      Header: 'Status',
      Cell: ({ value }) => (value ? 'In Sync' : 'Out of Sync'),
      headerStyle: { justifyContent: 'center' },
      style: { justifyContent: 'center' },
      width: 100,
    },
  ];

  if (!order.inSync) {
    columns.push({
      Header: 'Action',
      width: 175,
      Cell: Action,
    });
  }

  const handleSubmit = values => {
    const actions = [];

    for (const [key, action] of Object.entries(values)) {
      if (action === reconcileActions.NO_ACTION) continue;

      const entry = order.entries[key.split('_')[1]];

      if (action === reconcileActions.ADD_STRATA)
        actions.push({
          action: 'add',
          pulse: {
            credentialId: entry.pulse.credential.credential_id,
          },
        });
      else if (action === reconcileActions.ADD_PULSE)
        actions.push({
          action: 'add',
          strata: {
            credentialRequestId: entry.strata.credentialRequest.id,
          },
        });
      else if (action === reconcileActions.UPDATE_STRATA)
        actions.push({
          action: 'update',
          strata: {
            credentialRequestId: entry.strata.credentialRequest.id,
          },
        });
      else if (action === reconcileActions.UPDATE_PULSE)
        actions.push({
          action: 'update',
          pulse: {
            credentialId: entry.pulse.credential.credential_id,
          },
        });
      else if (action === reconcileActions.DELETE_STRATA)
        actions.push({
          action: 'delete',
          strata: {
            credentialRequestId: entry.strata.credentialRequest.id,
          },
        });
      else if (action === reconcileActions.DELETE_PULSE)
        actions.push({
          action: 'delete',
          pulse: {
            credentialId: entry.pulse.credential.credential_id,
          },
        });
    }

    if (actions.length === 0) return;

    return dispatch(reconcilePulseOrder(order.strataOrder, actions)).then(
      async action => {
        if (action.response.ok) {
          await onReconciled(action.json);
          dispatch(
            showNotification({
              status: 'success',
              message: `Successfully reconciled Pulse Order ${order.strataOrder.pulse_reference_id}`,
            }),
          );
        } else {
          dispatch(
            showNotification({
              status: 'error',
              message: `Error reconciling Pulse Order ${order.strataOrder.pulse_reference_id}: ${action.json.message}`,
            }),
          );
          return { [FORM_ERROR]: action.json.message };
        }
      },
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <div>
          <form onSubmit={handleSubmit} className="generic-form paper__wrapper">
            <div className="generic-form__body">
              <ReactTable
                data={data}
                columns={columns}
                minRows={true}
                sortable={false}
                showPagination={false}
                NoDataComponent={NoDataComponent}
              />
            </div>
            {!order.inSync && (
              <div className="generic-form__footer">
                <FormStatusButton
                  buttonText="Reconcile"
                  completeText="Reconciled!"
                />
              </div>
            )}
          </form>
        </div>
      )}
    />
  );
};

ReconcileOrder.propTypes = {
  order: PropTypes.object.isRequired,
  onReconciled: PropTypes.func.isRequired,
};

export default ReconcileOrder;
