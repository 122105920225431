import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { List } from 'immutable';
import CropperScaffold from '../common/forms/CropperScaffold';
import DropNCrop from '../common/forms/DropNCrop';
import Paper from '../common/paper/Paper';
import PaperHeader from '../common/paper/PaperHeader';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';

const validate = values => {
  const errors = {};
  if (!values.name) errors.name = ['Name is required'];
  if (!values.pulse_client_id)
    errors.pulse_client_id = ['Pulse Client is required'];
  return errors;
};

const PromoterForm = ({
  initialValues,
  promoter,
  onSubmit,
  clearOrigImage,
  pulseClientList,
  removeImage,
}) => {
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
      {({
        handleSubmit,
        invalid,
        modifiedSinceLastSubmit,
        pristine,
        submitError,
        submitting,
      }) => (
        <Paper>
          <PaperHeader
            backTo="/promoters"
            backToDisabled={submitting}
            title={`${
              initialValues && initialValues.id ? 'Edit' : 'Add'
            } Promoter`}
          />
          <form className="generic-form" onSubmit={handleSubmit}>
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                <Field
                  name="name"
                  component={ReduxFormsField}
                  label="Promoter Name"
                  required
                >
                  <input type="text" />
                </Field>
                {submitError ? (
                  <div className="alert alert--error">{submitError}</div>
                ) : null}

                {promoter || !pulseClientList ? null : (
                  <Field
                    name="pulse_client_id"
                    component={ReduxFormsField}
                    label="Pulse Client"
                    required
                  >
                    <select>
                      {pulseClientList.map(client => (
                        <option
                          key={client.get('client_id')}
                          value={client.get('client_id')}
                        >
                          {client.get('name')}
                        </option>
                      ))}
                    </select>
                  </Field>
                )}
                {promoter && promoter.get('file_id') && !clearOrigImage ? (
                  <CropperScaffold label="Logo">
                    <div className="original-image">
                      <img
                        alt="promoter logo"
                        src={promoter.getIn(['logo', 'location'])}
                      />
                    </div>
                    <div className="cropper-scaffold__controls">
                      <button
                        type="button"
                        className="button button--red"
                        onClick={removeImage}
                      >
                        Remove Logo
                      </button>
                    </div>
                  </CropperScaffold>
                ) : (
                  <Field
                    name="logo"
                    component={ReduxFormsFieldNoLabel}
                    label="Logo"
                  >
                    <DropNCrop showRemoveImage removeImageText="Remove Logo" />
                  </Field>
                )}
              </div>
            </div>
            <div className="generic-form__footer">
              <button
                type="submit"
                className="button"
                disabled={
                  submitting ||
                  !pulseClientList ||
                  (pristine && !clearOrigImage) ||
                  (invalid && !modifiedSinceLastSubmit)
                }
              >
                Save Promoter
              </button>
              <Link
                className="button button--plain"
                to="/promoters"
                disabled={submitting}
              >
                Cancel
              </Link>
            </div>
          </form>
        </Paper>
      )}
    </Form>
  );
};

PromoterForm.propTypes = {
  initialValues: PropTypes.object,
  promoter: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  clearOrigImage: PropTypes.bool,
  pulseClientList: PropTypes.instanceOf(List),
  removeImage: PropTypes.func,
};

export default PromoterForm;
