import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { trimUriSegments } from '../common/uri';
import Icon from '../common/icons/Icon';

const ContactLayout = () => {
  const location = useLocation();
  const params = useParams();

  const { contactId, listView, departmentType } = params;

  const renderButtons = () => {
    if (departmentType === 'media') return null;

    const segmentsToTrim = contactId ? 2 : 1;
    const basePath = `${trimUriSegments(location.pathname, segmentsToTrim)}`;

    return (
      <div className="contact-list__buttons">
        <Link
          className={`button button--small button--icon ${
            listView === 'calendar' ? 'button--outline' : ''
          }`}
          to={`${basePath}/list`}
        >
          <Icon icon="ListNonBullet" />
          <span>List</span>
        </Link>
        <Link
          className={`button button--small button--icon ${
            listView === 'list' ? 'button--outline' : ''
          }`}
          to={`${basePath}/calendar`}
        >
          <Icon icon="Calendar" />
          <span>Calendar</span>
        </Link>
      </div>
    );
  };

  return (
    <div>
      {renderButtons()}
      <Outlet />
    </div>
  );
};

export default ContactLayout;
