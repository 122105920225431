import { combineReducers } from 'redux';
import { Map } from 'immutable';
import getApiReducer, { defaultState } from '../common/utils/getApiReducer';
import {
  FETCH_DEPARTMENT_SLUGS,
  SET_DROPDOWN_RESULTS,
  CLEAR_DROPDOWN_RESULTS,
  SEARCHING,
  SET_NEXT_PAGE_GROUP,
} from './search-actions';
import { SUBMIT_DEPARTMENT } from '../department/department-actions';

const defaultResultsState = Map({
  dropdown: Map({
    contacts: Map(),
    departments: Map(),
  }),
  resultsPage: Map({
    contacts: Map(),
    departments: Map(),
  }),
});

const slugs = (state = defaultState, action) => {
  if (action.originalType === FETCH_DEPARTMENT_SLUGS) {
    return getApiReducer(FETCH_DEPARTMENT_SLUGS)(state, action);
  } else if (action.type === `${SUBMIT_DEPARTMENT}_SUCCESS`) {
    return state.setIn(['data', action.json.name], action.json.slug);
  }
  return state;
};

const results = (state = defaultResultsState, action) => {
  if (action.type === SET_DROPDOWN_RESULTS) {
    return state.setIn(['dropdown'], action.payload);
  } else if (action.type === SET_NEXT_PAGE_GROUP) {
    return state.setIn(['resultsPage'], action.payload);
  } else if (action.type === CLEAR_DROPDOWN_RESULTS) {
    return state.setIn(['dropdown'], defaultResultsState.get('dropdown'));
  }
  return state;
};

const searching = (state = false, action) => {
  switch (action.type) {
    case SEARCHING:
      return action.payload;
    default:
      return state;
  }
};

const reducers = combineReducers({
  slugs,
  results,
  searching,
});

export default reducers;
