import PropTypes from 'prop-types';
import CheckboxGroup from './CheckboxGroup';

const SelectAllCheckboxGroup = ({ onChange, options, value, ...rest }) => {
  const selectAll = event => {
    event.preventDefault();
    const newValue = !(value.length === options.length)
      ? options.reduce((arr, option) => arr.concat(option.value), [])
      : [];
    onChange(newValue);
  };

  return (
    <div>
      <button
        className="text-link"
        onClick={event => selectAll(event)}
        type="button"
      >
        <span>Select All</span>
      </button>
      <CheckboxGroup
        onChange={onChange}
        options={options}
        value={value}
        {...rest}
      />
    </div>
  );
};

SelectAllCheckboxGroup.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.any,
};

export default SelectAllCheckboxGroup;
