import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEventSettings } from './event-actions';
import { getCurrentEvent } from '../event/event-selectors';
import { fetchCredentialTypeCategoryList } from '../credential/type/credential-type-actions';
import LoadingIndicator from '../common/LoadingIndicator';

const EventLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const eventId = useSelector(state =>
    getCurrentEvent(state, { params })?.get('id'),
  );

  useEffect(() => {
    if (eventId) {
      dispatch(fetchEventSettings(eventId));
      dispatch(fetchCredentialTypeCategoryList(eventId));
    }
  }, [dispatch, eventId]);

  return eventId ? <Outlet /> : <LoadingIndicator />;
};

export default EventLoader;
