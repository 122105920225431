import { useState } from 'react';
import PropTypes from 'prop-types';
import FormSchema from '../../lib/form-schema';
import Icon from '../icons/Icon';

const fieldTypes = [
  { type: 'text', label: 'Text' },
  { type: 'textarea', label: 'Text Area' },
  { type: 'number', label: 'Number' },
  { type: 'checkbox', label: 'Checkbox' },
  { type: 'date', label: 'Date' },
  { type: 'time', label: 'Time' },
  { type: 'money', label: 'Money' },
  { type: 'select', label: 'Select' },
  { type: 'file', label: 'File' },
];

const components = {};
fieldTypes.forEach(fieldType => {
  components[fieldType.type] = require(
    `./builder-fields/${fieldType.type}`,
  ).default;
});

const FormBuilder = ({ event, value, onChange }) => {
  const [activeSelectValue, setActiveSelectValue] = useState('text');
  const [autoFocusIdx, setAutoFocusIdx] = useState(null);

  const onSelect = e => {
    setActiveSelectValue(e.target.value);
  };

  const onAdd = e => {
    e.preventDefault();
    const formSchema = value || new FormSchema();
    formSchema.addField(activeSelectValue);
    setAutoFocusIdx(formSchema.getImmutableSchema().get('fields').size - 1);
    onChange(formSchema);
  };

  const onRemove = (e, idx) => {
    e.preventDefault();
    const formSchema = value;
    formSchema.removeField(idx);
    onChange(formSchema);
  };

  const onFieldChange = (field, idx) => {
    const formSchema = value;
    formSchema.updateField(idx, field);
    onChange(formSchema);
  };

  const renderFieldForms = () => {
    if (!value) {
      return [];
    }

    const formSchema = value.getImmutableSchema();

    return formSchema.get('fields').map((field, idx) => {
      const Component = components[field.get('type')];
      return (
        <div className="form-builder__field" key={idx}>
          <Component
            event={event}
            field={field}
            autoFocus={idx === autoFocusIdx}
            onChange={updatedField => {
              onFieldChange(updatedField, idx);
            }}
            onFieldRemove={e => onRemove(e, idx)}
          />
        </div>
      );
    });
  };

  return (
    <div className="form-builder">
      {renderFieldForms()}
      <div className="form-builder__field form-builder__field--add">
        <div className="input-group input-group--small">
          <div className="input-scaffold">
            <select onChange={onSelect}>
              {fieldTypes.map(fieldType => (
                <option key={fieldType.type} value={fieldType.type}>
                  {fieldType.label}
                </option>
              ))}
            </select>
            <button
              className="button button--plain button--icon"
              onClick={onAdd}
              type="button"
            >
              <Icon icon="AddCircle" />
              <span>Add</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

FormBuilder.propTypes = {
  event: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default FormBuilder;
