import { useEffect, useRef } from 'react';
import { Field, useFormState } from 'react-final-form';
import { List, Map } from 'immutable';
import { resource, useACL } from '../common/ACL';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import CheckboxBoolean from '../common/forms/CheckboxBoolean';

const aclRules = {
  canEdit: [resource.DEPARTMENT, 'edit'],
};

const ContactInfoForm = ({ department, contact, relationships }) => {
  const acl = useACL(aclRules);
  const emailInputRef = useRef(null);

  const {
    values: { is_primary, relationship },
  } = useFormState();

  useEffect(() => {
    if (is_primary) emailInputRef.current.focus();
  }, [is_primary]);

  const shouldRenderPrimaryField =
    department.get('type') !== 'intern' &&
    (!contact || Boolean(contact.get('is_guest')) === false);

  return (
    <div className="contact-information-details">
      {shouldRenderPrimaryField && (
        <div className="contact-information-details__item contact-information-details__primary">
          <Field
            name="is_primary"
            required
            component={ReduxFormsFieldNoLabel}
            disabled={!acl.canEdit}
          >
            <CheckboxBoolean id="required" label="Make Primary" />
          </Field>
        </div>
      )}

      <div className="contact-information-details__item contact-information-details__email">
        <Field
          name="email"
          required
          component={ReduxFormsField}
          placeholder="+ Add Email"
          disabled={!acl.canEdit}
        >
          <input ref={emailInputRef} type="text" />
        </Field>
      </div>

      <div className="contact-information-details__item contact-information-details__phone">
        <Field
          name="phone"
          required
          component={ReduxFormsField}
          placeholder="+ Add Phone"
          disabled={!acl.canEdit}
        >
          <input type="text" />
        </Field>
      </div>
      {relationships?.size > 0 ? (
        <div
          className={classNames({
            'staff-details__relationship': true,
            'staff-details__relationship-no-value': !relationship,
            'contact-information-details__item': true,
            'contact-information-details__relationship': true,
          })}
        >
          <Field
            name="relationship"
            required={false}
            component={ReduxFormsField}
            disabled={!acl.canEdit}
          >
            <select>
              <option value={''}>{'+ Role'}</option>
              {relationships.map((relationship, index) => (
                <option key={`${relationship}-${index}`} value={relationship}>
                  {relationship}
                </option>
              ))}
            </select>
          </Field>
        </div>
      ) : null}
      <div className="contact-information-details__item contact-information-details__other-role">
        <Field
          name="other_role"
          required
          component={ReduxFormsField}
          placeholder="+ Role (text field)"
          disabled={!acl.canEdit}
        >
          <input />
        </Field>
      </div>
    </div>
  );
};

ContactInfoForm.propTypes = {
  contact: PropTypes.instanceOf(Map),
  department: PropTypes.instanceOf(Map),
  relationships: PropTypes.instanceOf(List),
};

export default ContactInfoForm;
