import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import Paper from '../common/paper/Paper';

const FullPageMessage = ({ children, icon = 'Sad', message }) => {
  return (
    <Paper className="generic-list--empty">
      {icon && <Icon icon={icon} />}
      {message && <p>{message}</p>}
      {children}
    </Paper>
  );
};

FullPageMessage.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  message: PropTypes.string,
};

export default FullPageMessage;
