import PropTypes from 'prop-types';
import { getCredentialDateRange } from '../common/utils/credentials/credential-utils';

const ContactCredentialDateRange = ({ credential }) => (
  <div className="date-item">
    <div className="date-item__days">{getCredentialDateRange(credential)}</div>
  </div>
);

ContactCredentialDateRange.propTypes = {
  credential: PropTypes.object.isRequired,
};

export default ContactCredentialDateRange;
