import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { Map } from 'immutable';
import { GROUP_UNASSIGNED } from './ContactCredentials';
import { isGroupCredentialAssigned } from './contact-selectors';
import { useMaximumRequestableParameters } from './DepartmentUnsavedCredentials';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import ContactCredentialDateRange from './ContactCredentialDateRange';
import ContactRequestQuantity from './ContactRequestQuantity';
import SavedQuantityEdit from './SavedQuantityEdit';

const ContactCredentialRequest = ({
  type,
  department,
  credentialRequest,
  issued,
  canEdit,
  isLockDateActive,
  onSaving = () => {},
  onDeleting = () => {},
}) => {
  const [editing, setEditing] = useState(false);

  const canDeleteOverride = useSelector(
    state =>
      type === GROUP_UNASSIGNED &&
      isGroupCredentialAssigned(state, {
        department,
        credReq: credentialRequest,
      }),
  );

  const { maximumExceededBy, maximumRequestable, available } =
    useMaximumRequestableParameters(
      department,
      credentialRequest.get('credential_id'),
    );

  const dataTooltipId = `maximum-requestable-tooltip-${credentialRequest.get(
    'id',
  )}`;

  return (
    <div className="contact-credentials__item contact-credentials__display">
      <div className="contact-credentials__display-item contact-credentials__date">
        {!editing || maximumExceededBy < 1 || maximumRequestable === null ? (
          <ContactCredentialDateRange
            credential={credentialRequest.get('credential')}
          />
        ) : (
          <div
            style={{
              backgroundColor: 'pink',
              padding: '5px',
              width: '162px',
            }}
          >
            department maximum exceeded
          </div>
        )}
      </div>

      {canEdit && !editing ? (
        <div
          className="contact-credentials__edit"
          onClick={() => setEditing(true)}
        >
          <button type="button">
            <Icon icon="Pencil" />
          </button>
        </div>
      ) : null}

      {editing ? (
        <SavedQuantityEdit
          type={type}
          department={department}
          credentialRequest={credentialRequest}
          issued={issued}
          cancelEdit={() => setEditing(false)}
          isLockDateActive={isLockDateActive}
          maximumExceeded={maximumExceededBy > 0}
          onSaving={onSaving}
        />
      ) : (
        <div
          className="contact-credentials__display-item contact-credentials__qty"
          data-tooltip-id={dataTooltipId}
          data-tooltip-hidden={
            (editing && maximumExceededBy > 0) || maximumRequestable === null
          }
        >
          <ContactRequestQuantity
            credentialRequest={credentialRequest}
            status="pending"
            canEdit={canEdit}
            canDeleteOverride={canDeleteOverride}
            onDeleting={onDeleting}
          />
          <ContactRequestQuantity
            credentialRequest={credentialRequest}
            issued={issued}
            status="approved"
            canEdit={canEdit}
            canDeleteOverride={canDeleteOverride}
            onDeleting={onDeleting}
          />
          <ContactRequestQuantity
            credentialRequest={credentialRequest}
            status="rejected"
            canEdit={canEdit}
            canDeleteOverride={canDeleteOverride}
            onDeleting={onDeleting}
          />
          <ContactRequestQuantity
            credentialRequest={credentialRequest}
            issued={issued}
            status="zero"
            canEdit={canEdit}
            canDeleteOverride={canDeleteOverride}
            onDeleting={onDeleting}
          />
          <Tooltip id={dataTooltipId} offset={10}>
            {available} are requestable <br></br> from a maximum of{' '}
            {maximumRequestable} <br></br> for this department
          </Tooltip>
        </div>
      )}
    </div>
  );
};

ContactCredentialRequest.propTypes = {
  type: PropTypes.string,
  department: PropTypes.instanceOf(Map).isRequired,
  credentialRequest: PropTypes.instanceOf(Map).isRequired,
  issued: PropTypes.number,
  canEdit: PropTypes.bool,
  isLockDateActive: PropTypes.bool,
  onSaving: PropTypes.func,
  onDeleting: PropTypes.func,
};

export default ContactCredentialRequest;
