import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { combineValidators, isRequired } from 'revalidate';
import { trimUriSegments } from '../common/uri';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { getCurrentEvent } from './event-selectors';
import {
  convertRoleBooleansToRoleArray,
  getDefaultRoleValues,
} from './event-settings-helpers';
import {
  clearInviteEventAdmin,
  fetchEventAdminList,
  inviteEventAdmin,
} from './event-actions';
import { showNotification } from '../notification/notification-actions';
import recursiveTrim from '../common/utils/recursive-trim';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import EventAdminPermissionsForm, {
  NO_ROLES_ERROR,
} from './EventAdminPermissionsForm';

const validate = combineValidators({
  first_name: isRequired('First Name'),
  last_name: isRequired('Last Name'),
  email: isRequired('Email'),
});

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  roles: getDefaultRoleValues(),
};

const EventAdminInvite = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const userListPath = trimUriSegments(location.pathname, 1);

  const handleSubmit = values => {
    const payload = recursiveTrim(values);
    payload.roles = convertRoleBooleansToRoleArray(payload.roles);

    if (payload.roles.length === 0) {
      return { [FORM_ERROR]: NO_ROLES_ERROR };
    }

    return dispatch(inviteEventAdmin(event.get('id'), payload)).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      }
      dispatch(
        showNotification({
          message: 'Section admin successfully invited.',
          status: 'success',
        }),
      );
      dispatch(fetchEventAdminList(event.get('id')));
      dispatch(clearInviteEventAdmin());
      navigate(userListPath);
    });
  };
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <form className="generic-form paper__wrapper" onSubmit={handleSubmit}>
          <Paper>
            <PaperHeader
              backTo={userListPath}
              backToDisabled={submitting}
              title="Invite Admin"
            />
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                <Field
                  name="first_name"
                  component={ReduxFormsField}
                  label="First Name"
                  placeholder="First Name"
                  value="test"
                  required
                >
                  <input type="text" />
                </Field>
                <Field
                  name="last_name"
                  component={ReduxFormsField}
                  label="Last Name"
                  placeholder="Last Name"
                  required
                >
                  <input type="text" />
                </Field>
                <Field
                  name="email"
                  component={ReduxFormsField}
                  label="Email"
                  placeholder="user@email.com"
                  required
                >
                  <input type="text" />
                </Field>
              </div>
            </div>
          </Paper>
          <EventAdminPermissionsForm cancelLink={userListPath} />
        </form>
      )}
    />
  );
};

export default EventAdminInvite;
