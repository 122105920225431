import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from '../department/department-selectors';
import { useReports } from './Reports';
import { fetchDownload, getReportUrl } from './reports-utils';
import { Container, Header } from './ReportsContainer.styles';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import Report from './Report';

export const EventReportsContainer = () => {
  const params = useParams();
  const event = useSelector(state => getCurrentEvent(state, { params }));

  return <ReportsContainer title="Event Reports" resource={event} />;
};

export const DepartmentReportsContainer = () => {
  const params = useParams();
  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  return <ReportsContainer title="Department Reports" resource={department} />;
};

const ReportsContainer = ({ title, resource }) => {
  const resourceType = resource?.get('resource_id');
  const resourceId = resource?.get('id');
  const resourceSlug = resource?.get('slug');

  const reports = useReports(resourceType, resourceId);

  if (!reports) return <LoadingIndicator />;

  const navigateToReport = (title, reportSlug, parameters) =>
    window.open(
      getReportUrl(
        resourceType,
        resourceId,
        resourceSlug,
        title,
        reportSlug,
        true,
        parameters,
      ),
      '_blank',
    );

  return (
    <div>
      <Container>
        <Header>
          <Paper>
            <PaperHeader title={title} />
            {reports.length === 0 && (
              <div className="generic-list--empty">
                <Icon icon="Sad" />
                <p>No reports are available</p>
              </div>
            )}
          </Paper>
        </Header>
        {reports.map((report, index) => (
          <Report
            key={index}
            report={report}
            navigateToReport={navigateToReport}
            fetchDownload={payload =>
              fetchDownload(resourceType, resourceId, resourceSlug, payload)
            }
          />
        ))}
      </Container>
    </div>
  );
};
