import Client, { JwtPlugin } from './lib/fetch-client';
import storage from 'store2';
import config from './config';

const client = new Client({
  url: '/api',
  timeout: config.apiTimeout ?? 10000,
});

client.addPlugin(new JwtPlugin());
client.setJwtTokenGetter(() => storage.session('idToken'));

export const publicClient = new Client({
  url: '/api',
  timeout: config.apiTimeout ?? 10000,
});

export default client;
