import client from '../client';

export const FETCH_EVENT_ONSITE_REQUESTS = 'FETCH_EVENT_ONSITE_REQUESTS';
export function fetchEventOnsiteRequests(eventId) {
  return {
    eventId,
    type: FETCH_EVENT_ONSITE_REQUESTS,
    promise: client.get(`events/${eventId}/onsite/requests`),
  };
}

export const DELETE_ONSITE_CREDENTIAL_REQUEST =
  'DELETE_ONSITE_CREDENTIAL_REQUEST';
export function deleteOnsiteCredentialRequest(requestId, credentialRequestId) {
  return {
    type: DELETE_ONSITE_CREDENTIAL_REQUEST,
    promise: client.delete(
      `onsite/requests/${requestId}/credential-requests/${credentialRequestId}`,
    ),
  };
}

export const APPROVE_ONSITE_REQUEST = 'APPROVE_ONSITE_REQUEST';
export function approveOnsiteRequest(
  requestId,
  notes,
  approvePendingCredentials,
) {
  return {
    type: APPROVE_ONSITE_REQUEST,
    promise: client.put(`onsite/requests/${requestId}/approve`, {
      notes,
      approvePendingCredentials,
    }),
  };
}

export const DECLINE_ONSITE_REQUEST = 'DECLINE_ONSITE_REQUEST';
export function declineOnsiteRequest(
  requestId,
  notes,
  declinePendingCredentials,
) {
  return {
    type: DECLINE_ONSITE_REQUEST,
    promise: client.put(`onsite/requests/${requestId}/decline`, {
      notes,
      declinePendingCredentials,
    }),
  };
}

export const ONSITE_REQUEST_NAME_CHANGE = 'ONSITE_REQUEST_NAME_CHANGE';
export function requestNameChange(contact, first_name, last_name) {
  return {
    type: ONSITE_REQUEST_NAME_CHANGE,
    promise: client.post(`onsite/requests/name-change`, {
      contact: contact.get('id'),
      first_name,
      last_name,
    }),
  };
}

export const ONSITE_REQUEST_ADD_CONTACT = 'ONSITE_REQUEST_ADD_CONTACT';
export function requestAddContact(
  department,
  first_name,
  last_name,
  email,
  credentials,
  notes,
) {
  return {
    type: ONSITE_REQUEST_ADD_CONTACT,
    promise: client.post(`onsite/requests/add-contact`, {
      department: department.get('id'),
      first_name,
      last_name,
      email,
      credentials,
      notes,
    }),
  };
}

export const ONSITE_REQUEST_EDIT_CONTACT = 'ONSITE_REQUEST_EDIT_CONTACT';
export function requestEditContact(
  department,
  contact,
  email,
  credentials,
  notes,
) {
  return {
    type: ONSITE_REQUEST_EDIT_CONTACT,
    promise: client.post(`onsite/requests/edit-contact`, {
      department: department.get('id'),
      contact: contact.get('id'),
      email,
      credentials,
      notes,
    }),
  };
}

export const ONSITE_REQUEST_DELETE_CONTACT = 'ONSITE_REQUEST_DELETE_CONTACT';
export function requestDeleteContact(contact, notes) {
  return {
    type: ONSITE_REQUEST_DELETE_CONTACT,
    promise: client.post(`onsite/requests/delete-contact`, {
      contact: contact.get('id'),
      notes,
    }),
  };
}
