import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentDepartment } from '../department/department-selectors';
import { getCurrentEvent } from '../event/event-selectors';
import { getEmailsByRecipient } from './group-credential-selectors';
import {
  getGroupCredentialEmails,
  getGroupCredentialLinkEmailTemplates,
  sendGroupCredentialEmail,
} from './group-credential-actions';
import LoadingIndicator from '../common/LoadingIndicator';
import EmailHistory from '../email/EmailHistory';
import SendLinkEmail from '../email/SendLinkEmail';

const SendCredentialLinkEmail = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const departmentId = department?.get('id');

  const emails = useSelector(state => getEmailsByRecipient(state));

  const [emailTemplates, setEmailTemplates] = useState(null);

  useEffect(() => {
    if (departmentId) {
      dispatch(getGroupCredentialEmails(departmentId));
    }
  }, [departmentId, dispatch]);

  useEffect(() => {
    if (event && department) {
      dispatch(
        getGroupCredentialLinkEmailTemplates(
          event.get('id'),
          department.get('type'),
        ),
      ).then(action => {
        if (action.response.ok) {
          setEmailTemplates(action.json);
        }
      });
    }
  }, [department, dispatch, event]);

  const sendEmail = payload => sendGroupCredentialEmail(departmentId, payload);

  const onEmailSent = () => {
    dispatch(getGroupCredentialEmails(departmentId));
  };

  return !emailTemplates ? (
    <LoadingIndicator />
  ) : (
    <>
      <SendLinkEmail
        title="Send Credential Link"
        emailTemplates={emailTemplates}
        sendEmail={sendEmail}
        onEmailSent={onEmailSent}
      />
      {!emails.isEmpty() && <EmailHistory emails={emails} />}
    </>
  );
};

export default SendCredentialLinkEmail;
