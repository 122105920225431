import { createSelector } from 'reselect';
import { getCurrentDepartment } from '../department/department-selectors';
import { FILE_UPLOAD_TYPE, PLACEHOLDER_UPLOAD_TYPE } from './contact-actions';
import { GROUP_PERSONNEL, PLACEHOLDER_STAFF } from './ContactCredentials';
import sortContacts from './sort-contacts';

const getQuantityApprovedFromDepartmentAndCredential = createSelector(
  (department, credential) =>
    department
      .get('credentialRequests')
      .find(
        credReq =>
          credReq.get('credential_id') === credential.get('id') &&
          credReq.get('requested_for') === null,
      ),
  credentialRequests =>
    credentialRequests && credentialRequests.get('quantity_approved'),
);

export const getMaxForUnsavedQuantity = createSelector(
  type => type,
  (_, department, credential) =>
    getQuantityApprovedFromDepartmentAndCredential(department, credential),
  (type, quantityApproved) => {
    if (type === GROUP_PERSONNEL) {
      return quantityApproved;
    } else if (type === PLACEHOLDER_STAFF) {
      return 1;
    }
    return null;
  },
);

export const getStaffContactList = createSelector(
  getCurrentDepartment,
  state => state.contact.contactList,
  (department, contactList) =>
    department
      ? contactList
          .getIn([department.get('id'), 'data'])
          ?.filter(contact => !contact.get('is_guest'))
      : null,
);

export const getStaffContactListLoaded = createSelector(
  getCurrentDepartment,
  state => state.contact.contactList,
  (department, contactList) =>
    contactList.getIn([department?.get('id'), 'loaded']) ?? false,
);

export const getCurrentContact = createSelector(
  getStaffContactList,
  (_, props) => props.params.contactId,
  (contacts, contactId) =>
    contacts?.find(contact => String(contact.get('id')) === String(contactId)),
);

export const isGroupCredentialAssigned = createSelector(
  (state, ownProps) => {
    let groupCredentialIsAssigned = false;
    if (
      state.contact &&
      state.contact.contactList &&
      state.contact.contactList.getIn([ownProps.department.get('id'), 'data'])
    ) {
      const groupContactsForDept = state.contact.contactList
        .getIn([ownProps.department.get('id'), 'data'])
        .filter(contact => contact.get('is_group'));

      for (let j = 0; j < groupContactsForDept.size; j++) {
        if (
          groupContactsForDept
            .getIn([j, 'credentialRequests'])
            .filter(
              cr =>
                cr.get('credential_id') ===
                  ownProps.credReq.get('credential_id') &&
                cr.get('quantity_approved') > 0,
            ).size
        )
          groupCredentialIsAssigned = true;
      }
    }
    return groupCredentialIsAssigned;
  },
  stuff => stuff,
);

export const getCalendarContactList = createSelector(
  getCurrentDepartment,
  state => state.contact.contactList,
  (department, contactList) =>
    department
      ? sortContacts(
          contactList
            .getIn([department.get('id'), 'data'])
            ?.filter(contact => !contact.get('is_guest')),
        )
      : null,
);

export const getContactUploads = createSelector(
  state => state.contact.uploads,
  (_, departmentId) => departmentId,
  (uploads, departmentId) =>
    uploads.getIn([departmentId, 'data'])?.toJSON() ?? [],
);

export const areContactUploadsProcessing = createSelector(
  getContactUploads,
  uploads => uploads?.some(upload => upload.status === 'Processing') ?? false,
);

export const getContactFileUploads = createSelector(
  getContactUploads,
  uploads =>
    uploads?.filter(upload => upload.upload_type === FILE_UPLOAD_TYPE) ?? [],
);

export const getContactPlaceholderUploads = createSelector(
  getContactUploads,
  uploads =>
    uploads?.filter(upload => upload.upload_type === PLACEHOLDER_UPLOAD_TYPE) ??
    [],
);
