import client from '../client';

export const GET_ACTIVITY_LOG = 'GET_ACTIVITY_LOG';
export function getActivityLog(eventId, departmentId, page, pagesize, sorted) {
  return {
    type: GET_ACTIVITY_LOG,
    promise: departmentId
      ? client.get(
          `events/${eventId}/action-logs?department=${departmentId}&page=${page}&pagesize=${pagesize}&sorted=${JSON.stringify(
            sorted,
          )}`,
        )
      : client.get(
          `events/${eventId}/action-logs?page=${page}&pagesize=${pagesize}&sorted=${JSON.stringify(
            sorted,
          )}`,
        ),
  };
}
