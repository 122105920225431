import React from 'react';

function Anchor() {
  /* eslint-disable max-len */
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M-5-5h23.964v23.964H-5" />
        <path
          d="M7.48 1.994c0-.135-.05-.252-.15-.35-.098-.1-.215-.148-.35-.148-.135 0-.252.05-.35.148-.1.098-.15.215-.15.35 0 .135.05.252.15.35.098.1.215.15.35.15.135 0 .252-.05.35-.15.1-.098.15-.215.15-.35zm6.48 7.23v2.742c0 .114-.052.192-.156.233-.04.01-.073.01-.093.01-.06 0-.12-.03-.17-.07l-.72-.73c-.62.74-1.44 1.33-2.48 1.76-1.03.43-2.15.65-3.34.65s-2.31-.22-3.34-.65c-1.03-.44-1.86-1.02-2.48-1.77l-.72.72c-.047.04-.107.07-.18.07-.02 0-.05-.01-.09-.02-.107-.04-.16-.12-.16-.238V9.22c0-.072.026-.132.07-.18.05-.045.11-.07.18-.07H3c.117 0 .195.053.236.157.04.1.024.19-.054.273l-.78.78c.35.47.84.87 1.477 1.195.63.324 1.34.538 2.11.642V6.98h-1.5c-.14 0-.26-.05-.35-.148-.1-.1-.15-.216-.15-.35v-.998c0-.135.04-.252.14-.35.09-.1.21-.148.35-.148h1.49v-1.27c-.3-.177-.55-.417-.73-.72-.18-.304-.27-.638-.27-1.002 0-.55.19-1.02.58-1.41C5.96.194 6.43 0 6.98 0S8 .195 8.39.584c.39.39.584.86.584 1.41 0 .364-.09.698-.272 1-.182.305-.424.545-.725.722v1.27h1.496c.135 0 .252.05.35.148.1.098.148.215.148.35v.997c0 .14-.04.26-.14.36-.1.1-.21.15-.35.15h-1.5v5.04c.78-.1 1.48-.31 2.12-.64.64-.32 1.13-.72 1.48-1.19l-.78-.78c-.07-.08-.09-.17-.05-.27.04-.1.12-.15.24-.15h2.74c.07 0 .13.03.18.07.05.05.07.11.07.18z"
          fill="#201C23"
        />
      </g>
    </svg>
  );
  /* eslint-enable */
}

export default Anchor;
