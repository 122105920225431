import { Checkbox } from 'synfrastructure';
import translateErrors from '../translateErrors';
import PropTypes from 'prop-types';
import InputScaffold from '../../forms/InputScaffold';
import ToggleScaffold from '../../forms/ToggleScaffold';
import Icon from '../../icons/Icon';

const Number = ({ field, autoFocus, onFieldRemove, onChange }) => (
  <div className="input-group input-group--large">
    <div className="custom-request-form__field--title">
      <h3>Number Field</h3>
      <button
        className="button button--plain button--icon"
        onClick={onFieldRemove}
        type="button"
      >
        <Icon icon="Trash" />
        <span>Remove</span>
      </button>
    </div>

    <InputScaffold
      label="Label"
      required
      validation={translateErrors(field.get('error')?.label)}
    >
      <input
        type="text"
        name="label"
        value={field.get('label') || ''}
        onChange={e => onChange(field.set('label', e.target.value))}
        autoFocus={autoFocus}
      />
    </InputScaffold>

    <InputScaffold
      label="Default Value"
      validation={translateErrors(field.get('error')?.value)}
    >
      <input
        type="number"
        name="value"
        value={field.get('value') || ''}
        onChange={e => onChange(field.set('value', e.target.value))}
      />
    </InputScaffold>

    <InputScaffold
      label="Description"
      validation={translateErrors(field.get('error')?.description)}
    >
      <textarea
        name="description"
        value={field.get('description') || ''}
        onChange={e => onChange(field.set('description', e.target.value))}
      />
    </InputScaffold>

    <ToggleScaffold
      label="Required"
      validation={translateErrors(field.get('error')?.required)}
    >
      <Checkbox
        id="required"
        name="required"
        checked={!!field.get('required')}
        onChange={e => onChange(field.set('required', e.target.checked))}
      />
    </ToggleScaffold>
  </div>
);

Number.propTypes = {
  field: PropTypes.object,
  onFieldRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default Number;
