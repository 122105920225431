import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { resource, useACL } from '../../common/ACL';
import { getPeriodList } from './period-selectors';
import moment from 'moment';
import Icon from '../../common/icons/Icon';
import LoadingIndicator from '../../common/LoadingIndicator';
import Paper, { PaperHeader } from '../../common/paper/Paper';
import ReactTable from '../../lib/react-table';

const getFormattedDate = date => moment.utc(date).format('MM/DD/YYYY');

const aclRules = {
  canAddPeriod: [resource.EVENT, 'edit-credential'],
};

const PeriodList = () => {
  const acl = useACL(aclRules);

  const navigate = useNavigate();
  const params = useParams();

  const periodList = useSelector(state => getPeriodList(state, { params }));

  const data = useMemo(
    () =>
      periodList?.size
        ? periodList
            .map(period => ({
              id: period.get('id'),
              name: period.get('name'),
              date: {
                startDate: period.get('start_date'),
                endDate: period.get('end_date'),
              },
            }))
            .toJS()
        : null,
    [periodList],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Date',
        accessor: 'date',
        sortMethod: (a, b) => {
          return a.startDate - b.startDate;
        },
        Cell: row => (
          <span>{`${getFormattedDate(row.value.startDate)} - ${getFormattedDate(
            row.value.endDate,
          )}`}</span>
        ),
      },
      {
        sortable: false,
        filterable: false,
        width: 64,
        className: 'action action--animate',
        Cell: () => <Icon icon="ArrowRight" />,
      },
    ],
    [],
  );

  const getAddPeriodPath = () =>
    `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/credentials/periods/add`;

  const getViewPeriodPath = periodId =>
    `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/credentials/periods/${periodId}`;

  const onClickRow = periodId => {
    navigate(getViewPeriodPath(periodId));
  };

  const renderPeriodList = () => {
    return (
      <ReactTable
        data={data}
        columns={columns}
        defaultSorted={[
          {
            id: 'date',
            asc: true,
          },
        ]}
        minRows={0}
        defaultPageSize={20}
        showPagination={data.length > 20}
        showPageSizeOptions={false}
        getTrGroupProps={(_state, rowInfo) => ({
          onClick: () => onClickRow(rowInfo.row._original.id),
        })}
      />
    );
  };

  const renderEmpty = () => (
    <div className="generic-list--empty">
      <Icon icon="Sad" />
      <p>You have not added any periods yet to this event.</p>
    </div>
  );

  if (!periodList) {
    return <LoadingIndicator />;
  }

  return (
    <Paper>
      <PaperHeader
        title="Periods"
        actions={
          acl.canAddPeriod ? (
            <Link
              className="button button--plain button--icon"
              to={getAddPeriodPath()}
            >
              <Icon icon="AddCircle" />
              <span>Add Period</span>
            </Link>
          ) : null
        }
      />
      {!periodList.size ? renderEmpty() : renderPeriodList()}
    </Paper>
  );
};

export default PeriodList;
