import getApplicationActions from '../common/application/get-application-actions';

const actions = getApplicationActions('intern');

export const GET_INTERN_APPLICATION = actions.GET_INTERN_APPLICATION;
export const getInternApplications = actions.getApplications;

export const GET_INTERN_APPLICATION_RESPONSES =
  actions.GET_INTERN_APPLICATION_RESPONSES;
export const getInternApplicationResponses = actions.getApplicationResponses;

export const SAVE_INTERN_APPLICATION = actions.SAVE_INTERN_APPLICATION;
export const saveInternApplication = actions.saveApplication;

export const DELETE_INTERN_APPLICATION = actions.DELETE_INTERN_APPLICATION;
export const deleteInternApplication = actions.deleteApplication;

export const CREATE_INTERN_APPLICATION_SECTION =
  actions.CREATE_INTERN_APPLICATION_SECTION;
export const createInternApplicationSection = actions.createApplicationSection;

export const CREATE_OR_UPDATE_INTERN_APPLICATION_SECTION =
  actions.CREATE_OR_UPDATE_INTERN_APPLICATION_SECTION;
export const createOrUpdateInternApplicationSection =
  actions.createOrUpdateApplicationSection;

export const UPDATE_INTERN_APPLICATION_SECTION =
  actions.UPDATE_INTERN_APPLICATION_SECTION;
export const updateInternApplicationSection = actions.updateApplicationSection;

export const DELETE_INTERN_APPLICATION_SECTION =
  actions.DELETE_INTERN_APPLICATION_SECTION;
export const deleteInternApplicationSection = actions.deleteApplicationSection;

export const GET_INTERN_APPLICATION_PUBLIC =
  actions.GET_INTERN_APPLICATION_PUBLIC;
export const getInternApplicationPublic = actions.getApplicationPublic;

export const UPDATE_INTERN_APPLICATION_SECTION_PUBLIC =
  actions.UPDATE_INTERN_APPLICATION_SECTION_PUBLIC;
export const updateInternApplicationSectionPublic =
  actions.updateApplicationSectionPublic;

export const UPDATE_INTERN_APPLICATION_SECTION_SORT_ORDERS =
  actions.UPDATE_INTERN_APPLICATION_SECTION_SORT_ORDERS;
export const updateInternApplicationSectionSortOrders =
  actions.updateApplicationSectionSortOrders;

export const SAVE_INTERN_APPLICATION_PUBLIC =
  actions.SAVE_INTERN_APPLICATION_PUBLIC;
export const saveInternApplicationPublic = actions.saveApplicationPublic;

export const GET_INTERN_APPLICATIONS_FOR_EVENT =
  actions.GET_INTERN_APPLICATIONS_FOR_EVENT;
export const getApplicationListForEvent = actions.getApplicationListForEvent;

export const APPROVE_INTERN_APPLICATION = actions.APPROVE_INTERN_APPLICATION;
export const approveApplication = actions.approveApplication;

export const DENY_INTERN_APPLICATION = actions.DENY_INTERN_APPLICATION;
export const denyApplication = actions.denyApplication;

export const GET_INTERN_APPLICATION_EMAIL_TYPES =
  actions.GET_INTERN_APPLICATION_EMAIL_TYPES;
export const getInternApplicationEmailTypes = actions.getApplicationEmailTypes;
