import { createSelector } from 'reselect';

const getDropdown = state => state.search.results.get('dropdown');
const getResultsPage = state => state.search.results.get('resultsPage');

const sortResults = (departments, contacts) =>
  [...departments, ...contacts].sort((a, b) => b.relevance - a.relevance);

export const getSearchDepartmentSlugs = state => state.search.slugs.get('data');

export const getSearchResultsDepartments = createSelector(
  getResultsPage,
  resultsPage => resultsPage?.get('departments')?.valueSeq().toJS(),
);

export const getSearchResultsContacts = createSelector(
  getResultsPage,
  resultsPage => resultsPage?.get('contacts')?.valueSeq().toJS(),
);

export const getSearchResults = createSelector(
  getSearchResultsDepartments,
  getSearchResultsContacts,
  sortResults,
);

export const getSearchDropdownDepartments = createSelector(
  getDropdown,
  dropdown => dropdown?.get('departments')?.valueSeq().toJS(),
);

export const getSearchDropdownContacts = createSelector(getDropdown, dropdown =>
  dropdown?.get('contacts')?.valueSeq().toJS(),
);

export const getSearchDropdown = createSelector(
  getSearchDropdownDepartments,
  getSearchDropdownContacts,
  sortResults,
);
