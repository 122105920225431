import { createSelector } from 'reselect';

export const getPromoterList = state => state.promoter.list.get('data');
export const getPromoterListLoaded = state => state.promoter.list.get('loaded');

export const getCurrentPromoter = createSelector(
  getPromoterList,
  (_, props) => props.params.promoterSlug,
  (promoterList, promoterSlug) =>
    promoterList && promoterSlug
      ? promoterList.find(promoter => promoter.get('slug') === promoterSlug)
      : null,
);
