import { useState } from 'react';
import { Map } from 'immutable';
import moment from 'moment';
import PropTypes from 'prop-types';
import ExpandableRow from '../common/ExpandableRow';
import FormField from '../common/forms/FormField';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import QuantityField from '../common/forms/QuantityField';

const CateringPeriodRequest = ({ period, values }) => {
  const [expanded, setExpanded] = useState(false);

  const headerTitle = `${period.get('name')}
      (${moment.utc(period.get('startDate')).format('MMM D')} -
      ${moment.utc(period.get('endDate')).format('MMM D, YYYY')})`;

  const isDeadlineDateActive = mealType => {
    if (mealType.get('isDeadlineOverridden')) {
      return false;
    }
    const deadlineDate = mealType.get('deadline_date');
    const hasDeadlineTimePassed = deadlineDate
      ? new Date(Date.now()) > new Date(deadlineDate)
      : false;
    return hasDeadlineTimePassed;
  };

  const renderMealForm = (meal, mealType) => {
    const mealTypeDateId = meal.get('mealTypeDateId');
    const value = values.get(mealTypeDateId);

    if (value) {
      return (
        <td key={meal.get('mealTypeDateId')}>
          <div className="meals-needed">
            <div className="meals-needed-field">
              <div className="meals-needed-field__label">Available</div>
              <FormField
                name={`mealCount_${mealTypeDateId}`}
                component={ReduxFormsField}
                disabled={value.disabled || isDeadlineDateActive(mealType)}
                value={value.mealCount}
              >
                <QuantityField alwaysShowMinus value={value.mealCount} />
              </FormField>
            </div>
          </div>
        </td>
      );
    } else {
      return <td key={meal.get('mealTypeDateId')} />;
    }
  };

  const renderTable = () => (
    <div className="catering-table-wrapper">
      <table>
        <thead>
          <tr>
            <th style={{ verticalAlign: 'middle' }}>
              <span>Period</span>
            </th>
            {period.get('mealTypes').map(mealType => (
              <th key={mealType.get('id')}>
                <div className="meals-needed">
                  <div className="meals-needed-field">
                    {mealType.get('name')}
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {period
            .get('dates')
            .sortBy(periodDate => periodDate.get('periodDate'))
            .map(periodDate => (
              <tr key={periodDate.get('periodDateId')}>
                <td className="catering-table-date">
                  <div className="meals-needed">
                    <div className="meals-needed-field">
                      <span style={{ fontWeight: 'bold' }}>
                        {moment
                          .utc(periodDate.get('periodDate'))
                          .format('ddd MMM D')}
                      </span>
                    </div>
                  </div>
                </td>
                {period.get('mealTypes').map((mealType, index) => {
                  const meal = mealType
                    .get('dates')
                    .find(
                      mealTypeDate =>
                        mealTypeDate.get('periodDateId') ===
                        periodDate.get('periodDateId'),
                    );
                  if (!meal) {
                    return (
                      <td
                        className="catering-table-no-meal"
                        key={`no-meal-${periodDate.get(
                          'periodDateId',
                        )}-${index}`}
                      >
                        N/A
                      </td>
                    );
                  } else {
                    return renderMealForm(meal, mealType);
                  }
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );

  if (new Date(period.get('endDate')) < Date.now()) {
    return null;
  }

  return (
    <div className="catering-period-summary">
      <ExpandableRow
        isExpandable
        isExpanded={expanded}
        onToggleExpansion={() => setExpanded(!expanded)}
        columns={[headerTitle]}
      >
        {renderTable()}
      </ExpandableRow>
    </div>
  );
};

CateringPeriodRequest.propTypes = {
  period: PropTypes.instanceOf(Map).isRequired,
  values: PropTypes.object.isRequired,
};

export default CateringPeriodRequest;
