import client, { publicClient } from '../../client';
import dataUrlToFile from '../utils/dataUrlToFile';
import _ from 'lodash';

const applicationActions = type => {
  const actions = {};
  const typeUpper = type.toUpperCase();

  actions[
    `GET_${typeUpper}_APPLICATION_EMAIL_TYPES`
  ] = `GET_${typeUpper}_APPLICATION_EMAIL_TYPES`;
  actions.getApplicationEmailTypes = () => ({
    type: `GET_${typeUpper}_APPLICATION_EMAIL_TYPES`,
    promise: client.get(`${type}-application/email/types`),
  });

  actions[
    `GET_${typeUpper}_APPLICATION_AVAILABLE_CREDENTIALS`
  ] = `GET_${typeUpper}_APPLICATION_AVAILABLE_CREDENTIALS`;
  actions.getApplicationAvailableCredentials = (type, eventId) => ({
    type: `GET_${typeUpper}_APPLICATION_AVAILABLE_CREDENTIALS`,
    eventId,
    promise: client.get(
      `events/${eventId}/${type}-application/available-credentials`,
    ),
  });

  actions[`GET_${typeUpper}_APPLICATIONS`] = `GET_${typeUpper}_APPLICATIONS`;
  actions.getApplications = eventId => ({
    type: `GET_${typeUpper}_APPLICATIONS`,
    eventId,
    promise: client.get(`events/${eventId}/${type}-applications`),
  });

  actions[
    `DELETE_${typeUpper}_APPLICATION`
  ] = `DELETE_${typeUpper}_APPLICATION`;
  actions.deleteApplication = (eventId, applicationId) => ({
    type: `DELETE_${typeUpper}_APPLICATION`,
    eventId,
    applicationId,
    promise: client.delete(
      `events/${eventId}/${type}-application/${applicationId}`,
    ),
  });

  actions[
    `GET_${typeUpper}_APPLICATION_RESPONSES`
  ] = `GET_${typeUpper}_APPLICATION_RESPONSES`;
  actions.getApplicationResponses = departmentId => ({
    type: `GET_${typeUpper}_APPLICATION_RESPONSES`,
    promise: client.get(`departments/${departmentId}/${type}-application`),
  });

  actions[`SAVE_${typeUpper}_APPLICATION`] = `SAVE_${typeUpper}_APPLICATION`;
  actions.saveApplication = (promoterId, festivalId, eventId, payload) => {
    const data = new FormData();
    data.append('id', payload.id);
    data.append('name', payload.name);
    data.append('open_date', payload.open_date);
    data.append('close_date', payload.close_date);
    data.append('override_close_date', payload.override_close_date || '');
    data.append('background_image_tiled', payload.background_image_tiled);

    if (payload.selectedCredentials) {
      data.append('credentials', JSON.stringify(payload.selectedCredentials));
    }

    if (payload.emailTemplates && !_.isEmpty(payload.emailTemplates)) {
      const newEmailTemplates = [];
      Object.values(payload.emailTemplates).forEach(emailTemplate => {
        newEmailTemplates.push({
          id: emailTemplate.id,
          application_id: payload.id,
          email_type_id: emailTemplate.email_type_id,
          subject: emailTemplate.subject ?? null,
          body: emailTemplate.body ? JSON.stringify(emailTemplate.body) : null,
        });
      });
      data.append('email_templates', JSON.stringify(newEmailTemplates));
    }

    ['header_image', 'background_image'].forEach(imageType => {
      if (typeof payload[imageType] !== 'undefined') {
        if (payload[imageType] === null) {
          data.append(imageType, '');
        } else if (payload[imageType].result && payload[imageType].filename) {
          const file = dataUrlToFile(
            payload[imageType].result,
            payload[imageType].filename,
          );
          data.append(imageType, file, file.name);
        }
      }
    });

    if (payload.departmentType && !_.isEmpty(payload.departmentType)) {
      data.append('department_type', payload.departmentType);
    }

    return {
      type: `SAVE_${typeUpper}_APPLICATION`,
      eventId,
      festivalId,
      promise: client.put(`events/${eventId}/${type}-application`, data),
      promoterId,
    };
  };

  const formatCustomFields = values => {
    const newValues = { ...values };
    if (newValues.custom_fields) {
      newValues.custom_fields = newValues.custom_fields.getSchemaObject();
      newValues.custom_fields.fields.forEach(field => {
        if (!field.value) delete field.value;
        field.error = false;
      });
    }
    return newValues;
  };

  actions[
    `CREATE_${typeUpper}_APPLICATION_SECTION`
  ] = `CREATE_${typeUpper}_APPLICATION_SECTION`;
  actions.createApplicationSection = (applicationId, values) => {
    const payload = formatCustomFields(values);
    return {
      type: `CREATE_${typeUpper}_APPLICATION_SECTION`,
      promise: client.post(
        `${type}-applications/${applicationId}/sections`,
        payload,
      ),
    };
  };

  actions[
    `CREATE_OR_UPDATE_${typeUpper}_APPLICATION_SECTION`
  ] = `CREATE_OR_UPDATE_${typeUpper}_APPLICATION_SECTION`;
  actions.createOrUpdateApplicationSection = (applicationId, key, payload) => ({
    type: `CREATE_OR_UPDATE_${typeUpper}_APPLICATION_SECTION`,
    promise: client.put(
      `${type}-applications/${applicationId}/${key}`,
      payload,
    ),
  });

  actions[
    `UPDATE_${typeUpper}_APPLICATION_SECTION`
  ] = `UPDATE_${typeUpper}_APPLICATION_SECTION`;
  actions.updateApplicationSection = (sectionId, values) => {
    const payload = formatCustomFields(values);
    return {
      type: `UPDATE_${typeUpper}_APPLICATION_SECTION`,
      promise: client.put(`${type}-application-sections/${sectionId}`, payload),
    };
  };

  actions[
    `DELETE_${typeUpper}_APPLICATION_SECTION`
  ] = `DELETE_${typeUpper}_APPLICATION_SECTION`;
  actions.deleteApplicationSection = (applicationId, sectionId, eventId) => ({
    type: `DELETE_${typeUpper}_APPLICATION_SECTION`,
    applicationId,
    sectionId,
    eventId,
    promise: client.delete(`${type}-application-sections/${sectionId}`),
  });

  actions[
    `GET_${typeUpper}_APPLICATION_PUBLIC`
  ] = `GET_${typeUpper}_APPLICATION_PUBLIC`;
  actions.getApplicationPublic = token => ({
    type: `GET_${typeUpper}_APPLICATION_PUBLIC`,
    promise: client.get(`${type}-applications/${token}`),
  });

  actions[
    `UPDATE_${typeUpper}_APPLICATION_SECTION_PUBLIC`
  ] = `UPDATE_${typeUpper}_APPLICATION_SECTION_PUBLIC`;
  actions.updateApplicationSectionPublic = (sectionId, schema) => ({
    type: `UPDATE_${typeUpper}_APPLICATION_SECTION_PUBLIC`,
    sectionId,
    schema,
  });

  actions[
    `UPDATE_${typeUpper}_APPLICATION_SECTION_SORT_ORDERS`
  ] = `UPDATE_${typeUpper}_APPLICATION_SECTION_SORT_ORDERS`;
  actions.updateApplicationSectionSortOrders = (
    applicationId,
    eventId,
    payload,
  ) => ({
    applicationId,
    eventId,
    payload,
    type: `UPDATE_${typeUpper}_APPLICATION_SECTION_SORT_ORDERS`,
    promise: client.put(
      `${type}-applications/${applicationId}/sections/sort-orders`,
      payload,
    ),
  });

  actions[
    `SAVE_${typeUpper}_APPLICATION_PUBLIC`
  ] = `SAVE_${typeUpper}_APPLICATION_PUBLIC`;
  actions.saveApplicationPublic = (applicationToken, values) => ({
    type: `SAVE_${typeUpper}_APPLICATION_PUBLIC`,
    promise: publicClient.post(
      `${type}-applications/${applicationToken}`,
      values,
    ),
  });

  actions[
    `UPDATE_${typeUpper}_APPLICATION_PUBLIC`
  ] = `UPDATE_${typeUpper}_APPLICATION_PUBLIC`;
  actions.updateApplicationPublic = (applicationToken, values) => ({
    type: `UPDATE_${typeUpper}_APPLICATION_PUBLIC`,
    promise: publicClient.put(
      `${type}-applications/public/${applicationToken}`,
      values,
    ),
  });

  actions[
    `GET_${typeUpper}_APPLICATIONS_FOR_EVENT`
  ] = `GET_${typeUpper}_APPLICATIONS_FOR_EVENT`;
  actions.getApplicationListForEvent = eventId => ({
    type: `GET_${typeUpper}_APPLICATIONS_FOR_EVENT`,
    promise: client.get(`events/${eventId}/${type}/requests`),
  });

  actions[
    `APPROVE_${typeUpper}_APPLICATION`
  ] = `APPROVE_${typeUpper}_APPLICATION`;
  actions.approveApplication = (
    eventId,
    departmentId,
    isNotificationEnabled = false,
  ) => ({
    type: `APPROVE_${typeUpper}_APPLICATION`,
    promise: client.post(
      `events/${eventId}/${type}/requests/${departmentId}/approve?isNotificationEnabled=${isNotificationEnabled}`,
    ),
  });

  actions[`DENY_${typeUpper}_APPLICATION`] = `DENY_${typeUpper}_APPLICATION`;
  actions.denyApplication = (eventId, departmentId, isNotificationEnabled) => ({
    type: `DENY_${typeUpper}_APPLICATION`,
    promise: client.post(
      `events/${eventId}/${type}/requests/${departmentId}/deny?isNotificationEnabled=${isNotificationEnabled}`,
    ),
  });

  return actions;
};

export default applicationActions;
