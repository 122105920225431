import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { addListener, removeListener } from '@reduxjs/toolkit';
import {
  FETCH_CONTACT_UPLOADS,
  POLL_CONTACT_UPLOADS,
  SUBMIT_PLACEHOLDER_CONTACTS,
  UPLOAD_CONTACTS,
  fetchContactList,
  fetchContactUploads,
  pollContactUploads,
} from './contact-actions';
import {
  areContactUploadsProcessing,
  getStaffContactListLoaded,
} from './contact-selectors';
import { getCurrentDepartment } from '../department/department-selectors';
import { showNotification } from '../notification/notification-actions';
import { fetchDepartment } from '../department/department-actions';
import PropTypes from 'prop-types';
import LoadingIndicator from '../common/LoadingIndicator';

const ContactListLoader = ({ noUploads = false }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const departmentId = useSelector(state =>
    getCurrentDepartment(state, { params })?.get('id'),
  );

  const contactsLoaded = useSelector(state =>
    getStaffContactListLoaded(state, {
      params,
    }),
  );

  useEffect(() => {
    if (departmentId) {
      dispatch(fetchContactList(departmentId));
    }
  }, [departmentId, dispatch]);

  useEffect(() => {
    if (noUploads) return;

    let fetchedContactList = false;
    const predicate = action =>
      [
        POLL_CONTACT_UPLOADS,
        `${SUBMIT_PLACEHOLDER_CONTACTS}_SUCCESS`,
        `${UPLOAD_CONTACTS}_SUCCESS`,
      ].includes(action.type) && action.departmentId === departmentId;

    const effect = async (action, listenerApi) => {
      listenerApi.cancelActiveListeners();

      const task = listenerApi.fork(async forkApi => {
        while (true) {
          listenerApi
            .dispatch(fetchContactUploads(departmentId))
            .then(action => {
              if (!action.response.ok) {
                console.error('error polling csv status ', action.response);
                listenerApi.dispatch(
                  showNotification({
                    status: 'error',
                    message: `Error fetching Contact Uploads Status: ${action.response}`,
                  }),
                );
                throw new Error(action.response);
              }
            });

          await forkApi.delay(5000);

          if (action.type === `${UPLOAD_CONTACTS}_SUCCESS`)
            dispatch(fetchDepartment(departmentId));

          dispatch(fetchContactList(departmentId));
          fetchedContactList = true;
        }
      });

      await listenerApi.condition(
        (action, state) =>
          action.type === `${FETCH_CONTACT_UPLOADS}_SUCCESS` &&
          !areContactUploadsProcessing(state, departmentId),
      );

      task.cancel();

      if (
        !fetchedContactList &&
        action.type === `${SUBMIT_PLACEHOLDER_CONTACTS}_SUCCESS`
      ) {
        dispatch(fetchContactList(departmentId));
      }

      if (!fetchedContactList && action.type === `${UPLOAD_CONTACTS}_SUCCESS`) {
        dispatch(fetchDepartment(departmentId));
        dispatch(fetchContactList(departmentId));
      }
    };

    dispatch(addListener({ predicate, effect }));
    dispatch(pollContactUploads(departmentId));

    return () => {
      dispatch(removeListener({ predicate, effect, cancelActive: true }));
    };
  }, [dispatch, departmentId, noUploads]);

  return contactsLoaded ? <Outlet /> : <LoadingIndicator />;
};

ContactListLoader.propTypes = {
  noUploads: PropTypes.bool,
};
export default ContactListLoader;
