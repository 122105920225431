import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Label } from 'synfrastructure';

const ToggleScaffold = ({ children, label, className, disabled = false }) => {
  const renderLabel = () => {
    const childrenArray = [children];
    const labelClasses = {
      toggle: true,
      'toggle--disabled': disabled,
    };

    if (label) {
      childrenArray.push(
        <span className="toggle-label" key="label">
          {label}
        </span>,
      );
    }

    return <Label className={classNames(labelClasses)}>{childrenArray}</Label>;
  };

  const scaffoldClasses = {
    'toggle-scaffold': true,
    [`${className}`]: className,
  };

  return <div className={classNames(scaffoldClasses)}>{renderLabel()}</div>;
};

ToggleScaffold.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
};

export default ToggleScaffold;
