import { Field } from 'react-final-form';
import { Map } from 'immutable';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import { composeValidators, isRequired } from 'revalidate';
import isValidEmail from '../../validate/is-valid-email';
import PropTypes from 'prop-types';
import ReduxFormsField from '../../forms/ReduxFormsField';

const draftRenderer = new Renderer(defaultBlocks);

const InternGeneralInformation = ({ className, section }) => (
  <div className={className}>
    {section && (
      <div
        className="intern-name-section draftjs-output"
        dangerouslySetInnerHTML={{
          __html: draftRenderer.convertRawToHTML(section.get('content').toJS()),
        }}
      />
    )}

    <Field
      name="first_name"
      component={ReduxFormsField}
      label="First Name"
      placeholder="Enter Name"
      required
      validate={isRequired('First Name')}
    >
      <input type="text" />
    </Field>

    <Field
      name="last_name"
      component={ReduxFormsField}
      label="Last Name"
      placeholder="Enter Name"
      required
      validate={isRequired('Last Name')}
    >
      <input type="text" />
    </Field>

    <Field
      name="email"
      component={ReduxFormsField}
      label="Email"
      placeholder="Enter Email"
      required
      validate={composeValidators(isRequired, isValidEmail)('Email')}
    >
      <input type="text" />
    </Field>
  </div>
);

InternGeneralInformation.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
};

export default InternGeneralInformation;
