import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { getCurrentDepartment } from './department-selectors';
import { stripTrailingSlash } from '../common/uri';
import LoadingIndicator from '../common/LoadingIndicator';

const aclRules = {
  canEditDepartment: [resource.DEPARTMENT, 'edit'],
  canManageCatering: [resource.DEPARTMENT, 'manage-catering'],
  canViewReports: [resource.DEPARTMENT, 'view-reports'],
};

const DepartmentRedirector = () => {
  const acl = useACL(aclRules);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  useEffect(() => {
    if (!department || acl.canEditDepartment === undefined) return;

    const basePath = stripTrailingSlash(location.pathname);

    if (acl.canEditDepartment) {
      navigate(`${basePath}/contacts`, { replace: true });
    } else if (acl.canManageCatering) {
      navigate(`${basePath}/catering`, { replace: true });
    } else if (acl.canViewReports) {
      navigate(`${basePath}/reports`, { replace: true });
    }
  });

  return <LoadingIndicator />;
};

export default DepartmentRedirector;
