import { v1 as keygen } from 'uuid';
import { Container, Button } from './PaginationButtons.styles';
import PropTypes from 'prop-types';

const PaginationButtons = ({
  results,
  goToPage,
  currentPage,
  fetchNextSetFromServer,
  fetchPreviousSetFromServer,
  currentSet,
  pageSize,
  maxServerResponseSize,
}) => {
  const totalResults = results[0] && results[0].total;
  const setRange = currentSet * maxServerResponseSize;
  const nextSetExists =
    currentSet > 0
      ? totalResults - setRange > maxServerResponseSize
      : totalResults - maxServerResponseSize > 0;
  const previousSetExists = currentSet > 0;

  const enumeratePageButtons = () => {
    const numberOfClientSideResults = results.length;
    const numberOfButtons = Math.ceil(numberOfClientSideResults / pageSize);
    return numberOfButtons > 1 ? [...Array(numberOfButtons).keys()] : [];
  };

  return (
    <Container>
      {previousSetExists ? (
        <Button onClick={fetchPreviousSetFromServer}>...</Button>
      ) : null}
      {enumeratePageButtons(totalResults, currentSet).map(index => {
        const adjustedIndex = index + 1;
        const adjustedSet = currentSet * 4;
        const pageNumber = adjustedIndex + adjustedSet;
        const isCurrentPage = pageNumber === currentPage;
        return (
          <Button
            key={keygen()}
            onClick={() => goToPage(pageNumber)}
            isCurrentPage={isCurrentPage}
          >
            {pageNumber}
          </Button>
        );
      })}
      {nextSetExists ? (
        <Button onClick={fetchNextSetFromServer}>...</Button>
      ) : null}
    </Container>
  );
};

PaginationButtons.propTypes = {
  results: PropTypes.array,
  goToPage: PropTypes.func,
  currentPage: PropTypes.number,
  fetchNextSetFromServer: PropTypes.func,
  fetchPreviousSetFromServer: PropTypes.func,
  currentSet: PropTypes.number,
  pageSize: PropTypes.number,
  maxServerResponseSize: PropTypes.number,
};

export default PaginationButtons;
