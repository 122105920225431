import React from 'react';

function H3() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <path d="M9.179,13.96H7.129V9.694h-4V13.96H1.078V4.006H3.129V8.039h4V4.006H9.179Z" />
      <path d="M15.418,3.9V5.531h-.191a3.358,3.358,0,0,0-2.157.7,2.835,2.835,0,0,0-.98,1.88,2.691,2.691,0,0,1,2-.807,2.536,2.536,0,0,1,2.064.93,3.725,3.725,0,0,1,.766,2.448,3.655,3.655,0,0,1-.421,1.757,3,3,0,0,1-1.189,1.224,3.447,3.447,0,0,1-1.739.438A3.229,3.229,0,0,1,11.032,13a4.247,4.247,0,0,1-.967-2.919V9.372a6.4,6.4,0,0,1,.611-2.861,4.489,4.489,0,0,1,1.758-1.921A5.142,5.142,0,0,1,15.09,3.9ZM13.49,8.894a1.578,1.578,0,0,0-.868.249,1.5,1.5,0,0,0-.574.658v.6a2.661,2.661,0,0,0,.39,1.546,1.26,1.26,0,0,0,1.094.556,1.243,1.243,0,0,0,1.028-.5,2.039,2.039,0,0,0,.394-1.3,2.04,2.04,0,0,0-.4-1.31A1.293,1.293,0,0,0,13.49,8.894Z" />
    </svg>
  );
  /* eslint-enable */
}

export default H3;
