import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { getCurrentEvent } from '../event/event-selectors';
import { deleteMediaApplication } from './media-actions';
import { showNotification } from '../notification/notification-actions';
import Paper, { PaperHeader } from '../common/paper/Paper';
import LoadingIndicator from '../common/LoadingIndicator';
import Icon from '../common/icons/Icon';
import Confirmation from '../common/Confirmation';

const MediaApplicationList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const applicationList = useSelector(state =>
    state.media.applications?.getIn([event?.get('id'), 'data']),
  );

  const [applicationToDelete, setApplicationToDelete] = useState(null);

  const deleteApplication = async application => {
    await dispatch(
      deleteMediaApplication(event.get('id'), application.get('id')),
    );
    setApplicationToDelete(false);
    dispatch(
      showNotification({
        status: 'success',
        message: 'Application successfully deleted',
      }),
    );
  };

  if (applicationToDelete) {
    const application = applicationToDelete;

    return (
      <Confirmation
        warningText={`Deleting ${application.get(
          'name',
        )} will remove them from all access to Strata`}
        actionText={`delete ${application.get('name')}}`}
        onConfirm={() => deleteApplication(application)}
        onDismiss={() => setApplicationToDelete(false)}
      />
    );
  }

  return (
    <Paper>
      <PaperHeader
        title="Media Applications"
        actions={[
          <Link
            key="add-application-action"
            className="button button--plain button--icon"
            to={`${location.pathname}/~add`}
          >
            <Icon icon="AddCircle" />
            <span>Add Application</span>
          </Link>,
        ]}
      />
      {!applicationList ? (
        <LoadingIndicator />
      ) : !applicationList.size ? (
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>You have no media applications</p>
        </div>
      ) : (
        <div>
          {applicationList.map((application, index) => (
            <Link
              key={index}
              className="generic-list__item generic-list__item--link"
              to={`${location.pathname}/${application.get('id')}`}
            >
              <div className="content">
                <span>{application.get('name')}</span>
              </div>
              <button
                className="dropdown__item"
                key={`delete-application-${application.get('id')}`}
                onClick={event => {
                  event.preventDefault();
                  setApplicationToDelete(application);
                }}
                type="button"
              >
                <div className="dropdown__item__children">
                  <Icon icon="Trash" />
                </div>
              </button>

              <div className="action">
                <Icon icon="ArrowLeft" />
              </div>
            </Link>
          ))}
        </div>
      )}
    </Paper>
  );
};

export default MediaApplicationList;
