import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  min-height: 100%;
  flex-direction: column;
  margin: 20px 0;
  overflow: auto;
`;

export const Header = styled.div`
  display: flex;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const NewPersonnel = styled.div`
  border: 1px solid ${({ theme }) => theme.gray};
`;

export const AddPersonnelSpan = styled.span`
  font-weight: 600;
`;

export const AddPersonnel = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin-bottom: 8px;
  cursor: pointer;
  color: ${({ theme }) => theme.primary};
  svg {
    fill: ${({ theme }) => theme.primary};
    margin-right: 6px;
  }
`;
export const ContactContainer = styled.div`
  display: flex;
  position: relative;
  border: 1px solid ${({ theme }) => theme.gray};
  border-top: none;
  min-width: 925px;
  ${({ theme, open }) => {
    if (open) {
      return `min-height: 280px;`;
    }
    return `
      padding-bottom: 6px;
      padding-top: 4px;
      padding-left: 10px;
      &:hover {
        background: ${theme.grayLightest};
        cursor: pointer;
      }
    `;
  }};
`;

export const ContactContainerHeader = styled.div`
  display: flex;
  position: relative;
  border: 1px solid ${({ theme }) => theme.gray};
  min-width: 925px;
  background: ${({ theme }) => theme.grayLightest};
  height: 65px;
  padding-bottom: 10px;
  padding-left: 10px;
`;

export const PeriodCredentialsHeader = styled.div`
  align: left;
  vertical-align: middle;
  font-weight: 600;
  ${({ open }) => {
    if (open) {
      return `flex: 2.5;`;
    }
    return `
      flex: 3;
    `;
  }};
`;

export const PeriodCredentials = styled.div`
  flex: 3;
  align-items: center;
  vertical-align: middle;
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Name = styled.div`
  flex: 1.5;
  line-height: 36px;
  padding: 0 8px;
  color: #000;
  font-weight: 600;
  font-size: 18px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
`;

export const SplitRow = styled.div`
  display: flex;
  flex-direction: row;
  height: ${props => props.height + '%'};
  justify-content: flex-start;
  align-items: center;
`;

export const SplitRowHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 50%;
  justify-content: flex-start;
  align-items: center;
`;

export const Credential = styled.div`
  grid-column-start: ${props => props.index + 2};
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
`;

export const CredentialType = styled.div`
  font-size: 12px;
  margin-left: 6px;
  color: ${({ theme }) => theme.darkBlue};
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CredentialDescription = styled.div`
  margin-left: 14px;
  font-size: 14px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Gutter = styled.div`
  flex: 0.5;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 8px;
  > div > span > svg {
    fill: ${({ theme }) => theme.gray};
  }
`;

export const Italic = styled.span`
  font-style: italic;
`;
