import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { getLoggedInUser } from '../auth/auth-selectors';
import { isCredentialLocked } from '../common/utils/credentials/credential-utils';
import { useMaximumRequestableParameters } from './DepartmentUnsavedCredentials';
import PropTypes from 'prop-types';
import ContactCredentialDateRange from './ContactCredentialDateRange';
import UnsavedQuantityField from './UnsavedQuantityField';

const UnsavedCredentialRequest = ({
  type,
  department,
  contact,
  credential,
}) => {
  const loggedInUser = useSelector(getLoggedInUser);

  const { maximumExceededBy, maximumRequestable, available } =
    useMaximumRequestableParameters(department, credential.get('id'));

  const dataTooltipId = `maximum-requestable-tooltip-${credential.get('id')}`;

  return (
    <div className="contact-credentials__item contact-credentials__display">
      <div className="contact-credentials__display-item contact-credentials__date">
        {maximumExceededBy === null ||
        maximumExceededBy === undefined ||
        maximumExceededBy < 1 ||
        maximumRequestable === null ? (
          <ContactCredentialDateRange credential={credential} />
        ) : (
          <div
            style={{
              backgroundColor: 'pink',
              padding: '5px',
              width: '142px',
            }}
          >
            department maximum exceeded
          </div>
        )}
      </div>

      <div className="contact-credentials__display-item contact-credentials__qty">
        <div
          data-tooltip-id={dataTooltipId}
          data-tooltip-hidden={
            maximumExceededBy > 0 || maximumRequestable === null
          }
        >
          <UnsavedQuantityField
            type={type}
            department={department}
            contact={contact}
            credential={credential}
            isLocked={isCredentialLocked(credential, loggedInUser, department)}
          />
          <Tooltip
            id={dataTooltipId}
            offset={10}
            style={{ marginLeft: '-10px' }}
          >
            {available} are requestable <br></br> from a maximum of{' '}
            {maximumRequestable} <br></br> for this department
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

UnsavedCredentialRequest.propTypes = {
  type: PropTypes.string.isRequired,
  department: PropTypes.object.isRequired,
  contact: PropTypes.object,
  credential: PropTypes.object.isRequired,
};

export default UnsavedCredentialRequest;
