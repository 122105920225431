import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Grid, List, ListItem, Table } from 'semantic-ui-react';
import { parse } from 'csv-parse/browser/esm/sync';
import { getCurrentDepartment } from '../department/department-selectors';
import { getContactsUploadTemplate, uploadContacts } from './contact-actions';
import { getContactFileUploads } from './contact-selectors';
import { StyledButton } from '../common/StyledButton';
import departmentTypeMap from '../lib/department-type-map';
import config from '../config';
import moment from 'moment';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import FileSaver from 'file-saver';
import bytesToSize from '../common/utils/bytesToSize';
import phoneFilter from '../common/filters/phone-filter';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import StatusButton from '../common/StatusButton';
import ExpandableRow from '../common/ExpandableRow';
import Icon from '../common/icons/Icon';

const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

class ParseError extends Error {
  constructor(message, lines, index, value) {
    super(message);
    this.name = 'ParseError';
    this.lines = lines;
    this.index = index;
    this.code = value;
  }
}

const ContactsUpload = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const [validationErrors, setValidationErrors] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [toggles, setToggles] = useState({});

  const label = departmentTypeMap[params.departmentType].contacts.label;

  const uploads = useSelector(state =>
    getContactFileUploads(state, department.get('id')),
  );

  const isProcessing =
    uploads.length > 0 ? uploads[0].status === 'Processing' : false;

  const toggle = upload => {
    setToggles(oldToggles => {
      const newToggles = { ...oldToggles };
      newToggles[upload.id ?? upload] = !newToggles[upload.id ?? upload];
      return newToggles;
    });
  };

  const isToggled = upload => toggles[upload.id ?? upload];

  const getMessage = ({ status, contacts }) => {
    if (!status || !contacts) return null;

    let erroredContacts = contacts.reduce(
      (count, contact) => (!contact.success ? count + 1 : count),
      0,
    );

    let message =
      contacts.length === 1
        ? `Uploaded 1 ${label.singular}.`
        : `Uploaded ${contacts.length} ${label.plural}.`;

    if (status === 'Error')
      message +=
        erroredContacts === 1
          ? ` 1 ${label.singular} had errors.`
          : ` ${erroredContacts} ${label.plural} had errors.`;

    return message;
  };

  const getTemplate = () => {
    getContactsUploadTemplate(department.get('id'))
      .then(response => response.blob())
      .then(blob => {
        FileSaver.saveAs(
          blob,
          `${department.get('slug')}_${label.plural.toLowerCase()}_upload.csv`,
        );
      });
  };

  const onDropAccepted = onChange => files => {
    if (files && files.length > 0) {
      onChange(files[0]);
      setValidationErrors([]);
    }
  };

  const CsvDropzone = ({ value, onChange, submitSucceeded }) => (
    <Dropzone
      accept={{ 'text/csv': [] }}
      maxFiles={1}
      maxSize={1024 * 1024 * 3}
      multiple={false}
      onDropAccepted={onDropAccepted(onChange)}
    >
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
        <div
          {...getRootProps({
            className: `dropzone${isDragAccept ? ' dropzone--active' : ''}`,
            style: isDragReject
              ? {
                  background: 'rgba(255, 0, 0, 0.4)',
                  borderColor: 'rgb(255, 0, 0)',
                }
              : {},
          })}
        >
          <input {...getInputProps()} />
          <div className="dropzone-instructions">
            <div className="dropzone-instructions--main">
              Drag-n-drop or click to upload a file
            </div>
            <div className="dropzone-instructions--sub">
              <p>Accepted file type: .csv</p>
            </div>
            <div className="dropzone-instructions--sub">
              <p>Max file size: 3MB</p>
            </div>
            {value && (
              <aside
                style={{
                  textAlign: 'left',
                  borderTop: '2px dashed #ddd',
                  marginTop: '10px',
                  paddingTop: '10px',
                }}
              >
                <h4>{submitSucceeded ? 'Uploaded File' : 'File to Upload'}</h4>
                <p style={{ paddingTop: '5px' }}>
                  {value.name} ({bytesToSize(value.size)})
                </p>
              </aside>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );

  const uploadColumns = {};
  const fileColumns = {
    'First Name': 'first_name',
    'Last Name': 'last_name',
    Email: 'email',
    Phone: 'phone',
    Role: 'relationship',
    'Other Role': 'other_role',
    'Is Primary': 'is_primary',
    'Is Group': 'is_group',
  };

  const parseFile = async file => {
    const requiredColumns = {
      'First Name': false,
      'Last Name': false,
    };

    const parsedFile = await parse(await file.text(), {
      skip_empty_lines: true,
      skip_records_with_error: false,
      skip_records_with_empty_values: false,
      trim: true,
      columns: header => {
        const newHeader = [];
        for (const column of header) {
          if (column in fileColumns) {
            uploadColumns[fileColumns[column]] = column;
            requiredColumns[column] = true;
            newHeader.push(fileColumns[column]);
          } else if (column.startsWith('[')) {
            const uploadColumn = `credential_id-${parseInt(
              column.substring(1, column.indexOf(']')),
            )}`;
            uploadColumns[uploadColumn] = column;
            newHeader.push(uploadColumn);
          } else throw new Error(`File contains invalid column "${column}"`);
        }
        return newHeader;
      },
      cast: (value, { header, lines, column }) => {
        if (header) return value;
        if (column === 'first_name' && value === '') {
          throw new ParseError(
            'First Name must be provided',
            lines,
            uploadColumns[column],
            value,
          );
        } else if (column === 'last_name' && value === '') {
          throw new ParseError(
            'Last Name must be provided',
            lines,
            uploadColumns[column],
            value,
          );
        } else if (['is_primary', 'is_group'].includes(column)) {
          if (value === '') return undefined;
          return (
            ['true', 'yes'].includes(value.toLowerCase()) || Number(value) === 1
          );
        } else if (column === 'phone')
          return value !== '' ? phoneFilter(value) : null;
        else if (column.startsWith('credential_id-')) return parseFloat(value);
        else if (value === '') return null;
        else return value ?? null;
      },
    });

    const missingColumns = [];
    for (const [column, isIncluded] of Object.entries(requiredColumns)) {
      if (!isIncluded) missingColumns.push(column);
    }

    if (missingColumns.length > 0)
      throw new Error(
        `Missing required columns: ${missingColumns
          .map(column => `'${column}'`)
          .join(', ')}`,
      );

    return parsedFile;
  };

  const handleSubmit = async values => {
    let rows;
    try {
      rows = await parseFile(values.file);
    } catch (error) {
      setValidationErrors([
        {
          row: error.lines,
          field: error.index,
          value: error.code,
          message: error.message,
        },
      ]);
      return {
        file: error.message,
      };
    }

    if (rows?.length === 0) {
      setValidationErrors([
        { message: 'File must contain at least one contact' },
      ]);
      return {
        file: 'File must contain at least one contact',
      };
    }

    const contacts = [];

    rows.forEach(row => {
      const contact = { credentials: [] };
      for (const [key, value] of Object.entries(row)) {
        if (value === undefined) continue;
        if (key.startsWith('credential_id-')) {
          contact.credentials.push({
            id: Number(key.replace('credential_id-', '')),
            quantity: isNaN(value) ? null : value,
          });
        } else contact[key] = value;
      }

      if (contact.credentials.length === 0) delete contact.credentials;

      contacts.push(contact);
    });

    const filename = values.file.name;

    return dispatch(
      uploadContacts(department.get('id'), {
        contacts,
        filename,
      }),
    ).then(action => {
      if (!action.response.ok) {
        const errors = [];
        if (action.json.validationErrors) {
          action.json.validationErrors?.forEach(validationError => {
            let error = {
              message:
                validationError.type === 'array.unique'
                  ? 'Contact has been added more than once'
                  : config.validationMessages[validationError.type] ??
                    validationError.message,
            };
            if (Array.isArray(validationError.key)) {
              if (validationError.key[0] === 'contacts') {
                const contact = contacts[validationError.key[1]];
                error = {
                  row: validationError.key[1] + 2,
                  contact,
                  field: uploadColumns[validationError.context.key],
                  value:
                    typeof validationError.context.value === 'string'
                      ? validationError.context.value
                      : null,
                  ...error,
                };
              }
            }
            errors.push(error);
          });
          setValidationErrors(errors);
          return { [FORM_ERROR]: 'Validation Errors' };
        }
      }
    });
  };

  const renderContacts = (contacts, index) => {
    if (!contacts) return null;

    return contacts.map(({ contact, success, errors }, idx) => (
      <Table.Row key={`${index}-uploaded-contact-${idx}`} negative={!success}>
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
        <Table.Cell />
        <Table.Cell singleLine>{success ? 'Uploaded' : 'Error'}</Table.Cell>
        <Table.Cell>
          {`${contact?.first_name} ${contact?.last_name}`}
        </Table.Cell>
        <Table.Cell>
          {success ? (
            ''
          ) : (
            <List>
              {errors?.map(({ error }, index) => (
                <ListItem key={index}>{error}</ListItem>
              ))}
            </List>
          )}
        </Table.Cell>
        <Table.Cell />
      </Table.Row>
    ));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({
        handleSubmit,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
        submitFailed,
        submitSucceeded,
      }) => (
        <div>
          <Paper>
            <PaperHeader backTo=".." title={`Upload ${label.plural}`} />
            <form
              onSubmit={handleSubmit}
              className="generic-form paper__wrapper"
            >
              <div className="generic-form__body">
                <Column>
                  <Header>1. Export Template:</Header>
                  <Row>
                    <StyledButton
                      type="button"
                      title="Download Template"
                      color="blue"
                      onClick={getTemplate}
                      style={{
                        width: '250px',
                        alignSelf: 'end',
                      }}
                    />
                  </Row>
                </Column>
                <Column>
                  <Header>2. Upload CSV File:</Header>
                  <Row>
                    <Field
                      name="file"
                      component={ReduxFormsFieldNoLabel}
                      label=""
                      required
                    >
                      <CsvDropzone submitSucceeded={submitSucceeded} />
                    </Field>
                  </Row>
                </Column>
                <Column>
                  {validationErrors.length !== 0 && (
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Header style={{ paddingLeft: '10px' }}>
                            CSV FILE NOT PROCESSED - Please correct the errors
                            and re-upload:
                          </Header>
                          <Table collapsing padded selectable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Row #</Table.HeaderCell>
                                <Table.HeaderCell>Contact</Table.HeaderCell>
                                <Table.HeaderCell>Field</Table.HeaderCell>
                                <Table.HeaderCell>Value</Table.HeaderCell>
                                <Table.HeaderCell>
                                  Error Message
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {validationErrors.map((ve, idx) => (
                                <Table.Row negative key={idx}>
                                  <Table.Cell>{ve.row}</Table.Cell>
                                  <Table.Cell>
                                    {ve.contact?.first_name}{' '}
                                    {ve.contact?.last_name}
                                  </Table.Cell>
                                  <Table.Cell>{ve.field}</Table.Cell>
                                  <Table.Cell>{ve.value}</Table.Cell>
                                  <Table.Cell>{ve.message}</Table.Cell>
                                </Table.Row>
                              ))}
                            </Table.Body>
                          </Table>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                  {uploads.length > 0 && (
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Header>Last Upload Status:</Header>
                          <Table collapsing padded selectable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Uploaded By</Table.HeaderCell>
                                <Table.HeaderCell>File</Table.HeaderCell>
                                <Table.HeaderCell>Start Time</Table.HeaderCell>
                                <Table.HeaderCell>End Time</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Contact</Table.HeaderCell>
                                <Table.HeaderCell>Message</Table.HeaderCell>
                                <Table.HeaderCell />
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              <Table.Row
                                negative={uploads[0].status === 'Error'}
                              >
                                <Table.Cell>
                                  {uploads[0].uploadedBy.first_name}{' '}
                                  {uploads[0].uploadedBy.last_name}
                                </Table.Cell>
                                <Table.Cell>{uploads[0].file_name}</Table.Cell>
                                <Table.Cell>
                                  {moment(uploads[0].start_time).format(
                                    'MMM DD h:mm:ss a',
                                  )}
                                </Table.Cell>
                                <Table.Cell>
                                  {uploads[0].end_time
                                    ? moment(uploads[0].end_time).format(
                                        'MMM DD h:mm:ss a',
                                      )
                                    : ''}
                                </Table.Cell>
                                <Table.Cell>{uploads[0].status}</Table.Cell>
                                <Table.Cell />
                                <Table.Cell>
                                  {getMessage(uploads[0])}
                                </Table.Cell>
                                <Table.Cell>
                                  <div onClick={() => toggle('current')}>
                                    {isToggled('current') && (
                                      <Icon
                                        icon="Caret"
                                        style={{
                                          top: '5px',
                                          transform: 'rotate(180deg)',
                                        }}
                                      />
                                    )}
                                    {!isToggled('current') && (
                                      <Icon icon="Caret" />
                                    )}
                                  </div>
                                </Table.Cell>
                              </Table.Row>
                              {isToggled('current') &&
                                renderContacts(uploads[0].contacts, 'current')}
                            </Table.Body>
                          </Table>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                </Column>
              </div>
              <div className="generic-form__footer">
                <StatusButton
                  buttonText={`Upload ${label.plural}`}
                  completeText={`${label.plural} Uploaded`}
                  disabled={
                    pristine ||
                    submitting ||
                    isProcessing ||
                    (submitFailed && !modifiedSinceLastSubmit)
                  }
                  status={
                    submitting || isProcessing
                      ? 'loading'
                      : modifiedSinceLastSubmit
                      ? 'default'
                      : submitSucceeded
                      ? 'success'
                      : submitFailed
                      ? 'error'
                      : 'default'
                  }
                  type="submit"
                />
              </div>
            </form>
          </Paper>
          {uploads.length > 0 && (
            <ExpandableRow
              isExpandable
              isExpanded={expanded}
              onToggleExpansion={() => setExpanded(!expanded)}
              columns={['Upload History']}
            >
              <div className="generic-form__body">
                <Column>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Table collapsing padded selectable>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Uploaded By</Table.HeaderCell>
                              <Table.HeaderCell>File</Table.HeaderCell>
                              <Table.HeaderCell>Start Time</Table.HeaderCell>
                              <Table.HeaderCell>End Time</Table.HeaderCell>
                              <Table.HeaderCell>Status</Table.HeaderCell>
                              <Table.HeaderCell>Contact</Table.HeaderCell>
                              <Table.HeaderCell>Message</Table.HeaderCell>
                              <Table.HeaderCell />
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {uploads.map((upload, index) => (
                              <Fragment key={index}>
                                <Table.Row negative={upload.status === 'Error'}>
                                  <Table.Cell>
                                    {upload.uploadedBy.first_name}{' '}
                                    {upload.uploadedBy.last_name}
                                  </Table.Cell>
                                  <Table.Cell>{upload.file_name}</Table.Cell>
                                  <Table.Cell>
                                    {moment(upload.start_time).format(
                                      'MMM DD h:mm:ss a',
                                    )}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {upload.end_time
                                      ? moment(upload.end_time).format(
                                          'MMM DD h:mm:ss a',
                                        )
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell singleLine>
                                    {upload.status}
                                  </Table.Cell>
                                  <Table.Cell />
                                  <Table.Cell>{getMessage(upload)}</Table.Cell>
                                  <Table.Cell>
                                    <div onClick={() => toggle(upload)}>
                                      {isToggled(upload) && (
                                        <Icon
                                          icon="Caret"
                                          style={{
                                            top: '5px',
                                            transform: 'rotate(180deg)',
                                          }}
                                        />
                                      )}
                                      {!isToggled(upload) && (
                                        <Icon icon="Caret" />
                                      )}
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                                {isToggled(upload) &&
                                  renderContacts(upload.contacts, index)}
                              </Fragment>
                            ))}
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Column>
              </div>
            </ExpandableRow>
          )}
        </div>
      )}
    />
  );
};

export default ContactsUpload;
