import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { stripTrailingSlash } from '../common/uri';
import {
  getCurrentEvent,
  getCurrentEventSettings,
} from '../event/event-selectors';
import roleConstants from '../lib/acl/role-constants';

const aclRules = {
  canApproveCredential: [resource.EVENT, 'abstractapprove'],
};

const CredentialApprovalsRedirector = () => {
  const acl = useACL(aclRules);
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const eventSettings = useSelector(state =>
    getCurrentEventSettings(state, { params }),
  );
  const loggedInUser = useSelector(state =>
    state.auth.loggedInUser.get('data'),
  );

  useEffect(() => {
    if (!event || !eventSettings || !acl.canApproveCredential) return;

    const section = eventSettings.find(section =>
      section.getIn(['settings', 'section_enabled']),
    );

    if (!section) return;

    let urlSection = section.get('department_type');

    if (
      !loggedInUser
        .getIn(['eventRoles'])
        .some(
          er =>
            er.get('event_id') === event.get('id') &&
            er.get('role') === roleConstants.CREDENTIAL_APPROVE,
        )
    ) {
      const firstApproveRole = loggedInUser
        .getIn(['eventRoles'])
        .find(
          er =>
            er.get('event_id') === event.get('id') &&
            er.get('role').endsWith('_approve'),
        );
      if (firstApproveRole) {
        urlSection = firstApproveRole.get('role').replace(/(_approve$)/, '');
      }
    }

    navigate(`${stripTrailingSlash(location.pathname)}/${urlSection}`, {
      replace: true,
    });
  }, [
    acl.canApproveCredential,
    event,
    eventSettings,
    location.pathname,
    loggedInUser,
    navigate,
  ]);

  return null;
};

export default CredentialApprovalsRedirector;
