import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from '../Tag';

const ReduxFormsFieldNoLabel = ({
  label,
  required,
  children,
  className,
  input,
  onChange,
  placeholder,
  disabled = false,
  meta: { touched, modifiedSinceLastSubmit, error, submitError },
}) => {
  const scaffoldClasses = {
    'input-scaffold': true,
    [`${className}`]: className,
  };

  const labelClasses = {
    input: true,
    'input--disabled': disabled,
  };

  const previousValue = useRef(input?.value);

  useEffect(() => {
    if (onChange && previousValue.current !== input.value) {
      onChange(input.value, previousValue.current);
      previousValue.current = input.value;
    }
  }, [input.value, onChange]);

  return (
    <div className={classNames(scaffoldClasses)}>
      <div className={classNames(labelClasses)}>
        {label ? (
          <span className="input-label">
            <span className="input-label--label">{label}</span>
            {!required ? <Tag colorTheme="optional">optional</Tag> : null}
          </span>
        ) : null}
        {React.cloneElement(children, {
          ...{ placeholder, disabled },
          ...input,
        })}
      </div>
      {touched && error ? (
        <div className="input-validation">{error}</div>
      ) : null}
      {touched && !error && submitError && !modifiedSinceLastSubmit ? (
        <div className="input-validation">{submitError}</div>
      ) : null}
    </div>
  );
};

ReduxFormsFieldNoLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default ReduxFormsFieldNoLabel;
