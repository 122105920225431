import isPlainObject from 'lodash/isPlainObject';

export default function recursiveTrim(value) {
  if (typeof value === 'string') {
    return value.trim();
  } else if (Array.isArray(value)) {
    const trimmedArray = [];
    value.forEach((val, index) => {
      trimmedArray[index] = recursiveTrim(val);
    });
    return trimmedArray;
  } else if (isPlainObject(value)) {
    const trimmedObject = {};
    Object.keys(value).forEach(key => {
      trimmedObject[key] = recursiveTrim(value[key]);
    });
    return trimmedObject;
  }
  return value;
}
