import PropTypes from 'prop-types';
import { useState } from 'react';
import { v1 as keygen } from 'uuid';
import { configurePayload } from './reports-utils';
import { StyledButton } from '../common/StyledButton';
import { Body, Footer, Row, Select } from './Report.styles';
import DownloadModal from './Table/TableComponents/DownloadModal';
import ExpandableRow from '../common/ExpandableRow';
import ReduxReactSelectField from '../common/forms/ReduxReactSelectField';
import InputScaffold from '../common/forms/InputScaffold';

const Report = ({
  report: { title, fields, report_slug },
  fetchDownload,
  navigateToReport,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [parameters, setParameters] = useState({});
  const [downloading, setDownloading] = useState(false);

  const handleDownload = (downloadType, includeFilters) => {
    setDownloading(true);
    const payload = configurePayload({
      report_type: report_slug,
      mime: downloadType,
      filters: includeFilters ? parameters : null,
    });
    fetchDownload(payload).then(() => setDownloading(false));
  };

  const updateParameterValue = (parameterName, value) => {
    setParameters(parameters => ({
      ...parameters,
      [parameterName]: value,
    }));
  };

  return (
    <ExpandableRow
      columns={[title]}
      isExpanded={expanded}
      isExpandable
      onToggleExpansion={() => {
        setExpanded(!expanded);
      }}
      expandedHeaderStyle={{ borderBottom: '0px' }}
    >
      <Body>
        <Row>
          {fields
            ? Object.entries(fields).map(([, _value]) => {
                const { label, parameterName, options, multi } = _value;
                return (
                  <Select key={keygen()}>
                    <InputScaffold label={label} required>
                      <ReduxReactSelectField
                        name={label}
                        placeholder={label}
                        options={options}
                        isMulti={multi}
                        value={parameters[parameterName] ?? ''}
                        onChange={value =>
                          updateParameterValue(parameterName, value)
                        }
                      />
                    </InputScaffold>
                  </Select>
                );
              })
            : null}
          <Footer>
            <DownloadModal
              handleDownload={handleDownload}
              trigger={
                <StyledButton
                  title="Download Report"
                  color="gray"
                  submitting={downloading}
                />
              }
            />
            <StyledButton
              title="View Report"
              color="blue"
              margin="left"
              onClick={() => navigateToReport(title, report_slug, parameters)}
            />
          </Footer>
        </Row>
      </Body>
    </ExpandableRow>
  );
};

Report.propTypes = {
  report: PropTypes.object.isRequired,
  fetchDownload: PropTypes.func.isRequired,
};

export default Report;
