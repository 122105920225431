import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { getMaxForUnsavedQuantity } from './contact-selectors';
import { GROUP_PERSONNEL } from './ContactCredentials';
import { updateCredentialQuantities } from '../credential-request/credential-request-actions';
import { useDepartmentUnsavedCredentialsDispatch } from './DepartmentUnsavedCredentials';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import EditCredentialQuantity from './EditCredentialQuantity';

const SavedQuantityEdit = ({
  type,
  department,
  credentialRequest,
  issued,
  cancelEdit,
  maximumExceeded,
  isLockDateActive,
  onSaving = () => {},
}) => {
  const dispatch = useDispatch();

  const departmentUnsavedCredentialsDispatch =
    useDepartmentUnsavedCredentialsDispatch();

  const pristineApproved = credentialRequest.get('quantity_approved');
  const [approved, setApproved] = useState(pristineApproved);

  const pristinePending = credentialRequest.get('quantity_pending');
  const [pending, setPending] = useState(pristinePending);

  const credentialId = credentialRequest.get('credential_id');
  const totalQuantity = approved + pending;
  const pristineTotalQuantity = pristineApproved + pristinePending;
  const displayCheckMark =
    !maximumExceeded || pending + approved < pristinePending + pristineApproved;

  const maxQuantity =
    type === GROUP_PERSONNEL
      ? getMaxForUnsavedQuantity(
          type,
          department,
          credentialRequest.get('credential'),
        )
      : getMaxForUnsavedQuantity(
          type,
          department,
          credentialRequest.get('credential'),
        ) || Infinity;

  const shouldAutoApprove = () =>
    !!credentialRequest.getIn([
      'credential',
      'credentialType',
      'group_level_auto_approve',
    ]) || type === GROUP_PERSONNEL;

  const updateDepartmentUnsavedCredentials = quantity => {
    departmentUnsavedCredentialsDispatch({
      type: 'add',
      credentialId,
      quantity,
    });
  };

  const close = () => {
    cancelEdit();
    updateDepartmentUnsavedCredentials(
      pristineApproved + pristinePending - approved - pending,
    );
  };

  const addCredentials = amount => {
    if (shouldAutoApprove()) {
      if (approved - pristineApproved + amount <= maxQuantity) {
        setApproved(prevApproved => prevApproved + amount);
        updateDepartmentUnsavedCredentials(amount);
      }
    } else if (
      approved + amount <= maxQuantity &&
      approved + amount <= pristineApproved
    ) {
      setApproved(prevApproved => prevApproved + amount);
      updateDepartmentUnsavedCredentials(amount);
    } else if (
      pending + amount <= maxQuantity &&
      approved >= pristineApproved
    ) {
      setPending(prevPending => prevPending + amount);
      updateDepartmentUnsavedCredentials(amount);
    }
  };

  const substractCredentials = amount => {
    if (pending - amount >= 0) {
      setPending(prevPending => prevPending - amount);
    } else if (approved - amount >= (issued ?? 0)) {
      setApproved(prevApproved => prevApproved - amount);
    }
    updateDepartmentUnsavedCredentials(-amount);
  };

  const handleAddition = () => {
    addCredentials(1);
  };

  const handleSubtraction = () => {
    substractCredentials(1);
  };

  const handleChange = event => {
    const totalQuantity = approved + pending;
    const amount = parseInt(event.target.value, 10);
    if (isNaN(amount)) {
      return;
    } else {
      if (amount > totalQuantity) {
        addCredentials(amount - totalQuantity);
      } else {
        substractCredentials(totalQuantity - amount);
      }
    }
  };

  const handleSave = async () => {
    onSaving(true);
    const requestId = credentialRequest.get('id');

    const quantities = {
      quantity_approved: approved,
      quantity_pending: pending,
    };

    dispatch(updateCredentialQuantities(requestId, quantities)).then(() => {
      onSaving(false);
      close();
    });
  };

  return (
    <div className="contact-credentials__edit-quantity">
      <EditCredentialQuantity
        id={credentialId}
        value={totalQuantity}
        handleAddition={handleAddition}
        handleSubtraction={handleSubtraction}
        handleChange={handleChange}
        hidePlusSign={
          (isLockDateActive && totalQuantity === pristineTotalQuantity) ||
          (type === GROUP_PERSONNEL &&
            credentialRequest.get('requested_for') !== null &&
            approved - pristineApproved >= maxQuantity)
        }
        hideMinusSign={totalQuantity === 0}
      />
      <div className="button-group">
        <button
          className="button-green"
          type="button"
          onClick={handleSave}
          style={displayCheckMark ? null : { visibility: 'hidden' }}
        >
          <Icon icon="CheckCircle" />
        </button>
        <button type="button" className="button-red" onClick={close}>
          <Icon icon="CloseCircle" />
        </button>
      </div>
    </div>
  );
};

SavedQuantityEdit.propTypes = {
  type: PropTypes.string.isRequired,
  department: PropTypes.instanceOf(Map).isRequired,
  credentialRequest: PropTypes.instanceOf(Map).isRequired,
  issued: PropTypes.number,
  cancelEdit: PropTypes.func.isRequired,
  maximumExceeded: PropTypes.bool.isRequired,
  isLockDateActive: PropTypes.bool.isRequired,
  onSaving: PropTypes.func,
};

export default SavedQuantityEdit;
