import PropTypes from 'prop-types';
import classNames from 'classnames';

const Tag = ({ children, size, colorTheme, backgroundColor }) => {
  const classes = {
    tag: true,
    'tag--medium': size === 'medium',
    'tag--primary': colorTheme === 'primary',
    'input-label--optional': colorTheme === 'optional',
    'tag--category': backgroundColor,
  };

  return (
    <span className={classNames(classes)} style={{ backgroundColor }}>
      {children}
    </span>
  );
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  colorTheme: PropTypes.string,
  backgroundColor: PropTypes.string,
};
export default Tag;
