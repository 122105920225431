import React from 'react';
import Paper, { PaperHeader } from '../paper/Paper';

const ComplexReduxFieldWrapper = props => {
  const {
    children,
    input,
    label,
    meta: { touched, error },
  } = props;

  return (
    <Paper>
      <PaperHeader title={label} />
      {React.cloneElement(children, { ...input })}
      {touched && error ? (
        <div className="generic-form__body">
          <div className="input-scaffold">
            <div className="input-validation">{error}</div>
          </div>
        </div>
      ) : null}
    </Paper>
  );
};

export default ComplexReduxFieldWrapper;
