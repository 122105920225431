import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { formatValidationErrors } from '../../common/utils/getApiReducer';
import { showNotification } from '../../notification/notification-actions';
import {
  addCredentialTypeCategory,
  editCredentialTypeCategory,
} from './credential-type-actions';
import { getCurrentEvent } from '../../event/event-selectors';
import moment from 'moment-timezone';
import ColorPickerField from '../../common/forms/ColorPickerField';
import ReduxDatepickerField from '../../common/forms/ReduxDatepickerField';
import ReduxFormsField from '../../common/forms/ReduxFormsField';
import ReduxFormsFieldNoLabel from '../../common/forms/ReduxFormsFieldNoLabel';
import Paper, { PaperHeader } from '../../common/paper/Paper';
import StatusButton from '../../common/StatusButton';
import recursiveTrim from '../../common/utils/recursive-trim';
import { getCurrentCredentialTypeCategory } from './credential-type-selectors';

const CredentialTypeCategoryForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const timezone = event?.get('time_zone') ?? 'US/Pacific';
  const initialVisibleMonth = () => moment.utc();

  const currentCredentialTypeCategory = useSelector(state =>
    getCurrentCredentialTypeCategory(state, { params }),
  );

  let initialValues = {
    name: '',
    color: '#FABDB9',
  };

  if (currentCredentialTypeCategory) {
    initialValues = {
      name: currentCredentialTypeCategory.get('name'),
      color: currentCredentialTypeCategory.get('color'),
      lock_after_date: currentCredentialTypeCategory.get('lock_after_date')
        ? moment(currentCredentialTypeCategory.get('lock_after_date'))
        : null,
    };
  }

  const isEditing = !!currentCredentialTypeCategory;

  const [displayString, setDisplayString] = useState('');

  const getCredentialTypeListPath = `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/credentials/types`;

  const handleSubmit = values => {
    if (values.lock_after_date) {
      values.lock_after_date.hour(23).minute(59).second(59);
    }

    return dispatch(
      isEditing
        ? editCredentialTypeCategory(params.categoryId, recursiveTrim(values))
        : addCredentialTypeCategory(event.get('id'), recursiveTrim(values)),
    ).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      }

      navigate(getCredentialTypeListPath);
      dispatch(
        showNotification({
          message: 'Credential type category successfully saved',
          status: 'success',
        }),
      );
    });
  };

  const lockDateChange = date => {
    if (date) {
      let dateclone = moment.utc(date.format('YYYY-MM-DDTHH:mm:ss.SSSSZ'));
      dateclone.hour(23).minute(59).second(59);
      setDisplayString(dateclone.tz(timezone).format('HH:mm') + ' ' + timezone);
    } else {
      setDisplayString('');
    }
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting, submitFailed }) => (
        <Paper>
          <PaperHeader
            title={`${isEditing ? 'Edit' : 'Add'} Credential Category`}
            backTo={getCredentialTypeListPath}
            backToDisabled={submitting}
          />
          <form className="generic-form" onSubmit={handleSubmit}>
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                <Field
                  name="name"
                  label="Name"
                  component={ReduxFormsField}
                  required
                >
                  <input type="text" autoFocus />
                </Field>
                <Field
                  name="lock_after_date"
                  component={ReduxFormsField}
                  label="Lock After Date"
                >
                  <ReduxDatepickerField
                    initialVisibleMonth={initialVisibleMonth}
                    dayChangeNotify={lockDateChange}
                    allowPastDates
                    displayString={displayString}
                    showClearDate
                  />
                </Field>
                <Field
                  name="color"
                  component={ReduxFormsFieldNoLabel}
                  label="Category Color"
                  required
                >
                  <ColorPickerField />
                </Field>
              </div>
            </div>
            <div className="generic-form__footer">
              <StatusButton
                disabled={submitting || pristine}
                status={
                  submitFailed && pristine
                    ? 'error'
                    : submitting
                    ? 'loading'
                    : 'default'
                }
                type="submit"
              />
              <Link
                className="button button--plain"
                disabled={submitting}
                to={getCredentialTypeListPath}
              >
                Cancel
              </Link>
            </div>
          </form>
        </Paper>
      )}
    </Form>
  );
};

export default CredentialTypeCategoryForm;
