import { Map } from 'immutable';
import PropTypes from 'prop-types';
import CheckboxGroup from './CheckboxGroup';
import departmentTypeList from '../../lib/department-types';
import departmentTypeMap from '../../lib/department-type-map';

const SectionsCheckboxGroup = ({ eventSettings, onChange, value, ...rest }) => {
  const labelToValueMap = {};

  departmentTypeList.forEach(sectionKey => {
    labelToValueMap[departmentTypeMap[sectionKey].label.plural] =
      departmentTypeMap[sectionKey].singular;
  });

  const alphabetizedLabels = Object.keys(labelToValueMap).sort();

  const options = alphabetizedLabels
    .filter(
      label =>
        !eventSettings ||
        eventSettings.getIn([
          labelToValueMap[label],
          'settings',
          'section_enabled',
        ]),
    )
    .map(label => ({ value: labelToValueMap[label], label }));

  const selectAll = event => {
    event.preventDefault();

    const newValue = !(value.length === options.length)
      ? options.reduce((arr, option, id) => arr.concat(option.value), [])
      : [];

    onChange(newValue);
  };

  return (
    <div>
      <button className="text-link" onClick={selectAll} type="button">
        <span>Select All</span>
      </button>
      <CheckboxGroup
        options={options}
        onChange={onChange}
        value={value}
        {...rest}
      />
    </div>
  );
};

SectionsCheckboxGroup.propTypes = {
  eventSettings: PropTypes.instanceOf(Map),
  onChange: PropTypes.func,
  value: PropTypes.any,
};

export default SectionsCheckboxGroup;
