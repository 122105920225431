import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { useDropzone } from 'react-dropzone';
import {
  combineValidators,
  composeValidators,
  hasLengthGreaterThan,
  isRequired,
} from 'revalidate';
import { parse } from 'csv-parse/browser/esm/sync';
import { getCurrentEvent } from '../event/event-selectors';
import { addMealTickets, getCateringLocations } from './catering-actions';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import bytesToSize from '../common/utils/bytesToSize';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import SelectAllCheckboxGroup from '../common/forms/SelectAllCheckboxGroup';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import StatusButton from '../common/StatusButton';
import Icon from '../common/icons/Icon';

const validate = combineValidators({
  mealTypes: composeValidators(
    isRequired,
    hasLengthGreaterThan(0),
  )({
    message: 'Please choose at least one meal type',
  }),
  file: isRequired('Meal Tickets Barcode File'),
});

const BarcodeDropzone = ({ value, onChange }) => {
  const onDropAccepted = acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      onChange(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      accept: { 'text/csv': ['.csv'] },
      maxFiles: 1,
      maxSize: 1024 * 1024 * 3,
      multiple: false,
      onDropAccepted,
    });

  return (
    <div
      {...getRootProps({
        className: `dropzone ${
          isDragAccept
            ? 'dropzone--active'
            : isDragReject
            ? 'dropzone--reject'
            : ''
        }`,
      })}
    >
      <input {...getInputProps()} />
      <div key="dropzone-instructions">
        <div className="dropzone-instructions">
          <div className="dropzone-instructions--main">
            Drag-n-drop or click upload a file
          </div>
          <div className="dropzone-instructions--sub">
            <p>Accepted file type: .csv</p>
          </div>
          <div className="dropzone-instructions--sub">
            <p>Max file size: 3MB</p>
          </div>
        </div>
        {value && (
          <aside
            style={{
              textAlign: 'left',
              borderTop: '2px dashed #ddd',
              marginTop: '10px',
              paddingTop: '10px',
            }}
          >
            <h4>Uploaded File</h4>
            <p style={{ paddingTop: '5px' }}>
              {value.name} ({bytesToSize(value.size)})
            </p>
          </aside>
        )}
      </div>
      {value && value.error ? (
        <div key="dropzone-validation" className="dropzone-validation">
          {value && value.error}
        </div>
      ) : null}
    </div>
  );
};

const MealTickets = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const locations = useSelector(state => state.catering.locations.get('data'));
  const mealTypeList = useSelector(state =>
    state.catering.mealTypeList.get('data'),
  );

  useEffect(() => {
    if (event) dispatch(getCateringLocations(event.get('id')));
  }, [dispatch, event]);

  const parseFile = async file => {
    const fileText = await file.text();
    return parse(fileText, {
      from: /^[0-9]/.test(fileText) ? 1 : 2,
      skip_records_with_error: true,
      skip_records_with_empty_values: true,
      skip_empty_lines: true,
      trim: true,
      cast: (value, { quoting }) => {
        if (value === '') {
          if (quoting) {
            return '';
          } else {
            return null;
          }
        } else {
          return value;
        }
      },
    });
  };

  const handleSubmit = async values => {
    const mealTypeIds = values.mealTypes;
    const locationIds = values.locations;

    const barcodes = await parseFile(values.file);

    if (!barcodes || barcodes.length === 0) {
      return {
        file: 'Meal Tickets Barcode File must contain at least one barcode',
      };
    }

    const action = addMealTickets(
      event.get('id'),
      mealTypeIds,
      locationIds,
      barcodes.flat(),
    );

    return dispatch(action).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      }
    });
  };

  const renderForm = () => {
    const mealTypesOptions = Array.from(
      mealTypeList
        .map(mealType => ({
          value: String(mealType.get('id')),
          label: mealType.get('name'),
        }))
        .values(),
    );

    const locationsOptions = Array.from(
      locations
        .map(location => ({
          value: String(location.get('id')),
          label: location.get('location'),
        }))
        .values(),
    ).sort((location1, location2) =>
      location1.label.localeCompare(location2.label),
    );

    return (
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({
          handleSubmit,
          hasValidationErrors,
          modifiedSinceLastSubmit,
          pristine,
          submitting,
          submitFailed,
          submitSucceeded,
        }) => (
          <form className="generic-form" onSubmit={handleSubmit}>
            <div className="generic-form__body">
              <div className="input-group">
                <Field
                  name="mealTypes"
                  component={ReduxFormsFieldNoLabel}
                  label="Meal Types"
                  required
                >
                  <SelectAllCheckboxGroup
                    className="checkboxes-horizontal"
                    options={mealTypesOptions}
                  />
                </Field>
                {locationsOptions && locationsOptions.length > 0 && (
                  <Field
                    name="locations"
                    component={ReduxFormsFieldNoLabel}
                    label="Catering Locations"
                  >
                    <SelectAllCheckboxGroup
                      className="checkboxes-horizontal"
                      options={locationsOptions}
                    />
                  </Field>
                )}
                <Field
                  name="file"
                  component={ReduxFormsFieldNoLabel}
                  label="Meal Tickets Barcode File"
                  required
                >
                  <BarcodeDropzone />
                </Field>
              </div>
            </div>
            <div className="generic-form__footer">
              <StatusButton
                buttonText="Submit"
                completeText="Submitted!"
                disabled={submitting || pristine || hasValidationErrors}
                status={
                  submitSucceeded && !modifiedSinceLastSubmit
                    ? 'success'
                    : submitFailed && !modifiedSinceLastSubmit
                    ? 'error'
                    : submitting
                    ? 'loading'
                    : 'default'
                }
                type="submit"
              />
            </div>
          </form>
        )}
      />
    );
  };

  const renderEmpty = () => {
    return (
      <Paper>
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>There are no meal types specified for this event.</p>
          <p>Start by first adding a meal type.</p>
        </div>
      </Paper>
    );
  };

  if (!mealTypeList) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <Paper>
        <PaperHeader title="Meal Tickets" />
        {mealTypeList.size > 0 ? renderForm() : renderEmpty()}
      </Paper>
    </div>
  );
};

export default MealTickets;
