import { createValidator } from 'revalidate';

export const validEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = email => validEmail.test(email);

export default createValidator(
  message => value => {
    if (value && !validateEmail(value)) {
      return message;
    }
  },
  field => `${field} must be a valid email address`,
);
