import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  deleteEmailTemplate,
  disableEmailTemplate,
  enableEmailTemplate,
} from '../event/event-actions';
import {
  getCurrentEvent,
  getEmailTemplates,
  getEmailTypes,
} from '../event/event-selectors';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import { getPeriodList } from '../credential/period/period-selectors';
import departmentTypeMap from '../lib/department-type-map';
import Icon from '../common/icons/Icon';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReactTable from '../lib/react-table';
import SwitchOn from '../common/icons/SwitchOn';
import SwitchOff from '../common/icons/SwitchOff';
import NoDataComponent from '../common/table/NoDataComponent';
import YesNoFilter, { YES, NO } from '../common/table/YesNoFilter';

const EmailTemplateList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const eventId = event?.get('id');

  const emailTypes = useSelector(getEmailTypes);

  const emailTemplates = useSelector(state =>
    getEmailTemplates(state, { params }),
  );

  const periodList = useSelector(state => getPeriodList(state, { params }));

  const [showTableFilter, setShowTableFilter] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState([]);

  const emailTypeOptions = emailTypes
    ? Array.from(
        emailTypes.map(emailType => ({
          label: emailType.get('label'),
          value: emailType.get('label'),
        })),
      )
    : null;

  const periodOptions =
    periodList?.map(period => ({
      label: period.get('name'),
      value: String(period.get('id')),
    })) ?? [];

  const sectionOptions = Object.values(departmentTypeMap).map(dt => ({
    label: dt.label.plural,
    value: dt.singular,
  }));

  const toggleTableFilter = e => {
    e.preventDefault();
    setShowTableFilter(!showTableFilter);
    if (!showTableFilter) setDefaultFilter([]);
  };

  const renderOptionsFilter = (options, { onChange }) => (
    <select onChange={event => onChange(event.target.value)}>
      <option />
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );

  const renderEnabledButton = ({ original, value }) => (
    <button
      className="switch__button"
      onClick={e => {
        e.stopPropagation();
        if (value) {
          dispatch(disableEmailTemplate(eventId, original.id));
        } else {
          dispatch(enableEmailTemplate(eventId, original.id));
        }
      }}
    >
      {value ? <SwitchOn /> : <SwitchOff />}
    </button>
  );

  const actions = [
    <button
      key="add-department-filter-action"
      className="button button--plain button--icon"
      onClick={toggleTableFilter}
    >
      <Icon icon="Filter" />
      <span>{showTableFilter ? 'Clear' : 'Advanced'} Filter</span>
    </button>,
    <Link
      key="add-email-template-action"
      className="button button--plain button--icon"
      to="~add"
    >
      <Icon icon="AddCircle" />
      <span>Add Email Template</span>
    </Link>,
  ];

  const columns = [
    {
      id: 'type',
      accessor: 'emailType.label',
      Header: 'Type',
      Filter: props => renderOptionsFilter(emailTypeOptions, props),
      filterMethod: (filter, row) => row.type === filter.value,
    },
    {
      id: 'sections',
      accessor: 'sections',
      Header: 'Sections',
      Cell: ({ row }) =>
        row.sections
          ?.map(section => departmentTypeMap[section].label.singular)
          .sort()
          .join(', '),
      Filter: props => renderOptionsFilter(sectionOptions, props),
      filterMethod: (filter, row) => row.sections.includes(filter.value),
    },
    {
      id: 'periods',
      accessor: 'periods',
      Header: 'Periods',
      Cell: ({ row }) =>
        row.periods
          ?.map(period => period.name)
          .sort()
          .join(', '),
      Filter: props => renderOptionsFilter(periodOptions, props),
      filterMethod: (filter, row) =>
        row.periods.some(period => String(period.id) === filter.value),
    },
    {
      Header: 'Enabled',
      accessor: 'enabled',
      sortable: false,
      width: 100,
      Cell: renderEnabledButton,
      Filter: YesNoFilter,
      filterMethod: (filter, row) =>
        (row.enabled && filter.value === YES) ||
        (!row.enabled && filter.value === NO),
    },
    {
      id: 'DeleteAction',
      sortable: false,
      filterable: false,
      width: 64,
      className: 'action',
      Cell: <Icon icon="Trash" />,
    },
  ];

  const deleteTemplate = emailTemplateId => {
    return dispatch(deleteEmailTemplate(eventId, emailTemplateId)).then(
      action => {
        if (action.response.ok) {
          dispatch(
            showNotification({
              message: 'Template deleted',
              status: 'success',
            }),
          );
        } else {
          return formatValidationErrors(action.json).toJS();
        }
      },
    );
  };

  const onClickRow = row => {
    navigate(`~edit/${row.original.id}`);
  };

  const onClickCell = (event, row, col) => {
    if (col.id === 'DeleteAction') {
      event.stopPropagation();
      deleteTemplate(row.original.id);
    }
  };

  const renderEmpty = () => (
    <div className="generic-list--empty">
      <Icon icon="Sad" />
      <p>You have not added any email templates</p>
    </div>
  );

  return (
    <Paper>
      <PaperHeader title="Email Templates" actions={actions} />
      <div className="generic-form__body">
        {emailTemplates && !emailTemplates?.isEmpty() ? (
          <ReactTable
            data={emailTemplates.toJS()}
            columns={columns}
            minRows={0}
            defaultPageSize={20}
            showPagination={emailTemplates.size > 20}
            showPageSizeOptions={false}
            filterable={showTableFilter}
            defaultFiltered={defaultFilter}
            sortable={true}
            defaultSorted={[
              {
                id: 'type',
                asc: true,
              },
              {
                id: 'section',
                asc: true,
              },
            ]}
            getTrGroupProps={(_state, rowInfo) => ({
              onClick: () => onClickRow(rowInfo),
            })}
            getTdProps={(_state, row, col) => ({
              onClick: event => onClickCell(event, row, col),
            })}
            NoDataComponent={NoDataComponent}
          />
        ) : (
          renderEmpty()
        )}
      </div>
    </Paper>
  );
};

export default EmailTemplateList;
