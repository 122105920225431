import { createContext, useContext, useReducer } from 'react';
import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { exceedMaximumByAmount } from '../common/utils/credentials/credential-utils';

const DepartmentUnsavedCredentialsContext = createContext(null);
const DepartmentUnsavedCredentialsDispatchContext = createContext(null);

export const DepartmentUnsavedCredentialsProvider = ({ children }) => {
  const [unsavedCredentials, dispatch] = useReducer(
    unsavedCredentialsReducer,
    Map(),
  );

  return (
    <DepartmentUnsavedCredentialsContext.Provider value={unsavedCredentials}>
      <DepartmentUnsavedCredentialsDispatchContext.Provider value={dispatch}>
        {children}
      </DepartmentUnsavedCredentialsDispatchContext.Provider>
    </DepartmentUnsavedCredentialsContext.Provider>
  );
};

export const useDepartmentUnsavedCredentialsDispatch = () =>
  useContext(DepartmentUnsavedCredentialsDispatchContext);

export const useDepartmentUnsavedCredentials = () =>
  useContext(DepartmentUnsavedCredentialsContext);

export const useDepartmentUnsavedCredentialQuantity = credentialId => {
  const unsavedCredentials = useContext(DepartmentUnsavedCredentialsContext);
  return unsavedCredentials.get(credentialId) ?? 0;
};

const selectMaximumRequestableParameters = createSelector(
  unsavedCredentialQuantity => unsavedCredentialQuantity,
  (_, department) => department,
  (_, __, credentialId) => credentialId,
  (delta, department, credentialId) =>
    exceedMaximumByAmount(delta, department, credentialId),
);

export const useMaximumRequestableParameters = (department, credentialId) =>
  selectMaximumRequestableParameters(
    useDepartmentUnsavedCredentialQuantity(credentialId),
    department,
    credentialId,
  );

const unsavedCredentialsReducer = (unsavedCredentials, action) => {
  switch (action.type) {
    case 'add':
      return handleAdd(unsavedCredentials, action);

    case 'subtract':
      return handleSubtract(unsavedCredentials, action);

    default:
      return unsavedCredentials;
  }
};

const handleAdd = (unsavedCredentials, action) =>
  action.changedQuantities
    ? updateMultipleCredentials(unsavedCredentials, action.changedQuantities)
    : updateCredential(
        unsavedCredentials,
        action.credentialId,
        action.quantity,
      );

const handleSubtract = (unsavedCredentials, action) =>
  action.changedQuantities
    ? updateMultipleCredentials(
        unsavedCredentials,
        action.changedQuantities,
        true,
      )
    : updateCredential(
        unsavedCredentials,
        action.credentialId,
        action.quantity,
        true,
      );

const updateMultipleCredentials = (
  unsavedCredentials,
  changedQuantities,
  subtract = false,
) => {
  if (!changedQuantities?.length) return unsavedCredentials;

  let newUnsavedCredentials = unsavedCredentials;
  changedQuantities.forEach(({ credentialId, quantity }) => {
    newUnsavedCredentials = updateCredential(
      newUnsavedCredentials,
      credentialId,
      quantity,
      subtract,
    );
  });

  return newUnsavedCredentials;
};

const updateCredential = (
  unsavedCredentials,
  credentialId,
  quantity,
  subtract = false,
) => {
  if (!quantity || quantity === 0) {
    return unsavedCredentials;
  }

  return unsavedCredentials.set(
    credentialId,
    (unsavedCredentials.get(credentialId) ?? 0) +
      (subtract ? -1 * quantity : quantity),
  );
};
