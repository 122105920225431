import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { Checkbox } from 'synfrastructure';
import { StyledButton } from '../common/StyledButton';
import { approveOnsiteRequest } from '../onsite/onsite-actions';
import { showNotification } from '../notification/notification-actions';
import get from 'lodash/get';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ToggleScaffold from './forms/ToggleScaffold';
import InputScaffold from './forms/InputScaffold';

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;
const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ApproveOnsiteRequestModal = ({ onsiteRequest, trigger }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [adminNotes, setAdminNotes] = useState(null);
  const [approvePendingCredentials, setApprovePendingCredentials] =
    useState(true);

  const open = e => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setSubmitting(false);
  };

  const displayErrors = errors => {
    if (errors?.length) {
      const message = errors
        .map(error => error.error)
        .reduce((acc, error) => (error ? `${acc}${error}.\n` : acc), '');
      dispatch(showNotification({ message, duration: 60000, status: 'error' }));
    }
  };

  const handleApprove = async () => {
    setSubmitting(true);

    dispatch(
      approveOnsiteRequest(
        onsiteRequest.id,
        adminNotes,
        approvePendingCredentials,
      ),
    ).then(response => {
      if (response.json.validationErrors) {
        dispatch(
          showNotification({
            message: `${response.json.error}: ${response.json.message}`,
            status: 'error',
          }),
        );
      }

      if (response.json.contact?.errors)
        displayErrors(response.json.contact.errors);

      if (response.json.credentials) {
        const rejected = response.json.credentials.rejected;
        if (rejected?.length) {
          const message = rejected
            .map(error =>
              error.isBoom ? get(error, 'output.payload.message') : null,
            )
            .reduce((acc, error) => (error ? `${acc}${error}.\n` : acc), '');
          dispatch(
            showNotification({ message, duration: 60000, status: 'error' }),
          );
        }
      }

      close();
    });
  };

  return (
    <Modal
      open={isOpen}
      onOpen={open}
      onClose={close}
      size="tiny"
      trigger={trigger}
    >
      <Modal.Header>Approve Onsite Request</Modal.Header>
      <Modal.Content>
        <Body>
          <InputScaffold label="Admin Notes">
            <textarea
              value={adminNotes || ''}
              onChange={e => setAdminNotes(e.target.value)}
            />
          </InputScaffold>
          <ToggleScaffold label="Approve all pending credential requests">
            <Checkbox
              id="approve_credentials"
              checked={approvePendingCredentials}
              onChange={() =>
                setApprovePendingCredentials(!approvePendingCredentials)
              }
            />
          </ToggleScaffold>
          <Footer>
            <StyledButton
              title="Cancel"
              color="gray"
              margin="right"
              handleClick={close}
            />
            <StyledButton
              submitting={submitting}
              title="Approve"
              color="blue"
              handleClick={handleApprove}
            />
          </Footer>
        </Body>
      </Modal.Content>
    </Modal>
  );
};

ApproveOnsiteRequestModal.propTypes = {
  onsiteRequest: PropTypes.object.isRequired,
  trigger: PropTypes.node,
};

export default ApproveOnsiteRequestModal;
