import { useLocation } from 'react-router-dom';
import { removeLastUriSegment } from '../common/uri';
import {
  saveInternApplication,
  createInternApplicationSection,
  createOrUpdateInternApplicationSection,
  deleteInternApplicationSection,
  updateInternApplicationSection,
  updateInternApplicationSectionSortOrders,
} from './intern-actions';
import Application from '../common/application/Application';

const InternApplicationForm = () => {
  const location = useLocation();
  return (
    <div>
      <Application
        type="intern"
        backTo={removeLastUriSegment(location.pathname)}
        headerText="Application for Interns"
        saveApplication={saveInternApplication}
        createSection={createInternApplicationSection}
        createOrUpdateSection={createOrUpdateInternApplicationSection}
        deleteSection={deleteInternApplicationSection}
        updateSection={updateInternApplicationSection}
        updateSortOrders={updateInternApplicationSectionSortOrders}
        sections={[
          {
            key: 'legal-notice',
            heading: 'Legal Notice',
            text: 'When entered, a legal notice will appear and have to be agreed on before an applicant can view the application.',
            excludeIfBlank: true,
          },
          {
            key: 'intro',
            heading: 'Application Intro',
            text: 'This intro will appear directly below the header image (if one exists) and can be as long as needed.',
          },
          {
            key: 'intern-name',
            heading: 'Intern Name',
            text:
              'This section will capture the name of the intern. You can enter a description ' +
              'that will appear above the inputs below.',
            sortable: true,
          },
        ]}
      />
    </div>
  );
};

export default InternApplicationForm;
