import PropTypes from 'prop-types';
import InputScaffold from '../../forms/InputScaffold';
import translateErrors from '../translateErrors';

const Money = ({ field, onBlur, onChange }) => (
  <InputScaffold
    label={field.get('label')}
    validation={translateErrors(field.getIn(['error', 'value']))}
    required={!!field.get('required')}
  >
    <input
      type="number"
      name="value"
      value={field.get('value') || ''}
      onBlur={onBlur}
      onChange={e => {
        if (!e.target.value || /^-?\d+(\.\d{1,2})?$/.test(e.target.value)) {
          onChange(field.set('value', e.target.value));
        }
      }}
      placeholder="0.00"
      step="0.01"
    />
  </InputScaffold>
);

Money.propTypes = {
  field: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default Money;
