import { logout } from '../auth/auth-redirects';
import departmentTypeMap from '../lib/department-type-map';
import FullPageMessage from '../common/FullPageMessage';

export function renderErrorMessage(message) {
  return (
    <div style={{ marginTop: '20px' }}>
      <FullPageMessage icon="Sad" message={message}>
        <button className="button" onClick={logout}>
          Logout
        </button>
      </FullPageMessage>
    </div>
  );
}

export function getContactLabelForDepartment(department) {
  const departmentType = department ? department.get('type') : null;
  return departmentType
    ? departmentTypeMap[departmentType].contacts.label.singular
    : 'Staff';
}
