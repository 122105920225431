import { useState } from 'react';
import { validateEmail } from '../../../common/validate/is-valid-email';
import { ValidationError } from '../../../common/StyledValidationError';
import { Modal } from 'semantic-ui-react';
import { StyledButton } from '../../../common/StyledButton';
import {
  ModalBody,
  Header,
  Description,
  Input,
  Row,
  Column,
  ModalFooter,
  Spacer,
} from './EmailModal.styles';

const styles = {
  button: {
    margin: '0px',
    width: '45%',
  },
};

const EmailModal = ({ emailReport, trigger }) => {
  const [value, setValue] = useState('');
  const [validationError, setValidationError] = useState('');
  const [submissionError, setSubmissionError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
    setValue('');
    setValidationError('');
    setSubmissionError(false);
    setSubmitting(false);
  };

  const close = () => {
    setIsOpen(false);
  };

  const handleChange = e => {
    setValue(e.target.value);
    setValidationError('');
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const emails = value.split(',').map(email => email.trim());

    const valid = validateEmails(emails);
    if (!valid) {
      setValidationError(
        'RECIPIENTS MUST BE A LIST OF COMMA SEPARATED EMAIL ADDRESSES',
      );
      setSubmitting(false);
      return;
    }

    const { response } = await emailReport({ mime: 'text/csv', emails });

    if (!response.ok) {
      setSubmissionError(true);
      setSubmitting(false);
      return;
    }

    setSubmitting(false);
    close();
  };

  const validateEmails = emails => emails.every(email => validateEmail(email));

  return (
    <Modal
      open={isOpen}
      onOpen={() => open()}
      onClose={() => close()}
      size="tiny"
      trigger={trigger}
    >
      <Modal.Header>Email to...</Modal.Header>
      <Modal.Content>
        <ModalBody>
          <Column>
            <Row>
              <Column>
                <Header>Recipients</Header>
                <Description>
                  Separate multiple email addresses with a comma.
                </Description>
              </Column>
            </Row>
            <Input value={value} onChange={handleChange} />
            {validationError && (
              <ValidationError>{validationError}</ValidationError>
            )}
          </Column>
        </ModalBody>
        <Spacer />
        <ModalFooter>
          <StyledButton
            color="blue"
            title="Send CSV"
            handleClick={() => handleSubmit()}
            disabled={!!validationError || !value}
            submitting={submitting}
            error={submissionError}
            margin="right"
            style={styles.button}
          />
        </ModalFooter>
      </Modal.Content>
    </Modal>
  );
};

export default EmailModal;
