import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, FormSpy } from 'react-final-form';
import { List, Map } from 'immutable';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { editContact } from './contact-actions';
import classNames from 'classnames';
import isValidPhone from '../common/validate/is-valid-phone';
import isValidEmail from '../common/validate/is-valid-email';
import phoneFilter from '../common/filters/phone-filter';
import PropTypes from 'prop-types';

const filters = {
  email: value => value.trim(),
  phone: phoneFilter,
  relationship: value => value.trim(),
};

const AutoSave = ({ contact, active, form }) => {
  const [field, setField] = useState(null);

  useEffect(() => {
    if (!field && active) setField(active);

    if (field && !active) {
      setField(null);
      const { pristine, valid } = form.getFieldState(field);

      if (!valid) form.restart();
      else if (!pristine) form.submit();
    }
  }, [field, active, contact, form]);

  return null;
};

const ContactInfo = ({ contact, relationships, readOnly = false }) => {
  const dispatch = useDispatch();

  const initialValues = {
    phone: contact.get('phone') ?? '',
    email: contact.get('email') ?? '',
    relationship: contact.get('relationship') ?? '',
  };

  const phoneInputRef = useRef(null);
  const emailInputRef = useRef(null);

  const handleSubmit = (values, form) => {
    const payload = {};
    Object.keys(form.getState().dirtyFields).forEach(
      field =>
        (payload[field] = values[field] ? filters[field](values[field]) : null),
    );

    return dispatch(editContact(contact.get('id'), payload)).then(action => {
      if (!action.response.ok)
        return formatValidationErrors(action.json).toJS();
    });
  };

  const renderRelationshipField = () =>
    relationships?.length ? (
      <Field
        name="relationship"
        render={({ input, meta: { error, submitError } }) => (
          <div
            className={classNames({
              'staff-details__relationship': true,
              'staff-details__relationship-no-value': !input.value,
            })}
          >
            <select disabled={readOnly} {...input}>
              <option value="">
                {readOnly ? 'No Relationship' : '+ Add Relationship'}
              </option>
              {relationships.map((relationship, index) => (
                <option key={`${relationship}-${index}`} value={relationship}>
                  {relationship}
                </option>
              ))}
            </select>
            {renderValidationErrors(error ?? submitError)}
          </div>
        )}
      />
    ) : null;

  const renderValidationErrors = error =>
    error ? (
      <div className="input-validation">
        <ul>
          <li key={error}>{error}</li>
        </ul>
      </div>
    ) : null;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy
            subscription={{ active: true }}
            component={AutoSave}
            contact={contact}
          />
          <div className="contact-info">
            <Field
              name="phone"
              validate={isValidPhone({
                message: 'Invalid 10-digit phone number',
              })}
              render={({ input, meta: { error, submitError } }) => (
                <div>
                  <input
                    type="text"
                    disabled={readOnly}
                    placeholder={readOnly ? 'No Phone Number' : '+ Add Phone'}
                    ref={phoneInputRef}
                    onKeyUp={e =>
                      e.key === 'Enter' && phoneInputRef.current.blur()
                    }
                    {...input}
                  />
                  {renderValidationErrors(error ?? submitError)}
                </div>
              )}
            />
            <Field
              name="email"
              validate={isValidEmail({ message: 'Invalid email address' })}
              render={({ input, meta: { error, submitError } }) => (
                <div>
                  <input
                    type="text"
                    disabled={readOnly}
                    placeholder={readOnly ? 'No Email' : '+ Add Email'}
                    ref={emailInputRef}
                    onKeyUp={e =>
                      e.key === 'Enter' && emailInputRef.current.blur()
                    }
                    {...input}
                  />
                  {renderValidationErrors(error ?? submitError)}
                </div>
              )}
            />
            {renderRelationshipField()}
          </div>
        </form>
      )}
    />
  );
};

ContactInfo.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  relationships: PropTypes.instanceOf(List).isRequired,
  readOnly: PropTypes.bool,
};

export default ContactInfo;
