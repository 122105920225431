import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from './department-selectors';
import { clearDepartmentList, fetchDepartmentList } from './department-actions';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import Icon from '../common/icons/Icon';

const DepartmentListLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const departmentList = useSelector(state => state.department.departmentList);
  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const loaded =
    departmentList.getIn([event?.get('id'), params.departmentType, 'loaded']) ??
    false;

  useEffect(() => {
    if (event && params.departmentType) {
      dispatch(fetchDepartmentList(event.get('id'), params.departmentType));
      return () =>
        dispatch(clearDepartmentList(event.get('id'), params.departmentType));
    }
  }, [dispatch, event, params.departmentType]);

  if (!event || !loaded) {
    return <LoadingIndicator />;
  } else if (
    loaded &&
    params.departmentSlug &&
    typeof department === 'undefined'
  ) {
    return <NotFound />;
  }

  return (
    <div>
      {event?.get('deactivated') ? (
        <div className="notification-strip__disabled">
          <Icon icon="Lock" className="notification-strip__icon" />
          <p className="notification-strip__message">Event Deactivated</p>
        </div>
      ) : null}
      <Outlet />
    </div>
  );
};

export default DepartmentListLoader;
