import PropTypes from 'prop-types';

const fieldTypes = [
  'text',
  'textarea',
  'number',
  'checkbox',
  'date',
  'time',
  'money',
  'select',
  'file',
];

const components = {};
fieldTypes.forEach(fieldType => {
  components[fieldType] = require(`./request-fields/${fieldType}`).default;
});

const CustomForm = ({
  event,
  formSchema,
  disabled = false,
  onChange,
  uploadAction,
}) => {
  const onFieldChange = (index, field) => {
    if (
      field.get('required') &&
      (!field.get('value') ||
        (typeof field.get('value') === 'string' &&
          !field.get('value').trim().length))
    ) {
      field = field.set('error', {
        value: [`${field.get('label')} is required`],
      });
    } else {
      field = field.set('error', false);
    }
    formSchema.updateField(index, field);
    onChange(formSchema);
  };

  const renderFields = () => {
    if (!formSchema) {
      return null;
    }

    return formSchema
      .getImmutableSchema()
      .get('fields')
      .map((field, index) => {
        const Component = components[field.get('type')];

        const props = {
          key: index,
          field,
          onBlur: () => onFieldChange(index, field),
          onChange: updatedField => {
            onFieldChange(index, updatedField);
          },
          disabled,
          event,
        };

        if (field.get('type') === 'file') {
          props.uploadAction = uploadAction;
        }

        return <Component {...props} />;
      });
  };

  return <div className="input-group">{renderFields()}</div>;
};

CustomForm.propTypes = {
  event: PropTypes.object,
  formSchema: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  uploadAction: PropTypes.func,
};

export default CustomForm;
