import { createValidator } from 'revalidate';

export default function mustBeLessThanOrEqualTo(otherField, otherFieldLabel) {
  return createValidator(
    message => (value, allValues) => {
      if (value > allValues[otherField]) {
        return message;
      }
    },
    field => `${field} must be less than or equal to ${otherFieldLabel}`,
  );
}
