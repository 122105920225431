import { Field, useFormState } from 'react-final-form';
import { Map } from 'immutable';
import { composeValidators, isRequired, isRequiredIf } from 'revalidate';
import PropTypes from 'prop-types';
import moment from 'moment';
import DEFAULT_TIMEZONE from '../../lib/default-timezone';
import dateNotAfter from '../validate/date-not-after';
import timeNotAfterIfSameDate from '../validate/time-not-after-if-same-date';
import ReduxFormsField from '../forms/ReduxFormsField';
import ReduxFormsFieldNoLabel from '../forms/ReduxFormsFieldNoLabel';
import ReduxDatepickerField from '../forms/ReduxDatepickerField';
import ReduxTimepickerField from '../forms/ReduxTimepickerField';
import CheckboxBoolean from '../forms/CheckboxBoolean';
import StatusButton from '../StatusButton';

const ApplicationSidebar = ({ type, application }) => {
  const {
    modifiedSinceLastSubmit,
    pristine,
    submitting,
    submitFailed,
    submitSucceeded,
    submitError,
    invalid,
    values,
  } = useFormState();

  const renderOverrideFields = formValues => {
    if (!formValues.override) return null;

    return [
      <Field
        key="override_close_date"
        name="override_close_date"
        label="New Close Date"
        component={ReduxFormsField}
        required
        validate={isRequiredIf(values => values.override)(
          'Override Close Date',
        )}
      >
        <ReduxDatepickerField />
      </Field>,
      <Field
        key="override_close_time"
        name="override_close_time"
        component={ReduxFormsFieldNoLabel}
        required
        validate={isRequiredIf(values => values.override)(
          'Override Close Time',
        )}
      >
        <ReduxTimepickerField allowEmpty={false} />
      </Field>,
    ];
  };

  const renderPreview = () => {
    if (!application) return null;
    return (
      <h1>
        <a
          href={`/${type}/${application.get('link_token')}/preview`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Preview
        </a>
      </h1>
    );
  };

  const renderLastPublishedMessage = () => {
    if (!application) return null;
    return (
      <div className="application-sidebar__last-published">
        Last published on &nbsp;
        {moment
          .utc(application.get('updated_at') || application.get('created_at'))
          .subtract(new Date().getTimezoneOffset(), 'minute')
          .format('MM/DD/YY h:mm A')}
      </div>
    );
  };

  const renderPublicLink = (tokenField, closeDateField) => {
    if (!application.get(tokenField)) return null;

    const path = `/${type}/${application.get(tokenField)}`;
    return (
      <div className="media-application-sidebar__public-link">
        <a
          href={path}
          target="_blank"
          rel="noopener noreferrer"
        >{`${window.location.hostname}${path}`}</a>
        <div className="public-link__close-date">
          Close date:{' '}
          {moment
            .utc(application.get(closeDateField))
            .tz(DEFAULT_TIMEZONE)
            .format('MM/DD/YY')}
        </div>
      </div>
    );
  };

  const renderPublicLinks = () =>
    !application ? null : (
      <div className="media-application-sidebar__public-links">
        <h1>Application Public Links</h1>
        {renderPublicLink('link_token', 'close_date')}
        {renderPublicLink('override_link_token', 'override_close_date')}
      </div>
    );

  return (
    <div>
      <div className="media-application-sidebar__header">
        <h1>Application Settings</h1>
        <h2>All times listed are {DEFAULT_TIMEZONE}.</h2>
      </div>
      <div className="generic-form__body">
        <div className="input-group">
          <Field
            name="name"
            label="Application name"
            component={ReduxFormsField}
            required
            validate={isRequired('Name')}
          >
            <input type="text" autoComplete="on" />
          </Field>
          <Field
            name="open_date"
            label="Open Date"
            component={ReduxFormsField}
            required
            validate={composeValidators(
              isRequired,
              dateNotAfter('close_date', 'Close Date'),
            )('Open Date')}
          >
            <ReduxDatepickerField />
          </Field>
          <Field
            name="open_time"
            component={ReduxFormsFieldNoLabel}
            required
            validate={composeValidators(
              isRequired,
              timeNotAfterIfSameDate(
                'close_time',
                'open_date',
                'close_date',
                'Closing Time',
              ),
            )('Open Time')}
          >
            <ReduxTimepickerField allowEmpty={false} />
          </Field>
          <Field
            name="close_date"
            label="Close Date"
            component={ReduxFormsField}
            required
            className="close-date"
            validate={isRequired('Close Date')}
          >
            <ReduxDatepickerField />
          </Field>
          <Field
            name="close_time"
            component={ReduxFormsFieldNoLabel}
            required
            validate={isRequired('Close Time')}
          >
            <ReduxTimepickerField allowEmpty={false} />
          </Field>
          <span className="override-flexbox">
            <Field
              name="override"
              component={ReduxFormsField}
              label="Override Close Date"
              required
            >
              <CheckboxBoolean id="override_close_date" />
            </Field>
          </span>
          {renderOverrideFields(values)}
        </div>
      </div>
      {submitError && !modifiedSinceLastSubmit && (
        <div className="global-error-message">{submitError}</div>
      )}
      <div className="generic-form__footer">
        {renderPreview()}
        <StatusButton
          style={{ width: '100%' }}
          buttonText={application ? 'Save' : 'Publish Application'}
          errorText="Save Failed"
          disabled={
            pristine || submitting || (invalid && !modifiedSinceLastSubmit)
          }
          status={
            submitting
              ? 'loading'
              : modifiedSinceLastSubmit
              ? 'default'
              : submitFailed
              ? 'error'
              : submitSucceeded && pristine
              ? 'success'
              : 'default'
          }
        />
        {renderLastPublishedMessage()}
        {renderPublicLinks()}
      </div>
    </div>
  );
};

ApplicationSidebar.propTypes = {
  type: PropTypes.oneOf(['media', 'intern', 'advance']).isRequired,
  application: PropTypes.instanceOf(Map),
};

export default ApplicationSidebar;
