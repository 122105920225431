import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import {
  getApplicationEmailTypes,
  getCurrentApplication,
} from './application-selectors';
import { getCurrentEvent } from '../../event/event-selectors';
import { formatValidationErrors } from '../utils/getApiReducer';
import { showNotification } from '../../notification/notification-actions';
import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import moment from 'moment';
import styled from 'styled-components';
import DEFAULT_TIMEZONE from '../../lib/default-timezone';
import departmentTypeMap from '../../lib/department-type-map';
import FormSchema from '../../lib/form-schema';
import LoadingIndicator from '../LoadingIndicator';
import Paper, { PaperHeader } from '../paper/Paper';
import Icon from '../icons/Icon';
import ExpandableRow from '../ExpandableRow';
import ApplicationSidebar from './ApplicationSidebar';
import ApplicationSection from './ApplicationSection';
import ApplicationImageUpload from './ApplicationImageUpload';
import RadioGroup from '../forms/RadioGroup';
import ReduxFormsFieldNoLabel from '../forms/ReduxFormsFieldNoLabel';
import CredentialsSection from './CredentialsSection';
import EmailSettingsSection from './EmailSettingsSection';
import SortableSectionList from './SortableSectionList';

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  align-items: center;
  > span.icon svg {
    fill: #e74c3c;
  }
`;

const isSectionDirty = (dirtyFields, section) =>
  Object.keys(dirtyFields).some(key =>
    key.startsWith(`sections.${section.key}`),
  );

const getMomentWithJustTheDate = date =>
  date
    ? moment.utc(moment.utc(date).tz(DEFAULT_TIMEZONE).format('YYYY-MM-DD'))
    : null;

const getTimeFromDate = date =>
  date ? moment.utc(date).tz(DEFAULT_TIMEZONE).format('HH:mm:ss') : null;

const getEmptySectionContent = section => ({
  blocks: [
    {
      key: section.key,
      data: {},
      text: '',
      type: 'unstyled',
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
    },
  ],
  entityMap: {},
});

const getInitialSectionValues = (application, sections) => {
  const sectionValues = {};
  const appSections = application?.get('sections');
  let initialSortOrder = 0;

  sections.forEach(section => {
    const appSection = appSections?.find(s => s.get('key') === section.key);
    const appSectionName = appSection?.get('name')?.trim();

    sectionValues[section.key] = {
      id: appSection?.get('id') ?? section.key,
      key: section.key,
      name:
        appSectionName && appSectionName !== ''
          ? appSectionName
          : section.heading,
      text: section.text,
      isNew: !!!appSection,
      isDeleted: false,
      isCustom: false,
      excludeIfBlank: section.excludeIfBlank ?? false,
      optional: section.optional ?? false,
      doNotDisplay: Boolean(appSection?.get('do_not_display')) ?? false,
      sortable: section.sortable ?? false,
      sortOrder: appSection?.has('sort_order')
        ? appSection?.get('sort_order')
        : section.sortable
        ? initialSortOrder++
        : 0,
      content: appSection?.get('content')?.toJS() ?? null,
    };
  });

  appSections
    ?.filter(
      appSection =>
        !sections.some(section => section.key === appSection.get('key')),
    )
    .forEach(appSection => {
      const sectionKey = `custom-${appSection.get('id')}`;

      sectionValues[sectionKey] = {
        id: appSection.get('id'),
        key: sectionKey,
        name: appSection.get('name'),
        text: null,
        isNew: false,
        isDeleted: false,
        isCustom: true,
        excludeIfBlank: false,
        optional: false,
        doNotDisplay: Boolean(appSection.get('do_not_display')),
        sortable: true,
        sortOrder: appSection.has('sort_order')
          ? appSection?.get('sort_order')
          : initialSortOrder++,
        content: appSection.get('content')?.toJS() ?? null,
        customFields: new FormSchema(appSection.get('custom_fields')),
      };
    });

  return sectionValues;
};

const getInitialSelectedCategories = application => {
  const selectedCategoriesMap = new Map();
  if (application) {
    application.get('credentials').forEach(credential => {
      const category = credential.getIn(['credentialType', 'category']);
      selectedCategoriesMap.set(category.get('id'), category);
    });
  }

  const selectedCategories = [];
  for (const category of selectedCategoriesMap.values()) {
    selectedCategories.push({
      label: category.get('name'),
      value: category.get('id'),
    });
  }

  return selectedCategories;
};

const getInitialSelectedPeriods = application => {
  const selectedPeriodsMap = new Map();
  if (application) {
    application.get('credentials').forEach(credential => {
      const periods =
        credential.getIn(['credentialType', 'issue_frequency']) === 'ONE_TIME'
          ? [...credential.get('oneTimePeriods')]
          : [credential.get('period')];
      periods.forEach(period => {
        selectedPeriodsMap.set(period.get('id'), period);
      });
    });
  }

  const selectedPeriods = [];
  for (const period of selectedPeriodsMap.values()) {
    selectedPeriods.push({
      label: period.get('name'),
      value: period.get('id'),
    });
  }

  return selectedPeriods;
};

const getInitialValues = (
  application,
  sections,
  includeCredentialsSection,
  includeEmailSettingsSection,
  emailTypes,
) => {
  const initialValues = application
    ? {
        name: application.get('name'),
        open_date: getMomentWithJustTheDate(application.get('open_date')),
        open_time: getTimeFromDate(application.get('open_date')),
        close_date: getMomentWithJustTheDate(application.get('close_date')),
        close_time: getTimeFromDate(application.get('close_date')),
        override: !!application.get('override_close_date'),
        override_close_date: getMomentWithJustTheDate(
          application.get('override_close_date'),
        ),
        override_close_time: getTimeFromDate(
          application.get('override_close_date'),
        ),
        header_image: application.get('header_image') ?? null,
        background_image: application.get('background_image') ?? null,
        background_image_tiled: Boolean(
          application.get('background_image_tiled'),
        ),
        sections: getInitialSectionValues(application, sections),
      }
    : {
        name: null,
        open_date: null,
        open_time: null,
        close_date: null,
        close_time: null,
        override: false,
        override_close_date: null,
        override_close_time: null,
        header_image: null,
        background_image: null,
        background_image_tiled: false,
        sections: getInitialSectionValues(application, sections),
      };

  if (includeCredentialsSection) {
    initialValues.selectedCategories = application
      ? getInitialSelectedCategories(application)
      : [];

    initialValues.selectedPeriods = application
      ? getInitialSelectedPeriods(application)
      : [];

    initialValues.selectedCredentials = application
      ? application
          .get('credentials')
          .map(credential => ({
            id: credential.get('id'),
            isSingleInstance: Boolean(
              credential.get('_pivot_is_single_instance'),
            ),
            isAddOn: Boolean(credential.get('_pivot_is_add_on')),
          }))
          .toArray()
      : [];
  }

  if (includeEmailSettingsSection) {
    const initialEmailTemplates = {};
    const emailTemplates = application?.get('emailTemplates');

    emailTypes?.forEach(emailType => {
      const emailTypeId = emailType.get('id');
      const emailTemplate = emailTemplates?.find(
        emailTemplate => emailTemplate.get('email_type_id') === emailTypeId,
      );
      initialEmailTemplates[emailType.get('type')] = {
        id: emailTemplate?.get('id') ?? null,
        email_type_id: emailTypeId,
        subject: emailTemplate?.get('subject') ?? null,
        body: emailTemplate?.get('body')?.toJSON() ?? null,
      };
    });

    initialValues.emailTemplates = initialEmailTemplates;
  }

  return initialValues;
};

const Application = ({
  type,
  backTo,
  headerText,
  saveApplication,
  sections,
  createSection,
  createOrUpdateSection,
  deleteSection,
  updateSection,
  departmentType,
  includeCredentialModifiers = false,
  includeCredentialsSection = false,
  includeEmailSettingsSection = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const application = useSelector(state =>
    getCurrentApplication(state, { type, params }),
  );

  const emailTypes = useSelector(state =>
    getApplicationEmailTypes(state, { type }),
  );

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const [initialValues, setInitialValues] = useState(null);
  const [expanded, setExpanded] = useState({});
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (!submitting) {
      setInitialValues(
        getInitialValues(
          application,
          sections,
          includeCredentialsSection,
          includeEmailSettingsSection,
          emailTypes,
        ),
      );
    }
    // emailTypes has been removed from dependencies so that adding a
    // new sample email template doesn't refresh the application
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    application,
    includeCredentialsSection,
    includeEmailSettingsSection,
    sections,
    submitting,
  ]);

  const renderHeaderActions = (formSections, addNewSection) => {
    const getNewSection = () => {
      const id = uniqueId('custom-new-');
      const sortOrder =
        Object.values(formSections).reduce(
          (max, section) =>
            max > (isNaN(section.sortOrder) ? 0 : section.sortOrder)
              ? max
              : section.sortOrder,
          0,
        ) + 1;
      return {
        id,
        key: id,
        name: null,
        text: null,
        isNew: true,
        isCustom: true,
        excludeIfBlank: false,
        sortable: true,
        sortOrder,
        content: null,
        customFields: null,
      };
    };

    return [
      <button
        key="add-section"
        type="button"
        className="button button--plain button--icon"
        onClick={() => addNewSection(getNewSection())}
      >
        <Icon icon="AddCircle" />
        Add Section
      </button>,
    ];
  };

  const toggleSection = section => {
    setExpanded(prevExpanded => {
      const newExpanded = { ...prevExpanded };
      newExpanded[section] = !prevExpanded[section];
      return newExpanded;
    });
  };

  const onDragEnd = (change, sortedSections, active, over) => {
    if (!active || !over || active.id === over.id) return;

    const indexToRemove = sortedSections.findIndex(
      section => section.id === active.id,
    );

    const removedSections = sortedSections.splice(indexToRemove, 1);

    let indexToInsert = sortedSections.findIndex(
      section => section.id === over.id,
    );

    indexToInsert =
      indexToRemove > indexToInsert ? indexToInsert : indexToInsert + 1;

    sortedSections.splice(indexToInsert, 0, removedSections[0]);

    sortedSections.forEach((section, index) => {
      change(`sections.${section.key}.sortOrder`, index);
    });
  };

  const handleSubmit = (values, form) => {
    setSubmitting(true);

    const { dirtyFields } = form.getState();

    const payload = {
      id: application?.get('id') ?? -1,
      name: `${values.name}`,
      open_date: `${values.open_date.format('YYYY-MM-DD')} ${values.open_time}`,
      close_date: `${values.close_date.format('YYYY-MM-DD')} ${
        values.close_time
      }`,
    };

    payload.override_close_date = values.override
      ? `${values.override_close_date.format('YYYY-MM-DD')} ${
          values.override_close_time
        }`
      : null;

    if (departmentType) {
      payload.departmentType = departmentType.singular;
    }

    if (!values.background_image && !!initialValues.background_image) {
      payload.background_image = null;
    }

    if (!values.header_image && !!initialValues.header_image) {
      payload.header_image = null;
    }

    Object.assign(
      payload,
      pick(values, [
        'header_image',
        'background_image',
        'background_image_tiled',
        'selectedCredentials',
        'emailTemplates',
      ]),
    );

    const handleResponse = action => {
      if (!action.response.ok) {
        const errors = formatValidationErrors(action.json).toJS();
        const error = { [FORM_ERROR]: Object.values(errors) };
        throw error;
      }
    };

    const newApplication = !!!application;

    return dispatch(
      saveApplication(
        event.get('promoter_id'),
        event.get('festival_id'),
        event.get('id'),
        payload,
      ),
    )
      .then(action => {
        handleResponse(action);

        const applicationId = action.json.id;
        const promises = [];

        for (const section of Object.values(values.sections)) {
          if (section.isCustom) {
            const payload = {
              name: section.name,
              content: section.content
                ? section.content
                : section.excludeIfBlank
                ? null
                : getEmptySectionContent(section),
              custom_fields: section.customFields,
              sort_order: section.sortOrder,
              do_not_display: section.doNotDisplay,
            };

            if (section.isDeleted) {
              if (section.isNew) continue;
              promises.push(
                dispatch(
                  deleteSection(applicationId, section.id, event.get('id')),
                ),
              );
            } else if (section.isNew) {
              promises.push(
                dispatch(createSection(applicationId, payload)).then(action =>
                  handleResponse(action),
                ),
              );
            } else if (isSectionDirty(dirtyFields, section)) {
              promises.push(
                dispatch(updateSection(section.id, payload)).then(action =>
                  handleResponse(action),
                ),
              );
            }
          } else {
            if (
              (section.isNew && !section.excludeIfBlank) ||
              (section.isNew && section.excludeIfBlank && section.content) ||
              (!section.isNew && isSectionDirty(dirtyFields, section))
            ) {
              const payload = {
                content: section.content
                  ? section.content
                  : section.excludeIfBlank
                  ? null
                  : getEmptySectionContent(section),
                sort_order: section.sortOrder,
                do_not_display: section.doNotDisplay,
              };
              promises.push(
                dispatch(
                  createOrUpdateSection(applicationId, section.key, payload),
                ).then(action => handleResponse(action)),
              );
            }
          }
        }
        return Promise.all(promises).then(() => {
          setSubmitting(false);
          if (newApplication) {
            dispatch(
              showNotification({
                message: 'Application successfully saved',
                status: 'success',
              }),
            );
            navigate(backTo);
          }
        });
      })
      .catch(error => error);
  };

  const renderBackgroundImageUploadForm = () => {
    return (
      <ExpandableRow
        columns={[
          <span className="anchored-row-name">
            <Icon icon="Anchor" className="anchored" />
            Background Image
          </span>,
        ]}
        onToggleExpansion={() => toggleSection('background-image')}
        isExpanded={expanded['background-image']}
        isExpandable
        renderChildrenWhenNotExpanded={true}
      >
        <Field name="background_image">
          {({ input: { value, onChange } }) => (
            <ApplicationImageUpload
              image={application?.get('backgroundImage')}
              value={value}
              onChange={onChange}
              description="Uploaded image will be used as the application background image when viewed by the applicant."
            />
          )}
        </Field>
        <div style={{ padding: '0 20px', marginBottom: '20px' }}>
          <Field
            name="background_image_tiled"
            component={ReduxFormsFieldNoLabel}
          >
            <RadioGroup
              options={[
                {
                  label: 'Stretch to fill',
                  value: false,
                },
                {
                  label: 'Tile to fill',
                  value: true,
                },
              ]}
            />
          </Field>
        </div>
      </ExpandableRow>
    );
  };

  const renderHeaderImageUploadForm = () => {
    return (
      <ExpandableRow
        columns={[
          <span className="anchored-row-name">
            <Icon icon="Anchor" className="anchored" />
            Header Image
          </span>,
        ]}
        onToggleExpansion={() => toggleSection('header-image')}
        isExpanded={expanded['header-image']}
        isExpandable
        renderChildrenWhenNotExpanded={true}
      >
        <Field name="header_image">
          {({ input: { value, onChange } }) => (
            <ApplicationImageUpload
              image={application?.get('headerImage')}
              value={value}
              onChange={onChange}
              description="This image will appear at the top of the public form. Maximum width for the image is 960px.
            If a smaller than 960px image is uploaded it will be center aligned and displayed in its
            original size."
            />
          )}
        </Field>
      </ExpandableRow>
    );
  };

  const renderAnchoredSections = formSections =>
    Object.values(formSections).map(section =>
      section.sortable ? null : (
        <ExpandableRow
          key={section.key}
          columns={[
            <span className="anchored-row-name">
              <Icon icon="Anchor" className="anchored" />
              {section.name}
            </span>,
          ]}
          onToggleExpansion={() => toggleSection(section.key)}
          isExpanded={expanded[section.key]}
          isExpandable
          renderChildrenWhenNotExpanded={true}
        >
          <ApplicationSection event={event} section={section} />
        </ExpandableRow>
      ),
    );

  const renderSortableSections = (change, formSections) => {
    const sortedSections = Object.values(formSections)
      .filter(section => section.sortable && !section.isDeleted)
      .sort((section1, section2) => section1.sortOrder - section2.sortOrder);

    return (
      <SortableSectionList
        event={event}
        sections={sortedSections}
        onDragEnd={({ active, over }) =>
          onDragEnd(change, sortedSections, active, over)
        }
      />
    );
  };

  const renderCredentialsSection = () => {
    const label = departmentType
      ? departmentType.label.singular
      : type in departmentTypeMap
      ? departmentTypeMap[type].label.singular
      : null;

    if (!includeCredentialsSection) {
      return null;
    }

    return (
      <Field
        name="selectedCredentials"
        validate={value => (!value?.length ? 'Required' : null)}
      >
        {({ input: { value, onChange }, meta: { error } }) => {
          const header = (
            <span className="anchored-row-name">
              <Icon icon="Anchor" className="anchored" />
              {label} Credentials
            </span>
          );

          const icons = error ? (
            <IconContainer>
              <Tooltip
                id="selected-credentials-tooltip"
                offset={10}
                content="Must select at least one credential"
                style={{ fontSize: '14px' }}
              />
              <Icon
                icon="ErrorTriangle"
                dataTooltipId="selected-credentials-tooltip"
                style={{ pointerEvents: 'initial' }}
              />
            </IconContainer>
          ) : null;

          return (
            <ExpandableRow
              columns={[header, icons]}
              onToggleExpansion={() => toggleSection('application-credentials')}
              isExpanded={expanded['application-credentials']}
              isExpandable
              renderChildrenWhenNotExpanded={true}
            >
              <CredentialsSection
                type={type}
                event={event}
                selectedCredentials={value}
                includeCredentialModifiers={includeCredentialModifiers}
                onChange={onChange}
              />
            </ExpandableRow>
          );
        }}
      </Field>
    );
  };

  const renderEmailSettingsSection = () => {
    if (!includeEmailSettingsSection) {
      return null;
    }

    return (
      <ExpandableRow
        columns={[
          <span className="anchored-row-name">
            <Icon icon="Anchor" className="anchored" />
            Email Settings
          </span>,
        ]}
        onToggleExpansion={() => toggleSection('application-email-settings')}
        isExpanded={expanded['application-email-settings']}
        isExpandable
        renderChildrenWhenNotExpanded={true}
      >
        <EmailSettingsSection type={type} />
      </ExpandableRow>
    );
  };

  const registerDeletedSections = formSections =>
    Object.values(formSections).map(section =>
      section.isDeleted ? (
        <Field
          key={section.key}
          name={`sections.${section.key}.isDeleted`}
          render={() => null}
        />
      ) : null,
    );

  if (!event || !initialValues) {
    return <LoadingIndicator />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{
        addNewSection: ([newSection], state, { changeValue }) => {
          changeValue(state, `sections.${newSection.key}`, () => newSection);
        },
      }}
      render={({ handleSubmit, form, values }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <main>
            <Paper>
              <PaperHeader
                title={headerText}
                actions={renderHeaderActions(
                  values.sections,
                  form.mutators.addNewSection,
                )}
                backTo={backTo}
              />
            </Paper>

            <section className="application__wrapper">
              <section>
                {registerDeletedSections(values.sections)}
                {renderBackgroundImageUploadForm()}
                {renderHeaderImageUploadForm()}
                {renderAnchoredSections(values.sections)}
                {renderSortableSections(form.change, values.sections)}
                {renderCredentialsSection()}
                {renderEmailSettingsSection()}
              </section>
              <aside>
                <ApplicationSidebar type={type} application={application} />
              </aside>
            </section>
          </main>
        </form>
      )}
    />
  );
};

Application.propTypes = {
  type: PropTypes.oneOf(['media', 'intern', 'advance']).isRequired,
  backTo: PropTypes.string.isRequired,
  headerText: PropTypes.string.isRequired,
  saveApplication: PropTypes.func.isRequired,
  createSection: PropTypes.func.isRequired,
  createOrUpdateSection: PropTypes.func.isRequired,
  deleteSection: PropTypes.func.isRequired,
  updateSection: PropTypes.func.isRequired,
  departmentType: PropTypes.object,
  includeCredentialModifiers: PropTypes.bool,
  includeCredentialsSection: PropTypes.bool,
  includeEmailSettingsSection: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      heading: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      optional: PropTypes.bool,
    }),
  ),
};

export default Application;
