import { createSelector } from 'reselect';
import { getCurrentEvent } from '../../event/event-selectors';

export const getCurrentPeriod = createSelector(
  getCurrentEvent,
  (_state, props) => props.params.periodId,
  (event, periodId) =>
    event
      ? event
          .get('periods')
          .find(period => period.get('id').toString() === periodId.toString())
      : null,
);

export const getPeriodList = createSelector(getCurrentEvent, event =>
  event?.get('periods').sortBy(period => period.get('start_date')),
);
