import {
  combineValidators,
  composeValidators,
  createValidator,
  hasLengthLessThan,
  isRequired,
} from 'revalidate';
import { hasError, hasErrorAt } from 'revalidate/assertions';
import isValidDecimal from '../validate/is-valid-decimal';

export default class FormValidator {
  constructor() {
    this.name = 'form-validator';
  }

  getFieldTypes() {
    return [
      'text',
      'textarea',
      'number',
      'checkbox',
      'date',
      'time',
      'money',
      'select',
      'file',
    ];
  }

  _getCommonSchema() {
    return {
      type: isRequired('Type'),
      label: isRequired('Label'),
      description: hasLengthLessThan(10001)('Description'),
    };
  }

  _validate(field, schema) {
    const validators = combineValidators(schema);
    const result = validators(field.toJS());

    if (!hasError(result)) {
      return false;
    }

    const errors = {};

    if (hasErrorAt(result, 'type')) errors.type = [result.type];
    if (hasErrorAt(result, 'label')) errors.label = [result.label];
    if (hasErrorAt(result, 'description'))
      errors.description = [result.description];
    if (hasErrorAt(result, 'value')) errors.value = [result.value];
    if (hasErrorAt(result, 'options')) errors.options = [result.options];

    return errors;
  }

  text(field) {
    const fieldSchema = this._getCommonSchema();
    fieldSchema.value = hasLengthLessThan(256)('Default Value');
    return this._validate(field, fieldSchema);
  }

  textarea(field) {
    const fieldSchema = this._getCommonSchema();
    fieldSchema.value = hasLengthLessThan(10001)('Default Value');
    return this._validate(field, fieldSchema);
  }

  number(field, numberDecimalPlaces = Number.MAX_SAFE_INTEGER) {
    const fieldSchema = this._getCommonSchema();
    fieldSchema.value = isValidDecimal(numberDecimalPlaces)('Default Value');
    return this._validate(field, fieldSchema);
  }

  checkbox(field) {
    const fieldSchema = this._getCommonSchema();
    return this._validate(field, fieldSchema);
  }

  date(field) {
    const fieldSchema = this._getCommonSchema();
    return this._validate(field, fieldSchema);
  }

  time(field) {
    const fieldSchema = this._getCommonSchema();
    return this._validate(field, fieldSchema);
  }

  money(field) {
    return this.number(field, 2);
  }

  select(field) {
    const fieldSchema = this._getCommonSchema();
    fieldSchema.value = hasLengthLessThan(256)('Default Value');
    fieldSchema.options = composeValidators(
      isRequired,
      createValidator(
        message => value => {
          if (Array.isArray(value) && value.length === 0) return message;
        },
        field => `${field} must contain at least one value`,
      ),
      createValidator(
        message => value => {
          if (Array.isArray(value)) {
            if (
              value.some(
                element =>
                  !element || element.trim() === '' || element.length > 255,
              )
            ) {
              return message;
            }
          }
        },
        field =>
          `${field} must not contain any empty values or values longer than 255 characters`,
      ),
    )('Options');
    return this._validate(field, fieldSchema);
  }

  file(field) {
    const fieldSchema = this._getCommonSchema();
    return this._validate(field, fieldSchema);
  }
}
