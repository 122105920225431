import { useEffect, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getStatusForButton } from '../../common/forms/FormHelper';
import { getCurrentEvent } from '../../event/event-selectors';
import { getCurrentPeriod } from './period-selectors';
import { editPeriod, fetchPulseEventList } from './period-actions';
import { editPeriodValidator } from './period-validators';
import { formatValidationErrors } from '../../common/utils/getApiReducer';
import { showNotification } from '../../notification/notification-actions';
import moment from 'moment';
import recursiveTrim from '../../common/utils/recursive-trim';
import Paper, { PaperHeader } from '../../common/paper/Paper';
import StatusButton from '../../common/StatusButton';
import InputScaffold from '../../common/forms/InputScaffold.js';
import ReduxFormsField from '../../common/forms/ReduxFormsField';

const DATE_FORMAT = 'MM/DD/YYYY';

const PeriodEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const period = useSelector(state => getCurrentPeriod(state, { params }));
  const pulseEvent = useSelector(state => {
    if (!period) return null;
    return state.period.pulseEventList
      .get('data')
      ?.find(
        pulseEventItem =>
          pulseEventItem.get('event_id') === period.get('pulse_event_id'),
      );
  });

  const initialValues = useMemo(
    () => ({
      name: period?.get('name'),
    }),
    [period],
  );

  const backTo = '..';

  useEffect(() => {
    if (event) dispatch(fetchPulseEventList(event.get('id')));
  }, [dispatch, event]);

  const handleSubmit = values => {
    return dispatch(
      editPeriod(
        period.get('event_id'),
        period.get('id'),
        recursiveTrim(values),
      ),
    ).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      } else {
        navigate(backTo);
        dispatch(
          showNotification({
            message: 'Period successfully saved',
            status: 'success',
          }),
        );
      }
    });
  };

  const renderDates = () => {
    const startDate = moment.utc(period.get('start_date'));
    const endDate = moment.utc(period.get('end_date'));

    return (
      <InputScaffold label="Dates" required disabled>
        <input
          type="text"
          value={`${startDate.format(DATE_FORMAT)} - ${endDate.format(
            DATE_FORMAT,
          )}`}
          disabled
        />
      </InputScaffold>
    );
  };

  const renderPulseEvent = () => {
    if (!pulseEvent) return null;

    return (
      <InputScaffold label="Pulse Event" required disabled>
        <input type="text" value={pulseEvent.get('name')} disabled />
      </InputScaffold>
    );
  };

  if (!period) {
    return null;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={editPeriodValidator}
      render={({ handleSubmit, pristine, submitting, valid }) => (
        <Paper>
          <PaperHeader
            backTo={backTo}
            backToDisabled={submitting}
            title="Edit Period"
          />
          <form className="generic-form" onSubmit={handleSubmit}>
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                <Field
                  name="name"
                  label="Period Name"
                  component={ReduxFormsField}
                  required
                >
                  <input type="text" />
                </Field>
              </div>
              <div className="input-group input-group--small">
                {renderDates()}
                {renderPulseEvent()}
              </div>
            </div>
            <div className="generic-form__footer">
              <StatusButton
                disabled={submitting || pristine || !valid}
                status={getStatusForButton({ submitting, pristine })}
                type="submit"
              />
              <Link
                className="button button--plain"
                disabled={submitting}
                to={backTo}
              >
                Cancel
              </Link>
            </div>
          </form>
        </Paper>
      )}
    />
  );
};

export default PeriodEdit;
