import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { sortBy } from 'lodash';
import { showNotification } from '../notification/notification-actions';
import { deleteOnsiteCredentialRequest } from './onsite-actions';
import {
  getCredentialDateRange,
  getCredentialStartDate,
} from '../common/utils/credentials/credential-utils';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import Tag from '../common/Tag';

const ErrorIcon = styled.div`
  > span.icon svg {
    fill: #e74c3c;
  }
`;

const OnsiteRequestDetails = ({
  onsiteRequest,
  categories,
  credentialTypes,
  credentials,
}) => {
  const dispatch = useDispatch();

  const issuedCredentials = useMemo(() => {
    const issuedCredentials = new Set();
    onsiteRequest.data.requestedFor.pulseOrders.forEach(pulseOrder => {
      pulseOrder.pulseTags.forEach(pulseTag => {
        issuedCredentials.add(pulseTag.credential.id);
      });
    });
    return issuedCredentials;
  }, [onsiteRequest.data.requestedFor.pulseOrders]);

  const renderNotes = () => (
    <div
      style={{
        flex: '0.7',
        paddingRight: '10px',
      }}
    >
      {onsiteRequest.type === 'Name Change' && (
        <div>
          <span>
            <strong>Original Name:</strong>{' '}
            {onsiteRequest.requestData.originalName}
          </span>
          <span style={{ marginLeft: '20px' }}>
            <strong>New Name: </strong>
            {onsiteRequest.requestData.firstName}{' '}
            {onsiteRequest.requestData.lastName}
          </span>
        </div>
      )}
      {onsiteRequest.requestNotes && (
        <div>
          <strong>Reason:</strong>{' '}
          {Array.isArray(onsiteRequest.requestNotes) ? (
            onsiteRequest.requestNotes.map((requestNote, index) => {
              if (index === 0)
                return <span key={index}>{requestNote.note}</span>;
              if (index === 1)
                return (
                  <div style={{ paddingTop: '20px' }} key={index}>
                    <strong>History:</strong>
                    <div style={{ paddingTop: '5px' }}>
                      <span>
                        <em>
                          {moment(requestNote.requestedOn).format(
                            'MM/DD/YYYY h:mm A',
                          )}
                        </em>{' '}
                        - {requestNote.note}
                      </span>
                    </div>
                  </div>
                );
              return (
                <div style={{ paddingTop: '10px' }} key={index}>
                  <span>
                    <em>
                      {moment(requestNote.requestedOn).format(
                        'MM/DD/YYYY h:mm A',
                      )}
                    </em>{' '}
                    - {requestNote.note}
                  </span>
                </div>
              );
            })
          ) : (
            <span>{onsiteRequest.requestNotes}</span>
          )}
        </div>
      )}
      {onsiteRequest.status !== 'Pending' && (
        <div style={{ paddingTop: '20px' }}>
          {onsiteRequest.reviewedBy && (
            <div>
              <strong>{onsiteRequest.status} by</strong>{' '}
              {onsiteRequest.reviewedBy} on{' '}
              {`${moment(onsiteRequest.reviewedOn).format(
                'MM/DD/YYYY h:mm A',
              )}`}
            </div>
          )}
          {onsiteRequest.adminNotes && (
            <div style={{ paddingTop: '20px' }}>
              <strong>Admin Notes:</strong> {onsiteRequest.adminNotes}
            </div>
          )}
        </div>
      )}
    </div>
  );

  const mapCredentialRequests = onsiteCredentialRequests => {
    const credentialRequests = onsiteCredentialRequests.map(
      credentialRequest => {
        const credential = credentials[credentialRequest.credential_id];
        const credentialType =
          credentialTypes[credential.get('credential_type_id')];
        const category = categories[credentialType.get('category_id')];
        const issueFrequency = credentialType.get('issue_frequency');

        return {
          id: credentialRequest.id,
          onsiteRequestId: onsiteRequest.id,
          categoryName: category.get('name'),
          categoryColor: category.get('color'),
          credentialTypeName: credentialType.get('name'),
          issueFrequency,
          periodName: credential.getIn(['period', 'name']),
          credentialStartDate: getCredentialStartDate(credential),
          credentialDateRange: getCredentialDateRange(credential),
          isLocked: issuedCredentials.has(credential.get('id')),
          error: credentialRequest.error,
        };
      },
    );

    return sortBy(credentialRequests, [
      'categoryName',
      'credentialTypeName',
      'credentialStartDate',
    ]);
  };

  const getCredentialRequests = (action, status) => {
    const credentialRequests = onsiteRequest.data.credentialRequests.filter(
      credentialRequest =>
        (!action ||
          (Array.isArray(action) &&
            action.includes(credentialRequest.action)) ||
          (!Array.isArray(action) && credentialRequest.action === action)) &&
        (!status || credentialRequest.status === status),
    );
    return mapCredentialRequests(credentialRequests);
  };

  const removeOnsiteCredentialRequest = async credentialRequest => {
    dispatch(
      deleteOnsiteCredentialRequest(
        credentialRequest.onsiteRequestId,
        credentialRequest.id,
      ),
    ).then(action => {
      if (action.json.validationErrors) {
        dispatch(
          showNotification({
            message: `${action.json.error}: ${action.json.message}`,
            status: 'error',
          }),
        );
      }
    });
  };

  const renderCredentialRequestsByCategory = (
    credentialRequests,
    category,
    canRemove = false,
  ) => {
    if (!credentialRequests.length) return null;
    return (
      <div style={{ marginBottom: '20px' }}>
        <div style={{ marginBottom: '10px' }}>
          <strong>{category} Credentials</strong>
        </div>
        <div>
          {credentialRequests.map(credentialRequest => {
            const style = {
              display: 'flex',
              alignItems: 'center',
              marginBottom: '5px',
            };
            if (credentialRequest.isLocked) style['fontStyle'] = 'italic';

            return (
              <div key={credentialRequest.id} style={style}>
                {credentialRequest.error && (
                  <ErrorIcon
                    data-tooltip-id={`request-error-tooltip-${credentialRequest.id}`}
                  >
                    <Icon icon="ErrorTriangle" />
                    <Tooltip
                      id={`request-error-tooltip-${credentialRequest.id}`}
                      content={credentialRequest.error}
                    />
                  </ErrorIcon>
                )}
                {credentialRequest.isLocked && (
                  <div
                    data-tooltip-id={`request-lock-tooltip-${credentialRequest.id}`}
                  >
                    <Icon icon="IssuedCredential" />
                    <Tooltip
                      id={`request-lock-tooltip-${credentialRequest.id}`}
                      content="Issued"
                    />
                  </div>
                )}
                {canRemove && (
                  <span
                    style={{ paddingRight: '5px', cursor: 'pointer' }}
                    onClick={() =>
                      removeOnsiteCredentialRequest(credentialRequest)
                    }
                  >
                    <Icon icon="Trash" />
                  </span>
                )}
                <Tag
                  backgroundColor={credentialRequest.categoryColor}
                  size="medium"
                >
                  {credentialRequest.categoryName.toUpperCase()}
                </Tag>
                {credentialRequest.issueFrequency !== 'ONE_TIME' && (
                  <span style={{ paddingLeft: '5px' }}>
                    <strong>{credentialRequest.periodName}</strong>
                  </span>
                )}
                <span style={{ paddingLeft: '10px' }}>
                  {credentialRequest.credentialTypeName}
                </span>
                <span style={{ paddingLeft: '10px' }}>
                  ({credentialRequest.credentialDateRange})
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderPendingCredentialRequests = () => {
    const newCredentialRequests = [];
    const deleteCredentialRequests = new Set();
    const existingCredentialRequests = [];

    onsiteRequest.data.credentialRequests.forEach(credentialRequest => {
      if (credentialRequest.action === 'Delete') {
        deleteCredentialRequests.add(credentialRequest.credential_id);
      } else if (credentialRequest.action === 'Add') {
        const credential = credentials[credentialRequest.credential_id];
        const credentialType =
          credentialTypes[credential.get('credential_type_id')];
        const category = categories[credentialType.get('category_id')];
        const issueFrequency = credentialType.get('issue_frequency');

        newCredentialRequests.push({
          id: credentialRequest.id,
          onsiteRequestId: onsiteRequest.id,
          categoryName: category.get('name'),
          categoryColor: category.get('color'),
          credentialTypeName: credentialType.get('name'),
          issueFrequency,
          periodName: credential.getIn(['period', 'name']),
          credentialStartDate: getCredentialStartDate(credential),
          credentialDateRange: getCredentialDateRange(credential),
        });
      }
    });

    onsiteRequest.data.requestedFor.credentialRequests.forEach(
      credentialRequest => {
        if (
          !credentialRequest.quantity_pending ||
          deleteCredentialRequests.has(credentialRequest.credential_id)
        )
          return;

        const credential = credentials[credentialRequest.credential_id];
        const credentialType =
          credentialTypes[credential.get('credential_type_id')];
        const category = categories[credentialType.get('category_id')];
        const issueFrequency = credentialType.get('issue_frequency');

        existingCredentialRequests.push({
          id: credentialRequest.id,
          categoryName: category.get('name'),
          categoryColor: category.get('color'),
          credentialTypeName: credentialType.get('name'),
          issueFrequency,
          periodName: credential.getIn(['period', 'name']),
          credentialStartDate: getCredentialStartDate(credential),
          credentialDateRange: getCredentialDateRange(credential),
        });
      },
    );

    const sortedNewCredentialRequests = sortBy(newCredentialRequests, [
      'categoryName',
      'credentialTypeName',
      'credentialStartDate',
    ]);
    const sortedExistingCredentialRequests = sortBy(
      existingCredentialRequests,
      ['categoryName', 'credentialTypeName', 'credentialStartDate'],
    );

    return (
      <div
        style={{
          flex: '1',
        }}
      >
        {!!sortedNewCredentialRequests.length &&
          renderCredentialRequestsByCategory(
            sortedNewCredentialRequests,
            'Submitted',
            true,
          )}
        {!!sortedExistingCredentialRequests.length &&
          renderCredentialRequestsByCategory(
            sortedExistingCredentialRequests,
            'Pending',
          )}
      </div>
    );
  };

  const renderCredentialRequestsByActionStatus = (
    action,
    status,
    category,
    canRemove = false,
  ) => {
    const credentialRequests = getCredentialRequests(action, status);

    return renderCredentialRequestsByCategory(
      credentialRequests,
      category,
      canRemove,
    );
  };

  const renderCredentialRequests = () => {
    if (
      onsiteRequest.type === 'Add Person' &&
      onsiteRequest.status === 'Pending'
    ) {
      return (
        <div style={{ flex: '1' }}>{renderPendingCredentialRequests()}</div>
      );
    }

    if (
      onsiteRequest.type === 'Modify Person' &&
      onsiteRequest.status === 'Pending'
    ) {
      return (
        <div style={{ flex: '1' }}>
          {renderCredentialRequestsByActionStatus(
            'Delete',
            'Pending',
            'Deleted',
            true,
          )}
          {renderPendingCredentialRequests()}
        </div>
      );
    }

    if (
      ['Add Person', 'Modify Person'].includes(onsiteRequest.type) &&
      onsiteRequest.status === 'Approved'
    ) {
      return (
        <div style={{ flex: '1' }}>
          {renderCredentialRequestsByActionStatus(
            ['Add', 'Existing'],
            'Approved',
            'Approved',
          )}
          {renderCredentialRequestsByActionStatus(
            'Delete',
            'Approved',
            'Deleted',
          )}
          {renderCredentialRequestsByActionStatus(
            ['Add', 'Delete'],
            'Errored',
            'Errored',
          )}
        </div>
      );
    }

    if (
      ['Add Person', 'Modify Person'].includes(onsiteRequest.type) &&
      onsiteRequest.status === 'Declined'
    ) {
      return (
        <div style={{ flex: '1' }}>
          {renderCredentialRequestsByActionStatus(
            'Add',
            'Declined',
            'Declined',
          )}
          {renderCredentialRequestsByActionStatus(
            'Delete',
            'Declined',
            'Declined Deleted',
          )}
        </div>
      );
    }
  };

  return (
    <div
      style={{
        padding: '20px',
        display: 'flex',
      }}
    >
      {renderNotes(onsiteRequest)}
      {onsiteRequest.type !== 'Name Change' &&
        renderCredentialRequests(onsiteRequest)}
    </div>
  );
};

OnsiteRequestDetails.propTypes = {
  onsiteRequest: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  credentialTypes: PropTypes.object.isRequired,
  credentials: PropTypes.object.isRequired,
};

export default OnsiteRequestDetails;
