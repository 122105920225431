import { createSelector } from 'reselect';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from '../department/department-selectors';

export const getAdvanceApplicationsList = createSelector(
  getCurrentEvent,
  state => state.advance.applications,
  (_, props) => props.params.departmentType,
  (event, applications, departmentType) =>
    event
      ? applications
          .getIn([event.get('id'), 'data'])
          ?.filter(
            application =>
              application.get('department_type') === departmentType,
          )
      : null,
);

export const getDepartmentAvailableAdvanceApplications = createSelector(
  getCurrentDepartment,
  getAdvanceApplicationsList,
  (department, applications) =>
    applications?.filter(
      application =>
        application.get('department_type') === department.get('type'),
    ),
);

export const getDepartmentAdvanceApplication = createSelector(
  getCurrentDepartment,
  getAdvanceApplicationsList,
  (department, applications) =>
    applications?.find(
      application =>
        application.get('id') ===
        department?.get('advanceFormLink')?.get('application_id'),
    ),
);

export const getDepartmentInfo = createSelector(
  state => state.advance.departmentInfo.get('data'),
  state => state.advance.departmentInfo.get('loaded'),
  (data, loaded) => (loaded ? data : null),
);

export const getCateringAvailability = createSelector(
  state => state.advance.catering.get('data'),
  state => state.advance.catering.get('loaded'),
  (data, loaded) => (loaded ? data : null),
);

export const getCredentialMinimums = createSelector(
  getDepartmentInfo,
  departmentInfo =>
    departmentInfo
      ?.get('credentialRequests')
      .filter(credentialRequest => !!credentialRequest.get('requested_for'))
      .reduce((map, credentialRequest) => {
        const id = String(credentialRequest.get('credential_id'));
        const quantity =
          credentialRequest.get('quantity_approved') +
          credentialRequest.get('quantity_pending');
        if (!map.has(id)) return map.set(id, quantity);
        return map.set(id, map.get(id) + quantity);
      }, new Map()) ?? new Map(),
);
