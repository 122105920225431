export const getStatusForButton = ({
  pristine,
  submitting,
  submitFailed,
  submitSucceeded,
}) => {
  if (submitSucceeded && pristine) {
    return 'success';
  } else {
    if (submitFailed && pristine) {
      return 'error';
    } else {
      if (submitting) {
        return 'loading';
      } else {
        return 'default';
      }
    }
  }
};
