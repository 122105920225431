import styled from 'styled-components';

export const ValidationError = styled.div`
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #e74c3c;
  background: white;
  border: 1px solid #e74c3c;
  border-radius: 2px;
  padding: 4px 8px;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
`;
