import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentPromoter } from '../promoter/promoter-selectors';

const OnsiteHeader = () => {
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const promoter = useSelector(state => getCurrentPromoter(state, { params }));

  if (!promoter) {
    return null;
  }

  const promoterImage =
    promoter && promoter.has('file_id')
      ? promoter.getIn(['logo', 'location'])
      : null;

  const eventImage =
    event && event.has('file_id') ? event.getIn(['logo', 'location']) : null;

  const renderBanner = () => {
    if (eventImage) {
      return <img alt="onsite-banner" src={eventImage} />;
    }

    if (promoterImage || promoter || event) {
      return (
        <div>
          {promoterImage && <img alt="onsite-banner" src={promoterImage} />}
          {!promoterImage && promoter && <span>{promoter.get('name')}</span>}
          {event && (
            <span style={{ marginTop: '10px' }}>{event.get('name')}</span>
          )}
        </div>
      );
    }

    return null;
  };

  return <div className="onsite__banner">{renderBanner()}</div>;
};

export default OnsiteHeader;
