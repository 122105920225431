export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

export function showNotification(notification) {
  return {
    type: SHOW_NOTIFICATION,
    notification,
  };
}

export function dismissNotification() {
  return {
    type: DISMISS_NOTIFICATION,
  };
}
