import PropTypes from 'prop-types';
import Icon from '../icons/Icon';

const QuantityField = ({
  value,
  onChange,
  min = 0,
  max,
  maxValueTemplate = 'Max value: {value}',
  maxHidden,
  allowEmpty = false,
  alwaysShowMinus,
  disabled,
  ...inputProps
}) => {
  const addOne = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!value && min !== 0) {
      onChange(min, null);
    } else {
      const oldValue = parseInt(value || min, 10);
      const newValue = max ? Math.min(max, oldValue + 1) : oldValue + 1;
      onChange(newValue, oldValue);
    }
  };

  const subtractOne = event => {
    event.preventDefault();
    event.stopPropagation();
    if (parseInt(value, 10) === min && allowEmpty) {
      onChange('');
    } else {
      const oldValue = parseInt(value, 10);
      const newValue = min ? Math.max(min, oldValue - 1) : oldValue - 1;
      onChange(newValue, oldValue);
    }
  };

  const _onChange = event => {
    // restrict value to integer-only characters or set to min if empty
    const enteredValue = event.currentTarget.value;
    if (!disabled) {
      if (enteredValue === '' && allowEmpty) {
        onChange(enteredValue);
      } else {
        if (typeof max !== 'number') {
          max = Infinity;
        }
        if (typeof max !== 'number') {
          min = Infinity;
        }
        const normalizedValue = parseInt(
          Math.max(Math.min(enteredValue, max), min),
          10,
        );
        onChange(normalizedValue, parseInt(value || min, 10));
      }
    }
  };

  const renderMinusButton = () => {
    if (
      typeof min !== 'number' ||
      value > min ||
      (value === min && allowEmpty)
    ) {
      return (
        <button type="button" className="quantity-button" onClick={subtractOne}>
          <Icon icon="Minus" />
        </button>
      );
    }
    return <span className="quantity-button__spacer" />;
  };

  const renderAddButton = () => {
    return typeof max !== 'number' || value < max ? (
      <button type="button" className="quantity-button" onClick={addOne}>
        <Icon icon="Plus" />
      </button>
    ) : (
      <span className="quantity-button__spacer" />
    );
  };

  return (
    <div className="quantity-field">
      {disabled && !alwaysShowMinus ? (
        <span className="quantity-button__spacer"></span>
      ) : (
        renderMinusButton()
      )}
      <input
        type="text"
        value={value}
        min={min || 0}
        onChange={_onChange}
        onClick={event => {
          event.stopPropagation();
        }}
        onKeyPress={event => {
          if (event.charCode < 48 || event.charCode > 57) {
            event.preventDefault();
          }
        }}
        {...inputProps}
      />
      {disabled ? (
        <span className="quantity-button__spacer"></span>
      ) : (
        renderAddButton()
      )}
      {typeof max !== 'undefined' && !maxHidden ? (
        <span className="quantity-field-max">
          {' '}
          {maxValueTemplate.replace('{value}', max)}
        </span>
      ) : null}
    </div>
  );
};

QuantityField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  maxValueTemplate: PropTypes.string,
  maxHidden: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  alwaysShowMinus: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default QuantityField;
