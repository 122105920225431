import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentDepartment } from '../department/department-selectors';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentPromoter } from '../promoter/promoter-selectors';
import acl, { resourceConstants, suffixConstants } from './utils/acl';
// import { isImmutable } from 'immutable';
// In 4.0 isImmutable will be exported, on 3.x we need to do this.
import Immutable from 'immutable';
const isImmutable = Immutable.Iterable.isIterable;

export { resourceConstants as resource };
export { suffixConstants as suffix };

export const useACL = aclQueries => {
  const params = useParams();

  const [state, setState] = useState({
    acl: {},
  });

  const loggedInUser = useSelector(state =>
    state.auth.loggedInUser.get('data'),
  );

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const event = useSelector(state => getCurrentEvent(state, { params }));
  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const resourcesMap = useMemo(() => {
    const resourcesMap = new Map();
    if (promoter) resourcesMap.set(resourceConstants.PROMOTER, promoter.toJS());
    if (event) resourcesMap.set(resourceConstants.EVENT, event.toJS());
    if (department)
      resourcesMap.set(resourceConstants.DEPARTMENT, department.toJS());
    return resourcesMap;
  }, [department, event, promoter]);

  const suffixesMap = useMemo(() => {
    const suffixesMap = new Map();
    if (params.departmentType)
      suffixesMap.set(suffixConstants.DEPARTMENT_TYPE, params.departmentType);
    return suffixesMap;
  }, [params.departmentType]);

  useEffect(() => {
    const getResource = value => {
      const resource = value[0];
      if (typeof resource === 'string' && resourcesMap.has(resource))
        return resourcesMap.get(resource);
      if (isImmutable(resource)) resource.toJS();
      return resource;
    };

    const getAction = action => {
      return typeof action === 'object' && suffixesMap.has(action.suffix)
        ? `${action.action}-${suffixesMap.get(action.suffix)}`
        : action;
    };

    const getActions = value => {
      const actions = value[1];
      return Array.isArray(actions)
        ? actions.map(getAction)
        : [getAction(actions)];
    };

    const queryACL = async () => {
      const loggedInUserJS = loggedInUser.toJS();
      const queries = Object.entries(aclQueries).flatMap(([key, value]) => {
        const resource = getResource(value);
        const actions = getActions(value);
        return actions.map(
          action =>
            new Promise(resolve => {
              if (!resource) return resolve({ key, allowed: false });

              acl.query(loggedInUserJS, resource, action, (error, allowed) => {
                if (error) {
                  return resolve({ key, allowed: false, error });
                }
                return resolve({ key, allowed });
              });
            }),
        );
      });

      const newState = {
        acl: {},
      };

      const results = await Promise.allSettled(queries);
      results.forEach(result => {
        if (result.status === 'fulfilled') {
          newState.acl[result.value.key] =
            (newState.acl[result.value.key] ?? false) || result.value.allowed;
          if (result.value.error) console.error(result.value.error);
        } else {
          console.error(result.reason);
        }
      });

      setState(newState);
    };

    if (loggedInUser) {
      queryACL();
    }
  }, [aclQueries, loggedInUser, resourcesMap, suffixesMap]);

  return state.acl;
};
