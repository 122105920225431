import { createValidator } from 'revalidate';

export default function isGreaterThan(comparisonValue) {
  return createValidator(
    message => value => {
      if (value <= comparisonValue) {
        return message;
      }
    },
    field => `${field} must be greater than ${comparisonValue}`,
  );
}
