import { useLocation } from 'react-router-dom';
import { removeLastUriSegment } from '../common/uri';
import {
  createMediaApplicationSection,
  createOrUpdateMediaApplicationSection,
  deleteMediaApplicationSection,
  saveMediaApplication,
  updateMediaApplicationSection,
  updateMediaApplicationSectionSortOrders,
} from './media-actions';
import Application from '../common/application/Application';

const MediaApplicationForm = () => {
  const location = useLocation();
  return (
    <div>
      <Application
        type="media"
        backTo={removeLastUriSegment(location.pathname)}
        headerText="Application for Media Outlets"
        saveApplication={saveMediaApplication}
        createSection={createMediaApplicationSection}
        createOrUpdateSection={createOrUpdateMediaApplicationSection}
        deleteSection={deleteMediaApplicationSection}
        updateSection={updateMediaApplicationSection}
        updateSortOrders={updateMediaApplicationSectionSortOrders}
        includeCredentialsSection
        includeEmailSettingsSection
        includeCredentialModifiers
        sections={[
          {
            key: 'legal-notice',
            heading: 'Legal Notice',
            text: 'When entered, a legal notice will appear and have to be agreed on before an applicant can view the application.',
            excludeIfBlank: true,
          },
          {
            key: 'intro',
            heading: 'Application Intro',
            text: 'This intro will appear directly below the header image (if one exists) and can be as long as needed.',
          },
          {
            key: 'applicant-info',
            heading: 'Person Filling Out Application Info',
            text:
              'This section will capture the name and email of the person filling out the application. ' +
              'You can enter a description that will appear above the capture inputs below.',
            sortable: true,
          },
          {
            key: 'media-outlet-name',
            heading: 'Media Outlet Name',
            text: 'This section will allow the user to type in the name of their media outlet. You can enter a description that will appear above the text input below.',
            sortable: true,
          },
          {
            key: 'media-outlet-type',
            heading: 'Media Outlet Type',
            text: 'This section will provide a dropdown for the user to select their media outlet type. You can enter a description that will appear above the dropdown below.',
            sortable: true,
          },
          {
            key: 'media-outlet-details',
            heading: 'Media Outlet Details',
            text: 'This section will allow the user to enter the Media Outlet details. You can enter a description that will appear above the text input below.',
            sortable: true,
            optional: true,
          },
          {
            key: 'editor-info',
            heading: 'Editor or Program Director Information',
            text: 'This section will allow the user to enter the Editor or Program Director information. You can enter a description that will appear above the text input below.',
            sortable: true,
            optional: true,
          },
          {
            key: 'personnel',
            heading: 'Personnel List',
            text: 'This section will provide a form for adding any number of personnel. You can enter a description that will appear above the personnel form below.',
            sortable: true,
          },
          {
            key: 'verification',
            heading: 'Verification and Authorization',
            text: 'This section will ask the applicant to verify that the supplied information is correct and they are authorized to submit the application. You can enter a description that will appear alongside the verification.',
            sortable: true,
            optional: true,
          },
        ]}
      />
    </div>
  );
};
export default MediaApplicationForm;
