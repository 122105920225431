import departmentTypeList from '../../../lib/department-types';
import roleConstants from '../../../lib/acl/role-constants';
import resourceConstants from '../../../lib/acl/resource-constants';
import multiAllow from '../../../lib/acl/acl-util';

const eventAdminChecker =
  (roles, eventIdPath = 'id', resultPolarity = true) =>
  (err, user, resource, _action, result, next) => {
    if (err) throw err;
    const usersEventRoles = user.permissionsMap.eventMap[resource[eventIdPath]];
    if (!usersEventRoles) {
      next();
    } else {
      let hasOneOfRoles = false;
      for (let i = 0; i < usersEventRoles.length && !hasOneOfRoles; i++) {
        hasOneOfRoles = roles.indexOf(usersEventRoles[i]) !== -1;
      }

      if (hasOneOfRoles) {
        result(null, resultPolarity);
      } else {
        next();
      }
    }
  };

const eventUserAccess = acl => {
  // Event Admin can do anything in their own event
  acl.allow(
    roleConstants.EVENT_ADMIN,
    resourceConstants.EVENT,
    null,
    eventAdminChecker([roleConstants.EVENT_ADMIN]),
  );

  // Event Coordinator can do anything in their own event
  acl.allow(
    roleConstants.EVENT_COORDINATOR,
    resourceConstants.EVENT,
    null,
    eventAdminChecker([roleConstants.EVENT_COORDINATOR]),
  );

  // Except, Event Coordinator cannot do the following in their own event
  acl.deny(
    roleConstants.EVENT_COORDINATOR,
    resourceConstants.EVENT,
    [
      'edit-user',
      'abstractapprove',
      'approve-credential',
      'approve-credential-artist',
      'approve-credential-food_vendor',
      'approve-credential-media',
      'approve-credential-guestlist',
      'approve-credential-intern',
      'approve-credential-production',
      'approve-credential-sponsor',
      'approve-credential-vendor',
      'approve-intern',
      'approve-media',
      'reconcile-order',
    ],
    eventAdminChecker([roleConstants.EVENT_COORDINATOR], 'id', false),
  );

  // Any Event User can view an event they are associated with
  acl.allow(
    null,
    resourceConstants.EVENT,
    'view',
    async (err, user, resource, _action, result, next) => {
      if (err) {
        throw err;
      }

      const isStrataAdmin = user.roles.indexOf(roleConstants.STRATA_ADMIN) > -1;

      const isPromoterAdmin =
        user.roles.indexOf(roleConstants.PROMOTER_ADMIN) > -1;

      const isEventUserForThisEvent =
        user.permissionsMap.eventMap &&
        !!user.permissionsMap.eventMap[resource.id];

      if (isStrataAdmin || isPromoterAdmin || isEventUserForThisEvent) {
        result(null, true);
      } else {
        next();
      }
    },
  );

  const allowIfIsSameDeptType =
    (role, deptType) => (err, user, resource, _action, result, next) => {
      if (err) throw err;
      const usersEventRoles = user.permissionsMap.eventMap[resource.event_id];
      if (!usersEventRoles) {
        next();
      } else {
        if (usersEventRoles.indexOf(role) === -1) {
          next();
        } else {
          if (resource.type === deptType) {
            result(null, true);
          } else {
            next();
          }
        }
      }
    };

  // Event Admin can do anything for all departments in their event
  acl.allow(
    roleConstants.EVENT_ADMIN,
    resourceConstants.DEPARTMENT,
    null,
    eventAdminChecker([roleConstants.EVENT_ADMIN], 'event_id'),
  );

  // Event Coordinator can do anything for all departments in their event
  acl.allow(
    roleConstants.EVENT_COORDINATOR,
    resourceConstants.DEPARTMENT,
    null,
    eventAdminChecker([roleConstants.EVENT_COORDINATOR], 'event_id'),
  );

  // Except, Event Coordinator cannot do the following departments in their event
  acl.deny(
    roleConstants.EVENT_COORDINATOR,
    resourceConstants.DEPARTMENT,
    ['edit-user', 'view-activity-log', 'approve-credential'],
    eventAdminChecker([roleConstants.EVENT_COORDINATOR], 'event_id', false),
  );

  // Catering Admin event user can request & manage catering and view catering reports for all departments in their event
  acl.allow(
    roleConstants.CATERING_ADMIN,
    resourceConstants.DEPARTMENT,
    ['view', 'manage-catering', 'request-catering'],
    eventAdminChecker([roleConstants.CATERING_ADMIN], 'event_id'),
  );

  // Event User with APPROVE role can approve credentials for all departments in their event
  acl.allow(
    roleConstants.CREDENTIAL_APPROVE,
    resourceConstants.DEPARTMENT,
    ['approve-credential'],
    eventAdminChecker([roleConstants.CREDENTIAL_APPROVE], 'event_id'),
  );

  // Section Users / Roles
  departmentTypeList.forEach(deptType => {
    // Users with VIEW role can view all departments in their section
    acl.allow(
      `${deptType}_view`,
      resourceConstants.DEPARTMENT,
      'view',
      allowIfIsSameDeptType(`${deptType}_view`, deptType),
    );

    // Users with EDIT role can perform the following functions for all departments in their section
    acl.allow(
      `${deptType}_edit`,
      resourceConstants.DEPARTMENT,
      [
        'edit',
        'admin-view',
        'admin-edit',
        'manage-catering',
        'request-catering',
        'manage-group-creds',
        'view-reports-all',
      ],
      allowIfIsSameDeptType(`${deptType}_edit`, deptType),
    );

    // Users with CREDENTIAL role can perform the following functions for all departments in their section
    acl.allow(
      `${deptType}_credential`,
      resourceConstants.DEPARTMENT,
      ['request-credential', 'view-reports-credential'],
      allowIfIsSameDeptType(`${deptType}_credential`, deptType),
    );

    // Users with APPROVE role can perform the following functions for all departments in their section
    acl.allow(
      `${deptType}_approve`,
      resourceConstants.DEPARTMENT,
      'approve-credential',
      allowIfIsSameDeptType(`${deptType}_credential`, deptType),
    );

    // Users with CATERING role can perform the following functions for all departments in their section
    acl.allow(
      `${deptType}_catering`,
      resourceConstants.DEPARTMENT,
      ['manage-catering', 'request-catering', 'view-reports-catering'],
      allowIfIsSameDeptType(`${deptType}_catering`, deptType),
    );

    // Users with REPORT role can perform the following functions for all departments in their section
    acl.allow(
      `${deptType}_report`,
      resourceConstants.DEPARTMENT,
      'view-reports-all',
      allowIfIsSameDeptType(`${deptType}_catering`, deptType),
    );
  });

  acl.allow(
    'media_application',
    resourceConstants.EVENT,
    'manage-media-application',
    eventAdminChecker(['media_application']),
  );

  // Event Users / Roles
  const rolesActionsObject = {
    [roleConstants.CREDENTIAL_EDIT]: ['view-credential', 'edit-credential'],
    [roleConstants.CREDENTIAL_APPROVE]: [
      'abstractapprove',
      'approve-credential',
      'approve-credential-artist',
      'approve-credential-food_vendor',
      'approve-credential-guestlist',
      'approve-credential-media',
      'approve-credential-intern',
      'approve-credential-production',
      'approve-credential-sponsor',
      'approve-credential-vendor',
      'view-artist',
      'view-food_vendor',
      'view-guestlist',
      'view-production',
      'view-sponsor',
      'view-vendor',
      'view-credential-requests-summary',
    ],
    [roleConstants.ARTIST_APPROVE]: [
      'abstractapprove',
      'approve-credential-artist',
      'view-credential-requests-summary',
    ],
    [roleConstants.FOOD_VENDOR_APPROVE]: [
      'abstractapprove',
      'approve-credential-food_vendor',
      'view-credential-requests-summary',
    ],
    [roleConstants.GUESTLIST_APPROVE]: [
      'abstractapprove',
      'approve-credential-guestlist',
      'view-credential-requests-summary',
    ],
    [roleConstants.INTERN_APPROVE]: [
      'approve-intern',
      'abstractapprove',
      'approve-credential-intern',
      'view-credential-requests-summary',
    ],
    [roleConstants.MEDIA_APPROVE]: [
      'abstractapprove',
      'approve-credential-media',
      'view-credential-requests-summary',
    ],
    [roleConstants.MEDIA_APPROVE_DEPARTMENT]: ['approve-media'],
    [roleConstants.PRODUCTION_APPROVE]: [
      'abstractapprove',
      'approve-credential-production',
      'view-credential-requests-summary',
    ],
    [roleConstants.SPONSOR_APPROVE]: [
      'abstractapprove',
      'approve-credential-sponsor',
      'view-credential-requests-summary',
    ],
    [roleConstants.VENDOR_APPROVE]: [
      'abstractapprove',
      'approve-credential-vendor',
      'view-credential-requests-summary',
    ],
    [roleConstants.ARTIST_VIEW]: ['view-artist'],
    [roleConstants.FOOD_VENDOR_VIEW]: ['view-food_vendor'],
    [roleConstants.GUESTLIST_VIEW]: ['view-guestlist'],
    [roleConstants.INTERN_VIEW]: ['view-intern'],
    [roleConstants.MEDIA_VIEW]: ['view-media'],
    [roleConstants.PRODUCTION_VIEW]: ['view-production'],
    [roleConstants.SPONSOR_VIEW]: ['view-sponsor'],
    [roleConstants.VENDOR_VIEW]: ['view-vendor'],
    [roleConstants.ARTIST_EDIT]: [
      'view-admincog',
      'create-artist',
      'view-department-artist',
      'view-credential-requests-summary',
      'view-reports',
      'view-reports-admin',
      'view-reports-catering',
      'view-reports-credential',
    ],
    [roleConstants.FOOD_VENDOR_EDIT]: [
      'view-admincog',
      'create-food_vendor',
      'view-department-food_vendor',
      'view-credential-requests-summary',
      'view-reports',
      'view-reports-admin',
      'view-reports-catering',
      'view-reports-credential',
    ],
    [roleConstants.GUESTLIST_EDIT]: [
      'view-admincog',
      'create-guestlist',
      'view-department-guestlist',
      'view-credential-requests-summary',
      'view-reports',
      'view-reports-admin',
      'view-reports-catering',
      'view-reports-credential',
    ],
    [roleConstants.INTERN_EDIT]: [
      'view-admincog',
      'create-intern',
      'view-department-intern',
      'view-credential-requests-summary',
      'view-reports',
      'view-reports-admin',
      'view-reports-catering',
      'view-reports-credential',
    ],
    [roleConstants.MEDIA_EDIT]: [
      'view-admincog',
      'create-media',
      'view-department-media',
      'view-credential-requests-summary',
      'view-reports',
      'view-reports-admin',
      'view-reports-catering',
      'view-reports-credential',
    ],
    [roleConstants.PRODUCTION_EDIT]: [
      'view-admincog',
      'create-production',
      'view-department-production',
      'view-credential-requests-summary',
      'view-reports',
      'view-reports-admin',
      'view-reports-catering',
      'view-reports-credential',
    ],
    [roleConstants.SPONSOR_EDIT]: [
      'view-admincog',
      'view-department-sponsor',
      'create-sponsor',
      'view-credential-requests-summary',
      'view-reports',
      'view-reports-admin',
      'view-reports-catering',
      'view-reports-credential',
    ],
    [roleConstants.VENDOR_EDIT]: [
      'view-admincog',
      'create-vendor',
      'view-department-vendor',
      'view-credential-requests-summary',
      'view-reports',
      'view-reports-admin',
      'view-reports-catering',
      'view-reports-credential',
    ],
    [roleConstants.ARTIST_CREDENTIAL]: [
      'view-admincog',
      'view-credential-requests-summary',
      'bulkcreate-artist',
      'view-reports',
      'view-reports-credential',
    ],
    [roleConstants.FOOD_VENDOR_CREDENTIAL]: [
      'view-admincog',
      'view-credential-requests-summary',
      'bulkcreate-food_vendor',
      'view-reports',
      'view-reports-credential',
    ],
    [roleConstants.GUESTLIST_CREDENTIAL]: [
      'view-admincog',
      'view-credential-requests-summary',
      'bulkcreate-guestlist',
      'view-reports',
      'view-reports-credential',
    ],
    [roleConstants.INTERN_CREDENTIAL]: [
      'view-admincog',
      'view-credential-requests-summary',
      'bulkcreate-intern',
      'view-reports',
      'view-reports-credential',
    ],
    [roleConstants.MEDIA_CREDENTIAL]: [
      'view-admincog',
      'view-credential-requests-summary',
      'bulkcreate-media',
      'view-reports',
      'view-reports-credential',
    ],
    [roleConstants.PRODUCTION_CREDENTIAL]: [
      'view-admincog',
      'view-credential-requests-summary',
      'bulkcreate-production',
      'view-reports',
      'view-reports-credential',
    ],
    [roleConstants.SPONSOR_CREDENTIAL]: [
      'view-admincog',
      'view-credential-requests-summary',
      'bulkcreate-sponsor',
      'view-reports',
      'view-reports-credential',
    ],
    [roleConstants.VENDOR_CREDENTIAL]: [
      'view-admincog',
      'view-credential-requests-summary',
      'bulkcreate-vendor',
      'view-reports',
      'view-reports-credential',
    ],
    [roleConstants.REPORTS_VIEW]: [
      'view-admincog',
      'view-reports',
      'view-reports-all',
    ],
    [roleConstants.ARTIST_REPORT]: [
      'view-admincog',
      'view-reports',
      'view-reports-all',
    ],
    [roleConstants.FOOD_VENDOR_REPORT]: [
      'view-admincog',
      'view-reports',
      'view-reports-all',
    ],
    [roleConstants.GUESTLIST_REPORT]: [
      'view-admincog',
      'view-reports',
      'view-reports-all',
    ],
    [roleConstants.INTERN_REPORT]: [
      'view-admincog',
      'view-reports',
      'view-reports-all',
    ],
    [roleConstants.MEDIA_REPORT]: [
      'view-admincog',
      'view-reports',
      'view-reports-all',
    ],
    [roleConstants.PRODUCTION_REPORT]: [
      'view-admincog',
      'view-reports',
      'view-reports-all',
    ],
    [roleConstants.SPONSOR_REPORT]: [
      'view-admincog',
      'view-reports',
      'view-reports-all',
    ],
    [roleConstants.VENDOR_REPORT]: [
      'view-admincog',
      'view-reports',
      'view-reports-all',
    ],
    [roleConstants.ARTIST_CATERING]: [
      'view-admincog',
      'view-artist',
      'view-department-artist',
      'view-reports',
      'view-reports-catering',
    ],
    [roleConstants.FOOD_VENDOR_CATERING]: [
      'view-admincog',
      'view-food_vendor',
      'view-department-food_vendor',
      'view-reports',
      'view-reports-catering',
    ],
    [roleConstants.GUESTLIST_CATERING]: [
      'view-admincog',
      'view-guestlist',
      'view-department-guestlist',
      'view-reports',
      'view-reports-catering',
    ],
    [roleConstants.PRODUCTION_CATERING]: [
      'view-admincog',
      'view-production',
      'view-department-production',
      'view-reports',
      'view-reports-catering',
    ],
    [roleConstants.SPONSOR_CATERING]: [
      'view-admincog',
      'view-sponsor',
      'view-department-sponsor',
      'view-reports',
      'view-reports-catering',
    ],
    [roleConstants.VENDOR_CATERING]: [
      'view-admincog',
      'view-vendor',
      'view-department-vendor',
      'view-reports',
      'view-reports-catering',
    ],
    [roleConstants.CATERING_ADMIN]: [
      'manage-catering',
      'view-admincog',
      'view-artist',
      'view-food_vendor',
      'view-guestlist',
      'view-production',
      'view-sponsor',
      'view-vendor',
      'view-department-artist',
      'view-department-food_vendor',
      'view-department-guestlist',
      'view-department-production',
      'view-department-sponsor',
      'view-department-vendor',
      'view-reports',
      'view-reports-catering',
    ],
  };
  multiAllow(
    rolesActionsObject,
    resourceConstants.EVENT,
    eventAdminChecker,
    acl,
  );
};

export default eventUserAccess;
