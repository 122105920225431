import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { renderErrorMessage } from './onsite-utils';
import {
  getCurrentPromoter,
  getPromoterList,
  getPromoterListLoaded,
} from '../promoter/promoter-selectors';
import InputScaffold from '../common/forms/InputScaffold';
import ReduxReactSelectField from '../common/forms/ReduxReactSelectField';

const SelectPromoter = () => {
  const navigate = useNavigate();
  const params = useParams();

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const promoterList = useSelector(getPromoterList);
  const promoterListLoaded = useSelector(getPromoterListLoaded);

  const promoterOptions = useMemo(
    () =>
      promoterList
        ?.map(promoter => ({
          label: promoter.get('name'),
          value: promoter.get('id'),
        }))
        .toArray(),
    [promoterList],
  );

  const getPromoterUrl = promoter =>
    `/onsite/promoters/${promoter.get('slug')}/festivals`;

  const selectPromoter = promoterId => {
    navigate(
      getPromoterUrl(
        promoterList.find(
          promoter => promoter.get('id') === parseInt(promoterId, 10),
        ),
      ),
    );
  };

  useEffect(() => {
    if (promoterListLoaded && promoterList.size === 1)
      navigate(getPromoterUrl(promoterList.first()));
  }, [navigate, promoterList, promoterListLoaded]);

  if (promoter || !promoterListLoaded || promoterList.size === 1) {
    return null;
  }

  if (promoterList.size === 0) {
    return renderErrorMessage('You are not associated to any promoters.');
  }

  return (
    <InputScaffold label="Promoter" required>
      <ReduxReactSelectField
        autoFocus={true}
        className="onsite-select"
        placeholder="Select Promoter..."
        options={promoterOptions}
        onChange={selectedPromoter => selectPromoter(selectedPromoter.value)}
      />
    </InputScaffold>
  );
};

export default SelectPromoter;
