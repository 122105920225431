import {
  DISMISS_NOTIFICATION,
  SHOW_NOTIFICATION,
} from './notification-actions';

const defaultNotificationState = {
  message: '',
  status: '',
};

const notificationReducers = (state = defaultNotificationState, action) => {
  if (action.type === SHOW_NOTIFICATION) {
    return action.notification;
  } else if (action.type === DISMISS_NOTIFICATION) {
    return defaultNotificationState;
  }
  return state;
};

export default notificationReducers;
