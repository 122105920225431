import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { resource, useACL } from '../common/ACL';
import {
  getCurrentEventSectionApplications,
  getCurrentEventSettings,
} from './event-selectors';
import styled from 'styled-components';
import departmentIconMap from '../department/department-icon-map';
import departmentTypeList from '../lib/department-types';
import departmentTypeMap from '../lib/department-type-map';
import PropTypes from 'prop-types';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import RequestStatusIndicator from '../contact/RequestStatusIndicator';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled.div`
  margin-bottom: 20px;
  flex-direction: row;
`;

const ApplicationContainer = styled.div`
  display: flex;
  width: 100%;
  height: 54px;
  border: 1px solid #ccc;
  margin-bottom: 12px;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.div`
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
  margin-left: 20px;
`;

const Description = styled.div`
  display: flex;
  opacity: 0.5;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 16px;
`;

const Status = styled.div`
  padding-right: 20px;
`;

const Button = styled.div`
  cursor: pointer;
`;

const aclRules = {};

departmentTypeList.forEach(section => {
  if (['intern', 'media'].includes(section))
    aclRules[`canManage-${section}`] = [
      resource.EVENT,
      `manage-${section}-application`,
    ];
  else aclRules[`canManage-${section}`] = [resource.EVENT, 'edit'];
});

const Application = ({ section }) => {
  const acl = useACL(aclRules);
  const navigate = useNavigate();
  const params = useParams();

  const applications = useSelector(state =>
    getCurrentEventSectionApplications(state, { params, section }),
  );

  const eventSettings = useSelector(state =>
    getCurrentEventSettings(state, { params }),
  );

  const sectionEnabled = eventSettings?.getIn([
    section,
    'settings',
    'section_enabled',
  ]);

  if (!sectionEnabled || !acl[`canManage-${section}`]) return null;

  return (
    <Button
      onClick={() => {
        navigate(`../${section}/applications`);
      }}
    >
      <ApplicationContainer>
        <Row>
          <Icon icon={departmentIconMap[section]} />
          <Title>{departmentTypeMap[section].label.plural}</Title>
          <Description>{`Manage ${departmentTypeMap[section].label.singular} Applications`}</Description>
        </Row>
        {applications?.size > 0 && (
          <Status>
            <RequestStatusIndicator
              status="approved"
              leftContent={applications.size}
            />
          </Status>
        )}
      </ApplicationContainer>
    </Button>
  );
};

Application.propTypes = {
  section: PropTypes.string.isRequired,
};

const EventApplicationsContainer = () => {
  const applications = [];

  departmentTypeList.forEach(section => {
    const application = <Application key={section} section={section} />;
    if (application) applications.push(application);
  });

  return (
    <Container>
      <Header>
        <Paper>
          <PaperHeader title="Event Applications" />
          {applications.length === 0 ? (
            <div className="generic-list--empty">
              <Icon icon="Sad" />
              <p>No Event Applications are available</p>
            </div>
          ) : null}
        </Paper>
      </Header>
      {applications}
    </Container>
  );
};

export default EventApplicationsContainer;
