import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { clearPromoterAdminList } from '../promoter/promoter-actions';
import { fetchFestivalList } from './festival-actions';
import { getCurrentPromoter } from '../promoter/promoter-selectors';

const FestivalLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const promoter = useSelector(state => getCurrentPromoter(state, { params }));

  useEffect(() => {
    if (promoter) dispatch(fetchFestivalList(promoter.get('id')));
    return () => {
      clearPromoterAdminList();
    };
  }, [dispatch, promoter]);

  return <Outlet />;
};

export default FestivalLoader;
