import { List } from 'immutable';
import client from '../client';

export const FETCH_CREDENTIAL_REQUEST_LIST = 'FETCH_CREDENTIAL_REQUEST_LIST';
export function fetchUnassignedCredentialList(department) {
  return {
    type: FETCH_CREDENTIAL_REQUEST_LIST,
    promise: client.get(`departments/${department}/credentials`),
  };
}

export const SUBMIT_CREDENTIAL_REQUEST = 'SUBMIT_CREDENTIAL_REQUEST';
export function submitCredentialRequest(
  departmentId,
  credentialTypeId,
  payload,
) {
  return {
    credentialTypeId,
    type: SUBMIT_CREDENTIAL_REQUEST,
    promise: client.put(
      `departments/${departmentId}/credentials/${credentialTypeId}`,
      payload,
    ),
  };
}

export const CLEAR_CREDENTIAL_REQUESTS_BY_EVENT =
  'CLEAR_CREDENTIAL_REQUESTS_BY_EVENT';
export function clearCredentialRequestsByEvent() {
  return {
    type: CLEAR_CREDENTIAL_REQUESTS_BY_EVENT,
  };
}

export const FETCH_CREDENTIAL_REQUESTS_BY_EVENT =
  'FETCH_CREDENTIAL_REQUESTS_BY_EVENT';
export function fetchCredentialRequestsByEvent(eventId, section) {
  return {
    type: FETCH_CREDENTIAL_REQUESTS_BY_EVENT,
    promise: client.get(`events/${eventId}/${section}/credential-requests`),
  };
}

export const UPDATE_CREDENTIAL_QUANTITIES = 'UPDATE_CREDENTIAL_QUANTITIES';
export function updateCredentialQuantities(requestId, payload) {
  return {
    type: UPDATE_CREDENTIAL_QUANTITIES,
    promise: client.patch(`credential-requests/${requestId}`, payload),
  };
}

export const APPROVE_CREDENTIALS = 'APPROVE_CREDENTIALS';
export function approveCredentials(eventId, section, credentialRequests) {
  let payload = {
    credentialRequests: List.isList(credentialRequests)
      ? credentialRequests.map(request => request.get('id')).toArray()
      : [credentialRequests.get('id')],
  };

  return {
    type: APPROVE_CREDENTIALS,
    promise: client.post(
      `events/${eventId}/${section}/credential-requests/approve`,
      payload,
    ),
  };
}

export const REJECT_CREDENTIALS = 'REJECT_CREDENTIALS';

export function rejectCredentials(eventId, section, credentialRequests) {
  let payload = {
    credentialRequests: List.isList(credentialRequests)
      ? credentialRequests.map(request => request.get('id')).toArray()
      : [credentialRequests.get('id')],
  };

  return {
    type: REJECT_CREDENTIALS,
    promise: client.post(
      `events/${eventId}/${section}/credential-requests/reject`,
      payload,
    ),
  };
}

export const REMOVE_CREDENTIAL_QUANTITIES = 'REMOVE_CREDENTIAL_QUANTITIES';
export function removeCredentialQuantities(requestId, type) {
  return {
    credentialRequestId: requestId,
    type: REMOVE_CREDENTIAL_QUANTITIES,
    promise: client.delete(`credential-requests/${requestId}/${type}`),
  };
}

export const CHANGE_CREDENTIAL = 'CHANGE_CREDENTIAL';
export function changeCredential(requestId, credentialId) {
  return {
    requestId,
    type: CHANGE_CREDENTIAL,
    promise: client.patch(`credential-requests/${requestId}`, {
      credential_id: credentialId,
    }),
  };
}
