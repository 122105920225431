import styled from 'styled-components';

export const CategoryHeader = styled.div`
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
`;

export const CredentialRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  min-height: 50px;
  max-width: 60%;
`;

export const CredentialLabel = styled.div`
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 50%;
  margin-left: 10px;
  margin-right: 30px;
  padding-bottom: 30px;
`;

export const CredentialQuantity = styled.div`
  flex-grow: 1;
  flex-basis: intial;
  display: flex;
  flex-direction: vertical;
  justify-content: center;
  margin-right: 10px;
  white-space: nowrap;
`;
