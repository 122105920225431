import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout } from '../auth/auth-redirects';
import PropTypes from 'prop-types';
import Logo from '../common/Logo';
import Icon from '../common/icons/Icon';

const OnsiteTopbar = ({ isAccountNavOpen, toggleAccountNav }) => {
  const loggedInUser = useSelector(state =>
    state.auth.loggedInUser?.get('data'),
  );

  const renderAccountProfile = () => {
    const firstName = loggedInUser?.get('first_name');
    const lastName = loggedInUser?.get('last_name');
    const email = loggedInUser?.get('email');
    return (
      <div className="topbar__account">
        <button
          className="topbar__account--image"
          style={{ height: '30px', width: '30px' }}
          onClick={toggleAccountNav}
        >
          <div>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</div>
        </button>
        {isAccountNavOpen ? (
          <div className="topbar__account--dropdown">
            <div className="topbar__account--account">
              <div className="topbar__account--welcome">Logged in as</div>
              <div className="topbar__account--name">
                {`${firstName} ${lastName}`}
              </div>
              <div className="topbar__account--email">{email}</div>
            </div>
            <nav className="topbar__account--nav">
              <button onClick={logout}>
                <Icon icon="Logout" />
                Logout
              </button>
            </nav>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="topbar">
      <div className="topbar__breadcrumbs">
        <Link to="/onsite" className="onsite__logo">
          <Logo />
        </Link>
      </div>
      <div className="topbar__divider">{renderAccountProfile()}</div>
    </div>
  );
};

OnsiteTopbar.propTypes = {
  isAccountNavOpen: PropTypes.bool.isRequired,
  toggleAccountNav: PropTypes.func.isRequired,
};

export default OnsiteTopbar;
