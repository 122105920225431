import roleConstants from './role-constants';
import roleParents from './role-parents';
import resourceConstants from './resource-constants';

const combine = (prefixes, suffixes) =>
  prefixes.reduce((arr, prefix) => {
    suffixes.forEach(suffix => {
      arr.push(`${prefix}_${suffix}`);
    });
    return arr;
  }, []);

const RoleResourceInit = acl => {
  acl.addRole(roleConstants.STRATA_ADMIN);
  acl.addRole(roleConstants.PROMOTER_ADMIN);
  acl.addRole(roleConstants.EVENT_ADMIN);
  acl.addRole(roleConstants.EVENT_COORDINATOR);

  Object.keys(roleParents).forEach(childRole => {
    const parentRole = roleParents[childRole];
    acl.addRole(childRole, parentRole);
  });

  combine(['credential'], ['edit', 'approve']).forEach(role =>
    acl.addRole(role),
  );

  Object.keys(resourceConstants).forEach(key =>
    acl.addResource(resourceConstants[key]),
  );
  return acl;
};

export default RoleResourceInit;
