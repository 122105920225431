import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from '../Tag';

const InputScaffoldNoLabel = ({
  children,
  className,
  disabled = false,
  label,
  required,
  validation,
}) => {
  const scaffoldClasses = {
    'input-scaffold': true,
    [`${className}`]: className,
  };

  const labelClasses = {
    input: true,
    'input--disabled': disabled,
  };

  return (
    <div className={classNames(scaffoldClasses)}>
      <div className={classNames(labelClasses)}>
        {label ? (
          <span className="input-label">
            <span className="input-label--label">{label}</span>
            {!required ? <Tag colorTheme="optional">optional</Tag> : null}
          </span>
        ) : null}
        {children}
      </div>
      {validation ? <div className="input-validation">{validation}</div> : null}
    </div>
  );
};

InputScaffoldNoLabel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  validation: PropTypes.any,
};

export default InputScaffoldNoLabel;
