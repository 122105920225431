import { combineReducers } from 'redux';
import Immutable from 'immutable';
import { SET_TOPBAR_TITLE, SET_PAGE_SETTINGS } from './nav-actions';

const title = (state = null, action) => {
  if (action.type === SET_TOPBAR_TITLE) {
    return action.title;
  }
  return state;
};

const pageSettings = (state = Immutable.Map(), action) => {
  switch (action.type) {
    case SET_PAGE_SETTINGS:
      if (action.payload.url) {
        return state.setIn([action.payload.url], action.payload.values);
      }
      return state;
    default:
      return state;
  }
};

const reducers = combineReducers({
  title,
  pageSettings,
});

export default reducers;
