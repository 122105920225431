import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeCredentialQuantities } from '../credential-request/credential-request-actions';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tag from '../common/Tag';

const CredentialTag = ({
  credential: { requestId, name, color, period, quantity },
  type,
  canDelete = false,
}) => {
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const onDelete = () => {
    setDeleting(true);
    dispatch(removeCredentialQuantities(requestId, type)).then(() => {
      setDeleting(false);
    });
  };

  const renderDeleteButton = () =>
    showDelete && canDelete ? (
      <button
        className="credential-tag-delete-button"
        disabled={deleting}
        onClick={onDelete}
      >
        {deleting && (
          <div className="button__children">
            <div className="spinner">
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </div>
          </div>
        )}
        {!deleting && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
          </svg>
        )}
      </button>
    ) : null;

  const classes = classNames({
    'credential-tag': true,
    'credential-tag--deleting': deleting,
  });

  const columnLayout = {
    display: 'flex',
  };

  return (
    <span
      className={classes}
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      <Tag backgroundColor={color}>
        <span style={columnLayout}>
          <span className="credential-count">{quantity}</span>
          <span>
            {' '}
            {name} {period && ' / ' + period}
          </span>
          {renderDeleteButton()}
        </span>
      </Tag>
    </span>
  );
};

CredentialTag.propTypes = {
  credential: PropTypes.shape({
    requestId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    quantity: PropTypes.number,
  }).isRequired,
  type: PropTypes.oneOf(['pending', 'approved']).isRequired,
  canDelete: PropTypes.bool,
};

export default CredentialTag;
