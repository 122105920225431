import classNames from 'classnames';
import PaperBody from './PaperBody';
import PaperHeader from './PaperHeader';

const Paper = ({ children, className }) => (
  <div className={classNames('paper', className)}>{children}</div>
);

export { PaperBody, PaperHeader };
export default Paper;
