import { createSelector } from 'reselect';

export const parsePayload = createSelector(
  data => {
    return {
      backgroundInfo: {
        location: data.getIn(['backgroundImage', 'location']),
        tiled: Boolean(data.get('background-image-tiled')),
        banner: data.getIn(['headerImage', 'location']),
      },
    };
  },
  backgroundImage => backgroundImage,
);

export const getEmailsByRecipient = createSelector(
  emails => emails,
  emails => emails.groupBy(email => email.get('recipients')),
);
