import { useParams } from 'react-router-dom';
import { Field } from 'react-final-form';
import { Map } from 'immutable';
import { composeValidators, isRequired } from 'revalidate';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import PropTypes from 'prop-types';
import ReduxFormsField from '../../forms/ReduxFormsField';
import isValidEmail from '../../validate/is-valid-email';

const draftRenderer = new Renderer(defaultBlocks);

const DepartmentInfoSection = ({ className, section }) => {
  const params = useParams();

  return (
    <section className={className}>
      <header>
        <div className={`${className}--title`}>Department Info</div>
      </header>

      <article>
        <div className={`${className}--desc`}>
          <div
            className="draftjs-output"
            dangerouslySetInnerHTML={{
              __html: draftRenderer.convertRawToHTML(
                section.get('content').toJS(),
              ),
            }}
          />
        </div>

        <Field
          name="department_info_contact_name"
          component={ReduxFormsField}
          label="Your Name"
          placeholder="Contact Name"
          required
          validate={isRequired('Your Name')}
        >
          <input type="text" />
        </Field>

        <Field
          name="department_info_name"
          component={ReduxFormsField}
          label="Department Name"
          placeholder="Enter Department Name"
          required
          disabled={!!params.departmentAdvanceLinkToken}
          validate={isRequired('Department Name')}
        >
          <input type="text" />
        </Field>

        <Field
          name="department_info_contact_email"
          component={ReduxFormsField}
          label="Your Email"
          placeholder="Enter Email"
          required
          validate={composeValidators(isRequired, isValidEmail)('Your Email')}
        >
          <input type="text" />
        </Field>
      </article>
    </section>
  );
};

DepartmentInfoSection.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
};

export default DepartmentInfoSection;
