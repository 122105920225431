import React from 'react';
import styled from 'styled-components';
import Logo from '../../common/Logo';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 2% 5% 2% 5%;
  & .ReactTable .select-wrap:after {
    border-style: none;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  .ReactTable .rt-thead .rt-resizable-header {
    overflow: hidden;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const LogoContainer = props => (
  <div {...props}>
    <Logo />
  </div>
);

export const StrataLogo = styled(LogoContainer)`
  display: flex;
  margin-right: auto;
  > svg {
    width: 162px;
    height: 50px;
    fill: #446cb3;
  }
`;
