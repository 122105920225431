import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Grid, List, ListItem, Table } from 'semantic-ui-react';
import {
  combineValidators,
  composeValidators,
  isNumeric,
  isRequired,
} from 'revalidate';
import { Map } from 'immutable';
import { showNotification } from '../notification/notification-actions';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { getStatusForButton } from '../common/forms/FormHelper';
import { submitPlaceholderContacts } from './contact-actions';
import { getContactPlaceholderUploads } from './contact-selectors';
import { useDepartmentUnsavedCredentialsDispatch } from './DepartmentUnsavedCredentials';
import {
  UnsavedCredentialsProvider,
  selectUnsavedCredentialsWithQuantity,
  useUnsavedCredentials,
  useUnsavedCredentialsDispatch,
} from './UnsavedCredentials';
import moment from 'moment';
import isGreaterThan from '../common/validate/is-greater-than';
import isLessThan from '../common/validate/is-less-than';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import QuantityField from '../common/forms/QuantityField';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import StatusButton from '../common/StatusButton';
import ContactCredentials, { PLACEHOLDER_STAFF } from './ContactCredentials';

const validate = combineValidators({
  description: isRequired('Role or Description'),
  quantity: composeValidators(
    isRequired('Number of Placeholders'),
    isNumeric({ message: 'placeholder quantity must be a number' }),
    isGreaterThan(0)({ message: 'min number of placeholders is 1' }),
    isLessThan(101)({ message: 'max number of placeholders is 100' }),
  )(),
});

const ContactCreatePlaceholders = ({ department, onCancel }) => {
  const dispatch = useDispatch();

  const departmentUnsavedCredentialsDispatch =
    useDepartmentUnsavedCredentialsDispatch();

  const unsavedCredentialsDispatch = useUnsavedCredentialsDispatch();
  const unsavedCredentials = selectUnsavedCredentialsWithQuantity(
    useUnsavedCredentials(),
  );

  const uploads = useSelector(state =>
    getContactPlaceholderUploads(state, department.get('id')),
  );

  const [toggles, setToggles] = useState({});

  const isProcessing =
    uploads.length > 0
      ? uploads.some(upload => upload.status === 'Processing')
      : false;

  const initialValues = {
    description: '',
    quantity: 1,
  };

  const toggle = upload => {
    setToggles(oldToggles => {
      const newToggles = { ...oldToggles };
      newToggles[upload.id ?? upload] = !newToggles[upload.id ?? upload];
      return newToggles;
    });
  };

  const isToggled = upload => toggles[upload.id ?? upload];

  const getMessage = ({ status, contacts }) => {
    if (!status || !contacts) return null;

    let erroredContacts = contacts?.reduce(
      (count, contact) => (!contact.success ? count + 1 : count),
      0,
    );

    let message =
      contacts.length === 1
        ? `Added 1 Placeholder.`
        : `Added ${contacts.length} Placeholders.`;

    if (status === 'Error')
      message +=
        erroredContacts === 1
          ? ` 1 Placeholder had errors.`
          : ` ${erroredContacts} Placeholder had errors.`;

    return message;
  };

  const clearDepartmentUnsavedCredentials = () => {
    departmentUnsavedCredentialsDispatch({
      type: 'subtract',
      changedQuantities: unsavedCredentials.map(unsavedCredential => ({
        credentialId: unsavedCredential.id,
        quantity: unsavedCredential.quantity,
      })),
    });
  };

  const onClose = () => {
    onCancel();
    clearDepartmentUnsavedCredentials();
  };

  const isSubmitDisabled = (pristine, invalid, submitting, submitFailed) =>
    pristine ||
    invalid ||
    submitting ||
    submitFailed ||
    isProcessing ||
    unsavedCredentials.length === 0;

  const handleSubmit = values => {
    let dto = { ...values };
    dto.credentials = unsavedCredentials;

    if (dto.credentials.length === 0) {
      const errorMessage = 'One credential must be requested';
      dispatch(showNotification({ message: errorMessage, status: 'error' }));
      return { [FORM_ERROR]: errorMessage };
    }

    return dispatch(submitPlaceholderContacts(department.get('id'), dto)).then(
      action => {
        if (action.response.ok) {
          clearDepartmentUnsavedCredentials();
          unsavedCredentialsDispatch({ type: 'clear' });
        } else {
          return formatValidationErrors(action.json).toJS();
        }
      },
    );
  };

  const renderContacts = (contacts, index) => {
    if (!contacts) return null;

    return contacts.map(({ contact, success, errors }, idx) => (
      <Table.Row key={`${index}-uploaded-contact-${idx}`} negative={!success}>
        <Table.Cell singleLine />
        <Table.Cell singleLine />
        <Table.Cell singleLine />
        <Table.Cell singleLine>{success ? 'Uploaded' : 'Error'}</Table.Cell>
        <Table.Cell singleLine>
          {`${contact?.first_name} ${contact?.last_name}`}
        </Table.Cell>
        <Table.Cell>
          {success ? (
            ''
          ) : (
            <List>
              {errors?.map(({ error }, index) => (
                <ListItem key={index}>{error}</ListItem>
              ))}
            </List>
          )}
        </Table.Cell>
        <Table.Cell />
      </Table.Row>
    ));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({
        handleSubmit,
        invalid,
        pristine,
        submitting,
        submitFailed,
      }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <div className="add-new-contact">
              <div className="add-new-contact__inputs">
                <span className="add-new-contact__name">
                  <Field
                    name="description"
                    placeholder="Enter Role or Description"
                    required
                    component={ReduxFormsFieldNoLabel}
                  >
                    <input type="text" autoFocus />
                  </Field>
                </span>
                <span className="add-new-contact__name">
                  <Field
                    name="quantity"
                    required
                    component={ReduxFormsFieldNoLabel}
                  >
                    <QuantityField min={1} max={100} maxHidden />
                  </Field>
                </span>
              </div>
              <div className="add-new-contact__cancel">
                <button type="button" onClick={onClose}>
                  <Icon icon="Close" />
                </button>
              </div>
            </div>
            <div className="expandable-row__expanded-content">
              <ContactCredentials
                type={PLACEHOLDER_STAFF}
                department={department}
                style={{ backgroundColor: 'white' }}
                headerStyle={{ borderLeft: 'none' }}
              />
              {uploads.length > 0 && (
                <div
                  className="contact-details-section"
                  style={{ backgroundColor: 'white' }}
                >
                  <div
                    className="contact-details-section__header"
                    style={{ borderLeft: 'none' }}
                  >
                    <Icon icon="AddCircle" />
                    Status
                  </div>
                  <div className="contact-details-section__content">
                    <div style={{ padding: '10px' }}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Table collapsing padded selectable>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    Requested By
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Start Time
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>End Time</Table.HeaderCell>
                                  <Table.HeaderCell>Status</Table.HeaderCell>
                                  <Table.HeaderCell>Contact</Table.HeaderCell>
                                  <Table.HeaderCell>Message</Table.HeaderCell>
                                  <Table.HeaderCell />
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {uploads.map((upload, index) => (
                                  <Fragment key={index}>
                                    <Table.Row
                                      negative={upload.status === 'Error'}
                                    >
                                      <Table.Cell singleLine>
                                        {upload.uploadedBy.first_name}{' '}
                                        {upload.uploadedBy.last_name}
                                      </Table.Cell>
                                      <Table.Cell singleLine>
                                        {moment(upload.start_time).format(
                                          'MMM DD h:mm:ss a',
                                        )}
                                      </Table.Cell>
                                      <Table.Cell singleLine>
                                        {upload.end_time
                                          ? moment(upload.end_time).format(
                                              'MMM DD h:mm:ss a',
                                            )
                                          : ''}
                                      </Table.Cell>
                                      <Table.Cell singleLine>
                                        {upload.status}
                                      </Table.Cell>
                                      <Table.Cell />
                                      <Table.Cell>
                                        {getMessage(upload)}
                                      </Table.Cell>
                                      <Table.Cell>
                                        <div onClick={() => toggle(upload)}>
                                          {isToggled(upload) && (
                                            <Icon icon="Caret" />
                                          )}
                                          {!isToggled(upload) && (
                                            <Icon
                                              icon="Caret"
                                              style={{
                                                top: '5px',
                                                transform: 'rotate(180deg)',
                                              }}
                                            />
                                          )}
                                        </div>
                                      </Table.Cell>
                                    </Table.Row>
                                    {isToggled(upload) &&
                                      renderContacts(upload.contacts, index)}
                                  </Fragment>
                                ))}
                              </Table.Body>
                            </Table>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="contact-details-section__footer"
                style={{ backgroundColor: 'white' }}
              >
                <StatusButton
                  type="submit"
                  buttonText="Add"
                  completeText="Added"
                  disabled={isSubmitDisabled(
                    pristine,
                    invalid,
                    submitting,
                    submitFailed,
                  )}
                  status={getStatusForButton({
                    pristine,
                    submitting: submitting || isProcessing,
                    submitFailed,
                  })}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
};

ContactCreatePlaceholders.propTypes = {
  department: PropTypes.instanceOf(Map).isRequired,
  onCancel: PropTypes.func.isRequired,
};

const ContactCreatePlaceholdersWrapper = props => (
  <UnsavedCredentialsProvider>
    <ContactCreatePlaceholders {...props} />
  </UnsavedCredentialsProvider>
);

export default ContactCreatePlaceholdersWrapper;
