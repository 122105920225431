import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 60px;
  flex-direction: column;
  margin: 20px 0 0 0;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: 20px 0 0 0;
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.5;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: start;
`;

export const EndColumn = styled.div`
  flex: 0.5;
`;

export const PeriodHeader = styled(Column)`
  justify-content: flex-start;
  align-items: start;
  flex: 9;
  color: ${props => props.theme.darkBlue};
  font-size: 14px;
  padding: 2px 6px;
  white-space: nowrap;
  p:first-child {
    font-weight: 600;
    font-family: 'Roboto Condensed';
    font-size: 16px;
  }
  p:nth-child(2) {
    font-weight: 600;
    font-family: 'Roboto Condensed';
    font-size: 14px;
  }
`;

export const CategoryHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
`;

export const Tag = styled.div`
  display: flex;
  background: #f2f2f2;
  min-height: 50px;
  border-radius: 5px;
  border-bottom: 2px solid #dcdcdc;
  margin: 0 6px 6px 0;
`;

export const Description = styled.div`
  display: grid;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.darkBlue};
  font-size: 14px;
  padding: 2px 6px;
  white-space: nowrap;
  p:first-child {
    font-weight: 600;
  }
  p:nth-child(n + 1) {
    font-family: 'Roboto Condensed';
  }
`;

export const Quantity = styled.div`
  display: grid;
  border-left: 1px solid #e4e4e4;
  align-items: center;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.darkBlue};
  padding: 0 6px;
  white-space: nowrap;
`;

export const Italic = styled.span`
  font-style: italic;
`;

export const StyledLock = styled.span`
  display: inline-block;
  width: 24px;
  transform: translateY(4px);
  svg {
    fill: ${({ theme }) => theme.grayDarkest};
  }
`;
