import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  getCredentialTypes,
  getCredentials,
} from '../credential/type/credential-type-selectors';
import { fetchCredentialRequestsByEvent } from '../credential-request/credential-request-actions';
import { getPeriodList } from '../credential/period/period-selectors';
import { getCurrentEvent } from '../event/event-selectors';
import PropTypes from 'prop-types';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ApprovalFilters from './ApprovalFilters';
import DepartmentApprovals from './DepartmentApprovals';

const isEmpty = value => (value ?? '') === '';

const CredentialRequestApprovalsPage = ({ departmentType }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const isLoading = useSelector(state => {
    const categoryList = state.credentialType.categoryList;
    const requestsForEvent = state.credentialRequest.requestsForEvent;
    return (
      categoryList.get('loading') ||
      !categoryList.get('loaded') ||
      requestsForEvent.get('loading') ||
      !requestsForEvent.get('loaded')
    );
  });

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const eventPeriods = useSelector(state => getPeriodList(state, { params }));

  const requestsForEvent = useSelector(state =>
    state.credentialRequest.requestsForEvent.get('data'),
  );

  const credentialTypes = useSelector(getCredentialTypes);
  const credentials = useSelector(getCredentials);

  const [filtering, setFiltering] = useState(false);
  const [filters, setFilters] = useState({
    category: '',
    credentialType: '',
    department: '',
    status: 'pending',
    period: '',
  });

  const hasQuantityFilter = request =>
    request.get('quantity_approved') +
      request.get('quantity_pending') +
      request.get('quantity_rejected') >
    0;

  const filteredRequestsForEvent = useMemo(() => {
    let reqsByDept = requestsForEvent;

    // Filter by department
    if (!isEmpty(filters.department)) {
      reqsByDept = reqsByDept.filter(
        dept => parseInt(filters.department, 10) === dept.get('id'),
      );
    }

    // Filter by category/type
    const filterByCredentialType = !isEmpty(filters.credentialType);
    const filterByCategory =
      !filterByCredentialType && !isEmpty(filters.category);

    reqsByDept = reqsByDept.map(dept =>
      dept.update('credentialRequests', requests =>
        requests.filter(request => {
          const credential = credentials[request.get('credential_id')];
          const credentialType =
            credentialTypes[credential?.get('credential_type_id')];

          // Check if request has quantity
          let includeRequest = hasQuantityFilter(request);

          // Filter by Credential Type
          if (includeRequest && filterByCredentialType)
            includeRequest =
              String(credentialType.get('id')) ===
              String(filters.credentialType);

          // Filter by Category
          if (includeRequest && filterByCategory)
            includeRequest =
              String(credentialType.get('category_id')) ===
              String(filters.category);

          // Filter by Status
          if (includeRequest && !isEmpty(filters.status)) {
            includeRequest =
              filters.status === 'pending'
                ? request.get('quantity_pending') > 0
                : filters.status === 'approved'
                ? request.get('quantity_approved') > 0
                : filters.status === 'rejected'
                ? request.get('quantity_rejected') > 0
                : false;
          }

          // Filter by Period
          if (includeRequest && !isEmpty(filters.period)) {
            const selectedPeriodId = parseInt(filters.period, 10);
            const issueFrequency = credentialType.get('issue_frequency');

            if (issueFrequency === 'ONE_TIME') {
              includeRequest = credential
                .get('oneTimePeriods')
                .some(period => period.get('id') === selectedPeriodId);
            } else {
              includeRequest = credential.get('period_id') === selectedPeriodId;
            }
          }

          return includeRequest;
        }),
      ),
    );

    return reqsByDept.filter(dept => !dept.get('credentialRequests').isEmpty());
  }, [credentialTypes, credentials, filters, requestsForEvent]);

  const filteredPeriods = useMemo(() => {
    const periods = new Set();

    filteredRequestsForEvent?.forEach(dept =>
      dept.get('credentialRequests').forEach(request => {
        const credential = credentials[request.get('credential_id')];
        const credentialType =
          credentialTypes[credential?.get('credential_type_id')];
        const issueFrequency = credentialType?.get('issue_frequency');

        if (issueFrequency === 'ONE_TIME') {
          credential
            ?.get('oneTimePeriods')
            .forEach(period => periods.add(period.get('id')));
        } else {
          periods.add(credential?.get('period_id'));
        }
      }),
    );

    return eventPeriods?.filter(
      period =>
        periods.has(period.get('id')) &&
        (isEmpty(filters.period) ||
          period.get('id') === parseInt(filters.period, 10)),
    );
  }, [
    credentialTypes,
    credentials,
    eventPeriods,
    filteredRequestsForEvent,
    filters.period,
  ]);

  useEffect(() => {
    const section = departmentType.singular;
    if (event && section) {
      dispatch(fetchCredentialRequestsByEvent(event.get('id'), section));
      return () => {
        setFilters(prevFilters => {
          const newFilters = { ...prevFilters };
          newFilters.department = '';
          return newFilters;
        });
      };
    }
  }, [departmentType.singular, dispatch, event]);

  useEffect(() => {
    if (searchParams.has('departmentId')) {
      setFilters(prevFilters => {
        const newFilters = { ...prevFilters };
        newFilters.department = searchParams.get('departmentId');
        return newFilters;
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (filtering) setFiltering(false);
  }, [filtering]);

  const onFiltersApplied = values => {
    setFiltering(true);
    setFilters(values);
  };

  const renderDepartmentsApprovals = () => {
    if (filteredRequestsForEvent?.isEmpty()) {
      return (
        <div className="approvals__section--zerocontent">
          There are no credential requests for this section
        </div>
      );
    }

    return (
      <div className="approvals__section--content">
        {filteredRequestsForEvent.map(dept => (
          <DepartmentApprovals
            key={dept.get('id')}
            event={event}
            departmentData={dept}
            periods={filteredPeriods}
            pendingOnly={filters.status === 'pending'}
          />
        ))}
      </div>
    );
  };

  return (
    <div>
      <Paper>
        <PaperHeader
          title={`Credential Approvals - ${departmentType.label.plural}`}
        />
      </Paper>
      {isLoading && <LoadingIndicator style={{ height: '100px' }} />}
      {!isLoading && (
        <>
          <ApprovalFilters
            periods={event.get('periods')}
            filters={filters}
            filtering={filtering}
            onSubmitFilter={onFiltersApplied}
          />
          {filtering && <LoadingIndicator style={{ height: '100px' }} />}
          {!filtering && (
            <section className="approvals">
              {renderDepartmentsApprovals()}
            </section>
          )}
        </>
      )}
    </div>
  );
};

CredentialRequestApprovalsPage.propTypes = {
  departmentType: PropTypes.object.isRequired,
};

export default CredentialRequestApprovalsPage;
