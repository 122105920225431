import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Map as ImmutableMap } from 'immutable';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import LoadingIndicator from '../common/LoadingIndicator';
import FullPageMessage from '../common/FullPageMessage';
import CateringPeriodRequest from './CateringPeriodRequest';

const CateringRequest = ({ event }) => {
  const catering = useSelector(state =>
    state.advance.catering.get('loaded')
      ? state.advance.catering.get('data')
      : null,
  );

  const [values, setValues] = useState(null);

  useEffect(() => {
    if (!catering) return;

    const today = moment.utc();
    const values = new Map();

    catering.get('periods').forEach(period => {
      const periodDates = period.get('dates');

      period.get('mealTypes').forEach(mealType => {
        mealType.get('dates').forEach(mealTypeDate => {
          const mealTypeDateId = mealTypeDate.get('mealTypeDateId');

          const periodDate = periodDates
            .find(
              entry =>
                entry.get('periodDateId') === mealTypeDate.get('periodDateId'),
            )
            .get('periodDate');

          const disabled = today.isAfter(
            moment.tz(
              periodDate.substring(0, 10) + ' 23:59',
              event.get('time_zone') ?? 'America/Los_Angeles',
            ),
          );

          values.set(mealTypeDateId, {
            periodId: period.get('id'),
            periodDateId: mealTypeDate.get('periodDateId'),
            mealTypeId: mealType.get('id'),
            mealTypeDateId,
            mealCount: mealTypeDate.get('mealCount'),
            disabled: disabled,
          });
        });
      });
    });

    setValues(values);
  }, [catering, event]);

  if (!values) {
    return <LoadingIndicator />;
  }

  if (values.size === 0) {
    return (
      <FullPageMessage icon="Sad" message="There are no meal types defined." />
    );
  }

  return (
    <div>
      {catering.get('periods').map(period => (
        <div key={period.get('id')}>
          <CateringPeriodRequest period={period} values={values} />
        </div>
      ))}
    </div>
  );
};

CateringRequest.propTypes = {
  event: PropTypes.instanceOf(ImmutableMap),
};

export default CateringRequest;
