import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getContactLabelForDepartment } from './onsite-utils';
import { getStaffContactList } from '../contact/contact-selectors';
import { getCurrentDepartment } from '../department/department-selectors';
import {
  getEventOnsiteRequestTypes,
  getEventOnsiteSettings,
} from '../event/event-selectors';
import departmentTypeMap from '../lib/department-type-map';
import InputScaffold from '../common/forms/InputScaffold';
import ReduxReactSelectField from '../common/forms/ReduxReactSelectField';
import NameChangeForm from './NameChangeForm';
import CreateContactForm from './CreateContactForm';
import EditContactForm from './EditContactForm';
import DeleteContactForm from './DeleteContactForm';

const LABEL_IDENTIFIER = '[Label]';

const NAME_CHANGE = 'Name Change';
const CREATE_CONTACT = 'Add Person';
const EDIT_CONTACT = 'Modify Person';
const DELETE_CONTACT = 'Remove Person';

const RequestTypes = [
  {
    label: 'Name Change',
    value: NAME_CHANGE,
    requireContacts: true,
  },
  {
    label: `Add ${LABEL_IDENTIFIER}`,
    value: CREATE_CONTACT,
    requireContacts: false,
  },
  {
    label: `Modify ${LABEL_IDENTIFIER}`,
    value: EDIT_CONTACT,
    requireContacts: true,
  },
  {
    label: `Remove ${LABEL_IDENTIFIER}`,
    value: DELETE_CONTACT,
    requireContacts: true,
  },
];

const OnsiteRequestForm = () => {
  const params = useParams();

  const selectRef = useRef(null);

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const contacts = useSelector(state => getStaffContactList(state, { params }));

  const eventOnsiteSettings = useSelector(state =>
    getEventOnsiteSettings(state, { params }),
  );

  const eventOnsiteRequestTypes = useSelector(state =>
    getEventOnsiteRequestTypes(state, { params }),
  );

  const filterCredentialsByPeriod = eventOnsiteSettings
    ? Boolean(eventOnsiteSettings.get('filter_credentials_by_period'))
    : false;

  const requireContactEmails = eventOnsiteSettings
    ? Boolean(eventOnsiteSettings.get('require_contact_emails'))
    : false;

  const overrideCredentialRequestMaximums = eventOnsiteSettings
    ? Boolean(eventOnsiteSettings.get('override_credential_request_maximums'))
    : false;

  const [requestType, setRequestType] = useState(null);

  const requestTypeOptions = useMemo(() => {
    const hasContacts = contacts && !!contacts.size;

    const onsiteRequestTypesMap = eventOnsiteRequestTypes.groupBy(
      onsiteRequestType => onsiteRequestType.get('onsite_request_type'),
    );
    const contactLabel = getContactLabelForDepartment(department);

    const options = [];
    RequestTypes.forEach(requestType => {
      if (
        !onsiteRequestTypesMap.get(requestType.value).first().get('is_enabled')
      )
        return;

      if (requestType.requireContacts && !hasContacts) return;

      options.push({
        label: requestType.label.replace(LABEL_IDENTIFIER, contactLabel),
        value: requestType.value,
      });
    });

    return options;
  }, [contacts, department, eventOnsiteRequestTypes]);

  const onSubmit = () => {
    setRequestType(null);
    selectRef.current.focus();
  };

  const renderRequestTypeForm = () => {
    switch (requestType?.value) {
      case NAME_CHANGE:
        return (
          <NameChangeForm
            department={department}
            eventOnsiteRequestTypes={eventOnsiteRequestTypes}
            onSubmit={onSubmit}
          />
        );

      case CREATE_CONTACT:
        return (
          <CreateContactForm
            department={department}
            eventOnsiteRequestTypes={eventOnsiteRequestTypes}
            filterCredentialsByPeriod={filterCredentialsByPeriod}
            requireContactEmails={requireContactEmails}
            overrideCredentialRequestMaximums={
              overrideCredentialRequestMaximums
            }
            onSubmit={onSubmit}
          />
        );

      case EDIT_CONTACT:
        return (
          <EditContactForm
            department={department}
            eventOnsiteRequestTypes={eventOnsiteRequestTypes}
            filterCredentialsByPeriod={filterCredentialsByPeriod}
            requireContactEmails={requireContactEmails}
            overrideCredentialRequestMaximums={
              overrideCredentialRequestMaximums
            }
            onSubmit={onSubmit}
          />
        );

      case DELETE_CONTACT:
        return (
          <DeleteContactForm
            department={department}
            eventOnsiteRequestTypes={eventOnsiteRequestTypes}
            onSubmit={onSubmit}
          />
        );

      default:
        return null;
    }
  };

  if (!department) {
    return null;
  }

  return (
    <div style={{ marginTop: '20px' }}>
      <h4>
        {departmentTypeMap[params.departmentType].label.singular} -{' '}
        {department.get('name')}
      </h4>
      <InputScaffold label="Type of Request" required>
        <ReduxReactSelectField
          ref={selectRef}
          autoFocus
          className="onsite-select"
          placeholder="Select request you want to make..."
          isClearable
          value={requestType}
          options={requestTypeOptions}
          onChange={setRequestType}
        />
      </InputScaffold>
      {renderRequestTypeForm()}
    </div>
  );
};

export default OnsiteRequestForm;
