import { useParams } from 'react-router-dom';
import ContactCalendar from './ContactCalendar';
import ContactListPage from './ContactListPage';

const ContactListView = () => {
  const params = useParams();
  return params.listView === 'calendar' ? (
    <ContactCalendar />
  ) : (
    <ContactListPage />
  );
};

export default ContactListView;
