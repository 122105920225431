import { Checkbox } from 'synfrastructure';
import moment from 'moment';
import translateErrors from '../translateErrors';
import PropTypes from 'prop-types';
import InputScaffold from '../../forms/InputScaffold';
import ToggleScaffold from '../../forms/ToggleScaffold';
import TimePicker from 'rc-time-picker';
import Icon from '../../icons/Icon';

const Time = ({ field, autoFocus, onFieldRemove, onChange }) => (
  <div className="input-group input-group--large">
    <div className="custom-request-form__field--title">
      <h3>Time Field</h3>
      <button
        className="button button--plain button--icon"
        onClick={onFieldRemove}
        type="button"
      >
        <Icon icon="Trash" />
        <span>Remove</span>
      </button>
    </div>

    <InputScaffold
      label="Label"
      required
      validation={translateErrors(field.get('error')?.label)}
    >
      <input
        type="text"
        name="label"
        value={field.get('label') || ''}
        onChange={e => onChange(field.set('label', e.target.value))}
        autoFocus={autoFocus}
      />
    </InputScaffold>

    <InputScaffold
      label="Default Value"
      validation={translateErrors(field.get('error')?.value)}
    >
      <div className="timepicker-container">
        <TimePicker
          name="value"
          value={
            (field.get('value') && moment.unix(field.get('value'))) || null
          }
          onChange={momentDate =>
            onChange(
              field.set(
                'value',
                moment.isMoment(momentDate) ? momentDate.unix() : momentDate,
              ),
            )
          }
          prefixCls="timepicker"
          showSecond={false}
          use12Hours
        />
      </div>
    </InputScaffold>

    <InputScaffold
      label="Description"
      validation={translateErrors(field.get('error')?.description)}
    >
      <textarea
        name="description"
        value={field.get('description') || ''}
        onChange={e => onChange(field.set('description', e.target.value))}
      />
    </InputScaffold>

    <ToggleScaffold
      label="Required"
      validation={translateErrors(field.get('error')?.required)}
    >
      <Checkbox
        id="required"
        name="required"
        checked={!!field.get('required')}
        onChange={e => onChange(field.set('required', e.target.checked))}
      />
    </ToggleScaffold>
  </div>
);

Time.propTypes = {
  field: PropTypes.object,
  onFieldRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default Time;
