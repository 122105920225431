import PropTypes from 'prop-types';

const MoneyDisplay = ({ label, value }) => (
  <div>
    <dt>{label}:</dt>
    <dd>${value}</dd>
  </div>
);

MoneyDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default MoneyDisplay;
