import { combineReducers } from 'redux';
import Immutable from 'immutable';
import { DELETE_USER, RESEND_INVITE } from './user-actions';
import getApiReducer, { defaultState } from '../common/utils/getApiReducer';

export function getUserListReducer(actionType) {
  return (state = defaultState, action) => {
    let newState = getApiReducer(actionType)(state, action);

    if (action.type === 'DELETE_USER_SUCCESS' && newState.get('data')) {
      const deletedIndex = newState
        .get('data')
        .findKey(item => item.get('id') === action.deletedUserId);
      if (typeof deletedIndex !== 'undefined') {
        newState = newState.set(
          'data',
          newState.get('data').delete(deletedIndex),
        );
      }
    }
    if (action.type === 'RESEND_INVITE_SUCCESS' && newState.get('data')) {
      const index = newState
        .get('data')
        .findKey(item => item.get('id') === action.json.id);
      if (typeof index !== 'undefined') {
        newState = newState.setIn(
          ['data', index, 'invite_sent_at'],
          action.json.invite_sent_at,
        );
      }
    }
    if (action.type === 'EDIT_EVENT_USER_SUCCESS' && newState.get('data')) {
      const index = newState
        .get('data')
        .findKey(item => item.get('id') === action.json.id);
      if (typeof index !== 'undefined') {
        newState = newState.mergeIn(['data', index], action.json);
      }
    }

    if (
      action.type === 'ENABLE_PROMOTER_ADMIN_SUCCESS' &&
      newState.get('data')
    ) {
      const index = newState
        .get('data')
        .findIndex(user => user.get('id') === action.userId);
      if (index > -1) {
        newState = newState.setIn(
          ['data', index, 'promoterRoles'],
          newState
            .getIn(['data', index, 'promoterRoles'])
            .push(Immutable.fromJS(action.json)),
        );
      }
    }

    if (
      action.type === 'DISABLE_PROMOTER_ADMIN_SUCCESS' &&
      newState.get('data')
    ) {
      const index = newState
        .get('data')
        .findIndex(user => user.get('id') === action.userId);
      if (index > -1) {
        newState = newState.setIn(
          ['data', index, 'promoterRoles'],
          newState
            .getIn(['data', index, 'promoterRoles'])
            .filter(role => role.get('promoter_id') !== action.promoterId),
        );
      }
    }

    if (action.type === 'ENABLE_EVENT_ADMIN_SUCCESS' && newState.get('data')) {
      const index = newState
        .get('data')
        .findIndex(user => user.get('id') === action.userId);
      if (index > -1) {
        newState = newState.setIn(
          ['data', index, 'eventRoles'],
          Immutable.fromJS(action.json),
        );
      }
    }

    if (action.type === 'DISABLE_EVENT_ADMIN_SUCCESS' && newState.get('data')) {
      const index = newState
        .get('data')
        .findIndex(user => user.get('id') === action.userId);
      if (index > -1) {
        newState = newState.setIn(
          ['data', index, 'eventRoles'],
          newState
            .getIn(['data', index, 'eventRoles'])
            .filter(role => role.get('event_id') !== action.eventId),
        );
      }
    }

    return newState;
  };
}

export default combineReducers({
  deleteUser: getApiReducer(DELETE_USER),
  resendInvite: getApiReducer(RESEND_INVITE),
});
