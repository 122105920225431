import React from 'react';

function Italic() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path d="M7 2v2h2.58l-3.66 8H3v2h8v-2H8.42l3.66-8H15V2z" />
    </svg>
  );
  /* eslint-enable */
}

export default Italic;
