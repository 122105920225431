import client from '../../client';

export const ADD_CREDENTIAL_TYPE = 'ADD_CREDENTIAL_TYPE';
export function addCredentialType(eventId, payload) {
  return {
    type: ADD_CREDENTIAL_TYPE,
    promise: client.post(`event/${eventId}/credential-types`, payload),
  };
}

export const EDIT_CREDENTIAL_TYPE = 'EDIT_CREDENTIAL_TYPE';
export function editCredentialType(credentialTypeId, payload) {
  return {
    type: EDIT_CREDENTIAL_TYPE,
    promise: client.patch(`credential-types/${credentialTypeId}`, payload),
  };
}

export const ADD_CREDENTIAL_TYPE_CATEGORY = 'ADD_CREDENTIAL_TYPE_CATEGORY';
export function addCredentialTypeCategory(eventId, payload) {
  return {
    type: ADD_CREDENTIAL_TYPE_CATEGORY,
    promise: client.post(
      `event/${eventId}/credential-type-categories`,
      payload,
    ),
  };
}

export const EDIT_CREDENTIAL_TYPE_CATEGORY = 'EDIT_CREDENTIAL_TYPE_CATEGORY';
export function editCredentialTypeCategory(categoryId, payload) {
  return {
    type: EDIT_CREDENTIAL_TYPE_CATEGORY,
    promise: client.patch(`credential-type-categories/${categoryId}`, payload),
  };
}

export const FETCH_CREDENTIAL_TYPE_CATEGORY_LIST =
  'FETCH_CREDENTIAL_TYPE_CATEGORY_LIST';
export function fetchCredentialTypeCategoryList(eventId, query = {}) {
  return {
    type: FETCH_CREDENTIAL_TYPE_CATEGORY_LIST,
    promise: client.get(`event/${eventId}/credential-type-categories`, query),
  };
}
