import React from 'react';

function H3() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <path d="M9.224,13.908H7.173V9.642h-4v4.266H1.123V3.955H3.174V7.988h4V3.955H9.224Z" />
      <path d="M10.4,9.013l.574-5.059h5.578V5.6H12.591l-.246,2.14a3.136,3.136,0,0,1,1.5-.376,2.869,2.869,0,0,1,2.229.882,3.522,3.522,0,0,1,.807,2.468,3.613,3.613,0,0,1-.406,1.726A2.878,2.878,0,0,1,15.3,13.625a3.648,3.648,0,0,1-1.791.42,3.872,3.872,0,0,1-1.675-.365,2.968,2.968,0,0,1-1.22-1.029,2.8,2.8,0,0,1-.476-1.511H12.1a1.459,1.459,0,0,0,.435.968,1.376,1.376,0,0,0,.974.345,1.217,1.217,0,0,0,1.032-.481,2.238,2.238,0,0,0,.362-1.364,1.845,1.845,0,0,0-.417-1.3A1.53,1.53,0,0,0,13.3,8.856a1.71,1.71,0,0,0-1.142.369l-.191.178Z" />
    </svg>
  );
  /* eslint-enable */
}

export default H3;
