const sortContacts = contacts =>
  contacts
    ?.sortBy(
      contact =>
        contact.get('last_name') && contact.get('last_name').toLowerCase(),
    )
    .sortBy(
      contact =>
        contact.get('first_name') && contact.get('first_name').toLowerCase(),
    )

    .sortBy(contact => contact.get('is_primary') * -1);

export default sortContacts;
