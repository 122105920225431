import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Map } from 'immutable';
import { cloneDeep } from 'lodash';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { editContact } from './contact-actions';
import PropTypes from 'prop-types';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import CredentialAssignmentField from './CredentialAssignmentField';
import StatusButton from '../common/StatusButton';

const CredentialAssignmentForm = ({ department, contact, requireApproval }) => {
  const dispatch = useDispatch();

  const handleSubmit = values => {
    const payload = cloneDeep(values);

    // Add existing quantities to values, since this form represents _additional_ credentials requested
    payload.credentials.forEach((credQty, index) => {
      contact.get('credentialRequests').forEach(credReq => {
        if (credReq.get('credential_id') === credQty.id) {
          payload.credentials[index].quantity +=
            credReq.get('quantity_pending');
        }
      });
    });

    return dispatch(editContact(contact.get('id'), payload)).then(action => {
      if (!action.response.ok)
        return formatValidationErrors(action.json).toJS();
    });
  };

  const getAlreadyAssignedIndividualPickupCredentialIds = () => {
    const individualPickupCredIds = [];
    department.get('credentialTypes').forEach(credType => {
      if (
        credType.getIn(['attendeePickupTypes', 0, 'pickup_type']) ===
        'individual'
      ) {
        individualPickupCredIds.push(
          ...credType
            .get('credentials')
            .map(cred => cred.get('id'))
            .toJS(),
        );
      }
    });

    const alreadyAssignedIndividualPickupCredIds = [];
    contact.get('credentialRequests').forEach(credReq => {
      if (
        individualPickupCredIds.indexOf(credReq.get('credential_id')) > -1 &&
        (credReq.get('quantity_pending') > 0 ||
          credReq.get('quantity_approved') > 0)
      ) {
        alreadyAssignedIndividualPickupCredIds.push(
          credReq.get('credential_id'),
        );
      }
    });

    return alreadyAssignedIndividualPickupCredIds;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ credentials: [] }}
      render={({ handleSubmit, pristine, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Field name="credentials" required component={ReduxFormsFieldNoLabel}>
            <CredentialAssignmentField
              department={department}
              requireApproval={requireApproval}
              skippedCredentials={getAlreadyAssignedIndividualPickupCredentialIds()}
            />
          </Field>
          <StatusButton
            type="submit"
            buttonText="Submit"
            disabled={pristine || submitting}
            status={submitting ? 'loading' : 'default'}
          />
        </form>
      )}
    />
  );
};

CredentialAssignmentForm.propTypes = {
  department: PropTypes.instanceOf(Map).isRequired,
  contact: PropTypes.instanceOf(Map).isRequired,
  requireApproval: PropTypes.bool.isRequired,
};

export default CredentialAssignmentForm;
