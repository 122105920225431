import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import {
  combineValidators,
  composeValidators,
  createValidator,
  isRequired,
  hasLengthGreaterThan,
} from 'revalidate';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { removeLastUriSegment } from '../common/uri';
import { addMealType, updateMealType } from './catering-actions';
import { getCurrentMealType } from './catering-selectors';
import {
  getCurrentEvent,
  getCurrentEventSettings,
} from '../event/event-selectors';
import { showNotification } from '../notification/notification-actions';
import Immutable from 'immutable';
import get from 'lodash/get';
import moment from 'moment-timezone';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import ReduxTimepickerFieldMoment from '../common/forms/ReduxTimepickerFieldMoment';
import FullPageMessage from '../common/FullPageMessage';
import StatusButton from '../common/StatusButton';
import LoadingIndicator from '../common/LoadingIndicator';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import SectionsCheckboxGroup from '../common/forms/SectionsCheckboxGroup';
import ReduxDatepickerField from '../common/forms/ReduxDatepickerField';
import ComplexReduxFieldWrapper from '../common/forms/ComplexReduxFieldWrapper';
import PassFulfillmentMegaField from '../credential/type/PassFulfillmentMegaField';

const datesAvailableNotEmpty = createValidator(
  message => value => {
    if (get(value, 'periods.0.dates', []).length === 0) {
      return message;
    }
  },
  () => 'You must select at least one date',
);

const validate = combineValidators({
  name: isRequired('Name'),
  sections: composeValidators(
    isRequired,
    hasLengthGreaterThan(0),
  )({ message: 'You must select at least one section' }),
  dates_available: composeValidators(
    isRequired,
    datesAvailableNotEmpty,
  )({ message: 'You must select at least one date' }),
});

const MealTypeForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const mealType = useSelector(state => getCurrentMealType(state, { params }));
  const event = useSelector(state => getCurrentEvent(state, { params }));
  const eventSettings = useSelector(state =>
    getCurrentEventSettings(state, { params }),
  );
  const timezone = event ? event.get('time_zone') : 'US/Pacific';
  const periodList = event?.get('periods');

  const initialValues = mealType
    ? {
        name: mealType.get('name'),
        description: mealType.get('description'),
        deadline_date: mealType.get('deadline_date')
          ? moment.utc(mealType.get('deadline_date')).tz(timezone)
          : null,
        deadline_time: mealType.get('deadline_date')
          ? moment.utc(mealType.get('deadline_date')).tz(timezone)
          : moment().startOf('day').set({ h: 17 }),
        sections: mealType
          .get('sections')
          .map(section => section.get('name'))
          .toJS(),
        dates_available: {
          issue_frequency: 'DAILY',
          periods: mealType
            .get('dates')
            .groupBy(date => date.get('period_id'))
            .map(period =>
              period.reduce(
                (collected, date) => {
                  collected.id = date.get('period_id');
                  collected.dates.push(Number(date.get('id')));

                  return collected;
                },
                { id: null, dates: [] },
              ),
            )
            .toList()
            .toJS(),
        },
      }
    : {
        dates_available: { issue_frequency: 'DAILY', periods: [] },
        deadline_time: moment().startOf('day').set({ h: 17 }),
        deadline_date: null,
      };

  const backTo = removeLastUriSegment(location.pathname);

  const handleSubmit = values => {
    const payload = {
      name: values.name,
      description: values.description ?? null,
      sections: values.sections,
      dates_available: {
        periods: values.dates_available.periods,
      },
      deadline_date: null,
    };

    if (values.deadline_date) {
      const deadlineDate = moment.tz(
        {
          year: values.deadline_date.year(),
          month: values.deadline_date.month(),
          day: values.deadline_date.date(),
          hour: values.deadline_time.hour(),
          minutes: values.deadline_time.minutes(),
        },
        timezone,
      );

      payload.deadline_date = deadlineDate;
    }

    const action = mealType
      ? updateMealType(event.get('id'), mealType.get('id'), payload)
      : addMealType(event.get('id'), payload);

    return dispatch(action).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      }
      navigate(backTo);
      dispatch(
        showNotification({
          message: 'Meal type successfully saved',
          status: 'success',
        }),
      );
    });
  };

  if (!periodList) {
    return <LoadingIndicator />;
  }

  if (periodList.size === 0) {
    return (
      <FullPageMessage
        icon="Sad"
        message="Cannot create a meal type because you haven't created any periods"
      />
    );
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
    >
      {({ handleSubmit, pristine, submitting, submitFailed, values }) => (
        <form className="generic-form" onSubmit={handleSubmit}>
          <Paper>
            <PaperHeader
              backTo={backTo}
              title={`${mealType ? 'Edit' : 'Add'} Meal Type`}
            />
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                <Field
                  name="name"
                  component={ReduxFormsField}
                  label="Meal Name"
                  required
                >
                  <input type="text" autoFocus={!mealType} />
                </Field>
                <Field
                  name="sections"
                  component={ReduxFormsFieldNoLabel}
                  label="Sections"
                  required
                >
                  <SectionsCheckboxGroup
                    eventSettings={
                      eventSettings && eventSettings.size
                        ? eventSettings
                        : Immutable.Map()
                    }
                  />
                </Field>
                <div style={{ display: 'flex' }}>
                  <Field
                    name="deadline_date"
                    label="Deadline Date"
                    component={ReduxFormsField}
                  >
                    <ReduxDatepickerField
                      showClearDate
                      startDate={moment.utc(event?.get('start_date'))}
                      initialVisibleMonth={() =>
                        moment.utc(event?.get('start_date'))
                      }
                    />
                  </Field>
                  {values.deadline_date && (
                    <Field
                      name="deadline_time"
                      label="Deadline Time"
                      component={ReduxFormsField}
                      required
                    >
                      <ReduxTimepickerFieldMoment allowEmpty={false} />
                    </Field>
                  )}
                  {values.deadline_date && (
                    <span
                      style={{
                        alignSelf: 'flex-end',
                        marginBottom: '15px',
                        marginLeft: '10px',
                      }}
                    >
                      {timezone}
                    </span>
                  )}
                </div>
                <Field
                  name="description"
                  component={ReduxFormsField}
                  label="Description"
                >
                  <textarea />
                </Field>
              </div>
            </div>
          </Paper>
          <br />
          <Field
            name="dates_available"
            label="Dates Available"
            component={ComplexReduxFieldWrapper}
          >
            <PassFulfillmentMegaField
              periods={periodList}
              showFrequencyDropdown={false}
            />
          </Field>
          <br />
          <Paper>
            <div className="generic-form__footer">
              <StatusButton
                type="submit"
                disabled={submitting || pristine}
                status={
                  submitFailed && pristine
                    ? 'error'
                    : submitting
                    ? 'loading'
                    : 'default'
                }
              />
              <Link
                className="button button--plain"
                to={backTo}
                disabled={submitting}
              >
                Cancel
              </Link>
            </div>
          </Paper>
        </form>
      )}
    </Form>
  );
};

export default MealTypeForm;
