import { useNavigate, useParams } from 'react-router-dom';
import { Input } from './SearchInput.styles';
import PropTypes from 'prop-types';

const SearchInput = ({
  eventId,
  isOpen,
  inputValue,
  onInputValueChange,
  handleClose,
  handleFetchStream,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const handleKeyDown = async event => {
    if (isOpen && event.key === 'Enter') {
      const response = await handleFetchStream(inputValue, 'ResultsContainer');
      if (response?.ok) {
        handleClose();
        navigate({
          pathname: `/promoters/${params.promoterSlug}/festivals/${
            params.festivalSlug
          }/events/${params.eventSlug}/search-results/${encodeURIComponent(
            eventId,
          )}/`,
          search: `?search=${encodeURIComponent(inputValue)}`,
        });
      }
    }
  };

  return (
    <Input
      autoFocus
      placeholder={isOpen ? 'Search....' : ''}
      value={inputValue}
      minLength={2}
      debounceTimeout={150}
      onChange={event => onInputValueChange(event.target.value)}
      onKeyDown={handleKeyDown}
    />
  );
};

SearchInput.propTypes = {
  eventId: PropTypes.number,
  isOpen: PropTypes.bool,
  inputValue: PropTypes.string,
  onInputValueChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleFetchStream: PropTypes.func,
};

export default SearchInput;
