import { composeValidators, createValidator } from 'revalidate';
import get from 'lodash/get';

const notEmpty = createValidator(
  message => value => {
    if (!get(value, 'periods.0')) {
      return message;
    }
  },
  field => `${field} must include at least one selected date or period`,
);

export default composeValidators(notEmpty)('Pass Fulfillment');
