import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import {
  combineValidators,
  composeValidators,
  isRequired,
  isRequiredIf,
} from 'revalidate';
import { NO_PREFERENCE, VEGAN, VEGETARIAN } from '../lib/meal-preferences';
import { resource, useACL } from '../common/ACL';
import { getStatusForButton } from '../common/forms/FormHelper';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import { editContact } from './contact-actions';
import { useDepartmentUnsavedCredentialsDispatch } from './DepartmentUnsavedCredentials';
import {
  UnsavedCredentialsProvider,
  selectUnsavedCredentialsWithQuantity,
  useUnsavedCredentials,
  useUnsavedCredentialsDispatch,
} from './UnsavedCredentials';
import isValidPhone from '../common/validate/is-valid-phone';
import isValidEmail from '../common/validate/is-valid-email';
import phoneFilter from '../common/filters/phone-filter';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import StatusButton from '../common/StatusButton';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import ContactInfoForm from './ContactInfoForm';
import ContactCredentials, {
  GROUP_PERSONNEL,
  REGULAR_STAFF,
} from './ContactCredentials';
import ContactOnsiteRequests from './ContactOnsiteRequests';

const aclRules = {
  canReconcileOrder: [resource.EVENT, 'reconcile-order'],
};

const ContactDetailsForm = ({ contact, department, deptSettings }) => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();

  const departmentUnsavedCredentialsDispatch =
    useDepartmentUnsavedCredentialsDispatch();

  const unsavedCredentialsDispatch = useUnsavedCredentialsDispatch();
  const unsavedCredentials = selectUnsavedCredentialsWithQuantity(
    useUnsavedCredentials(),
  );

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const onSaving = useCallback(isSaving => setSaving(isSaving), []);
  const onDeleting = useCallback(isDeleting => setDeleting(isDeleting), []);

  const initialValues = {
    phone: contact.get('phone') ?? null,
    email: contact.get('email') ?? null,
    other_role: contact.get('other_role') ?? null,
    relationship: contact.get('relationship') ?? null,
    is_primary: Boolean(contact.get('is_primary')),
    meal_preference: contact.get('meal_preference') ?? NO_PREFERENCE,
  };

  const validate = combineValidators({
    phone: isValidPhone('Phone'),
    email: composeValidators(
      department.get('require_contact_emails')
        ? isRequired
        : isRequiredIf(values => values.is_primary),
      isValidEmail,
    )('Email'),
  });

  const clearDepartmentUnsavedCredentials = () => {
    departmentUnsavedCredentialsDispatch({
      type: 'subtract',
      changedQuantities: unsavedCredentials.map(unsavedCredential => ({
        credentialId: unsavedCredential.id,
        quantity: unsavedCredential.quantity,
      })),
    });
  };

  const displayErrors = action => {
    const errors = action.json.errors;
    if (errors?.length) {
      const message = errors
        .map(error => error.error)
        .reduce((acc, error) => (error ? `${acc}${error}.\n` : acc), '');
      dispatch(showNotification({ message, duration: 60000, status: 'error' }));
    }
  };

  const handleSubmit = values => {
    const contactId = contact.get('id');

    const payload = {
      is_primary: Boolean(values.is_primary) ?? false,
      email: values.email?.trim() ?? null,
      phone: values.phone ? phoneFilter(values.phone) : null,
      relationship: values.relationship ?? null,
      other_role: values.other_role?.trim() ?? null,
      meal_preference: values.meal_preference ?? NO_PREFERENCE,
    };

    if (unsavedCredentials.length) {
      payload.credentials = unsavedCredentials;
    }

    return dispatch(editContact(contactId, payload)).then(action => {
      if (action.response.ok) {
        displayErrors(action);
        clearDepartmentUnsavedCredentials();
        unsavedCredentialsDispatch({ type: 'clear' });
      } else {
        return formatValidationErrors(action.json).toJS();
      }
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({
        handleSubmit,
        invalid,
        pristine,
        submitting,
        submitFailed,
      }) => {
        const isSubmitDisabled =
          (pristine && !unsavedCredentials.length) ||
          invalid ||
          saving ||
          deleting ||
          submitting ||
          submitFailed;

        return (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="contact-details-section">
                <div className="contact-details-section__header">
                  <Icon icon="Person" />
                  Contact Information
                </div>
                <div className="contact-details-section__content">
                  <ContactInfoForm
                    department={department}
                    contact={contact}
                    relationships={deptSettings?.get('relationships')}
                  />
                </div>
              </div>
              {deptSettings?.get('catering') && (
                <div className="contact-details-section">
                  <div className="contact-details-section__header contact-details-section__subtext">
                    <div>
                      <Icon icon="Food" />
                      Catering
                    </div>
                  </div>
                  <div className="contact-details-section__content">
                    <div className="contact-catering catering-row">
                      <div className="catering-row__meal">
                        <div className="contact-credentials__select">
                          <Field
                            name="meal_preference"
                            component={ReduxFormsFieldNoLabel}
                            required
                          >
                            <select>
                              <option value={NO_PREFERENCE}>
                                No Preference
                              </option>
                              <option value={VEGAN}>Vegan</option>
                              <option value={VEGETARIAN}>Vegetarian</option>
                            </select>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <ContactCredentials
                type={
                  contact?.get('is_group') ? GROUP_PERSONNEL : REGULAR_STAFF
                }
                department={department}
                contact={contact}
                onSaving={onSaving}
                onDeleting={onDeleting}
              />
              <ContactOnsiteRequests
                contact={contact}
                disabled={!isSubmitDisabled}
              />
            </div>
            <div className="contact-details-section__footer">
              {acl.canReconcileOrder && (
                <Link to={`../../reconcile/${contact.get('id')}`}>
                  Pulse Orders
                </Link>
              )}
              <StatusButton
                type="submit"
                disabled={isSubmitDisabled}
                status={getStatusForButton({
                  pristine,
                  submitting: submitting || deleting || saving,
                  submitFailed,
                })}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

ContactDetailsForm.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  department: PropTypes.instanceOf(Map).isRequired,
  deptSettings: PropTypes.instanceOf(Map).isRequired,
};

const ContactDetailsFormWrapper = props => (
  <UnsavedCredentialsProvider>
    <ContactDetailsForm {...props} />
  </UnsavedCredentialsProvider>
);

export default ContactDetailsFormWrapper;
