import { createValidator } from 'revalidate';

export default createValidator(
  message => value => {
    if (Array.isArray(value)) {
      if (value.some(element => !!!element)) {
        return message;
      }
    }
  },
  field => `${field} must not contain any empty values`,
);
