import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

const convertTimeStringToMoment = timeString => {
  return moment(`${moment().format('YYYY-MM-DD')} ${timeString}`);
};

const ReduxTimepickerFieldMoment = ({
  value,
  onChange,
  defaultOpenValue = moment().hours(12).minutes(0),
  allowEmpty = true,
}) => {
  if (value === '') {
    value = null;
  } else if (typeof value === 'string') {
    if (value.length === 8) {
      // If it's just the time we need to add a date to it
      value = convertTimeStringToMoment(value);
    } else {
      value = moment(value);
    }
  }

  return (
    <div className="timepicker-container">
      <TimePicker
        name="value"
        value={value}
        onChange={momentDate => onChange(momentDate && momentDate.seconds(0))}
        prefixCls="timepicker"
        showSecond={false}
        use12Hours
        defaultOpenValue={defaultOpenValue}
        allowEmpty={allowEmpty}
      />
    </div>
  );
};

ReduxTimepickerFieldMoment.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  defaultOpenValue: PropTypes.object,
  allowEmpty: PropTypes.bool,
};

export default ReduxTimepickerFieldMoment;
