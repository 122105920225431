import React from 'react';

function H2() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <path d="M9.2,14.045H7.149V9.779h-4v4.266H1.1V4.092H3.15V8.125h4V4.092H9.2Z" />
      <path d="M16.9,14.045H10.079V12.692L13.3,9.26a7.86,7.86,0,0,0,.98-1.265A2.022,2.022,0,0,0,14.6,6.969a1.519,1.519,0,0,0-.335-1.042,1.209,1.209,0,0,0-.957-.379,1.3,1.3,0,0,0-1.056.461,1.826,1.826,0,0,0-.387,1.213H9.881a3.267,3.267,0,0,1,.434-1.661,3.065,3.065,0,0,1,1.228-1.179,3.73,3.73,0,0,1,1.8-.427,3.528,3.528,0,0,1,2.389.738,2.609,2.609,0,0,1,.852,2.085,3.35,3.35,0,0,1-.383,1.5,8.356,8.356,0,0,1-1.312,1.785l-2.263,2.386H16.9Z" />
    </svg>
  );
  /* eslint-enable */
}

export default H2;
