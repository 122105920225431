import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import Icon from '../common/icons/Icon';
import CardListItem from '../common/CardListItem';
import Paper from '../common/paper/Paper';
import LoadingIndicator from '../common/LoadingIndicator';

const aclRules = {
  canAddOrEditPromoter: [resource.PROMOTER, 'edit'],
};

const PromoterList = () => {
  const acl = useACL(aclRules);
  const promoterList = useSelector(state => state.promoter.list.get('data'));
  const [activeCard, setActiveCard] = useState();

  const renderPromoterKebabMenu = promoter => {
    if (!acl.canAddOrEditPromoter) {
      return null;
    }

    return (
      <div>
        <Link
          className="dropdown__item"
          to={`/promoters/${promoter.get('slug')}/edit`}
        >
          <div className="dropdown__item__children">
            <Icon icon="Pencil" />
            <span className="text">Edit Promoter</span>
          </div>
        </Link>
      </div>
    );
  };

  const renderList = () => {
    return (
      <div className="card-list">
        {promoterList.map((promoter, index) => (
          <CardListItem
            key={promoter.get('id')}
            linkTo={`/promoters/${promoter.get('slug')}/festivals`}
            name={promoter.get('name')}
            image={
              promoter.get('file_id')
                ? promoter.getIn(['logo', 'location'])
                : false
            }
            menuActive={activeCard === index}
            onMenuToggle={() =>
              activeCard === index ? setActiveCard(false) : setActiveCard(index)
            }
          >
            {renderPromoterKebabMenu(promoter)}
          </CardListItem>
        ))}
        {acl.canAddOrEditPromoter ? (
          <Link className="card__link card--add" to="/promoters/~add">
            <div>
              <Icon icon="Plus" />
              <span className="text">Add New</span>
            </div>
          </Link>
        ) : null}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <Paper className="card-list--empty">
        <Icon icon="Sad" />
        <p>There are no promoters to display.</p>
        {acl.canAddOrEditPromoter ? (
          <Link className="button" to="/promoters/~add">
            Add Promoter
          </Link>
        ) : null}
      </Paper>
    );
  };

  return !promoterList ? (
    <LoadingIndicator />
  ) : !promoterList.size ? (
    renderEmpty()
  ) : (
    renderList()
  );
};

export default PromoterList;
