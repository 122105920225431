import { createValidator } from 'revalidate';

export default function isLessThan(comparisonValue) {
  return createValidator(
    message => value => {
      if (value >= comparisonValue) {
        return message;
      }
    },
    field => `${field} must be less than ${comparisonValue}`,
  );
}
