import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { trimUriSegments } from '../common/uri';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { createDepartmentGroup } from './department-actions';
import { getCurrentEvent } from '../event/event-selectors';
import { showNotification } from '../notification/notification-actions';
import DepartmentGroupForm from './DepartmentGroupForm';

const DepartmentGroupAdd = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const initialValues = {
    name: null,
    section: 'production',
    departments: [],
    credentialTypes: [],
    mealTypes: [],
  };

  const departmentGroupsUrl = trimUriSegments(location.pathname, 1);

  const handleSubmit = values => {
    const payload = {
      name: values.name,
      departments: values.departments.map(d => Number(d)),
      credentialTypes: values.credentialTypes.map(ct => ct.value),
      mealTypes: values.mealTypes.map(mt => mt.value),
    };

    return dispatch(
      createDepartmentGroup(event.get('id'), values.section, payload),
    ).then(action => {
      if (!action.response.ok) {
        const errors = formatValidationErrors(action.json).toJS();
        errors._error = action.response;
        const message =
          'name' in errors
            ? `Group name: ${payload.name} already in use in this section - group not created.`
            : 'Error creating department group';
        dispatch(showNotification({ message, status: 'error' }));
        return errors;
      }
      navigate(departmentGroupsUrl);
      dispatch(
        showNotification({
          message: `${payload.name} group successfully created`,
          status: 'success',
        }),
      );
    });
  };

  if (!event) return null;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={props => (
        <DepartmentGroupForm
          title="Create Department Group"
          eventId={event.get('id')}
          departmentGroupsUrl={departmentGroupsUrl}
          {...props}
        />
      )}
    />
  );
};

export default DepartmentGroupAdd;
