import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { trimUriSegments } from '../common/uri';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { getCurrentEvent, getCurrentEventAdmin } from './event-selectors';
import {
  convertRoleBooleansToRoleArray,
  getDefaultRoleValues,
} from './event-settings-helpers';
import { editEventUser, resendInvite } from '../user/user-actions';
import moment from 'moment';
import departmentTypeList from '../lib/department-types';
import recursiveTrim from '../common/utils/recursive-trim';
import Icon from '../common/icons/Icon';
import Paper, { PaperBody } from '../common/paper/Paper';
import EventAdminPermissionsForm, {
  NO_ROLES_ERROR,
} from './EventAdminPermissionsForm';
import { showNotification } from '../notification/notification-actions';

const EventAdminEdit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const userListPath = trimUriSegments(location.pathname, 1);

  let initialValues = {};

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const eventAdmin = useSelector(state =>
    getCurrentEventAdmin(state, { params }),
  );

  if (eventAdmin) {
    const existingRoles = {};

    eventAdmin.get('roles').forEach(role => {
      existingRoles[role] = true;
      // If section_edit is set, also set section_view to true for sections are expanded
      if (
        departmentTypeList.indexOf(role.substr(0, role.lastIndexOf('_'))) >
          -1 &&
        /_edit$/.test(role)
      ) {
        existingRoles[role.replace('_edit', '_view')] = true;
      }
    });

    initialValues = Object.assign(
      { roles: getDefaultRoleValues() },
      { roles: existingRoles },
    );
  }

  const handleSubmit = values => {
    const payload = recursiveTrim(values);
    payload.roles = convertRoleBooleansToRoleArray(payload.roles);

    if (payload.roles.length === 0) {
      return { [FORM_ERROR]: NO_ROLES_ERROR };
    }

    return dispatch(
      editEventUser(event.get('id'), eventAdmin.get('id'), payload),
    ).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      }
      dispatch(
        showNotification({
          message: 'Section admin successfully updated.',
          status: 'success',
        }),
      );
      navigate(userListPath);
    });
  };

  const renderUserPicture = () => {
    const picture = eventAdmin.getIn(['profile', 'picture']);
    return (
      <div className="user-info__profile__image">
        {picture ? (
          <img src={picture} alt="presentation" />
        ) : (
          <div>
            {`${eventAdmin.get('first_name').charAt(0)}${eventAdmin
              .get('last_name')
              .charAt(0)}`}
          </div>
        )}
      </div>
    );
  };

  const renderInviteStatus = () => {
    if (eventAdmin.get('last_login')) {
      return null;
    }

    return (
      <div className="user-info__profile__description__status">
        <span className="tag tag--invited">Invited</span>
        <span>
          {moment(eventAdmin.get('invite_sent_at')).format(
            'MM/DD/YYYY hh:mm A',
          )}
        </span>
        <button
          type="button"
          className="button button--plain"
          onClick={() =>
            dispatch(resendInvite(eventAdmin.get('id'))).then(action => {
              if (action.response.ok) {
                dispatch(
                  showNotification({
                    message: 'Section Admin invitation successfully sent',
                    status: 'success',
                  }),
                );
              }
            })
          }
        >
          Resend Invite
        </button>
      </div>
    );
  };

  const renderUserInfo = () => {
    return (
      <Paper>
        <PaperBody className="user-info paper">
          <Link className="user-info__back" to={userListPath}>
            <Icon icon="ArrowLeft" />
            <span>Back to Section Admins</span>
          </Link>
          <div className="user-info__profile">
            {renderUserPicture()}
            <div className="user-info__profile__description">
              <h3 className="user-info__profile__description__name">{`${eventAdmin.get(
                'first_name',
              )} ${eventAdmin.get('last_name')}`}</h3>
              <div className="user-info__profile__description__email">
                {eventAdmin.get('email')}
              </div>
              {renderInviteStatus()}
            </div>
          </div>
        </PaperBody>
      </Paper>
    );
  };

  if (!eventAdmin) {
    return null;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form className="generic-form paper__wrapper" onSubmit={handleSubmit}>
          {renderUserInfo()}
          <EventAdminPermissionsForm cancelLink={userListPath} />
        </form>
      )}
    />
  );
};

export default EventAdminEdit;
