import client from '../client';
import omit from 'lodash/omit';

export const GET_GROUP_CREDENTIALS = 'GET_GROUP_CREDENTIALS';
export function getGroupCredentials(token) {
  return {
    type: GET_GROUP_CREDENTIALS,
    promise: client.get(`group-credentials/${token}`),
  };
}

export const SUBMIT_GROUP_CREDENTIAL_LINK = 'SUBMIT_GROUP_CREDENTIAL_LINK';
export function submitGroupCredentialLink(departmentId, payload) {
  return {
    type: SUBMIT_GROUP_CREDENTIAL_LINK,
    promise: payload.department_id
      ? client.put(
          `departments/${departmentId}/group-credential-link`,
          omit(payload, 'department_id'),
        )
      : client.post(
          `departments/${departmentId}/group-credential-link`,
          omit(payload, 'department_id'),
        ),
  };
}

export const SEND_GROUP_CREDENTIAL_EMAIL = 'SEND_GROUP_CREDENTIAL_EMAIL';
export function sendGroupCredentialEmail(departmentId, payload) {
  return {
    type: SEND_GROUP_CREDENTIAL_EMAIL,
    promise: client.post(
      `departments/${departmentId}/group-credential-emails`,
      payload,
    ),
  };
}

export const GET_GROUP_CREDENTIAL_EMAILS = 'GET_GROUP_CREDENTIAL_EMAILS';
export function getGroupCredentialEmails(departmentId) {
  return {
    type: GET_GROUP_CREDENTIAL_EMAILS,
    promise: client.get(`departments/${departmentId}/group-credential-emails`),
  };
}

export const GET_GROUP_CREDENTIAL_LINK = 'GET_GROUP_CREDENTIAL_LINK';
export function getGroupCredentialLink(departmentId) {
  return {
    type: GET_GROUP_CREDENTIAL_LINK,
    promise: client.get(`departments/${departmentId}/group-credential-link`),
  };
}

export const GET_GROUP_CREDENTIAL_LINK_EMAIL_TEMPLATES =
  'GET_GROUP_CREDENTIAL_LINK_EMAIL_TEMPLATES';
export function getGroupCredentialLinkEmailTemplates(eventId, section) {
  return {
    type: GET_GROUP_CREDENTIAL_LINK_EMAIL_TEMPLATES,
    promise: client.get(
      `events/${eventId}/email/templates?emailType=group_credential_link&section=${section}`,
    ),
  };
}

export const SAVE_GROUP_CREDENTIAL_REQUESTS = 'SAVE_GROUP_CREDENTIAL_REQUESTS';
export function saveGroupCredentialRequests(departmentId, payload) {
  return {
    type: SAVE_GROUP_CREDENTIAL_REQUESTS,
    promise: client.put(
      `departments/${departmentId}/group-credential-requests`,
      payload,
    ),
  };
}
