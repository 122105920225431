import { useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import StatusButton from './StatusButton';

const FormStatusButton = ({
  buttonText = 'Save',
  completeText = 'Saved!',
  errorText = 'Error',
  enabledOnPristine = false,
  ...props
}) => {
  const {
    hasSubmitErrors,
    hasValidationErrors,
    modifiedSinceLastSubmit,
    pristine,
    submitting,
    submitFailed,
    submitSucceeded,
  } = useFormState({
    subscription: {
      hasSubmitErrors: true,
      hasValidationErrors: true,
      modifiedSinceLastSubmit: true,
      pristine: true,
      submitting: true,
      submitFailed: true,
      submitSucceeded: true,
    },
  });

  const disabled =
    (pristine && !enabledOnPristine) ||
    submitting ||
    (submitFailed && hasValidationErrors) ||
    (hasSubmitErrors && !modifiedSinceLastSubmit);

  const getStatus = () => {
    if (submitting) return 'loading';
    if (submitSucceeded && pristine) return 'success';
    if (submitFailed && hasValidationErrors) return 'error';
    if (hasSubmitErrors && !modifiedSinceLastSubmit) return 'error';
    return 'default';
  };

  return (
    <StatusButton
      {...props}
      type="submit"
      buttonText={buttonText}
      completeText={completeText}
      errorText={errorText}
      disabled={disabled}
      status={getStatus()}
    />
  );
};

FormStatusButton.propTypes = {
  buttonText: PropTypes.string,
  completeText: PropTypes.string,
  errorText: PropTypes.string,
  enabledOnPristine: PropTypes.bool,
};

export default FormStatusButton;
