import React from 'react';

function Ticket() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M15.58 16.8L12 14.5l-3.58 2.3 1.08-4.12-3.29-2.69 4.24-.25L12 5.8l1.54 3.95 4.24.25-3.29 2.69 1.09 4.11zM20 12a2.006 2.006 0 0 1 2-2V6a2.006 2.006 0 0 0-2-2H4a2 2 0 0 0-1.99 2v4A2 2 0 0 1 2 14v4a2.006 2.006 0 0 0 2 2h16a2.006 2.006 0 0 0 2-2v-4a2.006 2.006 0 0 1-2-2z" />
    </svg>
  );
  /* eslint-enable */
}

export default Ticket;
