import client from '../client';

export const GET_EVENT_RECONCILIATION = 'GET_EVENT_RECONCILIATION';
export function getEventReconciliation(eventId) {
  return {
    type: GET_EVENT_RECONCILIATION,
    promise: client.get(`events/${eventId}/pulse/orders/reconciliation`),
  };
}

export const CHECK_EVENT_RECONCILIATION = 'CHECK_EVENT_RECONCILIATION';
export function checkEventReconciliation(eventId) {
  return {
    type: CHECK_EVENT_RECONCILIATION,
    promise: client.get(`events/${eventId}/pulse/orders/check/reconciliation`),
  };
}
export const GET_LAST_TIME_RECONCILIATION_CHECK =
  'GET_LAST_TIME_RECONCILIATION_CHECK';
export function getLastimeReconciliationCheck(eventId) {
  return {
    type: GET_LAST_TIME_RECONCILIATION_CHECK,
    promise: client.get(
      `events/${eventId}/pulse/orders/reconciliation/last/time`,
    ),
  };
}

export const GET_DEPARTMENT_RECONCILIATION = 'GET_DEPARTMENT_RECONCILIATION';
export function getDepartmentReconciliation(departmentId) {
  return {
    type: GET_DEPARTMENT_RECONCILIATION,
    promise: client.get(
      `departments/${departmentId}/pulse/orders/reconciliation`,
    ),
  };
}

export const GET_DEPARTMENT_CONTACTS_RECONCILIATION =
  'GET_DEPARTMENT_CONTACTS_RECONCILIATION';
export function getDepartmentContactsReconciliation(
  eventId,
  departmentType,
  departmentId,
) {
  return {
    eventId,
    departmentType,
    departmentId,
    type: GET_DEPARTMENT_CONTACTS_RECONCILIATION,
    promise: client.get(
      `departments/${departmentId}/contacts/pulse/orders/reconciliation`,
    ),
  };
}

export const GET_CONTACT_RECONCILIATION = 'GET_CONTACT_RECONCILIATION';
export function getContactReconciliation(contactId) {
  return {
    type: GET_CONTACT_RECONCILIATION,
    promise: client.get(`contacts/${contactId}/pulse/orders/reconciliation`),
  };
}

export const RECONCILE_PULSE_ORDER = 'RECONCILE_PULSE_ORDER';
export function reconcilePulseOrder(strataOrder, actions) {
  return {
    type: RECONCILE_PULSE_ORDER,
    promise: client.post(
      `pulse/events/${strataOrder.pulse_event_id}/orders/${strataOrder.pulse_reference_id}/reconcile`,
      actions,
    ),
  };
}
