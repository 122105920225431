import { useCallback, useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { combineValidators, isRequired } from 'revalidate';
import { Map } from 'immutable';
import { includes } from 'lodash';
import moment from 'moment';
import LoadingIndicator from '../../common/LoadingIndicator';
import FullPageMessage from '../../common/FullPageMessage';
import Paper, { PaperHeader } from '../../common/paper/Paper';
import CheckboxBoolean from '../../common/forms/CheckboxBoolean';
import ReduxFormsField from '../../common/forms/ReduxFormsField';
import ComplexReduxFieldWrapper from '../../common/forms/ComplexReduxFieldWrapper';
import ReduxDatepickerField from '../../common/forms/ReduxDatepickerField';
import FormStatusButton from '../../common/FormStatusButton';
import AttendeeTypesField from './AttendeeTypesField';
import PassFulfillmentMegaField from './PassFulfillmentMegaField';
import validateAttendeeTypes from './validate-attendee-types';
import validatePassFulfillment from './validate-pass-fulfillment';

const validate = combineValidators({
  name: isRequired('Name'),
  attendee_types: validateAttendeeTypes,
  pass_fulfillment: validatePassFulfillment,
});

const CredentialTypeForm = ({
  disabledFields = [],
  editing = false,
  enabledSections,
  event,
  initialValues,
  onSubmit,
}) => {
  const params = useParams();

  const [displayString, setDisplayString] = useState();

  const periodList = event
    .get('periods')
    .sortBy(period => period.get('start_date'));

  const timezone = event?.get('time_zone') ?? 'US/Pacific';

  const backTo = `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/credentials/types`;

  const lockDateChange = useCallback(
    date => {
      if (date) {
        const dateString = moment
          .utc(date)
          .hour(23)
          .minute(59)
          .second(59)
          .tz(timezone)
          .format('HH:mm');
        setDisplayString(`${dateString} ${timezone}`);
      } else {
        setDisplayString(null);
      }
    },
    [timezone],
  );

  useEffect(() => {
    if (initialValues.lock_date) {
      lockDateChange(initialValues.lock_date);
    }
  }, [initialValues.lock_date, lockDateChange, timezone]);

  const renderAttendeeTypeSection = () => {
    return (
      <Field
        name="attendee_types"
        label="Sections"
        component={ComplexReduxFieldWrapper}
      >
        <AttendeeTypesField enabledSections={enabledSections} />
      </Field>
    );
  };

  const renderPassFulfillmentSection = () => {
    return (
      <Field
        name="pass_fulfillment"
        label="Item Fulfillment"
        component={ComplexReduxFieldWrapper}
        disabled={editing}
      >
        <PassFulfillmentMegaField periods={periodList} editing={editing} />
      </Field>
    );
  };

  if (!periodList) {
    return <LoadingIndicator />;
  }

  if (periodList.size === 0) {
    return (
      <FullPageMessage
        icon="Sad"
        message={
          "Cannot create a credential type because you haven't created any periods"
        }
      />
    );
  }

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
      {({ change, handleSubmit, submitting, values }) => (
        <form className="generic-form paper__wrapper" onSubmit={handleSubmit}>
          <Paper>
            <PaperHeader
              backTo={backTo}
              backToDisabled={submitting}
              title={`${editing ? 'Edit' : 'Add'} Credential Type`}
            />
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                <Field
                  name="name"
                  label="Credential Type Name"
                  component={ReduxFormsField}
                  required
                >
                  <input type="text" />
                </Field>
              </div>
              <div className="input-group input-group--small">
                <Field
                  name="lock_date"
                  label="Lock Date"
                  component={ReduxFormsField}
                >
                  <ReduxDatepickerField
                    dayChangeNotify={lockDateChange}
                    allowPastDates
                    displayString={displayString}
                    showClearDate
                  />
                </Field>

                <Field
                  name="access_type"
                  label="Festival Access"
                  component={ReduxFormsField}
                  required
                >
                  <select>
                    <option value="NO_FESTIVAL_ACCESS">
                      No Festival Access
                    </option>
                    <option value="FESTIVAL_NO_CATERING">
                      Festival Access, No Catering
                    </option>
                    <option value="FESTIVAL_WITH_CATERING">
                      Festival Access With Catering
                    </option>
                  </select>
                </Field>

                <Field
                  name="type"
                  disabled={includes(disabledFields, 'type')}
                  label="Type"
                  component={ReduxFormsField}
                  description="This indicates what type of physical item this credential represents."
                  required
                >
                  <select>
                    <option value="RFID">RFID</option>
                    <option value="BARCODE_REQUIRES_ACTIVATION">
                      Barcode (Requires Activation)
                    </option>
                    <option value="BARCODE">Barcode (PaH)</option>
                    <option value="UNTRACKED">Untracked</option>
                  </select>
                </Field>
              </div>
              <div className="input-group">
                <Field
                  name="group_level_auto_approve"
                  disabled={includes(
                    disabledFields,
                    'group_level_auto_approve',
                  )}
                  label="Auto-Approve Requests"
                  component={ReduxFormsField}
                  className="inline-checkbox"
                >
                  <CheckboxBoolean id="group_level_auto_approve" />
                </Field>
                <Field
                  name="group_level_available"
                  disabled={includes(disabledFields, 'group_level_available')}
                  label="Available for Department Level Credential Link"
                  component={ReduxFormsField}
                  onChange={() => {
                    if (values.groupLevelAvailable) {
                      change('group_level_auto_approve', false);
                    }
                  }}
                  className="inline-checkbox"
                >
                  <CheckboxBoolean id="group_level_available" />
                </Field>
              </div>
            </div>
          </Paper>

          {renderAttendeeTypeSection()}
          {renderPassFulfillmentSection()}

          <Paper>
            <div className="generic-form__footer">
              <FormStatusButton />
              <Link
                className="button button--plain"
                disabled={submitting}
                to={backTo}
              >
                Cancel
              </Link>
            </div>
          </Paper>
        </form>
      )}
    </Form>
  );
};

CredentialTypeForm.propTypes = {
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  editing: PropTypes.bool,
  enabledSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  event: PropTypes.instanceOf(Map).isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    lock_date: PropTypes.object,
    attendee_types: PropTypes.object.isRequired,
    access_type: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    pass_fulfillment: PropTypes.shape({
      issue_frequency: PropTypes.string.isRequired,
      periods: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CredentialTypeForm;
