import { useState } from 'react';
import { useFormState } from 'react-final-form';
import { Tooltip } from 'react-tooltip';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { has } from 'lodash';
import styled from 'styled-components';
import ExpandableRow from '../ExpandableRow';
import ApplicationSection from './ApplicationSection';
import Icon from '../icons/Icon';

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  align-items: center;
  > span.icon svg {
    fill: #e74c3c;
  }
`;

const SortableSection = ({ event, section }) => {
  const { errors } = useFormState();

  const [isExpanded, setIsExpanded] = useState(
    section.isCustom && section.isNew,
  );

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: section.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  let header = section.name ?? (section.isNew ? 'New Section' : null);
  header = (
    <span className="draggable-row-name">
      <div {...listeners}>
        <Icon icon="DragHandle" className="draggable" />
      </div>
      {header}
    </span>
  );

  const icons = has(errors, `sections.${section.key}`) ? (
    <IconContainer>
      <Tooltip
        id={`section-${section.key}-errors-tooltip`}
        offset={10}
        content={Object.values(errors.sections[section.key])[0]}
        style={{ fontSize: '14px', zIndex: '1' }}
      />
      <Icon
        icon="ErrorTriangle"
        dataTooltipId={`section-${section.key}-errors-tooltip`}
        style={{ pointerEvents: 'initial' }}
      />
    </IconContainer>
  ) : null;

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <ExpandableRow
        key={section.key}
        columns={[header, icons]}
        isExpanded={isExpanded}
        isExpandable
        onToggleExpansion={() => setIsExpanded(!isExpanded)}
        renderChildrenWhenNotExpanded={true}
      >
        <ApplicationSection event={event} section={section} />
      </ExpandableRow>
    </div>
  );
};

const SortableSectionList = ({ event, sections, onDragEnd }) => {
  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      onDragEnd={onDragEnd}
    >
      <SortableContext items={sections} strategy={verticalListSortingStrategy}>
        {sections.map(section => (
          <SortableSection key={section.key} event={event} section={section} />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default SortableSectionList;
