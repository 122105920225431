import React from 'react';

function MediaApplication() {
  /* eslint-disable max-len */
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2h-4.18C11.4.84 10.3 0 9 0 7.7 0 6.6.84 6.18 2H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM7.304 7.394h3.392l1.035 1.098h1.793c.622 0 1.13.494 1.13 1.097v6.585c0 .604-.508 1.098-1.13 1.098H4.477c-.622 0-1.13-.494-1.13-1.098V9.59c0-.604.508-1.098 1.13-1.098H6.27l1.034-1.098zm3.505 5.488c0-1.17-.604-1.756-1.81-1.756s-1.81.585-1.81 1.756c0 1.17.604 1.756 1.81 1.756s1.81-.585 1.81-1.756zM9 15.626c-1.56 0-2.827-1.23-2.827-2.744 0-1.515 1.267-2.744 2.827-2.744s2.827 1.23 2.827 2.744c0 1.515-1.267 2.744-2.827 2.744z" />
    </svg>
  );
  /* eslint-enable */
}

export default MediaApplication;
