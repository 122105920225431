import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { wrap } from 'fuzzaldrin-plus';
import { departmentFormatter } from '../../common/departmentFormatter';
import { getSearchDepartmentSlugs } from '../../search-selectors';
import { PersonIcon, DepartmentIcon } from '../../common/SearchIcons';
import { Container, Row, Name, Description } from './Result.styles';
import PropTypes from 'prop-types';

export const Result = ({ data, inputValue, handleClose }) => {
  const params = useParams();
  const navigate = useNavigate();
  const departmentSlugs = useSelector(getSearchDepartmentSlugs);

  const { id, table_name, department, type } = data;

  const pathname = `/promoters/${params.promoterSlug}/festivals/${
    params.festivalSlug
  }/events/${params.eventSlug}/${type}/${departmentSlugs.get(
    department,
  )}/contacts/list`;

  const navigateToResult = () => {
    handleClose();
    navigate({
      pathname,
      search:
        table_name === 'person' ? `?contact=${encodeURIComponent(id)}` : null,
    });
  };

  return (
    <div
      onMouseDown={e => {
        e.stopPropagation();
        navigateToResult();
      }}
    >
      {table_name === 'person' && (
        <PersonResult data={data} inputValue={inputValue} />
      )}
      {table_name === 'department' && (
        <DepartmentResult data={data} inputValue={inputValue} />
      )}
    </div>
  );
};

Result.propTypes = {
  data: PropTypes.object.isRequired,
  inputValue: PropTypes.string,
  handleClose: PropTypes.func,
};

const PersonResult = ({
  data: { department, type, first_name, last_name },
  inputValue,
}) => {
  const fuzzyName = wrap(`${first_name} ${last_name}`, inputValue);
  return (
    <Container>
      <Row>
        <PersonIcon />
        <Name>
          <span
            dangerouslySetInnerHTML={{
              __html: `${fuzzyName}`,
            }}
          />
        </Name>
      </Row>
      <Description>{`${department}, ${departmentFormatter(
        type,
      )} `}</Description>
    </Container>
  );
};

const DepartmentResult = ({ data: { department, type }, inputValue }) => {
  const fuzzyName = wrap(`${department}`, inputValue);
  return (
    <Container>
      <Row>
        <DepartmentIcon />
        <Name>
          <span
            dangerouslySetInnerHTML={{
              __html: fuzzyName,
            }}
          />
        </Name>
      </Row>
      <Description>{`${departmentFormatter(type)}`}</Description>
    </Container>
  );
};
