import styled, { css, keyframes } from 'styled-components';

const shrinkIcon = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(.85);
  }
`;

const animation = () => css`
  ${shrinkIcon} 0.2s linear forwards;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
`;

export const Search = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  > span {
    height: 25px;
    width: 25px;
  }
  svg {
    fill: #b1b1b1;
  }
  transition: width 0.3s ease-in-out;
  ${({ $isOpen }) =>
    $isOpen
      ? `
      width: 300px; 
      padding-left: 10px; 
      border-radius: 0; 
      border: 2px solid #dddddd;
    `
      : `
      &:active {
        animation: ${animation};
      }
      `};
  ${({ $isOpen, $animationOpen }) =>
    !$isOpen && !$animationOpen
      ? `
        &:hover {
        opacity: .57;
        background-color: #446cb3;
        svg {
          fill: #fff;
        }
      }
`
      : null}
`;
