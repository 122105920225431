import styled, { keyframes } from 'styled-components';

const buttonPress = keyframes`
  from {
    opacity: .95;
  }
  to {
    opacity: .90;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 8px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #446cb3;
  opacity: 0.75;
  height: 30px;
  width: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-left: 8px;
  &:hover,
  &:active {
    color: #fff;
    background-color: #446cb3;
    opacity: 0.95;
  }
  &:active {
    animation: ${buttonPress} 0.2s linear forwards;
  }
  ${({ isCurrentPage }) => {
    if (isCurrentPage) {
      return `
      color: #fff;
      background-color: #446cb3;
      opacity: .90;
    `;
    }
  }};
`;
