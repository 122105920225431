import { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from './icons/Icon';

const KebabMenu = ({ children, isMenuActive, onMenuToggle }) => {
  const [isActive, setIsActive] = useState(false);

  const menuToggle = () => {
    if (typeof onMenuToggle === 'function') {
      return onMenuToggle();
    }
    setIsActive(!isActive);
  };

  const menuActive = () => {
    if (typeof isMenuActive !== 'undefined') {
      return isMenuActive;
    }
    return isActive;
  };

  if (!children || (Array.isArray(children) && !children.length)) {
    return null;
  }

  return (
    <div className="menu">
      <button className="menu--icon" onClick={menuToggle} type="button">
        <Icon icon="Kebab" />
      </button>
      {menuActive() && children ? (
        <div className="menu--dropdown">
          {children}
          <button className="underlay" onClick={menuToggle} type="button" />
        </div>
      ) : null}
    </div>
  );
};

KebabMenu.propTypes = {
  children: PropTypes.node.isRequired,
  isMenuActive: PropTypes.bool,
  onMenuToggle: PropTypes.func,
};

export default KebabMenu;
