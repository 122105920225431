import { Map } from 'immutable';
import PropTypes from 'prop-types';
import CropperScaffold from '../forms/CropperScaffold';
import DropNCrop from '../forms/DropNCrop';

const ApplicationImageUpload = ({ image, description, value, onChange }) => {
  const isOriginalImage = image?.get('id') === value;

  const renderUploadField = () => {
    if (isOriginalImage) {
      return (
        <CropperScaffold>
          <div className="original-image">
            <img alt="application upload" src={image.get('location')} />
          </div>
        </CropperScaffold>
      );
    }

    return (
      <DropNCrop
        value={typeof value === 'string' ? {} : value}
        onChange={onChange}
      />
    );
  };

  const isRemoveButtonEnabled = () => isOriginalImage || Boolean(value);

  const removeFile = () => {
    onChange(null);
  };

  return (
    <>
      <p>{description}</p>
      <div className="generic-form__body">{renderUploadField()}</div>
      <div className="generic-form__footer">
        <button
          className="button"
          disabled={!isRemoveButtonEnabled()}
          type="button"
          onClick={removeFile}
        >
          Remove
        </button>
      </div>
    </>
  );
};

ApplicationImageUpload.propTypes = {
  image: PropTypes.instanceOf(Map),
  description: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default ApplicationImageUpload;
