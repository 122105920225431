function IssuedCredential() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M 10 1 C 9.448 1 9 1.448 9 2 L 9 5 L 13 5 L 13 2 C 13 1.448 12.552 1 12 1 L 10 1 z M 11 2 C 11.552 2 12 2.448 12 3 C 12 3.552 11.552 4 11 4 C 10.448 4 10 3.552 10 3 C 10 2.448 10.448 2 11 2 z M 5 3 C 3.898 3 3 3.897 3 5 L 3 20 C 3 21.103 3.898 22 5 22 L 11 22 L 9 20 L 5 20 L 5 5 L 8 5 L 8 3 L 5 3 z M 14 3 L 14 5 L 17 5 L 17 18 L 19 16 L 19 5 C 19 3.897 18.102 3 17 3 L 14 3 z M 11 8 C 9.8954305 8 9 8.8954305 9 10 C 9 11.104569 9.8954305 12 11 12 C 12.104569 12 13 11.104569 13 10 C 13 8.8954305 12.104569 8 11 8 z M 11 13 C 8.359 13 7 15.1875 7 15.1875 L 7 16 L 15 16 L 15 15.1875 C 15 15.1875 13.641 13 11 13 z M 22.28125 15.40625 L 16.125 21.5625 L 13.71875 19.1875 L 12.28125 20.59375 L 15.40625 23.71875 L 16.125 24.40625 L 16.84375 23.71875 L 23.71875 16.84375 L 22.28125 15.40625 z" />
    </svg>
  );
  /* eslint-enable */
}

export default IssuedCredential;
