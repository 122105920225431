import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { removeLastUriSegment } from '../common/uri';
import Icon from '../common/icons/Icon';
import Paper, { PaperHeader } from '../common/paper/Paper';
import LoadingIndicator from '../common/LoadingIndicator';
import ReactTable from '../lib/react-table';
import ContactDetails from '../contact/ContactDetails';

const InternApprovalQueue = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const requestList = useSelector(state =>
    state.intern.applicationRequests.get('data'),
  );

  const [showTableFilter, setShowTableFilter] = useState(false);

  const toggleTableFilter = e => {
    e.preventDefault();
    setShowTableFilter(!showTableFilter);
  };

  const onClickRow = row => {
    navigate(
      `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/approvals/intern/${row._original.slug}`,
    );
  };

  const renderRequestList = () => {
    const data = requestList
      .filter(
        department =>
          !!department.get('application_id') &&
          !department.get('contacts').isEmpty(),
      )
      .map(department => {
        const contact = department.getIn(['contacts', 0]);

        return {
          slug: department.get('slug'),
          name: `${contact.get('first_name')} ${contact.get('last_name')}`,
          contactId: contact.get('id'),
          credentials: contact.get('credentialRequests').size,
          status: department.get('status'),
          departmentId: department.get('id'),
        };
      })
      .toJS();

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
            placeholder="Search by name"
          />
        ),
      },
      {
        Header: 'Credentials',
        accessor: 'credentials',
        Cell: ({ value }) => `${value} Assigned`,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <span className={`status-${value}`}>
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </span>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            required
          >
            <option value="">Select a status</option>
            {['pending', 'rejected', 'approved'].map(status => (
              <option value={status} key={status}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </option>
            ))}
          </select>
        ),
      },
    ];

    return (
      <ReactTable
        className="department-list"
        data={data}
        columns={columns}
        defaultSorted={[
          {
            id: 'name',
            asc: true,
          },
        ]}
        minRows={0}
        defaultPageSize={20}
        showPagination={data.length > 20}
        showPageSizeOptions={false}
        filterable={showTableFilter}
        getTrGroupProps={(_state, rowInfo) => ({
          onClick: () => onClickRow(rowInfo.row),
        })}
        NoDataComponent={() => (
          <div className="generic-list--empty">
            <Icon icon="Sad" />
            <p>There are no results to display.</p>
          </div>
        )}
      />
    );
  };

  const renderSelectedIntern = () => {
    if (!params.departmentSlug || !requestList) return null;

    const department = requestList.find(
      department => department.get('slug') === params.departmentSlug,
    );

    if (!department) return null;

    const contact = department.get('contacts').first();

    return (
      <ContactDetails
        backTo={removeLastUriSegment(location.pathname)}
        department={department}
        contact={contact}
        onInternApprovalPage
      />
    );
  };

  const actions = [];
  if (requestList && requestList.size) {
    actions.push(
      <button
        key="add-department-filter-action"
        className="button button--plain button--icon"
        onClick={toggleTableFilter}
      >
        <Icon icon="Filter" />
        <span>{showTableFilter ? 'Clear' : 'Advanced'} Filter</span>
      </button>,
    );
  }

  return (
    <div className="approval-interns__wrapper">
      <Paper>
        <PaperHeader title="Intern Requests" actions={actions} />
        {!requestList ? (
          <LoadingIndicator />
        ) : requestList.isEmpty() ? (
          <div className="generic-list--empty">
            <Icon icon="Sad" />
            <p>You have no interns added yet for this section.</p>
          </div>
        ) : (
          renderRequestList()
        )}
      </Paper>
      {renderSelectedIntern()}
    </div>
  );
};

export default InternApprovalQueue;
