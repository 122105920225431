import { Field } from 'react-final-form';
import { List, Map } from 'immutable';
import { isRequired } from 'revalidate';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import PropTypes from 'prop-types';
import ReduxFormsField from '../../forms/ReduxFormsField';

const draftRenderer = new Renderer(defaultBlocks);

const MediaOutletTypeSection = ({ className, section, mediaTypes }) => (
  <section className={className}>
    <header>
      <div className={`${className}--title`}>Media Outlet Type</div>
    </header>

    <article>
      <div className={`${className}--desc`}>
        <div
          className="draftjs-output"
          dangerouslySetInnerHTML={{
            __html: draftRenderer.convertRawToHTML(
              section.get('content').toJS(),
            ),
          }}
        />
      </div>

      <Field
        name="type"
        component={ReduxFormsField}
        label="Media Outlet Type"
        required
        validate={isRequired('Media Outlet Type')}
      >
        <select>
          <option value="">Select Type</option>
          {mediaTypes &&
            mediaTypes
              .map(subtype => (
                <option value={subtype} key={subtype}>
                  {subtype}
                </option>
              ))
              .toJS()}
        </select>
      </Field>
    </article>
  </section>
);

MediaOutletTypeSection.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
  mediaTypes: PropTypes.instanceOf(List).isRequired,
};

export default MediaOutletTypeSection;
