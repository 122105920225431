import { jwtDecode } from 'jwt-decode';
import { roleConstants } from '../common/utils/acl';
import storage from 'store2';

const authHelpers = {
  getDecodedJwt() {
    const idToken = storage.session('idToken');
    if (!idToken) {
      return null;
    }

    return jwtDecode(idToken);
  },

  userIsStrataAdmin(user) {
    return user?.getIn(['permissionsMap', 'strataAdmin']) ?? false;
  },

  userIsPromoterAdmin(user, promoterId) {
    return (
      user
        ?.getIn(['permissionsMap', 'promoterMap', String(promoterId)])
        ?.includes(roleConstants.PROMOTER_ADMIN) ?? false
    );
  },

  userIsEventAdmin(user, eventId) {
    return (
      user
        ?.getIn(['permissionsMap', 'eventMap', String(eventId)])
        ?.includes(roleConstants.EVENT_ADMIN) ?? false
    );
  },

  userIsEventCoordinator(user, eventId) {
    return (
      user
        ?.getIn(['permissionsMap', 'eventMap', String(eventId)])
        ?.includes(roleConstants.EVENT_COORDINATOR) ?? false
    );
  },

  userHasSectionRole(user, eventId, section, roleSuffix) {
    return (
      user
        ?.getIn(['permissionsMap', 'eventMap', String(eventId)])
        ?.includes(`${section}_${roleSuffix}`) ?? false
    );
  },
};

export default authHelpers;
