import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { components } from 'react-select';
import { composeValidators, isRequired } from 'revalidate';
import { is, List, Map as ImmutableMap, Set } from 'immutable';
import { defer, memoize } from 'lodash';
import { Checkbox } from 'synfrastructure';
import { StyledButton } from '../common/StyledButton';
import { getStatusForButton } from '../common/forms/FormHelper';
import { showNotification } from '../notification/notification-actions';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import {
  getCategories,
  getCredentialTypesList,
} from '../credential/type/credential-type-selectors';
import { requestEditContact } from './onsite-actions';
import {
  exceedMaximumByAmount,
  formatDate,
  getCredentialDateRange,
  getCredentialStartDate,
  getPeriodDateRange,
} from '../common/utils/credentials/credential-utils';
import moment from 'moment';
import styled from 'styled-components';
import isValidEmail from '../common/validate/is-valid-email';
import PropTypes from 'prop-types';
import Tag from '../common/Tag';
import Icon from '../common/icons/Icon';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import StatusButton from '../common/StatusButton';
import InputScaffold from '../common/forms/InputScaffold';
import ExpandableRow from '../common/ExpandableRow';
import ToggleScaffold from '../common/forms/ToggleScaffold';
import ReduxReactSelectField from '../common/forms/ReduxReactSelectField';
import RequestStatusIndicator from '../contact/RequestStatusIndicator';
import SelectContact from './SelectContact';

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  align-items: center;
`;

const Option = props => (
  <components.Option {...props}>
    <div>
      <span
        className="credential-category-color"
        style={{ backgroundColor: `${props.data.color}` }}
      />
      <span>{props.data.label.toUpperCase()}</span>
    </div>
  </components.Option>
);

const SingleValue = props => (
  <components.SingleValue {...props}>
    <div>
      <span
        className="credential-category-color"
        style={{ backgroundColor: `${props.data.color}` }}
      />
      <span>{props.data.label.toUpperCase()}</span>
    </div>
  </components.SingleValue>
);

const EditContactForm = ({
  department,
  eventOnsiteRequestTypes,
  filterCredentialsByPeriod = false,
  requireContactEmails = false,
  overrideCredentialRequestMaximums = false,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  const [contact, setContact] = useState(null);
  const [showAddCredentialForm, setShowAddCredentialForm] = useState(false);
  const [periodOptions, setPeriodOptions] = useState(null);
  const [categoryOptions, setCategoryOptions] = useState(null);
  const [credentialTypeOptions, setCredentialTypeOptions] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedCredentialCategory, setSelectedCredentialCategory] =
    useState(null);
  const [selectedCredentialType, setSelectedCredentialType] = useState(null);
  const [availableCredentials, setAvailableCredentials] = useState(null);
  const [selectedCredentials, setSelectedCredentials] = useState(List());
  const [credentialsToAdd, setCredentialsToAdd] = useState(Set());

  const nameInputRef = useRef(null);
  const selectCategoryRef = useRef(null);
  const selectCredentialTypeRef = useRef(null);
  const setFocus = ref => defer(() => ref.current?.focus());

  const initialValues = {
    email: contact?.get('email') ?? '',
    notes: '',
  };

  const categories = useSelector(getCategories);
  const eventCredentialTypes = useSelector(getCredentialTypesList);

  const departmentCredentialTypes = useMemo(() => {
    const enabledCredentialTypeIds = department
      .get('credentialTypes')
      .filter(
        credentialType => !credentialType.get('attendeePickupTypes').isEmpty(),
      )
      .map(credentialType => credentialType.get('id'));

    return eventCredentialTypes.filter(credentialType =>
      enabledCredentialTypeIds.includes(credentialType.get('id')),
    );
  }, [department, eventCredentialTypes]);

  const _credentialInfo = useMemo(() => {
    const getPeriodEntry = (entry, period) => {
      let periodEntry = entry.periods[period.get('id')];

      if (!periodEntry) {
        periodEntry = {
          id: period.get('id'),
          name: period.get('name'),
          startDate: moment(period.get('start_date')),
          categories: {},
        };
        entry.periods[period.get('id')] = periodEntry;
      }

      return periodEntry;
    };

    const getCategoryEntry = (entry, category) => {
      let categoryEntry = entry.categories[category.get('id')];

      if (!categoryEntry) {
        categoryEntry = {
          id: category.get('id'),
          name: category.get('name'),
          color: category.get('color'),
          credentialTypes: {},
        };
        entry.categories[category.get('id')] = categoryEntry;
      }

      return categoryEntry;
    };

    const getCredentialTypeEntry = (entry, credentialType) => {
      let credentialTypeEntry = entry.credentialTypes[credentialType.get('id')];

      if (!credentialTypeEntry) {
        credentialTypeEntry = {
          id: credentialType.get('id'),
          name: credentialType.get('name'),
          issueFrequency: credentialType.get('issue_frequency'),
          credentials: [],
        };
        entry.credentialTypes[credentialType.get('id')] = credentialTypeEntry;
      }

      return credentialTypeEntry;
    };

    const addCredentialEntry = (
      entry,
      category,
      credentialType,
      credential,
    ) => {
      const categoryEntry = getCategoryEntry(entry, category);
      const credentialTypeEntry = getCredentialTypeEntry(
        categoryEntry,
        credentialType,
      );
      credentialTypeEntry.credentials.push(credential);
    };

    let credentialInfo = {
      periods: {},
      categories: {},
    };

    departmentCredentialTypes.forEach(credentialType => {
      const category = categories[credentialType.get('category_id')];
      const issueFrequency = credentialType.get('issue_frequency');

      const credentials = credentialType.get('credentials');
      if (!credentials) return;

      const now = moment.utc();
      let credentialTypeAdded = false;

      credentials.forEach(credential => {
        let addCredential = false;

        if (issueFrequency === 'ONE_TIME') {
          credential.get('oneTimePeriods').forEach(period => {
            if (now.isAfter(period.get('end_date'), 'day')) return;

            if (filterCredentialsByPeriod) {
              const periodEntry = getPeriodEntry(credentialInfo, period);
              addCredentialEntry(
                periodEntry,
                category,
                credentialType,
                credential,
              );
              credentialTypeAdded = true;
            } else {
              addCredential = true;
            }
          });
        } else {
          const period = credential.get('period');

          if (now.isAfter(period.get('end_date'), 'day')) return;

          if (filterCredentialsByPeriod) {
            const periodEntry = getPeriodEntry(credentialInfo, period);
            addCredentialEntry(
              periodEntry,
              category,
              credentialType,
              credential,
            );
            credentialTypeAdded = true;
          } else {
            addCredential = true;
          }
        }

        if (addCredential) {
          addCredentialEntry(
            credentialInfo,
            category,
            credentialType,
            credential,
          );
          credentialTypeAdded = true;
        }
      });

      if (credentialTypeAdded & filterCredentialsByPeriod) {
        credentialInfo.categories[category.get('id')] = {
          id: category.get('id'),
          name: category.get('name'),
          color: category.get('color'),
        };
      }
    });

    return credentialInfo;
  }, [categories, departmentCredentialTypes, filterCredentialsByPeriod]);

  const initialCredentialsToAdd = useMemo(() => {
    let credentialsToAdd = Set();

    if (contact) {
      const allowedCredentials = {};
      const now = moment.utc();

      eventCredentialTypes.forEach(credentialType =>
        credentialType.get('credentials').forEach(credential => {
          if (
            (credentialType.get('issue_frequency') === 'ONE_TIME' &&
              !credential
                .get('oneTimePeriods')
                .some(period =>
                  now.isSameOrBefore(period.get('end_date'), 'day'),
                )) ||
            now.isAfter(credential.getIn(['period', 'end_date']), 'day')
          ) {
            return;
          }

          allowedCredentials[credential.get('id')] = credential;
        }),
      );

      const credentialRequests = contact.get('credentialRequests');

      if (credentialRequests) {
        credentialRequests.forEach(credentialRequest => {
          const credential =
            allowedCredentials[credentialRequest.get('credential_id')];
          if (
            credential &&
            credentialRequest.get('quantity_approved') +
              credentialRequest.get('quantity_pending') >
              0
          )
            credentialsToAdd = credentialsToAdd.add(credential);
        });
      }

      const onsiteRequests = contact.get('onsiteRequests');

      if (onsiteRequests) {
        const pendingOnsiteRequest = onsiteRequests.find(
          onsiteRequest => onsiteRequest.get('status') === 'Pending',
        );
        if (pendingOnsiteRequest) {
          pendingOnsiteRequest
            .get('credentialRequests')
            .forEach(credentialRequest => {
              const credential =
                allowedCredentials[credentialRequest.get('credential_id')];
              if (!credential) return;
              if (credentialRequest.get('action') === 'Delete')
                credentialsToAdd = credentialsToAdd.remove(credential);
              else credentialsToAdd = credentialsToAdd.add(credential);
            });
        }
      }
    }

    return credentialsToAdd;
  }, [contact, eventCredentialTypes]);

  useEffect(() => {
    setFocus(nameInputRef);
  }, []);

  useEffect(() => {
    setCredentialsToAdd(initialCredentialsToAdd);
  }, [initialCredentialsToAdd]);

  useEffect(() => {
    if (periodOptions?.length === 1) selectPeriod(periodOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodOptions]);

  useEffect(() => {
    if (categoryOptions?.length === 1)
      selectCredentialCategory(categoryOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryOptions]);

  useEffect(() => {
    if (credentialTypeOptions?.length === 1)
      selectCredentialType(credentialTypeOptions[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentialTypeOptions]);

  if (!department) {
    return null;
  }

  const handleSubmit = values => {
    const existingCredentials = Set(initialCredentialsToAdd);

    const deletedCredentials = existingCredentials
      .subtract(credentialsToAdd)
      .map(credential => ({
        id: credential.get('id'),
      }))
      .toArray();

    const newCredentials = credentialsToAdd
      .subtract(existingCredentials)
      .map(credential => ({
        id: credential.get('id'),
        quantity: 1,
      }))
      .toArray();

    const credentials = {
      add: newCredentials,
      delete: deletedCredentials,
    };

    return dispatch(
      requestEditContact(
        department,
        contact,
        values.email,
        credentials,
        values.notes,
      ),
    ).then(action => {
      onSubmit();

      const contactDisplayName = contact
        ? `${contact.get('first_name')} ${contact.get('last_name')}`
        : '';

      if (action.response.ok) {
        const requestType = eventOnsiteRequestTypes.find(
          type => type.get('onsite_request_type') === 'Modify Person',
        );
        if (
          requestType.get('auto_approve') &&
          !requestType.get('send_reviewed_email')
        ) {
          dispatch(
            showNotification({
              message: `${contactDisplayName} successfully modified and all requested credentials approved and/or deleted.`,
              status: 'success',
            }),
          );
        } else {
          dispatch(
            showNotification({
              message: `Request to modify ${contactDisplayName} has been received.  We will notify you when it is approved.`,
              status: 'success',
            }),
          );
        }
      } else {
        dispatch(
          showNotification({ message: action.json.error, status: 'error' }),
        );

        return formatValidationErrors(action.json).toJS();
      }
    });
  };

  const canSubmit = () => !is(credentialsToAdd, initialCredentialsToAdd);

  const renderCredentialStatus = credential => {
    const credentialRequests = contact.get('credentialRequests');

    if (credentialRequests) {
      const credentialRequest = credentialRequests.find(
        credentialRequest =>
          credentialRequest.get('credential_id') === credential.get('id'),
      );
      if (!credentialRequest) return null;
      if (credentialRequest.get('quantity_approved') > 0)
        return <RequestStatusIndicator status="approved" />;
      return <RequestStatusIndicator status="pending" />;
    }

    return null;
  };

  const canRemoveCredential = memoize(credential => {
    let pulseOrders = department.get('pulseOrders');
    if (!pulseOrders) return true;

    pulseOrders = pulseOrders.filter(
      pulseOrder => pulseOrder.get('contact_id') === contact.get('id'),
    );
    if (!pulseOrders || !pulseOrders.size) return true;

    return !pulseOrders.some(pulseOrder => {
      const pulseTags = pulseOrder.get('pulseTags');
      return (
        pulseTags &&
        pulseTags.some(
          pulseTag =>
            pulseTag.get('pulse_event_id') ===
              credential.get('pulse_event_id') &&
            pulseTag.get('pulse_credential_id') ===
              credential.get('pulse_credential_id'),
        )
      );
    });
  });

  const getCredentialInfo = periodId =>
    filterCredentialsByPeriod
      ? _credentialInfo.periods[periodId ?? selectedPeriod?.id]
      : _credentialInfo;

  const getPeriodOptions = () => {
    if (!filterCredentialsByPeriod) return [];

    return Object.values(_credentialInfo.periods)
      .filter(period =>
        Object.values(period.categories).some(
          category =>
            Object.values(category.credentialTypes).filter(credentialTypeFilter)
              .length > 0,
        ),
      )
      .sort((period1, period2) =>
        period1.startDate.isSameOrBefore(period2.startDate) ? -1 : 1,
      )
      .map(period => ({
        id: period.id,
        value: period.id,
        label: period.name,
      }));
  };

  const getCategoryOptions = (selectedPeriodId = null) => {
    const categories = getCredentialInfo(selectedPeriodId).categories;

    return Object.values(categories)
      .filter(
        category =>
          Object.values(category.credentialTypes).filter(credentialTypeFilter)
            .length > 0,
      )
      .sort((category1, category2) =>
        category1.name.localeCompare(category2.name),
      )
      .map(category => ({
        id: category.id,
        value: category.id,
        label: category.name,
        color: category.color,
      }));
  };

  const getCredentialTypeOptions = selectedCategoryId => {
    const credentialTypes =
      getCredentialInfo().categories[selectedCategoryId].credentialTypes;

    return Object.values(credentialTypes)
      .filter(credentialTypeFilter)
      .sort((credentialType1, credentialType2) =>
        credentialType1.name.localeCompare(credentialType2),
      )
      .map(credentialType => ({
        id: credentialType.id,
        value: credentialType.id,
        label: credentialType.name,
        issueFrequency: credentialType.issueFrequency,
        credentials: credentialType.credentials,
      }));
  };

  const getCategory = credential =>
    categories[credential.getIn(['credentialType', 'category_id'])];

  const selectPeriod = selectedPeriod => {
    setSelectedPeriod(selectedPeriod ?? null);
    setSelectedCredentialCategory(null);
    setSelectedCredentialType(null);
    setSelectedCredentials(List());
    setAvailableCredentials(null);
    setCategoryOptions(
      selectedPeriod ? getCategoryOptions(selectedPeriod.id) : null,
    );

    if (selectedPeriod) setFocus(selectCategoryRef);
  };

  const selectCredentialCategory = selectedCredentialCategory => {
    setSelectedCredentialCategory(selectedCredentialCategory ?? null);
    setSelectedCredentialType(null);
    setSelectedCredentials(List());
    setAvailableCredentials(null);
    setCredentialTypeOptions(
      selectedCredentialCategory
        ? getCredentialTypeOptions(selectedCredentialCategory.id)
        : null,
    );

    if (selectedCredentialCategory) setFocus(selectCredentialTypeRef);
  };

  const selectCredentialType = selectedCredentialType => {
    let availableCredentials = null;

    if (selectedCredentialType) {
      availableCredentials = selectedCredentialType.credentials
        .filter(credential => !credentialsToAdd.has(credential))
        .filter(credential =>
          overrideCredentialRequestMaximums
            ? true
            : exceedMaximumByAmount(1, department, credential.get('id'))
                .available >= 0,
        )
        .sort((credential1, credential2) =>
          moment(getCredentialStartDate(credential1)).isSameOrBefore(
            moment(getCredentialStartDate(credential2)),
          )
            ? -1
            : 1,
        );
    }

    setSelectedCredentialType(selectedCredentialType);
    setAvailableCredentials(List(availableCredentials));
    setSelectedCredentials(
      availableCredentials?.length === 1 ? List(availableCredentials) : List(),
    );
  };

  const selectCredential = selectedCredential => {
    if (isCredentialSelected(selectedCredential)) {
      setSelectedCredentials(prevSelectedCredentials =>
        prevSelectedCredentials.delete(
          prevSelectedCredentials.indexOf(selectedCredential),
        ),
      );
    } else {
      setSelectedCredentials(prevSelectedCredentials =>
        prevSelectedCredentials.push(selectedCredential),
      );
    }
  };

  const isCredentialSelected = credential =>
    selectedCredentials.includes(credential);

  const displayAddCredentialForm = () => {
    setShowAddCredentialForm(true);

    setPeriodOptions(filterCredentialsByPeriod ? getPeriodOptions() : null);

    setCategoryOptions(
      filterCredentialsByPeriod && !selectedPeriod
        ? null
        : getCategoryOptions(),
    );
  };

  const addCredential = () => {
    setShowAddCredentialForm(false);
    setPeriodOptions(null);
    setCategoryOptions(null);
    setCredentialTypeOptions(null);
    setSelectedCredentialCategory(null);
    setSelectedCredentialType(null);
    setAvailableCredentials(null);
    setSelectedCredentials(List());
    setCredentialsToAdd(prevCredentialsToAdd =>
      prevCredentialsToAdd.union(selectedCredentials),
    );
  };

  const cancelAddCredential = () => {
    setShowAddCredentialForm(false);
    setPeriodOptions(null);
    setCategoryOptions(null);
    setCredentialTypeOptions(null);
    setSelectedCredentialCategory(null);
    setSelectedCredentialType(null);
    setAvailableCredentials(null);
    setSelectedCredentials(List());
  };

  const removeCredential = credential => {
    setCredentialsToAdd(prevCredentialsToAdd =>
      prevCredentialsToAdd.remove(credential),
    );
  };

  const credentialTypeFilter = credentialType => {
    if (
      !overrideCredentialRequestMaximums &&
      credentialType.credentials.some(
        credential =>
          exceedMaximumByAmount(1, department, credential.get('id'))
            .maximumExceededBy > 0,
      )
    )
      return false;

    return Set(credentialType.credentials).subtract(credentialsToAdd).size > 0;
  };

  const canRequestCredential = () => {
    if (filterCredentialsByPeriod) {
      return Object.values(_credentialInfo.periods).some(period =>
        Object.values(period.categories).some(
          category =>
            Object.values(category.credentialTypes).filter(credentialTypeFilter)
              .length > 0,
        ),
      );
    }

    return Object.values(_credentialInfo.categories).some(
      category =>
        Object.values(category.credentialTypes).filter(credentialTypeFilter)
          .length > 0,
    );
  };

  const renderOneTimeCredential = () => {
    const now = moment.utc();

    const toggles = [];
    availableCredentials.forEach(credential => {
      const periods = credential
        .get('oneTimePeriods')
        .filter(period =>
          filterCredentialsByPeriod
            ? period.get('id') === selectedPeriod.id
            : now.isSameOrBefore(period.get('end_date'), 'day'),
        )
        .sortBy(period => period.get('start_date'));

      periods.forEach((period, index) => {
        toggles.push({
          index,
          credential,
          period,
        });
      });
    });

    return (
      <InputScaffold label="When" required>
        {toggles.map(toggle => (
          <ToggleScaffold
            key={toggle.period.get('id')}
            label={`${toggle.period.get('name')} (${getPeriodDateRange(
              toggle.period,
            )})`}
          >
            {toggle.index === 0 && (
              <Checkbox
                id={`${toggle.credential.get('id')}`}
                checked={isCredentialSelected(toggle.credential)}
                onChange={() => selectCredential(toggle.credential)}
              />
            )}
            {toggle.index !== 0 && <span style={{ width: '48px' }} />}
          </ToggleScaffold>
        ))}
      </InputScaffold>
    );
  };

  const renderPeriodicCredentials = () => {
    return (
      <div>
        <InputScaffold label="When" required>
          {availableCredentials.map(credential => (
            <ToggleScaffold
              key={credential.get('period_id')}
              label={`${credential.getIn([
                'period',
                'name',
              ])} (${getPeriodDateRange(credential.get('period'))})`}
            >
              <Checkbox
                id={`${credential.get('id')}`}
                checked={isCredentialSelected(credential)}
                onChange={() => selectCredential(credential)}
              />
            </ToggleScaffold>
          ))}
        </InputScaffold>
      </div>
    );
  };

  const renderDailyCredentials = () => (
    <div>
      <InputScaffold label="When" required>
        {availableCredentials.map(credential => (
          <ToggleScaffold
            key={credential.get('date_id')}
            label={`${credential.getIn(['period', 'name'])} (${formatDate(
              credential.getIn(['date', 'date']),
            )})`}
          >
            <Checkbox
              id={`${credential.get('id')}`}
              checked={isCredentialSelected(credential)}
              onChange={() => selectCredential(credential)}
            />
          </ToggleScaffold>
        ))}
      </InputScaffold>
    </div>
  );

  const renderCredentials = () => {
    const issueFrequency = selectedCredentialType.issueFrequency;

    if (issueFrequency === 'ONE_TIME') {
      return renderOneTimeCredential();
    }

    if (issueFrequency === 'PERIODICALLY') {
      return renderPeriodicCredentials();
    }

    if (issueFrequency === 'DAILY') {
      return renderDailyCredentials();
    }

    return null;
  };

  const renderCredentialsSection = () => {
    const now = moment.utc();

    const sortedCredentialsToAdd = credentialsToAdd.sort(
      (credential1, credential2) => {
        const categoryId1 = credential1.getIn([
          'credentialType',
          'category_id',
        ]);

        const categoryId2 = credential2.getIn([
          'credentialType',
          'category_id',
        ]);

        if (categoryId1 !== categoryId2) {
          const category1 = getCategory(credential1);
          const category2 = getCategory(credential2);
          return category1.get('name').localeCompare(category2.get('name'));
        }

        const credentialType1 = credential1.getIn(['credentialType', 'name']);
        const credentialType2 = credential2.getIn(['credentialType', 'name']);

        if (credentialType1 !== credentialType2) {
          return credentialType1.localeCompare(credentialType2);
        }

        const startDate1 = moment(getCredentialStartDate(credential1));
        const startDate2 = moment(getCredentialStartDate(credential2));

        return startDate1.isSameOrBefore(startDate2) ? -1 : 1;
      },
    );

    const groupedCredentialsByPeriod = {};
    sortedCredentialsToAdd.forEach(credential => {
      const issueFrequency = credential.getIn([
        'credentialType',
        'issue_frequency',
      ]);
      const periods = [];
      if (issueFrequency === 'ONE_TIME') {
        credential.get('oneTimePeriods').forEach(period => {
          if (now.isSameOrBefore(period.get('end_date'), 'day'))
            periods.push(period);
        });
      } else {
        const period = credential.get('period');
        if (now.isSameOrBefore(period.get('end_date'), 'day'))
          periods.push(period);
      }
      periods.forEach(period => {
        let periodCredentials = groupedCredentialsByPeriod[period.get('id')];
        if (!periodCredentials) {
          periodCredentials = {
            period,
            credentials: [],
          };
          groupedCredentialsByPeriod[period.get('id')] = periodCredentials;
        }
        periodCredentials.credentials.push(credential);
      });
    });

    return (
      <div>
        <InputScaffold label="Requested Credentials" required>
          {Object.keys(groupedCredentialsByPeriod).map(periodId => {
            const periodCredentials = groupedCredentialsByPeriod[periodId];
            return (
              <div key={periodId}>
                <div className="onsite__credential-header">
                  <span>
                    <strong>
                      {periodCredentials.period.get('name').toUpperCase()}
                    </strong>
                  </span>
                  <span className="date-range">
                    <strong>
                      ({getPeriodDateRange(periodCredentials.period)})
                    </strong>
                  </span>
                </div>
                {periodCredentials.credentials.map(credential => {
                  const category = getCategory(credential);
                  const issueFrequency = credential.getIn([
                    'credentialType',
                    'issue_frequency',
                  ]);
                  return (
                    <ExpandableRow
                      key={credential.get('id')}
                      className="onsite-expandable-row"
                      columns={[
                        <Tag
                          backgroundColor={category.get('color')}
                          size="medium"
                        >
                          {category.get('name')}
                        </Tag>,
                        <span>
                          {credential.getIn(['credentialType', 'name'])}
                        </span>,
                        <span>
                          {issueFrequency === 'DAILY'
                            ? getCredentialDateRange(credential)
                            : ''}
                        </span>,
                        <IconContainer>
                          {renderCredentialStatus(credential)}
                          {canRemoveCredential(credential) && (
                            <span
                              style={{ cursor: 'pointer' }}
                              onClick={() => removeCredential(credential)}
                            >
                              <Icon
                                style={{ marginLeft: '10px' }}
                                icon="Trash"
                              />
                            </span>
                          )}
                          {!canRemoveCredential(credential) && (
                            <Icon
                              style={{ marginLeft: '10px' }}
                              icon="IssuedCredential"
                            />
                          )}
                        </IconContainer>,
                      ]}
                      isExpandable={false}
                      isExpanded={false}
                      onToggleExpansion={() => {}}
                    />
                  );
                })}
              </div>
            );
          })}
        </InputScaffold>
        {showAddCredentialForm && (
          <div className="onsite__credential-form">
            {filterCredentialsByPeriod && !!periodOptions?.length && (
              <InputScaffold label="Event" required>
                <ReduxReactSelectField
                  autoFocus
                  className="onsite-select"
                  placeholder="Select event..."
                  isClearable
                  value={selectedPeriod}
                  options={periodOptions}
                  onChange={selectPeriod}
                />
              </InputScaffold>
            )}
            {(!filterCredentialsByPeriod ||
              (filterCredentialsByPeriod &&
                periodOptions.some(
                  option => option.id === selectedPeriod?.id,
                ))) &&
              !!categoryOptions?.length && (
                <InputScaffold label="Type of Credential" required>
                  <ReduxReactSelectField
                    ref={selectCategoryRef}
                    autoFocus
                    className="onsite-select"
                    components={{ Option, SingleValue }}
                    placeholder="Select credential type..."
                    isClearable
                    value={selectedCredentialCategory}
                    options={categoryOptions}
                    onChange={selectCredentialCategory}
                  />
                </InputScaffold>
              )}
            {selectedCredentialCategory && (
              <InputScaffold label="Credential" required>
                <ReduxReactSelectField
                  ref={selectCredentialTypeRef}
                  className="onsite-select"
                  placeholder="Select credential..."
                  isClearable
                  value={selectedCredentialType ?? null}
                  options={credentialTypeOptions}
                  onChange={selectCredentialType}
                />
              </InputScaffold>
            )}
            {selectedCredentialType && renderCredentials()}
          </div>
        )}
        <div className="onsite__credential-form-buttons">
          {showAddCredentialForm && (
            <StyledButton
              title="Add"
              color="blue"
              height="auto"
              width="75px"
              margin="right"
              disabled={selectedCredentials.isEmpty()}
              handleClick={addCredential}
            />
          )}
          {showAddCredentialForm && (
            <span
              className="button--secondary button--plain button--secondary--icon"
              onClick={cancelAddCredential}
            >
              <Icon icon="CloseCircle" /> Cancel
            </span>
          )}
          {!showAddCredentialForm && canRequestCredential() && (
            <span
              className="button button--plain button--icon"
              onClick={displayAddCredentialForm}
            >
              <Icon icon="AddCircle" /> Request Credential
            </span>
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <SelectContact
        type="Modify"
        department={department}
        onContactSelected={setContact}
        disableIssuedContacts={false}
      />
      {contact && (
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={({
            handleSubmit,
            pristine,
            submitting,
            submitFailed,
            valid,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                label="Email"
                component={ReduxFormsField}
                required={requireContactEmails}
                validate={
                  requireContactEmails
                    ? composeValidators(isRequired, isValidEmail)('Email')
                    : isValidEmail('Email')
                }
              >
                <input type="text" />
              </Field>
              {renderCredentialsSection()}
              <Field
                name="notes"
                component={ReduxFormsField}
                label="Reason"
                required={true}
                validate={isRequired('Notes')}
              >
                <textarea />
              </Field>
              <div className="onsite__status-button">
                <StatusButton
                  className="button button--small"
                  type="submit"
                  buttonText="Submit"
                  disabled={
                    showAddCredentialForm ||
                    submitting ||
                    pristine ||
                    !valid ||
                    !canSubmit()
                  }
                  status={getStatusForButton({
                    pristine,
                    submitting,
                    submitFailed,
                  })}
                />
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
};

EditContactForm.propTypes = {
  department: PropTypes.instanceOf(ImmutableMap).isRequired,
  eventOnsiteRequestTypes: PropTypes.instanceOf(List),
  filterCredentialsByPeriod: PropTypes.bool,
  requireContactEmails: PropTypes.bool,
  overrideCredentialRequestMaximums: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

export default EditContactForm;
