import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Map } from 'immutable';
import { roleConstants } from '../common/utils/acl';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import ExpandableRow from '../common/ExpandableRow';
import CateringPeriodStatusIndicator from './CateringPeriodStatusIndicator';
import InputScaffoldNoLabel from '../common/forms/InputScaffoldNoLabel';
import QuantityField from '../common/forms/QuantityField';

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  align-items: center;
`;

const CateringPeriodSummary = ({
  department,
  period,
  timezone,
  values,
  onChange,
}) => {
  const loggedInUser = useSelector(state => state.auth.loggedInUser);

  const [expanded, setExpanded] = useState(false);
  const [mealTypeQuantities, setMealTypeQuantities] = useState({});

  const headerTitle = `${period.get('name')} (${moment
    .utc(period.get('startDate'))
    .format('MMM D')} - ${moment
    .utc(period.get('endDate'))
    .format('MMM D, YYYY')})`;

  const onMealTypeChange = (mealTypeId, value) => {
    setMealTypeQuantities(prevMealTypeQuantities => {
      const mealTypeQuantities = Object.assign({}, prevMealTypeQuantities);
      mealTypeQuantities[mealTypeId] = value;
      return mealTypeQuantities;
    });

    onChange('MealType', mealTypeId, value);
  };

  const onMealChange = (mealTypeDateId, value) => {
    onChange('MealTypeDate', mealTypeDateId, value);
  };

  const isDeadlineDateActive = mealType => {
    if (mealType.get('isDeadlineOverridden')) {
      return false;
    }

    const deadlineDate = mealType.get('deadline_date');
    let isDepartmentUser = loggedInUser
      .getIn(['data', 'departments'])
      .some(dept => dept.get('id') === department.get('id'));
    const hasDeadlineTimePassed = deadlineDate
      ? new Date(Date.now()) > new Date(deadlineDate)
      : false;

    if (isDepartmentUser && hasDeadlineTimePassed) {
      if (
        loggedInUser.getIn(['data', 'strata_admin']) ||
        loggedInUser
          .getIn(['data', 'eventRoles'])
          .some(
            er =>
              er.get('event_id') === department.get('event_id') &&
              (er.get('role') === roleConstants.EVENT_ADMIN ||
                er.get('role') === roleConstants.EVENT_COORDINATOR ||
                er.get('role') === `${department.get('type')}_credential`),
          ) ||
        loggedInUser
          .getIn(['data', 'promoterRoles'])
          .some(
            pr =>
              pr.get('promoter_id') === department.get('promoter_id') &&
              pr.get('role') === roleConstants.PROMOTER_ADMIN,
          )
      ) {
        isDepartmentUser = false;
      }
    }

    return isDepartmentUser && hasDeadlineTimePassed;
  };

  const getStatusIndicator = () => {
    let maxMealCount = 0;

    period.get('mealTypes').forEach(mealType => {
      mealType.get('dates').forEach(date => {
        const dateMealCount = date.get('mealCount');
        maxMealCount =
          dateMealCount > maxMealCount ? dateMealCount : maxMealCount;
      });
    });

    const status = maxMealCount > 0 ? 'all' : 'none';

    return (
      <IconContainer>
        <CateringPeriodStatusIndicator status={status} />
      </IconContainer>
    );
  };

  const renderMealForm = (meal, mealType) => {
    const value = values.get(meal.get('mealTypeDateId'));
    const mealsClaimed = meal.get('mealClaimed');
    if (value) {
      return (
        <td key={meal.get('mealTypeDateId')}>
          <div className="meals-needed">
            <div className="meals-needed-field">
              <div className="meals-needed-field__label">Available</div>
              <InputScaffoldNoLabel>
                <QuantityField
                  value={value.mealCount}
                  onChange={value =>
                    onMealChange(meal.get('mealTypeDateId'), value)
                  }
                  disabled={value.disabled || isDeadlineDateActive(mealType)}
                />
              </InputScaffoldNoLabel>
              {mealsClaimed > 0 && (
                <div className="meals-claimed__label">
                  Issued: {mealsClaimed}
                </div>
              )}
            </div>
          </div>
        </td>
      );
    } else {
      return <td key={meal.get('mealTypeDateId')} />;
    }
  };

  const renderTable = () => {
    const today = moment.utc();
    const disabled = today.isAfter(
      moment.tz(
        period.get('endDate').substring(0, 10) + ' 23:59',
        timezone ? timezone : 'America/Los_Angeles',
      ),
    );

    return (
      <div className="catering-table-wrapper">
        <table>
          <thead>
            <tr>
              <th style={{ verticalAlign: 'middle' }}>
                <span>Period</span>
              </th>
              {period.get('mealTypes').map(mealType => (
                <th key={mealType.get('id')}>
                  <div className="meals-needed">
                    <div className="meals-needed-field">
                      {mealType.get('name')}
                      <div style={{ color: 'initial' }}>
                        <InputScaffoldNoLabel>
                          <QuantityField
                            value={mealTypeQuantities[mealType.get('id')]}
                            onChange={value =>
                              onMealTypeChange(mealType.get('id'), value)
                            }
                            disabled={
                              disabled || isDeadlineDateActive(mealType)
                            }
                          />
                        </InputScaffoldNoLabel>
                      </div>
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {period
              .get('dates')
              .sortBy(periodDate => periodDate.get('periodDate'))
              .map(periodDate => (
                <tr key={periodDate.get('periodDateId')}>
                  <td className="catering-table-date">
                    <div className="meals-needed">
                      <div className="meals-needed-field">
                        <span style={{ fontWeight: 'bold' }}>
                          {moment
                            .utc(periodDate.get('periodDate'))
                            .format('ddd MMM D')}
                        </span>
                      </div>
                    </div>
                  </td>
                  {period.get('mealTypes').map((mealType, index) => {
                    const meal = mealType
                      .get('dates')
                      .find(
                        mealTypeDate =>
                          mealTypeDate.get('periodDateId') ===
                          periodDate.get('periodDateId'),
                      );
                    if (!meal) {
                      return (
                        <td
                          className="catering-table-no-meal"
                          key={`no-meal-${periodDate.get(
                            'periodDateId',
                          )}-${index}`}
                        >
                          N/A
                        </td>
                      );
                    } else {
                      return renderMealForm(meal, mealType);
                    }
                  })}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="catering-period-summary">
      <ExpandableRow
        isExpandable
        isExpanded={expanded}
        onToggleExpansion={() => setExpanded(!expanded)}
        columns={[headerTitle, getStatusIndicator()]}
      >
        {renderTable()}
      </ExpandableRow>
    </div>
  );
};

CateringPeriodSummary.propTypes = {
  period: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  department: PropTypes.object.isRequired,
  timezone: PropTypes.string,
};

export default CateringPeriodSummary;
