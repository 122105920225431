import client from '../../client';

export const FETCH_PULSE_EVENT_LIST = 'FETCH_PULSE_EVENT_LIST';
export function fetchPulseEventList(eventId) {
  return {
    type: FETCH_PULSE_EVENT_LIST,
    promise: client.get(`pulse/events?event=${eventId}`),
  };
}

export const ADD_PERIOD = 'ADD_PERIOD';
export function addPeriod(eventId, periodValues) {
  return {
    type: ADD_PERIOD,
    promise: client.post(`event/${eventId}/periods`, periodValues),
  };
}

export const EDIT_PERIOD = 'EDIT_PERIOD';
export function editPeriod(eventId, periodId, periodValues) {
  return {
    type: EDIT_PERIOD,
    promise: client.put(`event/${eventId}/periods/${periodId}`, periodValues),
  };
}
