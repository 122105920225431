import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Checkbox } from 'synfrastructure';
import moment from 'moment';
import clone from 'lodash/cloneDeep';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import ToggleScaffold from '../../common/forms/ToggleScaffold';

const DEFAULT_VALUE = { issue_frequency: 'PERIODICALLY', periods: [] };

const PassFulfillmentMegaField = ({
  editing = false,
  onChange,
  periods,
  showFrequencyDropdown = true,
  value = DEFAULT_VALUE,
}) => {
  const toggleIssueFrequency = event => {
    const newValue = clone(value);
    const newIssueFrequency = event.currentTarget.value;
    // If moving from or to daily, reset selected periods/dates
    if (newValue.issue_frequency === 'DAILY' || newIssueFrequency === 'DAILY') {
      newValue.periods = [];
    }
    newValue.issue_frequency = newIssueFrequency;
    onChange(newValue);
  };

  const onCheckDateInAlreadySelectedPeriod = (periodIndex, date) => {
    const newValue = clone(value);

    const dateIndex = findIndex(
      newValue.periods[periodIndex].dates,
      thisDate => thisDate === date.get('id'),
    );

    if (dateIndex > -1) {
      // Date checked, so uncheck it
      remove(newValue.periods[periodIndex].dates, (v, i) => i === dateIndex);
      if (newValue.periods[periodIndex].dates.length === 0) {
        remove(newValue.periods, (v, i) => i === periodIndex);
      }
    } else {
      // Date unchecked, so check it
      newValue.periods[periodIndex].dates.push(date.get('id'));
    }
    onChange(newValue);
  };

  const onCheck = (period, date = null) => {
    const newValue = clone(value);

    const periodIndex = findIndex(
      newValue.periods,
      thisPeriod => thisPeriod.id === period.get('id'),
    );

    if (periodIndex > -1) {
      // At least one date in period already checked
      if (!date) {
        // Working with whole periods, so remove the entire period
        remove(newValue.periods, (v, i) => i === periodIndex);
        onChange(newValue);
      } else {
        // Working with individual days
        onCheckDateInAlreadySelectedPeriod(periodIndex, date);
      }
    } else {
      // No dates for period are checked
      const dates = [];
      if (date) {
        // Date specified, so check only that date
        dates.push(date.get('id'));
        newValue.periods.push({
          id: period.get('id'),
          dates,
        });
      } else {
        // Check whole period or all dates in the period
        const newSelected = {
          id: period.get('id'),
        };
        if (value.issue_frequency === 'DAILY') {
          newSelected.dates = period
            .get('dates')
            .map(date => date.get('id'))
            .toJS();
        }
        newValue.periods.push(newSelected);
      }

      onChange(newValue);
    }
  };

  const selectAllDays = targetPeriod => {
    const newValue = clone(value);

    let newValuePeriod = find(
      newValue.periods,
      thisPeriod => thisPeriod.id === targetPeriod.get('id'),
    );

    if (!newValuePeriod) {
      newValuePeriod = { id: targetPeriod.get('id') };
      newValue.periods.push(newValuePeriod);
    }

    if (
      newValuePeriod &&
      newValuePeriod.dates &&
      newValuePeriod.dates.length === targetPeriod.get('dates').size
    ) {
      // If all days already selected, deselect them instead
      newValue.periods = newValue.periods.filter(
        period => targetPeriod.get('id') !== period.id,
      );
    } else {
      newValuePeriod.dates = targetPeriod
        .get('dates')
        .map(date => date.get('id'))
        .toJS();
    }

    onChange(newValue);
  };

  const isCheckboxChecked = (period, date = null) => {
    const selectedPeriod = find(
      value.periods,
      thisPeriod => thisPeriod.id === period.get('id'),
    );

    if (!selectedPeriod) {
      return false;
    }
    if (date) {
      return !!find(
        selectedPeriod.dates,
        thisDate => thisDate === date.get('id'),
      );
    }
    return true;
  };

  const selectAll = () => {
    let newValue = clone(value);

    // Check if all checkboxes are already selected.
    let allCheckboxesSelected = true;
    if (newValue.periods && newValue.periods.length === periods.size) {
      newValue.periods.forEach(selectedPeriod => {
        if (
          !allCheckboxesSelected || // Skip if we've already found a date that wasn't selected
          !selectedPeriod.dates ||
          selectedPeriod.dates.length ===
            periods
              .find(
                selectablePeriod =>
                  selectablePeriod.get('id') === selectedPeriod.id,
              )
              .get('dates').size
        ) {
          return;
        }
        allCheckboxesSelected = false;
      });
    } else {
      allCheckboxesSelected = false;
    }

    if (allCheckboxesSelected) {
      // Deselect all
      newValue.periods = [];
    } else {
      newValue = {
        issue_frequency: newValue.issue_frequency,
        periods: periods
          .map(period => {
            const value = { id: period.get('id') };
            if (newValue.issue_frequency === 'DAILY') {
              value.dates = period
                .get('dates')
                .map(date => date.get('id'))
                .toJS();
            }
            return value;
          })
          .toJS(),
      };
    }

    onChange(newValue);
  };

  const renderFormattedDate = date => {
    return moment.utc(date).format('ddd MMM DD');
  };

  const renderIndividualDaysSection = period => {
    return (
      <div
        key={period.get('id')}
        className="pass-fulfillment-field pass-fulfillment-field__separate-days"
      >
        <div className="pass-fulfillment-field__separate-days__main">
          <h4>{period.get('name')}</h4>
          {!editing && (
            <button
              type="button"
              className="button button--plain"
              onClick={() => selectAllDays(period)}
            >
              select all days
            </button>
          )}
        </div>
        <div className="pass-fulfillment-field__separate-days__dates">
          {period.get('dates').map(date => {
            const checkboxId = `date-checkbox-${period.get('id')}-${date.get(
              'id',
            )}`;
            return (
              <div key={date.get('id')}>
                <ToggleScaffold label={renderFormattedDate(date.get('date'))}>
                  <Checkbox
                    id={checkboxId}
                    checked={isCheckboxChecked(period, date)}
                    onChange={() => onCheck(period, date)}
                    disabled={editing}
                  />
                </ToggleScaffold>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderWholePeriodSection = period => {
    const dateRangeString = `${renderFormattedDate(
      period.get('start_date'),
    )} - ${renderFormattedDate(period.get('end_date'))}`;
    const checkboxId = `period-checkbox-${period.get('id')}`;
    return (
      <div
        key={period.get('id')}
        className="pass-fulfillment-field pass-fulfillment-field__whole-period"
      >
        <div className="pass-fulfillment-field__whole-period__main">
          <h4>{period.get('name')}</h4>
        </div>
        <div className="pass-fulfillment-field__whole-period__dates">
          <ToggleScaffold label={dateRangeString}>
            <Checkbox
              id={checkboxId}
              checked={isCheckboxChecked(period)}
              onChange={() => onCheck(period)}
              disabled={editing}
            />
          </ToggleScaffold>
        </div>
      </div>
    );
  };

  const renderPeriodSection = period => {
    const issue_frequency = value.issue_frequency;
    if (issue_frequency === 'DAILY') {
      return renderIndividualDaysSection(period);
    }
    return renderWholePeriodSection(period);
  };

  const renderFrequencyDropdown = () => {
    if (!showFrequencyDropdown) {
      return null;
    }

    return (
      <div className="input-scaffold">
        <select
          onChange={toggleIssueFrequency}
          value={value.issue_frequency}
          disabled={editing}
        >
          <option value="ONE_TIME">Items issued across all periods</option>
          <option value="PERIODICALLY">New items issued each period</option>
          <option value="DAILY">New item issued each day</option>
        </select>
      </div>
    );
  };

  return (
    <div>
      <div className="pass-fulfillment-field pass-fulfillment-field__periods">
        {renderFrequencyDropdown()}
        {!editing && (
          <button className="button" type="button" onClick={selectAll}>
            Select all {value.issue_frequency === 'DAILY' ? 'dates' : 'periods'}
          </button>
        )}
      </div>
      {periods.map(renderPeriodSection)}
    </div>
  );
};

PassFulfillmentMegaField.propTypes = {
  periods: PropTypes.instanceOf(List).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      periods: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          dates: PropTypes.arrayOf(PropTypes.number),
        }),
      ),
      issue_frequency: PropTypes.oneOf(['ONE_TIME', 'DAILY', 'PERIODICALLY']),
    }),
  ]),
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  showFrequencyDropdown: PropTypes.bool,
};

export default PassFulfillmentMegaField;
