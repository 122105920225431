import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import {
  getCurrentEvent,
  getEventOnsiteSettings,
} from '../event/event-selectors';
import { fetchCredentialTypeCategoryList } from '../credential/type/credential-type-actions';
import {
  fetchEventOnsiteRequestTypes,
  fetchEventOnsiteSettings,
} from '../event/event-actions';
import { renderErrorMessage } from './onsite-utils';

const CheckEvent = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const eventOnsiteSettings = useSelector(state =>
    getEventOnsiteSettings(state, { params }),
  );

  useEffect(() => {
    if (event) {
      dispatch(fetchCredentialTypeCategoryList(event.get('id')));
      dispatch(fetchEventOnsiteSettings(event.get('id')));
      dispatch(fetchEventOnsiteRequestTypes(event.get('id')));
    }
  }, [dispatch, event]);

  if (!eventOnsiteSettings?.get('enabled')) {
    return renderErrorMessage('Event is not enabled for Onsite Requests.');
  }

  return <Outlet />;
};

export default CheckEvent;
