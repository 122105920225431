import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { List, Map } from 'immutable';
import { resource, useACL } from '../common/ACL';
import departmentTypeMap from '../lib/department-type-map';
import PropTypes from 'prop-types';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import ContactCreatePlaceholders from './ContactCreatePlaceholders';
import ContactCreateForm from './ContactCreateForm';
import GroupCredentialRequests from '../group-credential/GroupCredentialRequests';
import ContactListContents from './ContactListContents';

const aclRules = {
  canEdit: [resource.DEPARTMENT, 'edit'],
  canRequestCredentials: [resource.DEPARTMENT, 'request-credential'],
};

const ContactList = ({ department, deptSettings, contactList }) => {
  const acl = useACL(aclRules);
  const [searchParams] = useSearchParams();

  const contactParam = parseInt(searchParams.get('contact'));

  const [addingNew, setAddingNew] = useState(false);
  const [addingPlaceholder, setAddingPlaceholder] = useState(false);
  const [displayContact, setDisplayContact] = useState(
    Number.isNaN(contactParam) ? null : Number(contactParam),
  );

  const onCreateContact = contactId => {
    setDisplayContact(contactId);
    setAddingNew(false);
  };
  const canAddContact = acl.canEdit && acl.canRequestCredentials;

  if (!department) return <LoadingIndicator />;

  const departmentType = department.get('type');
  const label = departmentTypeMap[departmentType].contacts.label;

  const showGroupCredentialRequests = !['intern', 'media'].includes(
    departmentType,
  );

  return (
    <div className="staff-page">
      <Paper>
        <PaperHeader
          title={label.plural}
          actions={[
            canAddContact && (
              <button
                key="contact-list-genplaceholder"
                className="button--secondary button--plain button--secondary--icon"
                onClick={() => setAddingPlaceholder(true)}
              >
                <Icon icon="AddCircle" />
                {`Add Placeholders`}
              </button>
            ),
            canAddContact && (
              <Link
                key="contact-list-upload"
                className="button button--plain button--icon"
                to="../upload"
              >
                <Icon icon="Upload" />
                {`Upload ${label.plural}`}
              </Link>
            ),
            canAddContact && (
              <button
                key="contact-list-add"
                className="button button--plain button--icon"
                onClick={() => setAddingNew(true)}
              >
                <Icon icon="AddCircle" />
                {`Add ${label.singular}`}
              </button>
            ),
          ]}
        />
      </Paper>
      <div>
        {addingPlaceholder && (
          <div>
            <ContactCreatePlaceholders
              department={department}
              onCancel={() => setAddingPlaceholder(false)}
            />
          </div>
        )}
        {addingNew ? (
          <ContactCreateForm
            department={department}
            deptSettings={deptSettings}
            onSuccess={onCreateContact}
            onCancel={() => setAddingNew(false)}
          />
        ) : null}
        {showGroupCredentialRequests && (
          <GroupCredentialRequests department={department} />
        )}
        {contactList ? (
          <ContactListContents
            contactList={contactList}
            department={department}
            deptSettings={deptSettings}
            displayContact={displayContact}
          />
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </div>
  );
};

ContactList.propTypes = {
  department: PropTypes.instanceOf(Map),
  deptSettings: PropTypes.instanceOf(Map),
  contactList: PropTypes.instanceOf(List).isRequired,
};

export default ContactList;
