import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { getCurrentEvent } from './event-selectors';
import { fetchEventAdminList } from './event-actions';

const EventAdminListLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const event = useSelector(state => getCurrentEvent(state, { params }));

  useEffect(() => {
    if (event) dispatch(fetchEventAdminList(event.get('id')));
  }, [dispatch, event]);

  return <Outlet />;
};

export default EventAdminListLoader;
