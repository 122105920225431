import { useState } from 'react';
import { useFormState } from 'react-final-form';
import { v1 as keygen } from 'uuid';
import { get } from 'lodash';
import { isRequired } from 'revalidate';
import { defaultBlocks } from '../../lib/draftjs-editor';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import FormSchema from '../../lib/form-schema';
import FormSection from '../forms/FormSection';
import FormField from '../forms/FormField';
import FormBuilder from '../custom-forms/FormBuilder';
import FormValidator from '../custom-forms/FormValidator';
import ReduxFormsFieldNoLabel from '../forms/ReduxFormsFieldNoLabel';
import ReduxFormsField from '../forms/ReduxFormsField';
import ReduxSynapseDraft from '../forms/ReduxSynapseDraft';
import CheckboxBoolean from '../forms/CheckboxBoolean';
import DeleteButton from '../DeleteButton';

const ApplicationSection = ({ event, section }) => {
  const [contentKey, setContentKey] = useState(null);

  const formSectionName = `sections.${section.key}`;

  useFormState({
    subscription: { values: true },
    onChange: ({ values }) => {
      if (get(values, `${formSectionName}.content`) && !contentKey)
        setContentKey(keygen());
    },
  });

  return (
    <FormSection name={formSectionName}>
      {section.text && <p>{section.text}</p>}
      <div className="generic-form__body">
        {section.isCustom && (
          <FormField
            name="name"
            label="Section Name"
            component={ReduxFormsField}
            required
            validate={isRequired('Section Name')}
          >
            <input type="text" autoFocus />
          </FormField>
        )}
        <FormField
          key={contentKey}
          name="content"
          component={ReduxFormsFieldNoLabel}
          required
          validate={section.isCustom ? isRequired('Content') : null}
        >
          <ReduxSynapseDraft
            inlineControls={['Bold', 'Italic', 'Underline', 'Link']}
            blockControls={['UL', 'OL']}
            customBlocks={defaultBlocks}
            customBlockControls={Object.keys(defaultBlocks)}
            controlDisplay="inline"
          />
        </FormField>
        {section.optional && (
          <FormField name="doNotDisplay" component={ReduxFormsField}>
            <CheckboxBoolean label="Do not display section in application" />
          </FormField>
        )}
        {section.isCustom && (
          <FormField
            name="customFields"
            validate={async value => {
              const formSchema = value;
              if (!formSchema) return;
              const formValidator = new FormValidator();
              formSchema.registerValidator(formValidator);
              const isValid = await formSchema.validate(formValidator.name);
              if (!isValid)
                return 'Custom Fields has at least one invalid field';
            }}
          >
            {({ input: { value, onChange } }) => (
              <FormBuilder
                event={event}
                value={value}
                onChange={value => onChange(new FormSchema(value.serialize()))}
              />
            )}
          </FormField>
        )}
        {section.sortable && (
          // Register sortOrder field so it registers as a change when it is re-ordered
          <FormField name="sortOrder" render={() => null} />
        )}
      </div>
      <div className="generic-form__footer">
        {section.isCustom && (
          <FormField name="isDeleted">
            {({ input: { onChange } }) => (
              <DeleteButton
                buttonText="Delete Section"
                onConfirmDelete={() => onChange(true)}
              />
            )}
          </FormField>
        )}
      </div>
    </FormSection>
  );
};

ApplicationSection.propTypes = {
  event: PropTypes.instanceOf(Map).isRequired,
  section: PropTypes.object.isRequired,
};

export default ApplicationSection;
