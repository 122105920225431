import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import { resource, useACL } from '../common/ACL';
import { showNotification } from '../notification/notification-actions';
import { addEmailTypeSample } from './email-actions';
import { getCurrentPromoter } from '../promoter/promoter-selectors';
import { getCurrentFestival } from '../festival/festival-selectors';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import InputScaffold from '../common/forms/InputScaffold';
import StatusButton from '../common/StatusButton';

const Body = styled.div`
  width: 100%;
  height: auto;
`;

const aclRules = {
  canManageGlobalSampleEmailTemplate: [
    resource.STRATA_ADMIN,
    'manage-sample-email-template',
  ],
  canManagePromoterSampleEmailTemplate: [
    resource.PROMOTER,
    'manage-sample-email-template',
  ],
};

const AddSampleEmailTemplateModal = ({ emailType, subject, body, trigger }) => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const params = useParams();

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const festival = useSelector(state => getCurrentFestival(state, { params }));

  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [scope, setScope] = useState('global');
  const [name, setName] = useState(null);

  const open = e => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const close = () => {
    setScope('global');
    setName(null);
    setIsOpen(false);
    setSubmitting(false);
  };

  const handleAdd = async () => {
    setSubmitting(true);

    const payload = { name, subject, body };

    if (scope === 'promoter') payload.promoter = promoter.get('id');
    if (scope === 'festival') payload.festival = festival.get('id');

    dispatch(addEmailTypeSample(emailType, payload)).then(action => {
      if (action.response.ok) {
        dispatch(
          showNotification({
            message: 'Sample Email Template added',
            status: 'success',
          }),
        );
      } else if (action.json.validationErrors) {
        const message = action.json.validationErrors
          .map(error => error.message)
          .reduce((acc, error) => (error ? `${acc}${error}.\n` : acc), '');

        dispatch(
          showNotification({
            message,
            status: 'error',
          }),
        );
      }

      close();
    });
  };

  return (
    <Modal
      closeIcon
      open={isOpen}
      onOpen={open}
      onClose={close}
      size="tiny"
      trigger={trigger}
    >
      <Modal.Header>Add Sample Email Template</Modal.Header>
      <Modal.Content>
        <div className="modal__body">
          <Body>
            <div className="generic-form__body">
              <InputScaffold label="Scope" required>
                <select
                  value={scope || ''}
                  onChange={e => setScope(e.target.value)}
                >
                  {acl.canManageGlobalSampleEmailTemplate && (
                    <option value="global">All Promoters</option>
                  )}
                  {acl.canManagePromoterSampleEmailTemplate && (
                    <>
                      <option value="promoter">Current Promoter</option>
                      <option value="festival">Current Festival</option>
                    </>
                  )}
                </select>
              </InputScaffold>
              <InputScaffold label="Name" required>
                <input
                  value={name || ''}
                  autoFocus
                  onChange={e => setName(e.target.value)}
                  onKeyDown={e => {
                    if (e.key === 'Enter') handleAdd();
                  }}
                />
              </InputScaffold>
            </div>
          </Body>
        </div>
        <div className="modal__footer">
          <StatusButton
            buttonText="Add"
            completeText="Added"
            disabled={submitting || !name}
            status="default"
            onClick={handleAdd}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

AddSampleEmailTemplateModal.propTypes = {
  emailType: PropTypes.object.isRequired,
  subject: PropTypes.string.isRequired,
  body: PropTypes.any.isRequired,
  trigger: PropTypes.node,
};

export default AddSampleEmailTemplateModal;
