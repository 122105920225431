import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { getCurrentDepartment } from '../department/department-selectors';
import { getCurrentEvent } from '../event/event-selectors';
import {
  getCateringAvailability,
  updateCateringAvailability,
} from './catering-actions';
import { showNotification } from '../notification/notification-actions';
import DEFAULT_TIMEZONE from '../lib/default-timezone';
import moment from 'moment-timezone';
import FullPageMessage from '../common/FullPageMessage';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import StatusButton from '../common/StatusButton';
import CateringPeriodSummary from './CateringPeriodSummary';
import CateringPeriodStatusIndicator from './CateringPeriodStatusIndicator';
import Icon from '../common/icons/Icon';

const CateringSummary = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const cateringAvailability = useSelector(state =>
    state.catering.availability.get('data'),
  );

  const cateringAvailabilityLoading = useSelector(state =>
    state.catering.availability.get('loading'),
  );

  const cateringAvailabilityLoaded = useSelector(state =>
    state.catering.availability.get('loaded'),
  );

  const timezone = event?.get('time_zone') ?? DEFAULT_TIMEZONE;
  const periods = cateringAvailability.get('periods');

  const [formValues, setFormValues] = useState(null);
  const [originalFormValues, setOriginalFormValues] = useState(null);

  useEffect(() => {
    if (!cateringAvailabilityLoaded) return;

    const today = moment().tz(timezone);

    const formValues = new Map();
    cateringAvailability.get('periods').forEach(period => {
      const periodDates = period.get('dates');
      period.get('mealTypes').forEach(mealType => {
        mealType.get('dates').forEach(mealTypeDate => {
          const mealTypeDateId = mealTypeDate.get('mealTypeDateId');
          const periodDate = periodDates
            .find(
              entry =>
                entry.get('periodDateId') === mealTypeDate.get('periodDateId'),
            )
            .get('periodDate');
          const disabled = today.isAfter(
            moment.tz(periodDate.substring(0, 10), timezone),
            'day',
          );
          formValues.set(mealTypeDateId, {
            periodId: period.get('id'),
            periodDateId: mealTypeDate.get('periodDateId'),
            mealTypeId: mealType.get('id'),
            mealTypeDateId,
            mealCount: mealTypeDate.get('mealCount'),
            disabled: disabled,
          });
        });
      });
    });

    setFormValues(formValues);
    setOriginalFormValues(cloneDeep(formValues));
  }, [cateringAvailability, cateringAvailabilityLoaded, event, timezone]);

  const hasChanges = () => {
    if (originalFormValues.size !== formValues.size) {
      return true;
    }

    for (const [key, originalFormValue] of originalFormValues) {
      const formValue = formValues.get(key);
      if (!formValue || formValue.mealCount !== originalFormValue.mealCount) {
        return true;
      }
    }

    return false;
  };

  const onPeriodSummaryChange = (periodId, type, id, mealCount) => {
    setFormValues(prevFormValues => {
      const formValues = cloneDeep(prevFormValues);
      let meal;

      if (type === 'Period') {
        const mealsToUpdate = [...formValues.values()].filter(
          value => value.periodId === periodId,
        );
        mealsToUpdate.forEach(mealToUpdate => {
          meal = formValues.get(mealToUpdate.mealTypeDateId);
          if (!meal.disabled) {
            meal.mealCount = mealCount;
          }
        });
      } else if (type === 'PeriodDate') {
        const mealsToUpdate = [...formValues.values()].filter(
          value => value.periodDateId === id,
        );
        mealsToUpdate.forEach(mealToUpdate => {
          meal = formValues.get(mealToUpdate.mealTypeDateId);
          if (!meal.disabled) {
            meal.mealCount = mealCount;
          }
        });
      } else if (type === 'MealType') {
        const mealsToUpdate = [...formValues.values()].filter(
          value => value.periodId === periodId && value.mealTypeId === id,
        );
        mealsToUpdate.forEach(mealToUpdate => {
          meal = formValues.get(mealToUpdate.mealTypeDateId);
          if (!meal.disabled) {
            meal.mealCount = mealCount;
          }
        });
      } else if (type === 'MealTypeDate') {
        meal = formValues.get(id);
        if (!meal.disabled) {
          meal.mealCount = mealCount;
        }
      }

      return formValues;
    });
  };

  const submit = () => {
    const payload = { availability: [] };

    if (!hasChanges()) {
      return;
    }

    for (const formValue of formValues.values()) {
      payload.availability.push({
        mealTypeDateId: formValue.mealTypeDateId,
        mealCount: formValue.mealCount,
      });
    }

    dispatch(updateCateringAvailability(department.get('id'), payload)).then(
      action => {
        if (action.response.ok) {
          dispatch(getCateringAvailability(department.get('id')));
          dispatch(
            showNotification({
              message: 'Department catering availability successfully saved',
              status: 'success',
            }),
          );
        } else {
          const errorMessage =
            action.json.validationErrors && action.json.validationErrors.length
              ? action.json.validationErrors[0].message
              : action.response.statusText;
          dispatch(
            showNotification({
              message: `Error saving department catering availability: ${errorMessage}`,
              status: 'error',
            }),
          );
        }
      },
    );
  };

  if (!formValues) {
    return <LoadingIndicator />;
  }

  if (periods?.size === 0) {
    return (
      <FullPageMessage icon="Sad" message="There are no meal types defined." />
    );
  }

  return (
    <div>
      <Paper>
        <PaperHeader
          title="Department Catering"
          actions={[
            <Link
              key="department-catering-upload"
              className="button button--plain button--icon"
              to="upload"
            >
              <Icon icon="Upload" />
              Upload Department Catering
            </Link>,
          ]}
        />
        <div className="catering-summary">
          <p>
            Manage your department's catering needs below. When finished, be
            sure to click the save button.
          </p>
          <p>
            Please expand each period and select the maximum number of meals
            available to the department for each period date and meal type.
          </p>
          <h4 className="catering-summary__indicators">Period Indicators</h4>
          <div className="catering-summary__indicator">
            <CateringPeriodStatusIndicator status="all" />
            <span> Meals have been requested for the period.</span>
          </div>
          <div className="catering-summary__indicator">
            <CateringPeriodStatusIndicator status="none" />
            <span> No meals have been requested for the period.</span>
          </div>
        </div>
      </Paper>
      {cateringAvailability.get('periods').map(period => (
        <div key={period.get('id')}>
          <CateringPeriodSummary
            key={period.get('id')}
            period={period}
            values={formValues}
            onChange={(type, id, mealCount) =>
              onPeriodSummaryChange(period.get('id'), type, id, mealCount)
            }
            timezone={timezone}
            department={department}
          />
        </div>
      ))}
      {periods.size > 0 && (
        <Paper className="generic-form__footer">
          <StatusButton
            disabled={cateringAvailabilityLoading || !hasChanges()}
            status={cateringAvailabilityLoading ? 'loading' : 'default'}
            onClick={submit}
          />
        </Paper>
      )}
    </div>
  );
};

export default CateringSummary;
