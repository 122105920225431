import {
  configureStore,
  createListenerMiddleware,
  isPlain,
} from '@reduxjs/toolkit';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { Iterable } from 'immutable';
import rootReducer from './reducers';
import apiRequestMiddleware from './common/utils/apiRequestMiddleware';

export const store = configureStore({
  reducer: rootReducer,
  enhancers: [responsiveStoreEnhancer],
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: {
        warnAfter: 1000,
      },
      serializableCheck: {
        ignoredActionPaths: ['promise', 'payload.predicate', 'payload.effect'],
        isSerializable: value => Iterable.isIterable(value) || isPlain(value),
        getEntries: value =>
          Iterable.isIterable(value) ? value.entries() : Object.entries(value),
      },
    })
      .concat(apiRequestMiddleware)
      .concat(createListenerMiddleware().middleware),
  devTools: { trace: true, traceLimit: 25, serialize: { options: true } },
});
