import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex: 1;
  padding: 20px 20px;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px 10px 20px 10px;
`;

export const Select = styled.div`
  display: flex;
  margin: 10px 10px 20px 10px;
  width: calc(50% - 20px);
  height: 44px;
  margin-bottom: 36px;
`;
