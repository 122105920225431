import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';

const CateringPeriodStatusIndicator = ({ status, mealCount }) => {
  let leftColor, rightColor, rightContent;
  switch (status) {
    case 'all':
      leftColor = '#2AA961';
      rightColor = '#2ECC71';
      rightContent = <Icon icon="CheckMark" />;
      break;
    case 'some':
      leftColor = '#B78B43';
      rightColor = '#DEA74B';
      rightContent = <Icon icon="Plus" />;
      break;
    case 'none':
      leftColor = '#BE4237';
      rightColor = '#E74C3C';
      rightContent = <Icon icon="Close" />;
      break;
    default:
      throw new Error(`Unknown status: ${status}`);
  }

  return (
    <div className="status-indicator">
      {mealCount !== undefined && (
        <span
          className="status-indicator__qty"
          style={{ backgroundColor: leftColor }}
        >
          {mealCount}
        </span>
      )}
      <span
        className="status-indicator__icon"
        style={{ backgroundColor: rightColor }}
      >
        {rightContent}
      </span>
    </div>
  );
};

CateringPeriodStatusIndicator.propTypes = {
  status: PropTypes.oneOf(['none', 'some', 'all']),
  mealCount: PropTypes.string,
};

export default CateringPeriodStatusIndicator;
