import roleChildren from './role-children';

const multiAllow = (rolesActionsObject, resourceConstant, cb, acl) => {
  Object.keys(rolesActionsObject).forEach(role => {
    const rawActions = rolesActionsObject[role];
    const actionsArray = Array.isArray(rawActions) ? rawActions : [rawActions];
    const roleAndChildRoles = [role];
    let parentRole = role;
    let childRole = roleChildren[parentRole];
    while (childRole) {
      roleAndChildRoles.push(childRole);
      parentRole = childRole;
      childRole = roleChildren[parentRole];
    }
    acl.allow(role, resourceConstant, actionsArray, cb(roleAndChildRoles));
  });
};

export default multiAllow;
