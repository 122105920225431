import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { Tooltip } from 'react-tooltip';
import { editContact } from './contact-actions';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';

const ContactName = ({ contact, canEdit }) => {
  const dispatch = useDispatch();

  const focus = useRef(false);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [firstName, setFirstName] = useState(contact.get('first_name') ?? '');
  const [lastName, setLastName] = useState(contact.get('last_name') ?? '');

  const displayName = editing ? firstName : `${firstName} ${lastName}`.trim();

  const unsetFocus = () => {
    focus.current = false;
    // The delay is to prevent saving changes immediately when losing focus
    // from either of the name fields, to allow time to change focus from
    // the first_name field to the last_name field.
    setTimeout(() => {
      if (!focus.current) cancelOrSubmit();
    }, 100);
  };

  const saveContactChanges = () => {
    setSaving(true);

    const contactNames = {
      first_name: firstName,
      last_name: lastName,
    };

    return dispatch(editContact(contact.get('id'), contactNames)).then(() => {
      setSaving(false);
      setEditing(false);
    });
  };

  const cancelOrSubmit = () => {
    if (
      firstName !== contact.get('first_name') ||
      lastName !== contact.get('last_name')
    ) {
      saveContactChanges();
    } else {
      setEditing(false);
    }
  };

  return (
    <div className="guest__name-fields">
      {!canEdit && (
        <div className="guest__name-fields">
          <input
            type="text"
            readOnly={true}
            value={displayName}
            onClick={event => event.stopPropagation()}
          />
        </div>
      )}
      {canEdit && (
        <input
          type="text"
          disabled={saving}
          onClick={event => event.stopPropagation()}
          onChange={event => setFirstName(event.currentTarget.value)}
          placeholder={editing ? 'Enter first name' : ` `}
          value={displayName}
          onFocus={() => {
            focus.current = true;
            setEditing(true);
          }}
          onBlur={() => unsetFocus()}
          onKeyDown={event => (event.key === 'Enter' ? cancelOrSubmit() : null)}
        />
      )}
      {editing ? (
        <input
          type="text"
          disabled={saving}
          onClick={event => event.stopPropagation()}
          onChange={event => setLastName(event.currentTarget.value)}
          placeholder="Enter last name"
          value={lastName}
          onFocus={() => {
            focus.current = true;
            setEditing(true);
          }}
          onBlur={() => unsetFocus()}
          onKeyDown={event => (event.key === 'Enter' ? cancelOrSubmit() : null)}
        />
      ) : null}
      {contact.get('is_primary') ? (
        <span className="primary-contact-pill">primary</span>
      ) : null}
      {contact.get('is_group') ? (
        <div data-tooltip-id={`group-contact-tooltip-${contact.get('id')}`}>
          <Icon icon="Clipboard" className="group-contact-clipboard" />
          <Tooltip
            id={`group-contact-tooltip-${contact.get('id')}`}
            content="Group Contact"
            style={{ marginLeft: '5px', fontWeight: 'normal' }}
          />
        </div>
      ) : null}
    </div>
  );
};

ContactName.propTypes = {
  contact: PropTypes.instanceOf(Map).isRequired,
  canEdit: PropTypes.bool,
};

export default ContactName;
