import getApiReducer, { defaultState } from '../utils/getApiReducer';
import Immutable from 'immutable';
import {
  ADD_EMAIL_TYPE_SAMPLE,
  DELETE_EMAIL_TYPE_SAMPLE,
  UPDATE_EMAIL_TYPE_SAMPLE,
} from '../../email/email-actions';

const getApplicationReducers = type => {
  const typeUpper = type.toUpperCase();

  const applicationsReducer = (state = Immutable.Map(), action) => {
    let newState = state;
    if (action.originalType === `GET_${typeUpper}_APPLICATIONS`) {
      newState = newState.set(
        action.eventId,
        getApiReducer(`GET_${typeUpper}_APPLICATIONS`)(
          newState.get(action.eventId),
          action,
        ),
      );
    }
    if (action.type === `SAVE_${typeUpper}_APPLICATION_SUCCESS`) {
      const path = [action.eventId, 'data'];
      const existingApplicationIndex = newState
        .getIn(path)
        .findIndex(application => application.get('id') === action.json.id);
      if (existingApplicationIndex > -1) {
        newState = newState.setIn(
          path.concat(existingApplicationIndex),
          Immutable.fromJS(action.json),
        );
      } else {
        newState = newState.setIn(
          path,
          newState.getIn(path).push(Immutable.fromJS(action.json)),
        );
      }
    }
    if (action.type === `DELETE_${typeUpper}_APPLICATION_SUCCESS`) {
      const path = [action.eventId, 'data'];
      newState = newState.setIn(
        path,
        newState
          .getIn(path)
          .filter(
            application => application.get('id') !== action.applicationId,
          ),
      );
    }
    if (action.type === `CREATE_${typeUpper}_APPLICATION_SECTION_SUCCESS`) {
      let path = [action.json.application.event_id, 'data'];
      const index = newState
        .getIn(path)
        .findIndex(
          application => application.get('id') === action.json.application.id,
        );
      if (index > -1) {
        path = path.concat([index, 'sections']);
        newState = newState.setIn(
          path,
          newState.getIn(path).push(Immutable.fromJS(action.json)),
        );
      }
    }
    if (action.type === `UPDATE_${typeUpper}_APPLICATION_SECTION_SUCCESS`) {
      let path = [action.json.application.event_id, 'data'];
      const index = newState
        .getIn(path)
        .findIndex(
          application => application.get('id') === action.json.application.id,
        );
      if (index > -1) {
        path = path.concat([index, 'sections']);
        const sectionIndex = newState
          .getIn(path)
          .findIndex(section => section.get('id') === action.json.id);
        if (sectionIndex > -1) {
          newState = newState.setIn(
            path.concat(sectionIndex),
            Immutable.fromJS(action.json),
          );
        }
      }
    }
    if (
      action.type ===
      `CREATE_OR_UPDATE_${typeUpper}_APPLICATION_SECTION_SUCCESS`
    ) {
      if (action.json?.application) {
        if (action.json.deleted_at) {
          let path = [action.json.application.event_id, 'data'];
          const index = newState
            .getIn(path)
            .findIndex(
              application =>
                application.get('id') === action.json.application.id,
            );
          if (index > -1) {
            path = path.concat([index, 'sections']);
            newState = newState.setIn(
              path,
              newState
                .getIn(path)
                .filter(section => section.get('id') !== action.json.id),
            );
          }
        } else {
          let path = [action.json.application.event_id, 'data'];
          const index = newState
            .getIn(path)
            .findIndex(
              application =>
                application.get('id') === action.json.application.id,
            );
          if (index > -1) {
            path = path.concat([index, 'sections']);
            const sectionIndex = newState
              .getIn(path)
              .findIndex(section => section.get('id') === action.json.id);
            if (sectionIndex > -1) {
              newState = newState.setIn(
                path.concat(sectionIndex),
                Immutable.fromJS(action.json),
              );
            } else {
              newState = newState.setIn(
                path,
                newState.getIn(path).push(Immutable.fromJS(action.json)),
              );
            }
          }
        }
      }
    }
    // Delete sections optimistically
    if (action.type === `DELETE_${typeUpper}_APPLICATION_SECTION_REQUEST`) {
      let path = [action.eventId, 'data'];
      const index = newState
        .getIn(path)
        .findIndex(
          application => application.get('id') === action.applicationId,
        );
      if (index > -1) {
        path = path.concat([index, 'sections']);
        newState = newState.setIn(
          path,
          newState
            .getIn(path)
            .filter(section => section.get('id') !== action.sectionId),
        );
      }
    }
    if (
      action.type ===
      `UPDATE_${typeUpper}_APPLICATION_SECTION_SORT_ORDERS_REQUEST`
    ) {
      const index = newState
        .getIn([action.eventId, 'data'])
        .findIndex(
          application => application.get('id') === action.applicationId,
        );
      const path = [action.eventId, 'data', index, 'sections'];
      action.payload.forEach((id, sortOrder) => {
        const sectionIndex = newState
          .getIn(path)
          .findIndex(section => section.get('id') === id);
        if (sectionIndex > -1) {
          newState = newState.setIn(
            path.concat([sectionIndex, 'sort_order']),
            sortOrder,
          );
        }
      });
    }

    return newState;
  };

  const applicationPublicReducer = (state = defaultState, action) => {
    let newState = state;

    if (action.originalType === `GET_${typeUpper}_APPLICATION_PUBLIC`) {
      newState = getApiReducer(`GET_${typeUpper}_APPLICATION_PUBLIC`)(
        newState,
        action,
      );
    }
    if (action.originalType === `GET_${typeUpper}_APPLICATION`) {
      newState = getApiReducer(`GET_${typeUpper}_APPLICATION`)(
        newState,
        action,
      );
    }

    if (action.type === `UPDATE_${typeUpper}_APPLICATION_SECTION_PUBLIC`) {
      const index = newState
        .getIn(['data', 'sections'])
        .findIndex(section => section.get('id') === action.sectionId);
      newState = newState.setIn(
        ['data', 'sections', index, 'custom_fields'],
        action.schema,
      );
    }

    if (action.type === `SAVE_${typeUpper}_APPLICATION_PUBLIC_FAILURE`) {
      const {
        json: { validationErrors },
      } = action;
      const error = validationErrors?.find(
        ({ type }) => type === 'customFields',
      );
      if (error) {
        error.context.forEach(({ id, fields }) => {
          const index = newState
            .getIn(['data', 'sections'])
            .findIndex(section => section.get('id') === id);
          newState = newState.setIn(
            ['data', 'sections', index, 'custom_fields'],
            fields,
          );
        });
      }
    }

    if (action.type === `UPDATE_${typeUpper}_APPLICATION_PUBLIC_FAILURE`) {
      const {
        json: { validationErrors },
      } = action;
      const error = validationErrors?.find(
        ({ type }) => type === 'customFields',
      );
      if (error) {
        error.context.forEach(({ id, fields }) => {
          const index = newState
            .getIn(['data', 'sections'])
            .findIndex(section => section.get('id') === id);
          newState = newState.setIn(
            ['data', 'sections', index, 'custom_fields'],
            fields,
          );
        });
      }
    }

    return newState;
  };

  const applicationRequestsReducer = (state = defaultState, action) => {
    let newState = state;
    if (
      action.originalType === `GET_${typeUpper}_APPLICATIONS_FOR_EVENT` ||
      action.originalType === `APPROVE_${typeUpper}_APPLICATION` ||
      action.originalType === `DENY_${typeUpper}_APPLICATION`
    ) {
      newState = getApiReducer(action.originalType)(newState, action);
    }

    if (
      action.type === 'EDIT_CONTACT_SUCCESS' &&
      action.json.department.type === type
    ) {
      const departmentList = newState.get('data');
      if (departmentList) {
        const departmentIndex = departmentList.findIndex(
          dept => dept.get('id') === action.json.department.id,
        );

        if (departmentIndex > -1) {
          let path = ['data', departmentIndex, 'credentialRequests'];
          newState = newState.setIn(
            path,
            newState
              .getIn(path)
              .filter(
                credReq => credReq.get('requested_for') !== action.json.id,
              )
              .concat(
                Immutable.fromJS(action.json.credentialRequests).map(credReq =>
                  credReq.delete('credential'),
                ),
              ),
          );

          const contactIndex = newState
            .getIn(['data', departmentIndex, 'contacts'])
            .findIndex(contact => contact.get('id') === action.json.id);

          if (contactIndex > -1) {
            newState = newState.mergeIn(
              ['data', departmentIndex, 'contacts', contactIndex],
              action.json,
            );
          }
        }
      }
    }

    if (
      action.type === 'REMOVE_CREDENTIAL_QUANTITIES_SUCCESS' &&
      action.json.credentialRequest.department.type === type
    ) {
      const departmentList = newState.get('data');
      if (departmentList) {
        const departmentIndex = departmentList.findIndex(
          department =>
            department.get('id') ===
            action.json.credentialRequest.department.id,
        );

        if (departmentIndex > -1) {
          let path = ['data', departmentIndex, 'credentialRequests'];

          newState = newState.setIn(
            path,
            newState
              .getIn(path)
              .filterNot(
                credReq =>
                  credReq.get('requested_for') ===
                    action.json.credentialRequest.requestedFor.id &&
                  credReq.get('credential_id') ===
                    action.json.credentialRequest.credential.id,
              ),
          );

          const contactIndex = newState
            .getIn(['data', departmentIndex, 'contacts'])
            .findIndex(
              contact =>
                contact.get('id') ===
                action.json.credentialRequest.requestedFor.id,
            );

          if (contactIndex > -1) {
            const path = [
              'data',
              departmentIndex,
              'contacts',
              contactIndex,
              'credentialRequests',
            ];
            newState = newState.setIn(
              path,
              newState
                .getIn(path)
                .filter(
                  credReq =>
                    credReq.get('credential_id') !==
                    action.json.credentialRequest.credential.id,
                ),
            );
          }
        }
      }
    }

    return newState;
  };

  const credentialsReducer = (state = Immutable.Map(), action) => {
    let newState = state;

    if (
      action.originalType ===
      `GET_${typeUpper}_APPLICATION_AVAILABLE_CREDENTIALS`
    ) {
      newState = newState.set(
        action.eventId,
        getApiReducer(action.originalType)(
          newState.get(action.eventId),
          action,
        ),
      );
    }

    return newState;
  };

  const emailTypes = (state = defaultState, action) => {
    let newState = state;

    if (action.originalType === `GET_${typeUpper}_APPLICATION_EMAIL_TYPES`) {
      newState = getApiReducer(`GET_${typeUpper}_APPLICATION_EMAIL_TYPES`)(
        newState,
        action,
      );
    }

    if (action.applicationType !== type) return newState;

    const getEmailTypeSamplesPath = action => {
      const path = ['data'];

      const index = newState
        .getIn(path)
        .findIndex(emailType => emailType.get('id') === action.emailTypeId);

      return path.concat([index, 'samples']);
    };

    const getEmailTypesSamplePath = action => {
      const path = getEmailTypeSamplesPath(action);

      const index = newState
        .getIn(path)
        .findIndex(
          sample =>
            String(sample.get('id')) === String(action.emailTypeSampleId),
        );

      return path.concat(index);
    };

    if (action.type === `${ADD_EMAIL_TYPE_SAMPLE}_SUCCESS`) {
      const path = getEmailTypeSamplesPath(action);
      if (newState.hasIn(path))
        newState = newState.updateIn(path, samples =>
          samples.push(Immutable.fromJS(action.json)),
        );
    }

    if (action.type === `${UPDATE_EMAIL_TYPE_SAMPLE}_SUCCESS`) {
      const path = getEmailTypesSamplePath(action);
      if (newState.hasIn(path))
        newState = newState.setIn(path, Immutable.fromJS(action.json));
    }

    if (action.type === `${DELETE_EMAIL_TYPE_SAMPLE}_SUCCESS`) {
      const path = getEmailTypesSamplePath(action);
      if (newState.hasIn(path)) newState = newState.deleteIn(path);
    }

    return newState;
  };

  const getAdvanceAppEmailSettings = (state = Immutable.Map(), action) => {
    let newState = state;

    if (action.originalType === `GET_${typeUpper}_APPLICATION_EMAIL_TEMPLATE`) {
      newState = getApiReducer(action.originalType)(newState, action);
    }
    return newState;
  };

  return {
    applications: applicationsReducer,
    applicationPublic: applicationPublicReducer,
    applicationRequests: applicationRequestsReducer,
    applicationResponses: getApiReducer(
      `GET_${typeUpper}_APPLICATION_RESPONSES`,
    ),
    credentials: credentialsReducer,
    emailTypes,
    emailSettings: getAdvanceAppEmailSettings,
  };
};

export default getApplicationReducers;
