import { css } from 'styled-components';

export const media = {
  phone: (...args) => css`
    @media (max-width: 480px) {
      ${css(...args)};
    }
  `,
  tabletPortrait: (...args) => css`
    @media (max-width: 600px) {
      ${css(...args)};
    }
  `,
  tabletLandscape: (...args) => css`
    @media (max-width: 900px) {
      ${css(...args)};
    }
  `,
  desktop: (...args) => css`
    @media (max-width: 1200) {
      ${css(...args)};
    }
  `,
};
