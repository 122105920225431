import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentEvent } from '../event/event-selectors';
import {
  getAdvanceApplicationEmailTypes,
  getAdvanceApplications,
} from './advance-actions';

const AdvanceApplicationLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const event = useSelector(state => getCurrentEvent(state, { params }));

  useEffect(() => {
    if (!event) return;
    const eventId = event.get('id');
    dispatch(getAdvanceApplications(eventId));
    dispatch(getAdvanceApplicationEmailTypes());
  }, [dispatch, event]);

  return <Outlet />;
};

export default AdvanceApplicationLoader;
