import PropTypes from 'prop-types';
import moment from 'moment';

const TimeDisplay = ({ label, value }) => (
  <div>
    <dt>{label}:</dt>
    <dd>{moment.unix(value).format('hh:mma')}</dd>
  </div>
);

TimeDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TimeDisplay;
