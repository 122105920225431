import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import { isRequired } from 'revalidate';
import {
  changeEventEnabledStatus,
  changeGroupsEnabledStatus,
} from './event-actions';
import CropperScaffold from '../common/forms/CropperScaffold';
import DropNCrop from '../common/forms/DropNCrop';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import Paper from '../common/paper/Paper';
import PaperHeader from '../common/paper/PaperHeader';
import PaperSwitch from '../common/paper/PaperSwitch';
import StatusButton from '../common/StatusButton';
import EventSettings from './EventSettings.js';

const EventForm = ({
  event,
  title,
  backTo,
  handleSubmit,
  invalid,
  modifiedSinceLastSubmit,
  submitting,
  submitFailed,
  submitSucceeded,
  pristine,
  values,
  form: { change },
}) => {
  const dispatch = useDispatch();

  const checkStatus = () => {
    if (submitting) {
      return 'loading';
    } else if (submitSucceeded && !modifiedSinceLastSubmit) {
      return 'success';
    } else if (submitFailed && !modifiedSinceLastSubmit) {
      return 'error';
    }
    return 'default';
  };

  const handleEventActivation = useCallback(
    activated => {
      dispatch(changeEventEnabledStatus(event.get('id'), !activated));
    },
    [dispatch, event],
  );

  const handleGroupsActivation = useCallback(
    activated => {
      dispatch(changeGroupsEnabledStatus(event.get('id'), activated));
    },
    [dispatch, event],
  );

  return (
    <form className="generic-form paper__wrapper" onSubmit={handleSubmit}>
      <Paper>
        <PaperHeader
          backTo={backTo}
          backToDisabled={submitting}
          title={title}
        />
        <div className="generic-form__body flex">
          <div className="input-group input-group--large flex-column">
            <Field
              name="name"
              component={ReduxFormsField}
              label="Event Name"
              required
              validate={isRequired('Name')}
            >
              <input type="text" />
            </Field>
            <Field name="file_id" render={() => null} />
            {values.file_id ? (
              <CropperScaffold label="Logo">
                <div className="original-image">
                  <img
                    alt="event logo"
                    src={event.getIn(['logo', 'location'])}
                  />
                </div>
                <div className="cropper-scaffold__controls">
                  <button
                    type="button"
                    className="button button--red"
                    onClick={() => change('file_id', null)}
                  >
                    Remove Logo
                  </button>
                </div>
              </CropperScaffold>
            ) : (
              <Field
                name="logo"
                component={ReduxFormsFieldNoLabel}
                label="Logo"
                className={
                  event && event.get('deactivated') ? 'event-deactivated' : ''
                }
              >
                <DropNCrop showRemoveImage removeImageText="Remove Logo" />
              </Field>
            )}
            <Field name="time_zone" component="input" type="hidden" />
          </div>
          <div className="flex-column">
            {event && (
              <PaperSwitch
                name="deactivated"
                title={
                  event.get('deactivated')
                    ? 'Event Is Deactivated'
                    : 'Event Is Activated'
                }
                activated={!event.get('deactivated')}
                disabled={false}
                performAction={handleEventActivation}
              />
            )}
            {event && (
              <div style={{ marginTop: '20px' }}>
                <PaperSwitch
                  name="groupsEnabled"
                  title={
                    !!event.get('groups_enabled')
                      ? 'Groups Are Enabled'
                      : 'Groups Are Disabled'
                  }
                  activated={!!event.get('groups_enabled')}
                  disabled={false}
                  performAction={handleGroupsActivation}
                />
              </div>
            )}
          </div>
        </div>
      </Paper>
      <Paper>
        <PaperHeader title="Departments" />
      </Paper>
      <EventSettings />
      <Paper>
        <div className="generic-form__footer">
          <StatusButton
            type="submit"
            buttonText={values.id ? 'Save' : 'Create'}
            disabled={
              submitting || pristine || (invalid && !modifiedSinceLastSubmit)
            }
            status={checkStatus()}
          />
          {backTo && (
            <Link
              className="button button--plain"
              disabled={submitting}
              to={backTo}
            >
              Cancel
            </Link>
          )}
        </div>
      </Paper>
    </form>
  );
};

EventForm.propTypes = {
  event: PropTypes.object,
  title: PropTypes.string.isRequired,
  backTo: PropTypes.string,
  clearOrigImage: PropTypes.bool,
  removeImage: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  values: PropTypes.object.isRequired,
};

export default EventForm;
