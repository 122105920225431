import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { getIn } from 'final-form';
import { FormSectionContext } from './FormSectionContext';
import PropTypes from 'prop-types';
import Icon from '../icons/Icon';
import Switch from './Switch';
import FormField from './FormField';

const getValues = (prefix, formValues, topLevelFieldName, toggles) => {
  const values = [];
  const fieldNames = [];

  if (topLevelFieldName) {
    fieldNames.push(topLevelFieldName);
  }

  toggles.forEach(toggle => {
    fieldNames.push(toggle.name);
  });

  fieldNames.forEach(name => {
    values[name] = getIn(formValues, `${prefix ? `${prefix}.` : ''}${name}`);
  });

  return values;
};

const ToggleGroup = ({
  label,
  topLevelFieldName,
  description,
  disabled,
  toggles,
}) => {
  const { change } = useForm();
  const { values: formValues } = useFormState();
  const prefix = useContext(FormSectionContext);

  const values = getValues(prefix, formValues, topLevelFieldName, toggles);

  const [expanded, setExpanded] = useState(() => {
    if (topLevelFieldName) return false;
    // Set initial expansion state based on form values: if any toggles are on, show expanded
    const toggleStates = [];
    Object.keys(values).forEach(key => toggleStates.push(values[key]));
    return toggleStates.indexOf(true) > -1;
  });

  const isExpanded = useCallback(
    () => (topLevelFieldName ? values[topLevelFieldName] : expanded),
    [expanded, topLevelFieldName, values],
  );

  const toggleExpansion = () => setExpanded(prevExpanded => !prevExpanded);

  useEffect(() => {
    if (!isExpanded) {
      // Unset all values if collapsed
      Object.keys(values).forEach(key => {
        change(`${prefix ? `${prefix}.` : ''}${key}`, false);
      });
    }
  }, [change, isExpanded, prefix, values]);

  const renderDescription = (description, toggleIsOn) => {
    const toggledOff = toggleIsOn ? '' : ' toggledOff';
    return (
      <div
        id="toggleDescription"
        className={`toggle-group__item toggle-group__item--description${toggledOff}`}
      >
        {description && (
          <>
            <Icon icon="InfoCircle" className="toggle-group__item--icon" />
            <p>{description}</p>
          </>
        )}
      </div>
    );
  };

  const renderToggle = toggle => {
    const { name, label, description, disabledIfToggled, hiddenIfToggled } =
      toggle;
    const toggleIsOn = values[name];

    if (hiddenIfToggled) {
      if (Array.isArray(hiddenIfToggled)) {
        for (const toggle of hiddenIfToggled) {
          if (values[toggle]) {
            return null;
          }
        }
      } else if (values[hiddenIfToggled]) {
        return null;
      }
    }

    return (
      <div key={name} className="toggle-group__child">
        <div className="toggle-group__item toggle-group__item--label">
          {label}
        </div>

        {description && renderDescription(description, toggleIsOn)}

        <div className="toggle-group__item toggle-group__item--toggle">
          <FormField
            name={name}
            component={Switch}
            required
            disabled={
              disabled || (disabledIfToggled && values[disabledIfToggled])
            }
          />
        </div>
      </div>
    );
  };

  const renderTopLevelToggle = () => (
    <div className="toggle-group__item toggle-group__item--toggle">
      {topLevelFieldName && (
        <FormField
          name={topLevelFieldName}
          component={Switch}
          disabled={disabled}
        />
      )}
      {!topLevelFieldName && (
        <Switch
          input={{
            name: 'expander',
            value: isExpanded(),
            onChange: toggleExpansion,
          }}
          disabled={disabled}
        />
      )}
    </div>
  );

  return (
    <div className="toggle-group">
      <div className="toggle-group__parent">
        <div className="toggle-group__item toggle-group__item--label">
          <strong>{label}</strong>
        </div>
        {renderDescription(description, values[topLevelFieldName])}
        {renderTopLevelToggle()}
      </div>
      {isExpanded() && toggles.map(renderToggle)}
    </div>
  );
};

ToggleGroup.propTypes = {
  label: PropTypes.string.isRequired,
  topLevelFieldName: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  toggles: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      disabledIfToggled: PropTypes.string,
      hiddenIfToggled: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    }),
  ).isRequired,
};

export default ToggleGroup;
