import { combineReducers } from 'redux';
import Immutable from 'immutable';
import getApiReducer from '../common/utils/getApiReducer';
import { FETCH_CONTACT_UPLOADS, FETCH_CONTACT_LIST } from './contact-actions';

const contactList = (state = Immutable.Map(), action) => {
  let newState = state;
  if (action.originalType === FETCH_CONTACT_LIST) {
    newState = newState.setIn(
      [action.departmentId],
      getApiReducer(FETCH_CONTACT_LIST)(
        newState.getIn([action.departmentId]),
        action,
      ),
    );
  }

  if (action.type === 'UPDATE_CREDENTIAL_QUANTITIES_SUCCESS') {
    const contactList = newState.getIn([action.json.department_id, 'data']);
    if (contactList) {
      const contactIndex = contactList.findIndex(
        contact => contact.get('id') === action.json.requested_for,
      );
      if (contactIndex !== -1) {
        const credentialRequestIndex = newState
          .getIn([
            action.json.department_id,
            'data',
            contactIndex,
            'credentialRequests',
          ])
          .findIndex(
            credReq =>
              credReq.get('credential_id') === action.json.credential_id,
          );
        if (credentialRequestIndex !== -1) {
          newState = newState.setIn(
            [
              action.json.department_id,
              'data',
              contactIndex,
              'credentialRequests',
              credentialRequestIndex,
            ],
            Immutable.fromJS(action.json),
          );
        }
      }
    }
  }

  if (action.type === 'SUBMIT_CONTACT_SUCCESS') {
    newState = newState.setIn(
      [action.departmentId, 'data'],
      newState
        .getIn([action.departmentId, 'data'])
        .unshift(Immutable.fromJS(action.json)),
    );
  }

  if (action.type === 'EDIT_CONTACT_SUCCESS') {
    const dataPath = [action.json.department_id, 'data'];
    const contactData = newState.getIn(dataPath);
    if (contactData) {
      const editedContactIndex = contactData.findIndex(
        contact => contact.get('id') === action.json.id,
      );
      if (editedContactIndex > -1) {
        newState = newState.mergeIn(
          dataPath.concat(editedContactIndex),
          Immutable.fromJS(action.json),
        );
      }
    }
  }

  if (action.type === 'ONSITE_REQUEST_NAME_CHANGE_SUCCESS') {
    const dataPath = [action.json.contact.department_id, 'data'];
    const contactData = newState.getIn(dataPath);
    if (contactData) {
      const editedContactIndex = contactData.findIndex(
        contact => contact.get('id') === action.json.contact.id,
      );
      if (editedContactIndex > -1) {
        newState = newState.mergeIn(
          dataPath.concat(editedContactIndex),
          Immutable.fromJS(action.json.contact),
        );
      }
    }
  }

  if (action.type === 'ONSITE_REQUEST_ADD_CONTACT_SUCCESS') {
    newState = newState.setIn(
      [action.json.contact.department_id, 'data'],
      newState
        .getIn([action.json.contact.department_id, 'data'])
        .push(Immutable.fromJS(action.json.contact)),
    );
  }

  if (action.type === 'DELETE_ONSITE_CREDENTIAL_REQUEST_SUCCESS') {
    let dataPath = [action.json.department_id, 'data'];
    const contactData = newState.getIn(dataPath);
    if (contactData) {
      const editedContactIndex = contactData.findIndex(
        contact => contact.get('id') === action.json.requested_for,
      );
      if (editedContactIndex > -1) {
        const onsiteRequestsPath = dataPath.concat([
          editedContactIndex,
          'onsiteRequests',
        ]);
        const onsiteRequests = newState.getIn(onsiteRequestsPath);
        if (onsiteRequests) {
          const onsiteRequestIndex = onsiteRequests.findIndex(
            onsiteRequest => onsiteRequest.get('id') === action.json.id,
          );
          if (onsiteRequestIndex > -1) {
            newState = newState.setIn(
              onsiteRequestsPath.concat(onsiteRequestIndex),
              Immutable.fromJS(action.json),
            );
          }
        }
      }
    }
  }

  if (
    [
      'ONSITE_REQUEST_EDIT_CONTACT_SUCCESS',
      'APPROVE_ONSITE_REQUEST_SUCCESS',
      'DECLINE_ONSITE_REQUEST_SUCCESS',
    ].includes(action.type)
  ) {
    let dataPath = [action.json.contact.department_id, 'data'];
    const contactData = newState.getIn(dataPath);
    if (contactData) {
      const editedContactIndex = contactData.findIndex(
        contact => contact.get('id') === action.json.contact.id,
      );
      if (editedContactIndex > -1) {
        if (action.json.contact.deleted_at) {
          newState = newState.deleteIn(dataPath.concat(editedContactIndex));
        } else {
          newState = newState.mergeIn(
            dataPath.concat(editedContactIndex),
            Immutable.fromJS(action.json.contact),
          );
        }
      }
    }
  }

  if (action.type === 'DELETE_CONTACT_SUCCESS') {
    const dataPath = [action.departmentId, 'data'];
    if (newState.size) {
      newState = newState.setIn(
        dataPath,
        newState
          .getIn(dataPath)
          .filter(contact => contact.get('id') !== action.contactId),
      );
    }
  }

  if (action.type === 'ONSITE_REQUEST_DELETE_CONTACT_SUCCESS') {
    const dataPath = [action.json.contact.department_id, 'data'];
    if (newState.size) {
      newState = newState.setIn(
        dataPath,
        newState
          .getIn(dataPath)
          .filter(contact => contact.get('id') !== action.json.contact.id),
      );
    }
  }

  if (action.type === 'REMOVE_CREDENTIAL_QUANTITIES_SUCCESS') {
    const contactId =
      (action.json.credentialRequest.requestedFor &&
        action.json.credentialRequest.requestedFor.id) ||
      'group';
    const departmentId = action.json.credentialRequest.department.id;
    const departmentData = newState.getIn([departmentId, 'data']);
    if (departmentData) {
      const contactIndex = departmentData.findIndex(
        contact => contact.get('id') === contactId,
      );
      const deptCredRequests = newState.getIn([
        departmentId,
        'data',
        contactIndex,
        'credentialRequests',
      ]);

      if (deptCredRequests) {
        const credentialRequestIndex = deptCredRequests.findIndex(
          cr => cr.get('id') === action.credentialRequestId,
        );

        if (action.json.action === 'delete') {
          if (contactIndex > -1 && credentialRequestIndex > -1) {
            newState = newState.deleteIn([
              departmentId,
              'data',
              contactIndex,
              'credentialRequests',
              credentialRequestIndex,
            ]);
          }
        } else {
          newState = newState.setIn(
            [
              departmentId,
              'data',
              contactIndex,
              'credentialRequests',
              credentialRequestIndex,
              `quantity_${action.json.type}`,
            ],
            0,
          );
        }
      }
    }
  }

  return newState;
};

const uploads = (state = Immutable.Map(), action) => {
  let newState = state;

  if (action.originalType === FETCH_CONTACT_UPLOADS) {
    newState = newState.setIn(
      [action.departmentId],
      getApiReducer(FETCH_CONTACT_UPLOADS)(
        newState.getIn([action.departmentId]),
        action,
      ),
    );
  }

  return newState;
};

const reducers = combineReducers({
  contactList,
  uploads,
});

export default reducers;
