import { useMemo } from 'react';
import { List, Map as ImmutableMap } from 'immutable';
import { getPeriods } from './utils';
import { getCredentialDateRange } from '../../common/utils/credentials/credential-utils';
import { getGroupCredentialCategories } from '../group-credential-form-selectors';
import {
  CategoryHeader,
  Column,
  Container,
  Description,
  EndColumn,
  FirstColumn,
  PeriodHeader,
  Quantity,
  Row,
  Tag,
} from './CredentialTags.styles';
import PropTypes from 'prop-types';

const CredentialTags = ({ department, contacts, requests, sortRequests }) => {
  const groupCredentialCategories = getGroupCredentialCategories(requests);
  const departmentName = department.get('name');

  const periods = useMemo(() => getPeriods(requests), [requests]);

  const findTotal = request =>
    request.get('quantity_approved') +
    contacts
      .filter(contact => contact.get('is_group'))
      .reduce((accum, contact) => {
        const matchingReq = contact
          .get('credentialRequests')
          .find(
            credential =>
              credential.get('credential_id') === request.get('credential_id'),
          );
        if (matchingReq) {
          return accum + matchingReq.get('quantity_approved');
        }
        return accum;
      }, 0);

  const getPulseTagsCount = request => {
    if (!request) {
      return 0;
    }

    let pulseOrders = department.get('pulseOrders');
    if (!pulseOrders) {
      return 0;
    }

    let pulseTags = pulseOrders.flatMap(pulseOrder =>
      pulseOrder.get('pulseTags'),
    );
    if (!pulseTags) {
      return 0;
    }

    return pulseTags.filter(
      pulseTag =>
        pulseTag.get('pulse_credential_id') ===
        request.getIn(['credential', 'pulse_credential_id']),
    ).size;
  };

  const renderCredentialRequests = (category, period) => {
    const categoryRequests = requests
      .filter(request => {
        const credential = request.get('credential');
        const credentialType = credential.get('credentialType');
        if (category.value !== credentialType.get('category_id')) {
          return false;
        }

        return credentialType.get('issue_frequency') === 'ONE_TIME'
          ? credential
              .get('oneTimePeriods')
              .some(otp => period.id === otp.get('id'))
          : credential.get('period_id') === period.id;
      })
      .sort(sortRequests);

    return (
      <Column key={period.id}>
        <Row style={{ margin: 0 }}>
          {categoryRequests?.map(request => {
            const credential = request.get('credential');
            const credentialType = credential.get('credentialType');
            const total = findTotal(request);
            return total > 0 ? (
              <Tag key={request.get('id')}>
                <Description>
                  <p>{credentialType.get('name')}</p>
                  {credentialType.get('issue_frequency') === 'DAILY' && (
                    <p>{getCredentialDateRange(credential)}</p>
                  )}
                </Description>
                <Quantity>
                  {Math.max(
                    request.get('quantity_approved') -
                      getPulseTagsCount(request),
                    0,
                  )}{' '}
                  / {total}
                </Quantity>
                <Column />
              </Tag>
            ) : null;
          })}
        </Row>
      </Column>
    );
  };

  const renderCredentialCategory = category => (
    <div key={`category-${category.value}`}>
      <Row>
        <FirstColumn>
          <CategoryHeader>
            <span
              className="credential-category-color"
              style={{ backgroundColor: `${category.color}` }}
            />
            <span>{category.label.toUpperCase()}</span>
          </CategoryHeader>
        </FirstColumn>
        {periods.map(period => renderCredentialRequests(category, period))}
        <EndColumn />
      </Row>
    </div>
  );

  return (
    <Container>
      <h3>
        Available Credentials{departmentName ? ` for ${departmentName}` : ''}
      </h3>
      <Row>
        <FirstColumn />
        {periods?.map(period => (
          <Column key={period.id}>
            <PeriodHeader>
              <p>{period.name}</p>
              <p>{period.dateRange}</p>
            </PeriodHeader>
          </Column>
        ))}
        <EndColumn />
      </Row>
      {groupCredentialCategories.map(category =>
        renderCredentialCategory(category),
      )}
    </Container>
  );
};

CredentialTags.propTypes = {
  department: PropTypes.instanceOf(ImmutableMap).isRequired,
  contacts: PropTypes.instanceOf(List).isRequired,
  requests: PropTypes.instanceOf(List).isRequired,
  sortRequests: PropTypes.func.isRequired,
};

export default CredentialTags;
