import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { resource, useACL } from '../common/ACL';
import { Map } from 'immutable';
import { showNotification } from '../notification/notification-actions';
import { saveGroupCredentialRequests } from './group-credential-actions';
import { useDepartmentUnsavedCredentialsDispatch } from '../contact/DepartmentUnsavedCredentials';
import {
  UnsavedCredentialsProvider,
  selectUnsavedCredentialsWithQuantity,
  useUnsavedCredentials,
  useUnsavedCredentialsDispatch,
} from '../contact/UnsavedCredentials';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import StatusButton from '../common/StatusButton';
import ExpandableRow from '../common/ExpandableRow';
import ContactCredentials, {
  GROUP_UNASSIGNED,
} from '../contact/ContactCredentials';

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  align-items: center;
  > div {
    margin-left: 12px;
  }
`;

const ErrorIcon = styled.div`
  span.icon svg {
    fill: #e74c3c !important;
  }
`;

const aclRules = {
  canRequestCredentials: [resource.DEPARTMENT, 'request-credential'],
  canReconcileOrder: [resource.EVENT, 'reconcile-order'],
};

const GroupCredentialRequests = ({ department }) => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();

  const departmentUnsavedCredentialsDispatch =
    useDepartmentUnsavedCredentialsDispatch();

  const unsavedCredentialsDispatch = useUnsavedCredentialsDispatch();
  const unsavedCredentials = selectUnsavedCredentialsWithQuantity(
    useUnsavedCredentials(),
  );

  const [expanded, setExpanded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const onSaving = useCallback(isSaving => setSaving(isSaving), []);
  const onDeleting = useCallback(isDeleting => setDeleting(isDeleting), []);

  const pulseOrderOutOfSync =
    department
      .get('pulseOrders')
      ?.some(
        pulseOrder =>
          !pulseOrder.get('contact_id') && pulseOrder.get('out_of_sync'),
      ) ?? false;

  const hasGroupCredentialRequests = department
    .get('credentialRequests')
    .some(request => request.get('requested_for') === null);

  const hasGroupLevelCredentialTypes = () =>
    department
      .get('credentialTypes')
      .some(
        credentialType =>
          credentialType.get('group_level_available') &&
          credentialType
            .get('attendeePickupTypes')
            .some(apt => apt.get('pickup_type') === 'group'),
      );

  const clearDepartmentUnsavedCredentials = () => {
    departmentUnsavedCredentialsDispatch({
      type: 'subtract',
      changedQuantities: unsavedCredentials.map(unsavedCredential => ({
        credentialId: unsavedCredential.id,
        quantity: unsavedCredential.quantity,
      })),
    });
  };

  const displayErrors = action => {
    const errors = action.json.errors;
    if (errors?.length) {
      const message = errors
        .map(error => error.error)
        .reduce((acc, error) => (error ? `${acc}${error}.\n` : acc), '');
      dispatch(showNotification({ message, duration: 60000, status: 'error' }));
    }
  };

  const handleSubmit = () => {
    if (unsavedCredentials) {
      setSubmitting(true);
      return dispatch(
        saveGroupCredentialRequests(department.get('id'), {
          credentials: unsavedCredentials,
        }),
      ).then(action => {
        setSubmitting(false);
        if (action.response.ok) {
          displayErrors(action);
          clearDepartmentUnsavedCredentials();
          unsavedCredentialsDispatch({ type: 'clear' });
        } else {
          showNotification({
            status: 'error',
            message: 'Error occurred submitting group credentials',
          });
        }
      });
    }
    return false;
  };

  const columns = ['Group Level Credentials'];
  if (pulseOrderOutOfSync)
    columns.push(
      <IconContainer>
        <ErrorIcon data-tooltip-id="department-out-of-sync-tooltip">
          {acl.canReconcileOrder && (
            <Link to="../../reconcile">
              <Icon icon="ErrorTriangle" />
            </Link>
          )}
          {!acl.canReconcileOrder && <Icon icon="ErrorTriangle" />}
          <Tooltip
            id="department-out-of-sync-tooltip"
            content="Pulse Order Out of Sync"
          />
        </ErrorIcon>
      </IconContainer>,
    );

  return hasGroupCredentialRequests || hasGroupLevelCredentialTypes() ? (
    <ExpandableRow
      columns={columns}
      isExpanded={expanded}
      isExpandable
      onToggleExpansion={() => setExpanded(!expanded)}
    >
      <ContactCredentials
        type={GROUP_UNASSIGNED}
        department={department}
        headerStyle={{ borderLeft: 'none' }}
        onSaving={onSaving}
        onDeleting={onDeleting}
      />
      <div className="contact-details-section__footer">
        {acl.canReconcileOrder && (
          <Link to="../../reconcile">Pulse Orders</Link>
        )}
        <StatusButton
          type="button"
          onClick={handleSubmit}
          disabled={
            !acl.canRequestCredentials ||
            submitting ||
            saving ||
            deleting ||
            unsavedCredentials.length === 0
          }
          status={submitting || saving || deleting ? 'loading' : 'default'}
        />
      </div>
    </ExpandableRow>
  ) : null;
};

GroupCredentialRequests.propTypes = {
  department: PropTypes.instanceOf(Map).isRequired,
};

const GroupCredentialRequestsWrapper = props => (
  <UnsavedCredentialsProvider>
    <GroupCredentialRequests {...props} />
  </UnsavedCredentialsProvider>
);

export default GroupCredentialRequestsWrapper;
