import { forwardRef } from 'react';
import Select, { components } from 'react-select';

const primary = '#4183D7';
const grayLighter = '#ccc';
const grayDarkest = '#201c23';

const styles = {
  control: (_styles, { isFocused, menuIsOpen }) => ({
    backgroundColor: '#fff',
    border: `2px solid ${grayLighter}`,
    borderColor: isFocused ? primary : null,
    borderBottomLeftRadius: menuIsOpen ? '0' : null,
    borderBottomRightRadius: menuIsOpen ? '0' : null,
    borderCollapse: 'separate',
    borderRadius: '2px',
    borderSpacing: '0',
    boxShadow: isFocused
      ? menuIsOpen
        ? `0px 0px 0px 2px rgba(65, 131, 215, 0.5)`
        : 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1)'
      : null,
    boxSizing: 'border-box',
    color: '#333',
    cursor: 'default',
    display: 'flex',
    height: '44px',
    outline: 'none',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  }),
  dropdownIndicator: () => ({
    background: 'none',
    backgroundRepeat: 'no-repeat',
    backgroundImage:
      "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABYUlEQVRoge2YMU7EMBBF3yIuhFJyARo6aKHOCTYFHQU5AUegouMaEZehQ1pBgSMi5Dgz9hizYl4VxfbkP8lx7Oy6ruMYmKYpev/kl3OY4wKtcYHWuEBrXKA1LtAaF2hNjsCZeYqC2lqBHngFBu2DBAyhdq8ZpBHogcdw/YCtxBBqEp4hlpAKLMPPWEksw8+IJSQCO+Bipa1UIhZ+5lxSQCLwAVwDLyvtuRKp8E/AraSIdAq9A1ekJe6FtQh9U+FvgIOkkOYl3pK4A0ZBnTH0jaEKD/pldEtiT1piDH1iqMND3ocsV8I8PORvJbQSVcIDnOYMCswSz8BlpH2/cr2kKDyUCYBO4ifF4cFmN7o1nWKYhAe77bRGwiw82J4HJBKm4cH+QJOSMA8PdU5kMYkq4aF8FVpjuTq9USk81BOAb4kDlcJDXQH4kqjKv/wr8adwgda4QGtcoDUu0BoXaM3RC3wCkn5bVLDSEjUAAAAASUVORK5CYII=')",
    backgroundSize: '24px 24px',
    backgroundPosition: 'right 12px center',
    cursor: 'pointer',
    height: '100%',
    width: '35px',
  }),
  input: styles => ({
    ...styles,
    cursor: 'text',
  }),
  menu: () => ({
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '0',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
    marginTop: '0',
    maxHeight: '200px',
    position: 'absolute',
    top: '100%',
    width: '100%',
    zIndex: '1',
    WebkitOverflowScrolling: 'touch',
  }),
  menuList: () => ({
    maxHeight: '198px',
    overflowY: 'auto',
  }),
  multiValue: () => ({
    backgroundColor: 'rgba(0, 126, 255, 0.08)',
    border: '1px solid rgba(0, 126, 255, 0.24)',
    borderRadius: '2px',
    color: '#007eff',
    display: 'flex',
    fontSize: '1.05em',
    lineHeight: '1.4',
    marginRight: '7px',
  }),
  multiValueLabel: () => ({
    padding: '2px 5px',
  }),
  multiValueRemove: () => ({
    alignItems: 'center',
    borderLeft: '1px solid rgba(0, 126, 255, 0.24)',
    cursor: 'pointer',
    display: 'flex',
    padding: '0px 3px',
    ':hover': {
      backgroundColor: 'rgba(0, 113, 230, 0.08)',
      color: '#0071e6',
    },
  }),
  option: (_styles, { isDisabled, isFocused, isSelected }) => ({
    alignItems: 'center',
    backgroundColor: isFocused
      ? 'rgba(0, 126, 255, 0.08)'
      : isSelected
      ? 'rgba(0, 126, 255, 0.04)'
      : '#fff',
    borderBottom: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    borderLeft: '1px solid #ccc',
    color: isDisabled ? '#ccc' : isFocused || isSelected ? '#333' : grayDarkest,
    cursor: isDisabled ? 'default' : 'pointer',
    display: 'flex',
    fontSize: '18px',
    height: '50px',
    padding: '8px 10px 8px 20px',
    position: 'relative',
    ':last-child': {
      borderRadius: '0',
    },
  }),
  placeholder: styles => ({
    ...styles,
    color: '#aaa',
    fontSize: '18px',
  }),
};

const IndicatorSeparator = () => null;
const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <></>
  </components.DropdownIndicator>
);

const ReduxReactSelectField = forwardRef(function ReduxReactSelectField(
  { components, onBlur, placeholder, value, ...rest },
  ref,
) {
  return (
    <Select
      ref={ref}
      components={Object.assign(
        {},
        {
          DropdownIndicator,
          IndicatorSeparator,
        },
        components,
      )}
      styles={styles}
      classNamePrefix="react-select"
      onBlur={() => (onBlur ? onBlur(value) : null)}
      placeholder={placeholder ?? null}
      value={value}
      {...rest}
    />
  );
});

export default ReduxReactSelectField;
