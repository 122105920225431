import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, FormSpy } from 'react-final-form';
import { getCurrentPromoter } from '../promoter/promoter-selectors';
import { getCurrentFestival } from '../festival/festival-selectors';
import { getCurrentEvent, getCurrentEventFlags } from './event-selectors';
import { fetchFestivalList } from '../festival/festival-actions';
import { fetchEventSettings, submitEvent } from './event-actions';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import recursiveTrim from '../common/utils/recursive-trim';
import LoadingIndicator from '../common/LoadingIndicator';
import EventForm from './EventForm';

const EventFormEdit = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const [initialValues, setInitialValues] = useState(null);

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const festival = useSelector(state => getCurrentFestival(state, { params }));
  const event = useSelector(state => getCurrentEvent(state, { params }));
  const eventSettings = useSelector(state =>
    getCurrentEventFlags(state, { params }),
  );

  useEffect(() => {
    if (formSubmitting) return;
    setInitialValues({
      festival_id: festival?.get('id'),
      id: event?.get('id'),
      name: event?.get('name'),
      settings: eventSettings,
      time_zone: event?.get('time_zone'),
      file_id: event?.get('file_id'),
      logo: {},
    });
  }, [event, eventSettings, festival, formSubmitting]);

  const handleSubmit = values =>
    dispatch(submitEvent(recursiveTrim(values))).then(async action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      } else {
        await dispatch(fetchFestivalList(promoter.get('id')));
        await dispatch(fetchEventSettings(event.get('id')));
        dispatch(
          showNotification({
            message: 'Event successfully saved',
            status: 'success',
          }),
        );
      }
    });

  if (!initialValues) return <LoadingIndicator />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={props => (
        <>
          <FormSpy
            subscription={{ submitting: true }}
            onChange={({ submitting }) => {
              setFormSubmitting(submitting);
            }}
          />
          <EventForm event={event} title="Edit Event" {...props} />
        </>
      )}
    />
  );
};

export default EventFormEdit;
