import PropTypes from 'prop-types';
import { Map } from 'immutable';

const FileDisplay = ({ label, value }) => (
  <div>
    <dt>{label}:</dt>
    <dd>
      {value && value.get('location') ? (
        <a href={value.get('location')} target="_blank" rel="noreferrer">
          Download
        </a>
      ) : (
        <div>No file attached</div>
      )}
    </dd>
  </div>
);

FileDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.instanceOf(Map),
};

export default FileDisplay;
