export const appTheme = {
  primary: '#4183D7',
  primaryLight: '#6BB9F0',
  primaryDark: '#446CB3',

  mediumBlue: '#8c99a8',
  darkBlue: '#5A6879',
  fadedBlue: '#5890ff',
  materialBlue: '#4283d8',
  success: '#2ECC71',
  warning: '#ffd653',
  pending: '#DEA74B',
  error: '#E74C3C',
  errorAlt: '#F96763',

  white: '#fff',
  lightBlueGray: '#F4F7FA',
  grayLightest: '#F5F5F5',
  grayLighter: '#CCCCCC',
  gray: '#aaa',
  grayMedium: '#626065',
  grayDark: '#3E3942',
  grayDarker: '#322D35',
  grayDarkest: '#201C23',
  black: '#000',

  formGray: '#8f8d91',

  sans: 'Roboto Condensed, sans-serif',
  mono: 'Source Code Pro monospace',
  headingColor: 'grayDarkest',
  bodyColor: 'grayDarkest',
  anchorColor: 'primary',
  regular: 400,
  bold: 700,
  fontBase: '1rem',

  /* Settings */
  gutter: 16,
  cardBorderRadius: 0,
  cardBoxShadow: '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
  fabBoxShadow: '0 0px 6px 0 rgba(0,0,0,0.12), 0 6px 10px 0 rgba(0,0,0,0.16)',

  /* Media Breakpoints */
  phone: 480,
  tabletPortrait: 600,
  tabletLandscape: 900,
  desktop: 1200,
};
