import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import CheckCircle from './icons/CheckCircle';

const StatusButton = forwardRef(
  (
    {
      status,
      buttonText = 'Save',
      completeText = 'Saved!',
      errorText = 'Error',
      ...props
    },
    _ref,
  ) => {
    let buttonChildren;

    switch (status) {
      case 'loading':
        buttonChildren = (
          <div className="button__children">
            <div className="spinner">
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </div>
          </div>
        );
        break;
      case 'error':
        buttonChildren = (
          <div className="button__children">
            <span>{errorText}</span>
          </div>
        );
        break;
      case 'success':
        buttonChildren = (
          <div className="button__children">
            <CheckCircle />
            <span>{completeText}</span>
          </div>
        );
        break;
      default:
        buttonChildren = (
          <div className="button__children">
            <span>{buttonText}</span>
          </div>
        );
        break;
    }

    return (
      <button className={`button button--${status}`} {...props}>
        {buttonChildren}
      </button>
    );
  },
);

StatusButton.propTypes = {
  status: PropTypes.oneOf(['default', 'loading', 'error', 'success']),
  buttonText: PropTypes.string,
  completeText: PropTypes.string,
  errorText: PropTypes.string,
};

export default StatusButton;
