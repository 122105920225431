import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { Checkbox } from 'synfrastructure';
import { StyledButton } from './StyledButton';
import { declineOnsiteRequest } from '../onsite/onsite-actions';
import { showNotification } from '../notification/notification-actions';
import get from 'lodash/get';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ToggleScaffold from './forms/ToggleScaffold';
import InputScaffold from './forms/InputScaffold';

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
`;
const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const DeclineOnsiteRequestModal = ({ onsiteRequest, trigger }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [adminNotes, setAdminNotes] = useState(null);
  const [declinePendingCredentials, setDeclinePendingCredentials] =
    useState(true);

  const open = e => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setSubmitting(false);
  };

  const handleDecline = async () => {
    setSubmitting(true);

    const action = await dispatch(
      declineOnsiteRequest(
        onsiteRequest.id,
        adminNotes,
        declinePendingCredentials,
      ),
    );

    if (action.json.validationErrors) {
      dispatch(
        showNotification({
          message: `${action.json.error}: ${action.json.message}`,
          status: 'error',
        }),
      );
    }

    if (action.json.credentials) {
      const rejected = action.json.credentials.rejected;
      if (rejected && rejected.length) {
        const message = rejected
          .map(error =>
            error.isBoom ? get(error, 'output.payload.message') : null,
          )
          .reduce((acc, error) => (error ? `${acc}${error}.\n` : acc), '');
        dispatch(
          showNotification({ message, duration: 60000, status: 'error' }),
        );
      }
    }

    close();
  };

  return (
    <Modal
      open={isOpen}
      onOpen={open}
      onClose={close}
      size="tiny"
      trigger={trigger}
    >
      <Modal.Header>Decline Onsite Request</Modal.Header>
      <Modal.Content>
        <Body>
          <InputScaffold required label="Admin Notes">
            <textarea
              value={adminNotes || ''}
              onChange={e => setAdminNotes(e.target.value)}
            />
          </InputScaffold>
          <ToggleScaffold label="Decline all pending credential requests">
            <Checkbox
              id="decline_credentials"
              checked={declinePendingCredentials}
              onChange={() =>
                setDeclinePendingCredentials(!declinePendingCredentials)
              }
            />
          </ToggleScaffold>
          <Footer>
            <StyledButton
              title="Cancel"
              color="gray"
              margin="right"
              handleClick={close}
            />
            <StyledButton
              disabled={!Boolean(adminNotes)}
              submitting={submitting}
              title="Decline"
              color="blue"
              handleClick={handleDecline}
            />
          </Footer>
        </Body>
      </Modal.Content>
    </Modal>
  );
};

DeclineOnsiteRequestModal.propTypes = {
  onsiteRequest: PropTypes.object.isRequired,
  trigger: PropTypes.node,
};

export default DeclineOnsiteRequestModal;
