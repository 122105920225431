import { Field } from 'react-final-form';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 12px;
  min-height: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex: initial;
  flex-direction: column;
  > div:not(:nth-of-type(1)) {
    margin-top: 10px;
  }
  > span {
    margin: auto;
    padding-bottom: 5px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

export const StyledQtyField = styled(Field)`
  display: flex;
  width: unset !important;
`;

export const NumberBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  width: 108px;
`;
