import { getCredentialStartDate } from '../common/utils/credentials/credential-utils';
import {
  selectUnsavedCredentialIds,
  useUnsavedCredentials,
} from './UnsavedCredentials';
import PropTypes from 'prop-types';
import UnsavedCredentialRequest from './UnsavedCredentialRequest';

const UnsavedCredentialRequestList = ({
  type,
  department,
  contact,
  availableCredentialTypes,
  credentialTypeId,
  periodId,
}) => {
  const unsavedCredentialIds = selectUnsavedCredentialIds(
    useUnsavedCredentials(),
  );

  const credentialIncludesPeriod = (credType, credential) =>
    credType.get('issue_frequency') === 'ONE_TIME'
      ? credential.get('oneTimePeriods').some(otp => periodId === otp.get('id'))
      : credential.get('period_id') === periodId;

  let credentials = [];
  if (unsavedCredentialIds.isEmpty() || availableCredentialTypes.isEmpty())
    return null;

  credentials = availableCredentialTypes
    .map(credentialType =>
      credentialType
        .get('credentials')
        .filter(
          credential =>
            unsavedCredentialIds.includes(credential.get('id')) &&
            credentialType.get('id') === credentialTypeId &&
            credentialIncludesPeriod(credentialType, credential),
        ),
    )
    .reduce((flatList, list) => flatList.concat(list))
    .sortBy(credential => getCredentialStartDate(credential));

  return (
    <div>
      {credentials.map(credential => (
        <div key={credential.get('id')}>
          <UnsavedCredentialRequest
            type={type}
            department={department}
            contact={contact}
            credential={credential}
          />
        </div>
      ))}
    </div>
  );
};

UnsavedCredentialRequestList.propTypes = {
  type: PropTypes.string.isRequired,
  department: PropTypes.object.isRequired,
  contact: PropTypes.object,
  availableCredentialTypes: PropTypes.object.isRequired,
  credentialTypeId: PropTypes.number.isRequired,
  periodId: PropTypes.number.isRequired,
};

export default UnsavedCredentialRequestList;
