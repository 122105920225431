import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { trimUriSegments } from '../common/uri';
import { getCurrentEvent } from '../event/event-selectors';
import { fetchEventOnsiteRequests } from './onsite-actions';
import { getCurrentOnsiteRequests } from './onsite-selectors';
import {
  getCategories,
  getCredentialTypes,
  getCredentials,
} from '../credential/type/credential-type-selectors';
import moment from 'moment';
import departmentTypeMap from '../lib/department-type-map';
import Icon from '../common/icons/Icon';
import Paper, { PaperHeader } from '../common/paper/Paper';
import LoadingIndicator from '../common/LoadingIndicator';
import StatusButton from '../common/StatusButton';
import ReactTable from '../lib/react-table';
import ApproveOnsiteRequestModal from '../common/ApproveOnsiteRequestModal';
import DeclineOnsiteRequestModal from '../common/DeclineOnsiteRequestModal';
import OnsiteRequestDetails from './OnsiteRequestDetails';

const OnsiteRequestsApprovalQueue = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const categories = useSelector(getCategories);
  const credentialTypes = useSelector(getCredentialTypes);
  const credentials = useSelector(getCredentials);

  const onsiteRequests = useSelector(state =>
    getCurrentOnsiteRequests(state, { params }),
  );

  const [expanded, setExpanded] = useState({});
  const [showTableFilter, setShowTableFilter] = useState(true);
  const [pageIndex, setPageIndex] = useState(0);
  const [defaultFilter, setDefaultFilter] = useState([
    { id: 'status', value: 'Pending' },
  ]);

  useEffect(() => {
    if (event) dispatch(fetchEventOnsiteRequests(event.get('id')));
  }, [dispatch, event]);

  const contactPulseTagCount = contact =>
    contact.pulseOrders.reduce(
      (count, order) => count + order.pulseTags.length,
      0,
    );

  const toggleTableFilter = e => {
    e.preventDefault();
    setShowTableFilter(!showTableFilter);
    if (!showTableFilter) setDefaultFilter([]);
  };

  const renderRequestsTable = () => {
    const baseLinkPath = trimUriSegments(location.pathname, 2);

    const data = onsiteRequests.toJS().map(request => {
      const deptTypeLabel =
        departmentTypeMap[request.department.type].label.singular;

      const deptTypeSingular =
        departmentTypeMap[request.department.type].singular;

      const requestedForIsDeleted = !!request.requestedFor.deleted_at;
      const pulseTagsCount = contactPulseTagCount(request.requestedFor);
      const isLocked = !!pulseTagsCount;

      return {
        data: request,
        id: request.id,
        type: request.type,
        status: request.status,
        canApprove: Boolean(request.can_approve),
        canDecline: Boolean(request.can_decline),
        department: (
          <span>
            {deptTypeLabel}:&nbsp;
            <Link
              to={`${baseLinkPath}/${deptTypeSingular}/${request.department.slug}`}
            >
              {request.department.name}
            </Link>
          </span>
        ),
        departmentName: request.department.name,
        requestedOn: request.requested_on,
        requestedBy: `${request.requestedBy.first_name} ${request.requestedBy.last_name}`,
        requestedFor: requestedForIsDeleted ? (
          `${request.requestedFor.first_name} ${request.requestedFor.last_name}`
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isLocked && (
              <div
                style={{ paddingBottom: '2px' }}
                data-tooltip-id={`contact-lock-tooltip-${request.requested_for}`}
              >
                <Icon style={{ width: '16px' }} icon="IssuedCredential" />
                <Tooltip
                  id={`contact-lock-tooltip-${request.requested_for}`}
                  content={`Issued: ${pulseTagsCount}`}
                  offset={5}
                />
              </div>
            )}
            <Link
              to={`${baseLinkPath}/${deptTypeSingular}/${request.department.slug}/contacts/list?contact=${request.requested_for}`}
            >
              {request.requestedFor.first_name} {request.requestedFor.last_name}
            </Link>
          </div>
        ),
        requestedForName: `${request.requestedFor.first_name} ${request.requestedFor.last_name}`,
        requestData: request.request_data,
        requestNotes: request.request_notes,
        adminNotes: request.admin_notes,
        reviewedOn: request.reviewed_on,
        reviewedBy: request.reviewedBy
          ? `${request.reviewedBy.first_name} ${request.reviewedBy.last_name}`
          : null,
      };
    });

    const columns = [
      {
        Header: 'Type',
        accessor: 'type',
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            required
          >
            <option value="">All</option>
            {[
              'Name Change',
              'Add Person',
              'Modify Person',
              'Remove Person',
            ].map(status => (
              <option value={status} key={status}>
                {status}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            required
          >
            <option value="">All</option>
            {['Pending', 'Approved', 'Declined'].map(status => (
              <option value={status} key={status}>
                {status}
              </option>
            ))}
          </select>
        ),
      },
      {
        Header: 'Department',
        accessor: 'department',
        filterMethod: (filter, row) =>
          row._original.departmentName
            .toLowerCase()
            .includes(filter.value.toLowerCase()),
      },
      {
        Header: 'Requested On',
        accessor: 'requestedOn',
        filterable: false,
        defaultSortDesc: true,
        sortMethod: (date1, date2) => {
          const date1Moment = moment(date1);
          const date2Moment = moment(date2);

          if (date1Moment.isBefore(date2Moment)) {
            return -1;
          }

          if (date1Moment.isAfter(date2Moment)) {
            return 1;
          }

          return 0;
        },
        Cell: row => (
          <span>{`${moment(row.value).format('MM/DD/YYYY h:mm A')}`}</span>
        ),
      },
      {
        Header: 'Requested By',
        accessor: 'requestedBy',
      },
      {
        Header: 'Requested For',
        accessor: 'requestedFor',
        filterMethod: (filter, row) =>
          row._original.requestedForName
            .toLowerCase()
            .includes(filter.value.toLowerCase()),
      },
      {
        Header: () => null,
        filterable: false,
        Cell: ({ original: request }) => {
          if (request.status === 'Pending') {
            return (
              <div className="onsite-request__action-buttons">
                <ApproveOnsiteRequestModal
                  onsiteRequest={request}
                  trigger={
                    <StatusButton
                      className="onsite-request__action-button"
                      type="button"
                      buttonText="Approve"
                      disabled={!request.canApprove}
                    />
                  }
                />
                <DeclineOnsiteRequestModal
                  onsiteRequest={request}
                  trigger={
                    <StatusButton
                      className="onsite-request__action-button"
                      type="button"
                      buttonText="Decline"
                      disabled={!request.canDecline}
                    />
                  }
                />
              </div>
            );
          }
        },
      },
    ];

    return (
      <ReactTable
        className="department-list"
        data={data}
        columns={columns}
        defaultSorted={[
          {
            id: 'requestedOn',
            desc: true,
          },
        ]}
        minRows={0}
        page={pageIndex}
        onPageChange={setPageIndex}
        onFilteredChange={() => setPageIndex(0)}
        defaultPageSize={20}
        showPagination={data.length > 20}
        showPageSizeOptions={false}
        filterable={showTableFilter}
        defaultFiltered={defaultFilter}
        defaultFilter={({ filter, onChange }) => (
          <input
            type="text"
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
          />
        )}
        defaultFilterMethod={(filter, row) => {
          const id = filter.pivotId || filter.id;
          return row[id] !== undefined
            ? row[id].toLowerCase().includes(filter.value.toLowerCase())
            : true;
        }}
        expanded={expanded}
        onExpandedChange={setExpanded}
        SubComponent={({ original: request }) => (
          <OnsiteRequestDetails
            onsiteRequest={request}
            categories={categories}
            credentialTypes={credentialTypes}
            credentials={credentials}
          />
        )}
        NoDataComponent={() => (
          <div className="generic-list--empty">
            <Icon icon="Sad" />
            <p>There are no results to display.</p>
          </div>
        )}
      />
    );
  };

  const actions = [];
  if (!onsiteRequests?.isEmpty()) {
    actions.push(
      <button
        key="add-department-filter-action"
        className="button button--plain button--icon"
        onClick={toggleTableFilter}
      >
        <Icon icon="Filter" />
        <span>{showTableFilter ? 'Clear' : 'Advanced'} Filter</span>
      </button>,
    );
  }

  return (
    <Paper>
      <PaperHeader title="Onsite Requests" actions={actions} />
      <span className="activity-log">
        {!onsiteRequests ? (
          <LoadingIndicator />
        ) : onsiteRequests.isEmpty() ? (
          <div className="generic-list--empty">
            <Icon icon="Sad" />
            <p>No onsite requests have been made for this event.</p>
          </div>
        ) : (
          renderRequestsTable()
        )}
      </span>
    </Paper>
  );
};

export default OnsiteRequestsApprovalQueue;
