import { getPeriodDateRange } from '../../common/utils/credentials/credential-utils';

const extractPeriods = periods => request => {
  const issueFrequency = request.getIn([
    'credential',
    'credentialType',
    'issue_frequency',
  ]);

  if (issueFrequency === 'ONE_TIME') {
    const oneTimePeriods = request.getIn(['credential', 'oneTimePeriods']);
    oneTimePeriods.forEach(period => {
      const periodId = period.get('id');

      if (!periods.has(periodId)) {
        periods.set(periodId, {
          id: periodId,
          name: period.get('name'),
          startDate: period.get('start_date'),
          endDate: period.get('end_date'),
          dateRange: getPeriodDateRange(period),
        });
      }
    });
  } else {
    const period = request.getIn(['credential', 'period']);
    const periodId = period.get('id');

    if (!periods.has(periodId)) {
      periods.set(periodId, {
        id: periodId,
        name: period.get('name'),
        startDate: period.get('start_date'),
        endDate: period.get('end_date'),
        dateRange: getPeriodDateRange(period),
      });
    }
  }
};

export const getPeriods = (requests, contact = null) => {
  const periods = new Map();
  const addPeriods = extractPeriods(periods);
  requests?.forEach(addPeriods);
  contact?.get('credentialRequests').forEach(addPeriods);

  return Array.from(periods.values()).sort((period1, period2) =>
    period1.startDate <= period2.startDate ? -1 : 1,
  );
};
