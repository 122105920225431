import { combineReducers } from 'redux';
import Immutable from 'immutable';
import getApiReducer, { defaultState } from '../common/utils/getApiReducer';
import {
  GET_GROUP_CREDENTIAL_LINK,
  GET_GROUP_CREDENTIAL_EMAILS,
  GET_GROUP_CREDENTIALS,
  SUBMIT_GROUP_CREDENTIAL_LINK,
} from './group-credential-actions';

const requestsReducer = (state = defaultState, action) => {
  let newState = getApiReducer(GET_GROUP_CREDENTIALS)(state, action);

  if (action.type === 'CREATE_PUBLIC_GROUP_CONTACT_SUCCESS') {
    newState = newState.setIn(
      ['data', 'contacts'],
      newState.getIn(['data', 'contacts']).push(Immutable.fromJS(action.json)),
    );
  }

  if (
    action.type === 'CREATE_PUBLIC_GROUP_CONTACT_SUCCESS' ||
    action.type === 'EDIT_PUBLIC_GROUP_CONTACT_SUCCESS'
  ) {
    if (action.contactValues.credentials) {
      const groupCredentialRequests =
        action.json.department.credentialRequests.filter(
          credentialRequest => credentialRequest.requested_for === null,
        );

      const departmentRequestsPath = [
        'data',
        'department',
        'credentialRequests',
      ];
      newState = newState.setIn(
        departmentRequestsPath,
        newState
          .getIn(departmentRequestsPath)
          .mergeDeep(Immutable.fromJS(groupCredentialRequests)),
      );

      const requestsPath = ['data', 'requests'];
      const requests = newState.getIn(requestsPath).toJS();

      groupCredentialRequests.forEach(
        ({ credential_id, quantity_approved }) => {
          const request = requests.find(
            r => parseInt(r.credential_id, 10) === parseInt(credential_id, 10),
          );

          if (request) {
            request.quantity_approved = quantity_approved;
          }
        },
      );

      newState = newState.setIn(requestsPath, Immutable.fromJS(requests));
    }
  }

  if (action.type === 'EDIT_PUBLIC_GROUP_CONTACT_SUCCESS') {
    const contactEntry = newState
      .getIn(['data', 'contacts'])
      .findEntry(contact => contact.get('id') === action.json.id);
    if (contactEntry) {
      const index = contactEntry[0];
      const contact = contactEntry[1];
      newState = newState.setIn(
        ['data', 'contacts', index],
        contact.merge({
          first_name: action.json.first_name,
          last_name: action.json.last_name,
          other_role: action.json.other_role,
          email: action.json.email,
          phone: action.json.phone,
          relationship: action.json.relationship,
          meals: action.json.meals,
          credentialRequests: Immutable.fromJS(action.json.credentialRequests),
        }),
      );
    }
  }

  if (
    action.type === 'REMOVE_CREDENTIAL_QUANTITIES_SUCCESS' &&
    newState.getIn(['data', 'requests'])
  ) {
    // Update available cred quantity in requests
    const requestEntry = newState
      .getIn(['data', 'requests'])
      .findEntry(
        req =>
          req.get('credential_id') ===
          action.json.credentialRequest.credential.id,
      );
    if (requestEntry) {
      const index = requestEntry[0];
      const request = requestEntry[1];
      newState = newState.setIn(
        ['data', 'requests', index],
        request.set(
          'quantity_approved',
          request.get('quantity_approved') + action.json.quantityDeleted,
        ),
      );
    }
    // Remove cred from contact
    const contactEntry = newState
      .getIn(['data', 'contacts'])
      .findEntry(
        contact =>
          contact.get('id') === action.json.credentialRequest.requestedFor.id,
      );
    if (contactEntry) {
      const index = contactEntry[0];
      newState = newState.setIn(
        ['data', 'contacts', index, 'credentialRequests'],
        newState
          .getIn(['data', 'contacts', index, 'credentialRequests'])
          .filter(
            credReq =>
              credReq.get('id') !== parseInt(action.credentialRequestId, 10),
          ),
      );
    }
  }

  return newState;
};

const deptLinkReducer = (state, action) => {
  let newState = getApiReducer(GET_GROUP_CREDENTIAL_LINK)(state, action);

  if (action.type === `${SUBMIT_GROUP_CREDENTIAL_LINK}_SUCCESS`) {
    newState = newState.set('data', Immutable.fromJS(action.json));
  }

  return newState;
};

export default combineReducers({
  deptEmails: getApiReducer(GET_GROUP_CREDENTIAL_EMAILS),
  deptLink: deptLinkReducer,
  requests: requestsReducer,
});
