import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { getCurrentEvent } from './event-selectors';
import { getDepartmentSettings } from '../department/department-selectors';
import { submitDepartmentSettings } from '../department/department-actions';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import departmentTypeMap from '../lib/department-type-map';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import ArrayInput from '../common/forms/ArrayInput';
import StatusButton from '../common/StatusButton';
import { fetchEventSettings } from './event-actions';
import { showNotification } from '../notification/notification-actions';
import { combineValidators } from 'revalidate';
import arrayHasNoEmptyValues from '../common/validate/array-has-no-empty-values';

const validate = combineValidators({
  subtypes: arrayHasNoEmptyValues('Types'),
  relationships: arrayHasNoEmptyValues('Roles'),
});

const EventDepartmentSettingsForm = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const eventId = useSelector(state =>
    getCurrentEvent(state, { params })?.get('id'),
  );

  const subtypes = useSelector(state =>
    getDepartmentSettings(state, { params }, 'subtypes'),
  );

  const relationships = useSelector(state =>
    getDepartmentSettings(state, { params }, 'relationships'),
  );

  const departmentTypeLabel =
    departmentTypeMap[params.departmentSettingsType].label.singular;

  const initialValues = {
    subtypes: subtypes?.toJS() ?? [],
    relationships: relationships?.toJS() ?? [],
  };

  const renderRelationshipsField = departmentTypeLabel => {
    if (params.departmentSettingsType === 'media') {
      return null;
    }

    return (
      <Field
        name="relationships"
        component={ReduxFormsFieldNoLabel}
        label={`${departmentTypeLabel} Roles`}
        required
      >
        <ArrayInput />
      </Field>
    );
  };

  const handleSubmit = async values => {
    const action = await dispatch(
      submitDepartmentSettings(eventId, params.departmentSettingsType, values),
    );

    if (!action.response.ok) {
      return formatValidationErrors(action.json).toJS();
    }

    dispatch(fetchEventSettings(eventId));
    dispatch(
      showNotification({
        message: `${departmentTypeLabel} settings successfully saved`,
        status: 'success',
      }),
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({
        handleSubmit,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
        submitSucceeded,
        valid,
      }) => (
        <Paper>
          <PaperHeader title={`${departmentTypeLabel} Settings`} />
          <form className="generic-form" onSubmit={handleSubmit}>
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                <Field
                  name="subtypes"
                  component={ReduxFormsFieldNoLabel}
                  label={`${departmentTypeLabel} Types`}
                  required
                >
                  <ArrayInput />
                </Field>

                {renderRelationshipsField(departmentTypeLabel)}
              </div>
            </div>

            <div className="generic-form__footer">
              <StatusButton
                type="submit"
                disabled={
                  submitting ||
                  pristine ||
                  !valid ||
                  (submitSucceeded && !modifiedSinceLastSubmit)
                }
                status={submitting ? 'loading' : 'default'}
              />
            </div>
          </form>
        </Paper>
      )}
    />
  );
};

export default EventDepartmentSettingsForm;
