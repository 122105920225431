import { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useMatches } from 'react-router-dom';
import storage from 'store2';

const RequireAuth = () => {
  const location = useLocation();
  const matches = useMatches();

  const redirectTarget = !storage.session.has('idToken')
    ? matches?.findLast(match => !!match.handle?.redirectTarget)?.handle
        .redirectTarget ?? `${location.pathname}${location.search}`
    : null;

  useEffect(() => {
    // Clear the 'nonce' value from local storage
    // NOTE: This should be the responsibility of the 'PostLoginPage' component
    // but doing this in that component causes issues due to React.StrictMode
    // rendering the component twice.
    storage.session.remove('nonce');
    //return () => localstorage.clearAll();
  }, []);

  useEffect(() => {
    if (redirectTarget) storage.session('redirectTarget', redirectTarget);
    else storage.session.remove('redirectTarget');
  }, [redirectTarget]);

  return storage.session.has('idToken') ? <Outlet /> : <Navigate to="/login" />;
};

export default RequireAuth;
