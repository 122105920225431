import { Field } from 'react-final-form';
import styled from 'styled-components';
import Icon from '../common/icons/Icon';

const Lock = props => <Icon icon="FilledLock" {...props} />;

export const StyledField = styled(Field)`
  margin-top: 0 !important;
`;

export const StyledLock = styled(Lock)`
  fill: white;
  transform: scale(0.7);
`;

export const LockedItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LockBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.gray};
  margin: 4px 15px 4px 3px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
`;

export const Label = styled.div`
  font-size: 18px;
  font-weight: 400;
  padding: 12px 12px 12px 0px;
`;

export const Header = styled.div`
  font-weight: 700;
  font-size: 18px;
  padding: 12px 2px;
`;
