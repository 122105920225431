import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { getCurrentEvent } from '../../event/event-selectors';
import { fetchCredentialTypeCategoryList } from './credential-type-actions';

const CredentialTypeLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const event = useSelector(state => getCurrentEvent(state, { params }));

  useEffect(() => {
    if (event) dispatch(fetchCredentialTypeCategoryList(event.get('id')));
  }, [dispatch, event]);

  return <Outlet />;
};

export default CredentialTypeLoader;
