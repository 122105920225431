import { combineReducers } from 'redux';
import Immutable from 'immutable';
import moment from 'moment';
import getApiReducer from '../common/utils/getApiReducer';
import { UPDATE_EVENT_EMAIL_SETTINGS } from '../event/event-actions';
import {
  FETCH_FESTIVAL_LIST,
  FETCH_PULSE_FESTIVAL_LIST,
  FETCH_PULSE_FESTIVAL_TIMEZONE,
  FETCH_TIMEZONES,
} from './festival-actions';
import { SAVE_INTERN_APPLICATION } from '../intern/intern-actions';
import { SAVE_MEDIA_APPLICATION } from '../media/media-actions';

const list = (state = Immutable.Map(), action) => {
  let newState = state;
  if (action.originalType === FETCH_FESTIVAL_LIST) {
    newState = newState.set(
      action.promoterId,
      getApiReducer(FETCH_FESTIVAL_LIST)(
        newState.get(action.promoterId),
        action,
      ),
    );
    if (newState.getIn([action.promoterId, 'data'])) {
      // add promoter_id to events
      const festivalList = newState
        .getIn([action.promoterId, 'data'])
        .map(festival =>
          festival.set(
            'events',
            festival
              .get('events')
              .map(event =>
                event.set('promoter_id', festival.get('promoter_id')),
              ),
          ),
        );
      newState = newState.setIn([action.promoterId, 'data'], festivalList);
    }
  } else if (action.type === 'ADD_PERIOD_SUCCESS') {
    const [festivalIndex, festival] = newState
      .getIn([action.json.event.promoter_id, 'data'])
      .findEntry(
        festivalEntry =>
          festivalEntry.get('id') === action.json.event.festival_id,
      );
    const [eventIndex, event] = festival
      .get('events')
      .findEntry(eventEntry => eventEntry.get('id') === action.json.event.id);

    if (event) {
      if (
        !event.get('start_date') ||
        moment(event.get('start_date')).isAfter(action.json.start_date)
      ) {
        newState = newState.setIn(
          [
            action.json.event.promoter_id,
            'data',
            festivalIndex,
            'events',
            eventIndex,
            'start_date',
          ],
          Immutable.fromJS(action.json.start_date),
        );
      }

      if (
        !event.get('end_date') ||
        moment(event.get('end_date')).isBefore(action.json.end_date)
      ) {
        newState = newState.setIn(
          [
            action.json.event.promoter_id,
            'data',
            festivalIndex,
            'events',
            eventIndex,
            'end_date',
          ],
          Immutable.fromJS(action.json.end_date),
        );
      }

      newState = newState.setIn(
        [
          action.json.event.promoter_id,
          'data',
          festivalIndex,
          'events',
          eventIndex,
          'periods',
        ],
        event.get('periods').push(Immutable.fromJS(action.json)),
      );
    }
  } else if (action.type === 'CHANGE_EVENT_ENABLED_STATUS_SUCCESS') {
    const [festivalIndex, festival] = newState
      .getIn([action.json.festival.promoter_id, 'data'])
      .findEntry(
        festivalEntry => festivalEntry.get('id') === action.json.festival_id,
      );

    const [eventIndex] = festival
      .get('events')
      .findEntry(eventEntry => eventEntry.get('id') === action.json.id);

    newState = newState.setIn(
      [
        action.json.festival.promoter_id,
        'data',
        festivalIndex,
        'events',
        eventIndex,
        'deactivated',
      ],
      action.json.deactivated,
    );
  } else if (action.type === `${UPDATE_EVENT_EMAIL_SETTINGS}_SUCCESS`) {
    const [festivalIndex, festival] = newState
      .getIn([action.json.promoter_id, 'data'])
      .findEntry(
        festivalEntry => festivalEntry.get('id') === action.json.festival_id,
      );

    const [eventIndex] = festival
      .get('events')
      .findEntry(eventEntry => eventEntry.get('id') === action.json.id);

    const path = [
      action.json.promoter_id,
      'data',
      festivalIndex,
      'events',
      eventIndex,
    ];

    newState = newState.setIn(
      path.concat(['default_bcc']),
      action.json.default_bcc,
    );

    newState = newState.setIn(
      path.concat(['error_notification_email']),
      action.json.error_notification_email,
    );
  } else if (action.type === 'CHANGE_GROUPS_ENABLED_STATUS_SUCCESS') {
    const [festivalIndex, festival] = newState
      .getIn([action.json.festival.promoter_id, 'data'])
      .findEntry(
        festivalEntry => festivalEntry.get('id') === action.json.festival_id,
      );

    const [eventIndex] = festival
      .get('events')
      .findEntry(eventEntry => eventEntry.get('id') === action.json.id);

    newState = newState.setIn(
      [
        action.json.festival.promoter_id,
        'data',
        festivalIndex,
        'events',
        eventIndex,
        'groups_enabled',
      ],
      action.json.groups_enabled,
    );
  }

  if (action.type === 'UPDATE_EVENT_ONSITE_SETTINGS_SUCCESS') {
    const [festivalIndex, festival] = newState
      .getIn([action.promoterId, 'data'])
      .findEntry(
        festivalEntry => festivalEntry.get('id') === action.festivalId,
      );

    const [eventIndex] = festival
      .get('events')
      .findEntry(eventEntry => eventEntry.get('id') === action.eventId);

    newState = newState.setIn(
      [
        action.promoterId,
        'data',
        festivalIndex,
        'events',
        eventIndex,
        'onsite_requests_enabled',
      ],
      action.json.enabled,
    );
  }

  if (
    action.type === `${SAVE_INTERN_APPLICATION}_SUCCESS` ||
    action.type === `${SAVE_MEDIA_APPLICATION}_SUCCESS`
  ) {
    const promoterId = action.promoterId;
    const [festivalIndex, festival] = newState
      .getIn([promoterId, 'data'])
      .findEntry(
        festivalEntry => festivalEntry.get('id') === action.festivalId,
      );
    const [eventIndex, event] = festival
      .get('events')
      .findEntry(eventEntry => eventEntry.get('id') === action.eventId);

    if (event) {
      const applications = newState.getIn([
        promoterId,
        'data',
        festivalIndex,
        'events',
        eventIndex,
        'applications',
      ]);
      const applicationIndex = applications.findIndex(
        application =>
          application.get('id').toString() === action.json.id.toString(),
      );

      if (applicationIndex !== -1) {
        newState = newState.mergeIn(
          [
            promoterId,
            'data',
            festivalIndex,
            'events',
            eventIndex,
            'applications',
            applicationIndex,
          ],
          Immutable.fromJS(action.json),
        );
      } else {
        newState = newState.mergeIn(
          [
            promoterId,
            'data',
            festivalIndex,
            'events',
            eventIndex,
            'applications',
          ],
          applications.push(Immutable.fromJS(action.json)),
        );
      }
    }
  }

  if (action.type === 'EDIT_PERIOD_SUCCESS') {
    const promoterId = action.json.event.promoter_id;
    const [festivalIndex, festival] = newState
      .getIn([promoterId, 'data'])
      .findEntry(
        festivalEntry =>
          festivalEntry.get('id') === action.json.event.festival_id,
      );
    const [eventIndex, event] = festival
      .get('events')
      .findEntry(eventEntry => eventEntry.get('id') === action.json.event.id);

    if (event) {
      const periodIndex = newState
        .getIn([
          promoterId,
          'data',
          festivalIndex,
          'events',
          eventIndex,
          'periods',
        ])
        .findIndex(
          period => period.get('id').toString() === action.json.id.toString(),
        );
      if (periodIndex !== -1) {
        newState = newState.mergeIn(
          [
            promoterId,
            'data',
            festivalIndex,
            'events',
            eventIndex,
            'periods',
            periodIndex,
          ],
          action.json,
        );
      }
    }
  }

  return newState;
};

const reducers = combineReducers({
  list,
  pulseFestivalList: getApiReducer(FETCH_PULSE_FESTIVAL_LIST),
  pulseFestival0Timezone: getApiReducer(FETCH_PULSE_FESTIVAL_TIMEZONE),
  timezones: getApiReducer(FETCH_TIMEZONES),
});

export default reducers;
