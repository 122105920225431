import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { combineValidators, isRequired } from 'revalidate';
import recursiveTrim from '../common/utils/recursive-trim';
import LoadingIndicator from '../common/LoadingIndicator';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import FullPageMessage from '../common/FullPageMessage';
import Paper from '../common/paper/Paper';
import PaperHeader from '../common/paper/PaperHeader';
import StatusButton from '../common/StatusButton';
import {
  editFestival,
  fetchFestivalList,
  fetchPulseFestivalList,
  fetchPulseFestivalTimezone,
  fetchTimezones,
  resetPulseFestivalList,
  resetPulseFestivalTimezone,
} from './festival-actions';
import { showNotification } from '../notification/notification-actions';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { getCurrentPromoter } from '../promoter/promoter-selectors';
import { getCurrentFestival } from './festival-selectors';

const validate = combineValidators({
  name: isRequired('Name'),
  pulse_festival_id: isRequired('Pulse Festival'),
});

const FestivalFormContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const festival = useSelector(state => getCurrentFestival(state, { params }));

  const initialValues = useMemo(
    () => ({
      id: festival?.get('id'),
      name: festival?.get('name'),
      promoter: festival?.get('promoter_id'),
      pulse_festival_id: festival
        ?.get('pulseFestivals')
        .first()
        .get('pulse_festival_id'),
      time_zone: festival?.get('time_zone'),
    }),
    [festival],
  );

  const handleSubmit = values => {
    const { pulse_festival_id } = values;

    const payload = {
      ...values,
      pulse_festival_ids: [pulse_festival_id],
    };
    delete payload.pulse_festival_id;

    return dispatch(editFestival(recursiveTrim(payload))).then(action => {
      if (!action.response.ok) {
        const errors = formatValidationErrors(action.json).toJS();
        errors._error = action.response;
        return errors;
      } else {
        dispatch(fetchFestivalList(promoter.get('id')));

        dispatch(
          showNotification({
            message: 'Festival successfully saved',
            status: 'success',
          }),
        );

        navigate(`/promoters/${promoter.get('slug')}/festivals`);
      }
    });
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
      render={props => <FestivalForm promoter={promoter} {...props} />}
    />
  );
};

const FestivalForm = ({
  promoter,
  handleSubmit,
  submitting,
  valid,
  pristine,
  form: { change },
  initialValues,
  values,
}) => {
  const dispatch = useDispatch();

  const pulse_festival_id = values.pulse_festival_id;
  const time_zone = values.time_zone;

  const timezones = useSelector(state => state.festival.timezones.get('data'));

  const notLoaded = useSelector(
    state =>
      !state.festival.timezones.get('loaded') ||
      !state.festival.pulseFestivalList.get('loaded'),
  );

  const pulseFestivalList = useSelector(state =>
    state.festival.pulseFestivalList.get('data'),
  );

  const pulseTimeZone = useSelector(state =>
    state.festival.pulseFestival0Timezone.getIn(['data', 'timezone']),
  );

  const pulseTimeZoneLoaded = useSelector(state =>
    state.festival.pulseFestival0Timezone.get('loaded'),
  );

  const changeTimeZone = useRef(false);

  const pulseFestival = useMemo(
    () =>
      pulseFestivalList?.find(
        pf => pf.get('festival_id') === pulse_festival_id,
      ),
    [pulse_festival_id, pulseFestivalList],
  );

  useEffect(() => {
    if (!timezones?.size) {
      dispatch(fetchTimezones());
    }
  }, [dispatch, timezones]);

  useEffect(() => {
    if (promoter) dispatch(fetchPulseFestivalList(promoter.get('id')));
    return () => dispatch(resetPulseFestivalList());
  }, [dispatch, promoter]);

  useEffect(() => {
    if (!promoter) return;

    if (pulse_festival_id) {
      if (pulse_festival_id !== initialValues.pulse_festival_id) {
        changeTimeZone.current = true;
      }
      dispatch(
        fetchPulseFestivalTimezone(promoter.get('id'), pulse_festival_id),
      ).then(result => {
        if (changeTimeZone.current) change('time_zone', result.json.timezone);
      });
    }

    return () => dispatch(resetPulseFestivalTimezone());
  }, [
    dispatch,
    change,
    promoter,
    pulse_festival_id,
    initialValues.pulse_festival_id,
  ]);

  if (notLoaded) {
    return <LoadingIndicator />;
  }

  if (pulseFestivalList.size === 0) {
    return (
      <FullPageMessage
        icon="Sad"
        message="Cannot create festival because there are no Pulse festivals for this client"
      />
    );
  }

  return (
    <Paper>
      <PaperHeader
        backTo={promoter ? `/promoters/${promoter.get('slug')}/festivals` : '/'}
        backToDisabled={submitting}
        title="Edit Festival"
      />
      <form className="generic-form" onSubmit={handleSubmit}>
        <div className="generic-form__body">
          <div className="input-group input-group--large">
            <Field
              name="name"
              component={ReduxFormsField}
              label="Festival Name"
              required
            >
              <input type="text" />
            </Field>
            <Field
              name="pulse_festival_id"
              component={ReduxFormsField}
              label="Pulse Festival"
              required
            >
              <select>
                {pulseFestivalList.map(pf => (
                  <option
                    value={parseInt(pf.get('festival_id'), 10)}
                    key={pf.get('festival_id')}
                  >
                    {pf.get('name')}
                  </option>
                ))}
              </select>
            </Field>
            <Field
              name="time_zone"
              component={ReduxFormsField}
              label="Time Zone"
              required
            >
              <select>
                {timezones.map(tz => (
                  <option value={tz} key={tz}>
                    {tz}
                  </option>
                ))}
              </select>
            </Field>
            {pulseFestival &&
              pulseTimeZoneLoaded &&
              pulseTimeZone !== time_zone && (
                <div style={{ color: 'red' }}>
                  Pulse Festival <strong>{pulseFestival.get('name')}</strong>{' '}
                  has a timezone of: <strong>{pulseTimeZone}</strong>
                </div>
              )}
            <Field name="promoter" component="input" type="hidden" />
          </div>
        </div>

        <div className="generic-form__footer">
          <StatusButton
            type="submit"
            disabled={submitting || pristine || !valid || !pulseTimeZoneLoaded}
            status={submitting || !pulseTimeZoneLoaded ? 'loading' : 'default'}
          />
          <Link
            className="button button--plain"
            disabled={submitting}
            to={promoter ? `/promoters/${promoter.get('slug')}/festivals` : '/'}
          >
            Cancel
          </Link>
        </div>
      </form>
    </Paper>
  );
};

export default FestivalFormContainer;
