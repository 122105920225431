import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { isRequired } from 'revalidate';
import { List, Map } from 'immutable';
import { defer } from 'lodash';
import { getStatusForButton } from '../common/forms/FormHelper';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { requestNameChange } from './onsite-actions';
import { showNotification } from '../notification/notification-actions';
import PropTypes from 'prop-types';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import StatusButton from '../common/StatusButton';
import SelectContact from './SelectContact';

const NameChangeForm = ({ department, eventOnsiteRequestTypes, onSubmit }) => {
  const dispatch = useDispatch();

  const nameInputRef = useRef(null);
  const [contact, setContact] = useState(null);

  const contactDisplayName = contact
    ? `${contact.get('first_name')} ${contact.get('last_name')}`
    : '';

  const initialValues = {
    name: contactDisplayName,
  };

  useEffect(() => {
    if (contact) defer(() => nameInputRef.current.focus());
  }, [contact]);

  if (!department) {
    return null;
  }

  const handleSubmit = values => {
    const namePieces = values.name.split(' ');
    let first_name = '';
    let last_name = '';

    if (namePieces.length > 0) {
      first_name = namePieces[0];
      last_name = namePieces.slice(1).join(' ');
    }

    return dispatch(requestNameChange(contact, first_name, last_name)).then(
      action => {
        if (action.response.ok) {
          onSubmit();

          const requestType = eventOnsiteRequestTypes.find(
            type => type.get('onsite_request_type') === 'Name Change',
          );
          if (
            requestType.get('auto_approve') &&
            !requestType.get('send_reviewed_email')
          ) {
            dispatch(
              showNotification({
                message: `Successfully renamed ${contactDisplayName} to ${first_name} ${last_name}`,
                status: 'success',
              }),
            );
          } else {
            dispatch(
              showNotification({
                message: `Request to rename ${contactDisplayName} to ${first_name} ${last_name} has been received.  We will contact you when it is approved.`,
                status: 'success',
              }),
            );
          }
        } else {
          const errors = formatValidationErrors(action.json).toJS();

          const nameErrors = [];
          if (errors.first_name) {
            errors.first_name.forEach(error => {
              nameErrors.push(`First name ${error}`);
            });
          }
          if (errors.last_name) {
            errors.last_name.forEach(error => {
              nameErrors.push(`Last name ${error}`);
            });
          }

          errors.name = nameErrors.length ? nameErrors : action.json.message;

          return errors;
        }
      },
    );
  };

  return (
    <div>
      <SelectContact
        type="Change"
        department={department}
        onContactSelected={setContact}
        disableIssuedContacts={true}
      />
      {contact && (
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={({
            handleSubmit,
            pristine,
            submitting,
            submitFailed,
            submitSucceeded,
          }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="name"
                label="Name"
                component={ReduxFormsField}
                required
                validate={isRequired('Name')}
              >
                <input type="text" ref={nameInputRef} autoFocus={true} />
              </Field>
              <div className="onsite__status-button">
                <StatusButton
                  className="button button--small"
                  type="submit"
                  disabled={submitting || pristine}
                  status={getStatusForButton({
                    pristine,
                    submitting,
                    submitFailed,
                    submitSucceeded,
                  })}
                />
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
};

NameChangeForm.propTypes = {
  department: PropTypes.instanceOf(Map).isRequired,
  eventOnsiteRequestTypes: PropTypes.instanceOf(List).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default NameChangeForm;
