import PropTypes from 'prop-types';

const CheckboxDisplay = ({ label, value }) => (
  <div>
    <dt>{label}:</dt>
    <dd>{value ? 'Yes' : 'No'}</dd>
  </div>
);

CheckboxDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
};

export default CheckboxDisplay;
