import { createSelector } from 'reselect';

const mealsForGroupContact = createSelector(
  (groupContacts, contact) => {
    if (!groupContacts || !contact) {
      return null;
    }
    const contactId = contact.get('id');
    const contactWithMeals = groupContacts.find(
      groupContact => groupContact.get('id') === contactId,
    );

    if (!contactWithMeals) {
      return null;
    }

    return contactWithMeals.get('meals');
  },
  meals => meals,
);

export const getEmailsByRecipient = createSelector(
  state => state.groupCredential.deptEmails.get('data'),
  emails => emails?.groupBy(email => email.get('recipients')),
);

export const getMealsForGroupContact = (state, props) =>
  mealsForGroupContact(
    state.groupCredential.requests.getIn(['data', 'contacts']),
    props.contact,
  );
