import { useLocation, Navigate } from 'react-router-dom';
import { logout } from './auth-redirects';
import storage from 'store2';
import client from '../client';

const PostLoginPage = () => {
  const location = useLocation();

  const nonce = storage.session('nonce');
  const redirectTarget = storage.session('redirectTarget') ?? '/';

  if (!nonce || !location.hash) {
    logout();
    return;
  }

  // Parse URL hash of format #foo=bar&stuff=things
  const hashQuery = location.hash
    .substring(1)
    .split('&')
    .map(str => str.split('='))
    .reduce((prev, curr) => {
      prev[curr[0]] = curr[1];
      return prev;
    }, {});

  if (!hashQuery.id_token) {
    logout();
    return;
  }

  const decoded = JSON.parse(window.atob(hashQuery.id_token.split('.')[1]));

  if (nonce !== decoded?.nonce) {
    logout();
    return;
  }

  storage.session('idToken', hashQuery.id_token);

  client.put('user/set-last-login');

  return <Navigate to={redirectTarget} replace />;
};

export default PostLoginPage;
