import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { resource, useACL } from '../common/ACL';
import { removeLastUriSegment } from '../common/uri';
import {
  getDateRangesForOneTimeCredential,
  sortPeriods,
} from '../credential-request/credential-request-helpers';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from '../department/department-selectors';
import { getCalendarContactList } from './contact-selectors';
import classNames from 'classnames';
import moment from 'moment';
import departmentTypeMap from '../lib/department-type-map';
import LoadingIndicator from '../common/LoadingIndicator';
import FullPageMessage from '../common/FullPageMessage';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import CheckboxBoolean from '../common/forms/CheckboxBoolean';
import Tag from '../common/Tag';

// Darkens/lightens a hex value– based on the percentage passed in: -1 to 1.
function shadeColor(color, percent) {
  let f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return `#${(
    0x1000000 +
    (Math.round((t - R) * p) + R) * 0x10000 +
    (Math.round((t - G) * p) + G) * 0x100 +
    (Math.round((t - B) * p) + B)
  )
    .toString(16)
    .slice(1)}`;
}

const aclRules = {
  canAddContact: [resource.DEPARTMENT, 'edit'],
};

const ContactCalendar = () => {
  const acl = useACL(aclRules);
  const location = useLocation();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const contactList = useSelector(state =>
    getCalendarContactList(state, { params }),
  );

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filters, setFilters] = useState({});
  const [calendarState, setCalendarState] = useState({});

  useEffect(() => {
    const updateCalendarState = () => {
      const periods = sortPeriods(event.get('periods').toJS());

      const newCalendarState = {};

      contactList.toJS().forEach(contact => {
        const calendarEventsByDay = [];
        const credentialTypesUsed = {};
        const credentialTypeSlots = {};
        let slotCount = 0;
        const daysWithEvents = {};
        contact.credentialRequests.forEach(credentialRequest =>
          // Loop over requests to find credentials for this date
          periods.forEach(period =>
            period.dates.forEach(date => {
              const calendarEvents = [];
              if (
                credentialRequest.quantity_approved === 0 &&
                credentialRequest.quantity_pending === 0
              ) {
                return null;
              }
              if (!filters[credentialRequest.credential.credentialType.id]) {
                return null;
              }

              const issueFrequency =
                credentialRequest.credential.credentialType.issue_frequency;
              const credentialType =
                credentialRequest.credential.credentialType;
              const slot =
                typeof credentialTypeSlots[credentialType.id] === 'undefined'
                  ? slotCount
                  : credentialTypeSlots[credentialType.id];

              switch (issueFrequency) {
                case 'DAILY':
                  if (date.id === credentialRequest.credential.date_id) {
                    calendarEvents.push({
                      credReq: credentialRequest,
                      length: 1,
                      slot,
                    });
                    daysWithEvents[date.date] = true;
                  }
                  break;
                case 'ONE_TIME':
                  const dateRanges = getDateRangesForOneTimeCredential(
                    credentialRequest.credential,
                  );
                  dateRanges.forEach(dateRange => {
                    if (dateRange.start === date.date) {
                      const lengthInDays =
                        1 +
                        moment
                          .utc(dateRange.end)
                          .diff(moment.utc(dateRange.start), 'days');

                      calendarEvents.push({
                        credReq: credentialRequest,
                        length: lengthInDays,
                        slot,
                      });
                      for (let i = 0; i < lengthInDays; i += 1) {
                        daysWithEvents[
                          moment
                            .utc(dateRange.start)
                            .add(i, 'days')
                            .toISOString()
                        ] = true;
                      }
                    }
                  });
                  break;
                case 'PERIODICALLY':
                  if (
                    credentialRequest.credential.period.start_date === date.date
                  ) {
                    const lengthInDays =
                      credentialRequest.credential.period.dates.length;

                    calendarEvents.push({
                      credReq: credentialRequest,
                      length: lengthInDays,
                      slot,
                    });
                    for (let i = 0; i < lengthInDays; i += 1) {
                      daysWithEvents[
                        moment.utc(date.date).add(i, 'days').toISOString()
                      ] = true;
                    }
                  }
                  break;
                default:
                  throw new Error(`Unknown issue frequency: ${issueFrequency}`);
              }

              if (
                calendarEvents.length &&
                !credentialTypesUsed[
                  calendarEvents[0].credReq.credential.credentialType.id
                ]
              ) {
                credentialTypesUsed[
                  calendarEvents[0].credReq.credential.credentialType.id
                ] = true;
                credentialTypeSlots[credentialType.id] = slotCount;
                slotCount += 1;
              }

              let dayIndex = calendarEventsByDay.findIndex(
                events => events.date.id === date.id,
              );
              if (dayIndex < 0) {
                calendarEventsByDay.push({
                  date,
                  events: calendarEvents,
                });
                dayIndex = calendarEventsByDay.length - 1;
              } else {
                calendarEventsByDay[dayIndex].events.push.apply(
                  calendarEventsByDay[dayIndex].events,
                  calendarEvents,
                );
              }
            }),
          ),
        );

        newCalendarState[contact.id] = {
          slotCount,
          daysWithEvents,
          calendarEventsByDay,
        };
      });

      setCalendarState(newCalendarState);
    };

    if (event && contactList) updateCalendarState();
  }, [contactList, event, filters]);

  useEffect(() => {
    if (department) {
      const initialFilters = {};
      department.get('credentialTypes').forEach(credType => {
        initialFilters[credType.get('id')] = true;
      });
      setFilters(initialFilters);
    }
  }, [department]);

  const toggleFilterCredType = credTypeId => {
    setFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      newFilters[credTypeId] = !newFilters[credTypeId];
      return newFilters;
    });
  };

  const renderFilterMenu = () => {
    if (!showFilterMenu) {
      return null;
    }

    const credentialTypes = department.get('credentialTypes').toJS();

    return (
      <div className="calendar-filter-menu">
        {credentialTypes.map(credType => (
          <CheckboxBoolean
            key={credType.id}
            id={`filter-checkbox-${credType.id}`}
            value={filters[credType.id]}
            label={
              <Tag backgroundColor={credType.category.color}>
                {credType.name}
              </Tag>
            }
            onChange={() => toggleFilterCredType(credType.id)}
          />
        ))}
      </div>
    );
  };

  const renderFilterButtonAndMenu = () => {
    return (
      <div className="hidden-action-box">
        <button
          key="contact-list-filter-action"
          className="button button--plain button--icon"
          onClick={() => setShowFilterMenu(!showFilterMenu)}
        >
          <Icon icon="Filter" />
          <span>Credential Filter</span>
        </button>
        {renderFilterMenu()}
      </div>
    );
  };

  const getRowHeight = contactId => {
    const minRowHeight = 140;
    const height = Math.max(
      minRowHeight,
      calendarState[contactId] ? calendarState[contactId].slotCount * 34 : 0,
    );
    return `${height}px`;
  };

  const renderCredentialRequest = (date, credReq, lengthInDays, slot) => {
    const credentialRequestClasses = {
      'contact-calender__credential': true,
      'contact-calender__credential--pending': credReq.quantity_pending > 0,
    };

    return (
      <div
        key={`${date.id}-${credReq.id}`}
        style={{
          position: 'absolute',
          top: slot * 32 + 8,
          left: '8px',
          zIndex: 1,
          width: `calc(${lengthInDays * 100}% - ${18 - 2 * lengthInDays}px)`,
          backgroundColor:
            credReq.credential.credentialType.category.color || '#ccc',
          backgroundImage:
            credReq.quantity_pending > 0
              ? `repeating-linear-gradient(135deg,
                ${credReq.credential.credentialType.category.color},
                ${credReq.credential.credentialType.category.color} 4px,
                ${shadeColor(
                  credReq.credential.credentialType.category.color,
                  0.8,
                )} 4px,
                ${shadeColor(
                  credReq.credential.credentialType.category.color,
                  0.8,
                )} 6px)`
              : '',
          borderLeftColor:
            shadeColor(
              credReq.credential.credentialType.category.color,
              -0.2,
            ) || '#aaa',
        }}
        className={classNames(credentialRequestClasses)}
        data-rh={credReq.quantity_pending > 0 ? 'PENDING' : null}
      >
        {credReq.credential.credentialType.name}
      </div>
    );
  };

  const renderCalendarEvents = (contact, periods) => {
    if (!calendarState[contact.id]) {
      return null;
    }
    const calendarEventsByDay = calendarState[contact.id].calendarEventsByDay;
    const daysWithEvents = calendarState[contact.id].daysWithEvents;
    return calendarEventsByDay.map(({ events, date }, i) => {
      const classes = {
        'contact-calendar__day': true,
        'contact-calendar__day--empty': !daysWithEvents[date.date],
        'contact-calendar__cell': true,
      };
      return (
        <div key={date.id} className={classNames(classes)}>
          {events.map(({ credReq, length, slot }, i) =>
            renderCredentialRequest(date, credReq, length, slot),
          )}
        </div>
      );
    });
  };

  const renderContactRow = (periods, contact) => {
    const totalCredentials = contact.credentialRequests.reduce(
      (acc, credReq) =>
        acc + credReq.quantity_approved + credReq.quantity_pending,
      0,
    );
    return (
      <div
        key={contact.id}
        className="contact-calendar__row"
        style={{ height: getRowHeight(contact.id) }}
      >
        <div className="contact-calendar__contact contact-calendar__cell">
          <div className="name">{`${contact.first_name} ${contact.last_name}`}</div>
          <div className="contact--credential-count">
            {totalCredentials} Credential{totalCredentials === 1 ? '' : 's'}
          </div>
        </div>
        {renderCalendarEvents(contact, periods)}
      </div>
    );
  };

  if (!contactList) {
    return <LoadingIndicator />;
  }

  if (contactList.size === 0) {
    return (
      <FullPageMessage
        icon="Sad"
        message={`No staff have been added for this ${
          departmentTypeMap[params.departmentType].label.singular
        }`}
      >
        {acl.canAddContact ? (
          <p>
            You can add staff from the{' '}
            <Link to={`${removeLastUriSegment(location.pathname)}/list`}>
              list view
            </Link>
          </p>
        ) : null}
      </FullPageMessage>
    );
  }

  const periods = sortPeriods(event.get('periods').toJS());

  return (
    <div>
      <Paper>
        <PaperHeader
          title={department.get('name')}
          actions={renderFilterButtonAndMenu()}
        />
      </Paper>
      <div className="staff-page" style={{ margin: '10px 0' }}>
        <div className="contact-calendar__wrapper">
          <div className="contact-calendar">
            <div className="contact-calendar__header">
              <div className="contact-calendar__header--contact contact-calendar__header--name contact-calendar__cell">
                <div />
              </div>
              {periods.map(period =>
                period.dates.map(
                  (date, i) =>
                    i === 0 && (
                      <div
                        key={date.id}
                        className="contact-calendar__header--name contact-calendar__cell"
                        style={{
                          width: period.dates.length * 100,
                          flexGrow: period.dates.length,
                        }}
                      >
                        {period.name}
                      </div>
                    ),
                ),
              )}
            </div>
            <div className="contact-calendar__header">
              <div className="contact-calendar__header--contact contact-calendar__header--date contact-calendar__cell">
                <div>Staff</div>
              </div>
              {periods.map(period =>
                period.dates.map(date => (
                  <div
                    key={date.id}
                    className="contact-calendar__header--date contact-calendar__cell"
                  >
                    <div>{moment.utc(date.date).format('MMM D')}</div>
                  </div>
                )),
              )}
            </div>
            {contactList
              .toJS()
              .map(contact => renderContactRow(periods, contact))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCalendar;
