import client from '../client';

export const ADD_MEAL_TYPE = 'ADD_MEAL_TYPE';
export function addMealType(eventId, payload) {
  return {
    type: ADD_MEAL_TYPE,
    promise: client.post(`events/${eventId}/meal-types`, payload),
  };
}

export const UPDATE_MEAL_TYPE = 'UPDATE_MEAL_TYPE';
export function updateMealType(eventId, mealTypeId, payload) {
  return {
    type: UPDATE_MEAL_TYPE,
    promise: client.patch(
      `events/${eventId}/meal-types/${mealTypeId}`,
      payload,
    ),
  };
}

export const UPDATE_MEAL_TYPE_SORT_ORDERS = 'UPDATE_MEAL_TYPE_SORT_ORDERS';
export function updateMealTypeSortOrders(eventId, payload) {
  return {
    payload,
    type: UPDATE_MEAL_TYPE_SORT_ORDERS,
    promise: client.put(`events/${eventId}/meal-types/sort-orders`, payload),
  };
}

export const GET_MEAL_TYPE_LIST = 'GET_MEAL_TYPE_LIST';
export function getMealTypes(eventId) {
  return {
    type: GET_MEAL_TYPE_LIST,
    promise: client.get(`events/${eventId}/meal-types`),
  };
}

export const GET_CATERING_AVAILABILITY = 'GET_CATERING_AVAILABILITY';
export function getCateringAvailability(departmentId) {
  return {
    type: GET_CATERING_AVAILABILITY,
    promise: client.get(`departments/${departmentId}/catering-availability`),
  };
}

export const UPDATE_CATERING_AVAILABILITY = 'UPDATE_CATERING_AVAILABILITY';
export function updateCateringAvailability(departmentId, payload) {
  return {
    type: UPDATE_CATERING_AVAILABILITY,
    promise: client.put(
      `departments/${departmentId}/catering-availability`,
      payload,
    ),
  };
}

export const ADD_MEAL_TICKETS = 'ADD_MEAL_TICKETS';
export function addMealTickets(eventId, mealTypeIds, locationIds, barcodes) {
  const payload = {
    mealTypeIds,
    locationIds,
    barcodes,
  };

  return {
    type: ADD_MEAL_TICKETS,
    promise: client.put(`events/${eventId}/meal-tickets`, payload),
  };
}

export const GET_CATERING_LOCATIONS = 'GET_CATERING_LOCATIONS';
export function getCateringLocations(eventId) {
  return {
    type: GET_CATERING_LOCATIONS,
    promise: client.get(`event/${eventId}/catering/locations`),
  };
}

export function getDepartmentCateringUploadTemplate(departmentId) {
  return client.get(
    `departments/${departmentId}/catering-availability/upload/template`,
  );
}

export function getCateringUploadTemplate(eventId) {
  return client.get(`events/${eventId}/catering/uploads/template`);
}

export const UPLOAD_CATERING = 'UPLOAD_CATERING';
export function uploadCatering(eventId, payload) {
  return {
    type: UPLOAD_CATERING,
    promise: client.post(`events/${eventId}/catering/uploads`, payload),
  };
}

export const FETCH_CATERING_UPLOADS = 'FETCH_CATERING_UPLOADS';
export function fetchCateringUploads(eventId) {
  return {
    type: FETCH_CATERING_UPLOADS,
    promise: client.get(`events/${eventId}/catering/uploads`),
  };
}
