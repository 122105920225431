import { Field } from 'react-final-form';
import styled, { css } from 'styled-components';
import ReduxReactSelectField from '../../common/forms/ReduxReactSelectField';

export const Container = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  background: #ececec;
  flex-direction: column;
  padding: 18px;
  min-height: 100%;
  min-width: 100%;
`;

export const CredentialsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-top: 12px;
  padding-bottom: 12px;
  align-items: flex-start;
`;

export const FirstCredentialsItem = styled.div`
  display: flex;
  flex-grow: 1.5;
  flex-shrink: 1;
  flex-basis: 12%;
`;

export const CredentialsItem = styled.div`
  display: flex;
  flex: 3.5;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 0.5;
  margin-bottom: 12px;
`;

export const Footer = styled(Row)`
  justify-content: flex-end;
`;

export const Column = styled.div`
  display: flex;
  flex: initial;
  align-items: start;
  flex-direction: column;
`;

export const Gutter = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div[name='delete'] {
    cursor: pointer;
  }
  > div > span > svg {
    fill: ${({ theme }) => theme.grayMedium};
  }
`;

export const Close = styled.div`
  cursor: pointer;
  min-width: 24px;
  margin-left: 20px;
  font-size: 1.4rem;
`;

const FieldStyles = css`
  margin-right: 36px !important;
  margin-bottom: 18px;
`;

export const StyledField = styled(Field)`
  ${FieldStyles};
  width: 300px !important;
  > div {
    color: ${({ theme }) => theme.grayDark};
    > input {
      height: 46px !important;
    }
  }
`;

export const StyledCredentialSelect = styled(ReduxReactSelectField)`
  ${FieldStyles};
  width: ${props => (props.width ? props.width : '636px')} !important;
  min-width: 200px !important;
  .react-select__single-value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
  }
  .react-select__control {
    border: 2px solid ${({ theme }) => theme.grayLighter} !important;
    }
  }
`;

export const StyledSelect = styled(ReduxReactSelectField)`
  margin-right: 36px !important;
  width: 300px !important;
  .react-select__single-value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
  }
  .react-select__control {
    height: 46px !important;
    border: 2px solid ${({ theme }) => theme.grayLighter} !important;
    }
  }
`;

export const DeleteUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 4px;
  margin: 0 18px 0 0;
  cursor: pointer;
  color: ${({ theme }) => theme.grayMedium};
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > div > span > svg {
    fill: ${({ theme }) => theme.grayMedium};
  }
`;

export const NameHeaders = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

export const CredentialPeriodHeader = styled(Column)`
  justify-content: flex-start;
  align-items: start;
  flex: 3;
  color: ${props => props.theme.darkBlue};
  font-size: 18px;
  padding: 2px 6px;
  white-space: nowrap;
  p:first-child {
    font-weight: 600;
  }
  p:nth-child(2) {
    font-weight: 600;
    font-family: 'Roboto Condensed';
    font-size: 14px;
  }
`;

export const CredentialCategoryHeader = styled.div`
  font-weight: 700;
  font-size: 18px;
`;

export const CredentialTypeHeader = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 18px;
  color: ${props => props.theme.darkBlue};
  padding-left: 40px;
  word-wrap: break-word;
  align-items: start;
`;

export const CancelButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 4px;
  margin: 0 18px 0 0;
  cursor: pointer;
  color: ${({ theme }) => theme.grayMedium};
  font-size: 18px;
`;
