import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { List } from 'immutable';
import { showNotification } from '../notification/notification-actions';
import { fetchInvitationLink } from '../user/user-actions';
import { getUserIsStrataAdmin } from '../auth/auth-selectors';
import auth from '../auth/auth-helpers';
import Confirmation from '../common/Confirmation';
import Icon from '../common/icons/Icon';
import Paper from '../common/paper/Paper';
import moment from 'moment';
import ProfileCardListItem from '../common/ProfileCardListItem';
import LoadingIndicator from '../common/LoadingIndicator';

const UserList = ({
  addNewRoute,
  enableAction,
  deleteAction,
  disableAction,
  isDisabled = () => {},
  loading = false,
  deleting = false,
  fetchAction,
  linkTo,
  resendInviteAction,
  userList,
  userType = 'User',
  canInvite,
}) => {
  const dispatch = useDispatch();

  const isStrataAdmin = useSelector(getUserIsStrataAdmin);

  const [activeCard, setActiveCard] = useState();
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const wasDeleting = useRef(deleting);

  useEffect(() => {
    if (fetchAction) {
      dispatch(fetchAction());
    }
  }, [dispatch, fetchAction]);

  useEffect(() => {
    if (wasDeleting.current && !deleting) {
      hideDeleteConfirmation();
      dispatch(
        showNotification({
          message: `${userType} user successfully deleted`,
          status: 'success',
        }),
      );
    }
    wasDeleting.current = deleting;
  }, [deleting, dispatch, userType]);

  const onDeleteUser = user => {
    dispatch(deleteAction(user.get('id')));
  };

  const onEnableUser = user => {
    setActiveCard(null);
    dispatch(enableAction(user.get('id')));
  };

  const onDisableUser = user => {
    setActiveCard(null);
    dispatch(disableAction(user.get('id')));
  };

  const showDeleteConfirmation = user => {
    setConfirmingDelete(user);
  };

  const hideDeleteConfirmation = () => {
    setActiveCard(null);
    setConfirmingDelete(false);
  };

  const resendInvite = user => {
    setActiveCard(null);
    dispatch(resendInviteAction(user.get('id'))).then(action => {
      if (action.response.ok) {
        dispatch(
          showNotification({
            message: `${userType} invitation successfully sent`,
            status: 'success',
          }),
        );
      }
    });
  };

  const renderEnableButton = user => {
    if (!enableAction) {
      return null;
    }

    return (
      <button
        className="dropdown__item"
        key={`enable-user-${user.get('id')}`}
        onClick={() => onEnableUser(user)}
      >
        Enable User
      </button>
    );
  };

  const renderDisableButton = user => {
    if (!disableAction) {
      return null;
    }

    return (
      <button
        className="dropdown__item"
        key={`disable-user-${user.get('id')}`}
        onClick={() => onDisableUser(user)}
      >
        <div className="dropdown__item__children">
          <Icon icon="Disable" />
          <span className="text">Disable User</span>
        </div>
      </button>
    );
  };

  const renderDeleteButton = (user, currentUserJwt) => {
    // Can't delete yourself
    if (!deleteAction || user.get('id') === currentUserJwt.sub) {
      return null;
    }

    return (
      <button
        className="dropdown__item"
        key={`delete-user-${user.get('id')}`}
        onClick={() => showDeleteConfirmation(user)}
        type="button"
      >
        <div className="dropdown__item__children">
          <Icon icon="Trash" />
          <span className="text">Delete User</span>
        </div>
      </button>
    );
  };

  const renderResendInviteButton = user => {
    return (
      <button
        className="dropdown__item"
        key={`resend-invite-${user.get('id')}`}
        onClick={() => resendInvite(user)}
      >
        <div className="dropdown__item__children">
          <Icon icon="Mail" />
          <span className="text">Resend Invite</span>
        </div>
      </button>
    );
  };

  const renderInviteLinkButton = user => {
    return (
      <button
        className="dropdown__item"
        key={`invite-link-${user.get('id')}`}
        onClick={() => {
          dispatch(fetchInvitationLink(user.get('id'))).then(action => {
            if (action.json.status === 'success') {
              navigator.clipboard.writeText(action.json.link);
            }
            dispatch(showNotification(action.json));
            setActiveCard(null);
          });
        }}
      >
        <div className="dropdown__item__children">
          <Icon icon="Clipboard" />
          <span className="text">Copy invite link</span>
        </div>
      </button>
    );
  };

  const renderMenu = (user, currentUserJwt) => {
    let items = [];

    if (isDisabled(user) && enableAction) {
      items.push(renderEnableButton(user));
      items.push(renderDeleteButton(user, currentUserJwt));
    } else {
      if (disableAction && !isDisabled(user)) {
        items.push(renderDisableButton(user));
      } else {
        items.push(renderDeleteButton(user, currentUserJwt));
      }
      items.push(renderResendInviteButton(user));

      if (isStrataAdmin && !user.get('last_login')) {
        items.push(renderInviteLinkButton(user));
      }
    }

    items = items.filter(item => item !== null);

    if (!items.length) {
      return [];
    }
    return <div>{items}</div>;
  };

  const renderStatusText = user => {
    if (!user.get('last_login') && user.get('invite_sent_at')) {
      return (
        <span className="card__profile--status__children">
          <Icon icon="Mail" />
          <span>{`Invitation sent: ${moment(user.get('invite_sent_at')).format(
            'MM/DD/YYYY hh:mm A',
          )}`}</span>
        </span>
      );
    }

    return null;
  };

  const renderItem = (user, index) => {
    const labels = [];
    const userJwt = auth.getDecodedJwt();
    if (user.get('id') === userJwt.sub) {
      labels.push({
        text: 'You',
        className: 'tag tag--you',
      });
    }
    if (!user.get('last_login')) {
      labels.push({
        text: 'Invited',
        className: 'tag tag--invited',
      });
    }

    const disabled = isDisabled(user);
    if (disabled) {
      labels.push({
        text: 'Disabled',
        className: 'tag tag--disabled',
      });
    }
    const className = disabled ? 'card--disabled' : null;

    return (
      <ProfileCardListItem
        className={className}
        key={user.get('id')}
        id={user.get('id')}
        linkTo={linkTo}
        name={`${user.get('first_name')} ${user.get('last_name')}`}
        subText={user.get('email')}
        statusText={renderStatusText(user)}
        image={user.get('file_id') ? user.getIn(['logo', 'location']) : false}
        labels={labels}
        menuActive={activeCard === index}
        onMenuToggle={() =>
          activeCard === index ? setActiveCard(false) : setActiveCard(index)
        }
      >
        {renderMenu(user, userJwt)}
      </ProfileCardListItem>
    );
  };

  const renderList = () => {
    return (
      <div className="card-list">
        {userList.map(renderItem)}
        {canInvite ? (
          <Link className="card__link card--add" to={addNewRoute}>
            <div>
              <Icon icon="PersonAdd" />
              <span className="text">Invite {userType}</span>
            </div>
          </Link>
        ) : null}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <Paper className="card-list--empty">
        <Icon icon="Sad" />
        <p>There aren't any users yet.</p>
        {canInvite ? (
          <Link className="button" to={addNewRoute}>
            Invite {userType}
          </Link>
        ) : null}
      </Paper>
    );
  };

  if (confirmingDelete) {
    const user = confirmingDelete;
    const name = `${user.get('first_name')} ${user.get('last_name')}`;
    return (
      <Confirmation
        warningText={`Deleting ${name} will remove them from all access to Strata`}
        actionText={`delete ${user.get('first_name')} ${user.get('last_name')}`}
        onConfirm={() => onDeleteUser(user)}
        onDismiss={hideDeleteConfirmation}
        disableButtons={deleting}
      />
    );
  }

  return loading ? (
    <LoadingIndicator />
  ) : !userList?.size ? (
    renderEmpty()
  ) : (
    renderList()
  );
};

UserList.propTypes = {
  addNewRoute: PropTypes.string.isRequired,
  enableAction: PropTypes.func,
  deleteAction: PropTypes.func,
  disableAction: PropTypes.func,
  isDisabled: PropTypes.func,
  loading: PropTypes.bool,
  deleting: PropTypes.bool,
  fetchAction: PropTypes.func,
  linkTo: PropTypes.func,
  resendInviteAction: PropTypes.func.isRequired,
  userList: PropTypes.instanceOf(List),
  userType: PropTypes.string,
  canInvite: PropTypes.bool.isRequired,
};

export default UserList;
