import PropTypes from 'prop-types';
import classNames from 'classnames';

const colors = [
  '#FABDB9',
  '#D1C2DF',
  '#BCE5F9',
  '#EFB3BA',
  '#FCEEBB',
  '#FBE0B9',
  '#F1CAB3',
  '#C1EED5',
  '#BBEAE1',
  '#D9F0BE',
  '#DCCCC0',
  '#C6D4B5',
  '#FEE8E4',
  '#E6F4FD',
  '#F9E1FD',
];

const ColorPickerField = ({ value, onChange }) => {
  const renderColorSwatch = color => {
    const colorSwatchClasses = {
      'color-picker__color': true,
      'color-picker__color--active': color === value,
    };

    return (
      <button
        key={color}
        type="button"
        className={classNames(colorSwatchClasses)}
        style={{ backgroundColor: color }}
        onClick={() => onChange(color)}
      />
    );
  };

  return <div className="color-picker">{colors.map(renderColorSwatch)}</div>;
};

ColorPickerField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default ColorPickerField;
