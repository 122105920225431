import { createResponsiveStateReducer } from 'redux-responsive';

import adminReducers from './admin/admin-reducers';
import advanceReducers from './advance-application/advance-reducers';
import authReducers from './auth/auth-reducers';
import cateringReducers from './catering/catering-reducers';
import contactReducers from './contact/contact-reducers';
import credentialRequestReducers from './credential-request/credential-request-reducers';
import credentialTypeReducers from './credential/type/credential-type-reducers';
import departmentReducers from './department/department-reducers';
import errorReducers from './error/error-reducers';
import eventReducers from './event/event-reducers';
import festivalReducers from './festival/festival-reducers';
import fileReducers from './file/file-reducers';
import groupCredentialReducers from './group-credential/group-credential-reducers';
import internReducers from './intern/intern-reducers';
import mediaReducers from './media/media-reducers';
import navReducers from './navigation/nav-reducers';
import notificationReducers from './notification/notification-reducers';
import onsiteReducers from './onsite/onsite-reducers';
import periodReducers from './credential/period/period-reducers';
import promoterReducers from './promoter/promoter-reducers';
import reportReducers from './reports/reports-reducers';
import searchReducers from './search/search-reducers';
import userReducers from './user/user-reducers';

const reducers = {
  admin: adminReducers,
  advance: advanceReducers,
  auth: authReducers,
  browser: createResponsiveStateReducer({
    tabletPortrait: 599,
    tabletLandscape: 899,
    desktop: 1199,
  }),
  catering: cateringReducers,
  contact: contactReducers,
  credentialRequest: credentialRequestReducers,
  credentialType: credentialTypeReducers,
  department: departmentReducers,
  error: errorReducers,
  event: eventReducers,
  festival: festivalReducers,
  file: fileReducers,
  groupCredential: groupCredentialReducers,
  intern: internReducers,
  media: mediaReducers,
  nav: navReducers,
  notification: notificationReducers,
  onsite: onsiteReducers,
  period: periodReducers,
  promoter: promoterReducers,
  reports: reportReducers,
  search: searchReducers,
  user: userReducers,
};

export default reducers;
