import client from '../client';

export const GET_REPORT_DATA = 'GET_REPORT_DATA';
export const getReportData = (resource, resourceId, report_slug, payload) => ({
  resource,
  resourceId,
  report_slug,
  type: GET_REPORT_DATA,
  promise: client.post(`${resource}/${resourceId}/reports`, payload),
});

export const FETCH_OPTIONS = 'FETCH_OPTIONS';
export const fetchOptions = (resource, resourceId) => ({
  resource,
  resourceId,
  type: FETCH_OPTIONS,
  promise: client.get(`${resource}/${resourceId}/report-options`),
});
