import { Checkbox } from 'synfrastructure';
import { List } from 'immutable';
import translateErrors from '../translateErrors';
import PropTypes from 'prop-types';
import ArrayInput from '../../forms/ArrayInput';
import InputScaffold from '../../forms/InputScaffold';
import InputScaffoldNoLabel from '../../forms/InputScaffoldNoLabel';
import ToggleScaffold from '../../forms/ToggleScaffold';
import Icon from '../../icons/Icon';

const Select = ({ field, autoFocus, onFieldRemove, onChange }) => {
  const onOptionsChange = newOptions => {
    let newValue = field.set('options', newOptions);

    // Make sure a default value gets set
    if (newOptions.indexOf(newValue.get('value')) === -1) {
      newValue = newValue.set('value', newOptions[0]);
    }

    onChange(newValue);
  };

  let options = field.get('options');

  if (options instanceof List) {
    options = options.toJS();
  }

  return (
    <div className="input-group input-group--large">
      <div className="custom-request-form__field--title">
        <h3>Select Field</h3>
        <button
          className="button button--plain button--icon"
          onClick={onFieldRemove}
          type="button"
        >
          <Icon icon="Trash" />
          <span>Remove</span>
        </button>
      </div>

      <InputScaffold
        label="Label"
        required
        validation={translateErrors(field.get('error')?.label)}
      >
        <input
          type="text"
          name="label"
          value={field.get('label') || ''}
          onChange={e => onChange(field.set('label', e.target.value))}
          autoFocus={autoFocus}
        />
      </InputScaffold>

      <InputScaffoldNoLabel
        label="Options in Select Field"
        required
        validation={translateErrors(field.get('error')?.options)}
      >
        <ArrayInput
          value={options || ['']}
          onChange={newOptions => onOptionsChange(newOptions)}
          maxLength={255}
        />
      </InputScaffoldNoLabel>

      <InputScaffold
        label="Default Value"
        required
        validation={translateErrors(field.get('error')?.value)}
      >
        <select
          value={field.get('value') || ''}
          onChange={e => onChange(field.set('value', e.target.value))}
        >
          {(field.get('options') || ['']).map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </InputScaffold>

      <InputScaffold
        label="Description"
        validation={translateErrors(field.get('error')?.description)}
      >
        <textarea
          name="description"
          value={field.get('description') || ''}
          onChange={e => onChange(field.set('description', e.target.value))}
        />
      </InputScaffold>

      <ToggleScaffold
        label="Required"
        validation={translateErrors(field.get('error')?.required)}
      >
        <Checkbox
          id="required"
          name="required"
          checked={!!field.get('required')}
          onChange={e => onChange(field.set('required', e.target.checked))}
        />
      </ToggleScaffold>
    </div>
  );
};

Select.propTypes = {
  field: PropTypes.object,
  onFieldRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default Select;
