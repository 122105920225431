import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { isRequired } from 'revalidate';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { fetchDepartmentList } from '../department/department-actions';
import { getDepartmentList } from '../department/department-selectors';
import { bulkSendEmail } from './email-actions';
import {
  getCurrentEmailType,
  getCurrentEvent,
  getEmailTypePeriods,
  getEmailTypeSections,
} from '../event/event-selectors';
import { showNotification } from '../notification/notification-actions';
import styled from 'styled-components';
import departmentTypeMap from '../lib/department-type-map';
import isNotEmpty from '../common/validate/array-is-not-empty';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import SelectAllMultiSelect from '../common/forms/SelectAllMultiSelect';
import FormStatusButton from '../common/FormStatusButton';
import PaperSwitch from '../common/paper/PaperSwitch';
import Icon from '../common/icons/Icon';
import SelectAllCheckboxGroup from '../common/forms/SelectAllCheckboxGroup';

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  button {
    margin-right: 20px;
  }
`;

const BulkSend = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const [includeDisabled, setIncludeDisabled] = useState(
    searchParams.has('includeDisabled'),
  );

  const emailType = useSelector(state =>
    getCurrentEmailType(state, { params }),
  );

  const emailTypeId = emailType?.get('id');
  const canBulkSend = !!emailType?.get('can_bulk_send');
  const bulkSendOptions = emailType?.get('bulk_send_options')?.toJSON() ?? {};

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const sections = useSelector(state =>
    getEmailTypeSections(state, {
      params,
      emailTypeId,
      includeDisabled,
    }),
  );

  const periods = useSelector(state =>
    getEmailTypePeriods(state, {
      params,
      emailTypeId,
      includeDisabled,
    }),
  );

  const [section, setSection] = useState(sections ? sections[0] : null);

  const departments = useSelector(state =>
    getDepartmentList(state, {
      params: { ...params, departmentType: section },
    }),
  );

  const initialValues = {
    section,
    periods: [],
    departments: [],
    cateringPeriods: [],
  };

  useEffect(() => {
    if (event && section)
      dispatch(fetchDepartmentList(event.get('id'), section));
  }, [dispatch, event, section]);

  useEffect(() => {
    if (sections)
      setSection(prevSection =>
        prevSection !== sections[0] ? sections[0] : prevSection,
      );
  }, [sections]);

  const handleSubmit = values => {
    const payload = { includeDisabled };

    if (bulkSendOptions.section) payload.section = values.section;

    if (bulkSendOptions.departments)
      payload.departments = values.departments?.map(d => d);

    if (bulkSendOptions.periods)
      payload.periods = values.periods?.map(ct => ct.value);

    if (bulkSendOptions.cateringPeriods)
      payload.cateringPeriods = values.cateringPeriods?.map(ct => ct.value);

    return dispatch(
      bulkSendEmail(event.get('id'), emailType.get('id'), payload),
    ).then(action => {
      if (!action.response.ok) {
        dispatch(
          showNotification({
            message: 'Error sending email',
            status: 'error',
          }),
        );
        return formatValidationErrors(action.json).toJS();
      } else {
        const { emailResponse } = action.json;
        let notification = {
          message: `There are currently no Credential Link Created for any selected departments.`,
          status: 'info',
        };
        if ('number' == typeof emailResponse) {
          notification =
            emailResponse > 0
              ? {
                  message: `${
                    emailResponse === 1 ? 'Email' : emailResponse + ' Emails'
                  } successfully sent.`,
                  status: 'success',
                }
              : {
                  message: `There are currently no email addresses configured for any selected departments.`,
                  status: 'info',
                };
        }
        dispatch(showNotification(notification));
      }
    });
  };

  const renderSectionOptions = () =>
    sections?.map(section => (
      <option key={section} value={section}>
        {departmentTypeMap[section].label.plural}
      </option>
    )) ?? [];

  const renderDepartmentsOptions = () =>
    departments
      ?.map(department => ({
        value: String(department.get('id')),
        label: department.get('name'),
      }))
      .toArray() ?? [];

  const renderPeriodsOptions = () =>
    periods
      ?.map(period => ({
        value: String(period.get('id')),
        label: period.get('name'),
      }))
      .toArray() ?? [];

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <div>
          <Paper>
            <PaperHeader
              backTo=".."
              title={`Bulk Send: ${emailType?.get('label')}`}
              actions={[
                <div
                  key="enable-email-template"
                  style={{ marginRight: '15px' }}
                >
                  <PaperSwitch
                    name="enabled"
                    title="Include disabled templates"
                    activated={includeDisabled}
                    performAction={setIncludeDisabled}
                  />
                </div>,
              ]}
            />
            {!canBulkSend && (
              <div className="generic-list--empty">
                <Icon icon="Sad" />
                <p>No Email Templates are available to Bulk Send</p>
              </div>
            )}
            {canBulkSend && (
              <form
                className="generic-form paper__wrapper"
                onSubmit={handleSubmit}
              >
                <div className="generic-form__body ">
                  <div className="input-group">
                    <div className="input-group--small">
                      {bulkSendOptions.section && (
                        <Field
                          name="section"
                          label="Section"
                          component={ReduxFormsField}
                          onChange={setSection}
                          required
                          validate={isRequired('Section')}
                        >
                          <select>{renderSectionOptions()}</select>
                        </Field>
                      )}
                    </div>
                    {bulkSendOptions.departments && (
                      <Field
                        name="departments"
                        component={ReduxFormsFieldNoLabel}
                        label="Departments"
                        required
                        validate={isNotEmpty('Departments')}
                      >
                        <SelectAllCheckboxGroup
                          className="checkboxes-horizontal"
                          name="departments"
                          options={renderDepartmentsOptions()}
                        />
                      </Field>
                    )}
                    {bulkSendOptions.periods && (
                      <Field
                        name="periods"
                        component={ReduxFormsFieldNoLabel}
                        label="Periods"
                        required
                        validate={isNotEmpty('Periods')}
                      >
                        <SelectAllMultiSelect
                          name="periods"
                          options={renderPeriodsOptions()}
                        />
                      </Field>
                    )}
                    {bulkSendOptions.cateringPeriods && (
                      <Field
                        name="cateringPeriods"
                        component={ReduxFormsFieldNoLabel}
                        label="Catering Periods"
                        required
                        validate={isNotEmpty('Catering Periods')}
                      >
                        <SelectAllMultiSelect
                          name="periods"
                          options={renderPeriodsOptions()}
                        />
                      </Field>
                    )}
                  </div>
                </div>
                <div className="generic-form__footer">
                  <Footer>
                    <FormStatusButton buttonText="Send" completeText="Sent!" />
                  </Footer>
                </div>
              </form>
            )}
          </Paper>
        </div>
      )}
    />
  );
};

export default BulkSend;
