import client from '../client';
import omit from 'lodash/omit';

export const SUBMIT_DEPARTMENT = 'SUBMIT_DEPARTMENT';
export function submitDepartment(departmentValues) {
  return {
    eventId: departmentValues.event,
    departmentType: departmentValues.type,
    departmentId: departmentValues.id,
    type: SUBMIT_DEPARTMENT,
    promise: departmentValues.id
      ? client.put(
          `departments/${departmentValues.id}`,
          omit(departmentValues, 'id'),
        )
      : client.post('departments', departmentValues),
  };
}

export const FETCH_DEPARTMENT_LIST = 'FETCH_DEPARTMENT_LIST';
export function fetchDepartmentList(eventId, departmentType) {
  return {
    eventId,
    departmentType,
    type: FETCH_DEPARTMENT_LIST,
    promise: client.get(`events/${eventId}/${departmentType}`),
  };
}

export const FETCH_DEPARTMENT_LIST_RESET = 'FETCH_DEPARTMENT_LIST_RESET';
export function clearDepartmentList(eventId, departmentType) {
  return {
    eventId,
    departmentType,
    originalType: FETCH_DEPARTMENT_LIST,
    type: FETCH_DEPARTMENT_LIST_RESET,
  };
}

export const FETCH_DEPARTMENT_SUMMARIES = 'FETCH_DEPARTMENT_SUMMARIES';
export function fetchDepartmentSummaries(eventId, departmentType) {
  return {
    eventId,
    departmentType,
    type: FETCH_DEPARTMENT_SUMMARIES,
    promise: client.get(
      `events/${eventId}/${departmentType}/credential-requests-summary`,
    ),
  };
}

export const FETCH_DEPARTMENT = 'FETCH_DEPARTMENT';
export function fetchDepartment(departmentId) {
  return {
    departmentId,
    type: FETCH_DEPARTMENT,
    promise: client.get(`departments/${departmentId}`),
  };
}

export const FETCH_DEPARTMENT_USER_LIST = 'FETCH_DEPARTMENT_USER_LIST';
export function fetchUserList(departmentId) {
  return {
    departmentId,
    type: FETCH_DEPARTMENT_USER_LIST,
    promise: client.get(`users/department-user/${departmentId}`),
  };
}

export const INVITE_DEPARTMENT_USER = 'INVITE_DEPARTMENT_USER';
export function inviteUser(departmentId, payload) {
  return {
    type: INVITE_DEPARTMENT_USER,
    promise: client.post(`users/department-user/${departmentId}`, payload),
  };
}

export const INVITE_DEPARTMENT_USER_RESET = 'INVITE_DEPARTMENT_USER_RESET';
export function clearNewUser() {
  return {
    type: INVITE_DEPARTMENT_USER_RESET,
  };
}

export const RESEND_DEPARTMENT_USER_INVITE = 'RESEND_DEPARTMENT_USER_INVITE';
export function resendUserInvite(userId) {
  return {
    type: RESEND_DEPARTMENT_USER_INVITE,
    promise: client.post(`users/${userId}/resend-invite`),
  };
}

export const FETCH_DEPARTMENT_USER_LIST_RESET =
  'FETCH_DEPARTMENT_USER_LIST_RESET';
export function clearUserList() {
  return {
    type: FETCH_DEPARTMENT_USER_LIST_RESET,
  };
}

export const ADD_DEPARTMENT_CREDENTIAL_TYPE = 'ADD_DEPARTMENT_CREDENTIAL_TYPE';
export function addDepartmentCredentialType(department, credentialTypeId) {
  const departmentId = department.get('id');
  return {
    eventId: department.get('event_id'),
    departmentId,
    departmentType: department.get('type'),
    credentialTypeId,
    type: ADD_DEPARTMENT_CREDENTIAL_TYPE,
    promise: client.put(
      `departments/${departmentId}/credential-types/${credentialTypeId}`,
    ),
  };
}

export const REMOVE_DEPARTMENT_CREDENTIAL_TYPE =
  'REMOVE_DEPARTMENT_CREDENTIAL_TYPE';
export function removeDepartmentCredentialType(department, credentialTypeId) {
  const departmentId = department.get('id');
  return {
    eventId: department.get('event_id'),
    departmentId,
    departmentType: department.get('type'),
    credentialTypeId,
    type: REMOVE_DEPARTMENT_CREDENTIAL_TYPE,
    promise: client.delete(
      `departments/${departmentId}/credential-types/${credentialTypeId}`,
    ),
  };
}

export const UPDATE_CREDENTIAL_TYPES_DEPARTMENTS =
  'UPDATE_CREDENTIAL_TYPES_DEPARTMENTS';
export function updateCredentialTypesDepartments(
  department,
  credentialTypeId,
  payload,
) {
  return {
    departmentId: department.get('id'),
    departmentType: department.get('type'),
    eventId: department.get('event_id'),
    credentialTypeId,
    payload: payload,
    type: UPDATE_CREDENTIAL_TYPES_DEPARTMENTS,
    promise: client.patch(
      `departments/${department.get(
        'id',
      )}/credential-types/${credentialTypeId}`,
      payload,
    ),
  };
}

export const UPSERT_DEPARTMENT_CREDENTIAL_MAXIMUMS =
  'UPSERT_DEPARTMENT_CREDENTIAL_MAXIMUMS';
export function upsertDepartmentCredentialMaximum(
  department,
  credential,
  payload,
) {
  return {
    departmentId: department.get('id'),
    departmentType: department.get('type'),
    eventId: department.get('event_id'),
    credentialId: credential.get('id'),
    credentialTypeId: credential.get('credential_type_id'),
    periodId: credential.get('period_id'),
    maximumRequestable: payload.maximum_requestable,
    type: UPSERT_DEPARTMENT_CREDENTIAL_MAXIMUMS,
    promise: client.put(
      `departments/${department.get('id')}/credentials/${credential.get('id')}`,
      payload,
    ),
  };
}

export const CREATE_DEPARTMENT_MEAL_TYPE_OVERRIDE =
  'CREATE_DEPARTMENT_MEAL_TYPE_OVERRIDE';
export function createDepartmentMealTypeOverride(department, mealTypeId) {
  return {
    type: CREATE_DEPARTMENT_MEAL_TYPE_OVERRIDE,
    promise: client.post(
      `departments/${department.get('id')}/meal-types/${mealTypeId}/override`,
    ),
    departmentId: department.get('id'),
    departmentType: department.get('type'),
    eventId: department.get('event_id'),
    mealTypeId,
  };
}

export const DELETE_DEPARTMENT_MEAL_TYPE_OVERRIDE =
  'DELETE_DEPARTMENT_MEAL_TYPE_OVERRIDE';
export function deleteDepartmentMealTypeOverride(department, mealTypeId) {
  return {
    type: DELETE_DEPARTMENT_MEAL_TYPE_OVERRIDE,
    promise: client.delete(
      `departments/${department.get('id')}/meal-types/${mealTypeId}/override`,
    ),
    departmentId: department.get('id'),
    departmentType: department.get('type'),
    eventId: department.get('event_id'),
    mealTypeId,
  };
}

export const SUBMIT_DEPARTMENT_SETTINGS = 'SUBMIT_DEPARTMENT_SETTINGS';
export function submitDepartmentSettings(
  eventId,
  departmentType,
  departmentValues,
) {
  delete departmentValues.event;
  delete departmentValues.department;

  return {
    type: SUBMIT_DEPARTMENT_SETTINGS,
    promise: client.put(
      `event/${eventId}/settings/${departmentType}`,
      departmentValues,
    ),
  };
}

export const FETCH_DEPARTMENT_MEAL_TYPES = 'FETCH_DEPARTMENT_MEAL_TYPES';
export function fetchDepartmentMealTypes(departmentId) {
  return {
    type: FETCH_DEPARTMENT_MEAL_TYPES,
    promise: client.get(`departments/${departmentId}/meal-types`),
  };
}

export const CREATE_DEPARTMENT_MEAL_TYPE_EXCEPTION =
  'CREATE_DEPARTMENT_MEAL_TYPE_EXCEPTION';
export function createDepartmentMealTypeException(department, mealTypeId) {
  return {
    type: CREATE_DEPARTMENT_MEAL_TYPE_EXCEPTION,
    promise: client.post(
      `departments/${department.get('id')}/meal-types/${mealTypeId}/exception`,
    ),
    department,
    mealTypeId,
  };
}

export const DELETE_DEPARTMENT_MEAL_TYPE_EXCEPTION =
  'DELETE_DEPARTMENT_MEAL_TYPE_EXCEPTION';
export function deleteDepartmentMealTypeException(department, mealTypeId) {
  return {
    type: DELETE_DEPARTMENT_MEAL_TYPE_EXCEPTION,
    promise: client.delete(
      `departments/${department.get('id')}/meal-types/${mealTypeId}/exception`,
    ),
    mealTypeId,
  };
}

export const FETCH_DEPARTMENT_GROUP_LIST = 'FETCH_DEPARTMENT_GROUP_LIST';
export function fetchDepartmentGroupList(eventId, departmentType) {
  return {
    type: FETCH_DEPARTMENT_GROUP_LIST,
    eventId,
    departmentType,
    promise: client.get(
      `events/${eventId}/${departmentType}/department-groups`,
    ),
  };
}

export const FETCH_DEPARTMENT_GROUP = 'FETCH_DEPARTMENT_GROUP';
export function fetchDepartmentGroup(eventId, departmentGroupId) {
  return {
    type: FETCH_DEPARTMENT_GROUP,
    eventId,
    departmentGroupId,
    promise: client.get(
      `events/${eventId}/department-groups/${departmentGroupId}`,
    ),
  };
}

export const SUBMIT_DEPARTMENT_GROUP = 'SUBMIT_DEPARTMENT_GROUP';
export function createDepartmentGroup(eventId, departmentType, payload) {
  return {
    type: SUBMIT_DEPARTMENT_GROUP,
    eventId,
    departmentType,
    promise: client.post(
      `events/${eventId}/${departmentType}/create-group`,
      payload,
    ),
  };
}

export const UPDATE_DEPARTMENT_GROUP = 'UPDATE_DEPARTMENT_GROUP';
export function updateDepartmentGroup(eventId, departmentType, payload) {
  return {
    type: UPDATE_DEPARTMENT_GROUP,
    eventId,
    departmentType,
    departmentGroupId: payload.id,
    departmentGroupName: payload.name,
    promise: client.put(
      `events/${eventId}/${departmentType}/edit-group`,
      payload,
    ),
  };
}

export const DELETE_DEPARTMENT_GROUP = 'DELETE_DEPARTMENT_GROUP';
export function deleteDepartmentGroup(
  eventId,
  departmentType,
  departmentGroupId,
) {
  return {
    type: DELETE_DEPARTMENT_GROUP,
    eventId,
    departmentGroupId,
    departmentType,
    promise: client.delete(
      `events/${eventId}/${departmentType}/department-groups/${departmentGroupId}`,
    ),
  };
}

export function getDepartmentsUploadTemplate(eventId, departmentType) {
  return client.get(`events/${eventId}/${departmentType}/uploads/template`);
}

export const UPLOAD_DEPARTMENTS = 'UPLOAD_DEPARTMENTS';
export function uploadDepartments(eventId, departmentType, payload) {
  return {
    type: UPLOAD_DEPARTMENTS,
    promise: client.post(
      `events/${eventId}/${departmentType}/uploads`,
      payload,
    ),
  };
}

export const FETCH_DEPARTMENTS_UPLOADS = 'FETCH_DEPARTMENTS_UPLOADS';
export function fetchDepartmentsUploads(eventId, departmentType) {
  return {
    type: FETCH_DEPARTMENTS_UPLOADS,
    promise: client.get(`events/${eventId}/${departmentType}/uploads`),
  };
}
