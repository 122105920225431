import client from '../client';

export const DELETE_USER = 'DELETE_USER';
export function deleteUser(userId) {
  return {
    type: DELETE_USER,
    promise: client.delete(`users/${userId}`),
    deletedUserId: userId,
  };
}

export const ENABLE_PROMOTER_ADMIN = 'ENABLE_PROMOTER_ADMIN';
export function enablePromoterAdmin(promoterId, userId) {
  return {
    promoterId,
    userId,
    type: ENABLE_PROMOTER_ADMIN,
    promise: client.put(`promoters/${promoterId}/users/${userId}`),
  };
}

export const DISABLE_PROMOTER_ADMIN = 'DISABLE_PROMOTER_ADMIN';
export function disablePromoterAdmin(promoterId, userId) {
  return {
    promoterId,
    userId,
    type: DISABLE_PROMOTER_ADMIN,
    promise: client.delete(`promoters/${promoterId}/users/${userId}`),
    deletedUserId: userId,
  };
}

export const ENABLE_EVENT_ADMIN = 'ENABLE_EVENT_ADMIN';
export function enableEventAdmin(eventId, userId) {
  return {
    eventId,
    userId,
    type: ENABLE_EVENT_ADMIN,
    promise: client.put(`events/${eventId}/users/${userId}/enable`),
  };
}

export const DISABLE_EVENT_ADMIN = 'DISABLE_EVENT_ADMIN';
export function disableEventAdmin(eventId, userId) {
  return {
    eventId,
    userId,
    type: DISABLE_EVENT_ADMIN,
    promise: client.delete(`events/${eventId}/users/${userId}`),
    deletedUserId: userId,
  };
}

export const ENABLE_DEPARTMENT_USER = 'ENABLE_DEPARTMENT_USER';
export function enableDepartmentUser(departmentId, userId) {
  return {
    departmentId,
    userId,
    type: ENABLE_DEPARTMENT_USER,
    promise: client.put(`departments/${departmentId}/users/${userId}`),
  };
}

export const DISABLE_DEPARTMENT_USER = 'DISABLE_DEPARTMENT_USER';
export function disableDepartmentUser(departmentId, userId) {
  return {
    departmentId,
    userId,
    type: DISABLE_DEPARTMENT_USER,
    promise: client.delete(`departments/${departmentId}/users/${userId}`),
    deletedUserId: userId,
  };
}

export const RESEND_INVITE = 'RESEND_INVITE';
export function resendInvite(userId) {
  return {
    type: RESEND_INVITE,
    promise: client.post(`users/${userId}/resend-invite`),
  };
}

export const EDIT_EVENT_USER = 'EDIT_EVENT_USER';
export function editEventUser(eventId, userId, values) {
  return {
    type: EDIT_EVENT_USER,
    promise: client.put(`events/${eventId}/users/${userId}`, values),
  };
}

export const FETCH_INVITATION_LINK = 'FETCH_INVITATION_LINK';
export function fetchInvitationLink(userId) {
  return {
    type: FETCH_INVITATION_LINK,
    promise: client.get(`users/${userId}/invitation-link`),
  };
}
