import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getCurrentEvent,
  getEventOnsiteRequestTypes,
  getEventOnsiteSettings,
} from './event-selectors';
import {
  fetchEventOnsiteRequestTypes,
  fetchEventOnsiteSettings,
  updateEventOnsiteRequestType,
  updateEventOnsiteSettings,
} from './event-actions';
import { validateEmailList } from '../common/validate/is-valid-email-list';
import { showNotification } from '../notification/notification-actions';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ExpandableRow from '../common/ExpandableRow';
import SwitchOn from '../common/icons/SwitchOn';
import SwitchOff from '../common/icons/SwitchOff';
import InputScaffoldNoLabel from '../common/forms/InputScaffoldNoLabel';
import Icon from '../common/icons/Icon';

const EventOnsiteSettingsForm = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const eventOnsiteSettings = useSelector(state =>
    getEventOnsiteSettings(state, { params }),
  );
  const eventOnsiteRequestTypes = useSelector(state =>
    getEventOnsiteRequestTypes(state, { params }),
  );

  const [emailBcc, setEmailBcc] = useState('');
  const [emailBccValidationError, setEmailBccValidationError] = useState('');

  useEffect(() => {
    if (!event) return;

    const eventId = event.get('id');
    dispatch(fetchEventOnsiteSettings(eventId)).then(action =>
      setEmailBcc(action.json.email_bcc ?? ''),
    );
    dispatch(fetchEventOnsiteRequestTypes(eventId));
  }, [dispatch, event]);

  const updateEmailBcc = value => {
    setEmailBcc(value ?? '');
    setEmailBccValidationError('');
  };

  const renderOnsiteRequestTypes = () => {
    if (!eventOnsiteRequestTypes) return null;

    return eventOnsiteRequestTypes.map(requestType => (
      <ExpandableRow
        key={requestType.get('id')}
        columns={[
          <span className="expandable-row__title">
            {requestType.get('onsite_request_type')}
          </span>,
          <div className="expandable-row__switch">
            <button
              className="switch__button"
              onClick={e => {
                e.stopPropagation();
                dispatch(
                  updateEventOnsiteRequestType(
                    event.get('id'),
                    requestType.get('id'),
                    { isEnabled: !requestType.get('is_enabled') },
                  ),
                );
              }}
            >
              {requestType.get('is_enabled') ? <SwitchOn /> : <SwitchOff />}
            </button>
            <span>Enabled For Event</span>
          </div>,
          <div className="expandable-row__switch">
            <button
              className="switch__button"
              onClick={e => {
                e.stopPropagation();
                dispatch(
                  updateEventOnsiteRequestType(
                    event.get('id'),
                    requestType.get('id'),
                    { autoApprove: !requestType.get('auto_approve') },
                  ),
                );
              }}
            >
              {requestType.get('auto_approve') ? <SwitchOn /> : <SwitchOff />}
            </button>
            <span>Auto Approve</span>
          </div>,
          <div className="expandable-row__switch">
            <button
              className="switch__button"
              onClick={e => {
                e.stopPropagation();
                dispatch(
                  updateEventOnsiteRequestType(
                    event.get('id'),
                    requestType.get('id'),
                    {
                      sendReceiptEmail: !requestType.get('send_receipt_email'),
                    },
                  ),
                );
              }}
            >
              {requestType.get('send_receipt_email') ? (
                <SwitchOn />
              ) : (
                <SwitchOff />
              )}
            </button>
            <span>Send Receipt Email</span>
          </div>,
          <div className="expandable-row__switch">
            <button
              className="switch__button"
              onClick={e => {
                e.stopPropagation();
                dispatch(
                  updateEventOnsiteRequestType(
                    event.get('id'),
                    requestType.get('id'),
                    {
                      sendReviewedEmail: !requestType.get(
                        'send_reviewed_email',
                      ),
                    },
                  ),
                );
              }}
            >
              {requestType.get('send_reviewed_email') ? (
                <SwitchOn />
              ) : (
                <SwitchOff />
              )}
            </button>
            <span>Send Reviewed Email</span>
          </div>,
        ]}
        isExpandable={false}
        isExpanded={false}
        onToggleExpansion={() => {}}
      />
    ));
  };

  return (
    <div>
      <Paper>
        <PaperHeader title="Event Onsite Requests" />
      </Paper>
      {eventOnsiteSettings && (
        <div>
          <ExpandableRow
            columns={[
              <span className="expandable-row__title">
                Onsite Requests Enabled for Event
              </span>,
              <button
                className="switch__button"
                onClick={e => {
                  e.stopPropagation();
                  dispatch(
                    updateEventOnsiteSettings(event, {
                      enabled: !eventOnsiteSettings.get('enabled'),
                      overrideCredentialRequestMaximums:
                        !eventOnsiteSettings.get('enabled'),
                    }),
                  );
                }}
              >
                {eventOnsiteSettings.get('enabled') ? (
                  <SwitchOn />
                ) : (
                  <SwitchOff />
                )}
              </button>,
            ]}
            isExpandable={false}
            isExpanded={false}
            onToggleExpansion={() => {}}
          />
          {!eventOnsiteSettings.get('enabled') ? null : (
            <div>
              <ExpandableRow
                columns={[
                  <span className="expandable-row__title">
                    Filter Credentials By Period
                  </span>,
                  <button
                    className="switch__button"
                    onClick={e => {
                      e.stopPropagation();
                      dispatch(
                        updateEventOnsiteSettings(event, {
                          filterCredentialsByPeriod: !eventOnsiteSettings.get(
                            'filter_credentials_by_period',
                          ),
                        }),
                      );
                    }}
                  >
                    {eventOnsiteSettings.get('filter_credentials_by_period') ? (
                      <SwitchOn />
                    ) : (
                      <SwitchOff />
                    )}
                  </button>,
                ]}
                isExpandable={false}
                isExpanded={false}
                onToggleExpansion={() => {}}
              />
              <ExpandableRow
                columns={[
                  <span className="expandable-row__title">
                    Require Email for Staff or Personnel
                  </span>,
                  <button
                    className="switch__button"
                    onClick={e => {
                      e.stopPropagation();
                      dispatch(
                        updateEventOnsiteSettings(event, {
                          requireContactEmails: !eventOnsiteSettings.get(
                            'require_contact_emails',
                          ),
                        }),
                      );
                    }}
                  >
                    {eventOnsiteSettings.get('require_contact_emails') ? (
                      <SwitchOn />
                    ) : (
                      <SwitchOff />
                    )}
                  </button>,
                ]}
                isExpandable={false}
                isExpanded={false}
                onToggleExpansion={() => {}}
              />
              <ExpandableRow
                columns={[
                  <span className="expandable-row__title">
                    Override Onsite Credential Request Maximums for Event
                  </span>,
                  <button
                    className="switch__button"
                    onClick={e => {
                      e.stopPropagation();
                      dispatch(
                        updateEventOnsiteSettings(event, {
                          overrideCredentialRequestMaximums:
                            !eventOnsiteSettings.get(
                              'override_credential_request_maximums',
                            ),
                        }),
                      );
                    }}
                  >
                    {eventOnsiteSettings.get(
                      'override_credential_request_maximums',
                    ) ? (
                      <SwitchOn />
                    ) : (
                      <SwitchOff />
                    )}
                  </button>,
                ]}
                isExpandable={false}
                isExpanded={false}
                onToggleExpansion={() => {}}
              />
              <ExpandableRow
                columns={[
                  <span className="expandable-row__title">Email BCC</span>,
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <InputScaffoldNoLabel validation={emailBccValidationError}>
                      <input
                        type="text"
                        value={emailBcc}
                        onChange={event => updateEmailBcc(event.target.value)}
                      />
                    </InputScaffoldNoLabel>
                    <button
                      type="button"
                      className="button--icon"
                      style={{ cursor: 'pointer', marginLeft: '5px' }}
                      onClick={e => {
                        e.stopPropagation();
                        if (!validateEmailList(emailBcc)) {
                          setEmailBccValidationError(
                            'Contains invalid email address',
                          );
                        } else {
                          dispatch(
                            updateEventOnsiteSettings(event, {
                              emailBcc: emailBcc.trim(),
                            }),
                          ).then(action => {
                            if (action.response.ok) {
                              dispatch(
                                showNotification({
                                  message: 'Email BCC successfully updated',
                                  status: 'success',
                                }),
                              );
                              updateEmailBcc(action.json.email_bcc);
                            } else {
                              setEmailBccValidationError(
                                action.json.message ?? '',
                              );
                            }
                          });
                        }
                      }}
                    >
                      <Icon icon="CheckMark" />
                    </button>
                    <button
                      type="button"
                      className="button--icon"
                      style={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.stopPropagation();
                        updateEmailBcc(eventOnsiteSettings.get('email_bcc'));
                      }}
                    >
                      <Icon icon="Sync" />
                    </button>
                  </div>,
                ]}
                isExpandable={false}
                isExpanded={false}
                onToggleExpansion={() => {}}
              />
              <div>
                <Paper>
                  <PaperHeader title="Onsite Request Types" />
                </Paper>
                {renderOnsiteRequestTypes()}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventOnsiteSettingsForm;
