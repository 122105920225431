import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useField, useFormState } from 'react-final-form';
import { ValidationError } from '../common/StyledValidationError';
import { getEnabledEventSections } from './event-selectors';
import departmentTypeMap from '../lib/department-type-map';
import PropTypes from 'prop-types';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ToggleGroup from '../common/forms/ToggleGroup';
import FormSection from '../common/forms/FormSection';
import StatusButton from '../common/StatusButton';

export const NO_ROLES_ERROR = 'At least one role is required';

const SectionPermissionsForm = ({ section }) => {
  const formState = useFormState();

  const topLevelFieldName = `${section}_view`;
  const singular = departmentTypeMap[section].label.singular;

  const field = useField(`roles.${topLevelFieldName}`, {
    validate: (sectionEnabled, { roles }) =>
      !sectionEnabled ||
      Object.entries(roles).some(
        ([role, isEnabled]) =>
          role !== topLevelFieldName && role.startsWith(section) && isEnabled,
      )
        ? undefined
        : `At least one ${singular} role is required`,
  });

  const eventAdminSelected = formState.values.roles.event_admin;
  const eventCoordinatorSelected = formState.values.roles.event_coordinator;
  const cateringAdminSelected = formState.values.roles.catering_admin;

  if (eventAdminSelected) {
    return null;
  }

  const toggles = [];

  if (!eventCoordinatorSelected) {
    toggles.push.apply(toggles, [
      {
        label: 'Edit',
        name: `${section}_edit`,
        description: `Can edit ${singular} section`,
      },
      {
        label: 'Request Credentials',
        name: `${section}_credential`,
        description: `Can request credentials in ${singular} section`,
      },
    ]);
  }

  if (section === 'intern') {
    toggles.push.apply(toggles, [
      {
        label: 'Approve Credentials and Departments',
        name: `${section}_approve`,
        description: `Can access credential approvals and approve departments in ${singular} section`,
      },
    ]);
  } else {
    toggles.push.apply(toggles, [
      {
        label: 'Approve Credentials',
        name: `${section}_approve`,
        description: `Can approve credentials in ${singular} section`,
      },
    ]);

    if (section === 'media') {
      toggles.push.apply(toggles, [
        {
          label: 'Approve Applications',
          name: `${section}_approve_department`,
          description: `Can approve applications in the ${singular} section`,
        },
      ]);
    }
  }

  if (!cateringAdminSelected) {
    if (!['intern', 'media'].includes(section))
      toggles.push.apply(toggles, [
        {
          label: 'Manage Catering',
          name: `${section}_catering`,
          description: `Can manage catering in ${singular} section`,
        },
      ]);
  }

  if (!eventCoordinatorSelected) {
    toggles.push.apply(toggles, [
      {
        label: 'View Reports',
        name: `${section}_report`,
        description: `Can view and download reports in ${singular} section`,
      },
    ]);
  }

  return (
    <>
      {field.meta.invalid && (
        <ValidationError>{field.meta.error}</ValidationError>
      )}
      <div key={singular} style={{ margin: '10px 0' }}>
        <ToggleGroup
          key={section}
          label={departmentTypeMap[section].label.plural}
          topLevelFieldName={topLevelFieldName}
          toggles={toggles}
        />
      </div>
    </>
  );
};

const EventAdminPermissionsForm = ({ cancelLink }) => {
  const formState = useFormState();
  const params = useParams();

  const eventAdminSelected = formState.values.roles.event_admin;

  const enabledSections = useSelector(state =>
    getEnabledEventSections(state, { params }),
  );

  return (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <Paper>
          <PaperHeader title="Event Roles" />
        </Paper>
      </div>

      <FormSection name="roles">
        {formState.submitError && !formState.modifiedSinceLastSubmit && (
          <ValidationError>{formState.submitError}</ValidationError>
        )}
        <ToggleGroup
          label="Event Users"
          description="Event user roles are granted per event. If an event user is invited to an event they can be granted these roles and they will only apply to the one event they were added to."
          toggles={[
            {
              label: 'Event Admin',
              name: 'event_admin',
              description: 'Can do any action',
              hiddenIfToggled: ['event_coordinator'],
            },
            {
              label: 'Event Coordinator',
              name: 'event_coordinator',
              description:
                'Can do any action other than approve credentials and media applications',
              hiddenIfToggled: ['event_admin'],
            },
            {
              label: 'Edit Credentials',
              name: 'credential_edit',
              description:
                'Can create and edit credential categories and types',
              hiddenIfToggled: ['event_admin', 'event_coordinator'],
            },
            {
              label: 'Approve Credentials',
              name: 'credential_approve',
              description: 'Can approve credentials',
              hiddenIfToggled: ['event_admin', 'event_coordinator'],
            },
            {
              label: 'Manage Catering',
              name: 'catering_admin',
              description: 'Can manage catering',
              hiddenIfToggled: ['event_admin', 'event_coordinator'],
            },
            {
              label: 'View Reports',
              name: 'reports_view',
              description: 'Can generate reports',
              hiddenIfToggled: ['event_admin', 'event_coordinator'],
            },
          ]}
        />

        {!eventAdminSelected && (
          <div style={{ margin: '10px 0' }}>
            <Paper>
              <PaperHeader title="Section Roles" />
            </Paper>
          </div>
        )}

        {!eventAdminSelected &&
          enabledSections?.map(section => (
            <SectionPermissionsForm section={section} />
          ))}

        <Paper className="generic-form__footer">
          <StatusButton
            type="submit"
            status={
              formState.submitting
                ? 'loading'
                : formState.hasSubmitErrors &&
                  !formState.modifiedSinceLastSubmit
                ? 'error'
                : 'default'
            }
            disabled={
              formState.pristine ||
              formState.hasValidationErrors ||
              (formState.hasSubmitErrors &&
                !formState.modifiedSinceLastSubmit) ||
              formState.submitting
            }
          />

          <Link
            className="button button--plain"
            to={cancelLink}
            disabled={formState.submitting}
          >
            Cancel
          </Link>
        </Paper>
      </FormSection>
    </div>
  );
};

EventAdminPermissionsForm.propTypes = {
  cancelLink: PropTypes.string.isRequired,
};

export default EventAdminPermissionsForm;
