import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { combineValidators, isRequired } from 'revalidate';
import { resource, useACL } from '../common/ACL';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { getCurrentEvent } from '../event/event-selectors';
import { submitDepartment } from './department-actions';
import {
  getCurrentDepartment,
  getDepartmentSettings,
} from './department-selectors';
import isValidEmail from '../common/validate/is-valid-email';
import recursiveTrim from '../common/utils/recursive-trim';
import departmentTypeMap from '../lib/department-type-map';
import PropTypes from 'prop-types';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import CheckboxBoolean from '../common/forms/CheckboxBoolean';
import DepartmentCredentialList from './DepartmentCredentialList';
import DepartmentCateringList from './DepartmentCateringList';
import { showNotification } from '../notification/notification-actions';

const aclRules = {
  canEdit: [resource.DEPARTMENT, 'admin-edit'],
  canManageCatering: [resource.DEPARTMENT, 'manage-catering'],
};

const validate = combineValidators({
  name: isRequired('Name'),
  default_email_address: isValidEmail('Default Email Address'),
});

const DepartmentSettingsForm = ({ editing = false }) => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const deptSettings = useSelector(
    state => getDepartmentSettings(state, { params }) ?? {},
  );

  const subtypeList = useSelector(
    state => getDepartmentSettings(state, { params }, 'subtypes') ?? [],
  );

  const departmentTypeLabel =
    departmentTypeMap[params.departmentType].label.singular;

  const initialValues = {
    event: event?.get('id'),
    type: params.departmentType,
    name: department?.get('name'),
    subtype: department?.get('subtype'),
    require_contact_emails: Boolean(department?.get('require_contact_emails')),
    default_email_address: department?.get('default_email_address'),
  };

  const getReturnPath = departmentSlug => {
    const { promoterSlug, festivalSlug, eventSlug, departmentType } = params;

    let returnPath = `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/${departmentType}`;
    if (departmentSlug) returnPath += `/${departmentSlug}`;
    return returnPath;
  };

  const handleSubmit = values => {
    if (editing) values.id = department.get('id');
    if (values.subtype && values.subtype.trim() === 'Select Type')
      values.subtype = null;

    return dispatch(submitDepartment(recursiveTrim(values))).then(action => {
      if (action.response.ok) {
        if (!editing) {
          navigate(getReturnPath(action.json.slug));
        } else {
          dispatch(
            showNotification({
              message: departmentTypeLabel + ' saved',
              status: 'success',
            }),
          );
        }
      } else {
        return formatValidationErrors(action.json).toJS();
      }
    });
  };

  if (editing && !department) {
    return <LoadingIndicator />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, submitting }) => (
        <div>
          <form className="generic-form" onSubmit={handleSubmit}>
            <Paper>
              <PaperHeader
                backTo={editing ? null : getReturnPath()}
                backToDisabled={submitting}
                title={`${editing ? 'Edit' : 'Add'} ${departmentTypeLabel}`}
              />
              <div className="generic-form__body">
                <div className="input-group input-group--large">
                  <Field
                    name="name"
                    component={ReduxFormsField}
                    label={`${departmentTypeLabel} Name`}
                    required
                    disabled={editing && !acl.canEdit}
                  >
                    <input type="text" autoFocus />
                  </Field>
                  <Field
                    name="subtype"
                    component={ReduxFormsField}
                    label={`${departmentTypeLabel} Type`}
                    disabled={editing && !acl.canEdit}
                  >
                    <select>
                      {[
                        <option key="blank" value={null}>
                          Select Type
                        </option>,
                      ].concat(
                        subtypeList.map(subType => (
                          <option key={subType} value={subType}>
                            {subType}
                          </option>
                        )),
                      )}
                    </select>
                  </Field>
                  <Field
                    name="default_email_address"
                    component={ReduxFormsField}
                    label="Department Email Address"
                    disabled={editing && !acl.canEdit}
                  >
                    <input type="text" />
                  </Field>
                  <div className="department-settings">
                    <div className="department-form__personnel-email">
                      <h4>Personnel Email</h4>
                      <p>
                        This setting cannot be changed once this department is
                        created
                      </p>
                      <Field
                        name="require_contact_emails"
                        component={ReduxFormsField}
                        disabled={editing}
                      >
                        <CheckboxBoolean label="Require email for all staff or personnel" />
                      </Field>
                    </div>
                  </div>
                </div>
              </div>
              <div className="generic-form__footer">
                <button
                  type="submit"
                  className="button"
                  disabled={submitting || (editing && !acl.canEdit)}
                >
                  {editing ? 'Save' : 'Create'}
                </button>
                <Link
                  className="button button--plain"
                  disabled={submitting}
                  to={getReturnPath()}
                >
                  Cancel
                </Link>
                {department && (
                  <span style={{ marginLeft: '150px', fontSize: '18px' }}>
                    {department.get('departmentGroups')?.size > 0 &&
                      department
                        .get('group_description')
                        ?.replaceAll(',', ', ')}
                  </span>
                )}
              </div>
            </Paper>
          </form>

          <div style={{ marginTop: '10px' }} />

          {editing && acl.canEdit && <DepartmentCredentialList />}

          {editing &&
            (acl.canEdit || acl.canManageCatering) &&
            deptSettings?.get('catering') && <DepartmentCateringList />}
        </div>
      )}
    />
  );
};

DepartmentSettingsForm.propTypes = {
  editing: PropTypes.bool,
};

export default DepartmentSettingsForm;
