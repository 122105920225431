import { createSelector } from 'reselect';
import { getCurrentPromoter } from '../promoter/promoter-selectors';

export const getFestivalListLoaded = createSelector(
  getCurrentPromoter,
  state => state.festival.list,
  (promoter, festivalList) =>
    promoter ? festivalList.getIn([promoter.get('id'), 'loaded']) : undefined,
);

export const getFestivalList = createSelector(
  getCurrentPromoter,
  state => state.festival.list,
  (promoter, festivalList) =>
    promoter ? festivalList.getIn([promoter.get('id'), 'data']) : undefined,
);

export const getCurrentFestival = createSelector(
  getFestivalList,
  (_, props) => props.params.festivalSlug,
  (festivalList, festivalSlug) =>
    festivalList && festivalSlug
      ? festivalList.find(festival => festival.get('slug') === festivalSlug)
      : null,
);
