import client from '../client';
import dataUrlToFile from '../common/utils/dataUrlToFile';

export const ADD_FESTIVAL = 'ADD_FESTIVAL';
export function addFestival(festivalValues) {
  const data = new FormData();

  data.append('name', festivalValues.name);
  data.append('promoter', festivalValues.promoter_id);
  data.append('eventName', festivalValues.eventName);
  data.append(
    'pulse_festival_ids',
    JSON.stringify(festivalValues.pulse_festival_ids),
  );
  data.append('settings', JSON.stringify(festivalValues.settings));
  data.append('time_zone', festivalValues.time_zone);

  if (
    festivalValues.eventLogo &&
    festivalValues.eventLogo.result &&
    festivalValues.eventLogo.filename
  ) {
    const file = dataUrlToFile(
      festivalValues.eventLogo.result,
      festivalValues.eventLogo.filename,
    );
    data.append('eventFile', file, file.name);
  }

  return {
    type: ADD_FESTIVAL,
    promise: client.post('festivals', data),
  };
}

export const EDIT_FESTIVAL = 'EDIT_FESTIVAL';
export function editFestival(festivalValues) {
  const festivalId = festivalValues.id;
  delete festivalValues.id;
  return {
    type: EDIT_FESTIVAL,
    promise: client.put(`festivals/${festivalId}`, festivalValues),
  };
}

export const FETCH_FESTIVAL_LIST = 'FETCH_FESTIVAL_LIST';
export function fetchFestivalList(promoterId) {
  return {
    promoterId,
    type: FETCH_FESTIVAL_LIST,
    promise: client.get(`promoter/${promoterId}/festivals`),
  };
}

export const FETCH_PULSE_FESTIVAL_LIST = 'FETCH_PULSE_FESTIVAL_LIST';
export function fetchPulseFestivalList(promoterId) {
  return {
    type: FETCH_PULSE_FESTIVAL_LIST,
    promise: client.get(`pulse/festivals?promoter=${promoterId}`),
  };
}

export function resetPulseFestivalList() {
  return {
    type: 'FETCH_PULSE_FESTIVAL_LIST_RESET',
  };
}

export const FETCH_PULSE_FESTIVAL_TIMEZONE = 'FETCH_PULSE_FESTIVAL_TIMEZONE';
export function fetchPulseFestivalTimezone(promoterId, pulseFestivalId) {
  return {
    promoterId,
    pulseFestivalId,
    type: FETCH_PULSE_FESTIVAL_TIMEZONE,
    promise: client.get(
      `promoter/${promoterId}/festival/${pulseFestivalId}/timezone`,
    ),
  };
}

export function resetPulseFestivalTimezone() {
  return {
    type: 'FETCH_PULSE_FESTIVAL_TIMEZONE_RESET',
  };
}

export const FETCH_TIMEZONES = 'FETCH_TIMEZONES';
export function fetchTimezones() {
  return {
    type: FETCH_TIMEZONES,
    promise: client.get(`timezones`),
  };
}
