import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { getCurrentPromoter } from '../promoter/promoter-selectors';
import {
  getFestivalList,
  getFestivalListLoaded,
} from '../festival/festival-selectors';
import { getCurrentEvent } from '../event/event-selectors';
import { renderErrorMessage } from './onsite-utils';
import moment from 'moment';
import InputScaffold from '../common/forms/InputScaffold';
import ReduxReactSelectField from '../common/forms/ReduxReactSelectField';

const SelectEvent = () => {
  const navigate = useNavigate();
  const params = useParams();

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const festivalList = useSelector(state => getFestivalList(state, { params }));
  const festivalListLoaded = useSelector(state =>
    getFestivalListLoaded(state, { params }),
  );
  const event = useSelector(state => getCurrentEvent(state, { params }));

  const festivalOptions = useMemo(() => {
    const options = [];
    festivalList?.forEach(festival => {
      const now = moment.utc();
      var events = festival
        .get('events')
        .filter(
          event =>
            event.get('onsite_requests_enabled') &&
            (!event.get('end_date') ||
              now.isSameOrBefore(event.get('end_date'), 'day', '[]')),
        );
      events.forEach(event => {
        options.push({
          label: `${festival.get('name')} - ${event.get('name')}`,
          festivalId: festival.get('id'),
          eventId: event.get('id'),
        });
      });
    });
    return options;
  }, [festivalList]);

  const getEventUrl = option => {
    const festival = festivalList.find(
      festival => festival.get('id') === parseInt(option.festivalId, 10),
    );

    const event = festival
      .get('events')
      .find(event => event.get('id') === parseInt(option.eventId, 10));

    return `/onsite/promoters/${promoter.get('slug')}/festivals/${festival.get(
      'slug',
    )}/events/${event.get('slug')}`;
  };

  if (event || !festivalListLoaded) {
    return null;
  }

  if (festivalList.size === 0) {
    return renderErrorMessage(
      'You do not have access to any festivals or events.',
    );
  }

  if (festivalOptions.length === 0) {
    return renderErrorMessage(
      'You do not have access to any active festivals or events.',
    );
  }

  if (festivalOptions.length === 1) {
    return <Navigate to={getEventUrl(festivalOptions[0])} />;
  }

  return (
    <InputScaffold label="Events" required>
      <ReduxReactSelectField
        autoFocus={true}
        className="onsite-select"
        placeholder="Select event..."
        options={festivalOptions}
        onChange={selectedOption => navigate(getEventUrl(selectedOption))}
      />
    </InputScaffold>
  );
};

export default SelectEvent;
