import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import {
  getCurrentEvent,
  getEnabledEventSections,
} from '../../event/event-selectors';
import { formatValidationErrors } from '../../common/utils/getApiReducer';
import { showNotification } from '../../notification/notification-actions';
import { addCredentialType } from './credential-type-actions';
import moment from 'moment';
import LoadingIndicator from '../../common/LoadingIndicator';
import NotFound from '../../common/NotFound';
import CredentialTypeForm from './CredentialTypeForm';
import recursiveTrim from '../../common/utils/recursive-trim';

const CredentialTypeCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const categoryList = useSelector(state =>
    state.credentialType.categoryList.get('data'),
  );

  const category = categoryList?.find(
    category => category.get('id') === parseInt(params.categoryId, 10),
  );

  const enabledSections =
    useSelector(state => getEnabledEventSections(state, { params })) ?? [];

  const attendeeTypes = {};
  enabledSections.forEach(section => {
    attendeeTypes[section] = 'individual';
  });

  const initialValues = {
    name: '',
    lock_date: category?.get('lock_after_date')
      ? moment(category.get('lock_after_date'))
      : null,
    attendee_types: attendeeTypes,
    access_type: 'NO_FESTIVAL_ACCESS',
    type: 'RFID',
    pass_fulfillment: { issue_frequency: 'PERIODICALLY', periods: [] },
  };

  const handleSubmit = values => {
    const submittedValues = cloneDeep(values);

    submittedValues.category_id = params.categoryId;

    submittedValues.issue_frequency =
      submittedValues.pass_fulfillment.issue_frequency;
    delete submittedValues.pass_fulfillment.issue_frequency;

    if (submittedValues.lock_date) {
      submittedValues.lock_date.hour(23).minute(59).second(59);
    }

    return dispatch(
      addCredentialType(event.get('id'), recursiveTrim(submittedValues)),
    ).then(action => {
      if (!action.response.ok) {
        const errors = formatValidationErrors(action.json).toJS();
        errors._error = action.response;
        return errors;
      }
      navigate(
        `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/credentials/types`,
      );
      dispatch(
        showNotification({
          message: 'Credential type successfully added',
          status: 'success',
        }),
      );
    });
  };

  if (!categoryList) return <LoadingIndicator />;
  if (!category) return <NotFound />;

  return (
    <CredentialTypeForm
      event={event}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enabledSections={enabledSections}
    />
  );
};

export default CredentialTypeCreate;
