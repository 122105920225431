import client from '../client';

export const FETCH_ADMIN_LIST = 'FETCH_ADMIN_LIST';
export function fetchAdminList() {
  return {
    type: FETCH_ADMIN_LIST,
    promise: client.get('users/strata-admin'),
  };
}

export const INVITE_ADMIN = 'INVITE_ADMIN';
export function inviteAdmin(payload) {
  return {
    type: INVITE_ADMIN,
    promise: client.post('users/strata-admin', payload),
  };
}

export const INVITE_ADMIN_RESET = 'INVITE_ADMIN_RESET';
export function clearNewAdmin() {
  return {
    type: INVITE_ADMIN_RESET,
  };
}

export const RESEND_ADMIN_INVITE = 'RESEND_ADMIN_INVITE';
export function resendAdminInvite(userId) {
  return {
    type: RESEND_ADMIN_INVITE,
    promise: client.post(`users/${userId}/resend-invite`),
  };
}
