module.exports = {
  artist: {
    singular: 'artist',
    plural: 'artists',
    label: {
      singular: 'Artist',
      plural: 'Artists',
    },
    subtypes: [],
    relationships: [],
    contacts: {
      singular: 'personnel',
      plural: 'personnel',
      label: {
        singular: 'Personnel',
        plural: 'Personnel',
      },
    },
  },
  food_vendor: {
    singular: 'food_vendor',
    plural: 'food_vendors',
    label: {
      singular: 'Food Vendor',
      plural: 'Food Vendors',
    },
    subtypes: ['BBC', 'BBQ'],
    relationships: [],
    contacts: {
      singular: 'staff',
      plural: 'staff',
      label: {
        singular: 'Staff',
        plural: 'Staff',
      },
    },
  },
  guestlist: {
    singular: 'guestlist',
    plural: 'guestlist',
    label: {
      singular: 'Guestlist',
      plural: 'Guestlist',
    },
    subtypes: [],
    relationships: [],
    contacts: {
      singular: 'guest',
      plural: 'guests',
      label: {
        singular: 'Guest',
        plural: 'Guests',
      },
    },
  },
  intern: {
    singular: 'intern',
    plural: 'interns',
    label: {
      singular: 'Intern',
      plural: 'Interns',
    },
    subtypes: [],
    relationships: [],
    contacts: {
      singular: 'intern',
      plural: 'interns',
      label: {
        singular: 'Intern',
        plural: 'Interns',
      },
    },
  },
  media: {
    singular: 'media',
    plural: 'media',
    label: {
      singular: 'Media',
      plural: 'Media',
    },
    subtypes: ['Blog', 'Magazine', 'Newspaper', 'Radio'],
    relationships: [],
    contacts: {
      singular: 'staff',
      plural: 'staff',
      label: {
        singular: 'Staff',
        plural: 'Staff',
      },
    },
  },
  production: {
    singular: 'production',
    plural: 'production',
    label: {
      singular: 'Production',
      plural: 'Production',
    },
    subtypes: [
      'Security / Emergency',
      'Internet / Network',
      'Catering',
      'Access Control',
    ],
    relationships: [],
    contacts: {
      singular: 'staff',
      plural: 'staff',
      label: {
        singular: 'Staff',
        plural: 'Staff',
      },
    },
  },
  sponsor: {
    singular: 'sponsor',
    plural: 'sponsors',
    label: {
      singular: 'Sponsor',
      plural: 'Sponsors',
    },
    subtypes: [],
    relationships: [],
    contacts: {
      singular: 'staff',
      plural: 'staff',
      label: {
        singular: 'Staff',
        plural: 'Staff',
      },
    },
  },
  vendor: {
    singular: 'vendor',
    plural: 'vendors',
    label: {
      singular: 'Vendor',
      plural: 'Vendors',
    },
    subtypes: ['Merch', 'Craft', 'Food', 'Production', 'Misc.'],
    relationships: [],
    contacts: {
      singular: 'staff',
      plural: 'staff',
      label: {
        singular: 'Staff',
        plural: 'Staff',
      },
    },
  },
};
