import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCredentialName } from '../credential-request/credential-request-helpers';
import { getMediaApplicationResponses } from '../media/media-actions';
import { getInternApplicationResponses } from '../intern/intern-actions';
import { getCurrentDepartment } from './department-selectors';
import LoadingIndicator from '../common/LoadingIndicator';
import NotFound from '../common/NotFound';
import Paper, { PaperHeader } from '../common/paper/Paper';
import DisplayFields from '../common/custom-forms/display-fields';

const DepartmentApplicationDisplay = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );
  const departmentId = department?.get('id');

  const contactList = useSelector(state =>
    department ? state.contact.contactList.getIn([departmentId, 'data']) : null,
  );

  const applicationName = department?.getIn(['application', 'name']);

  const responses = useSelector(state =>
    state[params.departmentType].applicationResponses.get('loaded')
      ? state[params.departmentType].applicationResponses
          .get('data')
          .sort(
            (a, b) =>
              a.getIn(['applicationSection', 'sort_order']) -
              b.getIn(['applicationSection', 'sort_order']),
          )
      : null,
  );

  useEffect(() => {
    if (!departmentId) return;
    if (params.departmentType === 'media')
      dispatch(getMediaApplicationResponses(departmentId));
    else dispatch(getInternApplicationResponses(departmentId));
  }, [departmentId, dispatch, params.departmentType]);

  const getResponse = key =>
    responses.find(
      response => response.getIn(['applicationSection', 'key']) === key,
    );

  const renderError = ({ error }, index) => (
    <dd key={index} style={{ color: 'red' }}>
      {error}
    </dd>
  );

  const renderCredential = ({ credentialId, credential }) => (
    <dd key={credentialId}>{credential}</dd>
  );

  const renderPerson = (person, index) => (
    <div key={index}>
      <div>
        <dt>Name</dt>
        <dd>
          {person.first_name} {person.last_name}
        </dd>
      </div>
      <div>
        <dt>
          Credentials
          {person.errors.length > 0 && (
            <>
              <span>
                {' '}
                / <span style={{ color: 'red' }}>Errors</span>
              </span>
            </>
          )}
        </dt>
        {person.credentials.map(renderCredential)}
        {person.errors.map(renderError)}
      </div>
    </div>
  );

  const renderCredentialRequest = credentialRequest => (
    <dd key={credentialRequest.get('id')}>
      {getCredentialName(credentialRequest.get('credential'))}
    </dd>
  );

  const renderContact = contact => (
    <div key={contact.get('id')}>
      <div>
        <dt>Name</dt>
        <dd>
          {contact.get('first_name')} {contact.get('last_name')}
        </dd>
      </div>
      <div>
        <dt>Credentials</dt>
        {contact.get('credentialRequests').map(renderCredentialRequest)}
      </div>
    </div>
  );

  const renderPersonnel = () => {
    const response = getResponse('personnel');
    const personnel = response
      ?.getIn(['content', 'fields', 'personnel'])
      ?.toJS();

    return (
      <Paper>
        <PaperHeader title="Personnel" />
        <div className="media-app-std-res">
          <div>
            <dl>
              {personnel
                ? personnel.map(renderPerson)
                : contactList.map(renderContact)}
            </dl>
          </div>
        </div>
      </Paper>
    );
  };

  const renderCustomResponse = response => (
    <Paper key={response.get('id')}>
      <PaperHeader title={response.getIn(['applicationSection', 'name'])} />
      <dl className="media-info__wrapper">
        {response.getIn(['content', 'fields']).map((field, index) => (
          <DisplayFields
            key={index}
            label={field.get('label')}
            type={field.get('type')}
            value={field.get('value')}
          />
        ))}
      </dl>
    </Paper>
  );

  const renderCustomResponses = () => {
    const customResponses = responses.filter(
      response => response.getIn(['applicationSection', 'key']) === null,
    );

    return customResponses.map(renderCustomResponse);
  };

  const renderApplicantInfo = () => {
    const response = getResponse('applicant-info');
    const applicantInfo = response?.getIn(['content', 'fields'])?.toJS();

    if (!applicantInfo) return null;

    return (
      <>
        <div>
          <dt>Submitter Name</dt>
          <dd>{applicantInfo.person_info_name}</dd>
        </div>
        <div>
          <dt>Submitter Email</dt>
          <dd>{applicantInfo.person_info_email}</dd>
        </div>
      </>
    );
  };

  const getMediaOutletName = () =>
    getResponse('media-outlet-name')?.getIn(['content', 'fields', 'name']) ??
    department.get('name');

  const getMediaOutletType = () =>
    getResponse('media-outlet-type')?.getIn(['content', 'fields', 'type']) ??
    department.get('subtype');

  const renderMediaResponse = () => (
    <div>
      <dl>
        <div>
          <dt>Media Outlet Name</dt>
          <dd>{getMediaOutletName()}</dd>
        </div>
        <div>
          <dt>Media Outlet Type</dt>
          <dd>{getMediaOutletType()}</dd>
        </div>
        {renderApplicantInfo()}
      </dl>
    </div>
  );

  const getInternName = () =>
    getResponse('intern-name')?.getIn(['content', 'fields', 'name']) ??
    department.get('name');

  const getInternEmail = () => {
    const personnel = getResponse('intern-name')
      ?.getIn(['content', 'fields', 'personnel'])
      ?.toJS();
    return personnel?.length > 0 ? personnel[0].email : department.get('name');
  };

  const renderInternResponse = () => (
    <div>
      <dl>
        <div>
          <dt>Applicant Name</dt>
          <dd>{getInternName()}</dd>
        </div>
        <div>
          <dt>Applicant Email</dt>
          <dd>{getInternEmail()}</dd>
        </div>
      </dl>
    </div>
  );

  const renderMediaOutletDetails = () => {
    const response = getResponse('media-outlet-details');
    const mediaOutletDetails = response?.getIn(['content', 'fields'])?.toJS();

    if (!mediaOutletDetails) return null;

    return (
      <Paper>
        <PaperHeader title="Media Outlet Details" />
        <div className="media-app-std-res">
          <div>
            <dl>
              <div>
                <dt>Publication Link / Website Outlet Type:</dt>
                <dd>{mediaOutletDetails.publicationLink}</dd>
              </div>
              <div>
                <dt>Unique Visitors Per Month:</dt>
                <dd>{mediaOutletDetails.uniqueVisitors}</dd>
              </div>
              <div>
                <dt>Link to Past Festival Coverage:</dt>
                <dd>{mediaOutletDetails.festivalCoverageLink}</dd>
              </div>
              <div>
                <dt>Social Media Links:</dt>
                <dd>{mediaOutletDetails.socialMediaLink}</dd>
              </div>
              <div>
                <dt>Has Been Granted Press Accreditation To Past Festivals?</dt>
                <dd>{mediaOutletDetails.hasPastAccreditation}</dd>
              </div>
              <div>
                <dt>Has Already Purchased Festival Passes?</dt>
                <dd>{mediaOutletDetails.hasPurchasedPasses}</dd>
              </div>
            </dl>
          </div>
        </div>
      </Paper>
    );
  };

  const renderEditorInfo = () => {
    const response = getResponse('editor-info');
    const editorInfo = response?.getIn(['content', 'fields'])?.toJS();

    if (!editorInfo) return null;

    return (
      <Paper>
        <PaperHeader title="Editor or Program Director Information" />
        <div className="media-app-std-res">
          <div>
            <dl>
              <div>
                <dt>Editor Full Name:</dt>
                <dd>{editorInfo.name}</dd>
              </div>
              <div>
                <dt>Editor Email Address:</dt>
                <dd>{editorInfo.email}</dd>
              </div>
              <div>
                <dt>Editor Phone Number:</dt>
                <dd>{editorInfo.phone}</dd>
              </div>
            </dl>
          </div>
        </div>
      </Paper>
    );
  };

  if (!department.get('application')) return <NotFound />;
  if (!contactList || !responses) return <LoadingIndicator />;

  return (
    <div className="media-app">
      <Paper>
        <PaperHeader title={applicationName} />
        <div className="media-app-std-res">
          {params.departmentType === 'media' && renderMediaResponse()}
          {params.departmentType === 'intern' && renderInternResponse()}
        </div>
      </Paper>
      {params.departmentType === 'media' && renderPersonnel()}
      {params.departmentType === 'media' && renderMediaOutletDetails()}
      {params.departmentType === 'media' && renderEditorInfo()}
      <div className="media-app-custom-res">{renderCustomResponses()}</div>
    </div>
  );
};

export default DepartmentApplicationDisplay;
