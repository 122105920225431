import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FORM_ERROR } from 'final-form';
import {
  getAdvanceApplicationCateringAvailability,
  getAdvanceApplicationDepartmentInfo,
  getAdvanceApplicationPublic,
  getAdvanceApplicationResponses,
  updateAdvanceApplicationPublic,
  updateAdvanceApplicationSectionPublic,
} from './advance-actions';
import { showNotification } from '../notification/notification-actions';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { getAdvanceApplicationValues } from '../common/application/application-selectors';
import { renderAdvanceApplicationPublicFormStatus } from '../common/application/application-helpers';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DEFAULT_TIMEZONE from '../lib/default-timezone';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import LegalNotice from '../common/application/LegalNotice';
import NotificationBanner from '../notification/NotificationBanner';
import ApplicationPublicForm from '../common/application/ApplicationPublicForm';
import {
  getCateringAvailability,
  getDepartmentInfo,
} from './advance-selectors';

const AdvanceApplicationPublicPage = ({ isPreview = false }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const application = useSelector(state =>
    state.advance.applicationPublic.get('loaded')
      ? state.advance.applicationPublic.get('data')
      : null,
  );

  const applicationId = application?.get('id');

  const event = application?.get('event');
  const eventId = event?.get('id');

  const applicationResponses = useSelector(state =>
    state.advance.applicationResponses.get('loaded')
      ? state.advance.applicationResponses.get('data')
      : null,
  );

  const departmentInfo = useSelector(getDepartmentInfo);

  const cateringAvailability = useSelector(getCateringAvailability);

  const initialValues = useMemo(() => {
    if (!departmentInfo || !cateringAvailability) return {};

    const primaryContact = departmentInfo
      .get('contacts')
      .find(contact => Boolean(contact.get('is_primary')));

    const initialValues = {
      department_info_name: departmentInfo.get('name'),
      department_info_contact_name: primaryContact
        ? `${primaryContact.get('first_name')} ${primaryContact.get(
            'last_name',
          )}`
        : '',
      department_info_contact_email: primaryContact?.get('email') ?? '',
      catering: {},
      credentials: {},
      credentialRequestsNotes: departmentInfo.getIn([
        'credentialRequestsNotes',
        'notes',
      ]),
    };

    departmentInfo.get('credentialRequests').forEach(request => {
      initialValues.credentials[`c_${request.get('credential_id')}`] =
        (initialValues.credentials[`c_${request.get('credential_id')}`] ?? 0) +
        request.get('quantity_pending') +
        request.get('quantity_approved');
    });

    cateringAvailability.get('periods').forEach(period => {
      period.get('mealTypes').forEach(mealType => {
        mealType.get('dates').forEach(mealTypeDate => {
          initialValues.catering[
            `mealCount_${mealTypeDate.get('mealTypeDateId')}`
          ] = mealTypeDate.get('mealCount');
        });
      });
    });

    return initialValues;
  }, [cateringAvailability, departmentInfo]);

  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(getAdvanceApplicationPublic(params.applicationToken)).then(
      action => {
        if (!action.response.ok) {
          setError(true);
          return;
        }

        if (params.departmentAdvanceLinkToken) {
          dispatch(
            getAdvanceApplicationDepartmentInfo(
              params.departmentAdvanceLinkToken,
            ),
          );
          dispatch(
            getAdvanceApplicationResponses(params.departmentAdvanceLinkToken),
          );
        }
      },
    );
  }, [dispatch, params.applicationToken, params.departmentAdvanceLinkToken]);

  useEffect(() => {
    if (applicationId)
      dispatch(
        getAdvanceApplicationCateringAvailability(
          eventId,
          applicationId,
          params.departmentAdvanceLinkToken,
        ),
      );
  }, [applicationId, dispatch, eventId, params.departmentAdvanceLinkToken]);

  useEffect(() => {
    if (!applicationResponses) return;
    applicationResponses.forEach(response => {
      const applicationSection = response.get('applicationSection');
      if (applicationSection.get('key')) return; // Only concerned with updating custom sections
      const sectionId = applicationSection.get('id');
      const content = response.get('content');
      if (content.get('fields').size > 0) {
        const schema = {
          name: content.get('name'),
          fields: content
            .get('fields')
            .map(field => Object.fromEntries(field))
            .toArray(),
        };
        schema.fields.forEach(ele =>
          ele.options ? (ele.options = ele.options.toArray()) : null,
        );
        dispatch(updateAdvanceApplicationSectionPublic(sectionId, schema));
      }
    });
  }, [applicationResponses, dispatch]);

  const handleSubmit = values => {
    const payload = getAdvanceApplicationValues(values, sections);
    if (params.departmentAdvanceLinkToken)
      payload.departmentAdvanceLinkToken = params.departmentAdvanceLinkToken;

    return dispatch(
      updateAdvanceApplicationPublic(params.applicationToken, payload),
    ).then(action => {
      setSubmitSucceeded(action.response.ok);
      if (!action.response.ok) {
        if (action.json.validationErrors) {
          const errors = formatValidationErrors({
            validationErrors: action.json.validationErrors.filter(
              ({ type }) => !type || type !== 'customFields',
            ),
          }).toJS();

          if (!!errors.personnel) {
            errors.personnel = { _error: errors.personnel[0] };
          }

          return errors;
        } else {
          dispatch(
            showNotification({
              status: 'error',
              message: `Error submitting Advance Application: ${action.json.message}`,
            }),
          );
          return { [FORM_ERROR]: action.json.message };
        }
      }
    });
  };

  if (error) {
    return <NotFound />;
  }

  if (!application) {
    return <LoadingIndicator />;
  }

  const sections = application.get('sections');
  const legalNotice = sections?.find(
    section => section.get('key') === 'legal-notice',
  );

  const timeZone = event ? event.get('time_zone') : DEFAULT_TIMEZONE;
  const closeDate = moment(
    params.applicationToken === application.get('override_link_token')
      ? application.get('override_close_date')
      : application.get('close_date'),
  ).tz(timeZone);
  const isClosed = moment().tz(timeZone).isAfter(closeDate);

  const title = `${application.get('name')}${
    isPreview ? ' - PREVIEW VERSION' : isClosed ? ' - IS CLOSED' : ''
  }`;

  if (!isPreview && !isClosed) {
    const applicationStatus = renderAdvanceApplicationPublicFormStatus(
      title,
      event,
      application,
      submitSucceeded,
    );
    if (applicationStatus) {
      return applicationStatus;
    }
  }

  const headerImageLocation = application.getIn(['headerImage', 'location']);

  const backgroundStyle = {
    display: 'flex',
    flexDirection: 'column',
  };

  if (application.get('backgroundImage')) {
    backgroundStyle.backgroundImage = `url(${application.getIn([
      'backgroundImage',
      'location',
    ])}`;
    backgroundStyle.backgroundRepeat = application.get('background_image_tiled')
      ? 'repeat'
      : 'no-repeat';
    if (!application.get('background_image_tiled')) {
      backgroundStyle.backgroundSize = 'cover';
    }
  }

  return (
    <div style={backgroundStyle}>
      <NotificationBanner />
      {application && (
        <h1 className="public-application__header">{event.get('name')}</h1>
      )}
      <div className="public-application__body">
        <section className="public-application__section public-application__section--textcenter">
          <header style={{ margin: '0px' }}>
            <div className="public-application__section--title">{title}</div>
            <div className="public-application__section--closedate">
              Due by {closeDate.format('MM/DD/YYYY hh:mmA z')}
            </div>
          </header>
        </section>

        {headerImageLocation && (
          <section className="public-application__section">
            <figure className="public-application__image">
              <img src={headerImageLocation} alt="Header" />
            </figure>
          </section>
        )}

        {legalNotice && !disclaimerAccepted ? (
          <LegalNotice
            legalNotice={legalNotice}
            onViewApplication={() => setDisclaimerAccepted(true)}
          />
        ) : (
          <ApplicationPublicForm
            event={event}
            application={application}
            applicationToken={params.applicationToken}
            initialValues={initialValues}
            disabled={isPreview || isClosed}
            onSubmit={handleSubmit}
            updateSection={(...args) =>
              dispatch(updateAdvanceApplicationSectionPublic(...args))
            }
          />
        )}
      </div>
    </div>
  );
};

AdvanceApplicationPublicPage.propTypes = {
  isPreview: PropTypes.bool,
};

export default AdvanceApplicationPublicPage;
