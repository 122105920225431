import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { getCurrentDepartment } from '../department/department-selectors';
import { getCateringAvailability } from './catering-actions';
import { fetchDepartmentMealTypes } from '../department/department-actions';

const DepartmentCateringLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const departmentId = useSelector(state =>
    getCurrentDepartment(state, { params })?.get('id'),
  );

  useEffect(() => {
    if (departmentId) {
      dispatch(fetchDepartmentMealTypes(departmentId));
      dispatch(getCateringAvailability(departmentId));
    }
  }, [departmentId, dispatch]);

  return <Outlet />;
};

export default DepartmentCateringLoader;
