import { forwardRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LoadingIndicator from './LoadingIndicator';

export const ButtonStyle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || '50px'};
  color: #fff;
  border-radius: 2px;
  font-size: 18px;
  padding: 10px;
  width: ${({ width }) => width || '150px'};
  > div {
    height: 100%;
  }
  > div > div > div {
    background-color: #fff;
  }
  ${({ margin }) => {
    if (margin === 'left') {
      return `
        margin-left: 10px;
      `;
    } else if (margin === 'right') {
      return `margin-right: 10px;`;
    }
    return ``;
  }}
  background-color: ${({ color, error, theme }) => {
    if (error) {
      return `
        ${theme.error}
      `;
    } else if (color === 'blue') {
      return `${theme.primary}`;
    } else if (color === 'gray') {
      return `${theme.gray}`;
    }
    return `${color}`;
  }};
  ${({ disabled }) => {
    if (disabled === true) {
      return `
        opacity: .4
      `;
    }
    return ``;
  }};
`;

const getChildren = ({ submitting, error, title }) => {
  if (error) {
    return 'Error';
  } else if (submitting) {
    return <LoadingIndicator />;
  }
  return title;
};

export const StyledButton = forwardRef(
  ({ handleClick, title, submitting, error, ...rest }, _ref) => (
    <ButtonStyle onClick={handleClick} error={error} {...rest}>
      {getChildren({ submitting, error, title })}
    </ButtonStyle>
  ),
);

StyledButton.propTypes = {
  handleClick: PropTypes.func,
  title: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  color: PropTypes.string,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};
