import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { getMealTypes } from './catering-actions';
import { getCurrentEvent } from '../event/event-selectors';

const MealTypeLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const event = useSelector(state => getCurrentEvent(state, { params }));

  useEffect(() => {
    if (event) dispatch(getMealTypes(event.get('id')));
  }, [dispatch, event]);

  return <Outlet />;
};

export default MealTypeLoader;
