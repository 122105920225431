import { createValidator } from 'revalidate';

export default createValidator(
  message => value => {
    if (value && !(value.indexOf(' ') >= 1)) {
      return message;
    }
  },
  field => `${field} must be First and Last Name`,
);
