import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dismissNotification } from './notification-actions';
import classNames from 'classnames';
import Icon from '../common/icons/Icon';

const NotificationBanner = () => {
  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification);
  const [isVisible, setIsVisible] = useState();
  const timeoutId = useRef(null);

  const closeBanner = useCallback(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;

      setIsVisible(false);

      setTimeout(() => {
        dispatch(dismissNotification());
      }, 1000); // 1 sec delay is for fade set on the CSS transition
    }
  }, [dispatch]);

  useEffect(() => {
    const startDismissTimer = (duration = 5000) => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }

      timeoutId.current = setTimeout(() => {
        closeBanner();
      }, duration);
    };

    if (notification.message !== '') {
      setIsVisible(true);
      startDismissTimer(notification.duration);
    }
  }, [closeBanner, notification]);

  const renderIcon = status => {
    switch (status) {
      case 'error':
        return <Icon icon="ErrorTriangle" />;
      case 'success':
        return <Icon icon="CheckMark" />;
      default:
        return <Icon icon="ErrorTriangle" />;
    }
  };

  const bannerClasses = {
    'notification-banner': true,
    'notification-banner--visible': isVisible,
    [`notification-banner--status-${notification.status}`]: notification.status,
  };

  return (
    <div className="notification-banner-container">
      <div className={classNames(bannerClasses)}>
        {renderIcon(notification.status)}
        <div className="notification-banner__message">
          {notification.message}
        </div>
        <button className="notification-banner__dismiss" onClick={closeBanner}>
          <Icon icon="Close" />
        </button>
      </div>
    </div>
  );
};

export default NotificationBanner;
