const apiRequestMiddleware = () => next => action => {
  if (!action.promise) {
    return next(action);
  }

  function makeAction(status, data) {
    const newAction = {
      ...action,
      ...{ type: `${action.type}_${status}` },
      ...data,
      originalType: action.type,
    };

    delete newAction.promise;
    return newAction;
  }

  next(makeAction('REQUEST'));
  return action.promise.then(
    response => {
      if (response.ok) {
        return response.json().then(
          json => next(makeAction('SUCCESS', { response, json })),
          () => next(makeAction('SUCCESS', { response })),
        );
      }

      return response.json().then(
        json => {
          if (json.validationErrors) {
            return next(makeAction('FAILURE', { response, json }));
          }
          const newAction = {
            ...action,
            ...{
              json,
              response,
              type: 'UNEXPECTED_ERROR',
            },
          };
          return next(newAction);
        },
        () =>
          next(
            makeAction('FAILURE', {
              response,
              json: { message: 'An unexpected error occurred' },
            }),
          ),
      );
    },
    error => {
      next({
        ...action,
        ...{
          type: 'UNEXPECTED_ERROR',
          error,
        },
      });
    },
  );
};

export default apiRequestMiddleware;
