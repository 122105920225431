import { useState } from 'react';
import { SingleDatePicker } from 'react-dates-gte-react-17';
import { ICON_AFTER_POSITION } from 'react-dates-gte-react-17/constants';
import PropTypes from 'prop-types';
import moment from 'moment';
import Icon from '../icons/Icon';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

const ReduxDatepickerField = ({
  allowPastDates = false,
  dateFormat = DEFAULT_DATE_FORMAT,
  dayChangeNotify,
  disabled = false,
  displayString,
  endDate,
  initialVisibleMonth = () => moment.utc(),
  onChange,
  showClearDate = false,
  startDate,
  value,
}) => {
  const [focused, setFocused] = useState(false);

  if (value === '') {
    value = null;
  } else if (typeof value === 'string') {
    value = moment(value);
  }

  return (
    <div
      className={
        showClearDate ? 'SideTimeDisplay WithClearButton' : 'SideTimeDisplay'
      }
    >
      <SingleDatePicker
        id="date_input"
        date={value}
        onDateChange={date => {
          onChange(date ? date.startOf('day') : date);
          if (dayChangeNotify) {
            dayChangeNotify(date ? date.startOf('day') : date);
          }
        }}
        focused={focused}
        onFocusChange={({ focused }) => {
          if (!disabled) {
            setFocused(focused);
          }
        }}
        noBorder
        withPortal
        customInputIcon={<Icon icon="Calendar" />}
        inputIconPosition={ICON_AFTER_POSITION}
        displayFormat={dateFormat}
        numberOfMonths={1}
        transitionDuration={0}
        initialVisibleMonth={initialVisibleMonth}
        showClearDate={showClearDate}
        isOutsideRange={day => {
          if (!allowPastDates && day.isBefore(startDate ?? moment(), 'day'))
            return true;
          if (endDate && day.isAfter(endDate, 'day')) return true;
          return false;
        }}
      />
      {displayString && <div className="timezone">{displayString}</div>}
    </div>
  );
};

ReduxDatepickerField.propTypes = {
  allowPastDates: PropTypes.bool,
  dateFormat: PropTypes.string,
  dayChangeNotify: PropTypes.func,
  disabled: PropTypes.bool,
  displayString: PropTypes.string,
  endDate: PropTypes.instanceOf(moment),
  initialVisibleMonth: PropTypes.func,
  onChange: PropTypes.func,
  showClearDate: PropTypes.bool,
  startDate: PropTypes.instanceOf(moment),
  value: PropTypes.any,
};

export default ReduxDatepickerField;
