import { StyledField, StyledSelect } from './PersonnelForm.styles';
import { isRequired } from 'revalidate';
import isValidPhone from '../../common/validate/is-valid-phone';
import isValidEmail from '../../common/validate/is-valid-email';
import ReduxFormsField from '../../common/forms/ReduxFormsFieldNoLabel';

export const EmailField = () => (
  <StyledField
    name="email"
    placeholder="example@example.com"
    label="Email"
    component={ReduxFormsField}
    validate={isValidEmail('Email')}
  >
    <input />
  </StyledField>
);

export const PhoneField = () => (
  <StyledField
    name="phone"
    placeholder="(555) 555-5555"
    label="Phone Number"
    component={ReduxFormsField}
    validate={isValidPhone('Phone')}
  >
    <input />
  </StyledField>
);

export const RoleField = ({ options }) => (
  <StyledField
    name="role"
    label="Role"
    placeholder="Select..."
    component={ReduxFormsField}
    required
    validate={isRequired('Role')}
  >
    <StyledSelect name="role" options={options} searchable={false} />
  </StyledField>
);

export const RoleText = () => (
  <StyledField
    name="other_role"
    placeholder="Role..."
    label="Role"
    component={ReduxFormsField}
    required
    validate={isRequired('Role')}
  >
    <input />
  </StyledField>
);
