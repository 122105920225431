import { combineReducers } from 'redux';
import {
  GET_CATERING_AVAILABILITY,
  GET_CATERING_LOCATIONS,
  GET_MEAL_TYPE_LIST,
} from './catering-actions';
import getApiReducer, { defaultState } from '../common/utils/getApiReducer';
import Immutable from 'immutable';

const mealTypeListReducer = (state = defaultState, action) => {
  let newState = getApiReducer(GET_MEAL_TYPE_LIST)(state, action);

  if (action.type === 'ADD_MEAL_TYPE_SUCCESS') {
    newState = newState.set(
      'data',
      newState.get('data').push(Immutable.fromJS(action.json)),
    );
  } else if (action.type === `UPDATE_MEAL_TYPE_SUCCESS`) {
    const index = newState
      .get('data')
      .findIndex(mealType => mealType.get('id') === Number(action.json.id));
    if (index !== -1) {
      newState = newState.set(
        'data',
        newState.get('data').set(index, Immutable.fromJS(action.json)),
      );
    }
  }

  if (action.type === 'UPDATE_MEAL_TYPE_SORT_ORDERS_REQUEST') {
    const reorderedList = [];
    action.payload.forEach(id => {
      const typeAtIndex = newState
        .get('data')
        .find(type => type.get('id') === id);
      if (typeAtIndex) {
        reorderedList.push(typeAtIndex);
      }
    });
    newState = newState.set('data', Immutable.fromJS(reorderedList));
  }

  return newState;
};

export default combineReducers({
  availability: getApiReducer(GET_CATERING_AVAILABILITY),
  locations: getApiReducer(GET_CATERING_LOCATIONS),
  mealTypeList: mealTypeListReducer,
});
