import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { fetchDepartment } from './department-actions';
import { getCurrentDepartment } from './department-selectors';
import LoadingIndicator from '../common/LoadingIndicator';

const DepartmentLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const departmentId = department?.get('id');
  const isLoaded = department?.has('credentialRequests');

  useEffect(() => {
    if (departmentId) dispatch(fetchDepartment(departmentId));
  }, [departmentId, dispatch]);

  if (!isLoaded) return <LoadingIndicator />;

  return <Outlet />;
};

export default DepartmentLoader;
