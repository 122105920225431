import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { submitFile, resetFileSubmit } from '../../../file/file-actions';
import translateErrors from '../translateErrors';
import PropTypes from 'prop-types';
import InputScaffoldNoLabel from '../../forms/InputScaffoldNoLabel';

const File = ({ field, onChange, uploadAction = submitFile }) => {
  const dispatch = useDispatch();

  const onRemove = () => {
    onChange(field.set('value', null));
    dispatch(resetFileSubmit());
  };

  const onDropAccepted = files => {
    const file = files[0];
    // TODO: Client-side validation on dropped file

    // Upload
    dispatch(uploadAction(file)).then(action => {
      if (!action.response.ok) {
        // TODO: API-side validation errors for this individual file upload input
      } else {
        // Success, call `onChange` with response file id
        // this.props.onChange(this.props.field.set('value', action.json));
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDropAccepted,
  });

  return (
    <InputScaffoldNoLabel
      label={field.get('label')}
      validation={translateErrors(field.getIn(['error', 'value']))}
      required={!!field.get('required')}
    >
      {field.get('value') ? (
        <div className="fileinput--with-values">
          <input type="text" value="Successfully uploaded!" disabled />
          <a
            className="button button--plain"
            href={field.getIn(['value', 'location'])}
            target="_blank"
            rel="noreferrer"
          >
            Download Preview
          </a>
          <button
            className="button button--red button--plain"
            onClick={onRemove}
          >
            Remove
          </button>
        </div>
      ) : (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <div className="dropzone-instructions">
            <div className="dropzone-instructions--main">
              Drag-n-drop or click to upload a file
            </div>
          </div>
        </div>
      )}
    </InputScaffoldNoLabel>
  );
};

File.propTypes = {
  uploadAction: PropTypes.func,
  onChange: PropTypes.func,
  field: PropTypes.object,
};

export default File;
