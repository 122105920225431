import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { Tooltip } from 'react-tooltip';
import { composeValidators, isRequired } from 'revalidate';
import { List as ImmutableList, Map as ImmutableMap } from 'immutable';
import { getPeriods } from './utils';
import { formatValidationErrors } from '../../common/utils/getApiReducer';
import { showNotification } from '../../notification/notification-actions';
import { getCredentialEndDate } from '../../common/utils/credentials/credential-utils';
import {
  getAvailableCredentialTypes,
  getGroupCredentialCategories,
} from '../group-credential-form-selectors';
import {
  createPublicGroupContact,
  editPublicGroupContact,
} from '../../contact/contact-actions';
import { EmailField, PhoneField, RoleField, RoleText } from './OptionalFields';
import {
  Container,
  Row,
  Close,
  StyledField,
  StyledCredentialSelect,
  DeleteUser,
  Footer,
  NameHeaders,
  CancelButton,
  CredentialsRow,
  FirstCredentialsItem,
  CredentialsItem,
  CredentialCategoryHeader,
  CredentialPeriodHeader,
  CredentialTypeHeader,
  Gutter,
} from './PersonnelForm.styles';
import PropTypes from 'prop-types';
import DEFAULT_TIMEZONE from '../../lib/default-timezone';
import moment from 'moment-timezone';
import isValidFullName from '../../common/validate/is-valid-full-name';
import phoneFilter from '../../common/filters/phone-filter';
import FormStatusButton from '../../common/FormStatusButton';
import Icon from '../../common/icons/Icon';
import ReduxFormsField from '../../common/forms/ReduxFormsField';
import DeleteContactModal from '../../common/DeleteContactModal';
import Credential, { CREDENTIAL_FORM_KEY } from './Credential';

const PersonnelForm = ({
  token,
  department,
  contact,
  requests,
  optionalFields,
  relationships,
  allowEdits,
  pulseTagsCount,
  closeDatePassed,
  handleClose,
  onDelete,
}) => {
  const dispatch = useDispatch();

  const groupCredentialCategories = getGroupCredentialCategories(requests);
  const availableCredentialTypes = getAvailableCredentialTypes({
    department,
    requests,
  });

  const timezone =
    department?.getIn(['event', 'time_zone']) ?? DEFAULT_TIMEZONE;
  const today = moment().tz(timezone);

  const roleOptions = relationships
    ? relationships
        .map(option => ({
          label: option,
          value: option,
        }))
        .sort((a, b) => b.value.localeCompare(a.value))
        .toJS()
    : [];

  const periods = useMemo(
    () => getPeriods(requests, contact),
    [contact, requests],
  );

  const [credentialTypes, setCredentialTypes] = useState(() => {
    const credentialTypes = {};
    contact?.get('credentialRequests').forEach(request => {
      const approvedQuantity = request.get('quantity_approved');
      if (approvedQuantity > 0) {
        const credentialType = request.getIn(['credential', 'credentialType']);
        const credentialCategory = credentialType.get('category');

        credentialTypes[credentialType.get('id')] = {
          id: credentialType.get('id'),
          name: credentialType.get('name'),
          categoryId: credentialCategory.get('id'),
          categoryName: credentialCategory.get('name'),
          issueFrequency: credentialType.get('issue_frequency'),
        };
      }
    });
    return new ImmutableMap(credentialTypes);
  });

  const initialValues = useMemo(() => {
    const initialValues = {
      full_name: contact
        ? `${contact.get('first_name')} ${contact.get('last_name')}`
        : '',
    };

    if (optionalFields.includes('email'))
      initialValues.email = contact?.get('email') ?? '';

    if (optionalFields.includes('phone'))
      initialValues.phone = contact?.get('phone') ?? '';

    if (optionalFields.includes('role'))
      initialValues.role = contact
        ? {
            label: contact?.get('relationship'),
            value: contact?.get('relationship'),
          }
        : null;

    if (optionalFields.includes('roleText'))
      initialValues.other_role = contact?.get('other_role') ?? '';

    const contactRequests = contact?.get('credentialRequests');
    contactRequests?.forEach(request => {
      initialValues[`${CREDENTIAL_FORM_KEY}${request.get('credential_id')}`] =
        request.get('quantity_approved');
    });

    return initialValues;
  }, [contact, optionalFields]);

  const filterCredentialTypes = categoryId => {
    let filteredCredentialTypes = availableCredentialTypes
      .filter(credentialType => {
        return (
          credentialType.get('category_id') === categoryId &&
          !credentialTypes.some(
            visibleCredentialType =>
              visibleCredentialType.id === credentialType.get('id'),
          )
        );
      })
      .sortBy(credentialType => credentialType.get('name'))
      .map(credentialType => {
        const value = credentialType.get('id');
        const label = credentialType.get('name');
        return {
          value,
          label,
        };
      })
      .toList()
      .toJS();

    return filteredCredentialTypes;
  };

  const handleAddCredential = (change, selected) => {
    const selectedCredentialTypeId = selected.value;

    const credentialType = availableCredentialTypes.find(
      credentialType => credentialType.get('id') === selectedCredentialTypeId,
    );

    if (!credentialType) {
      return;
    }
    const category = credentialType.get('category');

    requests
      .filter(request => {
        const credential = request.get('credential');
        const credentialTypeId = credential.getIn(['credentialType', 'id']);
        const credentialEndDate = moment.tz(
          moment.utc(getCredentialEndDate(credential)).format('YYYY-MM-DD'),
          timezone,
        );
        return (
          selectedCredentialTypeId === credentialTypeId &&
          today.isSameOrBefore(credentialEndDate, 'day') &&
          request.get('quantity_approved') > 0
        );
      })
      .forEach(request => {
        change(`${CREDENTIAL_FORM_KEY}${request.get('credential_id')}`, 1);
      });

    setCredentialTypes(prevCredentialTypes =>
      prevCredentialTypes.set(selectedCredentialTypeId, {
        id: credentialType.get('id'),
        name: credentialType.get('name'),
        categoryId: category.get('id'),
        categoryName: category.get('name'),
        issueFrequency: credentialType.get('issue_frequency'),
      }),
    );
  };

  const displayErrors = action => {
    const errors = action.json.errors;
    if (errors?.length) {
      const message = errors
        .map(error => error.error)
        .reduce((acc, error) => (error ? `${acc}${error}.\n` : acc), '');
      dispatch(showNotification({ message, duration: 60000, status: 'error' }));
    }
  };

  const splitFullName = full_name => {
    const namePieces = full_name.split(' ');

    return namePieces.length > 0
      ? {
          first_name: namePieces[0],
          last_name: namePieces.slice(1).join(' '),
        }
      : {
          first_name: null,
          last_name: null,
        };
  };

  const updatePayloadCredentials = (payload, key, value, initialValues) => {
    if (!key.startsWith(CREDENTIAL_FORM_KEY)) return;
    if (initialValues[key] && initialValues[key] === value) return;
    if (!initialValues[key] && !value) return;

    payload.credentials.push({
      id: parseInt(key.replace(CREDENTIAL_FORM_KEY, '')),
      quantity: value === 0 ? null : value,
    });
  };

  const updatePayloadPhone = (payload, key, value) => {
    if (key === 'phone')
      payload.phone = value?.trim() === '' ? null : phoneFilter(value);
  };

  const updatePayloadRole = (payload, key, value) => {
    if (key === 'role') payload.role = value.value;
  };

  const updatePayload = (payload, key, value) => {
    if (['email', 'other_role'].includes(key))
      payload[key] = value?.trim() === '' ? null : value.trim();
  };

  const getPayload = (initialValues, values) => {
    const { full_name, ...rest } = values;

    const payload = { credentials: [] };
    optionalFields.forEach(
      field => (payload[field === 'roleText' ? 'other_role' : field] = null),
    );

    const names = splitFullName(full_name);
    payload.first_name = names.first_name;
    payload.last_name = names.last_name;

    for (const [key, value] of Object.entries(rest)) {
      updatePayloadCredentials(payload, key, value, initialValues);
      updatePayloadPhone(payload, key, value);
      updatePayloadRole(payload, key, value);
      updatePayload(payload, key, value);
    }

    if (payload.credentials.length === 0) delete payload.credentials;

    return payload;
  };

  const getValidationErrors = action => {
    const validationErrors = formatValidationErrors(action.json).toJS();
    if (validationErrors.first_name || validationErrors.last_name) {
      validationErrors.full_name = [];
      if (validationErrors.first_name)
        validationErrors.full_name.push(
          `First Name - ${validationErrors.first_name.join(',')}. `,
        );
      if (validationErrors.last_name)
        validationErrors.full_name.push(
          `Last Name - ${validationErrors.last_name.join(',')}.`,
        );
    }
    return validationErrors;
  };

  const handleSubmit = (values, { getState }) => {
    const { initialValues } = getState();
    const departmentId = department.get('id');

    const payload = getPayload(initialValues, values);

    const promise = contact
      ? editPublicGroupContact(token, departmentId, contact.get('id'), payload)
      : createPublicGroupContact(token, departmentId, payload);

    return dispatch(promise).then(action => {
      if (action.response.ok) {
        displayErrors(action);
        handleClose();
      } else {
        return getValidationErrors(action);
      }
    });
  };

  const renderOptionalFields = () => {
    const fields = [];

    if (optionalFields.includes('email')) {
      fields.push(<EmailField key="email" />);
    }

    if (optionalFields.includes('phone')) {
      fields.push(<PhoneField key="phone" />);
    }

    if (optionalFields.includes('role')) {
      fields.push(<RoleField key="role" options={roleOptions} />);
    }

    if (optionalFields.includes('roleText')) {
      fields.push(<RoleText key="roleText" />);
    }

    return fields;
  };

  const renderCredentialTypesSelect = (change, categoryId) => {
    const options = filterCredentialTypes(categoryId);

    if (!options.length) {
      return null;
    }

    const maxLength = Math.max(...options.map(option => option.label.length));

    return (
      <StyledCredentialSelect
        name="credential_select"
        placeholder="Select..."
        options={options}
        onChange={selected => handleAddCredential(change, selected)}
        onBlur={() => {}}
        searchable={false}
        width={`${maxLength * 10}px`}
      />
    );
  };

  const renderCredentialRequestsForPeriod = (
    index,
    credentialType,
    period,
    requests,
    contact,
  ) => {
    const departmentRequests = requests.filter(request => {
      if (
        credentialType.id !==
        request.getIn(['credential', 'credentialType', 'id'])
      ) {
        return false;
      }

      const credential = request.get('credential');
      return credentialType.issueFrequency === 'ONE_TIME'
        ? credential
            .get('oneTimePeriods')
            .some(otp => period.id === otp.get('id'))
        : credential.get('period_id') === period.id;
    });

    let credentialRequests;
    if (contact) {
      credentialRequests = contact.get('credentialRequests').filter(request => {
        if (
          credentialType.id !==
          request.getIn(['credential', 'credentialType', 'id'])
        ) {
          return false;
        }

        const credential = request.get('credential');
        return credentialType.issueFrequency === 'ONE_TIME'
          ? credential
              .get('oneTimePeriods')
              .some(otp => period.id === otp.get('id'))
          : credential.get('period_id') === period.id;
      });
    }

    return (
      <CredentialsItem key={index}>
        <Credential
          department={department}
          contact={contact}
          departmentRequests={departmentRequests}
          credentialRequests={credentialRequests}
          issueFrequency={credentialType.issueFrequency}
        />
      </CredentialsItem>
    );
  };

  const renderCredentialRequests = (
    requests,
    contact,
    credentialTypes,
    periods,
  ) =>
    credentialTypes.valueSeq().map(credentialType => (
      <CredentialsRow key={`type-${credentialType.id}`}>
        <FirstCredentialsItem>
          <CredentialTypeHeader>{credentialType.name}</CredentialTypeHeader>
        </FirstCredentialsItem>
        {periods.map((period, index) =>
          renderCredentialRequestsForPeriod(
            index,
            credentialType,
            period,
            requests,
            contact,
          ),
        )}
      </CredentialsRow>
    ));

  const renderCredentialCategory = (
    change,
    category,
    requests,
    contact,
    periods,
  ) => {
    const categoryCredentialTypes = credentialTypes
      .filter(credentialType => credentialType.categoryId === category.value)
      .sortBy(credentialType => credentialType.name);

    if (!closeDatePassed || categoryCredentialTypes?.size > 0) {
      return (
        <div key={`category-${category.value}`}>
          <CredentialsRow>
            <CredentialCategoryHeader>
              <span
                className="credential-category-color"
                style={{ backgroundColor: `${category.color}` }}
              />
              <span>{category.label.toUpperCase()}</span>
            </CredentialCategoryHeader>
          </CredentialsRow>
          {renderCredentialRequests(
            requests,
            contact,
            categoryCredentialTypes,
            periods,
          )}
          {!closeDatePassed && (
            <CredentialsRow>
              <CredentialsItem>
                {renderCredentialTypesSelect(change, category.value)}
              </CredentialsItem>
            </CredentialsRow>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form: { change } }) => (
        <Container>
          <form onSubmit={handleSubmit}>
            {contact && (
              <Gutter>
                {pulseTagsCount > 0 && (
                  <div
                    className="credential-tooltip"
                    data-tooltip-id={`contact-issued-credential-tooltip-${contact.get(
                      'id',
                    )}`}
                  >
                    <Icon icon="IssuedCredential" />
                    <Tooltip
                      id={`contact-issued-credential-tooltip-${contact.get(
                        'id',
                      )}`}
                      content={`Issued: ${pulseTagsCount}`}
                    />
                  </div>
                )}
                {allowEdits && !closeDatePassed && (
                  <DeleteContactModal
                    contact={contact}
                    onDelete={onDelete}
                    trigger={
                      <div name="delete">
                        <Icon icon="Trash" />
                      </div>
                    }
                  />
                )}
                <Close
                  onClick={e => {
                    e.stopPropagation();
                    handleClose();
                  }}
                >
                  <div className="expandable-row__dropdown--expanded">
                    <Icon icon="Caret" />
                  </div>
                </Close>
              </Gutter>
            )}
            <div>
              {allowEdits ? (
                <Row>
                  <StyledField
                    name="full_name"
                    placeholder="Full Name"
                    label="Full Name"
                    component={ReduxFormsField}
                    required
                    validate={composeValidators(
                      isRequired,
                      isValidFullName,
                    )('Full Name')}
                  >
                    <input autoFocus={!contact} />
                  </StyledField>
                  {renderOptionalFields()}
                </Row>
              ) : (
                <div>
                  <NameHeaders>
                    {`${contact.get('first_name')} ${contact.get('last_name')}`}
                  </NameHeaders>
                </div>
              )}
            </div>
            <CredentialsRow>
              <FirstCredentialsItem></FirstCredentialsItem>
              {periods.map(period => (
                <CredentialsItem key={`period-${period.id}`}>
                  <CredentialPeriodHeader>
                    <p>{period.name}</p>
                    <p>({period.dateRange})</p>
                  </CredentialPeriodHeader>
                </CredentialsItem>
              ))}
            </CredentialsRow>
            {groupCredentialCategories.map(category =>
              renderCredentialCategory(
                change,
                category,
                requests,
                contact,
                periods,
              ),
            )}
            {!closeDatePassed && (
              <Footer>
                {contact && allowEdits && (
                  <DeleteContactModal
                    contact={contact}
                    onDelete={onDelete}
                    trigger={
                      <DeleteUser>
                        <div>
                          <Icon icon="Trash" />
                        </div>
                        Remove Personnel
                      </DeleteUser>
                    }
                  />
                )}
                {!contact && (
                  <CancelButton onClick={handleClose}>Cancel</CancelButton>
                )}
                <FormStatusButton />
              </Footer>
            )}
          </form>
        </Container>
      )}
    />
  );
};

PersonnelForm.propTypes = {
  token: PropTypes.string.isRequired,
  department: PropTypes.instanceOf(ImmutableMap).isRequired,
  contact: PropTypes.instanceOf(ImmutableMap),
  requests: PropTypes.instanceOf(ImmutableList).isRequired,
  optionalFields: PropTypes.instanceOf(ImmutableList),
  relationships: PropTypes.instanceOf(ImmutableList),
  allowEdits: PropTypes.bool.isRequired,
  pulseTagsCount: PropTypes.number,
  closeDatePassed: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default PersonnelForm;
