import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import KebabMenu from './KebabMenu';

const CardListItem = ({
  children,
  name,
  linkTo,
  image,
  subText,
  menuActive,
  onMenuToggle,
  labels,
}) => {
  const renderCardLabels = () => {
    if (!labels) {
      return null;
    }

    return (
      <div className="card__tags">
        {labels.map(label => (
          <span key={label.text} className={label.className}>
            {label.text}
          </span>
        ))}
      </div>
    );
  };

  const renderCardMenu = () => {
    if (!children) {
      return null;
    }

    return (
      <KebabMenu onMenuToggle={onMenuToggle} isMenuActive={menuActive}>
        {children}
      </KebabMenu>
    );
  };

  return (
    <div className="card">
      <Link to={linkTo} className="card__link" title={name}>
        {renderCardLabels()}
        <div className="card__logo">
          {image ? <img alt={`${name} logo`} src={image} /> : <div>{name}</div>}
        </div>
        <div className="card__content">
          <div className="card__content--name">{name}</div>
          {subText ? (
            <div className="card__content--subtitle">{subText}</div>
          ) : null}
        </div>
      </Link>
      {renderCardMenu()}
    </div>
  );
};

CardListItem.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  subText: PropTypes.string,
  menuActive: PropTypes.bool,
  onMenuToggle: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      className: PropTypes.string,
    }),
  ),
};

export default CardListItem;
