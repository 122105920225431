import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';

const RequestStatusIndicator = ({ status, leftContent }) => {
  let leftColor, rightColor, rightContent;
  switch (status) {
    case 'pending':
      leftColor = '#B78B43';
      rightColor = '#DEA74B';
      rightContent = <Icon icon="Hourglass" />;
      break;
    case 'approved':
      leftColor = '#2AA961';
      rightColor = '#2ECC71';
      rightContent = <Icon icon="CheckMark" />;
      break;
    case 'rejected':
      leftColor = '#BE4237';
      rightColor = '#E74C3C';
      rightContent = <Icon icon="Close" />;
      break;
    case 'zero':
      leftColor = '#5A6879';
      rightColor = '#CCC';
      rightContent = <Icon icon="Kebab" />;
      break;
    default:
      throw new Error(`Unknown status: ${status}`);
  }

  return (
    <div className="status-indicator">
      {leftContent == null ? null : (
        <span
          className="status-indicator__qty"
          style={{ backgroundColor: leftColor, cursor: 'default' }}
        >
          {leftContent}
        </span>
      )}
      <span
        className="status-indicator__icon"
        style={{ backgroundColor: rightColor }}
      >
        {rightContent}
      </span>
    </div>
  );
};

RequestStatusIndicator.propTypes = {
  status: PropTypes.oneOf(['pending', 'approved', 'rejected', 'zero']),
  leftContent: PropTypes.node,
};

export default RequestStatusIndicator;
