import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentEvent,
  getEmailTemplatesLoaded,
  getEmailTypesLoaded,
} from '../event/event-selectors';
import { fetchEmailTemplates, fetchEmailTypes } from '../event/event-actions';
import LoadingIndicator from '../common/LoadingIndicator';

const EmailSettingsLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const event = useSelector(state => getCurrentEvent(state, { params }));
  const emailTypesLoaded = useSelector(getEmailTypesLoaded);
  const emailTemplatesLoaded = useSelector(state =>
    getEmailTemplatesLoaded(state, { params }),
  );

  const loaded = emailTypesLoaded && emailTemplatesLoaded;

  useEffect(() => {
    dispatch(fetchEmailTypes());
  }, [dispatch]);

  useEffect(() => {
    if (event) {
      dispatch(fetchEmailTemplates(event.get('id')));
    }
  }, [dispatch, event]);

  return loaded ? <Outlet /> : <LoadingIndicator />;
};

export default EmailSettingsLoader;
