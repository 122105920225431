import { Navigate, Route } from 'react-router-dom';

import CheckEvent from './onsite/CheckEvent';
import ContactListLoader from './contact/ContactListLoader';
import DepartmentListLoader from './department/DepartmentListLoader';
import DepartmentLoader from './department/DepartmentLoader';
import FestivalLoader from './festival/FestivalLoader';
import OnsiteLayout from './onsite/OnsiteLayout';
import OnsiteRequestForm from './onsite/OnsiteRequestForm';
import PromoterLoader from './promoter/PromoterLoader';
import SelectDepartment from './onsite/SelectDepartment';
import SelectEvent from './onsite/SelectEvent';
import SelectPromoter from './onsite/SelectPromoter';

export default (
  <Route
    path="/onsite"
    element={<OnsiteLayout />}
    handle={{ redirectTarget: '/onsite' }}
  >
    <Route index element={<Navigate to="promoters" replace />} />
    <Route path="promoters" element={<PromoterLoader />}>
      <Route index element={<Navigate to="~select" replace />} />
      <Route path="~select" element={<SelectPromoter />} />
      <Route path=":promoterSlug" element={<FestivalLoader />}>
        <Route index element={<Navigate to="festivals" replace />} />
        <Route path="festivals">
          <Route index element={<Navigate to="~select" replace />} />
          <Route path="~select" element={<SelectEvent />} />
          <Route
            path=":festivalSlug/events/:eventSlug"
            element={<CheckEvent />}
          >
            <Route index element={<Navigate to="~select" replace />} />
            <Route path="~select" element={<SelectDepartment />} />
            <Route path=":departmentType" element={<DepartmentListLoader />}>
              <Route index element={<Navigate to="~select" replace />} />
              <Route path="~select" element={<SelectDepartment />} />
              <Route path=":departmentSlug" element={<DepartmentLoader />}>
                <Route index element={<Navigate to="~request" replace />} />
                <Route element={<ContactListLoader noUploads />}>
                  <Route path="~request" element={<OnsiteRequestForm />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  </Route>
);
