import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { useACL } from '../common/ACL';
import { resourceConstants } from '../common/utils/acl';
import { fetchOptions } from './reports-actions';
import { getReportOptions } from './reports-selectors';
import EventReportsConfig from './EventReportsConfig';
import DepartmentReportsConfig from './DepartmentReportsConfig';

const ReportsConfig = {
  [resourceConstants.EVENT]: EventReportsConfig,
  [resourceConstants.DEPARTMENT]: DepartmentReportsConfig,
};

export const useReports = (resource, resourceId) => {
  const reportsConfig = ReportsConfig[resource];
  const acl = useACL(reportsConfig?.aclRules ?? []);
  const dispatch = useDispatch();

  const options = useSelector(state =>
    getReportOptions(state, resource, resourceId),
  );

  const [reports, setReports] = useState([]);

  useEffect(() => {
    if (resource && !options) dispatch(fetchOptions(resource, resourceId));
  }, [dispatch, options, resource, resourceId]);

  useEffect(() => {
    if (!options || Object.keys(acl).length === 0) return;

    const clonedReports = cloneDeep(
      reportsConfig.reports.filter(report => acl[report.report_slug]),
    );

    for (const report of clonedReports) {
      Object.entries(report.fields).forEach(([key, value]) => {
        value.options = options[key] ?? value.options ?? [];
      });
    }

    setReports(clonedReports);
  }, [acl, dispatch, options, reportsConfig?.reports, resource, resourceId]);

  return reports;
};
