import { Map } from 'immutable';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import PropTypes from 'prop-types';
import ReduxFormsField from '../../forms/ReduxFormsField';
import FormSection from '../../forms/FormSection';
import FormField from '../../forms/FormField';
import CheckboxBoolean from '../../forms/CheckboxBoolean';
import isTrue from '../../validate/is-true';

const draftRenderer = new Renderer(defaultBlocks);

const VerificationSection = ({ className, section }) => (
  <section className={className}>
    <header>
      <div className={`${className}--title`}>
        Verification and Authorization
      </div>
    </header>
    <article>
      <div className={`${className}--desc`}>
        <div
          className="draftjs-output"
          dangerouslySetInnerHTML={{
            __html: draftRenderer.convertRawToHTML(
              section.get('content').toJS(),
            ),
          }}
        />
      </div>
      <FormSection name="verification">
        <FormField
          name="verified"
          component={ReduxFormsField}
          required
          validate={isTrue('Verified')}
        >
          <CheckboxBoolean label="I have verified that my contact details are correct." />
        </FormField>
        <FormField
          name="authorized"
          component={ReduxFormsField}
          required
          validate={isTrue('Authorized')}
        >
          <CheckboxBoolean label="I have completed this application and am authorized to supply all the information contained here within." />
        </FormField>
      </FormSection>
    </article>
  </section>
);

VerificationSection.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
};

export default VerificationSection;
