import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { uniq } from 'lodash';
import { resource, useACL } from '../common/ACL';
import {
  getCurrentDepartment,
  getDepartmentSettings,
} from '../department/department-selectors';
import { getStaffContactList } from './contact-selectors';
import {
  formatDate,
  getPeriodDateRange,
} from '../common/utils/credentials/credential-utils';
import { fetchDownload } from '../reports/reports-utils';
import { DepartmentUnsavedCredentialsProvider } from './DepartmentUnsavedCredentials';
import styled from 'styled-components';
import LoadingIndicator from '../common/LoadingIndicator';
import Icon from '../common/icons/Icon';
import ContactList from './ContactList';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const aclRules = {
  canDownloadCredentialReport: [
    resource.DEPARTMENT,
    ['view-reports-all', 'view-reports-credential'],
  ],
  canDownloadCateringReport: [
    resource.DEPARTMENT,
    ['view-reports-all', 'view-reports-catering'],
  ],
};

const ContactListPage = () => {
  const acl = useACL(aclRules);
  const params = useParams();

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const deptSettings = useSelector(state =>
    getDepartmentSettings(state, { params }),
  );

  const contactList = useSelector(state =>
    getStaffContactList(state, { params }),
  );

  const maximumRequestableMessages = useMemo(() => {
    const getPeriods = credentialType => {
      const credentialTypes = [credentialType];
      const periods = new Map();
      credentialTypes.forEach(credentialType => {
        const issueFrequency = credentialType.get('issue_frequency');
        const credentials = credentialType.get('credentials');
        credentials.forEach(credential => {
          if (issueFrequency === 'ONE_TIME') {
            const oneTimePeriods = credential.get('oneTimePeriods');
            oneTimePeriods.forEach(period => {
              const periodId = period.get('id');

              if (!periods.has(periodId)) {
                periods.set(periodId, {
                  id: periodId,
                  name: period.get('name'),
                  startDate: period.get('start_date'),
                  endDate: period.get('end_date'),
                  dateRange: getPeriodDateRange(period),
                });
              }
            });
          } else {
            const period = credential.get('period');
            const periodId = period.get('id');

            if (!periods.has(periodId)) {
              periods.set(periodId, {
                id: periodId,
                name: period.get('name'),
                startDate: period.get('start_date'),
                endDate: period.get('end_date'),
                dateRange: getPeriodDateRange(period),
              });
            }
          }
        });
      });

      return Array.from(periods.values()).sort((period1, period2) =>
        period1.startDate <= period2.startDate ? -1 : 1,
      );
    };

    const getMaximumRequestableDisplayStrings = () => {
      const requestMaximums = department
        .get('requestMaximums')
        .filter(
          rm =>
            rm.get('_pivot_maximum_requestable') !== null &&
            department
              .get('credentialTypeSettings')
              .some(
                cts =>
                  cts.get('credential_type_id') ===
                  rm.get('credential_type_id'),
              ),
        )
        .sortBy(rm => rm.get('_pivot_credential_id'))
        .toJS();

      const credentialTypeIds = uniq(
        requestMaximums.map(rm => rm.credential_type_id),
      );
      const credentialTypeMessages = [];
      credentialTypeIds.forEach(ctid => {
        const credentialType = department
          .getIn(['credentialTypes'])
          .find(ct => ct.get('id') === ctid);
        if (!credentialType) return;
        const credentialTypeMessage = {
          name: credentialType.get('name'),
          content: '',
        };
        if (credentialType.get('issue_frequency') === 'ONE_TIME') {
          credentialTypeMessage.content = requestMaximums
            .find(rm => rm.credential_type_id === credentialType.get('id'))
            ._pivot_maximum_requestable.toString();
        } else if (credentialType.get('issue_frequency') === 'DAILY') {
          const credTypeRequestMaximums = requestMaximums.filter(
            rm => rm.credential_type_id === credentialType.get('id'),
          );
          credTypeRequestMaximums.forEach(ctrm => {
            const credential = credentialType
              .getIn(['credentials'])
              .find(c => c.get('id') === ctrm._pivot_credential_id);
            credentialTypeMessage.content +=
              ctrm._pivot_maximum_requestable.toString() +
              ' for ' +
              formatDate(credential.getIn(['date', 'date'])) +
              ', ';
          });
        } else {
          const periods = getPeriods(credentialType);
          periods.forEach(period => {
            const requestMaximum = requestMaximums.find(
              rm =>
                rm.credential_type_id === credentialType.get('id') &&
                rm.period_id === period.id,
            );
            if (requestMaximum) {
              credentialTypeMessage.content +=
                requestMaximum._pivot_maximum_requestable.toString() +
                ' for ' +
                period.name +
                ', ';
            }
          });
        }
        credentialTypeMessage.content = credentialTypeMessage.content.replace(
          /,\s*$/,
          '',
        );
        credentialTypeMessages.push(credentialTypeMessage);
      });

      return credentialTypeMessages;
    };

    return getMaximumRequestableDisplayStrings();
  }, [department]);

  const downloadReport = report_slug => {
    const resource = department.get('resource_id');
    const resourceId = department.get('id');
    const resourceSlug = department.get('slug');

    const payload = {
      report_type: report_slug,
      mime: 'text/csv',
      parameters: {},
    };

    fetchDownload(resource, resourceId, resourceSlug, payload);
  };

  if (!department || !contactList) {
    return <LoadingIndicator />;
  }

  return (
    <div>
      <Header>
        <div>
          {maximumRequestableMessages.length > 0 && (
            <div style={{ marginRight: 'auto' }}>
              <div style={{ fontWeight: 'bold' }}>
                You have a maximum requestable limit for the following
                credentials:
              </div>
              <br></br>
              {maximumRequestableMessages.map((crt, j) => (
                <div key={j} style={{ display: 'flex' }}>
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginRight: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {crt.name} :{' '}
                  </div>
                  <div>{crt.content}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div>
          {acl.canDownloadCateringReport &&
            !['intern', 'media'].includes(params.departmentType) && (
              <button
                className="button--secondary button--plain button--secondary--icon"
                style={{
                  marginLeft: '4px',
                  backgroundColor: 'rgb(204,204,204)',
                  color: '#5a6879',
                  alignSelf: 'flex-end',
                }}
                onClick={() => {
                  downloadReport('catering_daily_meal_summary');
                }}
                type="button"
              >
                <Icon icon="DownloadReport" />
                <span>Catering Report</span>
              </button>
            )}
          {acl.canDownloadCredentialReport && (
            <button
              className="button--secondary button--plain button--secondary--icon"
              style={{
                marginLeft: '4px',
                backgroundColor: 'rgb(204,204,204)',
                color: '#5a6879',
                alignSelf: 'flex-end',
              }}
              onClick={() => {
                downloadReport('credentials_by_recipient');
              }}
              type="button"
            >
              <Icon icon="DownloadReport" />
              <span>Credentials Report</span>
            </button>
          )}
        </div>
      </Header>

      <DepartmentUnsavedCredentialsProvider>
        <ContactList
          department={department}
          deptSettings={deptSettings}
          contactList={contactList}
        />
      </DepartmentUnsavedCredentialsProvider>
    </div>
  );
};

export default ContactListPage;
