import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { OrderedMap, fromJS } from 'immutable';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from '../department/department-selectors';
import { fetchAllEmails, sendAdvanceFormEmail } from './advance-form-actions';
import { getEmailsByRecipient } from './advance-form-selectors';
import { getAdvanceApplicationEmailTemplates } from '../advance-application/advance-actions';
import LoadingIndicator from '../common/LoadingIndicator';
import EmailHistory from '../email/EmailHistory';
import SendLinkEmail from '../email/SendLinkEmail';

const SendAdvanceFormLinkEmail = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );
  const departmentId = department?.get('id');

  const [doFetchAllEmails, setDoFetchAllEmails] = useState(true);
  const [emailTemplates, setEmailTemplates] = useState(null);
  const [emailHistory, setEmailHistory] = useState(new OrderedMap());

  useEffect(() => {
    if (doFetchAllEmails)
      fetchAllEmails(departmentId).then(response => {
        setDoFetchAllEmails(false);
        response.json().then(emails => {
          const emailMap = getEmailsByRecipient(fromJS(emails));
          setEmailHistory(emailMap);
        });
      });
  }, [departmentId, doFetchAllEmails]);

  useEffect(() => {
    if (event && department) {
      dispatch(
        getAdvanceApplicationEmailTemplates(
          event.get('id'),
          department.get('type'),
        ),
      ).then(action => {
        if (action.response.ok) {
          setEmailTemplates(action.json);
        }
      });
    }
  }, [department, dispatch, event]);

  const sendEmail = payload => sendAdvanceFormEmail(departmentId, payload);

  const onEmailSent = () => {
    setDoFetchAllEmails(true);
  };

  return !emailTemplates ? (
    <LoadingIndicator />
  ) : (
    <>
      <SendLinkEmail
        title="Send Advance Form"
        emailTemplates={emailTemplates}
        sendEmail={sendEmail}
        onEmailSent={onEmailSent}
      />
      {!emailHistory?.isEmpty() && <EmailHistory emails={emailHistory} />}
    </>
  );
};

export default SendAdvanceFormLinkEmail;
