import client, { publicClient } from '../client';

export const CREATE_PUBLIC_GROUP_CONTACT = 'CREATE_PUBLIC_GROUP_CONTACT';
export function createPublicGroupContact(token, departmentId, contactValues) {
  return {
    departmentId,
    contactValues,
    type: CREATE_PUBLIC_GROUP_CONTACT,
    promise: publicClient.post(
      `public/departments/${departmentId}/group-contacts?token=${token}`,
      contactValues,
    ),
  };
}

export const EDIT_PUBLIC_GROUP_CONTACT = 'EDIT_PUBLIC_GROUP_CONTACT';
export function editPublicGroupContact(
  token,
  departmentId,
  contactId,
  contactValues,
) {
  return {
    contactId,
    contactValues,
    type: EDIT_PUBLIC_GROUP_CONTACT,
    promise: publicClient.patch(
      `public/departments/${departmentId}/group-contacts/${contactId}?token=${token}`,
      contactValues,
    ),
  };
}

export const DELETE_PUBLIC_GROUP_CONTACT = 'DELETE_PUBLIC_GROUP_CONTACT';
export function deletePublicGroupContact(token, departmentId, contactId) {
  return {
    contactId,
    departmentId,
    type: DELETE_PUBLIC_GROUP_CONTACT,
    promise: publicClient.delete(
      `public/department/${departmentId}/group-contacts/${contactId}?token=${token}`,
    ),
  };
}

export const SUBMIT_CONTACT = 'SUBMIT_CONTACT';
export function submitContact(departmentId, contactValues) {
  const values = { ...contactValues };
  if (typeof values.is_guest === 'undefined') {
    values.is_guest = 0;
  }
  return {
    departmentId,
    type: SUBMIT_CONTACT,
    promise: contactValues.id
      ? client.put(
          `departments/${departmentId}/contacts/${contactValues.id}`,
          values,
        )
      : client.post(`departments/${departmentId}/contacts`, values),
  };
}

export const SUBMIT_PLACEHOLDER_CONTACTS = 'SUBMIT_PLACEHOLDER_CONTACTS';
export function submitPlaceholderContacts(departmentId, values) {
  return {
    type: SUBMIT_PLACEHOLDER_CONTACTS,
    departmentId,
    promise: client.post(
      `department/${departmentId}/contacts/placeholders`,
      values,
    ),
  };
}

export const FETCH_CONTACT_LIST = 'FETCH_CONTACT_LIST';
export function fetchContactList(departmentId) {
  return {
    type: FETCH_CONTACT_LIST,
    promise: client.get(`departments/${departmentId}/contacts`),
    departmentId,
  };
}

export const EDIT_CONTACT = 'EDIT_CONTACT';
export function editContact(contactId, values) {
  return {
    type: EDIT_CONTACT,
    promise: client.patch(`contacts/${contactId}`, values),
  };
}

export const DELETE_CONTACT = 'DELETE_CONTACT';
export function deleteContact(departmentId, contactId) {
  return {
    departmentId,
    contactId,
    type: DELETE_CONTACT,
    promise: client.delete(`contacts/${contactId}`),
  };
}

export function getContactsUploadTemplate(departmentId) {
  return client.get(`departments/${departmentId}/contacts/uploads/template`);
}

export const UPLOAD_CONTACTS = 'UPLOAD_CONTACTS';
export function uploadContacts(departmentId, payload) {
  return {
    type: UPLOAD_CONTACTS,
    departmentId,
    promise: client.post(
      `departments/${departmentId}/contacts/uploads`,
      payload,
    ),
  };
}

export const POLL_CONTACT_UPLOADS = 'POLL_CONTACT_UPLOADS';
export function pollContactUploads(departmentId) {
  return {
    type: POLL_CONTACT_UPLOADS,
    departmentId,
  };
}

export const FILE_UPLOAD_TYPE = 'file';
export const PLACEHOLDER_UPLOAD_TYPE = 'placeholder';
export const FETCH_CONTACT_UPLOADS = 'FETCH_CONTACT_UPLOADS';
export function fetchContactUploads(departmentId) {
  return {
    type: FETCH_CONTACT_UPLOADS,
    promise: client.get(`departments/${departmentId}/contacts/uploads`),
    departmentId,
  };
}
