import PropTypes from 'prop-types';

const TextDisplay = ({ label, value }) => (
  <div>
    <dt>{label}:</dt>
    <dd>{value}</dd>
  </div>
);

TextDisplay.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TextDisplay;
