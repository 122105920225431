import Immutable, { Map } from 'immutable';
import moment from 'moment';

const isLocked = function (credential, department) {
  if (
    // Make it work whether or not department has been toJS'd
    (department.get && department.get('require_approval_pre_assignment')) ||
    department.required_approval_pre_assigned
  ) {
    return true;
  }

  const lockDate = credential.credentialType.lock_date
    ? new Date(credential.credentialType.lock_date)
    : false;
  const now = new Date();

  return lockDate && lockDate < now;
};

export function hasAvailableCredentials(department) {
  if (!department.get('require_approval_pre_assignment')) {
    return department.get('credentialTypes').size > 0;
  }

  let hasAvailableCredentials = false;

  department.get('credentialRequests').forEach(credReq => {
    if (
      credReq.get('requested_for') === null &&
      credReq.get('quantity_approved') > 0
    ) {
      hasAvailableCredentials = true;
    }
  });
  return hasAvailableCredentials;
}

export const sortPeriods = periods =>
  periods.sort((a, b) => moment.utc(a.start_date) - moment.utc(b.start_date));

export function sortCredentialsOfTheSameType(a, b) {
  if (a.date_id) {
    return moment.utc(a.date.date) - moment.utc(b.date.date);
  } else if (a.period) {
    return moment.utc(a.period.start_date) - moment.utc(b.period.start_date);
  }
  return moment.utc(
    sortPeriods(a.oneTimePeriods)[0].start_date,
    sortPeriods(b.oneTimePeriods)[0].start_date,
  );
}

export const getDateRangesForOneTimeCredential = cred => {
  const dateRanges = [];
  const sortedPeriods = cred.oneTimePeriods.sort(
    (a, b) => moment.utc(a.start_date) - moment.utc(b.start_date),
  );
  sortedPeriods.forEach(period => {
    if (dateRanges.length) {
      const prevDateRange = dateRanges[dateRanges.length - 1];
      const prevEndDate = prevDateRange.end;
      const nextStartDate = period.start_date;
      const gapBetweenDates = moment
        .utc(nextStartDate)
        .diff(moment.utc(prevEndDate), 'days');
      if (gapBetweenDates === 1) {
        prevDateRange.end = period.end_date;
      } else {
        dateRanges.push({ start: period.start_date, end: period.end_date });
      }
    } else {
      dateRanges.push({ start: period.start_date, end: period.end_date });
    }
  });
  return dateRanges;
};

export function getCredentialName(credential) {
  const cred = credential instanceof Map ? credential.toJS() : credential;
  let dayOrDayRange;
  if (cred.date_id) {
    dayOrDayRange = moment.utc(cred.date.date).format('ddd');
  } else if (cred.period_id) {
    dayOrDayRange =
      cred.period.start_date === cred.period.end_date
        ? moment.utc(cred.period.start_date).format('ddd')
        : `${moment.utc(cred.period.start_date).format('ddd')} - ${moment
            .utc(cred.period.end_date)
            .format('ddd')}`;
  } else {
    const dateRanges = getDateRangesForOneTimeCredential(cred);

    const dateRangeStrings = [];
    dateRanges.forEach(dateRange => {
      dateRangeStrings.push(
        `${moment.utc(dateRange.start).format('ddd')}-${moment
          .utc(dateRange.end)
          .format('ddd')}`,
      );
    });
    dayOrDayRange = dateRangeStrings.join(', ');
  }
  return `${cred.credentialType.name} ${dayOrDayRange}`;
}

export function calculateQuantityAssignedAndAvailable(credentials, department) {
  const credsWithQuantities = credentials
    .toJS()
    .filter(
      credential =>
        // Filter out unrequested creds if department requires pre approval
        credential.requests.length ||
        !department.get('require_approval_pre_assignment'),
    )
    .map(credential => {
      let quantity_available = 0;
      let quantity_assigned = 0;
      credential.requests.forEach(request => {
        if (request.requested_for) {
          quantity_assigned += request.quantity_approved;
          if (!isLocked(credential, department)) {
            quantity_assigned += request.quantity_pending;
          }
        } else {
          quantity_available += request.quantity_approved;
          if (!isLocked(credential, department)) {
            quantity_available += request.quantity_pending;
          }
        }
      });
      return Object.assign(credential, {
        quantity_available,
        quantity_assigned,
      });
    });
  return Immutable.fromJS(credsWithQuantities);
}

export function groupRequestsByCredential(requests) {
  const requestList = [];
  requests.toJS().forEach(request => {
    const quantityName = request.requested_for
      ? 'quantity_assigned'
      : 'quantity_available';
    const existingReq = requestList.find(
      reqInList => reqInList.credential.id === request.credential.id,
    );

    const getQuantityAvailableOrAssigned = request =>
      isLocked(request.credential, request.department)
        ? request.quantity_approved
        : request.quantity_pending + request.quantity_approved;

    if (existingReq) {
      existingReq[quantityName] = getQuantityAvailableOrAssigned(request);
      existingReq.quantity_pending += request.quantity_pending;
      existingReq.quantity_approved += request.quantity_approved;
      existingReq.quantity_rejected += request.quantity_rejected;
    } else {
      request[quantityName] = getQuantityAvailableOrAssigned(request);
      delete request.id;
      requestList.push(request);
    }
  });
  return Immutable.fromJS(requestList);
}

const DAY_FORMAT = 'ddd DD';

export function getDayRange(dates) {
  if (dates.length === 1 || dates[0] === dates[dates.length - 1]) {
    return moment.utc(dates[0]).format(DAY_FORMAT);
  }
  return `${moment.utc(dates[0]).format(DAY_FORMAT)} - ${moment
    .utc(dates[dates.length - 1])
    .format(DAY_FORMAT)}`;
}

export function formatDaysForCredential(credential) {
  if (credential.date_id) {
    return moment.utc(credential.date.date).format(DAY_FORMAT);
  } else if (credential.period_id) {
    return getDayRange(credential.period.dates.map(date => date.date));
  }
  const dateRanges = getDateRangesForOneTimeCredential(credential);
  const dateRangeStrings = [];
  dateRanges.forEach(dateRange => {
    dateRangeStrings.push(
      `${moment.utc(dateRange.start).format('ddd DD')} - ${moment
        .utc(dateRange.end)
        .format('ddd DD')}`,
    );
  });
  return dateRangeStrings.join(', ');
}

export function getSortableDateForCredential(credential) {
  let date;
  const cred = credential instanceof Map ? credential.toJS() : credential;
  if (cred.date_id) {
    date = new Date(cred.date.date).toISOString();
  } else if (cred.period_id) {
    date = new Date(cred.period.dates[0].date).toISOString();
  } else {
    const sortedPeriods = sortPeriods(cred.oneTimePeriods);
    date = new Date(sortedPeriods[0].start_date).toISOString();
  }
  return date;
}
