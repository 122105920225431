import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { capitalize } from 'lodash';
import { trimUriSegments } from '../common/uri';
import { getCurrentEventSettings } from '../event/event-selectors';
import { setPageSettings } from '../navigation/nav-actions';
import Immutable from 'immutable';
import ReactTable from '../lib/react-table';
import Icon from '../common/icons/Icon';
import Paper, { PaperHeader } from '../common/paper/Paper';
import LoadingIndicator from '../common/LoadingIndicator';

const MediaApprovalQueue = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const keepFilter = searchParams.has('keepFilter');

  const loading = useSelector(state =>
    state.media.applicationRequests.get('loading'),
  );

  const requestList = useSelector(state =>
    state.media.applicationRequests.get('data'),
  );

  const pageSettings = useSelector(state =>
    state.nav.pageSettings.get(location.pathname),
  );

  const subtypes = useSelector(state => {
    const eventSettings = getCurrentEventSettings(state, { params });
    return eventSettings?.getIn(['media', 'settings', 'subtypes']) ?? [];
  });

  const [showTableFilter, setShowTableFilter] = useState(true);
  const [pageIndex, setPageIndex] = useState(
    keepFilter && pageSettings?.pageIndex ? pageSettings.pageIndex : 0,
  );
  const [defaultFilter, setDefaultFilter] = useState(
    keepFilter && pageSettings?.filtered
      ? pageSettings.filtered
      : [{ id: 'status', value: 'pending' }],
  );

  useEffect(() => {
    dispatch(
      setPageSettings({
        url: location.pathname,
        values: null,
      }),
    );
  }, [dispatch, location.pathname]);

  const getRequestedCredentials = (
    department,
    status = 'quantity_approved',
  ) => {
    let types = department.get('credentialTypes'),
      typesMap = new Map();
    //credential id to credential-type-name map
    for (let t of types) {
      const creds = t.get('credentials');
      for (let c of creds) {
        typesMap.set(c.get('id'), t.get('name'));
      }
    }

    let requests = department.get('credentialRequests');
    //the same credential might be requested in more than one request, we need to aggregate for each credential
    let credMap = new Map();
    requests.every(entry => {
      const id = entry.get('credential_id');
      let sum = credMap.get(id) ? credMap.get(id) : 0;
      credMap.set(id, entry.get(status) + sum);
      return true;
    });
    let credLabels = [];
    credMap.forEach((value, key) =>
      value !== 0
        ? credLabels.push(
            <li key={key}>
              {typesMap.get(key)} ({value})
            </li>,
          )
        : '',
    );
    return credLabels;
  };

  const getPeriodsFilter = (filter, onChange) => {
    let credentialPeriods = new Immutable.Map();

    requestList.forEach(department => {
      department.get('credentialTypes').forEach(credentialType => {
        credentialType.get('credentials').forEach(credential => {
          const issueFrequency = credentialType.get('issue_frequency');

          let periods;
          if (issueFrequency === 'ONE_TIME') {
            periods = credential.get('oneTimePeriods');
          } else {
            periods = new Immutable.List();
            periods = periods.push(credential.get('period'));
          }
          credentialPeriods = credentialPeriods.set(
            credential.get('id'),
            periods,
          );
        });
      });
    });

    let filteredPeriods = new Immutable.Set();

    requestList.forEach(department => {
      department.get('credentialRequests').forEach(request => {
        const periods = credentialPeriods.get(request.get('credential_id'));
        periods.forEach(period => {
          filteredPeriods = filteredPeriods.add(
            Immutable.Map({
              id: period.get('id'),
              name: period.get('name'),
            }),
          );
        });
      });
    });

    return (
      <select
        onChange={event => onChange(event.target.value)}
        value={filter ? filter.value : ''}
        required
      >
        <option value="">All Periods</option>
        {filteredPeriods
          .sortBy(period => period.get('name'))
          .toJS()
          .map(period => (
            <option value={period.id} key={period.id}>
              {period.name}
            </option>
          ))}
      </select>
    );
  };

  const filterCredentialsByPeriod = (filter, row) => {
    const selectedPeriodId = parseInt(filter.value, 10);

    const credentialIds = new Set();
    const department = requestList.find(
      request => request.get('id') === row._original.id,
    );

    department.get('credentialTypes').forEach(credentialType => {
      const issueFrequency = credentialType.get('issue_frequency');
      credentialType.get('credentials').forEach(credential => {
        let hasPeriod = false;
        if (issueFrequency === 'ONE_TIME') {
          hasPeriod = credential
            .get('oneTimePeriods')
            .some(period => period.get('id') === selectedPeriodId);
        } else {
          hasPeriod = credential.get('period_id') === selectedPeriodId;
        }
        if (hasPeriod) {
          credentialIds.add(credential.get('id'));
        }
      });
    });

    if (filter.id === 'approvedCredential') {
      return row.approvedCredential
        .map(credential => parseInt(credential.key, 10))
        .some(credentialId => credentialIds.has(credentialId));
    } else if (filter.id === 'pendingCredential') {
      return row.pendingCredential
        .map(credential => parseInt(credential.key, 10))
        .some(credentialId => credentialIds.has(credentialId));
    }

    return false;
  };

  const toggleTableFilter = e => {
    e.preventDefault();
    if (showTableFilter) {
      setDefaultFilter([]);
      setShowTableFilter(false);
    } else {
      setShowTableFilter(true);
    }
  };

  const onClickRow = (row, filtered, pageIndex) => {
    dispatch(
      setPageSettings({
        url: location.pathname,
        values: { filtered, pageIndex },
      }),
    );

    navigate(
      `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/approvals/media/${row._original.slug}`,
    );
  };

  const onClickCell = (event, row, col) => {
    if (col.Header === 'Name' && row.original.status === 'approved') {
      event.stopPropagation();
      window.open(
        trimUriSegments(location.pathname, 2) + `/media/${row.original.slug}`,
        '_blank',
      );
    }
  };

  const renderRequestList = () => {
    const data = requestList
      .map(department => ({
        id: department.get('id'),
        name: department.get('name'),
        type: department.get('subtype'),
        approvedCredential: getRequestedCredentials(department),
        pendingCredential: getRequestedCredentials(
          department,
          'quantity_pending',
        ),
        slug: department.get('slug'),
        status: department.get('status'),
      }))
      .toJS();

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }) => (
          <span
            className={row._original.status === 'approved' ? 'text-link' : ''}
          >
            {row._original.name}
          </span>
        ),
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
            placeholder="Search by name"
          />
        ),
        filterMethod: (filter, row) =>
          row._original.name.toLowerCase().includes(filter.value.toLowerCase()),
        width: 200,
      },
      {
        Header: 'Type',
        accessor: 'type',
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            required
          >
            <option value="">All</option>
            {subtypes.map(type => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </select>
        ),
        width: 100,
      },
      {
        Header: 'Approved Credentials',
        accessor: 'approvedCredential',
        Cell: ({ value }) => (
          <div className={`status-approved`}>
            <ul>{value}</ul>
          </div>
        ),
        Filter: getPeriodsFilter,
        filterMethod: filterCredentialsByPeriod,
        sortable: false,
        width: 450,
      },
      {
        Header: 'Pending Credentials',
        accessor: 'pendingCredential',
        Cell: ({ value }) => (
          <div className={`status-pending`}>
            <ul>{value}</ul>
          </div>
        ),
        Filter: getPeriodsFilter,
        filterMethod: filterCredentialsByPeriod,
        sortable: false,
        width: 450,
      },

      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
          <span className={`status-${value}`}>{capitalize(value)}</span>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            onChange={event => onChange(event.target.value)}
            value={filter ? filter.value : ''}
            required
          >
            <option value="">All</option>
            {['pending', 'rejected', 'approved'].map(status => (
              <option value={status} key={status}>
                {capitalize(status)}
              </option>
            ))}
          </select>
        ),
        width: 100,
      },
      {
        sortable: false,
        filterable: false,
        width: 64,
        className: 'action action--animate',
        Cell: () => <Icon icon="ArrowRight" />,
      },
    ];

    return (
      <ReactTable
        className="department-list"
        data={data}
        columns={columns}
        defaultSorted={[
          {
            id: 'name',
            asc: true,
          },
        ]}
        defaultFiltered={defaultFilter}
        minRows={0}
        page={pageIndex}
        onPageChange={setPageIndex}
        onFilteredChange={() => setPageIndex(0)}
        defaultPageSize={20}
        showPagination={data.length > 20}
        showPageSizeOptions={false}
        filterable={showTableFilter}
        getTrGroupProps={(state, rowInfo) => ({
          onClick: () => onClickRow(rowInfo.row, state.filtered, state.page),
        })}
        getTdProps={(_state, row, col) => ({
          onClick: event => onClickCell(event, row, col),
        })}
        NoDataComponent={() => (
          <div className="generic-list--empty">
            <Icon icon="Sad" />
            <p>There are no results to display.</p>
          </div>
        )}
      />
    );
  };

  const actions = [];
  if (!requestList.isEmpty()) {
    actions.push(
      <button
        key="add-department-filter-action"
        className="button button--plain button--icon"
        onClick={toggleTableFilter}
      >
        <Icon icon="Filter" />
        <span>{showTableFilter ? 'Clear' : 'Advanced'} Filter</span>
      </button>,
    );
  }

  return (
    <Paper>
      <PaperHeader title="Media Requests" actions={actions} />
      {loading ? (
        <LoadingIndicator />
      ) : requestList.isEmpty() ? (
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>You have no departments added yet for this section.</p>
        </div>
      ) : (
        renderRequestList()
      )}
    </Paper>
  );
};

export default MediaApprovalQueue;
