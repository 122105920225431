import { createValidator } from 'revalidate';

export default createValidator(
  message => value => {
    if (value && value.replace(/[^\d]/g, '').length !== 10) {
      return message;
    }
  },
  field => `${field} must be a valid 10 digit phone number`,
);
