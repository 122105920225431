import { combineReducers } from 'redux';
import { getUserListReducer } from '../user/user-reducers';
import getApiReducer from '../common/utils/getApiReducer';
import {
  FETCH_PULSE_CLIENT_LIST,
  FETCH_PROMOTER_ADMIN_LIST,
  FETCH_PROMOTER_LIST,
} from './promoter-actions';

const reducers = combineReducers({
  adminList: getUserListReducer(FETCH_PROMOTER_ADMIN_LIST),
  list: getApiReducer(FETCH_PROMOTER_LIST),
  pulseClientList: getApiReducer(FETCH_PULSE_CLIENT_LIST),
});

export default reducers;
