import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAdvanceApplicationResponses } from '../advance-application/advance-actions';
import { getCurrentDepartment } from './department-selectors';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import DisplayFields from '../common/custom-forms/display-fields';
import Icon from '../common/icons/Icon';

const DepartmentAdvanceApplicationDisplay = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const responses = useSelector(state =>
    state.advance.applicationResponses.get('loaded')
      ? state.advance.applicationResponses
          .get('data')
          .sort(
            (a, b) =>
              a.getIn(['applicationSection', 'sort_order']) -
              b.getIn(['applicationSection', 'sort_order']),
          )
      : null,
  );

  useEffect(() => {
    if (department) {
      const linkToken = department.getIn(['advanceFormLink', 'link_token']);
      if (linkToken) dispatch(getAdvanceApplicationResponses(linkToken));
    }
  }, [department, dispatch]);

  if (!responses) return <LoadingIndicator />;

  if (responses.isEmpty())
    return (
      <Paper>
        <PaperHeader title="Application" />
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>No application was submitted</p>
        </div>
      </Paper>
    );

  const getResponse = key =>
    responses.find(
      response => response.getIn(['applicationSection', 'key']) === key,
    );

  const renderDepartmentInfo = () => {
    const response = getResponse('department-info');
    const departmentInfo = response?.getIn(['content', 'fields']);

    if (!departmentInfo) return null;

    return (
      <Paper>
        <PaperHeader title="Department Info" />
        <div className="media-app-std-res">
          <dl>
            <div>
              <dt>Department Name</dt>
              <dd>{departmentInfo.get('department_info_name')}</dd>
            </div>
            <div>
              <dt>Submitter Name</dt>
              <dd>{departmentInfo.get('department_info_contact_name')}</dd>
            </div>
            <div>
              <dt>Submitter Email</dt>
              <dd>{departmentInfo.get('department_info_contact_email')} </dd>
            </div>
          </dl>
        </div>
      </Paper>
    );
  };

  const renderCredentialRequest = credentialRequest => {
    const approvedLabel = credentialRequest.quantity.approved
      ? `${credentialRequest.quantity.approved} (Approved)`
      : '';
    const pendingLabel = credentialRequest.quantity.pending
      ? `${credentialRequest.quantity.approved ? ', ' : ''}${
          credentialRequest.quantity.pending
        } (Pending)`
      : '';

    let label = credentialRequest.credential;

    if (credentialRequest.action !== 'Removed')
      label += `, Quantity: ${approvedLabel}${pendingLabel}`;

    return (
      <div>
        <dt>{credentialRequest.action}</dt>
        <dd>{label}</dd>
      </div>
    );
  };

  const renderError = error => (
    <div style={{ color: 'red' }}>
      <dt>Error</dt>
      <dd>{error.error}</dd>
    </div>
  );

  const renderStaffCredentials = () => {
    const fields = getResponse('credential-requests')
      ?.getIn(['content', 'fields'])
      ?.toJS();

    if (!fields) return null;

    const { credentialRequests, errors } = fields;
    if (!credentialRequests?.length > 0 && !errors?.length > 0) return null;

    return (
      <Paper>
        <PaperHeader title="Staff Credentials" />
        <div className="media-app-std-res">
          <dl>
            {credentialRequests?.map(renderCredentialRequest)}
            {errors?.map(renderError)}
          </dl>
        </div>
      </Paper>
    );
  };

  const renderCustomResponse = response => (
    <Paper key={response.get('id')}>
      <PaperHeader title={response.getIn(['applicationSection', 'name'])} />
      <dl className="media-info__wrapper">
        {response.getIn(['content', 'fields']).map((field, index) => (
          <DisplayFields
            key={index}
            label={field.get('label')}
            type={field.get('type')}
            value={field.get('value')}
          />
        ))}
      </dl>
    </Paper>
  );

  const renderCustomResponses = () => (
    <div className="media-app-custom-res">
      {responses
        .filter(
          response => response.getIn(['applicationSection', 'key']) === null,
        )
        .map(renderCustomResponse)}
    </div>
  );

  const renderNotes = () => (
    <Paper>
      <PaperHeader title="Notes" />
      <div className="media-app-std-res">
        {department.get('credentialRequestsNotes')?.get('notes')}
      </div>
    </Paper>
  );

  return (
    <div className="media-app">
      {renderDepartmentInfo()}
      {renderStaffCredentials()}
      {renderCustomResponses()}
      {renderNotes()}
    </div>
  );
};

export default DepartmentAdvanceApplicationDisplay;
