import { createValidator } from 'revalidate';

export default function isValidDecimal(
  numDecimalPlaces = Number.MAX_SAFE_INTEGER,
) {
  const regex = new RegExp(
    `^-?\\d+${numDecimalPlaces > 0 ? `(\\.\\d{1,${numDecimalPlaces}})?` : ''}$`,
  );
  return createValidator(
    message => value => {
      if (value && !regex.test(String(value))) {
        return message;
      }
    },
    field => `${field} must be a valid decimal number`,
  );
}
