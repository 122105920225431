import { useState } from 'react';
import { Renderer, defaultBlocks } from '../../lib/draftjs-editor';
import Checkbox from '../forms/CheckboxBoolean';
import InputScaffold from '../forms/InputScaffold';

const draftRenderer = new Renderer(defaultBlocks);

const LegalNotice = ({ legalNotice, onViewApplication }) => {
  const [accepted, setAccepted] = useState(false);

  const legalNoticeUpdated = draftRenderer.convertRawToHTML(
    legalNotice.get('content').toJS(),
  );

  return (
    <section className="public-application__section">
      <header>
        <div className="public-application__section--title">
          Legal Disclaimer
        </div>
      </header>
      <div
        className="draftjs-output"
        dangerouslySetInnerHTML={{ __html: legalNoticeUpdated }}
      />
      <InputScaffold>
        <Checkbox
          label="I accept the terms and conditions."
          value={accepted}
          onChange={() => setAccepted(!accepted)}
        />
      </InputScaffold>
      <button
        className="button"
        type="button"
        onClick={onViewApplication}
        disabled={!accepted}
      >
        Continue to Application
      </button>
    </section>
  );
};

export default LegalNotice;
