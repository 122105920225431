import { useSelector } from 'react-redux';
import { Field, useFormState } from 'react-final-form';
import { Map as ImmutableMap } from 'immutable';
import {
  getAvailableCategoryOptions,
  getAvailableCredentialPeriodOptions,
  getAvailableCredentials,
} from './application-selectors';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import ReduxReactSelectField from '../forms/ReduxReactSelectField';
import ReduxFormsField from '../forms/ReduxFormsField';
import Credential from './Credential';

const CredentialsSection = ({
  type,
  event,
  selectedCredentials,
  includeCredentialModifiers,
  onChange,
}) => {
  const {
    values: { selectedCategories, selectedPeriods },
  } = useFormState();

  const availableCategoryOptions = useSelector(state =>
    getAvailableCategoryOptions(state, { type, event }),
  );

  const availableCredentialPeriodOptions = useSelector(state =>
    getAvailableCredentialPeriodOptions(state, { type, event }),
  );

  const availableCredentials = useSelector(state =>
    getAvailableCredentials(state, { type, event }),
  );

  const isCategoryInSelectedCategories = (category, selectedCategories) =>
    selectedCategories && selectedCategories.length > 0
      ? selectedCategories.some(
          selectedCategory => selectedCategory.value === category.get('id'),
        )
      : false;

  const onCategoryChange = options => {
    const selectedCategories = Object.values(options);

    const filteredAvailableCredentials = availableCredentials
      .filter(credential =>
        isCategoryInSelectedCategories(
          credential.getIn(['credentialType', 'category']),
          selectedCategories,
        ),
      )
      .map(credential => credential.get('id'))
      .toArray();

    const filteredSelectedCredentials = selectedCredentials.filter(credential =>
      filteredAvailableCredentials.includes(credential.id),
    );

    if (!isEqual(selectedCredentials, filteredSelectedCredentials))
      onChange(filteredSelectedCredentials);
  };

  const getFilteredPeriods = (credential, selectedPeriods) => {
    const periods =
      credential.getIn(['credentialType', 'issue_frequency']) === 'ONE_TIME'
        ? credential.get('oneTimePeriods').toArray()
        : [credential.get('period')];

    return periods.filter(period =>
      selectedPeriods.some(
        selectedPeriod => selectedPeriod.value === period.get('id'),
      ),
    );
  };

  const getAvailableCredentialOptions = () => {
    const availableCredentialOptions = [];
    if (availableCredentials) {
      availableCredentials.forEach(credential => {
        const credentialType = credential.get('credentialType');
        const category = credentialType.get('category');

        if (!isCategoryInSelectedCategories(category, selectedCategories)) {
          return;
        }

        const periods = getFilteredPeriods(credential, selectedPeriods);
        if (!periods.length) {
          return;
        }

        const selectedCredential = selectedCredentials.find(
          selectedCredential => selectedCredential.id === credential.get('id'),
        );

        availableCredentialOptions.push({
          credential,
          periods,
          selectedCredential,
        });
      });
    }

    return availableCredentialOptions;
  };

  const availableCredentialOptions = getAvailableCredentialOptions();

  const onPeriodChange = options => {
    const selectedPeriods = Object.values(options);

    const filteredAvailableCredentials = availableCredentials
      .filter(
        credential => !!getFilteredPeriods(credential, selectedPeriods).length,
      )
      .map(credential => credential.get('id'))
      .toArray();

    const filteredSelectedCredentials = selectedCredentials.filter(credential =>
      filteredAvailableCredentials.includes(credential.id),
    );

    if (!isEqual(selectedCredentials, filteredSelectedCredentials))
      onChange(filteredSelectedCredentials);
  };

  const onSelect = selectedCredential => {
    const newSelectedCredentials = [...selectedCredentials];

    const index = newSelectedCredentials.findIndex(
      credential => credential.id === selectedCredential.id,
    );
    if (index !== -1) {
      newSelectedCredentials[index] = selectedCredential;
    } else {
      newSelectedCredentials.push(selectedCredential);
    }

    onChange(newSelectedCredentials);
  };

  const onUnSelect = selectedCredential => {
    const newSelectedCredentials = [...selectedCredentials];

    const index = newSelectedCredentials.findIndex(
      credential => credential.id === selectedCredential.id,
    );
    if (index !== -1) {
      newSelectedCredentials.splice(index, 1);
    }

    onChange(newSelectedCredentials);
  };

  if (!availableCredentials?.size) {
    return (
      <div className="generic-form__body">
        <h4>{`No ${type} credentials available`}</h4>
      </div>
    );
  }

  return (
    <div className="generic-form__body">
      <div className="input-group input-group--large">
        <Field
          name="selectedCategories"
          component={ReduxFormsField}
          label="Category(s)"
          required
          onChange={onCategoryChange}
        >
          <ReduxReactSelectField
            name="Category(s)"
            placeholder="Category(s)"
            options={availableCategoryOptions}
            isMulti={true}
          />
        </Field>
        <Field
          name="selectedPeriods"
          component={ReduxFormsField}
          label="Period(s)"
          required
          onChange={onPeriodChange}
        >
          <ReduxReactSelectField
            name="Period(s)"
            placeholder="Period(s)"
            options={availableCredentialPeriodOptions}
            isMulti={true}
          />
        </Field>
      </div>
      <div>
        {availableCredentialOptions &&
          availableCredentialOptions.map((option, index) => (
            <Credential
              key={index}
              credential={option.credential}
              periods={option.periods}
              selectedCredential={option.selectedCredential}
              includeCredentialModifiers={includeCredentialModifiers}
              onSelect={onSelect}
              onUnSelect={onUnSelect}
            />
          ))}
      </div>
    </div>
  );
};

CredentialsSection.propTypes = {
  type: PropTypes.oneOf(['media', 'intern', 'advance']).isRequired,
  event: PropTypes.instanceOf(ImmutableMap).isRequired,
  selectedCredentials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isSingleInstance: PropTypes.bool.isRequired,
      isAddOn: PropTypes.bool.isRequired,
    }),
  ),
  includeCredentialModifiers: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

export default CredentialsSection;
