import { resource } from '../common/ACL';

const reports = [
  {
    title: 'Credentials By Recipient',
    report_slug: 'credentials_by_recipient',
    acl: [resource.DEPARTMENT, ['view-reports-all', 'view-reports-credential']],
    fields: {
      periods: {
        label: 'Period(s)',
        parameterName: 'periods',
        options: [],
        multi: true,
      },
      exclude: {
        label: 'Exclude',
        parameterName: 'exclude',
        options: [],
        multi: false,
      },
    },
  },
  {
    title: 'Issued Credentials by Hour',
    report_slug: 'issued_credentials_by_hour',
    acl: [resource.DEPARTMENT, ['view-reports-all', 'view-reports-credential']],
    fields: {
      credentialType: {
        label: 'Credential Type',
        parameterName: 'credential_type',
        options: [],
        multi: true,
      },
    },
  },
  {
    title: 'Pass Totals By Recipient',
    report_slug: 'pass_totals_by_recipient',
    acl: [resource.DEPARTMENT, ['view-reports-all', 'view-reports-credential']],
    fields: {
      credentialType: {
        label: 'Credential Type',
        parameterName: 'credential_type',
        options: [],
        multi: true,
      },
      status: {
        label: 'Status',
        parameterName: 'status',
        options: [
          { value: 'All', label: 'all' },
          { value: 'Approved', label: 'approved' },
          { value: 'Pending', label: 'pending' },
          { value: 'Declined', label: 'declined' },
          { value: 'Deactivated', label: 'deactivated' },
          { value: 'Issued', label: 'issued' },
        ],
        multi: false,
      },
      periods: {
        label: 'Period',
        parameterName: 'period',
        options: [],
        multi: false,
      },
      deliveryMethod: {
        label: 'Delivery Method',
        parameterName: 'delivery_method',
        options: [],
        multi: false,
      },
    },
  },
  {
    title: 'Catering: Requested Meals',
    report_slug: 'catering_requested_meals',
    acl: [resource.DEPARTMENT, ['view-reports-all', 'view-reports-catering']],
    fields: {},
  },
  {
    title: 'Catering: Daily Meal Summary',
    report_slug: 'catering_daily_meal_summary',
    acl: [resource.DEPARTMENT, ['view-reports-all', 'view-reports-catering']],
    fields: {
      periods: {
        label: 'Period(s)',
        parameterName: 'periods',
        options: [],
        multi: true,
      },
      periodDates: {
        label: 'Date(s)',
        parameterName: 'periodDates',
        options: [],
        multi: true,
      },
      mealTypes: {
        label: 'Meal Type(s)',
        parameterName: 'mealTypes',
        options: [],
        multi: true,
      },
    },
  },
  {
    title: 'Catering: Claimed Meal Summary',
    report_slug: 'catering_claimed_meal_summary',
    acl: [resource.DEPARTMENT, ['view-reports-all', 'view-reports-catering']],
    fields: {
      periods: {
        label: 'Period(s)',
        parameterName: 'periods',
        options: [],
        multi: true,
      },
      mealTypes: {
        label: 'Meal Type(s)',
        parameterName: 'mealTypes',
        options: [],
        multi: true,
      },
    },
  },
];

const aclRules = {};
reports.forEach(report => (aclRules[report.report_slug] = report.acl));

const config = {
  reports,
  aclRules,
};

export default config;
