import { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { Body } from './DownloadModal.styles';
import { StyledButton } from '../../../common/StyledButton';

const styles = {
  button: {
    width: '300px',
  },
};

const DownloadModal = ({ handleDownload, trigger }) => {
  const [open, setOpen] = useState(false);

  const handleClick = includeFilters => {
    handleDownload('text/csv', includeFilters);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      size="large"
      trigger={trigger}
    >
      <Modal.Header>Download Format...</Modal.Header>
      <Modal.Content>
        <Body>
          <StyledButton
            title="Download as CSV (current filters)"
            color="blue"
            margin="right"
            style={styles.button}
            handleClick={() => handleClick(true)}
          />
          <StyledButton
            title="Download as CSV (all data)"
            color="blue"
            style={styles.button}
            handleClick={() => handleClick(false)}
          />
        </Body>
      </Modal.Content>
    </Modal>
  );
};

export default DownloadModal;
