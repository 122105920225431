import { combineReducers } from 'redux';
import Immutable from 'immutable';
import getApiReducer from '../common/utils/getApiReducer';

import { FETCH_EVENT_ONSITE_REQUESTS } from './onsite-actions';

const onsiteRequestsReducer = (state = Immutable.Map(), action) => {
  let newState = state;

  if (action.originalType === FETCH_EVENT_ONSITE_REQUESTS) {
    newState = newState.setIn(
      [action.eventId],
      getApiReducer(FETCH_EVENT_ONSITE_REQUESTS)(
        newState.getIn([action.eventId]),
        action,
      ),
    );
  }

  if (action.type === 'DELETE_ONSITE_CREDENTIAL_REQUEST_SUCCESS') {
    let path = [action.json.event_id, 'data'];
    const onsiteRequests = newState.getIn(path);
    if (onsiteRequests) {
      const onsiteRequestIndex = onsiteRequests.findIndex(
        onsiteRequest => onsiteRequest.get('id') === action.json.id,
      );
      if (onsiteRequestIndex > -1) {
        newState = newState.setIn(
          path.concat(onsiteRequestIndex),
          Immutable.fromJS(action.json),
        );
      }
    }
  }

  if (
    [
      'APPROVE_ONSITE_REQUEST_SUCCESS',
      'DECLINE_ONSITE_REQUEST_SUCCESS',
    ].includes(action.type)
  ) {
    let path = [action.json.request.event_id, 'data'];
    const onsiteRequests = newState.getIn(path);
    if (onsiteRequests) {
      const onsiteRequestIndex = onsiteRequests.findIndex(
        onsiteRequest => onsiteRequest.get('id') === action.json.request.id,
      );
      if (onsiteRequestIndex > -1) {
        newState = newState.setIn(
          path.concat(onsiteRequestIndex),
          Immutable.fromJS(action.json.request),
        );
      }
    }
  }

  return newState;
};

const reducers = combineReducers({
  onsiteRequests: onsiteRequestsReducer,
});

export default reducers;
