import departmentTypeList from '../lib/department-types';

export const convertRoleBooleansToRoleArray = roleBooleans => {
  let roles = [];
  Object.keys(roleBooleans).forEach(role => {
    if (roleBooleans[role]) {
      roles.push(role);
    }
  });

  // if event_admin exists, that's the only role we need
  if (roles.indexOf('event_admin') > -1) {
    return ['event_admin'];
  }

  // If section_view does not exist
  departmentTypeList.forEach(section => {
    if (roles.indexOf(`${section}_view`) === -1) {
      roles = roles.filter(role => role.indexOf(`${section}_`) !== 0);
    }
  });

  return roles;
};

export const getDefaultRoleValues = () => {
  const defaultRoles = {
    event_admin: false,
    credential_edit: false,
    credential_request: false,
  };
  departmentTypeList.forEach(section => {
    defaultRoles[`${section}_view`] = false;
    defaultRoles[`${section}_edit`] = false;
    defaultRoles[`${section}_credential`] = false;
  });
  return defaultRoles;
};
