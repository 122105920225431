import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { composeValidators, isRequired, isRequiredIf } from 'revalidate';
import { defaultBlocks } from '../lib/draftjs-editor';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import { getCurrentDepartment } from '../department/department-selectors';
import isCsvOfEmails from '../common/validate/is-csv-of-emails';
import PropTypes from 'prop-types';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import CheckboxBoolean from '../common/forms/CheckboxBoolean';
import ReduxSynapseDraft from '../common/forms/ReduxSynapseDraft';
import InputScaffold from '../common/forms/InputScaffold';
import FormStatusButton from '../common/FormStatusButton';

const SendLinkEmail = ({ title, emailTemplates, sendEmail, onEmailSent }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const [selectedEmailTemplateId, setSelectedEmailTemplateId] = useState(
    emailTemplates.length === 1 ? emailTemplates[0].id : '-1',
  );

  const getEmailTemplate = id =>
    emailTemplates.find(
      emailTemplate => String(emailTemplate.id) === String(id),
    );

  const selectedEmailTemplate = getEmailTemplate(selectedEmailTemplateId);

  const initialValues = useMemo(() => {
    const defaultEmailTemplate =
      emailTemplates.length === 1 ? emailTemplates[0] : null;

    return {
      emailTemplateId: defaultEmailTemplate?.id ?? '-1',
      recipients: department?.get('default_email_address') ?? '',
      subject: defaultEmailTemplate?.subject ?? null,
      body: defaultEmailTemplate?.body ?? null,
      send_to_primary_contacts: false,
    };
  }, [department, emailTemplates]);

  const handleSubmit = (values, form) => {
    const payload = { ...values };
    if (values.emailTemplateId === '-1') delete payload.emailTemplateId;

    payload.recipients = values.recipients
      .split(',')
      .map(val => val.trim())
      .filter(val => !!val);

    return dispatch(sendEmail(payload)).then(action => {
      if (action.response.ok) {
        form.initialize(values);
        onEmailSent();
        dispatch(
          showNotification({
            status: 'success',
            message: 'Email successfully sent',
          }),
        );
      } else {
        return formatValidationErrors(action.json).toJS();
      }
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({ form: { initialize }, handleSubmit, values }) => (
        <Paper>
          <PaperHeader title={title} />
          <form onSubmit={handleSubmit}>
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                {emailTemplates && (
                  <InputScaffold label="Email Template" required>
                    <select
                      value={selectedEmailTemplateId}
                      onChange={e => {
                        const emailTemplateId = e.target.value;
                        const emailTemplate = getEmailTemplate(emailTemplateId);
                        setSelectedEmailTemplateId(emailTemplateId);
                        initialize({
                          emailTemplateId: emailTemplateId ?? null,
                          recipients: values.recipients,
                          subject: emailTemplate?.subject ?? null,
                          body: emailTemplate?.body ?? null,
                          send_to_primary_contacts:
                            values.send_to_primary_contacts,
                        });
                      }}
                    >
                      <option value="-1">No Template (Custom Message)</option>
                      {emailTemplates.map((emailTemplate, index) => (
                        <option value={emailTemplate.id} key={emailTemplate.id}>
                          {emailTemplate.emailType.label}{' '}
                          {emailTemplates.length > 1 ? `#${index + 1}` : ''}
                        </option>
                      ))}
                    </select>
                  </InputScaffold>
                )}
                <Field
                  name="recipients"
                  label={
                    <div>
                      Recipients
                      <p>Separate multiple email addresses with a comma.</p>
                    </div>
                  }
                  component={ReduxFormsField}
                  placeholder="e.g. name@email.com"
                  required
                  validate={composeValidators(
                    isRequiredIf(values => !values.send_to_primary_contacts),
                    isCsvOfEmails,
                  )('Recipients')}
                >
                  <input type="text" />
                </Field>
                <div style={{ marginTop: '0px' }}>
                  <Field
                    name="send_to_primary_contacts"
                    component={ReduxFormsFieldNoLabel}
                  >
                    <CheckboxBoolean
                      id="send_to_primary_contacts"
                      label="Send email to contacts marked as primary"
                    />
                  </Field>
                </div>
                <Field
                  name="subject"
                  label="Subject"
                  component={ReduxFormsField}
                  required
                  validate={isRequired('Subject')}
                >
                  <input type="text" />
                </Field>
                <Field
                  key={values.emailTemplateId}
                  name="body"
                  label="Body"
                  component={ReduxFormsField}
                  required
                  validate={isRequired('Body')}
                >
                  <ReduxSynapseDraft
                    inlineControls={['Bold', 'Italic', 'Underline', 'Link']}
                    blockControls={[
                      'UL',
                      'OL',
                      'H1',
                      'H2',
                      'H3',
                      'H4',
                      'H5',
                      'H6',
                      'Blockquote',
                    ]}
                    controlDisplay="inline"
                    customBlocks={defaultBlocks}
                    customBlockControls={Object.keys(defaultBlocks)}
                  />
                </Field>
                {selectedEmailTemplate?.attachments.length > 0 && (
                  <div className="input-scaffold">
                    <span className="input-label">Current Attachments:</span>{' '}
                    {selectedEmailTemplate.attachments.map(attachment => (
                      <a key={attachment.key} href={attachment.location}>
                        {attachment.key}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="generic-form__footer">
              <FormStatusButton
                buttonText="Send Email"
                completeText="Email Sent"
                enabledOnPristine
              />
            </div>
          </form>
        </Paper>
      )}
    />
  );
};

SendLinkEmail.propTypes = {
  title: PropTypes.string.isRequired,
  emailTemplates: PropTypes.array.isRequired,
  sendEmail: PropTypes.func.isRequired,
  onEmailSent: PropTypes.func.isRequired,
};

export default SendLinkEmail;
