import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { List, Map } from 'immutable';
import { Tooltip } from 'react-tooltip';
import { resource, useACL } from '../common/ACL';
import { fetchDepartment } from '../department/department-actions';
import { deleteContact } from './contact-actions';
import * as Scroll from 'react-scroll';
import styled from 'styled-components';
import departmentTypeMap from '../lib/department-type-map';
import sortContacts from './sort-contacts';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import ContactName from './ContactName';
import RequestStatusIndicator from './RequestStatusIndicator';
import ExpandableRow from '../common/ExpandableRow';
import DeleteContactModal from '../common/DeleteContactModal';
import ContactDetailsForm from './ContactDetailsForm';

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  align-items: center;
  > div {
    margin-left: 12px;
  }
`;

const ErrorIcon = styled.div`
  span.icon svg {
    fill: #e74c3c !important;
  }
`;

const aclRules = {
  canEdit: [resource.DEPARTMENT, 'edit'],
  canReconcileOrder: [resource.EVENT, 'reconcile-order'],
};

const ContactListContents = ({
  department,
  deptSettings,
  contactList,
  displayContact,
}) => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();

  const departmentType = department.get('type');

  const loggedInUser = useSelector(state =>
    state.auth.loggedInUser.get('data'),
  );

  const isAdmin = loggedInUser.get('roles').some(role => /admin/.test(role));

  const [hasScrolledToDisplayContact, setHasScrolledToDisplayContact] =
    useState(false);
  const [expanded, setExpanded] = useState({});

  const getScrollElementName = contactId => `scroll-contact-${contactId}`;

  useEffect(() => {
    if (displayContact) {
      setExpanded({ [displayContact]: true });
      setHasScrolledToDisplayContact(false);
    }
  }, [displayContact]);

  useEffect(() => {
    if (displayContact && !hasScrolledToDisplayContact) {
      if (contactList.some(contact => contact.get('id') === displayContact)) {
        setHasScrolledToDisplayContact(true);
        Scroll.scroller.scrollTo(getScrollElementName(displayContact), {
          smooth: true,
        });
      }
    }
  }, [contactList, displayContact, hasScrolledToDisplayContact]);

  const onDelete = async contact => {
    const departmentId = department.get('id');
    const contactId = contact.get('id');
    await dispatch(deleteContact(departmentId, contactId)).then(() =>
      dispatch(fetchDepartment(departmentId)),
    );
  };

  const toggleContactExpansion = contactId => {
    setExpanded(prevExpanded => {
      const newExpanded = { ...prevExpanded };
      newExpanded[contactId] = !newExpanded[contactId];
      return newExpanded;
    });
  };

  const getPulseOrders = contact => contact.get('pulseOrders');

  const getPulseOrderOutOfSync = contact =>
    getPulseOrders(contact)?.some(pulseOrder =>
      pulseOrder.get('out_of_sync'),
    ) ?? false;

  const getPulseTagsCount = contact => {
    const pulseOrders = getPulseOrders(contact);

    return !pulseOrders
      ? 0
      : pulseOrders.reduce(
          (count, order) => count + order.get('pulseTags').size,
          0,
        );
  };

  const getContactStatus = contact => {
    if (contact.get('credentialRequests').size === 0) {
      return null;
    }
    // return pending if there are any pending requests at all
    return contact
      .get('credentialRequests')
      .some(cred => cred.get('quantity_pending') > 0)
      ? 'pending'
      : 'approved';
  };

  return (
    <div>
      {contactList.isEmpty() ? (
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>
            {departmentType === 'intern'
              ? 'No interns have been added yet'
              : `No staff have been added for this ${departmentTypeMap[departmentType].label.singular}`}
          </p>
        </div>
      ) : (
        sortContacts(contactList).map(contact => {
          const contactId = contact.get('id');
          const contactStatus = getContactStatus(contact) || 'pending';
          const pulseOrderOutOfSync = getPulseOrderOutOfSync(contact);
          const pulseTagsCount = getPulseTagsCount(contact);
          const isLocked = !!pulseTagsCount;

          const columns = [
            <ContactName
              contact={contact}
              canEdit={!isLocked && acl.canEdit}
            />,
          ];

          columns.push(
            <IconContainer>
              <RequestStatusIndicator status={`${contactStatus}`} />
              {pulseOrderOutOfSync && (
                <ErrorIcon
                  data-tooltip-id={`contact-out-of-sync-tooltip-${contactId}`}
                >
                  {acl.canReconcileOrder && (
                    <Link to={`../../reconcile/${contactId}`}>
                      <Icon icon="ErrorTriangle" />
                    </Link>
                  )}
                  {!acl.canReconcileOrder && <Icon icon="ErrorTriangle" />}
                  <Tooltip
                    id={`contact-out-of-sync-tooltip-${contactId}`}
                    content="Pulse Order Out of Sync"
                  />
                </ErrorIcon>
              )}
              {isAdmin && isLocked && (
                <div
                  data-tooltip-id={`contact-issued-credentials-tooltip-${contactId}`}
                >
                  <Icon icon="IssuedCredential" />
                  <Tooltip
                    id={`contact-issued-credentials-tooltip-${contactId}`}
                    content={`Issued: ${pulseTagsCount}`}
                  />
                </div>
              )}
              {acl.canEdit && !isLocked && (
                <DeleteContactModal
                  contact={contact}
                  onDelete={onDelete}
                  trigger={
                    <div>
                      <Icon icon="Trash" />
                    </div>
                  }
                />
              )}
            </IconContainer>,
          );

          return (
            <Scroll.Element
              key={contactId}
              name={getScrollElementName(contactId)}
            >
              <ExpandableRow
                columns={columns}
                isExpanded={expanded[contactId]}
                isExpandable
                onToggleExpansion={() => toggleContactExpansion(contactId)}
              >
                <ContactDetailsForm
                  department={department}
                  contact={contact}
                  deptSettings={deptSettings}
                />
              </ExpandableRow>
            </Scroll.Element>
          );
        })
      )}
    </div>
  );
};

ContactListContents.propTypes = {
  department: PropTypes.instanceOf(Map).isRequired,
  deptSettings: PropTypes.instanceOf(Map),
  contactList: PropTypes.instanceOf(List).isRequired,
  displayContact: PropTypes.number,
};

export default ContactListContents;
