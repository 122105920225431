import { createValidator } from 'revalidate';

export default function timeNotAfterIfSameDate(
  compareTime,
  date1,
  date2,
  compareField,
) {
  return createValidator(
    message => (value, values) => {
      if (
        values[date1] &&
        values[date2] &&
        values[date1].format('YYYY-MM-DD') ===
          values[date2].format('YYYY-MM-DD') &&
        value >= values[compareTime]
      ) {
        return message;
      }
    },
    field => `${field} cannot be after ${compareField}`,
  );
}
