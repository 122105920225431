import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { getCurrentDepartment } from './department-selectors';
import { clearUserList, fetchUserList } from './department-actions';
import {
  disableDepartmentUser,
  enableDepartmentUser,
  resendInvite,
} from '../user/user-actions';
import UserList from '../user/UserList';

const aclRules = {
  canInvite: [resource.DEPARTMENT, 'edit'],
  canEnable: [resource.EVENT, 'enable-disable-department-user'],
  canDisable: [resource.EVENT, 'enable-disable-department-user'],
};

const DepartmentUserList = () => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const departmentId = useSelector(state =>
    getCurrentDepartment(state, { params })?.get('id'),
  );

  const userList = useSelector(state => state.department.userList.get('data'));
  const loading = useSelector(state =>
    state.department.userList.get('loading'),
  );
  const deleting = useSelector(state => state.user.deleteUser.get('loading'));

  const isDisabled = user =>
    !user.get('departments').find(dept => dept.get('id') === departmentId);

  useEffect(() => {
    if (departmentId) dispatch(fetchUserList(departmentId));
    return () => dispatch(clearUserList());
  }, [dispatch, departmentId]);

  if (!departmentId) return null;

  return (
    <UserList
      userType="User"
      userList={userList}
      loading={loading}
      deleting={deleting}
      isDisabled={isDisabled}
      addNewRoute={`${location.pathname}/add`}
      canInvite={acl.canInvite ?? false}
      resendInviteAction={resendInvite}
      disableAction={
        acl.canDisable
          ? userId => disableDepartmentUser(departmentId, userId)
          : null
      }
      enableAction={
        acl.canEnable
          ? userId => enableDepartmentUser(departmentId, userId)
          : null
      }
    />
  );
};

export default DepartmentUserList;
