import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCurrentDepartment } from '../department/department-selectors';
import { getCurrentContact } from '../contact/contact-selectors';
import { fetchDepartment } from '../department/department-actions';
import {
  getContactReconciliation,
  getDepartmentReconciliation,
} from './reconcile-actions';
import styled from 'styled-components';
import LoadingIndicator from '../common/LoadingIndicator';
import NotFound from '../common/NotFound';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ExpandableRow from '../common/ExpandableRow';
import RequestStatusIndicator from '../contact/RequestStatusIndicator';
import ReconcileOrder from './ReconcileOrder';

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  align-items: center;
  > div {
    margin-left: 12px;
  }
`;

const Reconcile = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const params = useParams();

  const backTo = state?.backTo ?? '..';

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const contact = useSelector(state => getCurrentContact(state, { params }));

  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState({});

  const label = contact
    ? `${contact.get('first_name')} ${contact.get('last_name')}`
    : department?.get('name') ?? '';

  useEffect(() => {
    if (params.contactId && contact)
      dispatch(getContactReconciliation(contact.get('id'))).then(action => {
        if (action.response.ok) {
          const orders = action.json;
          setOrders(orders);
          if (orders.length === 1)
            setExpanded({ [orders[0].strataOrder.id]: true });
        }
        setLoading(false);
      });
    else if (!params.contactId && department)
      dispatch(getDepartmentReconciliation(department.get('id'))).then(
        action => {
          if (action.response.ok) {
            const orders = action.json;
            setOrders(orders);
            if (orders.length === 1)
              setExpanded({ [orders[0].strataOrder.id]: true });
          }
          setLoading(false);
        },
      );
  }, [contact, department, dispatch, params.contactId]);

  const toggleExpanded = order => {
    setExpanded(prevExpanded => {
      const newExpanded = { ...prevExpanded };
      newExpanded[order.strataOrder.id] = !prevExpanded[order.strataOrder.id];
      return newExpanded;
    });
  };

  const onReconciled = orders => {
    setOrders(orders);
    if (!params.contactId && department)
      dispatch(fetchDepartment(department.get('id')));
  };

  if (loading) return <LoadingIndicator />;

  if (params.contactId && !contact) return <NotFound />;

  const getStatusIndicators = order => {
    const inSync = order.entries.reduce(
      (count, entry) => (entry.inSync ? count + 1 : count),
      0,
    );

    const outOfSync = order.entries.reduce(
      (count, entry) => (!entry.inSync ? count + 1 : count),
      0,
    );

    return (
      <>
        {inSync > 0 && (
          <RequestStatusIndicator status="approved" leftContent={inSync} />
        )}
        {outOfSync > 0 && (
          <RequestStatusIndicator status="rejected" leftContent={outOfSync} />
        )}
      </>
    );
  };

  return (
    <>
      <Paper>
        <PaperHeader backTo={backTo} title={`${label} - Pulse Orders`} />
      </Paper>
      {orders?.map(order => (
        <ExpandableRow
          key={order.strataOrder.id}
          isExpandable
          isExpanded={expanded[order.strataOrder.id]}
          onToggleExpansion={() => toggleExpanded(order)}
          columns={[
            `${order.pulseEvent.name} (${order.strataOrder.pulse_reference_id})`,
            <IconContainer key="status">
              {getStatusIndicators(order)}
            </IconContainer>,
          ]}
        >
          <ReconcileOrder order={order} onReconciled={onReconciled} />
        </ExpandableRow>
      ))}
    </>
  );
};

export default Reconcile;
