import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { trimUriSegments } from '../common/uri';
import { clearNewUser, inviteUser } from './department-actions';
import { getCurrentDepartment } from './department-selectors';
import UserForm from '../user/UserForm';

const DepartmentUserForm = () => {
  const location = useLocation();
  const params = useParams();

  const departmentId = useSelector(state =>
    getCurrentDepartment(state, { params })?.get('id'),
  );

  if (!departmentId) return null;

  return (
    <UserForm
      userType="User"
      backToRoute={trimUriSegments(location.pathname, 1)}
      inviteAction={payload => inviteUser(departmentId, payload)}
      clearAction={clearNewUser}
    />
  );
};

export default DepartmentUserForm;
