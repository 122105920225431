import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { List } from 'immutable';
import { resource, useACL } from '../../common/ACL';
import Icon from '../../common/icons/Icon';
import LoadingIndicator from '../../common/LoadingIndicator';
import Paper, { PaperHeader } from '../../common/paper/Paper';
import TableListItem from '../../common/TableListItem';
import { stripTrailingSlash } from '../../common/uri';
import { useState } from 'react';

const aclRules = {
  canAddCredentialTypesAndCategories: [resource.EVENT, 'edit-credential'],
};

const CredentialTypeList = () => {
  const acl = useACL(aclRules);
  const location = useLocation();
  const navigate = useNavigate();

  const [activeListItem, setActiveListItem] = useState();

  const categoryList = useSelector(state =>
    state.credentialType.categoryList.get('data'),
  );

  const renderEmpty = () => {
    return (
      <Paper>
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>There are no credentials specified for this event.</p>
          <p>Start by first adding a credential category.</p>
        </div>
      </Paper>
    );
  };

  const renderCredentialCategoryListItem = category => (
    <TableListItem
      key={category.get('id')}
      typeCategoryColor="transparent"
      isMenuActive={activeListItem === category.get('id')}
      onMenuToggle={() =>
        activeListItem === category.get('id')
          ? setActiveListItem(null)
          : setActiveListItem(category.get('id'))
      }
      menuItems={[]}
      accordionItems={category.get('credentialTypes') || List()}
      accordionItemOnClick={item => {
        navigate(`${stripTrailingSlash(location.pathname)}/${item.get('id')}`);
      }}
      accordionLink={
        acl.canAddCredentialTypesAndCategories ? (
          <Link
            className="button button--plain"
            to={`${location.pathname}/categories/${category.get('id')}/add`}
          >
            <span>Add Credential</span>
          </Link>
        ) : null
      }
    >
      <div className="content">
        <Link
          className="button button--plain"
          to={`${location.pathname}/categories/edit/${category.get('id')}`}
        >
          <span
            className="credential-category-color"
            style={{ backgroundColor: `${category.get('color')}` }}
          />
          <span>{category.get('name')}</span>
        </Link>
      </div>
    </TableListItem>
  );

  if (!categoryList) {
    return <LoadingIndicator />;
  }

  return (
    <span>
      <Paper>
        <PaperHeader
          title="Credential Types"
          actions={
            acl.canAddCredentialTypesAndCategories ? (
              <Link
                className="button button--plain button--icon"
                to={`${location.pathname}/categories/add`}
              >
                <Icon icon="AddCircle" />
                <span>Add Category</span>
              </Link>
            ) : null
          }
        />
      </Paper>

      {!categoryList.size ? (
        renderEmpty()
      ) : (
        <div className="generic-list__body">
          {categoryList.map(credentialCategory =>
            renderCredentialCategoryListItem(credentialCategory),
          )}
        </div>
      )}
    </span>
  );
};

export default CredentialTypeList;
