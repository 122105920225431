import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { List } from 'immutable';
import KebabMenu from './KebabMenu';
import classNames from 'classnames';
import Tag from './Tag';
import Icon from './icons/Icon';

const TableListItem = ({
  children,
  accordionItemOnClick,
  accordionItems,
  accordionLink,
  isMenuActive,
  menuItems,
  onMenuToggle,
  typeCategoryColor,
}) => {
  const [isAccordionOpen, setAccordionOpen] = useState(true);

  const toggleAccordionOpen = () => {
    setAccordionOpen(isAccordionOpen => !isAccordionOpen);
  };

  const renderSubMenu = () => (
    <KebabMenu isMenuActive={isMenuActive} onMenuToggle={onMenuToggle}>
      {menuItems}
    </KebabMenu>
  );

  const renderAccordion = () => {
    if (!accordionLink && accordionItems && !accordionItems.size) {
      return null;
    }

    const itemClassName = classNames({
      accordion__item: true,
      clickable: !!accordionItemOnClick,
    });

    return (
      <div className="accordion">
        {accordionItems.map((item, i) => (
          <div
            className={itemClassName}
            key={i}
            onClick={
              accordionItemOnClick ? () => accordionItemOnClick(item) : null
            }
          >
            <Tag backgroundColor={typeCategoryColor}>{item.get('name')}</Tag>
            <Icon icon="ArrowRight" />
          </div>
        ))}
        {accordionLink ? (
          <div className="accordion__item">{accordionLink}</div>
        ) : null}
      </div>
    );
  };

  const renderAccordionToggle = () => {
    return (
      <span
        className={
          isAccordionOpen
            ? 'accordion-toggle accordion-toggle--open'
            : 'accordion-toggle'
        }
      >
        <span>{`${accordionItems.size} Item(s)`}</span>
        <Icon icon="Caret" />
      </span>
    );
  };

  if (!children) {
    return null;
  }

  return (
    <div>
      <div className="generic-list__item" onClick={toggleAccordionOpen}>
        {children}
        {accordionLink || (accordionItems && accordionItems.size)
          ? renderAccordionToggle()
          : null}
        {renderSubMenu()}
      </div>
      {isAccordionOpen ? renderAccordion() : null}
    </div>
  );
};

TableListItem.propTypes = {
  children: PropTypes.node.isRequired,
  accordionItemOnClick: PropTypes.func,
  accordionItems: PropTypes.instanceOf(List),
  accordionLink: PropTypes.node,
  isMenuActive: PropTypes.bool.isRequired,
  menuItems: PropTypes.array.isRequired,
  onMenuToggle: PropTypes.func.isRequired,
  typeCategoryColor: PropTypes.string,
};

export default TableListItem;
