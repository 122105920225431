import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { trimUriSegments } from '../common/uri';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import {
  fetchDepartmentGroup,
  updateDepartmentGroup,
} from './department-actions';
import { getCurrentEvent } from '../event/event-selectors';
import { showNotification } from '../notification/notification-actions';
import DepartmentGroupForm from './DepartmentGroupForm';
import { getSelectedDepartmentGroup } from './department-selectors';
import { useEffect } from 'react';

const DepartmentGroupEdit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const departmentGroup = useSelector(state =>
    getSelectedDepartmentGroup(state, { params }),
  );

  const initialValues = !departmentGroup
    ? {}
    : {
        name: departmentGroup.get('name'),
        section: departmentGroup.get('department_type'),
        departments: departmentGroup
          .get('departments')
          .map(d => String(d.get('id')))
          .toArray(),
        credentialTypes: departmentGroup
          .get('credentialTypes')
          .map(d => {
            return { value: d.get('id'), label: d.get('name') };
          })
          .toArray(),
        mealTypes: departmentGroup
          .get('mealTypes')
          .map(d => {
            return { value: d.get('id'), label: d.get('name') };
          })
          .toArray(),
      };

  const departmentGroupsUrl = trimUriSegments(location.pathname, 2);

  useEffect(() => {
    if (!event) return;
    dispatch(fetchDepartmentGroup(event.get('id'), params.departmentGroupId));
  }, [dispatch, event, params.departmentGroupId]);

  const handleSubmit = values => {
    const payload = {
      id: departmentGroup.get('id'),
      name: values.name,
      departments: values.departments.map(d => Number(d)),
      credentialTypes: values.credentialTypes.map(ct => ct.value),
      mealTypes: values.mealTypes.map(mt => mt.value),
    };

    return dispatch(
      updateDepartmentGroup(event.get('id'), values.section, payload),
    ).then(action => {
      if (!action.response.ok) {
        const errors = formatValidationErrors(action.json).toJS();
        errors._error = action.response;
        const message =
          'name' in errors
            ? `Group name: ${payload.name} already in use in this section, no changes made.`
            : 'Error updating department group, no changes made.';
        dispatch(showNotification({ message, status: 'error' }));
        return errors;
      }
      navigate(departmentGroupsUrl);
      dispatch(
        showNotification({
          message: `${action.departmentGroupName} group successfully updated`,
          status: 'success',
        }),
      );
    });
  };

  if (!departmentGroup) return null;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={props => (
        <DepartmentGroupForm
          title="Edit Department Group"
          eventId={event.get('id')}
          departmentGroup={departmentGroup}
          departmentGroupsUrl={departmentGroupsUrl}
          {...props}
        />
      )}
    />
  );
};

export default DepartmentGroupEdit;
