import { clearNewAdmin, inviteAdmin } from './admin-actions';
import UserForm from '../user/UserForm';

const AdminForm = () => {
  return (
    <UserForm
      userType="Admin"
      backToRoute="/superadmins"
      inviteAction={inviteAdmin}
      clearAction={clearNewAdmin}
    />
  );
};
export default AdminForm;
