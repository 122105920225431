import { combineReducers } from 'redux';
import Immutable from 'immutable';
import getApiReducer, { defaultState } from '../common/utils/getApiReducer';
import {
  FETCH_CREDENTIAL_REQUEST_LIST,
  FETCH_CREDENTIAL_REQUESTS_BY_EVENT,
  UPDATE_CREDENTIAL_QUANTITIES,
} from './credential-request-actions';

const requestsForDepartment = (state = defaultState, action) => {
  let newState = getApiReducer(FETCH_CREDENTIAL_REQUEST_LIST)(state, action);

  if (
    action.type === 'SUBMIT_CREDENTIAL_REQUEST_SUCCESS' ||
    action.type === 'ADD_DEPARTMENT_CREDENTIAL_TYPE_SUCCESS' ||
    action.type === 'REMOVE_DEPARTMENT_CREDENTIAL_TYPE_SUCCESS' ||
    action.type === 'UPDATE_CREDENTIAL_TYPES_DEPARTMENTS_SUCCESS' ||
    action.type === 'UPSERT_DEPARTMENT_CREDENTIAL_MAXIMUMS_SUCCESS'
  ) {
    const categoryIndex = newState
      .get('data')
      .findIndex(category =>
        category
          .get('credentialTypes')
          .find(type => type.get('id') === action.credentialTypeId),
      );
    if (categoryIndex < 0) {
      return newState;
    }

    const credentialTypeIndex = newState
      .getIn(['data', categoryIndex])
      .get('credentialTypes')
      .findIndex(type => type.get('id') === action.credentialTypeId);
    if (credentialTypeIndex < 0) {
      return newState;
    }

    const credentialType = newState.getIn([
      'data',
      categoryIndex,
      'credentialTypes',
      credentialTypeIndex,
    ]);
    const credentialTypeJS = credentialType.toJS();

    if (action.type === 'ADD_DEPARTMENT_CREDENTIAL_TYPE_SUCCESS') {
      credentialTypeJS.enabled = true;
      credentialTypeJS.credentialTypeSettings.push({
        credential_type_id: action.credentialTypeId,
        department_id: action.departmentId,
        lock_date_override: 0,
        maximum_requestable: null,
      });
    } else if (action.type === 'REMOVE_DEPARTMENT_CREDENTIAL_TYPE_SUCCESS') {
      credentialTypeJS.enabled = false;
      credentialTypeJS.credentialTypeSettings =
        credentialTypeJS.credentialTypeSettings.filter(
          cts =>
            cts.credential_type_id === action.credentialTypeId &&
            cts.department_id !== action.departmentId,
        );
    } else if (action.type === 'UPDATE_CREDENTIAL_TYPES_DEPARTMENTS_SUCCESS') {
      const ctIndex = credentialTypeJS.credentialTypeSettings.findIndex(
        cts =>
          cts.department_id === action.departmentId &&
          cts.credential_type_id === action.credentialTypeId,
      );
      credentialTypeJS.credentialTypeSettings[ctIndex] = {
        ...credentialTypeJS.credentialTypeSettings[ctIndex],
        ...action.payload,
      };
    } else if (
      action.type === 'UPSERT_DEPARTMENT_CREDENTIAL_MAXIMUMS_SUCCESS'
    ) {
      const credentialIdx = credentialTypeJS.credentials.findIndex(
        c => c.id === action.credentialId,
      );
      const requestMaximumIdx = credentialTypeJS.credentials[
        credentialIdx
      ].requestMaximums.findIndex(
        rm =>
          rm._pivot_credential_id === action.credentialId &&
          rm._pivot_department_id === action.departmentId,
      );
      if (requestMaximumIdx > -1) {
        credentialTypeJS.credentials[credentialIdx].requestMaximums[
          requestMaximumIdx
        ]._pivot_maximum_requestable = action.maximumRequestable;
      } else {
        const requestMaximum = {
          _pivot_credential_id: action.credentialId,
          _pivot_department_id: action.departmentId,
          _pivot_maximum_requestable: action.maximumRequestable,
        };
        credentialTypeJS.credentials[credentialIdx].requestMaximums.push(
          requestMaximum,
        );
      }
    } else {
      // Loop through all credentials and all requests
      credentialTypeJS.credentials = credentialTypeJS.credentials.map(
        credential => {
          // If a request with the matching id exists in the response, replace it, otherwise delete it.
          credential.requests = credential.requests.filter(request =>
            action.json.find(
              updatedRequests => updatedRequests.id === request.id,
            ),
          );
          credential.requests = credential.requests.map(request =>
            action.json.find(
              updatedRequests => updatedRequests.id === request.id,
            ),
          );
          // Lastly, push any new requests to the requests of the credential to which it belongs
          action.json.forEach(updatedRequest => {
            if (
              updatedRequest.credential_id === credential.id &&
              !credential.requests.find(
                existingRequest => existingRequest.id === updatedRequest.id,
              )
            ) {
              credential.requests.push(updatedRequest);
            }
          });
          return credential;
        },
      );
    }
    return newState.setIn(
      ['data', categoryIndex, 'credentialTypes', credentialTypeIndex],
      Immutable.fromJS(credentialTypeJS),
    );
  }

  return newState;
};

const requestsForEvent = (state = defaultState, action) => {
  let newState = getApiReducer(FETCH_CREDENTIAL_REQUESTS_BY_EVENT)(
    state,
    action,
  );

  const addOrUpdateCredentialRequestState = data => {
    const dataToMerge = { ...data };
    delete dataToMerge.department;
    delete dataToMerge.credential;
    delete dataToMerge.correspondingGroupCredRequest;

    let path = ['data'];
    const departmentIndex = newState
      .getIn(path)
      .findIndex(department => department.get('id') === data.department_id);

    if (departmentIndex > -1) {
      path = path.concat([departmentIndex, 'credentialRequests']);
      const changedRequestIndex = newState
        .getIn(path)
        .findIndex(request => request.get('id') === data.id);

      if (changedRequestIndex > -1) {
        path = path.concat(changedRequestIndex);
        newState = newState.setIn(
          path,
          newState.getIn(path).mergeDeep(dataToMerge),
        );
      } else {
        newState = newState.setIn(
          path,
          newState.getIn(path).push(Immutable.toJS(dataToMerge)),
        );
      }
    }
  };

  if (action.type === 'CLEAR_CREDENTIAL_REQUESTS_BY_EVENT') {
    newState = newState.set('loaded', false);
    newState = newState.set('data', []);
  }

  if (action.type === 'UPDATE_CREDENTIAL_QUANTITIES_SUCCESS') {
    addOrUpdateCredentialRequestState(action.json);

    // Add/Update corresponding group credential request
    if (action.json.correspondingGroupCredRequest) {
      addOrUpdateCredentialRequestState(
        action.json.correspondingGroupCredRequest,
      );
    }
  }

  if (
    action.type === 'APPROVE_CREDENTIALS_SUCCESS' ||
    action.type === 'REJECT_CREDENTIALS_SUCCESS'
  ) {
    action.json.fulfilled.forEach(data =>
      addOrUpdateCredentialRequestState(data),
    );
  }

  if (action.type === 'CHANGE_CREDENTIAL_SUCCESS' && action.json) {
    const newRequest = { ...action.json };
    delete newRequest.department;
    delete newRequest.credential;

    const departmentIndex = newState
      .get('data')
      .findIndex(
        department => department.get('id') === newRequest.department_id,
      );

    if (departmentIndex > -1) {
      const existingRequestIndex = newState
        .getIn(['data', departmentIndex, 'credentialRequests'])
        .findIndex(request => request.get('id') === action.requestId);

      // Remove old request ID
      if (existingRequestIndex > -1) {
        newState = newState.deleteIn([
          'data',
          departmentIndex,
          'credentialRequests',
          existingRequestIndex,
        ]);
      }

      // Update or add new request
      const updatedRequestIndex = newState
        .getIn(['data', departmentIndex, 'credentialRequests'])
        .findIndex(request => request.get('id') === newRequest.id);

      if (updatedRequestIndex > -1) {
        newState = newState.setIn(
          ['data', departmentIndex, 'credentialRequests', updatedRequestIndex],
          Immutable.fromJS(newRequest),
        );
      } else {
        newState = newState.updateIn(
          ['data', departmentIndex, 'credentialRequests'],
          requests => requests.push(Immutable.fromJS(newRequest)),
        );
      }

      // Add/Update corresponding group credential requests
      const correspondingGroupCredRequests =
        action.json.correspondingGroupCredRequests;

      correspondingGroupCredRequests?.forEach(credentialRequest =>
        addOrUpdateCredentialRequestState(credentialRequest),
      );
    }
  }

  return newState;
};

const reducers = combineReducers({
  requestsForDepartment,
  requestsForEvent,
  updateCredentialQuantities: getApiReducer(UPDATE_CREDENTIAL_QUANTITIES),
});

export default reducers;
