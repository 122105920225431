import { Field } from 'react-final-form';
import { Map } from 'immutable';
import { isRequired } from 'revalidate';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import PropTypes from 'prop-types';
import ReduxFormsField from '../../forms/ReduxFormsField';

const draftRenderer = new Renderer(defaultBlocks);

const MediaOutletNameSection = ({ className, section }) => (
  <section className={className}>
    <header>
      <div className={`${className}--title`}>Media Outlet Name</div>
    </header>

    <article>
      <div className={`${className}--desc`}>
        <div
          className="draftjs-output"
          dangerouslySetInnerHTML={{
            __html: draftRenderer.convertRawToHTML(
              section.get('content').toJS(),
            ),
          }}
        />
      </div>
      <Field
        name="name"
        component={ReduxFormsField}
        label="Media Outlet Name"
        placeholder="Enter Name"
        required
        validate={isRequired('Media Outlet Name')}
      >
        <input type="text" />
      </Field>
    </article>
  </section>
);

MediaOutletNameSection.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
};

export default MediaOutletNameSection;
