import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ArrowLeft from '../icons/ArrowLeft';
import KebabMenu from '../KebabMenu';

const PaperHeader = ({ actions, backTo, backToDisabled, className, title }) => {
  const isMobile = useSelector(state => state.browser.lessThan.desktop);

  return (
    <div className={classNames('paper__header', className)}>
      {backTo ? (
        <Link
          to={backTo}
          className="paper__header--back"
          disabled={backToDisabled}
        >
          <ArrowLeft />
        </Link>
      ) : null}
      <h3 className="paper__header--title">{title}</h3>
      {actions ? (
        <div className="paper__header--actions">
          {isMobile ? <KebabMenu>{actions}</KebabMenu> : actions}
        </div>
      ) : null}
    </div>
  );
};

PaperHeader.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  backTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  backToDisabled: PropTypes.bool,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  title: PropTypes.string,
};

export default PaperHeader;
