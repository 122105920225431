import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { resource, useACL } from '../common/ACL';
import {
  getCurrentEvent,
  getCurrentEventSettings,
} from '../event/event-selectors';
import { getCurrentDepartment } from '../department/department-selectors';
import departmentIconMap from '../department/department-icon-map';
import departmentTypeList from '../lib/department-types';
import departmentTypeMap from '../lib/department-type-map';
import Logo from '../common/Logo';
import Icon from '../common/icons/Icon';

const promoterNotSelectedPattern = /^\/[^/]+(\/([^/])+)?$/;
const promoterSelectedPattern =
  /^\/promoters\/[^/]+\/[^/]+\/?~?[^/]+(\/(edit|events))?$/;
const eventSelectedPattern =
  /^\/promoters\/[^/]+\/festivals\/[^/]+\/events\/.+$/;

const departmentSelectedPatternString = `^/promoters/[^/]+/festivals/[^/]+/events/[^/]+/(${departmentTypeList.join(
  '|',
)})/[^/]+/.+$`;
const departmentSelectedPattern = new RegExp(departmentSelectedPatternString);

const convertToCamelCase = string =>
  string
    .split('_')
    .map(word => word[0].toUpperCase() + word.substr(1))
    .join('');

const aclRules = {
  canViewStrataAdmins: [resource.STRATA_ADMIN, 'view'],
  canViewPromoterAdmins: [resource.PROMOTER, 'view-promoter-admins'],
  canViewFestivals: [resource.PROMOTER, 'view-festivals'],
  canViewEvent: [resource.EVENT, 'view'],
  canApproveCredential: [resource.EVENT, 'approve-credential'],
  canApproveInternDepartment: [resource.EVENT, 'approve-intern'],
  canApproveIntern: [resource.EVENT, 'approve-credential-intern'],
  canApproveMediaDepartment: [resource.EVENT, 'approve-media'],
  canApproveMedia: [resource.EVENT, 'approve-credential-media'],
  canApproveArtist: [resource.EVENT, 'approve-credential-artist'],
  canApproveFoodVendor: [resource.EVENT, 'approve-credential-food_vendor'],
  canApproveSponsor: [resource.EVENT, 'approve-credential-sponsor'],
  canApproveVendor: [resource.EVENT, 'approve-credential-vendor'],
  canApproveGuestlist: [resource.EVENT, 'approve-credential-guestlist'],
  canApproveProduction: [resource.EVENT, 'approve-credential-production'],
  canEditCredential: [resource.EVENT, 'edit-credential'],
  canViewEventSettings: [resource.EVENT, 'view-settings'],
  canEditEvent: [resource.EVENT, 'edit'],
  canViewArtist: [resource.EVENT, 'view-artist'],
  canViewFoodVendor: [resource.EVENT, 'view-food_vendor'],
  canViewSponsor: [resource.EVENT, 'view-sponsor'],
  canViewVendor: [resource.EVENT, 'view-vendor'],
  canViewIntern: [resource.EVENT, 'view-intern'],
  canViewGuestlist: [resource.EVENT, 'view-guestlist'],
  canViewMedia: [resource.EVENT, 'view-media'],
  canViewProduction: [resource.EVENT, 'view-production'],
  canEditArtist: [resource.EVENT, 'edit-artist'],
  canEditFoodVendor: [resource.EVENT, 'edit-food_vendor'],
  canEditSponsor: [resource.EVENT, 'edit-sponsor'],
  canEditVendor: [resource.EVENT, 'edit-vendor'],
  canEditIntern: [resource.EVENT, 'edit-intern'],
  canEditGuestlist: [resource.EVENT, 'edit-guestlist'],
  canEditMedia: [resource.EVENT, 'edit-media'],
  canEditProduction: [resource.EVENT, 'edit-production'],
  canViewReports: [resource.EVENT, 'view-reports'],
  canViewActionLog: [resource.EVENT, 'view-activity-log'],
  canManageMediaApplication: [resource.EVENT, 'manage-media-application'],
  canManageInternApplication: [resource.EVENT, 'manage-intern-application'],
  canViewAdminCog: [resource.EVENT, 'view-admincog'],
  canBulkUploadArtist: [resource.EVENT, 'bulkcreate-artist'],
  canBulkUploadFoodVendor: [resource.EVENT, 'bulkcreate-food_vendor'],
  canBulkUploadMedia: [resource.EVENT, 'bulkcreate-media'],
  canBulkUploadProduction: [resource.EVENT, 'bulkcreate-production'],
  canBulkUploadGuestlist: [resource.EVENT, 'bulkcreate-guestlist'],
  canBulkUploadSponsor: [resource.EVENT, 'bulkcreate-sponsor'],
  canBulkUploadVendor: [resource.EVENT, 'bulkcreate-vendor'],
  canManageCatering: [resource.EVENT, 'manage-catering'],
  canReconcileOrder: [resource.EVENT, 'reconcile-order'],
  canAdminViewDepartment: [resource.DEPARTMENT, 'admin-view'],
  canAdminEditDepartment: [resource.DEPARTMENT, 'admin-edit'],
  canViewDepartment: [resource.DEPARTMENT, 'view'],
  canEditDepartment: [resource.DEPARTMENT, 'edit'],
  canManageDepartmentCatering: [resource.DEPARTMENT, 'manage-catering'],
  canRequestDepartmentCatering: [resource.DEPARTMENT, 'request-catering'],
  canRequestCredentialDepartment: [resource.DEPARTMENT, 'request-credential'],
  canManageGroupCredentials: [resource.DEPARTMENT, 'manage-group-creds'],
};

const Sidebar = () => {
  const acl = useACL(aclRules);

  const location = useLocation();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );
  const currentEventSettings = useSelector(state =>
    getCurrentEventSettings(state, { params }),
  );

  const renderBack = () => {
    const { promoterSlug, festivalSlug, departmentType, eventSlug } = params;
    let backInfo = {
      label: 'Back',
      path: -1,
    };
    const deptCamelCase =
      departmentType &&
      departmentType
        .split(/-|_/)
        .map(word => word[0].toUpperCase() + word.substring(1))
        .join('');
    if (
      departmentSelectedPattern.test(location.pathname) &&
      deptCamelCase &&
      acl[`canView${deptCamelCase}`]
    ) {
      backInfo = {
        label: `Back to ${departmentTypeMap[departmentType].label.plural}`,
        path: `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/${departmentType}/~list`,
      };
    } else if (promoterSelectedPattern.test(location.pathname)) {
      backInfo = {
        label: 'Back to Promoters',
        path: '/promoters/~list',
      };
    }
    return (
      <Link to={backInfo.path} className="sidebar__back">
        <Icon icon="ArrowLeft" />
        <span className="name">{backInfo.label}</span>
      </Link>
    );
  };

  const renderSectionSpecificTopItems = (departmentPath, name) => {
    const items = [];

    items.push.apply(items, [
      {
        name: departmentTypeMap[name].contacts.label.plural,
        route: `${departmentPath}contacts`,
        showIfUserCan: 'AdminViewDepartment',
      },
    ]);

    return items;
  };

  const renderSectionSpecificBottomItems = (departmentPath, name) => {
    const items = [];

    if (['intern', 'media'].includes(name)) {
      items.push.apply(items, [
        {
          name: 'Application',
          route: `${departmentPath}application`,
          showIfUserCan: 'AdminEditDepartment',
        },
      ]);
    } else {
      items.push.apply(items, [
        {
          name: 'Catering',
          route: `${departmentPath}catering`,
          feature: 'catering',
          section: name,
          showIfUserCan: 'RequestDepartmentCatering',
        },
        {
          name: 'Advance Form',
          route: `${departmentPath}advance-form`,
          section: name,
          showIfUserCan: 'AdminEditDepartment',
          subNav: [
            {
              name: 'Send Advance Form',
              route: `${departmentPath}advance-form/email-link`,
              section: name,
              showIfUserCan: 'AdminEditDepartment',
            },
          ],
        },
        {
          name: 'Application',
          route: `${departmentPath}advance-application`,
          showIfUserCan: 'AdminEditDepartment',
        },
        {
          name: 'Credential Link',
          route: `${departmentPath}group-credentials`,
          feature: 'credentials',
          section: name,
          showIfUserCan: 'ManageGroupCredentials',
          subNav: [
            {
              name: 'Send Credential Link',
              route: `${departmentPath}group-credentials/email-link`,
            },
          ],
        },
        {
          name: 'Users',
          route: `${departmentPath}users`,
          showIfUserCan: 'AdminEditDepartment',
        },
      ]);
    }

    return items;
  };

  const renderDepartmentItems = (departmentPath, name) => [
    ...renderSectionSpecificTopItems(departmentPath, name),
    ...renderSectionSpecificBottomItems(departmentPath, name),
  ];

  const getDepartmentNav = (name, label, icon) => {
    const { departmentSlug, promoterSlug, festivalSlug, eventSlug } = params;
    const camelCaseName = convertToCamelCase(name);
    const departmentPath = `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/${name}/${departmentSlug}/`;
    return {
      name: label,
      section: name,
      icon,
      route: `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/${name}`,
      atPath: eventSelectedPattern,
      showIfUserCan: `View${camelCaseName}`,
      shouldRenderSubNav: !!departmentSlug,
      subNav: [
        ...renderDepartmentItems(departmentPath, name),
        {
          name: 'Action Log',
          route: `${departmentPath}action-log`,
          showIfUserCan: 'ViewActionLog',
        },
        {
          name: 'Settings',
          route: `${departmentPath}settings`,
          showIfUserCan: ['AdminEditDepartment', 'ManageDepartmentCatering'],
        },
      ],
    };
  };

  const getCredentialApprovalsNav = eventPath => {
    const subNavItems = [];

    if (!currentEventSettings) {
      return;
    }

    currentEventSettings.forEach(section => {
      const departmentType = departmentTypeMap[section.get('department_type')];
      const camelCaseName = convertToCamelCase(departmentType.singular);
      subNavItems.push({
        name: departmentType.label.plural,
        route: `${eventPath}/approvals/credentials/${departmentType.singular}`,
        showIfUserCan: `Approve${camelCaseName}`,
        section: departmentType.singular,
      });
    });

    if (subNavItems.length) {
      return {
        name: 'Credentials',
        route: `${eventPath}/approvals/credentials`,
        showIfUserCan: [
          'ApproveCredential',
          'ApproveArtist',
          'ApproveFoodVendor',
          'ApproveGuestlist',
          'ApproveIntern',
          'ApproveMedia',
          'ApproveProduction',
          'ApproveSponsor',
          'ApproveVendor',
        ],
        subNav: subNavItems,
      };
    }
  };

  const getNavData = () => {
    const { promoterSlug, festivalSlug, eventSlug } = params;
    const eventPath = `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}`;

    return [
      {
        name: 'Promoters',
        icon: 'Megaphone',
        route: '/promoters',
        atPath: promoterNotSelectedPattern,
      },
      {
        name: 'Strata Admin',
        icon: 'Person',
        route: '/superadmins',
        showIfUserCan: 'ViewStrataAdmins',
        atPath: promoterNotSelectedPattern,
      },
      {
        name: 'Festivals',
        icon: 'Ticket',
        route: `/promoters/${promoterSlug}/festivals`,
        showIfUserCan: 'ViewFestivals',
        atPath: promoterSelectedPattern,
      },
      {
        name: 'Promoter Admin',
        icon: 'Person',
        route: `/promoters/${promoterSlug}/admins`,
        showIfUserCan: 'ViewPromoterAdmins',
        atPath: promoterSelectedPattern,
      },
      {
        name: 'Dashboard',
        icon: 'Dashboard',
        route: `${eventPath}/dashboard`,
        showIfUserCan: 'ViewEvent',
        atPath: eventSelectedPattern,
      },
      {
        name: 'Approvals',
        icon: 'ListCheck',
        route: `${eventPath}/approvals`,
        showIfUserCan: [
          'ApproveCredential',
          'ApproveArtist',
          'ApproveFoodVendor',
          'ApproveGuestlist',
          'ApproveIntern',
          'ApproveInternDepartment',
          'ApproveMedia',
          'ApproveMediaDepartment',
          'ApproveProduction',
          'ApproveSponsor',
          'ApproveVendor',
        ],
        atPath: eventSelectedPattern,
        subNav: [
          {
            name: 'Media',
            route: `${eventPath}/approvals/media`,
            showIfUserCan: 'ApproveMediaDepartment',
            section: 'media',
          },
          {
            name: 'Interns',
            route: `${eventPath}/approvals/intern`,
            showIfUserCan: 'ApproveInternDepartment',
            section: 'intern',
          },
          {
            name: 'Onsite Requests',
            route: `${eventPath}/approvals/onsite`,
            showIfUserCan: [
              'ApproveCredential',
              'ApproveArtist',
              'ApproveFoodVendor',
              'ApproveGuestlist',
              'ApproveIntern',
              //exception to the rule: 'ApproveMedia',
              'ApproveProduction',
              'ApproveSponsor',
              'ApproveVendor',
            ],
          },
          getCredentialApprovalsNav(eventPath),
        ],
      },
      {
        element: 'hr',
        atPath: eventSelectedPattern,
      },
      {
        name: 'Credentials',
        icon: 'Ticket',
        route: `${eventPath}/credentials`,
        showIfUserCan: 'EditCredential',
        atPath: eventSelectedPattern,
        subNav: [
          {
            name: 'Credential Types',
            route: `${eventPath}/credentials/types`,
          },
          {
            name: 'Periods',
            route: `${eventPath}/credentials/periods`,
          },
        ],
      },
      {
        name: 'Catering',
        icon: 'Food',
        route: `${eventPath}/catering`,
        atPath: eventSelectedPattern,
        showIfUserCan: ['EditEvent', 'ManageCatering'],
        subNav: [
          {
            name: 'Meal Types',
            route: `${eventPath}/catering/types`,
          },
          {
            name: 'Meal Tickets',
            route: `${eventPath}/catering/tickets`,
          },
          {
            name: 'Upload',
            route: `${eventPath}/catering/upload`,
          },
        ],
      },
      {
        element: 'hr',
        atPath: eventSelectedPattern,
      },
      getDepartmentNav('artist', 'Artists', departmentIconMap.artist),
      getDepartmentNav(
        'food_vendor',
        'Food Vendors',
        departmentIconMap.food_vendor,
      ),
      getDepartmentNav('guestlist', 'Guestlist', departmentIconMap.guestlist),
      getDepartmentNav('intern', 'Interns', departmentIconMap.intern),
      getDepartmentNav('media', 'Media', departmentIconMap.media),
      getDepartmentNav(
        'production',
        'Production',
        departmentIconMap.production,
      ),
      getDepartmentNav('sponsor', 'Sponsors', departmentIconMap.sponsor),
      getDepartmentNav('vendor', 'Vendors', departmentIconMap.vendor),
      {
        name: 'Admin Settings',
        icon: 'Cog',
        route: `${eventPath}/settings`,
        atPath: eventSelectedPattern,
        showIfUserCan: 'ViewAdminCog',
        subNav: [
          {
            name: 'Event',
            route: `${eventPath}/settings/edit`,
            showIfUserCan: 'EditEvent',
          },
          {
            name: 'Applications',
            route: `${eventPath}/settings/applications`,
            showIfUserCan: 'EditEvent',
          },
          {
            name: 'Email',
            route: `${eventPath}/settings/email`,
            showIfUserCan: 'EditEvent',
            subNav: [
              {
                name: 'Templates',
                route: `${eventPath}/settings/email/templates`,
              },
              {
                name: 'Bulk Send',
                route: `${eventPath}/settings/email/bulksend`,
              },
            ],
          },
          {
            name: 'Group Permission',
            route: `${eventPath}/settings/department-groups`,
            showIfUserCan: 'EditEvent',
          },
          {
            name: 'Section Admin',
            route: `${eventPath}/settings/section-admin`,
            showIfUserCan: 'ViewEventSettings',
          },
          {
            name: 'Artists',
            route: `${eventPath}/settings/artist`,
            showIfUserCan: 'EditEvent',
            section: 'artist',
            subNav: [
              {
                name: 'Applications',
                route: `${eventPath}/settings/artist/applications`,
              },
            ],
          },
          {
            name: 'Food Vendors',
            route: `${eventPath}/settings/food_vendor`,
            showIfUserCan: 'EditEvent',
            section: 'food_vendor',
            subNav: [
              {
                name: 'Applications',
                route: `${eventPath}/settings/food_vendor/applications`,
              },
            ],
          },
          {
            name: 'Guestlist',
            route: `${eventPath}/settings/guestlist`,
            showIfUserCan: 'EditEvent',
            section: 'guestlist',
          },
          {
            name: 'Interns',
            route: `${eventPath}/settings/intern/applications`,
            showIfUserCan: 'ManageInternApplication',
            section: 'intern',
          },
          {
            name: 'Media',
            route: `${eventPath}/settings/media`,
            showIfUserCan: 'EditEvent',
            section: 'media',
            subNav: [
              {
                name: 'Applications',
                route: `${eventPath}/settings/media/applications`,
                showIfUserCan: 'ManageMediaApplication',
              },
            ],
          },
          {
            name: 'Production',
            route: `${eventPath}/settings/production`,
            showIfUserCan: 'EditEvent',
            section: 'production',
            subNav: [
              {
                name: 'Applications',
                route: `${eventPath}/settings/production/applications`,
              },
            ],
          },
          {
            name: 'Sponsors',
            route: `${eventPath}/settings/sponsor`,
            showIfUserCan: 'EditEvent',
            section: 'sponsor',
            subNav: [
              {
                name: 'Applications',
                route: `${eventPath}/settings/sponsor/applications`,
              },
            ],
          },
          {
            name: 'Vendors',
            route: `${eventPath}/settings/vendor`,
            showIfUserCan: 'EditEvent',
            section: 'vendor',
            subNav: [
              {
                name: 'Applications',
                route: `${eventPath}/settings/vendor/applications`,
              },
            ],
          },
          {
            name: 'Uploads',
            route: `${eventPath}/settings/uploads`,
            showIfUserCan: 'EditEvent',
          },
          {
            name: 'Onsite Requests',
            route: `${eventPath}/settings/onsite`,
            showIfUserCan: 'EditEvent',
          },
          {
            name: 'Pulse Orders',
            route: `${eventPath}/settings/reconcile`,
            showIfUserCan: 'ReconcileOrder',
          },
          {
            name: 'Action Log',
            route: `${eventPath}/settings/action-log`,
            atPath: eventSelectedPattern,
            showIfUserCan: 'ViewActionLog',
          },
          {
            name: 'Reports',
            route: `${eventPath}/settings/reports`,
            atPath: eventSelectedPattern,
            showIfUserCan: 'ViewReports',
          },
        ],
      },
    ];
  };

  const shouldShowNavItem = item => {
    let departmentHasCredentialLinkCredentials = false;
    if (department) {
      if (department.get('summary')) {
        departmentHasCredentialLinkCredentials = department
          .get('summary')
          .some(credential => credential.get('hasGroupLevel') > 0);
      } else if (department.get('credentialRequests')) {
        departmentHasCredentialLinkCredentials = department
          .get('credentialRequests')
          .some(credentialRequest => !credentialRequest.get('requested_for'));
      }
    }

    if (
      !departmentHasCredentialLinkCredentials &&
      item.name === 'Send Credential Link'
    ) {
      return false;
    }

    if (
      department &&
      item.name === 'Send Advance Form' &&
      (!department.get('advanceFormLink') ||
        !department.get('advanceFormLink').get('application_id'))
    ) {
      return false;
    }

    if (
      department &&
      item.name === 'Application' &&
      !department.get('application_id')
    ) {
      return false;
    }

    if (
      event &&
      !event.get('groups_enabled') &&
      item.name === 'Group Permission'
    ) {
      return false;
    }

    if (
      item.departmentListPath &&
      !location.pathname.includes(item.departmentListPath)
    ) {
      return false;
    }

    if (item.showIfUserCan) {
      if (Array.isArray(item.showIfUserCan)) {
        let userCan = false;

        for (const action of item.showIfUserCan) {
          userCan = userCan || acl[`can${action}`];
        }

        if (!userCan) {
          return false;
        }
      } else if (!acl[`can${item.showIfUserCan}`]) {
        return false;
      }
    }

    if (item.atPath && !item.atPath.test(location.pathname)) {
      return false;
    }

    if (item.section) {
      if (!currentEventSettings) {
        return false;
      }

      if (!currentEventSettings.get(item.section)) {
        return false;
      }

      const settingToCheck = item.feature ? item.feature : 'section_enabled';
      return currentEventSettings.getIn([
        item.section,
        'settings',
        settingToCheck,
      ]);
    }

    return true;
  };

  const itemIsActive = item => location.pathname.startsWith(item.route);

  const getActiveTopLevelNavItem = () => {
    const navData = getNavData(params, department, currentEventSettings);
    let candidate = null;
    // Active nav item is the one with the longest route matching the current route
    navData.forEach(navItem => {
      if (
        itemIsActive(navItem) &&
        (!candidate || navItem.route.length > candidate.route.length)
      ) {
        candidate = navItem;
      }
    });
    return candidate;
  };

  const inSectionWithSubNav = () => {
    const activeNavData = getActiveTopLevelNavItem();
    return !!(
      activeNavData &&
      activeNavData.subNav &&
      activeNavData.subNav.length &&
      activeNavData.shouldRenderSubNav !== false
    );
  };

  const renderNavLink = (item, className = '') => {
    if (item.onClick) {
      return (
        <Link
          to="#"
          key={item.name || item.icon}
          onClick={item.onClick}
          className={className}
          title={item.name}
        >
          {item.icon ? <Icon icon={item.icon} /> : null}
          {!item.withSubNav ? <span className="name">{item.name}</span> : null}
        </Link>
      );
    }
    const route =
      typeof item.route === 'function'
        ? item.route(this.props.location.pathname)
        : item.route;
    return (
      <NavLink
        key={item.name + item.route}
        to={route}
        className={className}
        title={item.name}
      >
        {item.icon ? <Icon icon={item.icon} /> : null}
        {!item.withSubNav ? <span className="name">{item.name}</span> : null}
      </NavLink>
    );
  };

  const renderNav = () => {
    const nav = [];
    const withSubNav = inSectionWithSubNav();

    getNavData().forEach((navData, index) => {
      const navDataItem = navData;
      if (!shouldShowNavItem(navDataItem)) {
        return;
      }

      if (withSubNav) {
        navDataItem.withSubNav = true;
      }

      if (navDataItem.element) {
        nav.push(<navData.element key={`${navDataItem.element} - ${index}`} />);
      } else {
        nav.push(renderNavLink(navDataItem));
      }
    });

    const navClassnames = classNames({
      sidebar__navigation: true,
      'sidebar__navigation--condensed': inSectionWithSubNav(),
    });

    return <nav className={navClassnames}>{nav}</nav>;
  };

  const renderSubNav = () => {
    const activeItem = getActiveTopLevelNavItem();
    if (!inSectionWithSubNav()) {
      return null;
    }

    const navLinks = [];

    activeItem.subNav.forEach(item => {
      if (!item) return;
      if (shouldShowNavItem(item)) {
        navLinks.push(
          renderNavLink(
            item,
            itemIsActive(item) && item.subNav ? 'active' : null,
          ),
        );
        if (itemIsActive(item) && item.subNav) {
          item.subNav.forEach(subSubNav => {
            if (shouldShowNavItem(subSubNav)) {
              navLinks.push(renderNavLink(subSubNav, 'sidebar__subsubnav'));
            }
          });
        }
      }
    });

    return (
      <nav className="sidebar__subnav">
        <h4 className="sidebar__subnav-header">{activeItem.name}</h4>
        {navLinks}
      </nav>
    );
  };

  return (
    <div className="sidebar">
      <Link to="/" className="sidebar__logo">
        <Logo />
      </Link>
      {renderBack()}
      <div className="sidebar__nav__wrapper">
        {renderNav()}
        {renderSubNav()}
      </div>
    </div>
  );
};

export default Sidebar;
