const joinElements = (elements, seperator = ',') =>
  elements.reduce((acc, value, index) => {
    acc.push(value);
    if (index !== elements.length - 1) {
      acc.push(seperator);
    }
    return acc;
  }, []);

export default joinElements;
