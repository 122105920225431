import styled from 'styled-components';
import { Footer } from '../../Report.styles';

export const ModalBody = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  svg {
    fill: #4183d7;
    opacity: 0.8;
  }
  button {
    margin-bottom: 0px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  line-height: 18px;
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Input = styled.input`
  display: flex;
  font-family: 'Roboto Condensed';
  color: #201c23;
  font-size: 18px;
  line-height: 24px;
  border: 2px solid #ccc;
  border-radius: 2px;
  padding: 8px 12px;
`;

export const Header = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 3px;
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
`;

export const ModalFooter = styled(Footer)`
  margin: 0px;
  padding: 10px;
  justify-content: center;
  align-items: flex-end;
`;

export const Spacer = styled.div`
  display: flex;
  flex: 1;
`;
