import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { resource, useACL } from '../common/ACL';
import { updateMealTypeSortOrders } from './catering-actions';
import { getCurrentEvent } from '../event/event-selectors';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import LoadingIndicator from '../common/LoadingIndicator';

const aclRules = {
  canAddMealType: [resource.EVENT, 'manage-catering'],
};

const MealTypeList = () => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const mealTypeList = useSelector(state =>
    state.catering.mealTypeList.get('data'),
  );

  const SortableItem = ({ id, name }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
      useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
      <div ref={setNodeRef} style={style} {...attributes}>
        <div className="sortable-meal">
          <div className="drag-handle" {...listeners}>
            <Icon icon="DragHandle" className="draggable" />
          </div>
          <div className="meal-name">{name}</div>
          <div className="edit-meal">
            <Link to={`${location.pathname}/${id}`}>
              <Icon icon="Pencil" />
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const handleDragEnd = ({ active, over }) => {
    const movedMeal = mealTypeList.find(mt => mt.get('id') === active.id);
    const overMeal = mealTypeList.find(mt => mt.get('id') === over.id);
    const oldIndex = mealTypeList.indexOf(movedMeal);
    const newIndex = mealTypeList.indexOf(overMeal);
    const newList = mealTypeList.delete(oldIndex).insert(newIndex, movedMeal);
    const payload = newList.map(type => type.get('id')).toJS();

    dispatch(updateMealTypeSortOrders(event.get('id'), payload));
  };

  const mealTypes = mealTypeList
    .map(meal => ({
      id: meal.get('id'),
      name: meal.get('name'),
    }))
    .toJS();

  if (!mealTypeList) {
    return <LoadingIndicator />;
  }

  return (
    <div className="paper__wrapper">
      <Paper>
        <PaperHeader
          title="Meal Types"
          actions={
            acl.canAddMealType ? (
              <Link
                className="button button--plain button--icon"
                to={`${location.pathname}/add`}
              >
                <Icon icon="AddCircle" />
                <span>Add Meal</span>
              </Link>
            ) : null
          }
        />
      </Paper>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={mealTypes}
          strategy={verticalListSortingStrategy}
        >
          {mealTypes.map(mealType => (
            <SortableItem
              key={mealType.id}
              id={mealType.id}
              name={mealType.name}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default MealTypeList;
