import client from '../client';
import getApplicationActions from '../common/application/get-application-actions';

const actions = getApplicationActions('advance');

export const GET_ADVANCE_APPLICATIONS = actions.GET_ADVANCE_APPLICATIONS;
export const getAdvanceApplications = actions.getApplications;

export const SAVE_ADVANCE_APPLICATION = actions.SAVE_ADVANCE_APPLICATION;
export const saveAdvanceApplication = actions.saveApplication;

export const DELETE_ADVANCE_APPLICATION = actions.DELETE_ADVANCE_APPLICATION;
export const deleteAdvanceApplication = actions.deleteApplication;

export const CREATE_ADVANCE_APPLICATION_SECTION =
  actions.CREATE_ADVANCE_APPLICATION_SECTION;
export const createAdvanceApplicationSection = actions.createApplicationSection;

export const CREATE_OR_UPDATE_ADVANCE_APPLICATION_SECTION =
  actions.CREATE_OR_UPDATE_ADVANCE_APPLICATION_SECTION;
export const createOrUpdateAdvanceApplicationSection =
  actions.createOrUpdateApplicationSection;

export const UPDATE_ADVANCE_APPLICATION_SECTION =
  actions.UPDATE_ADVANCE_APPLICATION_SECTION;
export const updateAdvanceApplicationSection = actions.updateApplicationSection;

export const DELETE_ADVANCE_APPLICATION_SECTION =
  actions.DELETE_ADVANCE_APPLICATION_SECTION;
export const deleteAdvanceApplicationSection = actions.deleteApplicationSection;

export const GET_ADVANCE_APPLICATION_PUBLIC =
  actions.GET_ADVANCE_APPLICATION_PUBLIC;
export const getAdvanceApplicationPublic = actions.getApplicationPublic;

export const UPDATE_ADVANCE_APPLICATION_SECTION_PUBLIC =
  actions.UPDATE_ADVANCE_APPLICATION_SECTION_PUBLIC;
export const updateAdvanceApplicationSectionPublic =
  actions.updateApplicationSectionPublic;

export const UPDATE_ADVANCE_APPLICATION_SECTION_SORT_ORDERS =
  actions.UPDATE_ADVANCE_APPLICATION_SECTION_SORT_ORDERS;
export const updateAdvanceApplicationSectionSortOrders =
  actions.updateApplicationSectionSortOrders;

export const UPDATE_ADVANCE_APPLICATION_PUBLIC =
  actions.UPDATE_ADVANCE_APPLICATION_PUBLIC;
export const updateAdvanceApplicationPublic = actions.updateApplicationPublic;

export const GET_ADVANCE_APPLICATION_EMAIL_TYPES =
  actions.GET_ADVANCE_APPLICATION_EMAIL_TYPES;
export const getAdvanceApplicationEmailTypes = actions.getApplicationEmailTypes;

//advance application specific actions
//get department info action
export const GET_ADVANCE_APPLICATION_DEPARTMENT_INFO =
  'GET_ADVANCE_APPLICATION_DEPARTMENT_INFO';
export const getAdvanceApplicationDepartmentInfo =
  departmentAdvanceLinkToken => ({
    type: 'GET_ADVANCE_APPLICATION_DEPARTMENT_INFO',
    promise: client.get(
      `advance-applications/department-info/${departmentAdvanceLinkToken}`,
    ),
  });

export const GET_ADVANCE_APPLICATION_RESPONSES =
  'GET_ADVANCE_APPLICATION_RESPONSES';
export const getAdvanceApplicationResponses = departmentAdvanceLinkToken => ({
  type: 'GET_ADVANCE_APPLICATION_RESPONSES',
  promise: client.get(
    `departments/${departmentAdvanceLinkToken}/advance-application`,
  ),
});

export const GET_ADVANCE_APPLICATION_CATERING_AVAILABILITY =
  'GET_ADVANCE_APPLICATION_CATERING_AVAILABILITY';
export function getAdvanceApplicationCateringAvailability(
  eventId,
  appId,
  departmentAdvanceLinkToken,
) {
  let route = `advance-applications/catering-availability/${appId}`;
  if (departmentAdvanceLinkToken) {
    route = `advance-applications/catering-availability/event/${eventId}/department/${departmentAdvanceLinkToken}`;
  }
  return {
    type: 'GET_ADVANCE_APPLICATION_CATERING_AVAILABILITY',
    promise: client.get(route),
  };
}

export const GET_ADVANCE_APPLICATION_AVAILABLE_CREDENTIALS =
  'GET_ADVANCE_APPLICATION_AVAILABLE_CREDENTIALS';
export function getAdvanceApplicationAvailableCredentials(
  eventId,
  departmentType,
) {
  return {
    type: `GET_ADVANCE_APPLICATION_AVAILABLE_CREDENTIALS`,
    eventId,
    promise: client.get(
      `events/${eventId}/advance-application/available-credentials/${departmentType}`,
    ),
  };
}

export const GET_ADVANCE_FORM_LINK_EMAIL_TEMPLATES =
  'GET_ADVANCE_FORM_LINK_EMAIL_TEMPLATES';
export function getAdvanceApplicationEmailTemplates(eventId, section) {
  return {
    type: `GET_ADVANCE_FORM_LINK_EMAIL_TEMPLATES`,
    promise: client.get(
      `events/${eventId}/email/templates?emailType=advance_form_link&section=${section}`,
    ),
  };
}
