import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field } from 'react-final-form';
import { resource, useACL } from '../common/ACL';
import { getCurrentPromoter } from './promoter-selectors';
import { clearNewPromoterAdmin, invitePromoterAdmin } from './promoter-actions';
import CheckboxBoolean from '../common/forms/CheckboxBoolean';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import UserForm from '../user/UserForm';

const aclRules = {
  canCreateAutoApprover: [resource.USER, 'make-auto-approver'],
};

const PromoterAdminForm = () => {
  const acl = useACL(aclRules);

  const params = useParams();
  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const promoterId = promoter?.get('id');

  const _invitePromoterAdmin = useCallback(
    values => {
      return invitePromoterAdmin(promoterId, values);
    },
    [promoterId],
  );

  if (!promoter) {
    return null;
  }

  return (
    <UserForm
      inviteAction={_invitePromoterAdmin}
      clearAction={clearNewPromoterAdmin}
      backToRoute={`/promoters/${params.promoterSlug}/admins`}
      userType="Admin"
      instructions={
        "Promoter Admins will be granted full, unrestricted access to all Festivals and Events added to this Promoter's account. This user will be the only user type who has access to each event's settings."
      }
      additionalFields={
        acl.canCreateAutoApprover ? (
          <Field
            name="auto_approve"
            component={ReduxFormsFieldNoLabel}
            required
          >
            <CheckboxBoolean id="auto-approve" label="Allow Auto-Approval" />
          </Field>
        ) : null
      }
    />
  );
};

export default PromoterAdminForm;
