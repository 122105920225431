import React from 'react';

function H3() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <path d="M9.275,13.977H7.224V9.711h-4v4.266H1.174V4.023H3.225V8.056h4V4.023H9.275Z" />
      <path d="M12.164,8.125h1.053a1.484,1.484,0,0,0,1.114-.376,1.379,1.379,0,0,0,.362-1,1.221,1.221,0,0,0-.358-.937,1.4,1.4,0,0,0-.988-.335,1.457,1.457,0,0,0-.95.311,1,1,0,0,0-.383.81H10.038a2.437,2.437,0,0,1,.42-1.4,2.81,2.81,0,0,1,1.176-.967A3.926,3.926,0,0,1,13.3,3.886a3.722,3.722,0,0,1,2.475.755,2.578,2.578,0,0,1,.9,2.082,2.093,2.093,0,0,1-.417,1.258,2.711,2.711,0,0,1-1.094.882,2.479,2.479,0,0,1,1.255.9,2.453,2.453,0,0,1,.413,1.422,2.619,2.619,0,0,1-.968,2.126,3.888,3.888,0,0,1-2.56.8,3.685,3.685,0,0,1-2.437-.786,2.566,2.566,0,0,1-.947-2.078H11.89a1.142,1.142,0,0,0,.421.916,1.544,1.544,0,0,0,1.035.355,1.554,1.554,0,0,0,1.1-.372,1.285,1.285,0,0,0,.4-.988q0-1.49-1.641-1.491H12.164Z" />
    </svg>
  );
  /* eslint-enable */
}

export default H3;
