import { createSelector } from 'reselect';

const getPulseTagsCount = (department, request) => {
  if (!request) {
    return 0;
  }

  let pulseOrders = department.get('pulseOrders');
  if (!pulseOrders) {
    return 0;
  }

  let pulseTags = pulseOrders.flatMap(pulseOrder =>
    pulseOrder.get('pulseTags'),
  );
  if (!pulseTags) {
    return 0;
  }

  return pulseTags.filter(
    pulseTag =>
      pulseTag.get('pulse_credential_id') ===
      request.getIn(['credential', 'pulse_credential_id']),
  ).size;
};

export const getAvailableCredentialTypes = createSelector(
  props => props.department,
  props => props.requests,
  (department, requests) =>
    requests
      .filter(request => {
        const pulseTagsCount = getPulseTagsCount(department, request);
        return (
          request.get('requested_for') === null &&
          request.get('quantity_approved') - pulseTagsCount > 0
        );
      })
      .map(request => request.getIn(['credential', 'credentialType']))
      .groupBy(credentialType => credentialType.get('name'))
      .map(groupedCredentialTypes => groupedCredentialTypes.first()),
);

export const getGroupCredentialCategories = createSelector(
  requests => requests,
  requests => {
    if (requests) {
      return requests
        .map(request => {
          const category = request.getIn([
            'credential',
            'credentialType',
            'category',
          ]);
          return {
            value: category.get('id'),
            label: category.get('name'),
            color: category.get('color'),
          };
        })
        .groupBy(({ label }) => label)
        .map(groupedCategories => groupedCategories.first())
        .sortBy(category => category.label)
        .toList()
        .toJS();
    }
    return [];
  },
);
