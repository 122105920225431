import { combineReducers } from 'redux';
import { FETCH_OPTIONS, GET_REPORT_DATA } from './reports-actions';
import Immutable from 'immutable';
import getApiReducer from '../common/utils/getApiReducer';

const viewData = (state = Immutable.Map(), action) => {
  let newState = state;
  if (action.type === `${GET_REPORT_DATA}_SUCCESS`) {
    const report = getApiReducer(GET_REPORT_DATA)(state, action);
    const title = report.getIn(['data', 'report_type']);
    newState = newState.setIn(
      [action.resource, action.resourceId, title],
      report,
    );
  }
  return newState;
};

const options = (state = new Immutable.Map(), action) => {
  let newState = state;
  if (action.type === `${FETCH_OPTIONS}_SUCCESS`) {
    newState = state.setIn(
      [action.resource, action.resourceId],
      Immutable.fromJS(action.json),
    );
  }
  return newState;
};

const reducers = combineReducers({
  viewData,
  options,
});

export default reducers;
