import { useContext } from 'react';
import { FormSectionContext } from './FormSectionContext';
import { useForm } from 'react-final-form';
import { get } from 'lodash';

const FormSection = ({ name, children }) => {
  const form = useForm();
  const prefix = useContext(FormSectionContext);

  const formSectionName = prefix ? `${prefix}.${name}` : name;

  const change = (name, value) => {
    form.change(`${formSectionName}.${name}`, value);
  };

  const getValue = name =>
    get(form.getState().values, `${formSectionName}.${name}`);

  return (
    <FormSectionContext.Provider value={formSectionName}>
      {typeof children === 'function'
        ? children({ change, getValue })
        : children}
    </FormSectionContext.Provider>
  );
};

export default FormSection;
