import config from '../../config';

const validationMessages = config.validationMessages;

const translateErrors = errors => {
  let messages = false;
  if (errors) {
    messages = errors.map(error => validationMessages[error] || error);
  }
  return messages;
};

export default translateErrors;
