import { createSelector } from 'reselect';
import { List } from 'immutable';
import { getCurrentFestival } from '../festival/festival-selectors';
import { getCurrentPromoter } from '../promoter/promoter-selectors';

export const getCurrentEvent = createSelector(
  getCurrentFestival,
  (_, props) => props.params.eventSlug,
  (festival, eventSlug) =>
    festival
      ? festival.get('events').find(event => event.get('slug') === eventSlug)
      : null,
);

export const getCurrentEventAdmin = createSelector(
  state => state.event.adminList.get('data'),
  (_, props) => props.params.userId,
  (userList, userId) =>
    userList ? userList.find(user => user.get('id') === userId) : null,
);

export const getCurrentEventApplications = createSelector(
  getCurrentEvent,
  event => event?.get('applications'),
);

export const getCurrentEventSectionApplications = createSelector(
  getCurrentEventApplications,
  (_, props) => props.section,
  (applications, section) =>
    applications?.filter(
      application => application.get('department_type') === section,
    ),
);

export const getCurrentEventSettings = createSelector(
  getCurrentEvent,
  state => state.event.eventSettings,
  (event, eventSettings) =>
    eventSettings?.getIn([event?.get('id'), 'data']) ?? null,
);

export const getEnabledEventSections = createSelector(
  getCurrentEventSettings,
  eventSettings =>
    Array.from(
      eventSettings
        ?.filter(setting => setting.getIn(['settings', 'section_enabled']))
        .keys() ?? [],
    ),
);

export const getCurrentEventFlags = createSelector(
  getCurrentEventSettings,
  eventSettings => {
    let flags = {};
    if (eventSettings) {
      flags = eventSettings.reduce((acc, setting) => {
        acc[setting.get('department_type')] = {
          section_enabled: setting.getIn(['settings', 'section_enabled']),
          catering: setting.getIn(['settings', 'catering']),
          credentials: setting.getIn(['settings', 'credentials']),
        };
        return acc;
      }, {});
    }

    return flags;
  },
);

export const getEmailTypes = createSelector(
  state => state.event.emailTypes,
  emailTypes =>
    emailTypes?.get('data')?.sortBy(emailType => emailType.get('label')) ??
    null,
);

export const getEmailTypesLoaded = createSelector(
  state => state.event.emailTypes,
  emailTypes => emailTypes?.get('loaded'),
);

export const getEmailTypeSamples = createSelector(
  getCurrentPromoter,
  getCurrentFestival,
  (_, props) => props.emailType,
  (promoter, festival, emailType) => {
    return (
      emailType
        ?.get('samples')
        ?.filter(sample => {
          if (
            !!sample.get('festival_id') &&
            sample.get('festival_id') === festival.get('id')
          )
            return true;

          if (
            !!sample.get('promoter_id') &&
            sample.get('promoter_id') === promoter.get('id')
          )
            return true;

          return !sample.get('promoter_id') && !sample.get('festival_id');
        })
        .sortBy(sample => sample.get('name')) ?? List()
    );
  },
);

export const getCurrentEmailType = createSelector(
  getEmailTypes,
  (_, props) => props.params.emailType,
  (emailTypes, type) =>
    emailTypes?.find(emailType => emailType.get('type') === type) ?? null,
);

const getEventEmailTemplates = createSelector(
  getCurrentEvent,
  state => state.event.emailTemplates,
  (event, emailTemplates) =>
    event ? emailTemplates.get(event.get('id')) : null,
);

export const getEmailTemplates = createSelector(
  getEventEmailTemplates,
  emailTemplates => emailTemplates?.get('data'),
);

export const getEmailTemplatesLoaded = createSelector(
  getEventEmailTemplates,
  emailTemplates => emailTemplates?.get('loaded'),
);

export const getCurrentEmailTemplate = createSelector(
  getEmailTemplates,
  (_, props) => props.params.emailTemplateId,
  (emailTemplates, emailTemplateId) =>
    emailTemplates
      ?.find(
        emailTemplate => String(emailTemplate.get('id')) === emailTemplateId,
      )
      ?.toJS(),
);

export const getBulkSendEmailTypes = createSelector(
  getEmailTemplates,
  (_, props) => props.includeDisabled,
  (emailTemplates, includeDisabled) =>
    emailTemplates
      ?.filter(
        emailTemplate =>
          emailTemplate.getIn(['emailType', 'can_bulk_send']) &&
          (includeDisabled ? true : emailTemplate.get('enabled')),
      )
      .map(emailTemplate => emailTemplate.get('emailType'))
      .toSet()
      .sortBy(emailType => emailType.get('label')),
);

export const getEmailTypeSections = createSelector(
  getEnabledEventSections,
  getEmailTemplates,
  (_, props) => props.emailTypeId,
  (_, props) => props.includeDisabled,
  (eventSections, emailTemplates, emailTypeId, includeDisabled) =>
    emailTemplates
      ?.filter(emailTemplate => {
        if (String(emailTemplate.get('email_type_id')) !== String(emailTypeId))
          return false;
        return includeDisabled ? true : emailTemplate.get('enabled');
      })
      .flatMap(emailTemplate => emailTemplate.get('sections'))
      .toSet()
      .intersect(eventSections)
      .sortBy(section => section)
      .toArray(),
);

export const getEmailTypePeriods = createSelector(
  getCurrentEvent,
  getEmailTemplates,
  (_, props) => props.emailTypeId,
  (_, props) => props.includeDisabled,
  (event, emailTemplates, emailTypeId, includeDisabled) => {
    const emailTypePeriods = emailTemplates
      ?.filter(emailTemplate => {
        if (String(emailTemplate.get('email_type_id')) !== String(emailTypeId))
          return false;
        return includeDisabled ? true : emailTemplate.get('enabled');
      })
      .flatMap(emailTemplate =>
        emailTemplate.get('periods').map(period => String(period.get('id'))),
      )
      .toSet()
      .toArray();

    return event
      ?.get('periods')
      .filter(period => emailTypePeriods?.includes(String(period.get('id'))))
      .sortBy(period => period.get('start_date'));
  },
);

export const getEmailSettings = createSelector(
  getCurrentEvent,
  state => state.event.emailSettings,
  (event, emailSettings) => {
    if (event) {
      const eventId = event.get('id');
      const emailSettingsData = emailSettings.getIn([eventId, 'data']);
      if (emailSettingsData && emailSettingsData.size > 0) {
        let emailSettingsValues = { values: [], emailTypes: null };
        const emailTypes = new Set();
        emailSettingsData.forEach(ele => {
          const type = ele.get('emailType').get('type');
          const emailField = ele.get('field_name');
          const section = ele.get('department_type');
          const value = {};
          value[`${emailField}`] = ele.get('template')?.toJSON() ?? null;
          value[`${emailField}_id`] = ele.get('id');
          value.email_type_id = ele.get('email_type_id');
          value.periods = ele.get('periods')
            ? ele.get('periods').split(',')
            : [];
          value.type = type;
          value.section = section;

          if (ele.getIn(['emailAttachment', 'file', 'key'])) {
            value.fileKey = ele.getIn(['emailAttachment', 'file', 'key']);
            value.fileLocation = ele.getIn([
              'emailAttachment',
              'file',
              'location',
            ]);
          }

          const existingValueIndex = emailSettingsValues.values.findIndex(
            element =>
              element.type === value.type &&
              element.section === value.section &&
              JSON.stringify(element.periods) === JSON.stringify(value.periods),
          );
          if (existingValueIndex >= 0) {
            emailSettingsValues.values[existingValueIndex] = Object.assign(
              emailSettingsValues.values[existingValueIndex],
              value,
            );
          } else {
            emailSettingsValues.values.push(value);
          }

          emailTypes.add(type);
        });
        emailSettingsValues.emailTypes = [...emailTypes.values()];
        return emailSettingsValues;
      }
    }
  },
);

export const getEventOnsiteSettings = createSelector(
  getCurrentEvent,
  state => state.event.eventOnsiteSettings,
  (event, settings) =>
    event ? settings?.getIn([event.get('id'), 'data']) : null,
);

export const getEventOnsiteRequestTypes = createSelector(
  getCurrentEvent,
  state => state.event.eventOnsiteRequestTypes,
  (event, types) => (event ? types?.getIn([event.get('id'), 'data']) : null),
);

export const getEventPendingRequests = createSelector(
  getCurrentEvent,
  state => state.event.pendingRequestsForEvent,
  (event, pendingRequests) =>
    event
      ? pendingRequests
          .getIn([event.get('id'), 'data'])
          ?.map(request => request.get('type'))
      : null,
);
