import { createValidator } from 'revalidate';
import get from 'lodash/get';

export default function dateNotAfter(comparePath, compareField) {
  return createValidator(
    message => (value, values) => {
      const compareValue = get(values, comparePath);

      if (value && compareValue && value.isAfter(compareValue)) {
        return message;
      }
    },
    field => `${field} cannot be after ${compareField}`,
  );
}
