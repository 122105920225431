import client from '../client';

export const SEARCHING = 'SEARCHING';
export const searching = isSearching => ({
  type: SEARCHING,
  payload: isSearching,
});

export const FETCH_DEPARTMENT_SLUGS = 'FETCH_DEPARTMENT_SLUGS';
export const fetchDepartmentSlugs = eventId => ({
  type: FETCH_DEPARTMENT_SLUGS,
  promise: client.get(`search/${eventId}/fetch-slugs`),
});

export const WRITE_QUERY = 'WRITE_QUERY';
export const writeQuery = eventId => ({
  type: WRITE_QUERY,
  promise: client.get(`search/${eventId}/write`),
});

export const SET_DROPDOWN_RESULTS = 'SET_DROPDOWN_RESULTS';
export const setDropdownResults = (eventId, searchResults) => ({
  type: SET_DROPDOWN_RESULTS,
  payload: searchResults,
});

export const SET_NEXT_PAGE_GROUP = 'SET_NEXT_PAGE_GROUP';
export const setNextPageGroup = (eventId, payload) => ({
  type: SET_NEXT_PAGE_GROUP,
  payload,
});

export const CLEAR_DROPDOWN_RESULTS = 'CLEAR_DROPDOWN_RESULTS';
export const clearDropdownResults = () => ({
  type: CLEAR_DROPDOWN_RESULTS,
});
