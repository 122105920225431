import { useId } from 'react';
import { Checkbox } from 'synfrastructure';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import without from 'lodash/without';
import ToggleScaffold from './ToggleScaffold';

const CheckboxGroup = ({
  className,
  disabled = false,
  onChange,
  options = [],
  value = [],
}) => {
  const id = useId();

  const _onChange = selectedValue => {
    let newValue = Array.isArray(value) ? value.slice() : [];

    if (newValue.includes(selectedValue)) {
      newValue = without(newValue, selectedValue);
    } else {
      newValue.push(selectedValue);
    }

    if (!disabled) {
      onChange(newValue);
    }
  };

  return (
    <div className={classNames('checkboxes', className)}>
      {options.map((option, index) => (
        <ToggleScaffold key={option.value} label={option.label}>
          {option.tooltip && (
            <Tooltip
              id={`${id}-tooltip-${index}`}
              content={option.tooltip}
              offset={0}
            />
          )}
          {option.tooltip && (
            <span data-tooltip-id={`${id}-tooltip-${index}`}>
              <Checkbox
                id={`${id}-checkbox-${index}`}
                value={option.value}
                checked={value.indexOf(option.value) > -1}
                onChange={() => _onChange(option.value)}
              />
            </span>
          )}
          {!option.tooltip && (
            <Checkbox
              id={`${id}-checkbox-${index}`}
              value={option.value}
              checked={value.indexOf(option.value) > -1}
              onChange={() => _onChange(option.value)}
            />
          )}
        </ToggleScaffold>
      ))}
    </div>
  );
};

CheckboxGroup.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      value: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.array]),
};

export default CheckboxGroup;
