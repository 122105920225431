import React from 'react';

function H1() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      width="18"
      viewBox="0 0 18 18"
    >
      <path d="M5.948,15.689A3.034,3.034,0,0,1,3.487,12.51V11.15q0-1.443-1.217-1.442V8.292q1.189,0,1.217-1.36V5.483A3.2,3.2,0,0,1,4.1,3.439,3.469,3.469,0,0,1,5.948,2.311l.383,1.1a1.445,1.445,0,0,0-.786.667,2.805,2.805,0,0,0-.28,1.316V6.83A2.225,2.225,0,0,1,4.041,9a2.245,2.245,0,0,1,1.224,2.181v1.449q.034,1.6,1.066,1.955Z" />
      <path d="M11.668,14.582q1.04-.369,1.066-1.982V11.15A2.176,2.176,0,0,1,13.986,9a2.2,2.2,0,0,1-1.252-2.181V5.394Q12.708,3.8,11.668,3.412l.383-1.1a3.444,3.444,0,0,1,1.84,1.118,3.2,3.2,0,0,1,.621,2.034v1.47q.029,1.36,1.217,1.36V9.708q-1.216,0-1.217,1.429V12.62a3.022,3.022,0,0,1-2.461,3.069Z" />
    </svg>
  );
  /* eslint-enable */
}

export default H1;
