const RoleConstants = {
  STRATA_ADMIN: 'strata_admin',
  PROMOTER_ADMIN: 'promoter_admin',
  EVENT_ADMIN: 'event_admin',
  EVENT_COORDINATOR: 'event_coordinator',
  DEPARTMENT_USER: 'department_user',
  EVENT_USERS_VIEW: 'event_users_view',

  CREDENTIAL_EDIT: 'credential_edit',

  CREDENTIAL_APPROVE: 'credential_approve',

  CATERING_ADMIN: 'catering_admin',
  REPORTS_VIEW: 'reports_view',

  ARTIST_VIEW: 'artist_view',
  ARTIST_EDIT: 'artist_edit',
  ARTIST_CREDENTIAL: 'artist_credential',
  ARTIST_APPROVE: 'artist_approve',
  ARTIST_REPORT: 'artist_report',
  ARTIST_CATERING: 'artist_catering',

  FOOD_VENDOR_VIEW: 'food_vendor_view',
  FOOD_VENDOR_EDIT: 'food_vendor_edit',
  FOOD_VENDOR_CREDENTIAL: 'food_vendor_credential',
  FOOD_VENDOR_APPROVE: 'food_vendor_approve',
  FOOD_VENDOR_REPORT: 'food_vendor_report',
  FOOD_VENDOR_CATERING: 'food_vendor_catering',

  GUESTLIST_VIEW: 'guestlist_view',
  GUESTLIST_EDIT: 'guestlist_edit',
  GUESTLIST_CREDENTIAL: 'guestlist_credential',
  GUESTLIST_APPROVE: 'guestlist_approve',
  GUESTLIST_REPORT: 'guestlist_report',
  GUESTLIST_CATERING: 'guestlist_catering',

  INTERN_VIEW: 'intern_view',
  INTERN_EDIT: 'intern_edit',
  INTERN_CREDENTIAL: 'intern_credential',
  INTERN_APPROVE: 'intern_approve',
  INTERN_REPORT: 'intern_report',
  INTERN_CATERING: 'intern_catering',

  MEDIA_VIEW: 'media_view',
  MEDIA_EDIT: 'media_edit',
  MEDIA_CREDENTIAL: 'media_credential',
  MEDIA_APPROVE: 'media_approve',
  MEDIA_APPROVE_DEPARTMENT: 'media_approve_department',
  MEDIA_REPORT: 'media_report',
  MEDIA_CATERING: 'media_catering',

  PRODUCTION_VIEW: 'production_view',
  PRODUCTION_EDIT: 'production_edit',
  PRODUCTION_CREDENTIAL: 'production_credential',
  PRODUCTION_APPROVE: 'production_approve',
  PRODUCTION_REPORT: 'production_report',
  PRODUCTION_CATERING: 'production_catering',

  SPONSOR_VIEW: 'sponsor_view',
  SPONSOR_EDIT: 'sponsor_edit',
  SPONSOR_CREDENTIAL: 'sponsor_credential',
  SPONSOR_APPROVE: 'sponsor_approve',
  SPONSOR_REPORT: 'sponsor_report',
  SPONSOR_CATERING: 'sponsor_catering',

  VENDOR_VIEW: 'vendor_view',
  VENDOR_EDIT: 'vendor_edit',
  VENDOR_CREDENTIAL: 'vendor_credential',
  VENDOR_APPROVE: 'vendor_approve',
  VENDOR_REPORT: 'vendor_report',
  VENDOR_CATERING: 'vendor_catering',
};

export default RoleConstants;
