import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 54px;
  border: 1px solid #ccc;
  margin-bottom: 12px;
  background-color: #fff;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
  margin-left: 20px;
`;

export const Description = styled.div`
  display: flex;
  opacity: 0.5;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 16px;
`;

export const Button = styled.div`
  cursor: pointer;
`;
