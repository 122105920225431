import PropTypes from 'prop-types';

const Render = props => {
  if (props.if) {
    if (Array.isArray(props.children)) {
      return <span>{props.children}</span>;
    }
    return props.children;
  }
  return null;
};

Render.propTypes = {
  if: PropTypes.bool.isRequired,
};

export default Render;
