import { Map } from 'immutable';
import { composeValidators, isRequired } from 'revalidate';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import isValidEmail from '../../validate/is-valid-email';
import PropTypes from 'prop-types';
import ReduxFormsField from '../../forms/ReduxFormsField';
import FormSection from '../../forms/FormSection';
import FormField from '../../forms/FormField';
import isValidPhone from '../../validate/is-valid-phone';
import phoneFilter from '../../filters/phone-filter';

const draftRenderer = new Renderer(defaultBlocks);

const EditorInfoSection = ({ className, section }) => (
  <section className={className}>
    <header>
      <div className={`${className}--title`}>
        Editor or Program Director Information
      </div>
    </header>

    <article>
      <div className={`${className}--desc`}>
        <div
          className="draftjs-output"
          dangerouslySetInnerHTML={{
            __html: draftRenderer.convertRawToHTML(
              section.get('content').toJS(),
            ),
          }}
        />
      </div>
      <FormSection name="editorInfo">
        <FormField
          name="name"
          component={ReduxFormsField}
          label="Editor Full Name"
          placeholder="Enter Full Name"
          required
          validate={isRequired('Editor Full Name')}
        >
          <input type="text" />
        </FormField>
        <FormField
          name="email"
          component={ReduxFormsField}
          label="Editor Email Address"
          placeholder="Enter Email Address"
          required
          validate={composeValidators(
            isRequired,
            isValidEmail,
          )('Editor Email Address')}
        >
          <input type="text" />
        </FormField>
        <FormField
          name="phone"
          component={ReduxFormsField}
          label="Editor Phone Number"
          placeholder="Enter Phone Number"
          format={phoneFilter}
          parse={phoneFilter}
          required
          validate={composeValidators(
            isRequired,
            isValidPhone,
          )('Editor Phone Number')}
        >
          <input type="text" />
        </FormField>
      </FormSection>
    </article>
  </section>
);

EditorInfoSection.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
};

export default EditorInfoSection;
