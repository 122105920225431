import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { stripTrailingSlash } from '../common/uri';

const aclRules = {
  canApproveCredential: [resource.EVENT, 'abstractapprove'],
  canApproveIntern: [resource.EVENT, 'approve-intern'],
  canApproveMedia: [resource.EVENT, 'approve-media'],
};

const ApprovalsRedirector = () => {
  const acl = useACL(aclRules);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let path = null;

    if (acl.canApproveCredential) path = 'credentials';
    else if (acl.canApproveMedia) path = 'media';
    else if (acl.canApproveIntern) path = 'intern';

    if (path)
      navigate(`${stripTrailingSlash(location.pathname)}/${path}`, {
        replace: true,
      });
  }, [acl, location.pathname, navigate]);

  return null;
};

export default ApprovalsRedirector;
