import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { resource, useACL } from '../common/ACL';
import {
  contactHasCredentialRequest,
  departmentHasAssignableGroupLevelCredential,
  getPeriodDateRange,
  isCredentialLocked,
  isCredentialTypeLocked,
  isGroupLevelCredential,
} from '../common/utils/credentials/credential-utils';
import {
  useUnsavedCredentialsDispatch,
  useUnsavedCredentials,
  selectUnsavedCredentials,
} from './UnsavedCredentials';
import { useDepartmentUnsavedCredentialsDispatch } from './DepartmentUnsavedCredentials';
import {
  CategoryContainer,
  CategoryHeader,
  Column,
  Container,
  CredentialTypeHeader,
  HeaderColumn,
  PeriodHeader,
  Row,
} from './ContactCredentials.styles';
import { getLoggedInUser } from '../auth/auth-selectors';
import PropTypes from 'prop-types';
import Immutable, { List } from 'immutable';
import Icon from '../common/icons/Icon';
import ContactCredentialRequest from './ContactCredentialRequest';
import UnsavedCredentialRequestList from './UnsavedCredentialRequestList';

export const GROUP_PERSONNEL = 'GROUP_PERSONNEL';
export const GROUP_UNASSIGNED = 'GROUP_UNASSIGNED';
export const REGULAR_STAFF = 'REGULAR_STAFF';
export const PLACEHOLDER_STAFF = 'PLACEHOLDER_STAFF';

const aclRules = {
  canRequestCredentials: [resource.DEPARTMENT, 'request-credential'],
};

const ContactCredentials = ({
  type = REGULAR_STAFF,
  department,
  contact,
  onSaving,
  onDeleting,
  style = {},
  headerStyle = {},
}) => {
  const acl = useACL(aclRules);

  const loggedInUser = useSelector(getLoggedInUser);

  const departmentUnsavedCredentialsDispatch =
    useDepartmentUnsavedCredentialsDispatch();

  const unsavedCredentialsDispatch = useUnsavedCredentialsDispatch();
  const unsavedCredentials = selectUnsavedCredentials(useUnsavedCredentials());

  const [updateUnsavedCredentials, setUpdateUnsavedCredentials] =
    useState(false);

  const [saving, setSaving] = useState();
  const [deleting, setDeleting] = useState();

  useEffect(() => {
    if (!onSaving) return;
    if (saving === 0) onSaving(false);
    if (saving === 1) onSaving(true);
  }, [onSaving, saving]);

  useEffect(() => {
    if (!onDeleting) return;
    if (deleting === 0) onDeleting(false);
    if (deleting === 1) onDeleting(true);
  }, [onDeleting, deleting]);

  const credentialRequests = useMemo(() => {
    if (type === GROUP_UNASSIGNED)
      return department
        .get('credentialRequests')
        .filter(request => request.get('requested_for') === null);
    else if ([GROUP_PERSONNEL, REGULAR_STAFF].includes(type) && contact)
      return contact.get('credentialRequests');
    else return new List();
  }, [contact, department, type]);

  useEffect(() => {
    if (credentialRequests) setUpdateUnsavedCredentials(true);
  }, [credentialRequests]);

  const groupCredentialTypes = useMemo(() => {
    if (type !== GROUP_PERSONNEL) return null;

    const groupCredentialRequests = department
      .get('credentialRequests')
      .filter(
        request =>
          request.get('requested_for') === null &&
          request.get('quantity_approved') > 0,
      );

    const credentialTypes = new Map();

    groupCredentialRequests.forEach(credentialRequest => {
      const credential = credentialRequest.get('credential');
      let credentialType = credential.get('credentialType');
      const credentialTypeId = credentialType.get('id');
      const credentialCategory = credentialType.get('category');

      if (credentialTypes.has(credentialTypeId)) {
        const currentCredentialType =
          credentialTypes.get(credentialTypeId).credentialType;
        const credentials = currentCredentialType
          .get('credentials')
          .push(credential);
        credentialType = credentialType.set('credentials', credentials);
      } else {
        credentialType = credentialType.set('credentials', List([credential]));
      }

      credentialTypes.set(credentialTypeId, {
        id: credentialType.get('id'),
        name: credentialType.get('name'),
        categoryId: credentialCategory.get('id'),
        categoryName: credentialCategory.get('name'),
        issueFrequency: credentialType.get('issue_frequency'),
        credentialType,
      });
    });

    return Immutable.Map(credentialTypes);
  }, [department, type]);

  const credentialTypes = useMemo(() => {
    const credentialTypes = new Map();

    credentialRequests.forEach(credentialRequest => {
      const credential = credentialRequest.get('credential');
      let credentialType = credential.get('credentialType');
      const credentialTypeId = credentialType.get('id');
      const credentialCategory = credentialType.get('category');

      if (credentialTypes.has(credentialTypeId)) {
        const currentCredentialType =
          credentialTypes.get(credentialTypeId).credentialType;
        const credentials = currentCredentialType
          .get('credentials')
          .push(credential);
        credentialType = credentialType.set('credentials', credentials);
      } else {
        credentialType = credentialType.set('credentials', List([credential]));
      }

      credentialTypes.set(credentialTypeId, {
        id: credentialType.get('id'),
        name: credentialType.get('name'),
        categoryId: credentialCategory.get('id'),
        categoryName: credentialCategory.get('name'),
        issueFrequency: credentialType.get('issue_frequency'),
        credentialType,
      });
    });

    if (unsavedCredentials) {
      const unsavedCredentialIds = unsavedCredentials
        .filter(unsavedCredential => unsavedCredential.quantity > 0)
        .map(unsavedCredential => unsavedCredential.id);

      department.get('credentialTypes').forEach(credentialType => {
        const credentialCategory = credentialType.get('category');
        credentialType.get('credentials').forEach(credential => {
          if (unsavedCredentialIds.includes(credential.get('id'))) {
            credentialTypes.set(credentialType.get('id'), {
              id: credentialType.get('id'),
              name: credentialType.get('name'),
              categoryId: credentialCategory.get('id'),
              categoryName: credentialCategory.get('name'),
              issueFrequency: credentialType.get('issue_frequency'),
              credentialType,
            });
          }
        });
      });

      if (type === GROUP_PERSONNEL) {
        groupCredentialTypes.forEach(groupCredentialType => {
          const credentialType = groupCredentialType.credentialType;
          if (credentialTypes.has(credentialType.get('id'))) return;
          const credentialCategory = credentialType.get('category');
          credentialType.get('credentials').forEach(credential => {
            if (unsavedCredentialIds.includes(credential.get('id'))) {
              credentialTypes.set(credentialType.get('id'), {
                id: credentialType.get('id'),
                name: credentialType.get('name'),
                categoryId: credentialCategory.get('id'),
                categoryName: credentialCategory.get('name'),
                issueFrequency: credentialType.get('issue_frequency'),
                credentialType,
              });
            }
          });
        });
      }
    }

    return Immutable.Map(credentialTypes);
  }, [
    credentialRequests,
    department,
    groupCredentialTypes,
    type,
    unsavedCredentials,
  ]);

  const availableCredentialTypes = useMemo(() => {
    let availableCredentialTypes = department
      .get('credentialTypes')
      .filter(credentialType => {
        const attendeePickupTypes = credentialType.get('attendeePickupTypes');
        if (attendeePickupTypes.isEmpty()) return false;
        if ([REGULAR_STAFF, PLACEHOLDER_STAFF].includes(type)) return true;
        if (type === GROUP_PERSONNEL) {
          if (!isGroupLevelCredential(credentialType)) return true;
          else
            return (
              contactHasCredentialRequest(contact, credentialType) ||
              departmentHasAssignableGroupLevelCredential(
                department,
                credentialType,
              )
            );
        }
        return isGroupLevelCredential(credentialType);
      });

    if (type === GROUP_PERSONNEL) {
      groupCredentialTypes.forEach(({ credentialType }) => {
        if (
          !availableCredentialTypes.some(
            ct => ct.get('id') === credentialType.get('id'),
          )
        )
          availableCredentialTypes =
            availableCredentialTypes.push(credentialType);
      });
    }
    return availableCredentialTypes;
  }, [department, type, contact, groupCredentialTypes]);

  const allCredentialTypes = useMemo(() => {
    let allCredentialTypes = availableCredentialTypes;
    credentialTypes.forEach(({ credentialType }) => {
      if (
        !availableCredentialTypes.some(
          ct => ct.get('id') === credentialType.get('id'),
        )
      )
        allCredentialTypes = allCredentialTypes.push(credentialType);
    });
    return allCredentialTypes;
  }, [availableCredentialTypes, credentialTypes]);

  const credentialCategories = useMemo(
    () =>
      allCredentialTypes
        .map(credentialType => credentialType.get('category').toJS())
        .groupBy(category => category.id)
        .map(groupedCredentials => groupedCredentials.first())
        .sortBy(category => category.name)
        .toList(),
    [allCredentialTypes],
  );

  const periods = useMemo(() => {
    const periods = new Map();
    allCredentialTypes.forEach(credentialType => {
      const issueFrequency = credentialType.get('issue_frequency');
      const credentials = credentialType.get('credentials');

      credentials.forEach(credential => {
        if (issueFrequency === 'ONE_TIME') {
          const oneTimePeriods = credential.get('oneTimePeriods');
          oneTimePeriods.forEach(period => {
            const periodId = period.get('id');

            if (!periods.has(periodId)) {
              periods.set(periodId, {
                id: periodId,
                name: period.get('name'),
                startDate: period.get('start_date'),
                endDate: period.get('end_date'),
                dateRange: getPeriodDateRange(period),
              });
            }
          });
        } else {
          const period = credential.get('period');
          const periodId = period.get('id');

          if (!periods.has(periodId)) {
            periods.set(periodId, {
              id: periodId,
              name: period.get('name'),
              startDate: period.get('start_date'),
              endDate: period.get('end_date'),
              dateRange: getPeriodDateRange(period),
            });
          }
        }
      });
    });

    return Array.from(periods.values()).sort((period1, period2) =>
      period1.startDate <= period2.startDate ? -1 : 1,
    );
  }, [allCredentialTypes]);

  const categoryCredentialTypes = useMemo(
    () =>
      credentialTypes
        .sortBy(credentialType => credentialType.name)
        .groupBy(credentialType => credentialType.categoryId),
    [credentialTypes],
  );

  const pulseTags = useMemo(() => {
    const pulseTagsMap = new Map();

    var pulseOrders = department.get('pulseOrders');
    if (!pulseOrders || !pulseOrders.size) {
      return pulseTagsMap;
    }

    if (contact) {
      pulseOrders = pulseOrders.filter(
        pulseOrder => pulseOrder.get('contact_id') === contact.get('id'),
      );
    } else {
      pulseOrders = pulseOrders.filter(
        pulseOrder => !pulseOrder.get('contact_id'),
      );
    }

    if (pulseOrders) {
      pulseOrders.forEach(pulseOrder => {
        const pulseTags = pulseOrder.get('pulseTags');
        if (pulseTags) {
          pulseTags.forEach(pulseTag => {
            const pulseCredentialId = pulseTag.get('pulse_credential_id');
            if (pulseTagsMap.has(pulseCredentialId)) {
              pulseTagsMap.set(
                pulseCredentialId,
                pulseTagsMap.get(pulseCredentialId) + 1,
              );
            } else {
              pulseTagsMap.set(pulseCredentialId, 1);
            }
          });
        }
      });
    }

    return pulseTagsMap;
  }, [contact, department]);

  useEffect(() => {
    if (updateUnsavedCredentials) {
      setUpdateUnsavedCredentials(false);

      const unsavedCredentialIds = unsavedCredentials.map(
        unsavedCredential => unsavedCredential.id,
      );

      const groupedCredentialRequests = credentialRequests.groupBy(
        credentialRequest => credentialRequest.get('credential_id'),
      );

      const changedQuantities = availableCredentialTypes
        .filter(credentialType => credentialTypes.has(credentialType.get('id')))
        .map(credentialType =>
          credentialType
            .get('credentials')
            .filter(credential => {
              const credentialId = credential.get('id');
              return (
                !groupedCredentialRequests.has(credentialId) &&
                !unsavedCredentialIds.includes(credentialId)
              );
            })
            .map(credential => {
              return {
                credentialId: credential.get('id'),
                quantity: 0,
              };
            }),
        )
        .reduce((flatList, list) => flatList.concat(list));

      if (changedQuantities)
        unsavedCredentialsDispatch({
          type: 'change',
          changedQuantities,
        });
    }
  }, [
    availableCredentialTypes,
    credentialRequests,
    credentialTypes,
    unsavedCredentials,
    unsavedCredentialsDispatch,
    updateUnsavedCredentials,
  ]);

  const getSelectableCredentialTypes = categoryId => {
    const selectableCredentialTypes = availableCredentialTypes
      .filter(availableCredentialType => {
        return (
          availableCredentialType.get('category_id') === categoryId &&
          !credentialTypes.some(
            credentialType =>
              credentialType.id === availableCredentialType.get('id'),
          ) &&
          !isCredentialTypeLocked(
            availableCredentialType,
            loggedInUser,
            department,
          )
        );
      })
      .sortBy(credentialType => credentialType.get('name'))
      .map(credentialType => ({
        id: credentialType.get('id'),
        name: credentialType.get('name'),
      }));

    return selectableCredentialTypes;
  };

  const getCredentialRequests = (credentialType, period) => {
    const filteredCredentialRequests = credentialRequests.filter(request => {
      if (
        credentialType.id !==
        request.getIn(['credential', 'credential_type_id'])
      ) {
        return false;
      }

      const credential = request.get('credential');
      return credentialType.issueFrequency === 'ONE_TIME'
        ? credential
            .get('oneTimePeriods')
            .some(otp => period.id === otp.get('id'))
        : credential.get('period_id') === period.id;
    });

    if (
      filteredCredentialRequests.count() > 1 &&
      filteredCredentialRequests.every(r => r.getIn(['credential', 'date']))
    ) {
      return filteredCredentialRequests.sort((a, b) =>
        a.getIn(['credential', 'date', 'date']) >
        b.getIn(['credential', 'date', 'date'])
          ? 1
          : -1,
      );
    }
    return filteredCredentialRequests;
  };

  const getPulseTags = request =>
    pulseTags.get(request.getIn(['credential', 'pulse_credential_id']));

  const handleAddCredential = event => {
    const selectedCredentialTypeId = parseInt(event.target.value, 10);

    const credentialType = availableCredentialTypes.find(
      credentialType => credentialType.get('id') === selectedCredentialTypeId,
    );

    if (!credentialType) return;

    const credentials = credentialType.get('credentials');
    const changedQuantities = credentials
      .map(credential => ({
        credentialId: credential.get('id'),
        quantity: 1,
      }))
      .toArray();

    departmentUnsavedCredentialsDispatch({
      type: 'add',
      changedQuantities,
    });

    unsavedCredentialsDispatch({
      type: 'change',
      changedQuantities,
    });
  };

  const renderPeriodCredentialType = (period, category, credentialType) => {
    const requests = getCredentialRequests(credentialType, period);

    return (
      <Column key={`period-${period.id}`} $color={category.color}>
        {requests.map(request => (
          <ContactCredentialRequest
            key={request.get('id')}
            type={type}
            department={department}
            credentialRequest={request}
            issued={getPulseTags(request)}
            canEdit={acl.canRequestCredentials}
            isLockDateActive={isCredentialLocked(
              request.get('credential'),
              loggedInUser,
              department,
            )}
            onSaving={isSaving =>
              setSaving(prevSaving => (prevSaving ?? 0) + (isSaving ? 1 : -1))
            }
            onDeleting={isDeleting =>
              setDeleting(
                prevDeleting => (prevDeleting ?? 0) + (isDeleting ? 1 : -1),
              )
            }
          />
        ))}
        <UnsavedCredentialRequestList
          type={type}
          department={department}
          contact={contact}
          availableCredentialTypes={availableCredentialTypes}
          credentialTypeId={credentialType.id}
          periodId={period.id}
        />
      </Column>
    );
  };

  const renderCredentialType = (category, credentialType) => (
    <Row key={`credential-type-${credentialType.id}`} $columns={periods.length}>
      <HeaderColumn
        className="contact-details-section__header"
        style={headerStyle}
      >
        <CredentialTypeHeader>{credentialType.name}</CredentialTypeHeader>
      </HeaderColumn>
      {periods.map(period =>
        renderPeriodCredentialType(period, category, credentialType),
      )}
    </Row>
  );

  const renderCredentialTypeSelect = category => {
    const selectableCredentialTypes = getSelectableCredentialTypes(category.id);

    if (selectableCredentialTypes.isEmpty()) {
      return null;
    }

    return (
      <Row $columns={periods.length}>
        <HeaderColumn
          className="contact-details-section__header"
          style={headerStyle}
        >
          <div className="contact-credentials__item contact-credentials__select">
            <select
              onChange={handleAddCredential}
              id="selectCredentialTypeDropdown"
              style={{ width: '200px', marginRight: '0' }}
            >
              <option value="select-credential-type">
                Add {category.name}
              </option>
              {selectableCredentialTypes.map(credentialType => (
                <option key={credentialType.id} value={credentialType.id}>
                  {credentialType.name}
                </option>
              ))}
            </select>
          </div>
        </HeaderColumn>
      </Row>
    );
  };

  const renderCredentialCategory = category => {
    const credentialTypes = categoryCredentialTypes.get(category.id);
    return (
      <CategoryContainer key={`category-${category.id}`}>
        <Row $columns={periods.length}>
          <HeaderColumn
            className="contact-details-section__header"
            style={headerStyle}
          >
            <CategoryHeader>
              <span
                className="credential-category-color"
                style={{ backgroundColor: `${category.color}` }}
              />
              <span>{category.name}</span>
            </CategoryHeader>
          </HeaderColumn>
          {(!credentialTypes || credentialTypes.size === 0) &&
            periods.map(period => (
              <Column
                key={`period-${period.id}`}
                $color={category.color}
                style={{ textAlign: 'center' }}
              >
                No credentials
              </Column>
            ))}
        </Row>
        {credentialTypes &&
          credentialTypes
            .valueSeq()
            .map(credentialType =>
              renderCredentialType(category, credentialType),
            )}
        {acl.canRequestCredentials && renderCredentialTypeSelect(category)}
        <Row $columns={periods.length}>
          <HeaderColumn
            className="contact-details-section__header"
            style={headerStyle}
          />
        </Row>
      </CategoryContainer>
    );
  };

  if (!credentialCategories || credentialCategories.size === 0) {
    return null;
  }
  if (!periods || periods.length === 0) {
    return null;
  }

  return (
    <div className="contact-details-section" style={style}>
      <Container>
        <Row $columns={periods.length}>
          <HeaderColumn
            className="contact-details-section__header"
            style={headerStyle}
          >
            <Icon icon="Ticket" />
            Credentials
          </HeaderColumn>
          {periods.map(period => (
            <PeriodHeader key={`period-${period.id}`}>
              <div>{period.name}</div>
              <div>{period.dateRange}</div>
            </PeriodHeader>
          ))}
        </Row>
        {credentialCategories.map(category =>
          renderCredentialCategory(category),
        )}
      </Container>
    </div>
  );
};

ContactCredentials.propTypes = {
  type: PropTypes.oneOf([
    GROUP_PERSONNEL,
    GROUP_UNASSIGNED,
    REGULAR_STAFF,
    PLACEHOLDER_STAFF,
  ]),
  department: PropTypes.instanceOf(Immutable.Map).isRequired,
  contact: PropTypes.instanceOf(Immutable.Map),
  onSaving: PropTypes.func,
  onDeleting: PropTypes.func,
  style: PropTypes.object,
  headerStyle: PropTypes.object,
};

export default ContactCredentials;
