import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { List, Map } from 'immutable';
import { getPeriods } from './utils';
import { formatDate } from '../../common/utils/credentials/credential-utils';
import { deletePublicGroupContact } from '../../contact/contact-actions';
import { getGroupCredentials } from '../group-credential-actions';
import {
  Container,
  Header,
  NewPersonnel,
  AddPersonnel,
  ContactContainer,
  ContactContainerHeader,
  PeriodCredentialsHeader,
  PeriodCredentials,
  Contact,
  Name,
  SplitRow,
  SplitRowHeader,
  Row,
  Gutter,
  AddPersonnelSpan,
  Italic,
} from './Personnel.styles';
import PropTypes from 'prop-types';
import PersonnelForm from './PersonnelForm';
import Icon from '../../common/icons/Icon';
import DeleteContactModal from '../../common/DeleteContactModal';

const Personnel = ({
  token,
  department,
  contacts,
  requests,
  optionalFields,
  relationships,
  canEdit,
  closeDatePassed,
}) => {
  const dispatch = useDispatch();

  const periods = useMemo(() => getPeriods(requests), [requests]);

  const [addNew, setAddNew] = useState(false);
  const [open, setOpen] = useState(null);

  const onDelete = async contact => {
    await dispatch(
      deletePublicGroupContact(token, department.get('id'), contact.get('id')),
    ).then(() => {
      dispatch(getGroupCredentials(token));
    });
  };

  const getCredentialDailyDate = credential => {
    const issueFrequency = credential.getIn([
      'credentialType',
      'issue_frequency',
    ]);
    if (issueFrequency === 'DAILY') {
      return `${formatDate(credential.getIn(['date', 'date']))}`;
    } else return '';
  };

  const renderCredentialRequestsForPeriod = (index, period, contact) => {
    let credentialRequests;
    if (contact) {
      credentialRequests = contact.get('credentialRequests').filter(request => {
        const credential = request.get('credential');
        const credentialType = request.getIn(['credential', 'credentialType']);
        if (request.get('quantity_approved') > 0) {
          return credentialType.get('issue_frequency') === 'ONE_TIME'
            ? credential
                .get('oneTimePeriods')
                .some(otp => period.id === otp.get('id'))
            : credential.get('period_id') === period.id;
        } else {
          return false;
        }
      });
    }

    return (
      <PeriodCredentials key={index}>
        {credentialRequests?.map((credentialRequest, index) => (
          <SplitRow
            height={100 / credentialRequests.size}
            key={credentialRequest.get('credential_id')}
            index={index}
          >
            {`${credentialRequest.getIn([
              'credential',
              'credentialType',
              'name',
            ])} x ${credentialRequest.get(
              'quantity_approved',
            )} ${getCredentialDailyDate(
              credentialRequest.getIn(['credential']),
            )}`}
          </SplitRow>
        ))}
      </PeriodCredentials>
    );
  };

  return (
    <Container>
      <Header>
        <h3>Personnel</h3>
      </Header>

      <div style={{ marginTop: 12 }}>
        <Italic>
          To assign credentials, click on the{' '}
          <AddPersonnelSpan>+Add Person</AddPersonnelSpan> button, enter the
          person's full name, select a credential type and the quantity
          requested.
        </Italic>
      </div>

      <Row style={{ minHeight: 32 }}>
        {!closeDatePassed && !addNew && (
          <AddPersonnel
            onClick={() => {
              setAddNew(true);
              setOpen(null);
            }}
          >
            <Icon icon="AddCircle" /> Add Person
          </AddPersonnel>
        )}
      </Row>
      <ContactContainerHeader>
        <Contact>
          <Name>Name</Name>
          {periods.map(period => (
            <PeriodCredentialsHeader key={`period-${period.id}`}>
              <SplitRowHeader>{period.name}</SplitRowHeader>
              <SplitRowHeader>({period.dateRange})</SplitRowHeader>
            </PeriodCredentialsHeader>
          ))}
          <Gutter></Gutter>
        </Contact>
      </ContactContainerHeader>
      {!closeDatePassed && addNew && (
        <NewPersonnel>
          <PersonnelForm
            token={token}
            department={department}
            requests={requests}
            optionalFields={optionalFields}
            relationships={relationships}
            allowEdits
            handleClose={() => setAddNew(false)}
          />
        </NewPersonnel>
      )}
      {contacts.size > 0
        ? contacts
            .sortBy(contact => contact.get('last_name'))
            .filter(contact => !contact.get('is_guest'))
            .map(contact => {
              const contactIsOpen = open === contact.get('id');
              const allowEdits = canEdit(contact);
              const pulseTagsCount = contact
                .get('pulseOrders')
                .reduce((acc, order) => acc + order.get('pulseTags').size, 0);
              return (
                <ContactContainer
                  key={contact.get('id')}
                  open={contactIsOpen}
                  onClick={e => {
                    e.stopPropagation();
                    setAddNew(false);
                    setOpen(contact.get('id'));
                  }}
                >
                  {contactIsOpen ? (
                    <PersonnelForm
                      token={token}
                      department={department}
                      contact={contact}
                      requests={requests}
                      optionalFields={optionalFields}
                      relationships={relationships}
                      pulseTagsCount={pulseTagsCount}
                      closeDatePassed={closeDatePassed}
                      allowEdits={allowEdits}
                      handleClose={() => setOpen(null)}
                      onDelete={onDelete}
                    />
                  ) : (
                    <Contact>
                      <Name>
                        {contact.get('first_name')} {contact.get('last_name')}
                      </Name>
                      {periods.map((period, index) =>
                        renderCredentialRequestsForPeriod(
                          index,
                          period,
                          contact,
                        ),
                      )}
                      <Gutter>
                        {pulseTagsCount > 0 && (
                          <div
                            className="credential-tooltip"
                            data-tooltip-id={`contact-issued-credential-tooltip-${contact.get(
                              'id',
                            )}`}
                          >
                            <Icon icon="IssuedCredential" />
                            <Tooltip
                              id={`contact-issued-credential-tooltip-${contact.get(
                                'id',
                              )}`}
                              content={`Issued: ${pulseTagsCount}`}
                            />
                          </div>
                        )}
                        {allowEdits && !closeDatePassed && (
                          <DeleteContactModal
                            contact={contact}
                            onDelete={onDelete}
                            trigger={
                              <div>
                                <Icon icon="Trash" />
                              </div>
                            }
                          />
                        )}
                        <div className="expandable-row__dropdown">
                          <Icon icon="Caret" />
                        </div>
                      </Gutter>
                    </Contact>
                  )}
                </ContactContainer>
              );
            })
        : !addNew && (
            <div className="generic-list--empty">
              <p>You have no personnel added yet.</p>
            </div>
          )}
    </Container>
  );
};

Personnel.propTypes = {
  token: PropTypes.string.isRequired,
  department: PropTypes.instanceOf(Map).isRequired,
  contacts: PropTypes.instanceOf(List).isRequired,
  requests: PropTypes.instanceOf(List).isRequired,
  optionalFields: PropTypes.instanceOf(List),
  relationships: PropTypes.instanceOf(List),
  canEdit: PropTypes.func.isRequired,
  closeDatePassed: PropTypes.bool.isRequired,
};

export default Personnel;
