import getApplicationActions from '../common/application/get-application-actions';

const actions = getApplicationActions('media');

export const GET_MEDIA_APPLICATION_AVAILABLE_CREDENTIALS =
  actions.GET_MEDIA_APPLICATION_AVAILABLE_CREDENTIALS;
export const getMediaApplicationAvailableCredentials =
  actions.getApplicationAvailableCredentials;

export const GET_MEDIA_APPLICATION_EMAIL_TYPES =
  actions.GET_MEDIA_APPLICATION_EMAIL_TYPES;
export const getMediaApplicationEmailTypes = actions.getApplicationEmailTypes;

export const GET_MEDIA_APPLICATIONS = actions.GET_MEDIA_APPLICATIONS;
export const getMediaApplications = actions.getApplications;

export const GET_MEDIA_APPLICATION_RESPONSES =
  actions.GET_MEDIA_APPLICATION_RESPONSES;
export const getMediaApplicationResponses = actions.getApplicationResponses;

export const SAVE_MEDIA_APPLICATION = actions.SAVE_MEDIA_APPLICATION;
export const saveMediaApplication = actions.saveApplication;

export const DELETE_MEDIA_APPLICATION = actions.DELETE_MEDIA_APPLICATION;
export const deleteMediaApplication = actions.deleteApplication;

export const CREATE_MEDIA_APPLICATION_SECTION =
  actions.CREATE_MEDIA_APPLICATION_SECTION;
export const createMediaApplicationSection = actions.createApplicationSection;

export const CREATE_OR_UPDATE_MEDIA_APPLICATION_SECTION =
  actions.CREATE_OR_UPDATE_MEDIA_APPLICATION_SECTION;
export const createOrUpdateMediaApplicationSection =
  actions.createOrUpdateApplicationSection;

export const UPDATE_MEDIA_APPLICATION_SECTION =
  actions.UPDATE_MEDIA_APPLICATION_SECTION;
export const updateMediaApplicationSection = actions.updateApplicationSection;

export const DELETE_MEDIA_APPLICATION_SECTION =
  actions.DELETE_MEDIA_APPLICATION_SECTION;
export const deleteMediaApplicationSection = actions.deleteApplicationSection;

export const GET_MEDIA_APPLICATION_PUBLIC =
  actions.GET_MEDIA_APPLICATION_PUBLIC;
export const getMediaApplicationPublic = actions.getApplicationPublic;

export const UPDATE_MEDIA_APPLICATION_SECTION_PUBLIC =
  actions.UPDATE_MEDIA_APPLICATION_SECTION_PUBLIC;
export const updateMediaApplicationSectionPublic =
  actions.updateApplicationSectionPublic;

export const UPDATE_MEDIA_APPLICATION_SECTION_SORT_ORDERS =
  actions.UPDATE_MEDIA_APPLICATION_SECTION_SORT_ORDERS;
export const updateMediaApplicationSectionSortOrders =
  actions.updateApplicationSectionSortOrders;

export const SAVE_MEDIA_APPLICATION_PUBLIC =
  actions.SAVE_MEDIA_APPLICATION_PUBLIC;
export const saveMediaApplicationPublic = actions.saveApplicationPublic;

export const GET_MEDIA_APPLICATIONS_FOR_EVENT =
  actions.GET_MEDIA_APPLICATIONS_FOR_EVENT;
export const getApplicationListForEvent = actions.getApplicationListForEvent;

export const APPROVE_MEDIA_APPLICATION = actions.APPROVE_MEDIA_APPLICATION;
export const approveApplication = actions.approveApplication;

export const DENY_MEDIA_APPLICATION = actions.DENY_MEDIA_APPLICATION;
export const denyApplication = actions.denyApplication;
