import { useCallback, useEffect, useRef } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { getIn } from 'final-form';
import departmentTypeList from '../lib/department-types';
import departmentTypeMap from '../lib/department-type-map';
import ToggleGroup from '../common/forms/ToggleGroup';

export const defaultEventSettings = () => {
  const defaultSettings = {};

  departmentTypeList.forEach(departmentType => {
    defaultSettings[departmentType] = {
      section_enabled: true,
      credentials: true,
      catering: true,
    };
  });

  return defaultSettings;
};

const EventSettings = () => {
  const { change } = useForm();
  const { initialValues, values } = useFormState();

  const getEnabledSections = useCallback(() => {
    const enabledSections = {};

    departmentTypeList.forEach(departmentType => {
      enabledSections[departmentType] = getIn(
        values.settings,
        `${departmentType}.section_enabled`,
      );
    });

    return enabledSections;
  }, [values.settings]);

  const enabledSections = useRef(getEnabledSections());

  useEffect(() => {
    const previousEnabledSections = enabledSections.current;
    enabledSections.current = getEnabledSections();

    departmentTypeList.forEach(departmentType => {
      if (
        enabledSections.current[departmentType] &&
        enabledSections.current[departmentType] !==
          previousEnabledSections[departmentType]
      ) {
        const settings = initialValues.settings[departmentType];

        if (settings) {
          change(
            `settings.${departmentType}.credentials`,
            settings.credentials,
          );
          change(`settings.${departmentType}.catering`, settings.catering);
        }
      }
    });
  }, [
    change,
    enabledSections,
    getEnabledSections,
    initialValues.settings,
    values.settings,
  ]);

  return departmentTypeList.map(departmentTypeKey => {
    const departmentType = departmentTypeMap[departmentTypeKey];

    const toggles = [
      {
        name: `settings.${departmentType.singular}.credentials`,
        label: 'Credentials',
        description: `Enable credentials in ${departmentType.label.plural}`,
      },
      {
        name: `settings.${departmentType.singular}.catering`,
        label: 'Catering',
        description: `Enable catering in ${departmentType.label.plural}`,
      },
    ];

    return (
      <ToggleGroup
        key={`settings.${departmentType.singular}`}
        label={departmentTypeMap[departmentTypeKey].label.plural}
        topLevelFieldName={`settings.${departmentType.singular}.section_enabled`}
        description={`Enable ${departmentType.label.plural} section`}
        toggles={toggles}
      />
    );
  });
};

export default EventSettings;
