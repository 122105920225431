import PropTypes from 'prop-types';
import Icon from '../icons/Icon';

const NoDataComponent = ({ text = 'There are no results to display.' }) => (
  <div className="generic-list--empty">
    <Icon icon="Sad" />
    <p>{text}</p>
  </div>
);

NoDataComponent.propTypes = {
  text: PropTypes.string,
};

export default NoDataComponent;
