import { Outlet, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentEvent } from '../event/event-selectors';
import Icon from '../common/icons/Icon';

const EventWrapper = () => {
  const params = useParams();
  const event = useSelector(state => getCurrentEvent(state, { params }));

  return (
    <div>
      {event?.get('deactivated') ? (
        <div className="notification-strip__disabled">
          <Icon icon="Lock" className="notification-strip__icon" />
          <p className="notification-strip__message">Event Deactivated</p>
        </div>
      ) : null}
      <Outlet />
    </div>
  );
};

export default EventWrapper;
