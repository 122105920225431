import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { removeLastUriSegment } from '../common/uri';
import { getCurrentApplicationRequest } from '../common/application/application-selectors';
import { approveApplication, denyApplication } from './intern-actions';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import DisplayFields from '../common/custom-forms/display-fields';
import StatusButton from '../common/StatusButton';

const InternApprovalApplication = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const backTo = removeLastUriSegment(location.pathname);

  const department = useSelector(state =>
    getCurrentApplicationRequest(state, { type: 'intern', params }),
  );

  const [approving, setApproving] = useState(false);
  const [denying, setDenying] = useState(false);

  const contactList = department
    ?.get('contacts')
    .filterNot(contact => contact.get('is_guest'));

  const approve = () => {
    setApproving(true);
    return dispatch(
      approveApplication(department.get('event_id'), department.get('id')),
    ).then(action => {
      setApproving(false);
      if (action.response.ok) navigate(backTo);
    });
  };

  const deny = () => {
    setDenying(true);
    dispatch(
      denyApplication(department.get('event_id'), department.get('id')),
    ).then(action => {
      setDenying(false);
      if (action.response.ok) navigate(backTo);
    });
  };

  const renderCustomResponses = () => {
    const responses = department
      .get('applicationResponses')
      .filter(response => !!response.get('key'))
      .sort(
        (a, b) =>
          a.getIn(['applicationSection', 'sort_order']) -
          b.getIn(['applicationSection', 'sort_order']),
      );

    if (responses.isEmpty()) return null;

    return (
      <div>
        <Paper>
          <PaperHeader title="Form Answers" />
        </Paper>
        <div className="media-approval-page__form-answers">
          {responses &&
            responses.map(response => {
              const fields = response.getIn(['content', 'fields']);
              const section = response.get('applicationSection');

              return (
                <Paper className="custom-field-info" key={section.get('id')}>
                  <h3>{section.get('name')}</h3>
                  {fields.map((field, index) => (
                    <DisplayFields
                      key={index}
                      label={field.get('label')}
                      type={field.get('type')}
                      value={field.get('value')}
                    />
                  ))}
                </Paper>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div className="media-approval-page__wrapper">
      <div className="media-approval-page intern-approval-page">
        <Paper>
          <PaperHeader
            title={department && `${department.get('name')} (Intern)`}
            backTo={backTo}
          />
          {(!contactList && <LoadingIndicator />) || (
            <div
              className="paper applicant-info"
              style={{ border: 'none', borderTop: '1px solid #ccc' }}
            >
              <div className="fields-container">
                <div className="fields-container-item">
                  <span className="fields-container--label">
                    Applicant Name
                  </span>
                  {department.get('name')}
                </div>

                <div className="fields-container-item">
                  <span className="fields-container--label">
                    Applicant Email
                  </span>
                  {contactList.getIn([0, 'email'])}
                </div>
              </div>
            </div>
          )}
        </Paper>
        {department && renderCustomResponses()}
        <Paper>
          <PaperHeader title="Approve This Intern" />
          <div className="approve-media__desc">
            Approving this department will automatically approve all personnel
            listed above, unless they have been previously rejected, with his or
            her assigned credentials. You can continue to make changes to this
            Intern once approved, but changes will require re-approval.
          </div>
          <div className="approve-button__footer">
            <StatusButton
              type="button"
              onClick={approve}
              buttonText="Approve Intern"
              disabled={approving || denying}
              status={approving ? 'loading' : 'default'}
            />
            <button
              type="button"
              className="button button--plain"
              disabled={approving || denying}
              onClick={deny}
            >
              {denying && (
                <div className="deny">
                  <div className="spinner">
                    <div className="bounce1" />
                    <div className="bounce2" />
                    <div className="bounce3" />
                  </div>
                </div>
              )}
              {!denying && 'Deny Intern'}
            </button>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default InternApprovalApplication;
