import { Map } from 'immutable';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import PropTypes from 'prop-types';
import CateringRequest from '../../../catering/CateringRequest';
import FormSection from '../../forms/FormSection';

const draftRenderer = new Renderer(defaultBlocks);

const CateringRequestsSection = ({ className, section, event }) => (
  <section className={className}>
    <header>
      <div className={`${className}--title`}>Staff Catering</div>
    </header>

    <article>
      <div className={`${className}--desc`}>
        <div
          className="draftjs-output"
          dangerouslySetInnerHTML={{
            __html: draftRenderer.convertRawToHTML(
              section.get('content').toJS(),
            ),
          }}
        />
      </div>
      <FormSection name="catering">
        <CateringRequest event={event} />
      </FormSection>
    </article>
  </section>
);

CateringRequestsSection.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
  event: PropTypes.instanceOf(Map).isRequired,
};

export default CateringRequestsSection;
