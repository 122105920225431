import { createValidator } from 'revalidate';
import { validateEmail } from './is-valid-email';

export const validateEmailList = (list, delimiter = ';') => {
  const emails = list?.split(delimiter).map(email => email.trim()) ?? [];
  if (!emails.length) return true;
  if (emails.length === 1 && emails[0] === '') return true;
  return emails.every(email => validateEmail(email));
};

export default function isValidEmailList(delimiter = ';') {
  return createValidator(
    message => value => {
      if (!validateEmailList(value, delimiter)) {
        return message;
      }
    },
    field => `${field} contains invalid email addresses`,
  );
}
