import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 45px;
  left: -2px;
  z-index: 2;
  display: flex;
  width: 300px;
  background: #ffffff;
  border: 2px solid #dddddd;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  word-break: break-all;
  width: 100%;
  background-color: #f2f2f2;
  font-family: 'Roboto Condensed';
  font-size: 16px;
  padding: 10px;
  min-height: 40px;
`;

export const Title = styled.div`
  display: flex;
  flex-wrap: nowrap;
  opacity: 0.5;
  line-height: 20px;
  font-size: 16px;
  margin-right: 5px;
`;

export const SearchTerm = styled.div`
  display: flex;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  flex-wrap: wrap;
  overflow-wrap: break-word;
`;

export const Pagination = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: #9b9b9b;
  margin: 10px 10px 5px 0;
`;
