import React from 'react';
import SynapseDraft from '../../lib/draftjs-editor';

const ReduxSynapseDraft = props => {
  const newProps = Object.assign({}, props);
  newProps.content = newProps.value || null;
  delete newProps.value;
  return <SynapseDraft {...newProps} />;
};

export default ReduxSynapseDraft;
