import storage from 'store2';
import FileSaver from 'file-saver';
import config from '../config';

export const configurePayloadFromSearchParams = ({
  mime,
  email_to,
  searchParams,
  includeParameters = true,
}) => {
  const parameters = {};
  if (searchParams && includeParameters) {
    for (const key of searchParams.keys()) {
      if (['resource_slug', 'report_slug', 'include_labels'].includes(key))
        continue;
      parameters[key] = searchParams.getAll(key);
    }
  }
  return {
    report_type: searchParams.get('report_slug'),
    include_labels: searchParams.get('include_labels'),
    mime,
    email_to,
    parameters,
  };
};

export const configurePayload = ({
  report_type,
  mime,
  include_labels,
  email_to,
  filters,
}) => ({
  report_type,
  email_to,
  include_labels,
  mime,
  parameters: configureValues(filters),
});

export const configureValues = values => {
  const newObject = {};
  if (values) {
    Object.entries(values).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        newObject[k] = v.map(({ value }) => value);
      } else if (v) {
        newObject[k] = [v.value];
      }
    });
  }
  return newObject;
};

export const getReportUrl = (
  resource,
  resourceId,
  resourceSlug,
  title,
  reportSlug,
  includeLabels = false,
  filters = {},
) => {
  const query = new URLSearchParams();

  query.append('resource_slug', resourceSlug);
  query.append('report_slug', reportSlug);
  query.append('include_labels', includeLabels);

  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(value => query.append(key, value.value.toString()));
    } else {
      query.append(key, value.value.toString());
    }
  });

  return `/report/${resource}/${resourceId}/${title}?${query.toString()}`;
};

export const fetchDownload = (resource, resourceId, resourceSlug, payload) => {
  const apiUrl = `${config.apiUrl}${resource}/${resourceId}/reports`;
  return fetchFile(apiUrl, resourceSlug, payload);
};

function fetchFile(apiUrl, resourceSlug, payload) {
  const jwtToken = storage.session('idToken');
  const headers = new Headers({
    authorization: jwtToken,
  });
  const stringifiedPayload = JSON.stringify(payload);
  const { mime, report_type } = payload;
  return fetch(apiUrl, {
    method: 'POST',
    headers,
    body: stringifiedPayload,
  })
    .then(response => response.blob())
    .then(blob => {
      const ext = mime === 'text/csv' ? 'csv' : 'pdf';
      FileSaver.saveAs(blob, `${resourceSlug}_${report_type}.${ext}`);
    });
}
