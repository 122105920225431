import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';
import { v1 as keygen } from 'uuid';
import { resource, useACL } from '../ACL';
import { defaultBlocks } from '../../lib/draftjs-editor';
import { getApplicationEmailTypes } from './application-selectors';
import PropTypes from 'prop-types';
import LoadingIndicator from '../LoadingIndicator';
import FormSection from '../forms/FormSection';
import FormField from '../forms/FormField';
import ReduxFormsField from '../forms/ReduxFormsField';
import ReduxSynapseDraft from '../forms/ReduxSynapseDraft';
import ExpandableRow from '../ExpandableRow';
import Icon from '../icons/Icon';
import ViewSampleEmailTemplatesModal from '../../email/ViewSampleEmailTemplatesModal';
import AddSampleEmailTemplateModal from '../../email/AddSampleEmailTemplateModal';

const aclRules = {
  canManageGlobalSampleEmailTemplate: [
    resource.STRATA_ADMIN,
    'manage-sample-email-template',
  ],
  canManagePromoterSampleEmailTemplate: [
    resource.PROMOTER,
    'manage-sample-email-template',
  ],
};

const EmailTemplate = ({ emailType }) => {
  const acl = useACL(aclRules);
  const [expanded, setExpanded] = useState(false);

  const type = emailType.get('type');
  const label = emailType.get('label');
  const [bodyKey, setBodyKey] = useState(null);

  useFormState({
    subscription: { values: true },
    onChange: ({ values }) => {
      if (type in values.emailTemplates && !bodyKey) setBodyKey(keygen());
    },
  });

  const canManageSampleEmailTemplate =
    acl.canManageGlobalSampleEmailTemplate ||
    acl.canManagePromoterSampleEmailTemplate;

  return (
    <ExpandableRow
      key={type}
      columns={[
        <span key="clipboard" className="anchored-row-name">
          <Icon icon="Clipboard" className="anchored" />
          {label}
        </span>,
      ]}
      expandedHeaderStyle={{ fontSize: 'xx-large' }}
      isExpandable
      isExpanded={expanded}
      onToggleExpansion={() => setExpanded(!expanded)}
      renderChildrenWhenNotExpanded={true}
    >
      <FormSection name={type}>
        {({ change, getValue }) => (
          <div className="generic-form__body">
            <div className="input-group">
              <FormField
                key="subject"
                name="subject"
                label="Subject"
                component={ReduxFormsField}
                required
              >
                <input type="text" />
              </FormField>
              <FormField
                key={bodyKey}
                name="body"
                label="Body"
                component={ReduxFormsField}
                required
              >
                <ReduxSynapseDraft
                  inlineControls={['Bold', 'Italic', 'Underline', 'Link']}
                  blockControls={[
                    'UL',
                    'OL',
                    'H1',
                    'H2',
                    'H3',
                    'H4',
                    'H5',
                    'H6',
                    'Blockquote',
                  ]}
                  controlDisplay="inline"
                  customBlocks={defaultBlocks}
                  customBlockControls={Object.keys(defaultBlocks)}
                />
              </FormField>
            </div>
            {!emailType.get('samples').isEmpty() && (
              <ViewSampleEmailTemplatesModal
                emailType={emailType}
                onApply={(subject, body) => {
                  change('subject', subject);
                  change('body', body);
                  setBodyKey(keygen());
                }}
                trigger={
                  <button
                    className="button button--plain button--icon"
                    type="button"
                  >
                    <Icon icon="List" />
                    View Sample Templates
                  </button>
                }
              />
            )}
            {canManageSampleEmailTemplate &&
              getValue('subject') &&
              getValue('body') && (
                <AddSampleEmailTemplateModal
                  emailType={emailType}
                  subject={getValue('subject')}
                  body={getValue('body')}
                  trigger={
                    <button
                      key="contact-list-add"
                      type="button"
                      className="button button--plain button--icon"
                    >
                      <Icon icon="AddCircle" />
                      Add as Sample Template
                    </button>
                  }
                />
              )}
          </div>
        )}
      </FormSection>
    </ExpandableRow>
  );
};

EmailTemplate.propTypes = {
  emailType: PropTypes.object.isRequired,
};

const EmailSettingsSection = ({ type }) => {
  const emailTypes = useSelector(state =>
    getApplicationEmailTypes(state, { type }),
  );

  if (!emailTypes) return <LoadingIndicator />;

  return (
    <div className="generic-form__body">
      <div className="input-group">
        <FormSection name="emailTemplates">
          {emailTypes?.map(emailType => (
            <EmailTemplate key={emailType.get('id')} emailType={emailType} />
          ))}
        </FormSection>
      </div>
    </div>
  );
};

EmailSettingsSection.propTypes = {
  type: PropTypes.string.isRequired,
};

export default EmailSettingsSection;
