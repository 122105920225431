import { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Grid, Table } from 'semantic-ui-react';
import { parse } from 'csv-parse/browser/esm/sync';
import { showNotification } from '../notification/notification-actions';
import {
  fetchCateringUploads,
  getCateringUploadTemplate,
  uploadCatering,
} from './catering-actions';
import { getCurrentEvent } from '../event/event-selectors';
import { getSearchDepartmentSlugs } from '../search/search-selectors';
import { StyledButton } from '../common/StyledButton';
import config from '../config';
import moment from 'moment';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import FileSaver from 'file-saver';
import bytesToSize from '../common/utils/bytesToSize';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import StatusButton from '../common/StatusButton';
import ExpandableRow from '../common/ExpandableRow';
import Icon from '../common/icons/Icon';

class ParseError extends Error {
  constructor(message, lines, index, value) {
    super(message);
    this.name = 'ParseError';
    this.lines = lines;
    this.index = index;
    this.code = value;
  }
}

const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const CateringUpload = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const departmentSlugs = useSelector(getSearchDepartmentSlugs);
  const mealTypes = useSelector(state =>
    state.catering.mealTypeList.get('data'),
  );
  const periods = event?.get('periods');

  const [validationErrors, setValidationErrors] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [toggles, setToggles] = useState({});

  const isProcessing =
    statuses.length > 0 ? statuses[0].status === 'Processing' : false;

  const checkUploadStatus = useCallback(
    async (uploadStatus = null) => {
      const updateUploadStatus = uploadStatus => {
        setStatuses(uploadStatus);

        if (uploadStatus[0]?.status === 'Processing') {
          setTimeoutId(setTimeout(() => checkUploadStatus(), 5000));
        }
      };

      if (uploadStatus) {
        updateUploadStatus(uploadStatus);
      } else {
        dispatch(fetchCateringUploads(event.get('id'))).then(action => {
          if (action.response.ok) {
            updateUploadStatus(action.json);
          } else {
            console.error('error polling csv status ', action.response);
            dispatch(
              showNotification({
                status: 'error',
                message: `Error fetching Catering Upload Status: ${action.response}`,
              }),
            );
          }
        });
      }
    },
    [dispatch, event],
  );

  useEffect(() => {
    if (event) checkUploadStatus();
  }, [checkUploadStatus, event]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const toggle = status => {
    setToggles(oldToggles => {
      const newToggles = { ...oldToggles };
      newToggles[status.id ?? status] = !newToggles[status.id ?? status];
      return newToggles;
    });
  };

  const isToggled = status => toggles[status.id ?? status];

  const getMessage = ({ status, departments }) => {
    if (status === 'Processing' || !departments) return null;

    let erroredDepartments = departments.reduce(
      (count, department) => (!department.success ? count + 1 : count),
      0,
    );

    let message =
      departments.length === 1
        ? 'Uploaded 1 department.'
        : `Uploaded ${departments.length} departments.`;

    if (status === 'Error')
      message +=
        erroredDepartments === 1
          ? ' 1 department had errors.'
          : ` ${erroredDepartments} departments had errors.`;

    return message;
  };

  const getTemplate = () => {
    getCateringUploadTemplate(event.get('id'))
      .then(response => response.blob())
      .then(blob => {
        FileSaver.saveAs(blob, `${event.get('slug')}_catering_upload.csv`);
      });
  };

  const onDropAccepted = onChange => files => {
    if (files && files.length > 0) {
      onChange(files[0]);
      setValidationErrors([]);
    }
  };

  const CsvDropzone = ({ value, onChange, submitSucceeded }) => (
    <Dropzone
      accept={{ 'text/csv': [] }}
      maxFiles={1}
      maxSize={1024 * 1024 * 3}
      multiple={false}
      onDropAccepted={onDropAccepted(onChange)}
    >
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
        <div
          {...getRootProps({
            className: `dropzone${isDragAccept ? ' dropzone--active' : ''}`,
            style: isDragReject
              ? {
                  background: 'rgba(255, 0, 0, 0.4)',
                  borderColor: 'rgb(255, 0, 0)',
                }
              : {},
          })}
        >
          <input {...getInputProps()} />
          <div className="dropzone-instructions">
            <div className="dropzone-instructions--main">
              Drag-n-drop or click to upload a file
            </div>
            <div className="dropzone-instructions--sub">
              <p>Accepted file type: .csv</p>
            </div>
            <div className="dropzone-instructions--sub">
              <p>Max file size: 3MB</p>
            </div>
            {value && (
              <aside
                style={{
                  textAlign: 'left',
                  borderTop: '2px dashed #ddd',
                  marginTop: '10px',
                  paddingTop: '10px',
                }}
              >
                <h4>{submitSucceeded ? 'Uploaded File' : 'File to Upload'}</h4>
                <p style={{ paddingTop: '5px' }}>
                  {value.name} ({bytesToSize(value.size)})
                </p>
              </aside>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );

  const uploadColumns = {};
  const fileColumns = {
    Department: 'department',
    Period: 'period',
    Date: 'date',
  };

  const parseFile = async file => {
    const requiredColumns = {
      Department: false,
      Period: false,
      Date: false,
    };

    const parsedFile = await parse(await file.text(), {
      skip_empty_lines: true,
      skip_records_with_error: false,
      skip_records_with_empty_values: false,
      trim: true,
      columns: header => {
        const newHeader = [];
        for (const column of header) {
          if (column in fileColumns) {
            uploadColumns[fileColumns[column]] = column;
            requiredColumns[column] = true;
            newHeader.push(fileColumns[column]);
          } else {
            const mealType = mealTypes.find(mt => mt.get('name') === column);

            if (mealType) {
              const uploadColumn = `meal_type_id-${mealType.get('id')}`;
              uploadColumns[uploadColumn] = column;
              requiredColumns[uploadColumn] = true;
              newHeader.push(uploadColumn);
            } else throw new Error(`File contains invalid column "${column}"`);
          }
        }
        return newHeader;
      },
      cast: (value, { header, lines, column }) => {
        if (header) return value;
        else if (column === 'department') {
          if (!departmentSlugs.has(value)) {
            throw new ParseError(
              'Department does not exist',
              lines,
              uploadColumns[column],
              value,
            );
          }
          return value;
        } else if (column === 'period') {
          const period = periods.find(
            p => p.get('name').toLowerCase() === value.toLowerCase(),
          );
          if (period) return period;
          else
            throw new ParseError(
              'Invalid Period',
              lines,
              uploadColumns[column],
              value,
            );
        } else if (column === 'date') {
          return moment.utc(value);
        } else {
          if (value === '') return undefined;
          if (isNaN(value))
            throw new ParseError(
              `Invalid Meal Count`,
              lines,
              uploadColumns[column],
              value,
            );
          return parseFloat(value);
        }
      },
    });

    const missingColumns = [];
    let missingMealTypeColumn = true;

    for (const [column, isIncluded] of Object.entries(requiredColumns)) {
      if (!isIncluded) missingColumns.push(column);
      if (column.startsWith('meal_type_id-')) missingMealTypeColumn = false;
    }

    if (missingColumns.length > 0)
      throw new Error(
        `Missing required columns: ${missingColumns
          .map(column => `'${column}'`)
          .join(', ')}`,
      );

    if (missingMealTypeColumn)
      throw new Error('Missing at least one Meal Type column');

    return parsedFile;
  };

  const handleSubmit = async values => {
    let rows;
    try {
      rows = await parseFile(values.file);
    } catch (error) {
      console.error(error);
      setValidationErrors([
        {
          row: error.lines,
          field: error.index,
          value: error.code,
          message: error.message,
        },
      ]);
      return {
        file: error.message,
      };
    }

    if (rows?.length === 0) {
      setValidationErrors([
        { message: 'File must contain at least one department' },
      ]);
      return {
        file: 'File must contain at least one department',
      };
    }

    const cateringMap = new Map();

    const newValidationErrors = [];

    rows.forEach((row, index) => {
      let availability = [];

      if (cateringMap.has(row.department)) {
        availability = cateringMap.get(row.department).availability;
      } else {
        cateringMap.set(row.department, {
          department: row.department,
          availability,
          replace: false,
        });
      }

      const periodDate = row.period
        .get('dates')
        .find(date => moment.utc(date.get('date')).isSame(row.date));

      if (!periodDate) {
        newValidationErrors.push({
          row: index + 2,
          department: row.department,
          field: 'Date',
          value: row.date.format('YYYY-MM-DD'),
          message: `Date is not valid for Period '${row.period.get('name')}'`,
        });

        return;
      }

      for (const [key, value] of Object.entries(row)) {
        if (value === undefined) continue;
        if (key.startsWith('meal_type_id-')) {
          const mealTypeId = Number(key.replace('meal_type_id-', ''));
          const mealType = mealTypes.find(
            mt => Number(mt.get('id')) === mealTypeId,
          );
          const mealTypeDate = mealType
            .get('dates')
            .find(mtd => mtd.get('id') === periodDate.get('id'));

          if (!mealTypeDate) {
            newValidationErrors.push({
              row: index + 2,
              department: row.department,
              field: 'Date',
              value: row.date.format('YYYY-MM-DD'),
              message: `Date is not valid for Meal type '${mealType.get(
                'name',
              )}'`,
            });

            return;
          }

          availability.push({
            mealTypeDateId: mealTypeDate.get('_pivot_id'),
            mealCount: value,
          });
        }
      }
    });

    if (newValidationErrors.length > 0) {
      setValidationErrors(newValidationErrors);
      return { [FORM_ERROR]: 'Validation Errors' };
    }

    const filename = values.file.name;
    const catering = Array.from(cateringMap.values());

    return dispatch(
      uploadCatering(event.get('id'), {
        filename,
        catering,
      }),
    ).then(action => {
      if (action.response.ok) {
        checkUploadStatus(action.json);
      } else {
        const errors = [];
        if (action.json.validationErrors) {
          action.json.validationErrors?.forEach(validationError => {
            let error = {
              message:
                config.validationMessages[validationError.type] ??
                validationError.message,
            };
            if (validationError.context.key === 'department') {
              error = {
                row: validationError.key[1] + 2,
                department: validationError.context.value,
                field: uploadColumns[validationError.context.key],
                value:
                  typeof validationError.context.value === 'string'
                    ? validationError.context.value
                    : null,
                ...error,
              };
            }
            errors.push(error);
          });
          setValidationErrors(errors);
          return { [FORM_ERROR]: 'Validation Errors' };
        }
      }
    });
  };

  const renderDepartments = (departments, index) => {
    if (!departments) return null;

    return departments.map(
      ({ success, error, catering: { department, availability } }, idx) => (
        <Table.Row
          key={`${index}-uploaded-department-${idx}`}
          negative={!success}
        >
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell singleLine>{success ? 'Uploaded' : 'Error'}</Table.Cell>
          <Table.Cell>{department}</Table.Cell>
          <Table.Cell>
            {success ? (
              <span>
                {availability.length > 0
                  ? availability.length === 1
                    ? `${availability.length} meal`
                    : `${availability.length} meals`
                  : ''}
              </span>
            ) : (
              <span>{error}</span>
            )}
          </Table.Cell>
          <Table.Cell />
        </Table.Row>
      ),
    );
  };

  if (mealTypes.size === 0)
    return (
      <Paper>
        <PaperHeader title="Upload Catering" />
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>There are no meal types specified for this event.</p>
          <p>Start by first adding a meal type.</p>
        </div>
      </Paper>
    );

  return (
    <Form
      onSubmit={handleSubmit}
      render={({
        handleSubmit,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
        submitFailed,
        submitSucceeded,
      }) => {
        let status;
        if (submitting || isProcessing) status = 'loading';
        else if (modifiedSinceLastSubmit) status = 'default';
        else if (submitSucceeded) status = 'success';
        else if (submitFailed) status = 'error';
        else status = 'default';

        const disabled =
          pristine ||
          submitting ||
          isProcessing ||
          (submitFailed && !modifiedSinceLastSubmit);

        return (
          <div>
            <Paper>
              <PaperHeader title="Upload Catering" />
              <form
                onSubmit={handleSubmit}
                className="generic-form paper__wrapper"
              >
                <div className="generic-form__body">
                  <Column>
                    <Header>1. Export Template:</Header>
                    <Row>
                      <StyledButton
                        type="button"
                        title="Download Template"
                        color="blue"
                        onClick={getTemplate}
                        style={{
                          width: '250px',
                          alignSelf: 'end',
                        }}
                      />
                    </Row>
                  </Column>
                  <Column>
                    <Header>2. Upload CSV File:</Header>
                    <Row>
                      <Field
                        name="file"
                        component={ReduxFormsFieldNoLabel}
                        label=""
                        required
                      >
                        <CsvDropzone submitSucceeded={submitSucceeded} />
                      </Field>
                    </Row>
                  </Column>
                  <Column>
                    {validationErrors.length > 0 && (
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Header style={{ paddingLeft: '10px' }}>
                              CSV FILE NOT PROCESSED - Please correct the errors
                              and re-upload:
                            </Header>
                            <Table collapsing padded selectable>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>Row #</Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Department
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>Field</Table.HeaderCell>
                                  <Table.HeaderCell>Value</Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Error Message
                                  </Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                {validationErrors.map(ve => (
                                  <Table.Row negative key={ve.field}>
                                    <Table.Cell>{ve.row}</Table.Cell>
                                    <Table.Cell>{ve.department}</Table.Cell>
                                    <Table.Cell>{ve.field}</Table.Cell>
                                    <Table.Cell>{ve.value}</Table.Cell>
                                    <Table.Cell>{ve.message}</Table.Cell>
                                  </Table.Row>
                                ))}
                              </Table.Body>
                            </Table>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )}
                    {statuses.length > 0 && (
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Header>Last Upload Status:</Header>
                            <Table collapsing padded selectable>
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell>
                                    Uploaded By
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>File</Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Start Time
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>End Time</Table.HeaderCell>
                                  <Table.HeaderCell>Status</Table.HeaderCell>
                                  <Table.HeaderCell>
                                    Department
                                  </Table.HeaderCell>
                                  <Table.HeaderCell>Message</Table.HeaderCell>
                                  <Table.HeaderCell />
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                                <Table.Row
                                  negative={statuses[0].status === 'Error'}
                                >
                                  <Table.Cell>
                                    {statuses[0].uploadedBy.first_name}{' '}
                                    {statuses[0].uploadedBy.last_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {statuses[0].file_name}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {moment(statuses[0].start_time).format(
                                      'MMM DD h:mm:ss a',
                                    )}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {statuses[0].end_time
                                      ? moment(statuses[0].end_time).format(
                                          'MMM DD h:mm:ss a',
                                        )
                                      : ''}
                                  </Table.Cell>
                                  <Table.Cell>{statuses[0].status}</Table.Cell>
                                  <Table.Cell />
                                  <Table.Cell>
                                    {getMessage(statuses[0])}
                                  </Table.Cell>
                                  <Table.Cell>
                                    <div onClick={() => toggle('current')}>
                                      {isToggled('current') && (
                                        <Icon
                                          icon="Caret"
                                          style={{
                                            top: '5px',
                                            transform: 'rotate(180deg)',
                                          }}
                                        />
                                      )}
                                      {!isToggled('current') && (
                                        <Icon icon="Caret" />
                                      )}
                                    </div>
                                  </Table.Cell>
                                </Table.Row>
                                {isToggled('current') &&
                                  renderDepartments(
                                    statuses[0].departments,
                                    'current',
                                  )}
                              </Table.Body>
                            </Table>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    )}
                  </Column>
                </div>
                <div className="generic-form__footer">
                  <StatusButton
                    buttonText="Upload Catering"
                    completeText="Catering Uploaded"
                    disabled={disabled}
                    status={status}
                    type="submit"
                  />
                </div>
              </form>
            </Paper>
            {statuses.length > 0 && (
              <ExpandableRow
                isExpandable
                isExpanded={expanded}
                onToggleExpansion={() => setExpanded(!expanded)}
                columns={['Upload History']}
              >
                <div className="generic-form__body">
                  <Column>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <Table collapsing padded selectable>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>Uploaded By</Table.HeaderCell>
                                <Table.HeaderCell>File</Table.HeaderCell>
                                <Table.HeaderCell>Start Time</Table.HeaderCell>
                                <Table.HeaderCell>End Time</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Department</Table.HeaderCell>
                                <Table.HeaderCell>Message</Table.HeaderCell>
                                <Table.HeaderCell />
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {statuses.map(status => (
                                <Fragment key={status.id}>
                                  <Table.Row
                                    negative={status.status === 'Error'}
                                  >
                                    <Table.Cell>
                                      {status.uploadedBy.first_name}{' '}
                                      {status.uploadedBy.last_name}
                                    </Table.Cell>
                                    <Table.Cell>{status.file_name}</Table.Cell>
                                    <Table.Cell>
                                      {moment(status.start_time).format(
                                        'MMM DD h:mm:ss a',
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {status.end_time
                                        ? moment(status.end_time).format(
                                            'MMM DD h:mm:ss a',
                                          )
                                        : ''}
                                    </Table.Cell>
                                    <Table.Cell singleLine>
                                      {status.status}
                                    </Table.Cell>
                                    <Table.Cell />
                                    <Table.Cell>
                                      {getMessage(status)}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <div onClick={() => toggle(status)}>
                                        {isToggled(status) && (
                                          <Icon
                                            icon="Caret"
                                            style={{
                                              top: '5px',
                                              transform: 'rotate(180deg)',
                                            }}
                                          />
                                        )}
                                        {!isToggled(status) && (
                                          <Icon icon="Caret" />
                                        )}
                                      </div>
                                    </Table.Cell>
                                  </Table.Row>
                                  {isToggled(status) &&
                                    renderDepartments(
                                      status.departments,
                                      status.id,
                                    )}
                                </Fragment>
                              ))}
                            </Table.Body>
                          </Table>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Column>
                </div>
              </ExpandableRow>
            )}
          </div>
        );
      }}
    />
  );
};

export default CateringUpload;
