import { useId } from 'react';
import { Radio } from 'synfrastructure';
import { Tooltip } from 'react-tooltip';
import PropTypes from 'prop-types';
import ToggleScaffold from '../../common/forms/ToggleScaffold';

const RadioGroup = ({ options, value, onChange }) => {
  const id = useId();

  return (
    <div className="radio-button-group">
      {options.map((option, index) => (
        <ToggleScaffold key={option.value} label={option.label}>
          {option.tooltip && (
            <Tooltip
              id={`${id}-tooltip-${index}`}
              content={option.tooltip}
              offset={0}
            />
          )}
          {option.tooltip && (
            <span data-tooltip-id={`${id}-tooltip-${index}`}>
              <Radio
                id={`${id}-radio-${index}`}
                value={option.value}
                checked={option.value === value}
                onChange={() => onChange(option.value)}
                disabled={option.value === 'disabled'}
              />
            </span>
          )}
          {!option.tooltip && (
            <Radio
              id={`${id}-radio-${index}`}
              value={option.value}
              checked={option.value === value}
              onChange={() => onChange(option.value)}
              disabled={option.value === 'disabled'}
            />
          )}
        </ToggleScaffold>
      ))}
    </div>
  );
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
        .isRequired,
      value: PropTypes.any.isRequired,
      tooltip: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default RadioGroup;
