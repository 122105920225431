import styled from 'styled-components';
import { media } from '../styled-components/breakpoints';

export const PageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex: 1;
  flex-direction: column;
  background: #fff;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 75px;
  background: #000;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  > h1 {
    color: #ffffff;
    margin-top: 10px;
    font-size: 1.5rem;
    ${media.tabletPortrait`
    font-size: 1.2rem;`};
  }
  > h2 {
    color: #ffffff;
    margin-top: 10px;
    font-size: 1rem;
    letter-spacing: 1.57px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 10%;
`;

export const ErrorMessage = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
`;
