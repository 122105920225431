import { GROUP_PERSONNEL } from './ContactCredentials';
import { getMaxForUnsavedQuantity } from './contact-selectors';
import {
  useUnsavedCredentialsDispatch,
  useUnsavedCredentialQuantity,
} from './UnsavedCredentials';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import QuantityField from '../common/forms/QuantityField';
import { useDepartmentUnsavedCredentialsDispatch } from './DepartmentUnsavedCredentials';

const UnsavedQuantityField = ({
  type,
  department,
  credential,
  isLocked = false,
}) => {
  const unsavedCredentialsDispatch = useUnsavedCredentialsDispatch();
  const departmentUnsavedCredentialsDispatch =
    useDepartmentUnsavedCredentialsDispatch();

  const credentialId = credential.get('id');
  const maxQuantity = getMaxForUnsavedQuantity(type, department, credential);
  const unsavedCredentialQuantity = useUnsavedCredentialQuantity(credentialId);

  const quantity =
    type !== GROUP_PERSONNEL
      ? unsavedCredentialQuantity
      : maxQuantity < unsavedCredentialQuantity
      ? maxQuantity
      : unsavedCredentialQuantity;

  const onChange = newQuantity => {
    unsavedCredentialsDispatch({
      type: 'change',
      changedQuantities: [{ credentialId, quantity: newQuantity }],
    });

    departmentUnsavedCredentialsDispatch({
      type: 'add',
      credentialId,
      quantity: newQuantity - quantity,
    });
  };

  const onDelete = () => {
    unsavedCredentialsDispatch({
      type: 'change',
      changedQuantities: [{ credentialId, quantity: 0 }],
    });

    departmentUnsavedCredentialsDispatch({
      type: 'subtract',
      credentialId,
      quantity,
    });
  };

  if (quantity === 0) {
    if (isLocked || maxQuantity === 0) {
      return null;
    }
    return (
      <div style={{ display: 'flex' }}>
        <button type="button" onClick={() => onChange(1)} className="add-cred">
          <Icon icon="Plus" />
        </button>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <QuantityField
        id={credential.get('id')}
        value={quantity}
        min={maxQuantity === 0 ? 0 : 1}
        max={maxQuantity}
        maxHidden
        onChange={onChange}
      />
      <button type="button" onClick={onDelete} className="remove-cred">
        <Icon icon="CloseCircle" />
      </button>
    </div>
  );
};

UnsavedQuantityField.propTypes = {
  type: PropTypes.string.isRequired,
  department: PropTypes.object.isRequired,
  contact: PropTypes.object,
  credential: PropTypes.object.isRequired,
  isLocked: PropTypes.bool,
};

export default UnsavedQuantityField;
