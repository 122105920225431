import { useContext } from 'react';
import { Field } from 'react-final-form';
import { FormSectionContext } from './FormSectionContext';

const FormField = ({ name, ...props }) => {
  const prefix = useContext(FormSectionContext);
  return <Field name={prefix ? `${prefix}.${name}` : name} {...props} />;
};

export default FormField;
