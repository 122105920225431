import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { stripTrailingSlash } from '../common/uri';
import { getCurrentPromoter } from '../promoter/promoter-selectors';
import CardListItem from '../common/CardListItem';
import Icon from '../common/icons/Icon';
import Paper from '../common/paper/Paper';
import LoadingIndicator from '../common/LoadingIndicator';

const getEventDateString = periods => {
  let start, end, currentDate;
  const options = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  };

  if (periods.size === 0) {
    return 'No dates set for event.';
  }

  periods.forEach(period => {
    period.get('dates').forEach(date => {
      currentDate = new Date(date.get('date'));
      if (!start || currentDate < start) {
        start = currentDate;
      }

      if (!end || currentDate > end) {
        end = currentDate;
      }
    });
  });
  if (start === end) {
    return start.toLocaleDateString('en-us', options);
  }
  return `${start.toLocaleDateString(
    'en-us',
    options,
  )} - ${end.toLocaleDateString('en-us', options)}`;
};

const aclRules = {
  canEditPromoter: [resource.PROMOTER, 'edit'],
  canEditFestival: [resource.FESTIVAL, 'edit'],
};

const FestivalList = () => {
  const acl = useACL(aclRules);

  const location = useLocation();
  const params = useParams();

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const festivalList = useSelector(state =>
    promoter ? state.festival.list.getIn([promoter.get('id'), 'data']) : null,
  );

  const [activeCard, setActiveCard] = useState();

  const renderFestivalKebabMenu = festival => {
    const thisPath = stripTrailingSlash(location.pathname);

    return (
      <div>
        {acl.canEditFestival ? (
          <Link
            to={`${thisPath}/${festival.get('slug')}/edit`}
            className="dropdown__item"
          >
            <div className="dropdown__item__children">
              <Icon icon="Pencil" />
              <span className="text">Edit Festival</span>
            </div>
          </Link>
        ) : null}
        {acl.canEditFestival ? (
          <Link
            to={`${thisPath}/${festival.get('slug')}/events/~add`}
            className="dropdown__item"
          >
            <div className="dropdown__item__children">
              <Icon icon="Plus" />
              <span className="text">Add New Event</span>
            </div>
          </Link>
        ) : null}
        <Link
          to={`${thisPath}/${festival.get('slug')}/events`}
          className="dropdown__item"
        >
          <div className="dropdown__item__children">
            <Icon icon="Hamburger" />
            <span className="text">View Events</span>
          </div>
        </Link>
      </div>
    );
  };

  const renderList = () => {
    return (
      <div className="card-list">
        {festivalList.map((festival, index) => {
          const latestEvent = festival.getIn(['events', 0]);
          return (
            <CardListItem
              key={festival.get('id')}
              linkTo={`/promoters/${promoter.get(
                'slug',
              )}/festivals/${festival.get('slug')}/events/${latestEvent.get(
                'slug',
              )}`}
              name={latestEvent.get('name')}
              image={
                latestEvent.get('file_id')
                  ? latestEvent.getIn(['logo', 'location'])
                  : false
              }
              subText={getEventDateString(latestEvent.get('periods'))}
              menuActive={activeCard === index}
              onMenuToggle={() =>
                activeCard === index
                  ? setActiveCard(false)
                  : setActiveCard(index)
              }
            >
              {renderFestivalKebabMenu(festival)}
            </CardListItem>
          );
        })}
        {acl.canEditPromoter ? (
          <Link
            className="card__link card--add"
            to={`/promoters/${promoter.get('slug')}/festivals/~add`}
          >
            <div>
              <Icon icon="Plus" />
              <span className="text">Add New</span>
            </div>
          </Link>
        ) : null}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <Paper className="card-list--empty">
        <Icon icon="Sad" />
        <p>No festivals have been added for this promoter.</p>
        {acl.canEditPromoter ? (
          <Link
            className="button"
            to={`/promoters/${promoter.get('slug')}/festivals/~add`}
          >
            Add Festival
          </Link>
        ) : null}
      </Paper>
    );
  };

  return !festivalList ? (
    <LoadingIndicator />
  ) : !festivalList.size ? (
    renderEmpty()
  ) : (
    renderList()
  );
};

export default FestivalList;
