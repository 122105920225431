import { combineValidators, composeValidators, isRequired } from 'revalidate';
import mustBeLessThanOrEqualTo from '../../common/validate/must-be-less-than-or-equal-to';

export const addPeriodValidator = combineValidators({
  name: isRequired('Name'),
  start_date: composeValidators(
    isRequired,
    mustBeLessThanOrEqualTo('end_date', 'End Date'),
  )({ message: 'Start Date cannot be later than End Date' }),
  end_date: isRequired('End Date'),
  pulse_event_id: isRequired('Pulse Event'),
});

export const editPeriodValidator = combineValidators({
  name: isRequired('Name'),
});
