import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getCurrentEvent,
  getEnabledEventSections,
} from '../event/event-selectors';
import { fetchDepartmentGroupList } from './department-actions';
import { getDepartmentGroupList } from './department-selectors';
import departmentTypeMap from '../lib/department-type-map';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReactTable from '../lib/react-table';
import InputScaffold from '../common/forms/InputScaffold';
import Icon from '../common/icons/Icon';

const DepartmentGroups = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const enabledSections = useSelector(state =>
    getEnabledEventSections(state, { params }),
  );

  const departmentGroupList = useSelector(state =>
    getDepartmentGroupList(state, { params }),
  );

  const pageSettings = useSelector(state =>
    state.nav.pageSettings.get(location.pathname),
  );

  const [selectedSection, setSelectedSection] = useState(
    pageSettings?.section ?? 'production',
  );

  const actions = [
    <Link
      key="create-group-action"
      className="button button--plain button--icon"
      to={`${location.pathname}/add`}
    >
      <Icon icon="AddCircle" />
      <span>Create Group</span>
    </Link>,
  ];

  const columns = [
    { header: 'Name', accessor: 'name' },
    { header: 'default', accessor: 'isDefault' },
  ];

  const departmentGroupOptions =
    departmentGroupList &&
    departmentGroupList.getIn([selectedSection, 'data']) &&
    departmentGroupList.getIn([selectedSection, 'data']).size
      ? Array.from(
          departmentGroupList
            .getIn([selectedSection, 'data'])
            .map(dg => {
              return {
                value: dg.get('id').toString(),
                label: dg.get('name'),
                isDefault: dg.get('isDefault'),
              };
            })
            .values(),
        )
      : null;

  const tableData = departmentGroupOptions
    ? departmentGroupOptions.map(opt => ({
        name: opt.label,
        id: opt.value,
        isDefault: opt.isDefault ? '(Default Group)' : '',
      }))
    : [];

  useEffect(() => {
    if (event && selectedSection)
      dispatch(fetchDepartmentGroupList(event.get('id'), selectedSection));
  }, [dispatch, event, selectedSection]);

  const renderSectionOptions = () => {
    const options = [];
    options.push(
      ...enabledSections.map(section => ({
        label: departmentTypeMap[section].label.plural,
        value: section,
      })),
    );

    return renderOptions(options);
  };

  const renderOptions = options => {
    return options.map(option => (
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    ));
  };

  return (
    <div>
      <Paper>
        <PaperHeader title="Group Permission" actions={actions} />
        <p style={{ padding: '1em' }}>
          Group permission allows you to manage credentials on a group level.
          You can group departments into a specific group, then enable or
          disable department credentials and meal types in one place.
        </p>
        <p style={{ padding: '0 1em 1em 1em' }}>
          To create a new group, click{' '}
          <Link to={`${location.pathname}/add`}>Create Group</Link>
        </p>
        <hr />
        <div className="generic-form paper__wrapper">
          <div className="generic-form__body ">
            <div className="input-group" style={{ maxWidth: '470px' }}>
              {enabledSections && (
                <InputScaffold label="Section" required>
                  <select
                    value={selectedSection}
                    onChange={e => setSelectedSection(e.target.value)}
                  >
                    {renderSectionOptions()}
                  </select>
                </InputScaffold>
              )}
            </div>
            <div>
              <h4>
                <strong>
                  {!tableData.length && <span>No </span>}
                  <span>
                    {departmentTypeMap[selectedSection].label.singular}{' '}
                    Department Groups
                  </span>
                </strong>
              </h4>
              <ReactTable
                style={{
                  borderWidth: '0px',
                  marginTop: '15px',
                  maxWidth: '470px',
                }}
                data={tableData}
                columns={columns}
                defaultSorted={[
                  {
                    id: 'name',
                    asc: true,
                  },
                ]}
                minRows={0}
                defaultPageSize={10}
                showPageSizeOptions={false}
                showPagination={false}
                TheadComponent={() => null}
                NoDataComponent={() => null}
                showFilters={false}
                getTrGroupProps={(_state, rowInfo) => ({
                  style: { border: `none` },
                  onClick: () => {
                    navigate(
                      `${location.pathname}/edit/${rowInfo.original.id}`,
                    );
                  },
                })}
              />
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default DepartmentGroups;
