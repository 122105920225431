import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useACL } from './ACL';
import PropTypes from 'prop-types';
import NotFound from './NotFound';

export const ACL404 = ({ action, children }) => {
  const aclRules = useMemo(
    () => ({
      canViewThisPage: action,
    }),
    [action],
  );

  const acl = useACL(aclRules);

  if (!('canViewThisPage' in acl)) return null;

  return acl.canViewThisPage ? children ?? <Outlet /> : <NotFound />;
};

ACL404.propTypes = {
  action: PropTypes.array.isRequired,
  children: PropTypes.node,
};
