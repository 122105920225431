export const SET_TOPBAR_TITLE = 'SET_TOPBAR_TITLE';
export function setTopbarTitle(title) {
  return {
    type: SET_TOPBAR_TITLE,
    title,
  };
}

export const SET_PAGE_SETTINGS = 'SET_PAGE_SETTINGS';
export const setPageSettings = pageSettings => ({
  type: SET_PAGE_SETTINGS,
  payload: pageSettings,
});
