import React from 'react';

function Lodging() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M19 6.5h-8v7H3v-9H1v15h2v-3h18v3h2v-9a4 4 0 0 0-4-4zm-12 6a3 3 0 1 0-3-3 3 3 0 0 0 3 3z" />
    </svg>
  );
  /* eslint-enable */
}

export default Lodging;
