import { useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import {
  composeValidators,
  hasLengthGreaterThan,
  isRequired,
} from 'revalidate';
import moment from 'moment';
import difference from 'lodash/difference';
import isValidEmail from '../../validate/is-valid-email';
import Icon from '../../icons/Icon';
import ReduxFormsField from '../../forms/ReduxFormsField';
import CheckboxGroup from '../../forms/CheckboxGroup';
import RadioGroup from '../../forms/RadioGroup';

const draftRenderer = new Renderer(defaultBlocks);

const PersonnelSection = ({ className, section, credentials }) => {
  const { getFieldState } = useForm();

  const [personnelCounter, setPersonnelCounter] = useState(2);

  const pushPersonnelFields = fields => {
    fields.push({});
    setPersonnelCounter(
      previousPersonnelCounter => previousPersonnelCounter - 1,
    );
  };

  const removePersonnelFields = (fields, index) => {
    fields.remove(index);
    setPersonnelCounter(
      previousPersonnelCounter => previousPersonnelCounter + 1,
    );
  };

  const getCredentialOption = credential => {
    const credentialType = credential.get('credentialType');
    const periodName =
      credentialType.get('issue_frequency') === 'ONE_TIME'
        ? credential
            .get('oneTimePeriods')
            .map(period => period.get('name'))
            .join(', ')
        : credential.getIn(['period', 'name']);
    const date =
      credentialType.get('issue_frequency') === 'DAILY'
        ? credential.getIn(['date', 'date'])
        : null;

    const label = (
      <span>
        <span style={{ marginLeft: '10px', fontWeight: '700' }}>
          {credentialType.get('name')}
        </span>
        <span style={{ marginLeft: '10px' }}>{periodName}</span>
        {date && (
          <span style={{ marginLeft: '5px', fontSize: 'smaller' }}>
            ({moment.utc(date).format('ddd, MMM DD')})
          </span>
        )}
      </span>
    );

    return {
      label,
      value: credential.get('id').toString(),
      tooltip: credential.get('_pivot_is_single_instance')
        ? 'Credential can only be requested for one person'
        : null,
    };
  };

  const onCredentialsChange = (
    fields,
    fieldsIndex,
    newValue,
    previousValue,
  ) => {
    if (newValue && previousValue && newValue.length <= previousValue.length)
      return;

    const selectedCredentialId = difference(newValue, previousValue)[0];
    const selectedCredential = credentials.find(
      credential => String(credential.get('id')) === selectedCredentialId,
    );

    if (!selectedCredential?.get('_pivot_is_single_instance')) return;

    fields.forEach((field, index) => {
      if (index === fieldsIndex) return;

      const credentialsFieldState = getFieldState(`${field}.credentials`);
      if (!credentialsFieldState.value) return;

      const entryCredentialsIndex = credentialsFieldState.value.findIndex(
        entryCredential => entryCredential === selectedCredentialId,
      );

      if (entryCredentialsIndex > -1) {
        const newEntryCredentials = Array.from(credentialsFieldState.value);
        newEntryCredentials.splice(entryCredentialsIndex, 1);
        credentialsFieldState.change(newEntryCredentials);
      }
    });
  };

  const onAddOnCredentialsChange = (fields, fieldsIndex, newValue) => {
    const selectedCredentialId = newValue;
    const selectedCredential = credentials.find(
      credential =>
        String(credential.get('id')) === String(selectedCredentialId),
    );

    if (!selectedCredential?.get('_pivot_is_single_instance')) return;

    fields.forEach((field, index) => {
      if (index === fieldsIndex) return;

      const credentialsFieldState = getFieldState(`${field}.credentials_addon`);
      if (!credentialsFieldState.value) return;

      if (
        String(credentialsFieldState.value) === String(selectedCredentialId)
      ) {
        credentialsFieldState.change(0);
      }
    });
  };

  const entryCredentialOptions = credentials
    .filter(credential => !credential.get('_pivot_is_add_on'))
    .map(credential => getCredentialOption(credential))
    .toArray();

  const addOnCredentialOptions = credentials
    .filter(credential => !!credential.get('_pivot_is_add_on'))
    .map(credential => getCredentialOption(credential))
    .toArray();

  return (
    <section className={className}>
      <header>
        <div className={`${className}--title`}>Personnel Information</div>
      </header>
      <FieldArray
        name="personnel"
        validate={composeValidators(
          isRequired,
          hasLengthGreaterThan(0),
        )({ message: 'Personnel is required' })}
      >
        {({ fields, meta: { error, submitFailed } }) => (
          <>
            <article>
              <div className={`${className}--desc`}>
                <div
                  className="draftjs-output"
                  dangerouslySetInnerHTML={{
                    __html: draftRenderer.convertRawToHTML(
                      section.get('content').toJS(),
                    ),
                  }}
                />
              </div>

              {fields.map((field, index) => (
                <div key={index}>
                  <span className="public-application__remove-personnel">
                    <span
                      className="click-target"
                      onClick={() => removePersonnelFields(fields, index)}
                    >
                      Remove Personnel #{index + 1}
                    </span>
                  </span>
                  <Field
                    name={`${field}.first_name`}
                    component={ReduxFormsField}
                    label={`Personnel #${index + 1} First Name`}
                    required
                    validate={isRequired('First Name')}
                  >
                    <input type="text" />
                  </Field>
                  <Field
                    name={`${field}.last_name`}
                    component={ReduxFormsField}
                    label={`Personnel #${index + 1} Last Name`}
                    required
                    validate={isRequired('Last Name')}
                  >
                    <input type="text" />
                  </Field>
                  <Field
                    name={`${field}.email`}
                    component={ReduxFormsField}
                    label={`Personnel #${index + 1} Email`}
                    required
                    validate={composeValidators(
                      isRequired,
                      isValidEmail,
                    )('Email')}
                  >
                    <input type="text" />
                  </Field>
                  {Boolean(entryCredentialOptions.length) && (
                    <Field
                      name={`${field}.credentials`}
                      component={ReduxFormsField}
                      label="Entry Credentials Needed"
                      required
                      onChange={(newValue, previousValue) =>
                        onCredentialsChange(
                          fields,
                          index,
                          newValue,
                          previousValue,
                        )
                      }
                      validate={composeValidators(
                        isRequired,
                        hasLengthGreaterThan(0),
                      )({
                        message: 'Personnel must have at least one credential',
                      })}
                    >
                      <CheckboxGroup options={entryCredentialOptions} />
                    </Field>
                  )}
                  {Boolean(addOnCredentialOptions.length) && (
                    <Field
                      name={`${field}.credentials_addon`}
                      component={ReduxFormsField}
                      label="Add On Credentials Needed"
                      onChange={newValue =>
                        onAddOnCredentialsChange(fields, index, newValue)
                      }
                    >
                      <RadioGroup options={addOnCredentialOptions} />
                    </Field>
                  )}
                </div>
              ))}
            </article>
            {personnelCounter > 0 && (
              <span
                className="button button--plain button--icon"
                style={{ padding: '0px', cursor: 'pointer' }}
                onClick={() => pushPersonnelFields(fields)}
              >
                <Icon icon="AddCircle" /> Add Personnel
              </span>
            )}
            {submitFailed && error && typeof error === 'string' && (
              <div className="input-scaffold">
                <span className="input-validation">{error}</span>
              </div>
            )}
          </>
        )}
      </FieldArray>
    </section>
  );
};

export default PersonnelSection;
