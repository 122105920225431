import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { isRequired } from 'revalidate';
import { v1 as keygen } from 'uuid';
import { defaultBlocks } from '../lib/draftjs-editor';
import { resource, useACL } from '../common/ACL';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import { getPeriodList } from '../credential/period/period-selectors';
import { addEmailTemplate, updateEmailTemplate } from '../event/event-actions';
import {
  getCurrentEmailTemplate,
  getCurrentEvent,
  getEmailTypeSamples,
  getEmailTypes,
} from '../event/event-selectors';
import styled from 'styled-components';
import departmentTypeMap from '../lib/department-type-map';
import bytesToSize from '../common/utils/bytesToSize';
import isNotEmpty from '../common/validate/array-is-not-empty';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import ReduxSynapseDraft from '../common/forms/ReduxSynapseDraft';
import SelectAllMultiSelect from '../common/forms/SelectAllMultiSelect';
import LoadingIndicator from '../common/LoadingIndicator';
import FormStatusButton from '../common/FormStatusButton';
import PaperSwitch from '../common/paper/PaperSwitch';
import Icon from '../common/icons/Icon';
import AddSampleEmailTemplateModal from './AddSampleEmailTemplateModal';
import ViewSampleEmailTemplatesModal from './ViewSampleEmailTemplatesModal';

const AttachmentDropzone = ({ value, onChange }) => (
  <Dropzone
    maxFiles={1}
    maxSize={1024 * 1024 * 3}
    multiple={false}
    onDropAccepted={acceptedFiles => {
      if (acceptedFiles?.length > 0) {
        onChange(acceptedFiles[0]);
      }
    }}
    onDropRejected={files => {
      onChange({ error: files[0].errors[0].message });
    }}
  >
    {({ getRootProps, getInputProps, isDragActive }) => (
      <div
        {...getRootProps({
          className: `dropzone ${isDragActive ? ' dropzone--active' : ''}`,
        })}
      >
        <input {...getInputProps()} />
        <div className="dropzone-instructions">
          <div className="dropzone-instructions--main">
            <h3>Drag-n-drop or click to add a file</h3>
          </div>
          <div className="dropzone-instructions--sub">
            <p>Max file size: 3MB</p>
          </div>
        </div>
        {value?.error && (
          <div key="dropzone-validation" className="dropzone-validation">
            {value.error}
          </div>
        )}
        {value && !value.error && (
          <aside
            style={{
              textAlign: 'left',
              borderTop: '2px dashed #ddd',
              marginTop: '10px',
              paddingTop: '10px',
            }}
          >
            <h4>Attached File</h4>
            <p style={{ paddingTop: '5px' }}>
              {value.name} ({bytesToSize(value.size)})
            </p>
          </aside>
        )}
      </div>
    )}
  </Dropzone>
);

AttachmentDropzone.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
};

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
`;

const aclRules = {
  canManageGlobalSampleEmailTemplate: [
    resource.STRATA_ADMIN,
    'manage-sample-email-template',
  ],
  canManagePromoterSampleEmailTemplate: [
    resource.PROMOTER,
    'manage-sample-email-template',
  ],
};

const EmailTemplate = ({ editing = false }) => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const canManageSampleEmailTemplate =
    acl.canManageGlobalSampleEmailTemplate ||
    acl.canManagePromoterSampleEmailTemplate;

  const backTo = '..';

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const periodList = useSelector(state => getPeriodList(state, { params }));

  const emailTypes = useSelector(getEmailTypes);

  const getEmailType = id =>
    emailTypes?.find(type => String(type.get('id')) === String(id));

  const emailTemplate = useSelector(state =>
    getCurrentEmailTemplate(state, { params }),
  );

  const [bodyKey, setBodyKey] = useState(keygen());

  const [selectedEmailTypeId, setSelectedEmailTypeId] = useState(
    emailTemplate?.email_type_id ?? null,
  );

  const selectedEmailType = getEmailType(selectedEmailTypeId);

  const sampleEmailTemplates = useSelector(state =>
    getEmailTypeSamples(state, { params, emailType: selectedEmailType }),
  );

  const templateOptions = selectedEmailType
    ?.get('template_options')
    ?.toJS() ?? { sections: true, periods: true };

  const attachment = emailTemplate?.attachments
    ? emailTemplate.attachments[0]
    : null;

  const sectionOptions = Object.values(departmentTypeMap)
    .filter(section => {
      if (selectedEmailType?.get('sections')) {
        return selectedEmailType.get('sections').includes(section.singular);
      }
      return true;
    })
    .map(dt => ({
      value: dt.singular,
      label: dt.label.plural,
    }));

  const emailTypeOptions = emailTypes
    ? Array.from(
        emailTypes
          .sortBy(emailType => emailType.get('label'))
          .map(emailType => ({
            label: emailType.get('label'),
            value: emailType.get('id'),
          })),
      )
    : null;

  const periodsOptions = periodList
    ? Array.from(
        periodList.map(period => ({
          label: period.get('name'),
          value: period.get('id'),
        })),
      )
    : null;

  const initialValues = useMemo(() => {
    return {
      emailType: emailTemplate?.email_type_id,
      enabled: emailTemplate?.enabled ?? true,
      sections:
        emailTemplate?.sections.map(section =>
          sectionOptions.find(option => option.value === section),
        ) ?? [],
      subject: emailTemplate?.subject ?? '',
      body: emailTemplate?.body ?? null,
      periods:
        emailTemplate?.periods?.map(period =>
          periodsOptions?.find(option => option.value === period.id),
        ) ?? [],
      fileKey: attachment?.key,
      fileLocation: attachment?.location,
    };
    // NOTE: Only create initialValues on first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplate]);

  const renderOptions = options =>
    options.map(option => (
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    ));

  const selectEmailType = (change, emailTypeId) => {
    const emailType = getEmailType(emailTypeId);

    setSelectedEmailTypeId(emailTypeId);

    const defaultSubject = emailType?.get('default_subject') ?? '';
    change('subject', defaultSubject);

    const defaultBody = emailType?.get('default_body')?.toJSON() ?? null;
    change('body', defaultBody);
    setBodyKey(keygen());
  };

  const applySampleTemplate = (change, subject, body) => {
    change('subject', subject);
    change('body', body);
    setBodyKey(keygen());
  };

  const removeFile = (values, change) =>
    change(values.attachment ? 'attachment' : 'fileKey', undefined);

  const handleSubmit = values => {
    const payload = new FormData();

    if (!editing) payload.append('emailType', values.emailType);

    payload.append('enabled', values.enabled);
    payload.append('subject', values.subject?.trim());
    payload.append('body', JSON.stringify(values.body));

    if (templateOptions.sections)
      payload.append(
        'sections',
        JSON.stringify(values.sections.map(section => section.value)),
      );

    if (templateOptions.periods)
      payload.append(
        'periods',
        JSON.stringify(values.periods.map(period => period.value)),
      );

    if (values.attachment && !values.attachment.error) {
      payload.append('file', values.attachment);
    } else if (editing) {
      payload.append('fileKey', values.fileKey ?? ''); //null gets converted to 'null'
    }

    return dispatch(
      editing
        ? updateEmailTemplate(event.get('id'), emailTemplate.id, payload)
        : addEmailTemplate(event.get('id'), payload),
    ).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      } else {
        dispatch(
          showNotification({
            message: editing
              ? 'Email template successfully updated'
              : 'Email template successfully created',
            status: 'success',
          }),
        );

        if (!editing) navigate(backTo);
      }
    });
  };

  if (editing && !emailTemplate) return <LoadingIndicator />;

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, submitting, values, form: { change } }) => (
        <form className="generic-form paper__wrapper" onSubmit={handleSubmit}>
          <Paper>
            <PaperHeader
              backTo={backTo}
              backToDisabled={!!submitting}
              title={editing ? 'Edit Email Template' : 'New Email Template'}
              actions={[
                <div
                  key="enable-email-template"
                  style={{ marginRight: '15px' }}
                >
                  <Field name="enabled">
                    {({ input: { value, onChange } }) => (
                      <PaperSwitch
                        name="enabled"
                        title={
                          value
                            ? 'Email Template is enabled'
                            : 'Email Template is disabled'
                        }
                        activated={value}
                        performAction={onChange}
                      />
                    )}
                  </Field>
                </div>,
              ]}
            />
            <div className="generic-form__body">
              {emailTypes && (
                <Field
                  name="emailType"
                  label="Email Type"
                  component={ReduxFormsField}
                  disabled={editing}
                  required
                  validate={isRequired('Email Type')}
                  onChange={emailTypeId => selectEmailType(change, emailTypeId)}
                >
                  <select>
                    <option value="">Select Email Type</option>
                    {renderOptions(emailTypeOptions)}
                  </select>
                </Field>
              )}
              {templateOptions?.sections && sectionOptions && (
                <Field
                  name="sections"
                  label="Sections"
                  component={ReduxFormsField}
                  required
                  validate={isNotEmpty('Sections')}
                >
                  <SelectAllMultiSelect
                    name="sections"
                    options={sectionOptions}
                  />
                </Field>
              )}
              {templateOptions?.periods && periodsOptions && (
                <Field
                  name="periods"
                  label="Periods"
                  component={ReduxFormsFieldNoLabel}
                  required
                  validate={isNotEmpty('Periods')}
                >
                  <SelectAllMultiSelect
                    name="periods"
                    options={periodsOptions}
                  />
                </Field>
              )}
              <Field
                name="subject"
                label="Subject"
                component={ReduxFormsField}
                required
                validate={isRequired('Subject')}
              >
                <input type="text" />
              </Field>
              <Field
                key={bodyKey}
                name="body"
                label="Body"
                component={ReduxFormsField}
                required
                validate={isRequired('Body')}
              >
                <ReduxSynapseDraft
                  inlineControls={['Bold', 'Italic', 'Underline', 'Link']}
                  blockControls={[
                    'UL',
                    'OL',
                    'H1',
                    'H2',
                    'H3',
                    'H4',
                    'H5',
                    'H6',
                    'Blockquote',
                  ]}
                  controlDisplay="inline"
                  customBlocks={defaultBlocks}
                  customBlockControls={Object.keys(defaultBlocks)}
                />
              </Field>
              {/* Register fileKey field so it registers as a change when the original file is removed */}
              <Field name="fileKey" render={() => null} />
              {values.fileKey && (
                <div className="input-scaffold">
                  <span className="input-label">Current Attachment:</span>{' '}
                  <a href={values.fileLocation}>{values.fileKey}</a>
                </div>
              )}
              {!values.fileKey && (
                <Field
                  name="attachment"
                  component={ReduxFormsFieldNoLabel}
                  label="Attachment"
                >
                  <AttachmentDropzone />
                </Field>
              )}
              {(values.fileKey || values.attachment) && (
                <div className="cropper-scaffold__controls">
                  <button
                    type="button"
                    className="button button--red"
                    onClick={() => removeFile(values, change)}
                  >
                    Remove File
                  </button>
                </div>
              )}
            </div>
            <div className="generic-form__footer">
              <Footer>
                <FormStatusButton buttonText={editing ? 'Update' : 'Add'} />
                <Row>
                  {canManageSampleEmailTemplate &&
                    selectedEmailType &&
                    values.subject &&
                    values.body && (
                      <AddSampleEmailTemplateModal
                        emailType={selectedEmailType}
                        subject={values.subject}
                        body={values.body}
                        trigger={
                          <button
                            key="contact-list-add"
                            type="button"
                            className="button button--plain button--icon"
                          >
                            <Icon icon="AddCircle" />
                            Add as Sample Template
                          </button>
                        }
                      />
                    )}
                  {!sampleEmailTemplates.isEmpty() && (
                    <ViewSampleEmailTemplatesModal
                      emailType={selectedEmailType}
                      onApply={(subject, body) =>
                        applySampleTemplate(change, subject, body)
                      }
                      trigger={
                        <button
                          className="button button--plain button--icon"
                          type="button"
                        >
                          <Icon icon="List" />
                          View Sample Templates
                        </button>
                      }
                    />
                  )}
                </Row>
              </Footer>
            </div>
          </Paper>
        </form>
      )}
    </Form>
  );
};

EmailTemplate.propTypes = {
  editing: PropTypes.bool,
};

export default EmailTemplate;
