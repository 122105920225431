import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCurrentCredentialType } from './credential-type-selectors';
import {
  getCurrentEvent,
  getEnabledEventSections,
} from '../../event/event-selectors';
import moment from 'moment';
import NotFound from '../../common/NotFound';
import CredentialTypeForm from './CredentialTypeForm';
import { editCredentialType } from './credential-type-actions';
import recursiveTrim from '../../common/utils/recursive-trim';
import { formatValidationErrors } from '../../common/utils/getApiReducer';
import { showNotification } from '../../notification/notification-actions';

const CredentialTypeEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const credentialType = useSelector(state =>
    getCurrentCredentialType(state, { params }),
  );

  const enabledSections =
    useSelector(state => getEnabledEventSections(state, { params })) ?? [];

  let initialValues = {};

  if (credentialType) {
    const attendeeTypes = {};

    // Start with empty strings, because that's how we represent 'disabled', and because we don't store a row in the DB for those.
    enabledSections.forEach(section => {
      attendeeTypes[section] = '';
    });

    credentialType?.get('attendeePickupTypes').forEach(pickupType => {
      attendeeTypes[pickupType.get('attendee_type')] =
        pickupType.get('pickup_type');
    });

    const periods = [];
    const issueFrequency = credentialType?.get('issue_frequency');
    const credentials = credentialType.get('credentials');

    //credentials list will be empty if the cred type has not been requested
    if (credentials && credentials.size > 0) {
      if (issueFrequency === 'ONE_TIME') {
        credentials.forEach(cred => {
          cred.get('oneTimePeriods').forEach(period => {
            periods.push({ id: period.get('id') });
          });
        });
      } else if (issueFrequency === 'DAILY') {
        credentials.forEach(credential => {
          const period = credential.get('period');
          if (period) {
            periods.push({
              id: period.get('id'),
              dates: [...period.get('dates').map(date => date.get('id'))],
            });
          }
        });
      } else {
        credentials.forEach(credential => {
          const period = credential.get('period');
          if (period) {
            periods.push({ id: period.get('id') });
          }
        });
      }
    }

    initialValues = {
      name: credentialType.get('name'),
      lock_date: credentialType.get('lock_date')
        ? moment(credentialType.get('lock_date'))
        : null,
      access_type: credentialType.get('access_type'),
      type: credentialType.get('type'),
      attendee_types: attendeeTypes,
      pass_fulfillment: { issue_frequency: issueFrequency, periods: periods },
      group_level_available: Boolean(
        credentialType.get('group_level_available'),
      ),
      group_level_auto_approve: Boolean(
        credentialType.get('group_level_auto_approve'),
      ),
    };
  }

  const handleSubmit = values => {
    const payload = { ...values };

    delete payload.type;

    //pass fulfillment is readonly
    if (payload.pass_fulfillment) {
      delete payload.pass_fulfillment;
    }

    if (payload.lock_date) {
      payload.lock_date.hour(23).minute(59).second(59);
    }

    return dispatch(
      editCredentialType(credentialType.get('id'), recursiveTrim(payload)),
    ).then(action => {
      if (!action.response.ok) {
        const errors = formatValidationErrors(action.json).toJS();
        errors._error = action.response;
        return errors;
      }
      navigate(
        `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/credentials/types`,
      );
      dispatch(
        showNotification({
          message: 'Credential type successfully saved',
          status: 'success',
        }),
      );
    });
  };

  if (!credentialType) return <NotFound />;

  return (
    <CredentialTypeForm
      event={event}
      initialValues={initialValues}
      enabledSections={enabledSections}
      disabledFields={['type']}
      onSubmit={handleSubmit}
      editing
    />
  );
};

export default CredentialTypeEdit;
