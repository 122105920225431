import moment from 'moment-timezone';
import CustomSection from './CustomSection';
import Icon from '../icons/Icon';
import DEFAULT_TIMEZONE from '../../lib/default-timezone';
import InternNameSection from './sections/InternNameSection';
import ApplicantInfoSection from './sections/ApplicantInfoSection';
import DepartmentInfoSection from './sections/DepartmentInfoSection';
import CateringRequestsSection from './sections/CateringRequestsSection';
import AdvanceNotesSection from './sections/AdvanceNotesSection';
import CredentialRequestsSection from './sections/CredentialRequestsSection';
import MediaOutletNameSection from './sections/MediaOutletNameSection';
import MediaOutletTypeSection from './sections/MediaOutletTypeSection';
import MediaOutletDetailsSection from './sections/MediaOutletDetailsSection';
import PersonnelSection from './sections/PersonnelSection';
import EditorInfoSection from './sections/EditorInfoSection';
import VerificationSection from './sections/VerificationSection';

export const anchoredSections = ['intro', 'legal-notice'];

export function renderSection(
  application,
  section,
  event,
  updateSection,
  uploadAction,
) {
  if (section.get('do_not_display')) return null;
  switch (section.get('key')) {
    case 'intern-name':
      return (
        <InternNameSection className="generic-form__body" section={section} />
      );
    case 'applicant-info':
      return (
        <ApplicantInfoSection
          className="public-application__section"
          section={section}
        />
      );
    case 'department-info':
      return (
        <DepartmentInfoSection
          className="public-application__section"
          section={section}
        />
      );
    case 'credential-requests':
      return (
        <CredentialRequestsSection
          className="public-application__section"
          section={section}
          credentials={application.get('credentials')}
        />
      );
    case 'catering-instructions':
      return (
        <CateringRequestsSection
          className="public-application__section"
          section={section}
          event={event}
        />
      );
    case 'advance-notes':
      return (
        <AdvanceNotesSection
          className="public-application__section"
          section={section}
        />
      );
    case 'media-outlet-name':
      return (
        <MediaOutletNameSection
          className="public-application__section"
          section={section}
        />
      );
    case 'media-outlet-type': {
      const mediaTypes = application
        .getIn(['event', 'departmentSettings'])
        .find(setting => setting.get('department_type') === 'media')
        .getIn(['settings', 'subtypes']);

      return (
        <MediaOutletTypeSection
          className="public-application__section"
          section={section}
          mediaTypes={mediaTypes}
        />
      );
    }
    case 'media-outlet-details':
      return (
        <MediaOutletDetailsSection
          className="public-application__section"
          application={application}
          section={section}
        />
      );
    case 'editor-info':
      return (
        <EditorInfoSection
          className="public-application__section"
          application={application}
          section={section}
        />
      );
    case 'personnel':
      return (
        <PersonnelSection
          className="public-application__section"
          section={section}
          credentials={application.get('credentials')}
        />
      );
    case 'verification':
      return (
        <VerificationSection
          className="public-application__section"
          section={section}
        />
      );
    case null:
      return (
        <CustomSection
          className="public-application__section"
          event={event}
          section={section}
          key={section.get('id')}
          updateSection={updateSection}
          uploadAction={uploadAction}
        />
      );
    default:
      return null;
  }
}

export function renderApplicationPublicFormStatus(
  label,
  event,
  application,
  applicationToken,
  submitSucceeded,
) {
  const timezone = event ? event.get('time_zone') : DEFAULT_TIMEZONE;
  const isNotOpen = moment()
    .tz(timezone)
    .isBefore(moment(application.get('open_date')).tz(timezone));

  if (isNotOpen) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {event && (
          <h1 className="public-application__header">{event.get('name')}</h1>
        )}
        <div className="public-application__body">
          <section className="public-application__section public-application__section--textcenter">
            <header>
              <div className="public-application__section--title">
                Application for {label}
              </div>
            </header>
          </section>
          <section className="public-application__section public-application__section--textcenter">
            <div className="public-application__section--placeholder">
              Application not open
            </div>
          </section>
        </div>
      </div>
    );
  }

  let closeDate = application.get('close_date');
  if (applicationToken === application.get('override_link_token')) {
    closeDate = application.get('override_close_date');
  }
  closeDate = moment(closeDate).tz(timezone);

  const isClosed = moment().tz(timezone).isAfter(closeDate);

  if (isClosed) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {event && (
          <h1 className="public-application__header">{event.get('name')}</h1>
        )}
        <div className="public-application__body">
          <section className="public-application__section public-application__section--textcenter">
            <header>
              <div className="public-application__section--title">
                Application for {label}
              </div>
            </header>
          </section>
          <section className="public-application__section public-application__section--textcenter">
            <div className="public-application__section--placeholder">
              Application deadline has passed
            </div>
          </section>
        </div>
      </div>
    );
  }

  if (submitSucceeded) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h1 className="public-application__header">{event.get('name')}</h1>
        <div className="public-application__body">
          <section className="public-application__section public-application__section--textcenter">
            <header>
              <div className="public-application__section--title">
                Application for {label}
              </div>
            </header>
          </section>
          <section className="public-application__section public-application__section--textcenter">
            <div className="public-application__section--placeholder placeholder-success">
              <Icon icon="CheckCircle" />
              Submission Successful
            </div>
          </section>
        </div>
      </div>
    );
  }

  return null;
}

export function renderAdvanceApplicationPublicFormStatus(
  label,
  event,
  application,
  submitSucceeded,
) {
  const timezone = event ? event.get('time_zone') : DEFAULT_TIMEZONE;
  const isNotOpen = moment()
    .tz(timezone)
    .isBefore(moment(application.get('open_date')).tz(timezone));

  if (isNotOpen) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {event && (
          <h1 className="public-application__header">{event.get('name')}</h1>
        )}
        <div className="public-application__body">
          <section className="public-application__section public-application__section--textcenter">
            <header>
              <div className="public-application__section--title">
                Application for {label}
              </div>
            </header>
          </section>
          <section className="public-application__section public-application__section--textcenter">
            <div className="public-application__section--placeholder">
              Application not open
            </div>
          </section>
        </div>
      </div>
    );
  }
  //application closing date check removed
  //past closing date application is displayed in preview mode

  if (submitSucceeded) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h1 className="public-application__header">{event.get('name')}</h1>
        <div className="public-application__body">
          <section className="public-application__section public-application__section--textcenter">
            <header>
              <div className="public-application__section--title">
                Application for {label}
              </div>
            </header>
          </section>
          <section className="public-application__section public-application__section--textcenter">
            <div className="public-application__section--placeholder placeholder-success">
              <Icon icon="CheckCircle" />
              Submission Successful
            </div>
          </section>
        </div>
      </div>
    );
  }

  return null;
}
