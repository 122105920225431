import PropTypes from 'prop-types';
import InputScaffold from '../../forms/InputScaffold';
import translateErrors from '../translateErrors';

const Textarea = ({ field, onBlur, onChange }) => (
  <InputScaffold
    label={field.get('label')}
    validation={translateErrors(field.getIn(['error', 'value']))}
    required={!!field.get('required')}
  >
    <textarea
      name="value"
      value={field.get('value') || ''}
      onBlur={onBlur}
      onChange={e => onChange(field.set('value', e.target.value))}
      maxLength="10000"
    />
  </InputScaffold>
);

Textarea.propTypes = {
  field: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

export default Textarea;
