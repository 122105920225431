import moment from 'moment';
import auth from '../../../auth/auth-helpers';

export const formatDate = date => moment.utc(date).format('ddd MMM D');

export const getPeriodDateRange = period => {
  return `${formatDate(period.get('start_date'))} - ${formatDate(
    period.get('end_date'),
  )}`;
};

export const getCredentialDateRange = credential => {
  const issueFrequency = credential.getIn([
    'credentialType',
    'issue_frequency',
  ]);
  const period = credential.get('period');
  if (issueFrequency === 'ONE_TIME') {
    return `${formatDate(
      credential.get('oneTimePeriods').first().get('start_date'),
    )} - ${formatDate(
      credential.get('oneTimePeriods').last().get('end_date'),
    )}`;
  } else if (issueFrequency === 'PERIODICALLY') {
    return `${formatDate(period.get('start_date'))} - ${formatDate(
      period.get('end_date'),
    )}`;
  } else if (issueFrequency === 'DAILY') {
    return `${formatDate(credential.getIn(['date', 'date']))}`;
  }
};

export const getCredentialStartDate = credential => {
  const issueFrequency = credential.getIn([
    'credentialType',
    'issue_frequency',
  ]);
  if (issueFrequency === 'ONE_TIME') {
    return credential.get('oneTimePeriods').first().get('start_date');
  } else if (issueFrequency === 'PERIODICALLY') {
    return credential.getIn(['period', 'start_date']);
  } else if (issueFrequency === 'DAILY') {
    return credential.getIn(['date', 'date']);
  }
};

export const getCredentialEndDate = credential => {
  const issueFrequency = credential.getIn([
    'credentialType',
    'issue_frequency',
  ]);
  if (issueFrequency === 'ONE_TIME') {
    return credential.get('oneTimePeriods').last().get('end_date');
  } else if (issueFrequency === 'PERIODICALLY') {
    return credential.getIn(['period', 'end_date']);
  } else if (issueFrequency === 'DAILY') {
    return credential.getIn(['date', 'date']);
  }
};

const getLockDateOverride = (credentialType, department) =>
  credentialType.has('_pivot_lock_date_override')
    ? credentialType.get('_pivot_lock_date_override')
    : department
        .get('credentialTypes')
        .find(ct => ct.get('id') === credentialType.get('id'))
        ?.get('_pivot_lock_date_override') ?? false;

export const isCredentialTypeLocked = (credentialType, user, department) => {
  if (!credentialType || !user) return true;

  if (getLockDateOverride(credentialType, department)) return false;

  if (auth.userIsStrataAdmin(user)) return false;

  if (auth.userIsPromoterAdmin(user, department.get('promoter_id')))
    return false;

  const eventId = department.get('event_id');

  if (auth.userIsEventAdmin(user, eventId)) return false;

  if (auth.userIsEventCoordinator(user, eventId)) return false;

  if (
    auth.userHasSectionRole(user, eventId, department.get('type'), 'credential')
  )
    return false;

  const categoryLockDate = credentialType.getIn([
    'category',
    'lock_after_date',
  ]);
  const categoryIsLocked =
    moment(categoryLockDate).isValid() &&
    moment.utc(categoryLockDate).isBefore(moment.utc());

  if (categoryIsLocked) return true;
  const lockDate = credentialType.get('lock_date');
  return (
    moment(lockDate).isValid() && moment.utc(lockDate).isBefore(moment.utc())
  );
};

export const isCredentialLocked = (credential, user, department) =>
  isCredentialTypeLocked(credential.get('credentialType'), user, department);

export const isGroupLevelCredential = credentialType => {
  if (!credentialType) return false;
  return (
    credentialType.get('group_level_available') &&
    credentialType
      .get('attendeePickupTypes')
      ?.some(apt => apt.get('pickup_type') === 'group')
  );
};

export const contactHasCredentialRequest = (contact, credentialType) =>
  contact
    .get('credentialRequests')
    .some(
      request =>
        request.getIn(['credential', 'credential_type_id']) ===
        credentialType.get('id'),
    );

export const departmentHasAssignableGroupLevelCredential = (
  department,
  credentialType,
) =>
  isGroupLevelCredential(credentialType) &&
  department
    .get('credentialRequests')
    .filter(request => request.get('requested_for') === null)
    .some(
      request =>
        request.getIn(['credential', 'credential_type_id']) ===
          credentialType.get('id') && request.get('quantity_approved') > 0,
    );

export const exceedMaximumByAmount = (delta, department, credentialId) => {
  const maximumRequestable = department
    .get('requestMaximums')
    .find(rm => rm.get('_pivot_credential_id') === credentialId);
  let maximumExceededBy = 0;
  let previousPending = 0;
  let previousApproved = 0;
  let available = Infinity;
  if (
    maximumRequestable &&
    maximumRequestable.get('_pivot_maximum_requestable')
  ) {
    if (
      department
        .get('credentialRequests')
        .filter(cr => cr.get('credential_id') === credentialId).size > 0
    ) {
      previousPending = department
        .get('credentialRequests')
        .filter(cr => cr.get('credential_id') === credentialId)
        .map(cr => cr.get('quantity_pending'))
        .reduce((prev, current) => prev + current);
      previousApproved = department
        .get('credentialRequests')
        .filter(cr => cr.get('credential_id') === credentialId)
        .map(cr => cr.get('quantity_approved'))
        .reduce((prev, current) => prev + current);
    }
    maximumExceededBy =
      previousApproved +
      previousPending +
      delta -
      maximumRequestable.get('_pivot_maximum_requestable');
  }

  available =
    maximumRequestable &&
    typeof maximumRequestable.get('_pivot_maximum_requestable') === 'number'
      ? maximumRequestable.get('_pivot_maximum_requestable') -
        previousApproved -
        previousPending -
        delta
      : Infinity;

  return {
    maximumExceededBy,
    maximumRequestable: maximumRequestable
      ? maximumRequestable.get('_pivot_maximum_requestable')
      : null,
    available,
  };
};
