import client from '../client';
import dataUrlToFile from '../common/utils/dataUrlToFile';

export const SUBMIT_EVENT = 'SUBMIT_EVENT';
export function submitEvent(eventValues) {
  const data = new FormData();
  if (eventValues.file_id) data.append('file_id', eventValues.file_id);
  if (
    eventValues.logo &&
    eventValues.logo.result &&
    eventValues.logo.filename
  ) {
    const file = dataUrlToFile(
      eventValues.logo.result,
      eventValues.logo.filename,
    );
    data.append('file', file, file.name);
  }
  data.append('name', eventValues.name);
  data.append('festival', eventValues.festival_id);
  data.append('settings', JSON.stringify(eventValues.settings));
  return {
    type: SUBMIT_EVENT,
    promise: eventValues.id
      ? client.put(`events/${eventValues.id}`, data)
      : client.post('events', data),
  };
}
export const INVITE_EVENT_ADMIN = 'INVITE_EVENT_ADMIN';
export function inviteEventAdmin(eventId, values) {
  return {
    type: INVITE_EVENT_ADMIN,
    promise: client.post(`users/event-admin/${eventId}`, values),
  };
}

export const INVITE_EVENT_ADMIN_RESET = 'INVITE_EVENT_ADMIN_RESET';
export function clearInviteEventAdmin() {
  return {
    type: INVITE_EVENT_ADMIN_RESET,
  };
}

export const FETCH_EVENT_ADMIN_LIST = 'FETCH_EVENT_ADMIN_LIST';
export function fetchEventAdminList(eventId) {
  return {
    type: FETCH_EVENT_ADMIN_LIST,
    promise: client.get(`users/event-admin/${eventId}`),
  };
}

export const FETCH_EVENT_SETTINGS = 'FETCH_EVENT_SETTINGS';
export function fetchEventSettings(eventId) {
  return {
    eventId,
    type: FETCH_EVENT_SETTINGS,
    promise: client.get(`event/${eventId}/settings`),
  };
}

export const FETCH_EMAIL_TYPES = 'FETCH_EMAIL_TYPES';
export function fetchEmailTypes() {
  return {
    type: FETCH_EMAIL_TYPES,
    promise: client.get(`email/types`),
  };
}

export const FETCH_EMAIL_TEMPLATES = 'FETCH_EMAIL_TEMPLATES';
export function fetchEmailTemplates(eventId) {
  return {
    eventId,
    type: FETCH_EMAIL_TEMPLATES,
    promise: client.get(
      `events/${eventId}/email/templates?includeDisabled=true`,
    ),
  };
}

export const ENABLE_EMAIL_TEMPLATE = 'ENABLE_EMAIL_TEMPLATE';
export function enableEmailTemplate(eventId, emailTemplateId) {
  return {
    eventId,
    emailTemplateId,
    type: ENABLE_EMAIL_TEMPLATE,
    promise: client.post(
      `events/${eventId}/email/templates/${emailTemplateId}/enable`,
    ),
  };
}

export const DISABLE_EMAIL_TEMPLATE = 'DISABLE_EMAIL_TEMPLATE';
export function disableEmailTemplate(eventId, emailTemplateId) {
  return {
    eventId,
    emailTemplateId,
    type: DISABLE_EMAIL_TEMPLATE,
    promise: client.post(
      `events/${eventId}/email/templates/${emailTemplateId}/disable`,
    ),
  };
}

export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE';
export function deleteEmailTemplate(eventId, emailTemplateId) {
  return {
    eventId,
    emailTemplateId,
    type: DELETE_EMAIL_TEMPLATE,
    promise: client.delete(
      `events/${eventId}/email/templates/${emailTemplateId}`,
    ),
  };
}

export const ADD_EMAIL_TEMPLATE = 'ADD_EMAIL_TEMPLATE';
export function addEmailTemplate(eventId, payload) {
  return {
    eventId,
    type: ADD_EMAIL_TEMPLATE,
    promise: client.post(`events/${eventId}/email/templates`, payload),
  };
}

export const UPDATE_EMAIL_TEMPLATE = 'UPDATE_EMAIL_TEMPLATE';
export function updateEmailTemplate(eventId, emailTemplateId, payload) {
  return {
    eventId,
    emailTemplateId,
    type: UPDATE_EMAIL_TEMPLATE,
    promise: client.put(
      `events/${eventId}/email/templates/${emailTemplateId}`,
      payload,
    ),
  };
}

export const FETCH_EVENT_ONSITE_SETTINGS = 'FETCH_EVENT_ONSITE_SETTINGS';
export function fetchEventOnsiteSettings(eventId) {
  return {
    eventId,
    type: FETCH_EVENT_ONSITE_SETTINGS,
    promise: client.get(`event/${eventId}/onsite-settings`),
  };
}

export const UPDATE_EVENT_ONSITE_SETTINGS = 'UPDATE_EVENT_ONSITE_SETTINGS';
export function updateEventOnsiteSettings(event, values) {
  const eventId = event.get('id');
  return {
    promoterId: event.get('promoter_id'),
    festivalId: event.get('festival_id'),
    eventId,
    type: UPDATE_EVENT_ONSITE_SETTINGS,
    promise: client.patch(`event/${eventId}/onsite-settings`, { ...values }),
  };
}

export const UPDATE_EVENT_EMAIL_SETTINGS = 'UPDATE_EVENT_EMAIL_SETTINGS';
export function updateEventEmailSettings(event, payload) {
  const eventId = event.get('id');
  return {
    eventId,
    type: UPDATE_EVENT_EMAIL_SETTINGS,
    promise: client.patch(`event/${eventId}/email-settings`, payload),
  };
}

export const FETCH_EVENT_ONSITE_REQUEST_TYPES =
  'FETCH_EVENT_ONSITE_REQUEST_TYPES';
export function fetchEventOnsiteRequestTypes(eventId) {
  return {
    eventId,
    type: FETCH_EVENT_ONSITE_REQUEST_TYPES,
    promise: client.get(`event/${eventId}/onsite-request-types`),
  };
}

export const CHANGE_EVENT_ENABLED_STATUS = 'CHANGE_EVENT_ENABLED_STATUS';
export function changeEventEnabledStatus(eventId, deactivated) {
  return {
    eventId,
    type: CHANGE_EVENT_ENABLED_STATUS,
    promise: client.patch(`event/${eventId}/activation`, { deactivated }),
  };
}

export const CHANGE_GROUPS_ENABLED_STATUS = 'CHANGE_GROUPS_ENABLED_STATUS';
export function changeGroupsEnabledStatus(eventId, groupsEnabled) {
  return {
    eventId,
    type: CHANGE_GROUPS_ENABLED_STATUS,
    promise: client.patch(`event/${eventId}/groups-enable`, { groupsEnabled }),
  };
}

export const UPDATE_EVENT_ONSITE_REQUEST_TYPE =
  'UPDATE_EVENT_ONSITE_REQUEST_TYPE';
export function updateEventOnsiteRequestType(eventId, requestTypeId, values) {
  return {
    eventId,
    requestTypeId,
    type: UPDATE_EVENT_ONSITE_REQUEST_TYPE,
    promise: client.patch(
      `event/${eventId}/onsite-request-types/${requestTypeId}`,
      { ...values },
    ),
  };
}

export const FETCH_SECTIONS_WITH_PENDING_REQUESTS_BY_EVENT =
  'FETCH_SECTIONS_WITH_PENDING_REQUESTS_BY_EVENT';
export function fetchSectionsWithPendingCredentialRequestsByEvent(eventId) {
  return {
    eventId,
    type: FETCH_SECTIONS_WITH_PENDING_REQUESTS_BY_EVENT,
    promise: client.get(
      `events/${eventId}/sections-with-pending-credential-requests`,
    ),
  };
}
export const SUBMIT_EMAIL_SETTINGS = 'SUBMIT_EMAIL_SETTINGS';
export function submitEmailSettings(eventId, emailSettingsValues) {
  return {
    eventId,
    type: SUBMIT_EMAIL_SETTINGS,
    promise: client.put(`event/${eventId}/email-setting`, emailSettingsValues),
  };
}

export const NEW_EMAIL_SETTINGS = 'NEW_EMAIL_SETTINGS';
export function newEmailSettings(eventId, emailSettingValues) {
  return {
    eventId,
    type: NEW_EMAIL_SETTINGS,
    promise: client.post(`event/${eventId}/email-setting`, emailSettingValues),
  };
}

export const DELETE_EMAIL_SETTINGS = 'DELETE_EMAIL_SETTINGS';
export function deleteEmailSettings(eventId, ids) {
  const urlSearchParams = new URLSearchParams(ids.map(id => ['id', id]));
  return {
    type: DELETE_EMAIL_SETTINGS,
    promise: client.delete(
      `event/${eventId}/email-settings?${urlSearchParams}`,
    ),
  };
}

export const SUBMIT_EMAIL_ATTACHMENT = 'SUBMIT_EMAIL_ATTACHMENT';
export function submitEmailAttachment(templateId, fileKey, file) {
  const data = new FormData();
  data.append('file', file, file.name);
  data.append('templateId', templateId);
  data.append('fileKey', fileKey);
  return {
    type: SUBMIT_EMAIL_ATTACHMENT,
    promise: client.post('events', data),
  };
}
