class PermissionList {
  constructor(permissions, role, resource, action, done) {
    this.permissions = permissions;
    this.role = role;
    this.resource = resource;
    this.action = action;
    this.done = done;
    this.position = 0;
    this.count = this.permissions.length;
  }

  next() {
    if (this.count === this.position) {
      return this.done(null, false); // No more permissions to check, DENIED!!1
    }

    var permission = this.permissions[this.position];

    // Ensure custom assertions are run async, fixes issue #7
    setTimeout(
      function () {
        permission.query(
          this.role,
          this.resource,
          this.action,
          this.done,
          this.next.bind(this),
        );
      }.bind(this),
    );

    this.position++;
  }
}

export default PermissionList;
