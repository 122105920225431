import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { Map } from 'immutable';
import { removeCredentialQuantities } from '../credential-request/credential-request-actions';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../common/icons/Icon';
import RequestStatusIndicator from './RequestStatusIndicator';

const ContactRequestQuantity = ({
  credentialRequest,
  status,
  issued = 0,
  canEdit,
  canDeleteOverride,
  onDeleting = () => {},
}) => {
  const dispatch = useDispatch();

  const [deleting, setDeleting] = useState(false);

  const requestId = credentialRequest.get('id');
  const canDelete = canEdit && !canDeleteOverride && issued === 0;
  const isLocked = issued > 0;
  const quantity =
    status === 'zero'
      ? credentialRequest.get(`quantity_approved`) +
        credentialRequest.get(`quantity_pending`) +
        credentialRequest.get(`quantity_rejected`)
      : credentialRequest.get(`quantity_${status}`);

  if (
    (status === 'zero' && quantity !== 0) ||
    (status !== 'zero' && quantity === 0)
  ) {
    return null;
  }

  const classes = classNames({
    'credential-quantity': true,
    'credential-quantity--deleting': deleting,
  });

  const dataTooltipId = `credential-tooltip-${credentialRequest.get('id')}`;

  const deleteCredentialRequest = async () => {
    setDeleting(true);
    onDeleting(true);

    dispatch(
      removeCredentialQuantities(requestId, status === 'zero' ? 'all' : status),
    ).then(() => {
      setDeleting(false);
      onDeleting(false);
    });
  };

  return (
    <div className={classes}>
      <RequestStatusIndicator status={status} leftContent={quantity} />
      {isLocked && (
        <div
          style={{ paddingTop: '4px' }}
          className="remove-cred"
          data-tooltip-id={dataTooltipId}
        >
          <Icon icon="IssuedCredential" />
          <Tooltip id={dataTooltipId} content={`Issued: ${issued}`} />
        </div>
      )}
      {canDelete && (
        <button
          type="button"
          disabled={deleting}
          onClick={() => deleteCredentialRequest()}
          className="remove-cred"
        >
          <Icon icon="CloseCircle" />
        </button>
      )}
    </div>
  );
};

ContactRequestQuantity.propTypes = {
  credentialRequest: PropTypes.instanceOf(Map).isRequired,
  status: PropTypes.string.isRequired,
  issued: PropTypes.number,
  canEdit: PropTypes.bool,
  canDeleteOverride: PropTypes.bool,
  onDeleting: PropTypes.func,
};

export default ContactRequestQuantity;
