import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { store } from './configureStore';
import { appTheme } from './styled-components/appTheme';
import shouldForwardProp from './styled-components/shouldForwardProp';
import ReactDOM from 'react-dom/client';
import router from './router';
import 'react-dates-gte-react-17/initialize';
import 'react-dates-gte-react-17/lib/css/_datepicker.css';
import 'rc-time-picker/assets/index.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <Provider store={store}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ThemeProvider theme={appTheme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </StyleSheetManager>
    </Provider>
  </StrictMode>,
);

// NOTE: Commented out and removed the reportWebVitals function (along with
// the corresponding web-vitals npm package) since we don't use it.
// If you want to start measuring performance in this app, install the web-vitals
// npm package, uncomment the function call below and pass a function to log
// results(for example: reportWebVitals(console.log)) or send to an analytics
// endpoint.Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
