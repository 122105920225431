import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { getCurrentEvent } from '../../event/event-selectors';
import { addPeriod, fetchPulseEventList } from './period-actions';
import { getStatusForButton } from '../../common/forms/FormHelper';
import { formatValidationErrors } from '../../common/utils/getApiReducer';
import { showNotification } from '../../notification/notification-actions';
import { addPeriodValidator } from './period-validators';
import moment from 'moment';
import recursiveTrim from '../../common/utils/recursive-trim';
import LoadingIndicator from '../../common/LoadingIndicator';
import FullPageMessage from '../../common/FullPageMessage';
import Paper, { PaperHeader } from '../../common/paper/Paper';
import StatusButton from '../../common/StatusButton';
import ReduxFormsField from '../../common/forms/ReduxFormsField';
import ReduxDatepickerField from '../../common/forms/ReduxDatepickerField';

const PeriodAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const pulseEventList = useSelector(state =>
    state.period.pulseEventList.get('data'),
  );

  const initialValues = useMemo(
    () => ({
      name: '',
      start_date: null,
      end_date: null,
      pulse_event_id: pulseEventList.getIn([0, 'event_id']),
    }),
    [pulseEventList],
  );

  const eventStartDate = moment.utc(event?.get('start_date') ?? undefined);

  const backTo = '..';

  useEffect(() => {
    if (event) dispatch(fetchPulseEventList(event.get('id')));
  }, [dispatch, event]);

  const handleSubmit = values => {
    return dispatch(addPeriod(event.get('id'), recursiveTrim(values))).then(
      action => {
        if (!action.response.ok) {
          return formatValidationErrors(action.json).toJS();
        } else {
          navigate(backTo);
          dispatch(
            showNotification({
              message: 'Period successfully added',
              status: 'success',
            }),
          );
        }
      },
    );
  };

  if (!pulseEventList) {
    return <LoadingIndicator />;
  }

  if (pulseEventList.size === 0) {
    return (
      <FullPageMessage
        icon="Sad"
        message="Cannot add period because there are no Pulse events for this festival"
      />
    );
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={addPeriodValidator}
      render={({
        handleSubmit,
        invalid,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
        submitFailed,
        values,
      }) => (
        <Paper>
          <PaperHeader
            backTo={backTo}
            backToDisabled={submitting}
            title="Add Period"
          />
          <form className="generic-form" onSubmit={handleSubmit}>
            <div className="generic-form__body">
              <div className="input-group input-group--large">
                <Field
                  name="name"
                  label="Period Name"
                  component={ReduxFormsField}
                  required
                >
                  <input type="text" />
                </Field>
              </div>
              <div className="input-group input-group--small">
                <Field
                  name="start_date"
                  label="Start Date"
                  component={ReduxFormsField}
                  required
                >
                  <ReduxDatepickerField
                    initialVisibleMonth={() => eventStartDate}
                  />
                </Field>
                <Field
                  name="end_date"
                  label="End Date"
                  component={ReduxFormsField}
                  required
                >
                  <ReduxDatepickerField
                    startDate={values.start_date}
                    initialVisibleMonth={() =>
                      moment.utc(values.start_date ?? eventStartDate)
                    }
                  />
                </Field>
              </div>
              <div className="input-group input-group--small">
                <Field
                  name="pulse_event_id"
                  label="Pulse Event"
                  component={ReduxFormsField}
                  required
                >
                  <select>
                    {pulseEventList.map(pulseEvent => (
                      <option
                        value={pulseEvent.get('event_id')}
                        key={pulseEvent.get('event_id')}
                      >
                        {pulseEvent.get('name')}
                      </option>
                    ))}
                  </select>
                </Field>
              </div>
            </div>
            <div className="generic-form__footer">
              <StatusButton
                disabled={
                  submitting ||
                  pristine ||
                  (invalid && !modifiedSinceLastSubmit)
                }
                status={getStatusForButton(pristine, submitting, submitFailed)}
                type="submit"
              />
              <Link
                className="button button--plain"
                disabled={submitting}
                to={backTo}
              >
                Cancel
              </Link>
            </div>
          </form>
        </Paper>
      )}
    />
  );
};

export default PeriodAdd;
