import { Map as ImmutableMap } from 'immutable';
import { Tooltip } from 'react-tooltip';
import {
  CategoryColumn,
  Container,
  CredentialType,
  Date,
  NameColumn,
  Period,
  SelectColumn,
  SwitchColumn,
} from './Credential.styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import SwitchOn from '../icons/SwitchOn';
import SwitchOff from '../icons/SwitchOff';
import Tag from '../Tag';

const Credential = ({
  credential,
  periods,
  selectedCredential,
  includeCredentialModifiers,
  onSelect,
  onUnSelect,
}) => {
  const credentialId = credential.get('id');
  const credentialType = credential.get('credentialType');
  const category = credentialType.get('category');
  const periodName = periods.map(period => period.get('name')).join(', ');
  const date =
    credentialType.get('issue_frequency') === 'DAILY'
      ? credential.getIn(['date', 'date'])
      : null;
  const selected = !!selectedCredential;
  const isSingleInstance = selected
    ? selectedCredential.isSingleInstance
    : false;
  const isAddOn = selected ? selectedCredential.isAddOn : false;

  const toggleSelected = () => {
    if (selectedCredential) {
      onUnSelect(selectedCredential);
    } else {
      onSelect({
        id: credential.get('id'),
        isSingleInstance: false,
        isAddOn: false,
      });
    }
  };

  const toggleSingleInstance = () => {
    onSelect({
      id: selectedCredential.id,
      isSingleInstance: !selectedCredential.isSingleInstance,
      isAddOn: selectedCredential.isAddOn,
    });
  };

  const toggleAddOn = () => {
    onSelect({
      id: selectedCredential.id,
      isSingleInstance: selectedCredential.isSingleInstance,
      isAddOn: !selectedCredential.isAddOn,
    });
  };

  return (
    <Container>
      <SelectColumn>
        <button
          type="button"
          className="switch__button"
          onClick={e => {
            e.stopPropagation();
            toggleSelected();
          }}
        >
          {selected ? <SwitchOn /> : <SwitchOff />}
        </button>
      </SelectColumn>
      <CategoryColumn>
        <Tag backgroundColor={category.get('color')} size="small">
          {category.get('name')}
        </Tag>
      </CategoryColumn>
      <NameColumn>
        <CredentialType>{credentialType.get('name')}</CredentialType>
        <Period>{periodName}</Period>
        {date && <Date>({moment.utc(date).format('ddd, MMM DD')})</Date>}
      </NameColumn>
      {includeCredentialModifiers && (
        <>
          <SwitchColumn>
            <Tooltip
              id={`single-tooltip-${credentialId}`}
              offset={10}
              content="Credential can only be requested for one person per application"
            />
            <button
              type="button"
              className="switch__button"
              data-tooltip-id={`single-tooltip-${credentialId}`}
              disabled={!selected}
              onClick={e => {
                e.stopPropagation();
                toggleSingleInstance();
              }}
            >
              {isSingleInstance ? <SwitchOn /> : <SwitchOff />}
            </button>
            <span>Single</span>
          </SwitchColumn>
          <SwitchColumn>
            <button
              type="button"
              className="switch__button"
              disabled={!selected}
              onClick={e => {
                e.stopPropagation();
                toggleAddOn();
              }}
            >
              {isAddOn ? <SwitchOn /> : <SwitchOff />}
            </button>
            <span>Add On</span>
          </SwitchColumn>
        </>
      )}
    </Container>
  );
};

Credential.propTypes = {
  credential: PropTypes.instanceOf(ImmutableMap).isRequired,
  periods: PropTypes.arrayOf(ImmutableMap).isRequired,
  selectedCredential: PropTypes.shape({
    id: PropTypes.number.isRequired,
    isSingleInstance: PropTypes.bool.isRequired,
    isAddOn: PropTypes.bool.isRequired,
  }),
  includeCredentialModifiers: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUnSelect: PropTypes.func.isRequired,
};

export default Credential;
