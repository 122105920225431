import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { streamPump } from '../common/streamPump';
import {
  fetchDepartmentSlugs,
  clearDropdownResults,
  writeQuery,
} from '../search-actions';
import { getSearchDropdown } from '../search-selectors';
import { getCurrentEvent } from '../../event/event-selectors';
import { Search, Container } from './SearchBarContainer.styles';
import { MagnifyingGlassIcon } from '../common/SearchIcons';
import config from '../../config';
import SearchInput from './SearchBarComponents/SearchInput';
import ResultsDropDown from './SearchBarComponents/ResultsDropDown';

const SearchBarContainer = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const eventId = useSelector(state =>
    getCurrentEvent(state, { params })?.get('id'),
  );

  const results = useSelector(getSearchDropdown);

  const [isOpen, setIsOpen] = useState(false);
  const [animationOpen, setAnimationOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (eventId) dispatch(fetchDepartmentSlugs(eventId));
  }, [dispatch, eventId]);

  const handleOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
      setAnimationOpen(true);
      setInputValue('');
      dispatch(writeQuery(eventId));
    }
  };

  const handleClose = () => {
    if (isOpen) {
      setIsOpen(false);
      setInputValue('');
      dispatch(clearDropdownResults(eventId));
    }
  };

  const onTransitionEnd = () => {
    if (!isOpen) setAnimationOpen(false);
  };

  const handleFetchStream = (inputValue, caller) => {
    if (eventId && inputValue?.length > 1) {
      const apiUrl = `${config.apiUrl}search/${eventId}/${inputValue}`;
      return streamPump({ apiUrl, eventId, dispatch, caller });
    }
  };

  const onInputValueChange = inputValue => {
    setInputValue(inputValue);

    if (inputValue === '') {
      dispatch(clearDropdownResults(eventId));
    } else {
      handleFetchStream(inputValue);
    }
  };

  return (
    <Container>
      <Search
        $isOpen={isOpen}
        $animationOpen={animationOpen}
        onBlur={handleClose}
        onClick={handleOpen}
        onTransitionEnd={onTransitionEnd}
      >
        <MagnifyingGlassIcon />
        {animationOpen && (
          <SearchInput
            eventId={eventId}
            isOpen={isOpen}
            inputValue={inputValue}
            onInputValueChange={onInputValueChange}
            handleClose={handleClose}
            handleFetchStream={handleFetchStream}
          />
        )}
        {isOpen ? (
          <ResultsDropDown
            inputValue={inputValue}
            results={results}
            handleClose={handleClose}
          />
        ) : null}
      </Search>
    </Container>
  );
};

export default SearchBarContainer;
