import { v4 as uuid } from 'uuid';
import config from '../config';
import storage from 'store2';

export function login() {
  const nonce = uuid();
  storage.session('nonce', nonce);

  window.location.href = `${config.auth.domain}/op/auth?client_id=${config.auth.clientId}&response_type=${config.auth.responseType}&scope=${config.auth.scope}&redirect_uri=${config.auth.redirectUri}&nonce=${nonce}`;
}

export function logout() {
  const redirectTarget = storage.session('redirectTarget') ?? '/';
  storage.session.clearAll();

  window.location.href = `${
    config.auth.domain
  }/user/logout?post_logout_redirect_uri=${config.auth.postLogoutRedirectUri}${
    redirectTarget ?? ''
  }`;
}
