import styled from 'styled-components';

export const Container = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: center;
  min-height: 50px;
`;

export const SelectColumn = styled.div`
  flex: 0;
  margin-left: 10px;
  margin-right: 30px;
`;

export const CategoryColumn = styled.div`
  flex: 0;
  margin-right: 10px;
  white-space: nowrap;
`;

export const NameColumn = styled.div`
  flex: 1 0;
  margin-right: 10px;
  white-space: nowrap;
`;

export const SwitchColumn = styled.div`
  flex: 0;
  display: flex;
  align-items: center;
  margin-left: 30px;
  white-space: nowrap;

  span {
    margin-left: 14px;
    opacity: 0.7;
  }
`;

export const CredentialType = styled.span`
  font-weight: 700;
`;

export const Period = styled.span`
  margin-left: 10px;
`;

export const Date = styled.span`
  margin-left: 5px;
  font-size: smaller;
`;
