import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { fetchAdminList } from './admin-actions';
import { resendInvite, deleteUser } from '../user/user-actions';
import UserList from '../user/UserList';

const aclRules = {
  canEdit: [resource.STRATA_ADMIN, 'edit'],
};

const AdminList = () => {
  const acl = useACL(aclRules);
  const location = useLocation();

  const userList = useSelector(state => state.admin.list.get('data'));
  const loading = useSelector(state => state.admin.list.get('loading'));
  const deleting = useSelector(state => state.user.deleteUser.get('loading'));

  return (
    <UserList
      fetchAction={fetchAdminList}
      deleteAction={acl.canEdit ? deleteUser : null}
      resendInviteAction={resendInvite}
      userType="Admin"
      userList={userList}
      loading={loading}
      deleting={deleting}
      addNewRoute={`${location.pathname}/~add`}
      canInvite={acl.canEdit ?? false}
    />
  );
};

export default AdminList;
