import { Navigate, Outlet, Route, useParams } from 'react-router-dom';
import { resource, suffix } from './common/ACL';
import { ACL404 } from './common/ACL404';

import NotFound from './common/NotFound';

import ActionLogPage from './activity/ActionLogPage';
import AdvanceFormLinkPage from './advance-form/AdvanceFormLinkPage';
import CateringSummary from './catering/CateringSummary';
import ContactLayout from './contact/ContactLayout';
import ContactListLoader from './contact/ContactListLoader';
import ContactListView from './contact/ContactListView';
import ContactsUpload from './contact/ContactsUpload';
import DepartmentAdvanceApplicationDisplay from './department/DepartmentAdvanceApplicationDisplay';
import DepartmentApplicationDisplay from './department/DepartmentApplicationDisplay';
import DepartmentCateringLoader from './catering/DepartmentCateringLoader';
import DepartmentCateringUpload from './catering/DepartmentCateringUpload';
import DepartmentList from './department/DepartmentList';
import DepartmentListLoader from './department/DepartmentListLoader';
import DepartmentLoader from './department/DepartmentLoader';
import DepartmentRedirector from './department/DepartmentRedirector';
import DepartmentSettingsForm from './department/DepartmentSettingsForm';
import DepartmentSettingsLoader from './department/DepartmentSettingsLoader';
import DepartmentUserForm from './department/DepartmentUserForm';
import DepartmentUserList from './department/DepartmentUserList';
import DepartmentsUpload from './department/DepartmentsUpload';
import GroupCredentialAssignmentSettings from './group-credential/GroupCredentialAssignmentSettings';
import Reconcile from './reconcile/Reconcile';
import SendAdvanceFormLinkEmail from './advance-form/SendAdvanceFormLinkEmail';
import SendCredentialLinkEmail from './group-credential/SendCredentialLinkEmail';

const NotFoundIfDeptTypeIs = ({ children, deptTypes }) => {
  const params = useParams();
  if (deptTypes.indexOf(params.departmentType) > -1) {
    return <NotFound />;
  }
  return children ?? <Outlet />;
};

const NotFoundIfDeptTypeNot = ({ children, deptTypes }) => {
  const params = useParams();
  if (deptTypes.indexOf(params.departmentType) < 0) {
    return <NotFound />;
  }
  return children ?? <Outlet />;
};

export default (
  <Route
    element={
      <ACL404
        key="department"
        action={[
          resource.EVENT,
          { action: 'view', suffix: suffix.DEPARTMENT_TYPE },
        ]}
      />
    }
  >
    <Route path=":departmentType" element={<DepartmentListLoader />}>
      <Route index element={<Navigate to="~list" replace />} />
      <Route path="~list" element={<DepartmentList />} />
      <Route
        path="~add"
        element={
          <ACL404
            key="department-add"
            action={[
              resource.EVENT,
              { action: 'create', suffix: suffix.DEPARTMENT_TYPE },
            ]}
          >
            <DepartmentSettingsForm />
          </ACL404>
        }
      />
      <Route
        path="~upload"
        element={
          <ACL404
            key="departments-upload"
            action={[
              resource.EVENT,
              { action: 'bulkcreate', suffix: suffix.DEPARTMENT_TYPE },
            ]}
          >
            <DepartmentsUpload />
          </ACL404>
        }
      />
      <Route
        element={
          <ACL404
            key="department-view"
            action={[resource.DEPARTMENT, 'view']}
          />
        }
      >
        <Route path=":departmentSlug" element={<DepartmentLoader />}>
          <Route index element={<DepartmentRedirector />} />
          <Route
            element={
              <ACL404
                key="department-admin-view"
                action={[resource.DEPARTMENT, 'admin-view']}
              />
            }
          >
            <Route element={<ContactListLoader />}>
              <Route path="contacts">
                <Route element={<ContactLayout />}>
                  <Route index element={<Navigate to="list" replace />} />
                  <Route path=":listView" element={<ContactListView />} />
                </Route>
                <Route path="upload" element={<ContactsUpload />} />
              </Route>
              <Route
                path="reconcile/:contactId?"
                element={
                  <ACL404
                    key="department-reconcile-order"
                    action={[resource.EVENT, 'reconcile-order']}
                  >
                    <Reconcile />
                  </ACL404>
                }
              />
              <Route
                element={
                  <ACL404
                    key="department-application"
                    action={[resource.DEPARTMENT, 'admin-edit']}
                  />
                }
              >
                <Route
                  path="application"
                  element={
                    <NotFoundIfDeptTypeNot deptTypes={['intern', 'media']}>
                      <DepartmentApplicationDisplay />
                    </NotFoundIfDeptTypeNot>
                  }
                />
              </Route>
            </Route>
            <Route
              element={
                <ACL404
                  key="department-admin-edit"
                  action={[resource.DEPARTMENT, 'admin-edit']}
                />
              }
            >
              <Route path="users" element={<DepartmentUserList />} />
              <Route path="users/add" element={<DepartmentUserForm />} />
              <Route
                path="advance-application"
                element={
                  <NotFoundIfDeptTypeIs deptTypes={['intern', 'media']}>
                    <DepartmentAdvanceApplicationDisplay />
                  </NotFoundIfDeptTypeIs>
                }
              />
              <Route
                path="advance-form"
                element={
                  <NotFoundIfDeptTypeIs deptTypes={['intern', 'media']} />
                }
              >
                <Route index element={<AdvanceFormLinkPage />} />
                <Route
                  path="email-link"
                  element={<SendAdvanceFormLinkEmail />}
                />
              </Route>
            </Route>
          </Route>
          <Route
            element={
              <ACL404
                key="department-settings"
                action={[
                  resource.DEPARTMENT,
                  ['admin-edit', 'manage-catering'],
                ]}
              />
            }
          >
            <Route path="settings" element={<DepartmentSettingsLoader />}>
              <Route index element={<DepartmentSettingsForm editing />} />
            </Route>
          </Route>
          <Route
            element={
              <NotFoundIfDeptTypeIs deptTypes={['intern', 'media']}>
                <ACL404
                  key="department-catering"
                  action={[resource.DEPARTMENT, 'request-catering']}
                />
              </NotFoundIfDeptTypeIs>
            }
          >
            <Route path="catering" element={<DepartmentCateringLoader />}>
              <Route index element={<CateringSummary />} />
              <Route path="upload" element={<DepartmentCateringUpload />} />
            </Route>
          </Route>
          <Route
            path="group-credentials"
            element={
              <NotFoundIfDeptTypeIs deptTypes={['intern', 'media']}>
                <ACL404
                  key="department-group-credentials"
                  action={[resource.DEPARTMENT, 'manage-group-creds']}
                />
              </NotFoundIfDeptTypeIs>
            }
          >
            <Route index element={<GroupCredentialAssignmentSettings />} />
            <Route path="email-link" element={<SendCredentialLinkEmail />} />
          </Route>
          <Route
            path="action-log"
            element={
              <ACL404
                key="department-action-log"
                action={[resource.EVENT, 'view-activity-log']}
              >
                <ActionLogPage forDepartment />
              </ACL404>
            }
          />
        </Route>
      </Route>
    </Route>
  </Route>
);
