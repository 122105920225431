import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import dataUrlToFile from '../common/utils/dataUrlToFile';
import PromoterForm from './PromoterForm';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { showNotification } from '../notification/notification-actions';
import { getCurrentPromoter } from './promoter-selectors';
import {
  clearPulseClientList,
  fetchPromoterList,
  fetchPulseClientList,
  submitPromoter,
} from './promoter-actions';

const PromoterFormContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const promoter = useSelector(state => getCurrentPromoter(state, { params }));
  const pulseClientList = useSelector(state =>
    state.promoter.pulseClientList.get('data'),
  );

  const initialValues = useMemo(() => {
    const initialValues = {
      logo: {}, // Initial logo value for DropNCrop must be an object instead of a string
    };

    if (promoter) {
      initialValues.id = promoter.get('id');
      initialValues.name = promoter.get('name');
      initialValues.pulse_client_id = promoter.get('pulse_client_id');
    } else if (pulseClientList) {
      initialValues.pulse_client_id = pulseClientList.getIn([0, 'client_id']);
    }

    return initialValues;
  }, [promoter, pulseClientList]);

  const [clearOrigImage, setClearOrigImage] = useState(false);

  useEffect(() => {
    dispatch(fetchPulseClientList());
    return () => {
      dispatch(clearPulseClientList());
    };
  }, [dispatch]);

  const onSubmitSucceeded = () => {
    navigate('/promoters/~list');
    dispatch(
      showNotification({
        message: 'Promoter successfully saved',
        status: 'success',
      }),
    );
  };

  const removeImage = () => {
    setClearOrigImage(true);
  };

  const onSubmit = values => {
    const newValues = values;

    newValues.pulseClientManagerUserId = pulseClientList
      .find(client => client.get('client_id') === newValues.pulse_client_id)
      .get('client_mgr_user_id');

    if (newValues.logo && newValues.logo.result && newValues.logo.filename) {
      newValues.file = dataUrlToFile(
        newValues.logo.result,
        newValues.logo.filename,
      );
    }

    if (promoter && !clearOrigImage) {
      newValues.file_id = promoter.get('file_id');
    }

    return dispatch(submitPromoter(values)).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      } else {
        dispatch(fetchPromoterList());
        onSubmitSucceeded();
      }
    });
  };

  return (
    <div className="paper__wrapper">
      {pulseClientList || promoter ? (
        <PromoterForm
          initialValues={initialValues}
          promoter={promoter}
          onSubmit={onSubmit}
          clearOrigImage={clearOrigImage}
          removeImage={removeImage}
          pulseClientList={pulseClientList}
        />
      ) : null}
    </div>
  );
};

export default PromoterFormContainer;
