import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { trimUriSegments } from '../common/uri';
import {
  createAdvanceApplicationSection,
  createOrUpdateAdvanceApplicationSection,
  deleteAdvanceApplicationSection,
  updateAdvanceApplicationSection,
  updateAdvanceApplicationSectionSortOrders,
  saveAdvanceApplication,
  getAdvanceApplicationAvailableCredentials,
} from './advance-actions';
import { getCurrentEvent } from '../event/event-selectors';
import departmentTypeConstants from '../lib/department-type-map';
import Application from '../common/application/Application';
import { getCurrentApplication } from '../common/application/application-selectors';

const AdvanceApplicationForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));
  const application = useSelector(state =>
    getCurrentApplication(state, { type: 'advance', params }),
  );

  const departmentType =
    application?.get('departmentType') ?? params.departmentType;

  useEffect(() => {
    if (event)
      dispatch(
        getAdvanceApplicationAvailableCredentials(
          event.get('id'),
          departmentType,
        ),
      );
  }, [dispatch, event, departmentType]);

  return (
    <div>
      <Application
        type="advance"
        backTo={trimUriSegments(location.pathname, 1)}
        headerText="Advance Application"
        saveApplication={saveAdvanceApplication}
        createSection={createAdvanceApplicationSection}
        createOrUpdateSection={createOrUpdateAdvanceApplicationSection}
        deleteSection={deleteAdvanceApplicationSection}
        updateSection={updateAdvanceApplicationSection}
        updateSortOrders={updateAdvanceApplicationSectionSortOrders}
        includeCredentialsSection
        includeEmailSettingsSection
        departmentType={departmentTypeConstants[departmentType]}
        sections={[
          {
            key: 'intro',
            heading: 'Application Intro',
            text: 'This intro will appear directly below the header image (if one exists) and can be as long as needed.',
          },
          {
            key: 'department-info',
            heading: 'Department Info',
            text:
              'This section will capture the department contact info. ' +
              'You can enter a description that will appear above the capture inputs below.',
            sortable: true,
          },
          {
            key: 'credential-requests',
            heading: 'Staff Credentials',
            text:
              'This section will capture the group credential requests. ' +
              'You can enter a description that will appear above the capture inputs below.',
            sortable: true,
            optional: true,
          },
          {
            key: 'catering-instructions',
            heading: 'Staff Catering',
            text:
              'This section will capture the staff catering instructions. ' +
              'You can enter a description that will appear above the capture inputs below.',
            sortable: true,
            optional: true,
          },
          {
            key: 'advance-notes',
            heading: 'Advance Application Notes',
            text:
              'This section will capture notes for the advance application. ' +
              'You can enter a description that will appear above the capture inputs below.',
            sortable: true,
          },
        ]}
      />
    </div>
  );
};

export default AdvanceApplicationForm;
