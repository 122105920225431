import styled from 'styled-components';
import ReduxReactSelectField from '../common/forms/ReduxReactSelectField';

export const Header = styled.div`
  font-weight: 600;
  font-size: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledSectionSelect = styled(ReduxReactSelectField)`
  width: 330px !important;
`;
