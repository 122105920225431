import React, { useEffect, useId, useRef } from 'react';
import { Label } from 'synfrastructure';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Tag from '../Tag';

const ReduxFormsField = ({
  children,
  className,
  description,
  disabled = false,
  input,
  label,
  onChange,
  placeholder,
  required,
  meta: { touched, modifiedSinceLastSubmit, error, submitError },
}) => {
  const scaffoldClasses = {
    'input-scaffold': true,
    [`${className}`]: className,
  };

  const labelClasses = {
    input: true,
    'input--disabled': disabled,
  };

  const labelId = useId();
  const previousValue = useRef(input?.value);

  useEffect(() => {
    if (onChange && previousValue.current !== input.value) {
      onChange(input.value, previousValue.current);
      previousValue.current = input.value;
    }
  }, [input.value, onChange]);

  return (
    <div className={classNames(scaffoldClasses)}>
      <Label className={classNames(labelClasses)} htmlFor={labelId}>
        {label ? (
          <span className="input-label">
            <span className="input-label--label">{label}</span>
            {!required ? <Tag colorTheme="optional">optional</Tag> : null}
          </span>
        ) : (
          <span />
        )}
        {description ? (
          <span style={{ display: 'block', marginBottom: '10px' }}>
            {description}
          </span>
        ) : null}
      </Label>
      <div>
        {React.cloneElement(children, {
          id: labelId,
          ...{ disabled, placeholder },
          ...input,
        })}
      </div>
      {touched && error ? (
        <div className="input-validation">{error}</div>
      ) : null}
      {touched && !error && submitError && !modifiedSinceLastSubmit ? (
        <div className="input-validation">{submitError}</div>
      ) : null}
    </div>
  );
};

ReduxFormsField.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default ReduxFormsField;
