import Immutable from 'immutable';
import get from 'lodash/get';
import set from 'lodash/set';
import config from '../../config';

export const defaultState = Immutable.Map({
  loaded: false,
  loading: false,
  data: Immutable.fromJS([]),
  error: false,
  validationErrors: Immutable.Map(),
});

export function formatValidationErrors(error) {
  const errors = {};

  if (!error || !error.validationErrors) {
    return Immutable.fromJS(errors);
  }

  const validationMessages = config.validationMessages || {};

  error.validationErrors.forEach(validationError => {
    if (!get(errors, validationError.key)) {
      set(errors, validationError.key, []);
    }
    get(errors, validationError.key).push(
      validationMessages[validationError.type] || validationError.message,
    );
  });

  return Immutable.fromJS(errors);
}

const getApiReducer = (actionTypePrefix, dataDefault) => {
  const newDefault = !dataDefault
    ? defaultState
    : defaultState.set('data', Immutable.fromJS(dataDefault));

  return (state = newDefault, action) => {
    switch (action.type) {
      case `${actionTypePrefix}_REQUEST`:
        return state.merge({
          loading: true,
          loaded: false,
          validationErrors: Immutable.Map(),
        });
      case `${actionTypePrefix}_SUCCESS`:
        return state.merge({
          loading: false,
          loaded: true,
          data: Immutable.fromJS(action.json),
          validationErrors: Immutable.Map(),
          error: false,
        });
      case `${actionTypePrefix}_FAILURE`:
        return state.merge({
          loading: false,
          loaded: false,
          data: null,
          validationErrors: formatValidationErrors(action.json),
          error: action.json.validationErrors
            ? null
            : Immutable.fromJS(action.json),
        });
      case `${actionTypePrefix}_RESET`:
        return newDefault;
      default:
        break;
    }

    return state;
  };
};

export default getApiReducer;
