export const stripTrailingSlash = path =>
  path[path.length - 1] === '/' ? path.substr(0, path.length - 1) : path;

export const removeLastUriSegment = path =>
  stripTrailingSlash(path).substr(0, path.lastIndexOf('/'));

export const trimUriSegments = (path, count) => {
  let newPath = path;
  for (let i = 0; i < count; i++) {
    newPath = removeLastUriSegment(newPath);
  }
  return newPath;
};
