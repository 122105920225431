import { useState } from 'react';
import { Form } from 'react-final-form';
import { Renderer, defaultBlocks } from '../../lib/draftjs-editor';
import { renderSection } from './application-helpers';
import { applicationUpload } from '../../file/file-actions';
import arrayMutators from 'final-form-arrays';
import partial from 'lodash/partial';
import StatusButton from '../StatusButton';
import Immutable from 'immutable';
const isImmutable = Immutable.Iterable.isIterable;

const draftRenderer = new Renderer(defaultBlocks);

const ApplicationPublicForm = ({
  children,
  event,
  application,
  applicationToken,
  initialValues,
  disabled = false,
  onSubmit,
  updateSection,
}) => {
  const uploadAction = partial(applicationUpload, applicationToken);

  const sections = application.get('sections');
  const intro = sections?.find(section => section.get('key') === 'intro');

  const [sectionUpdated, setSectionUpdated] = useState(false);

  const handleUpdateSection = (...args) => {
    if (!sectionUpdated) setSectionUpdated(true);
    updateSection(...args);
  };

  const invalidCustomSections = () =>
    sections
      .filter(section => !section.get('key'))
      .some(section => {
        let customFields = section.get('custom_fields');
        if (!customFields) return false;
        if (isImmutable(customFields)) customFields = customFields.toJS();
        if (typeof customFields === 'string')
          customFields = JSON.parse(customFields);
        return customFields.fields?.some(field => !!field.error) ?? false;
      });

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        modifiedSinceLastSubmit,
        pristine,
        submitting,
        submitFailed,
      }) => (
        <form onSubmit={handleSubmit}>
          {/* APPLICATION INTRO SECTION */}
          {intro && (
            <section className="public-application__section">
              <div
                className="draftjs-output"
                dangerouslySetInnerHTML={{
                  __html: draftRenderer.convertRawToHTML(
                    intro.get('content').toJS(),
                  ),
                }}
              />
            </section>
          )}

          {children}

          {/* CUSTOM FORM BUILDER SECTION */}
          {sections &&
            sections.map(section => (
              <div key={section.get('id')}>
                {renderSection(
                  application,
                  section,
                  event,
                  handleUpdateSection,
                  uploadAction,
                )}
              </div>
            ))}

          {/* APPLICATION SUBMIT SECTION */}
          <section className="public-application__section">
            <StatusButton
              disabled={
                disabled ||
                submitting ||
                (submitFailed && !modifiedSinceLastSubmit && !sectionUpdated) ||
                (pristine && !sectionUpdated) ||
                invalidCustomSections()
              }
              status={
                submitFailed && !modifiedSinceLastSubmit
                  ? 'error'
                  : submitting
                  ? 'loading'
                  : 'default'
              }
              type="submit"
              buttonText="Submit Form"
              completeText="Submitted"
            />
          </section>
        </form>
      )}
    />
  );
};

export default ApplicationPublicForm;
