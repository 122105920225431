import roleConstants from '../../../lib/acl/role-constants';
import resourceConstants from '../../../lib/acl/resource-constants';

const promoterUserAccess = acl => {
  acl.allow(
    roleConstants.PROMOTER_ADMIN,
    resourceConstants.PROMOTER,
    null,
    (err, user, resource, action, result, next) => {
      if (err) {
        throw err;
      }

      const promoterRoles = user.permissionsMap.promoterMap[resource.id];
      if (!promoterRoles) {
        result(null, false);
      } else {
        result(
          null,
          promoterRoles.indexOf(roleConstants.PROMOTER_ADMIN) !== -1,
        );
      }
    },
  );

  acl.allow(
    roleConstants.PROMOTER_ADMIN,
    resourceConstants.FESTIVAL,
    null,
    (err, user, resource, action, result, next) => {
      if (err) {
        throw err;
      }

      const promoterRoles =
        user.permissionsMap.promoterMap[resource.promoter_id];
      if (!promoterRoles) {
        result(null, false);
      } else {
        result(
          null,
          promoterRoles.indexOf(roleConstants.PROMOTER_ADMIN) !== -1,
        );
      }
    },
  );

  acl.allow(
    roleConstants.PROMOTER_ADMIN,
    resourceConstants.EVENT,
    null,
    (err, user, resource, action, result, next) => {
      if (err) {
        throw err;
      }

      if (!resource.promoter_id) {
        next();
      } else {
        const promoterRoles =
          user.permissionsMap.promoterMap[resource.promoter_id];
        if (!promoterRoles) {
          result(null, false);
        } else {
          result(
            null,
            promoterRoles.indexOf(roleConstants.PROMOTER_ADMIN) !== -1,
          );
        }
      }
    },
  );

  acl.allow(
    roleConstants.PROMOTER_ADMIN,
    resourceConstants.DEPARTMENT,
    null,
    (err, user, resource, action, result, next) => {
      if (err) {
        throw err;
      }

      if (!resource.promoter_id) {
        next();
      } else {
        const promoterRoles =
          user.permissionsMap.promoterMap[resource.promoter_id];
        if (!promoterRoles) {
          result(null, false);
        } else {
          result(
            null,
            promoterRoles.indexOf(roleConstants.PROMOTER_ADMIN) !== -1,
          );
        }
      }
    },
  );
};

export default promoterUserAccess;
