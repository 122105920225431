import React from 'react';

function InternApplication() {
  /* eslint-disable max-len */
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 2h-4.18C11.4.84 10.3 0 9 0 7.7 0 6.6.84 6.18 2H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4.167c3.22 0 5.833 2.613 5.833 5.833S12.22 17.833 9 17.833 3.167 15.22 3.167 12 5.78 6.167 9 6.167zm-1.75 5.687c.402 0 .73.327.73.73 0 .402-.328.728-.73.728-.403 0-.73-.326-.73-.73 0-.4.327-.728.73-.728zm3.5 0c.402 0 .73.327.73.73 0 .402-.328.728-.73.728-.403 0-.73-.326-.73-.73 0-.4.327-.728.73-.728zM9 16.667c2.572 0 4.667-2.095 4.667-4.667 0-.46-.07-.904-.193-1.318-.42.1-.857.15-1.312.15-1.966 0-3.705-.973-4.76-2.466-.572 1.394-1.663 2.52-3.04 3.132-.017.164-.03.333-.03.502 0 2.572 2.095 4.667 4.668 4.667z" />
    </svg>
  );
  /* eslint-enable */
}

export default InternApplication;
