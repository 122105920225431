import PropTypes from 'prop-types';
import reduce from 'lodash/reduce';
import departmentTypeMap from '../../lib/department-type-map';

const DISABLED = '';
const GROUP_PICKUP = 'group';
const INDIVIDUAL_PICKUP = 'individual';

const AttendeeTypesField = ({ enabledSections, onChange, value }) => {
  const myOnChange = (type, event) => {
    const selectedValue = event.currentTarget.value;
    const newValue = { ...value };
    newValue[type] = selectedValue;
    onChange(newValue);
  };

  const selectAll = (selection, event) => {
    event.preventDefault();
    const newValues = reduce(
      { ...value },
      (newTypeSelections, _value, key) => {
        newTypeSelections[key] = selection;
        return newTypeSelections;
      },
      {},
    );
    onChange(newValues);
  };

  const renderTypeSelector = type => {
    const fieldId = `attendee-type-select-${type}`;
    const label = departmentTypeMap[type].label.singular;
    return (
      <label className="input-scaffold" key={type}>
        <span className="input-label">{label}</span>
        <select
          id={fieldId}
          onChange={event => myOnChange(type, event)}
          value={value[type] ?? DISABLED}
        >
          <option value={INDIVIDUAL_PICKUP}>Individual Pickup</option>
          <option value={GROUP_PICKUP}>Group Pickup</option>
          <option value={DISABLED}>Disabled</option>
        </select>
      </label>
    );
  };

  return (
    <div className="generic-form__body">
      <button
        className="button button--icon"
        style={{ margin: '0 20px 10px 0' }}
        onClick={event => selectAll(INDIVIDUAL_PICKUP, event)}
      >
        <span>All Individual</span>
      </button>

      <button
        className="button button--icon"
        style={{ margin: '0 20px 10px 0' }}
        onClick={event => selectAll(GROUP_PICKUP, event)}
      >
        <span>All Group</span>
      </button>

      <button
        className="button button--icon"
        style={{ margin: '0 20px 10px 0' }}
        onClick={event => selectAll(DISABLED, event)}
      >
        <span>Disable All</span>
      </button>

      <div className="input-group input-group--small">
        {enabledSections && enabledSections.map(renderTypeSelector)}
      </div>
    </div>
  );
};

AttendeeTypesField.propTypes = {
  enabledSections: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default AttendeeTypesField;
