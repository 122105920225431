import { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import StatusButton from '../common/StatusButton';

const Content = styled.div`
  margin-bottom: 16px;
`;

const DeclineAllConfirmationBox = ({
  declineAll,
  declining,
  pendingOnly = false,
  closeModal,
  handleDeclineAll,
}) => {
  const [dispatching, setDispatching] = useState(null);

  useEffect(() => {
    if (!declining) {
      setDispatching(null);
      closeModal();
    }
  }, [declining, closeModal]);

  const hasPending = declineAll?.requests?.some(
    req => req.get('quantity_pending') > 0,
  );

  const hasApproved = declineAll?.requests?.some(
    req => req.get('quantity_approved') > 0,
  );

  const getContent = () => {
    const requestsLabel = pendingOnly || !hasApproved ? 'all pending' : 'all';
    const periodsLabel = declineAll.period ? (
      <>
        period <strong>"{declineAll.period.name}"</strong>
      </>
    ) : (
      <>
        for <strong>all</strong> periods
      </>
    );

    if (declineAll.department && !declineAll.contact) {
      return (
        <Content>
          You have requested to decline <strong>{requestsLabel}</strong>{' '}
          credential requests for department{' '}
          <strong>"{declineAll.department.name}"</strong> for {periodsLabel}.
        </Content>
      );
    }

    if (declineAll.contact?.id === 'group') {
      return (
        <Content>
          You have requested to decline <strong>{requestsLabel}</strong> group
          level credential requests for department{' '}
          <strong>"{declineAll.department.name}"</strong> for {periodsLabel}.
        </Content>
      );
    }

    if (declineAll.contact) {
      return (
        <Content>
          You have requested to decline <strong>{requestsLabel}</strong>{' '}
          credential requests for staff{' '}
          <strong>"{declineAll.contact.name}"</strong> for {periodsLabel}.
        </Content>
      );
    }

    return null;
  };

  const _handleDeclineAll = () => {
    setDispatching('all');
    handleDeclineAll(declineAll, false);
  };

  const _handleDeclinePending = () => {
    setDispatching('pending');
    handleDeclineAll(declineAll, true);
  };

  return (
    <Modal closeIcon open={declineAll.open} onClose={closeModal}>
      <Modal.Header>
        <div className="confirmation__icon" style={{ display: 'flex' }}>
          <Icon icon="Warning" style={{ marginBottom: '0px' }} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            <span>
              Decline All {pendingOnly || !hasApproved ? 'Pending' : ''}{' '}
              Credentials
            </span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div className="modal__body">
          <div>
            {getContent()}
            <h4 style={{ color: '#E74C3C' }}>TIMEOUT WARNING:</h4>
            <Content>
              Please be aware that declining multiple credential requests in a
              single submission may cause your browser to timeout while they are
              being processed. Strata will continue processing all submitted
              credential requests even if your browser experiences a timeout
              during this process. Please refresh the approvals page in a short
              while to confirm that all credentials have been declined.
            </Content>
            {!pendingOnly && hasApproved && hasPending && (
              <Content>
                <strong>
                  Do you want to decline all "Pending and Approved Requests" or
                  only "Pending Requests"?
                </strong>
              </Content>
            )}
          </div>
        </div>
        <div className="modal__footer">
          {!pendingOnly && hasApproved && (
            <StatusButton
              buttonText="Decline All Requests"
              disabled={!!dispatching}
              status={dispatching === 'all' ? 'loading' : 'default'}
              onClick={_handleDeclineAll}
            />
          )}
          {(pendingOnly || hasPending) && (
            <StatusButton
              buttonText="Decline Pending Requests"
              disabled={!!dispatching}
              status={dispatching === 'pending' ? 'loading' : 'default'}
              onClick={_handleDeclinePending}
            />
          )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

DeclineAllConfirmationBox.propTypes = {
  declineAll: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    department: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    contact: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    period: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
  declining: PropTypes.bool.isRequired,
  pendingOnly: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  handleDeclineAll: PropTypes.func.isRequired,
};

export default DeclineAllConfirmationBox;
