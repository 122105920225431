import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import { getCurrentDepartment } from './department-selectors';
import { fetchUnassignedCredentialList } from '../credential-request/credential-request-actions';
import { fetchDepartmentMealTypes } from './department-actions';

const aclRules = {
  canEdit: [resource.DEPARTMENT, 'admin-edit'],
  canManageCatering: [resource.DEPARTMENT, 'manage-catering'],
};

const DepartmentSettingsLoader = () => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const params = useParams();

  const departmentId = useSelector(state =>
    getCurrentDepartment(state, { params })?.get('id'),
  );

  useEffect(() => {
    if (departmentId && acl.canEdit)
      dispatch(fetchUnassignedCredentialList(departmentId));
  }, [acl.canEdit, departmentId, dispatch]);

  useEffect(() => {
    if (departmentId && (acl.canEdit || acl.canManageCatering))
      dispatch(fetchDepartmentMealTypes(departmentId));
  }, [acl.canEdit, acl.canManageCatering, departmentId, dispatch]);

  return <Outlet />;
};

export default DepartmentSettingsLoader;
