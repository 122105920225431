import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentEvent } from '../event/event-selectors';
import {
  getMediaApplicationAvailableCredentials,
  getMediaApplicationEmailTypes,
  getMediaApplications,
} from './media-actions';

const MediaApplicationLoader = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const eventId = useSelector(state =>
    getCurrentEvent(state, { params })?.get('id'),
  );

  useEffect(() => {
    if (!eventId) return;

    dispatch(getMediaApplications(eventId));
    dispatch(getMediaApplicationAvailableCredentials('media', eventId));
    dispatch(getMediaApplicationEmailTypes());
  }, [dispatch, eventId]);

  return <Outlet />;
};

export default MediaApplicationLoader;
