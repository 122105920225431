import React from 'react';

function Truck() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M18,18.5A1.5,1.5,0,1,1,19.5,17,1.5,1.5,0,0,1,18,18.5Zm1.5-9L21.46,12H17V9.5ZM6,18.5A1.5,1.5,0,1,1,7.5,17,1.5,1.5,0,0,1,6,18.5ZM20,8H17V4H3A2.006,2.006,0,0,0,1,6V17H3a3,3,0,0,0,6,0h6a3,3,0,0,0,6,0h2V12Z" />
    </svg>
  );
  /* eslint-enable */
}

export default Truck;
