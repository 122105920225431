import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resource, useACL } from '../common/ACL';
import { getCurrentEvent } from '../event/event-selectors';
import { getCurrentDepartment } from './department-selectors';
import {
  createDepartmentMealTypeException,
  createDepartmentMealTypeOverride,
  deleteDepartmentMealTypeException,
  deleteDepartmentMealTypeOverride,
} from './department-actions';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import ExpandableRow from '../common/ExpandableRow';
import SwitchOn from '../common/icons/SwitchOn';
import SwitchOff from '../common/icons/SwitchOff';

const aclRules = {
  canEdit: [resource.DEPARTMENT, ['admin-edit', 'manage-catering']],
};

const DepartmentCateringList = () => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );

  const mealTypeList = useSelector(state =>
    state.department.departmentMealTypes.get('data'),
  );

  const mealTypeListLoaded = useSelector(state =>
    state.department.departmentMealTypes.get('loaded'),
  );

  if (!mealTypeListLoaded) {
    return <LoadingIndicator />;
  }

  const renderRequestableSwitch = type => {
    if (!acl.canEdit) {
      return null;
    }

    return (
      <div className="expandable-row__switch">
        <button
          type="button"
          className="switch__button"
          onClick={() => {
            if (type.get('departmentExceptions').size) {
              dispatch(
                deleteDepartmentMealTypeException(department, type.get('id')),
              );
            } else {
              dispatch(
                createDepartmentMealTypeException(department, type.get('id')),
              );
            }
          }}
        >
          {!type.get('departmentExceptions').size ? (
            <SwitchOn />
          ) : (
            <SwitchOff />
          )}
        </button>
        <span>Enabled For Department</span>
      </div>
    );
  };

  const renderDeadlineDateOverrideSwitch = type => {
    if (!acl.canEdit) {
      return null;
    }

    const mealTypeId = type.get('id');
    const isMealTypeEnabled = !type.get('departmentExceptions').size;
    if (!isMealTypeEnabled) return null;

    const mealTypeOverrides =
      department.getIn(['mealTypeOverrides']) &&
      department.getIn(['mealTypeOverrides']).toJS();

    const isLockDateOverriden =
      isMealTypeEnabled &&
      mealTypeOverrides &&
      mealTypeOverrides.some(ov => ov.id === mealTypeId);

    return (
      <div className="expandable-row__switch">
        <button
          type="button"
          className="switch__button"
          onClick={() => {
            if (isMealTypeEnabled) {
              if (isLockDateOverriden) {
                dispatch(
                  deleteDepartmentMealTypeOverride(department, mealTypeId),
                );
              } else {
                dispatch(
                  createDepartmentMealTypeOverride(department, mealTypeId),
                );
              }
            }
          }}
          disabled={!isLockDateOverriden && type.get('deadline_date') === null}
        >
          {isLockDateOverriden ? <SwitchOn /> : <SwitchOff />}
        </button>
        <span>Deadline Date Override</span>
      </div>
    );
  };

  const renderMealTypeList = () => {
    return (
      <div>
        {mealTypeList.map(type => (
          <ExpandableRow
            key={type.get('id')}
            columns={[
              <span className="expandable-row__title">{type.get('name')}</span>,
              <span></span>,
              <span></span>,
              <span style={{ opacity: 0.7 }}>
                {!!event.get('groups_enabled') &&
                type
                  .get('departmentGroupSettings')
                  .find(
                    s =>
                      s.get('meal_type_id') === type.get('id') &&
                      s.get('department_id') === department.get('id'),
                  ) &&
                ((Boolean(
                  type
                    .get('departmentGroupSettings')
                    .find(
                      s =>
                        s.get('meal_type_id') === type.get('id') &&
                        s.get('department_id') === department.get('id'),
                    )
                    .get('enabled'),
                ) === true &&
                  type.get('departmentExceptions').size > 0) ||
                  (Boolean(
                    type
                      .get('departmentGroupSettings')
                      .find(
                        s =>
                          s.get('meal_type_id') === type.get('id') &&
                          s.get('department_id') === department.get('id'),
                      )
                      .get('enabled'),
                  ) === false &&
                    type.get('departmentExceptions').size === 0)) ? (
                  <Icon
                    icon="ErrorTriangle"
                    style={{ float: 'right', marginRight: '10px' }}
                  />
                ) : null}
              </span>,
              renderRequestableSwitch(type),
              renderDeadlineDateOverrideSwitch(type),
            ]}
            isExpandable={false}
            isExpanded={false}
            onToggleExpansion={() => {}}
            caretSpace
          />
        ))}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <Paper>
        <div className="generic-list--empty">
          <Icon icon="Sad" />
          <p>No meal types are available for this department</p>
        </div>
      </Paper>
    );
  };

  return (
    <div>
      <Paper>
        <PaperHeader title="Catering" />
      </Paper>
      {mealTypeList.size ? renderMealTypeList(mealTypeList) : renderEmpty()}
    </div>
  );
};

export default DepartmentCateringList;
