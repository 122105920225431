import { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icons/Icon';

const ArrayInput = ({ value, placeholder, onChange, ...input }) => {
  const [autoFocus, setAutoFocus] = useState(false);

  const onAddIndex = event => {
    event.preventDefault();
    const values = value.slice(0);
    values.push('');
    onChange(values);
    setAutoFocus(true);
  };

  const onChangeIndex = (index, event) => {
    const values = value.slice(0);
    values[index] = event.target.value;
    onChange(values);
  };

  const onDeleteIndex = (index, event) => {
    event.preventDefault();
    const values = value.slice(0);
    values.splice(index, 1);
    onChange(values);
  };

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      event.target.blur();
    }
  };

  return (
    <div className="array-input__wrapper">
      {value.map((inputValue, index) => (
        <div className="array-input" key={index}>
          <input
            type="text"
            value={inputValue}
            onBlur={() => setAutoFocus(false)}
            onChange={event => onChangeIndex(index, event)}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            autoFocus={autoFocus && index === value.length - 1}
            {...input}
          />
          <button
            type="button"
            className="array-input__remove"
            onClick={event => onDeleteIndex(index, event)}
          >
            <Icon icon="Close" />
          </button>
        </div>
      ))}
      <button
        type="button"
        className="array-input__add button button--plain button--icon"
        onClick={onAddIndex}
      >
        <Icon icon="AddCircle" />
        <span>Add More</span>
      </button>
    </div>
  );
};

ArrayInput.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default ArrayInput;
