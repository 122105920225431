import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding-left: 10px;
  cursor: pointer;
  &:hover {
    background: #f2f2f2;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  > span {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    svg {
      fill: #201c23;
    }
  }
`;

export const Name = styled.div`
  display: flex;
  align-items: flex-end;
  height: 20px;
  color: #201c23;
  font-family: 'Roboto Condensed';
  font-size: 16px;
`;

export const Description = styled.div`
  display: flex;
  margin-left: 26px;
  opacity: 0.5;
  color: #201c23;
  font-size: 14px;
`;
