import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import KebabMenu from './KebabMenu';

const ProfileCardListItem = ({
  children,
  id,
  className,
  name,
  linkTo,
  image,
  subText,
  statusText,
  menuActive,
  onMenuToggle,
  labels,
}) => {
  const renderCardMenu = () => {
    return (
      <KebabMenu onMenuToggle={onMenuToggle} isMenuActive={menuActive}>
        {children}
      </KebabMenu>
    );
  };

  const renderCardLabels = () => {
    if (!labels) {
      return null;
    }

    return (
      <div className="card__tags">
        {labels.map(label => (
          <span key={label.text} className={label.className}>
            {label.text}
          </span>
        ))}
      </div>
    );
  };

  const _className = classNames({
    card: true,
    [className]: !!className,
  });

  const cardContents = (
    <Fragment>
      <div className="card__image">
        {image ? (
          <img alt={`${name} logo`} src={image} />
        ) : (
          <div>{name.charAt(0)}</div>
        )}
      </div>
      <div className="card__profile">
        <div className="card__profile--name">{name}</div>
        {subText ? (
          <div className="card__profile--subtitle">{subText}</div>
        ) : null}
        {statusText ? (
          <div className="card__profile--status">{statusText}</div>
        ) : null}
      </div>
    </Fragment>
  );

  return (
    <div className={_className}>
      {renderCardLabels()}
      {linkTo && (
        <Link to={linkTo(id)} className="card__link" title={name}>
          {cardContents}
        </Link>
      )}
      {!linkTo && <div className="card__link">{cardContents}</div>}
      {renderCardMenu()}
    </div>
  );
};

ProfileCardListItem.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  linkTo: PropTypes.func,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  subText: PropTypes.string,
  statusText: PropTypes.node,
  menuActive: PropTypes.bool,
  onMenuToggle: PropTypes.func,
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      className: PropTypes.string,
    }),
  ),
};

export default ProfileCardListItem;
