import { departmentFormatter } from '../../common/departmentFormatter';
import { PersonIcon, DepartmentIcon } from '../../common/SearchIcons';
import { Container, Row, Title, Description, Button } from './Result.styles';

const Result = ({ data, data: { table_name }, navigateToResult }) => (
  <Button
    onClick={() => {
      navigateToResult(data);
    }}
  >
    {table_name === 'person' && <PersonResult data={data} />}
    {table_name === 'department' && <DepartmentResult data={data} />}
  </Button>
);

const PersonResult = ({
  data: { first_name, last_name, department, type },
}) => (
  <Container>
    <PersonIcon />
    <Row>
      <Title>{`${first_name} ${last_name}`}</Title>
      <Description>
        in {`${department} in ${departmentFormatter(type)}`}
      </Description>
    </Row>
  </Container>
);

const DepartmentResult = ({ data: { type, department } }) => (
  <Container>
    <DepartmentIcon />
    <Row>
      <Title>{`${department}`}</Title>
      <Description>in {`${departmentFormatter(type)}`}</Description>
    </Row>
  </Container>
);

export default Result;
