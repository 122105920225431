import { Map } from 'immutable';
import { Renderer, defaultBlocks } from '../../lib/draftjs-editor';
import FormSchema from '../../lib/form-schema';
import PropTypes from 'prop-types';
import CustomForm from '../custom-forms/CustomForm';

const draftRenderer = new Renderer(defaultBlocks);

const CustomSection = ({
  className,
  event,
  section,
  updateSection,
  uploadAction,
}) => {
  const customFields = section.get('custom_fields');
  const formSchema = customFields ? new FormSchema(customFields) : null;

  return (
    <section
      className={`${className}`}
      key={section.get('id')}
      id={`section-${section.get('id')}`}
    >
      <header>
        <div className={`${className}--title`}>{section.get('name')}</div>
      </header>

      <article>
        <div className={`${className}--desc`}>
          <div
            className="draftjs-output"
            dangerouslySetInnerHTML={{
              __html: draftRenderer.convertRawToHTML(
                section.get('content').toJS(),
              ),
            }}
          />
        </div>

        {formSchema && (
          <CustomForm
            event={event}
            formSchema={formSchema}
            uploadAction={uploadAction}
            onChange={schema =>
              updateSection(section.get('id'), schema.getSchemaObject())
            }
          />
        )}
      </article>
    </section>
  );
};

CustomSection.propTypes = {
  className: PropTypes.string,
  event: PropTypes.instanceOf(Map),
  section: PropTypes.instanceOf(Map),
  updateSection: PropTypes.func.isRequired,
  uploadAction: PropTypes.func,
};

export default CustomSection;
