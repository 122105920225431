import React from 'react';

function Food() {
  /* eslint-disable max-len */
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M16,6v8h2.5v8H21V2C18.24,2,16,4.24,16,6ZM11,9H9V2H7V9H5V2H3V9a3.986,3.986,0,0,0,3.75,3.97V22h2.5V12.97A3.986,3.986,0,0,0,13,9V2H11Z" />
    </svg>
  );
  /* eslint-enable */
}

export default Food;
