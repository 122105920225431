import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import { combineValidators, isRequiredIf } from 'revalidate';
import { formatValidationErrors } from '../common/utils/getApiReducer';
import { getStatusForButton } from '../common/forms/FormHelper';
import { getCurrentDepartment } from '../department/department-selectors';
import { showNotification } from '../notification/notification-actions';
import {
  getGroupCredentialLink,
  submitGroupCredentialLink,
} from './group-credential-actions';
import {
  Header,
  Label,
  LockBox,
  LockedItems,
  Row,
  StyledField,
  StyledLock,
} from './GroupCredentialAssignmentSettings.styles';
import moment from 'moment';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import ReduxFormsField from '../common/forms/ReduxFormsField';
import ReduxFormsFieldNoLabel from '../common/forms/ReduxFormsFieldNoLabel';
import ReduxDatepickerField from '../common/forms/ReduxDatepickerField';
import ReduxTimepickerField from '../common/forms/ReduxTimepickerField';
import CheckboxGroup from '../common/forms/CheckboxGroup';
import StatusButton from '../common/StatusButton';
import { useEffect } from 'react';

const validate = combineValidators({
  close_date: isRequiredIf(values => values.close_time !== null)('Close Date'),
  close_time: isRequiredIf(values => values.close_date !== null)('Close Time'),
});

const GroupCredentialAssignmentSettings = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const department = useSelector(state =>
    getCurrentDepartment(state, { params }),
  );
  const departmentId = department?.get('id');

  const link = useSelector(state => state.groupCredential.deptLink.get('data'));
  const loaded = useSelector(state =>
    state.groupCredential.deptLink.get('loaded'),
  );

  const initialValues = {
    close_date: null,
    close_time: null,
    optionalFields: [],
    department_id: null,
  };

  let isEditing = false;
  if (link?.size > 0) {
    isEditing = true;

    initialValues.department_id = link.get('department_id');
    initialValues.optionalFields = JSON.parse(link.get('optional_fields'));

    const closeDate = link.get('close_date');
    initialValues.close_date = closeDate ? moment(closeDate) : null;
    initialValues.close_time = closeDate
      ? moment(closeDate).format('HH:mm:ss')
      : null;
  }

  useEffect(() => {
    if (departmentId) dispatch(getGroupCredentialLink(departmentId));
  }, [departmentId, dispatch]);

  const getOptionalFieldsOptions = () => {
    const options = [];

    if (!Boolean(department.get('require_contact_emails'))) {
      options.push({ label: 'Email', value: 'email' });
    }

    options.push({ label: 'Phone', value: 'phone' });
    options.push({ label: 'Role (populated drop-down)', value: 'role' });
    options.push({ label: 'Role (text field)', value: 'roleText' });

    return options;
  };

  const handleSubmit = values => {
    const { close_date: closeDate, close_time: closeTime, ...rest } = values;
    let payload = { ...rest };

    if (closeDate) {
      const close_date = moment(
        `${closeDate.format('YYYY-MM-DD')} ${closeTime}`,
      )
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      payload = { ...payload, close_date };
    }

    return dispatch(
      submitGroupCredentialLink(department.get('id'), payload),
    ).then(action => {
      if (!action.response.ok) {
        return formatValidationErrors(action.json).toJS();
      } else {
        dispatch(
          showNotification({ message: 'Link saved', status: 'success' }),
        );
      }
    });
  };

  if (!loaded) {
    return <LoadingIndicator />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({
        handleSubmit,
        hasValidationErrors,
        pristine,
        submitting,
        submitSucceeded,
        values,
      }) => {
        const timeSlotsRequired = !!values.close_date || !!values.close_time;

        return (
          <Paper>
            <PaperHeader title="Credential Link Settings" />
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="generic-form__body">
                <LockedItems>
                  <Header>Personnel Attributes</Header>
                  <Row>
                    <LockBox>
                      <StyledLock />
                    </LockBox>
                    <Label>First Name</Label>
                  </Row>
                  <Row>
                    <LockBox>
                      <StyledLock />
                    </LockBox>
                    <Label>Last Name</Label>
                  </Row>
                  {Boolean(department.get('require_contact_emails')) && (
                    <Row>
                      <LockBox>
                        <StyledLock />
                      </LockBox>
                      <Label>Email</Label>
                    </Row>
                  )}
                </LockedItems>
                <StyledField
                  style={{ marginTop: 0 }}
                  name="optionalFields"
                  component={ReduxFormsField}
                >
                  <CheckboxGroup options={getOptionalFieldsOptions()} />
                </StyledField>
                <div className="input-group input-group--large">
                  <div className="inline-inputs">
                    <Field
                      name="close_date"
                      label="Close Date"
                      required={timeSlotsRequired}
                      component={ReduxFormsField}
                      className="close-date"
                    >
                      <ReduxDatepickerField showClearDate />
                    </Field>
                    <Field
                      name="close_time"
                      label="Close Time"
                      required={timeSlotsRequired}
                      component={ReduxFormsFieldNoLabel}
                    >
                      <ReduxTimepickerField showClearDate />
                    </Field>
                  </div>
                </div>
              </div>
              <div className="generic-form__footer assignment-email">
                {link && link.get('link_url') && (
                  <div className="assignment-email__row">
                    <div className="assignment-email__column">
                      <span>
                        <strong>Link:</strong>{' '}
                        <a
                          target="_blank"
                          href={link.get('link_url')}
                          rel="noreferrer"
                        >
                          {link.get('link_url')}
                        </a>
                      </span>
                    </div>
                  </div>
                )}
                <div className="assignment-email__row__button">
                  <StatusButton
                    disabled={
                      submitting ||
                      hasValidationErrors ||
                      (pristine && isEditing)
                    }
                    status={getStatusForButton({
                      pristine,
                      submitting,
                      submitSucceeded,
                    })}
                    buttonText={`${isEditing ? 'Update' : 'Create'} Link`}
                    type="submit"
                  />
                </div>
              </div>
            </form>
          </Paper>
        );
      }}
    />
  );
};

export default GroupCredentialAssignmentSettings;
