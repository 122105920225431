import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { resource, suffix, useACL } from '../common/ACL';
import { getCurrentEvent } from '../event/event-selectors';
import { fetchDepartmentSummaries } from './department-actions';
import {
  getDepartmentList,
  getDepartmentListLoading,
} from './department-selectors';
import moment from 'moment';
import departmentTypeMap from '../lib/department-type-map';
import ReactTable from '../lib/react-table';
import Icon from '../common/icons/Icon';
import Paper, { PaperHeader } from '../common/paper/Paper';
import LoadingIndicator from '../common/LoadingIndicator';
import NoDataComponent from '../common/table/NoDataComponent';

const aclRules = {
  canViewRequestColumns: [resource.EVENT, 'view-credential-requests-summary'],
  canViewDepartment: [
    resource.EVENT,
    { action: 'view-department', suffix: suffix.DEPARTMENT_TYPE },
  ],
  canAddDepartment: [
    resource.EVENT,
    { action: 'create', suffix: suffix.DEPARTMENT_TYPE },
  ],
  canUploadDepartments: [
    resource.EVENT,
    { action: 'bulkcreate', suffix: suffix.DEPARTMENT_TYPE },
  ],
  canApproveCredential: [
    resource.EVENT,
    [
      'approve-credential',
      { action: 'approve-credential', suffix: suffix.DEPARTMENT_TYPE },
    ],
  ],
};

const DepartmentList = () => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { promoterSlug, festivalSlug, eventSlug, departmentType } = params;

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const departmentTypeLabel = departmentTypeMap[departmentType].label;

  const departmentList = useSelector(state =>
    getDepartmentList(state, { params }),
  );

  const loading = useSelector(state =>
    getDepartmentListLoading(state, { params }),
  );

  const [showTableFilter, setShowTableFilter] = useState(false);

  useEffect(() => {
    if (event && acl.canViewRequestColumns) {
      dispatch(
        fetchDepartmentSummaries(event.get('id'), params.departmentType),
      );
    }
  }, [acl.canViewRequestColumns, dispatch, event, params.departmentType]);

  const actions = [];
  if (departmentList && departmentList.size) {
    actions.push(
      <button
        type="button"
        key="add-department-filter-action"
        className="button button--plain button--icon"
        onClick={() => setShowTableFilter(!showTableFilter)}
      >
        <Icon icon="Filter" />
        <span>{showTableFilter ? 'Clear' : 'Advanced'} Filter</span>
      </button>,
    );
  }

  if (acl.canUploadDepartments) {
    actions.push(
      <Link
        key="upload-departments-action"
        className="button button--plain button--icon"
        to="../~upload"
      >
        <Icon icon="Upload" />
        <span>Upload {departmentTypeLabel.plural}</span>
      </Link>,
    );
  }

  if (acl.canAddDepartment) {
    actions.push(
      <Link
        key="add-department-action"
        className="button button--plain button--icon"
        to={`/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/${departmentType}/~add`}
      >
        <Icon icon="AddCircle" />
        <span>Add {departmentTypeLabel.singular}</span>
      </Link>,
    );
  }

  const onClickRow = row => {
    if (acl.canViewDepartment)
      window.open(
        `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/${departmentType}/${row._original.slug}`,
        `_blank`,
      );
    else if (acl.canApproveCredential && rowHasPendingRequests(row))
      navigate(
        `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/approvals/credentials/${departmentType}?departmentId=${row._original.id}`,
      );
  };

  const onClickCell = (event, row, col) => {
    if (
      col.id === 'ApprovalsAction' &&
      acl.canApproveCredential &&
      rowHasPendingRequests(row)
    ) {
      event.stopPropagation();
      navigate(
        `/promoters/${promoterSlug}/festivals/${festivalSlug}/events/${eventSlug}/approvals/credentials/${departmentType}?departmentId=${row.original.id}`,
      );
    }
  };

  const groupRequestsByPeriod = summary => {
    const requestsByCategoryMap = new Map();
    //holds the key values (period names) in ascending order sorted by periodStartDate
    const keysByPeriodStartDate = [];
    summary?.toJS().forEach(request => {
      const key = request.periodName;
      const date = request.periodStartDate;

      const requestsByCategoryName = requestsByCategoryMap.get(key);
      if (requestsByCategoryName) {
        requestsByCategoryName.push(request);
      } else {
        requestsByCategoryMap.set(key, [request]);
        keysByPeriodStartDate.push({
          name: key,
          date: date,
        });
      }
    });
    //sort the keys on period.start_date ascending order
    keysByPeriodStartDate.sort((a, b) => moment(a.date).diff(b.date));
    return {
      requestsMap: requestsByCategoryMap,
      keys: keysByPeriodStartDate,
    };
  };

  const renderDepartmentRequests = requests => (
    <div>
      {requests &&
        requests.map((request, index) => {
          return (
            <p key={index}>
              <span>{request.credentialType} (</span>
              <span className="status-approved" style={{ paddingRight: '2px' }}>
                {request.quantityApproved},
              </span>
              <span className="status-pending" style={{ paddingRight: '2px' }}>
                {request.quantityPending},
              </span>
              <span className="status-rejected">
                {request.quantityRejected}
              </span>
              <span>)</span>
            </p>
          );
        })}
    </div>
  );

  const rowHasPendingRequests = row => {
    const requestsByPeriod = row.original?.requests ?? row._original?.requests;
    if (!requestsByPeriod || !requestsByPeriod.size) {
      return false;
    }

    for (const periodRequests of requestsByPeriod.values()) {
      for (const request of periodRequests) {
        if (request.quantityPending) {
          return true;
        }
      }
    }

    return false;
  };

  const renderDepartmentList = () => {
    let periodRequestsColumnsMap = new Map();
    //stores the period name and the start_date for sorting
    const sectionKeysMap = new Map();
    const data = departmentList
      .map(department => {
        const { requestsMap, keys } = groupRequestsByPeriod(
          department.get('summary'),
        );
        for (const key of keys) {
          sectionKeysMap.set(key.name, key.date);
        }
        const [...periods] = requestsMap.keys();
        let result = {
          id: department.get('id'),
          name: department.get('name'),
          slug: department.get('slug'),
          requests: new Map(),
        };
        periods.forEach(key => {
          result.requests.set(key, requestsMap.get(key));
          periodRequestsColumnsMap.set(key, {
            Header: key,
            sortable: false,
            filterable: false,
            width: 250,
            id: key,
            accessor: row => row.requests.get(key),
            Cell: ({ value }) => renderDepartmentRequests(value),
          });
        });

        return result;
      })
      .toJS();

    let [...periodRequestsColumns] = periodRequestsColumnsMap.values();
    periodRequestsColumns.sort((a, b) =>
      moment(sectionKeysMap.get(a.Header)).diff(sectionKeysMap.get(b.Header)),
    );
    let columns = [
      {
        Header: 'Name',
        width: 250,
        accessor: 'name',
        Filter: ({ filter, onChange }) => (
          <input
            type="text"
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
            placeholder="Search by name"
          />
        ),
        Cell: row => {
          return (
            <span className="reconcilation">
              <span>{row.value}</span>
            </span>
          );
        },
      },
    ];

    if (acl.canViewRequestColumns && periodRequestsColumns.length > 0) {
      columns.push({
        Header: () => (
          <div className="department-list-column-header">
            <span>Requests (</span>
            <span className="status-approved">Approved</span>
            <span>, </span>
            <span className="status-pending">Pending</span>
            <span>, </span>
            <span className="status-rejected">Declined</span>
            <span>)</span>
          </div>
        ),
        className: 'department-list-column-header',
        width: 750,
        columns: periodRequestsColumns,
      });
    }

    if (acl.canApproveCredential) {
      columns.push({
        id: 'ApprovalsAction',
        sortable: false,
        filterable: false,
        width: 64,
        className: 'action',
        Cell: row => {
          if (acl.canApproveCredential && rowHasPendingRequests(row)) {
            return <Icon icon="ListCheck" />;
          }

          return null;
        },
      });
    }

    if (acl.canViewDepartment) {
      columns.push({
        sortable: false,
        filterable: false,
        width: 64,
        className: 'action action--animate',
        Cell: () => <Icon icon="ArrowRight" />,
      });
    }
    const pageSize = 20;
    return (
      <ReactTable
        className="department-list"
        data={data}
        columns={columns}
        defaultSorted={[
          {
            id: 'name',
            asc: true,
          },
        ]}
        minRows={0}
        defaultPageSize={pageSize}
        showPagination={data.length > pageSize}
        showPaginationTop={true}
        showPaginationBottom={true}
        showPageSizeOptions={false}
        filterable={showTableFilter}
        getTrGroupProps={(_state, rowInfo) =>
          acl.canViewDepartment || acl.canApproveCredential
            ? {
                onClick: () => onClickRow(rowInfo.row),
              }
            : {
                className: 'no-actions',
              }
        }
        getTdProps={(_state, row, col) => ({
          onClick: event => onClickCell(event, row, col),
        })}
        NoDataComponent={NoDataComponent}
      />
    );
  };

  const renderEmpty = () => (
    <div className="generic-list--empty">
      <Icon icon="Sad" />
      <p>You have no departments added yet for this section.</p>
    </div>
  );

  return (
    <Paper>
      <PaperHeader title={departmentTypeLabel.plural} actions={actions} />
      {loading || !departmentList ? (
        <LoadingIndicator />
      ) : !departmentList.size ? (
        renderEmpty()
      ) : (
        renderDepartmentList()
      )}
    </Paper>
  );
};

export default DepartmentList;
