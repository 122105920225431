import { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';

const DeleteButton = ({
  buttonText = 'Delete',
  confirmText = 'Are you sure you want to delete?',
  onConfirmDelete,
}) => {
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  if (confirmingDelete) {
    return (
      <div>
        <p>{confirmText}</p>
        <div>
          <button
            className="button button--icon button--plain"
            type="button"
            onClick={() => setConfirmingDelete(!onConfirmDelete())}
          >
            <Icon icon="CheckCircle" /> Yes
          </button>
          <button
            className="button--secondary button--plain button--secondary--icon"
            type="button"
            onClick={() => setConfirmingDelete(false)}
          >
            <Icon icon="CloseCircle" /> No
          </button>
        </div>
      </div>
    );
  }
  return (
    <button
      className="button button--icon button--plain"
      onClick={() => setConfirmingDelete(true)}
      type="button"
    >
      <Icon icon="Trash" />
      {buttonText}
    </button>
  );
};

DeleteButton.propTypes = {
  buttonText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirmDelete: PropTypes.func.isRequired,
  marginTopAdjust: PropTypes.bool,
};

export default DeleteButton;
