import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { components } from 'react-select';
import { Map } from 'immutable';
import { defer } from 'lodash';
import { getStaffContactList } from '../contact/contact-selectors';
import { getContactLabelForDepartment } from './onsite-utils';
import sortContacts from '../contact/sort-contacts';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';
import InputScaffold from '../common/forms/InputScaffold';
import ReduxReactSelectField from '../common/forms/ReduxReactSelectField';

const Option = props => (
  <components.Option {...props}>
    <div className="onsite-select__contact-option">
      <span>{props.label}</span>
      {props.isDisabled && <Icon icon="IssuedCredential" />}
    </div>
  </components.Option>
);

const SelectContact = ({
  type = 'Change',
  department,
  onContactSelected,
  disableIssuedContacts = false,
}) => {
  const params = useParams();
  const selectRef = useRef(null);

  const contacts = useSelector(state => getStaffContactList(state, { params }));

  const [selectedContact, setSelectedContact] = useState(null);

  const contactOptions = useMemo(() => {
    const getPulseTagsCount = contact => {
      const pulseOrders = department.get('pulseOrders');

      return !pulseOrders
        ? 0
        : pulseOrders
            .filter(
              pulseOrder => pulseOrder.get('contact_id') === contact.get('id'),
            )
            .reduce((count, order) => count + order.get('pulseTags').size, 0);
    };

    return sortContacts(contacts)
      ?.map(contact => ({
        label: `${contact.get('first_name')} ${contact.get('last_name')}`,
        value: contact.get('id'),
        isDisabled: disableIssuedContacts && !!getPulseTagsCount(contact),
      }))
      .toArray();
  }, [contacts, department, disableIssuedContacts]);

  useEffect(() => {
    if (!selectedContact) defer(() => selectRef.current.focus());
  }, [selectedContact]);

  if (!contacts) {
    return null;
  }

  const label = getContactLabelForDepartment(department);

  const selectContact = selectedContact => {
    const contact = selectedContact
      ? contacts.find(contact => contact.get('id') === selectedContact.value)
      : null;
    onContactSelected(contact);
    setSelectedContact(selectedContact);
  };

  return (
    <InputScaffold label={`${label} to ${type}`} required>
      <ReduxReactSelectField
        ref={selectRef}
        autoFocus
        className="onsite-select"
        components={{ Option }}
        placeholder={`Select ${label.toLowerCase()} to ${type.toLowerCase()}...`}
        isClearable
        value={selectedContact ? selectedContact : null}
        options={contactOptions}
        onChange={selectContact}
      />
    </InputScaffold>
  );
};

SelectContact.propTypes = {
  type: PropTypes.string,
  department: PropTypes.instanceOf(Map).isRequired,
  onContactSelected: PropTypes.func.isRequired,
  disableIssuedContacts: PropTypes.bool,
};

export default SelectContact;
