import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { List, Map } from 'immutable';
import { getReportData } from '../reports-actions';
import {
  configurePayloadFromSearchParams,
  fetchDownload,
} from '../reports-utils';
import {
  Container,
  Footer,
  StrataLogo,
  TableWrapper,
} from './TableContainer.styles';
import { StyledButton } from '../../common/StyledButton';
import { generateTitleAndColumns } from './table-utils';
import ReactTable from '../../lib/react-table';
import LoadingIndicator from '../../common/LoadingIndicator';
import DownloadModal from './TableComponents/DownloadModal';
import EmailModal from './TableComponents/EmailModal';

const styles = {
  table: {
    height: '100%',
    width: '100%',
  },
  tHeadGroupTh: {
    color: '#000',
    fontSize: '1.5rem',
    backgroundColor: '#fff',
  },
  tHeadTh: {
    color: '#fff',
    display: 'flex',
    flex: 1,
    width: '100%',
  },
};

const TableContainer = ({ tableSize = 20 }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const [searchParams] = useSearchParams();

  const resource = params.resource;
  const resourceId = params.resourceId;
  const resourceSlug = searchParams?.get('resource_slug');
  const reportSlug = searchParams?.get('report_slug');

  const reports = useSelector(state => state.reports);

  const [fetching, setFetching] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const [ready, setReady] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    const fetchReport = async () => {
      const payload = configurePayloadFromSearchParams({ searchParams });

      const { response } = await dispatch(
        getReportData(resource, resourceId, reportSlug, payload),
      );

      if (!response.ok) throw new Error('Error while fetching report.');

      setFetching(false);
    };

    fetchReport();
  }, [dispatch, reportSlug, resource, resourceId, searchParams]);

  useEffect(() => {
    if (!fetching && reports) {
      const viewData = reports.viewData.get(resource);

      const dataLabels = (
        viewData.getIn([resourceId, reportSlug, 'data', 'data_labels']) ||
        new Map()
      ).toJS();

      const data = (
        viewData.getIn([resourceId, reportSlug, 'data', 'data']) || new List()
      ).toJS();

      if (reportSlug === 'credential_link' || reportSlug === 'advance_link') {
        data.forEach(dataObj => {
          dataObj.link_url = (
            <a
              href={`${dataObj.link_url}`}
              target="__blank"
            >{`${dataObj.link_url}`}</a>
          );
        });
      }

      const { title, mappedColumns } = generateTitleAndColumns(
        reportSlug,
        dataLabels,
      );

      setTitle(title);
      setColumns(mappedColumns);
      setData(data);
      setReady(true);
    }
  }, [fetching, reports, reportSlug, resourceId, resource]);

  const emailReport = async ({ emails, mime }) => {
    const payload = configurePayloadFromSearchParams({
      searchParams,
      email_to: emails,
      mime,
    });

    return dispatch(getReportData(resource, resourceId, reportSlug, payload));
  };

  const downloadReport = (mime, includeParameters = false) => {
    setDownloading(true);
    const payload = configurePayloadFromSearchParams({
      mime,
      searchParams,
      includeParameters,
    });

    fetchDownload(resource, resourceId, resourceSlug, payload).then(() =>
      setDownloading(false),
    );
  };

  return (
    <Container>
      <TableWrapper>
        {ready ? (
          <ReactTable
            data={data}
            columns={[
              {
                Header: title,
                columns,
              },
            ]}
            getTheadGroupThProps={() => ({
              style: styles.tHeadGroupTh,
            })}
            getTheadThProps={() => ({
              style: styles.tHeadTh,
            })}
            defaultPageSize={tableSize}
            style={styles.table}
            className="-striped -highlight"
          />
        ) : (
          <LoadingIndicator size="large" />
        )}
      </TableWrapper>
      <Footer>
        <StrataLogo />
        <DownloadModal
          handleDownload={downloadReport}
          trigger={
            <StyledButton
              color="gray"
              title="Download Report"
              margin="right"
              submitting={downloading}
            />
          }
        />
        <EmailModal
          emailReport={emailReport}
          trigger={<StyledButton color="blue" title="Email Report" />}
        />
      </Footer>
    </Container>
  );
};

TableContainer.propTypes = {
  forDepartment: PropTypes.bool,
  tableSize: PropTypes.number,
};

export default TableContainer;
