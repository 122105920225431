import Acl from '../../lib/acl/acl';
import roleConstants from '../../lib/acl/role-constants';
import resourceConstants from '../../lib/acl/resource-constants';
import roleResourceInit from '../../lib/acl/role-resource-init';
import suffixConstants from '../../lib/acl/suffix-constants';

import promoterUserAccess from './access/promoter-user-access';
import eventUserAccess from './access/event-user-access';
import departmentUserAccess from './access/department-user-access';

const acl = new Acl();
roleResourceInit(acl);

acl.deny(); // deny all by default
acl.allow(roleConstants.STRATA_ADMIN); // strata admins can do anything

// Anyone can list festivals for a promoter. Endpoint only returns what user can access.
acl.allow(null, resourceConstants.PROMOTER, 'list-festivals');

promoterUserAccess(acl);
eventUserAccess(acl);
departmentUserAccess(acl);

export { resourceConstants, roleConstants, suffixConstants };
export default acl;
