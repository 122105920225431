import client from '../client';

export const BULK_SEND_EMAIL = 'BULK_SEND_EMAIL';
export function bulkSendEmail(eventId, emailTypeId, payload) {
  return {
    type: BULK_SEND_EMAIL,
    promise: client.post(
      `event/${eventId}/email/types/${emailTypeId}/bulksend`,
      payload,
    ),
  };
}

export const ADD_EMAIL_TYPE_SAMPLE = 'ADD_EMAIL_TYPE_SAMPLE';
export function addEmailTypeSample(emailType, payload) {
  const emailTypeId = emailType.get('id');
  const applicationType = emailType.get('application_type');
  const urlPrefix = applicationType ? 'application/' : '';
  return {
    emailTypeId,
    applicationType,
    type: ADD_EMAIL_TYPE_SAMPLE,
    promise: client.post(
      `${urlPrefix}email/types/${emailTypeId}/samples`,
      payload,
    ),
  };
}

export const UPDATE_EMAIL_TYPE_SAMPLE = 'UPDATE_EMAIL_TYPE_SAMPLE';
export function updateEmailTypeSample(emailType, emailTypeSampleId, payload) {
  const emailTypeId = emailType.get('id');
  const applicationType = emailType.get('application_type');
  const urlPrefix = applicationType ? 'application/' : '';
  return {
    emailTypeId,
    emailTypeSampleId,
    applicationType,
    type: UPDATE_EMAIL_TYPE_SAMPLE,
    promise: client.put(
      `${urlPrefix}email/types/${emailTypeId}/samples/${emailTypeSampleId}`,
      payload,
    ),
  };
}

export const DELETE_EMAIL_TYPE_SAMPLE = 'DELETE_EMAIL_TYPE_SAMPLE';
export function deleteEmailTypeSample(emailType, emailTypeSampleId) {
  const emailTypeId = emailType.get('id');
  const applicationType = emailType.get('application_type');
  const urlPrefix = applicationType ? 'application/' : '';
  return {
    emailTypeId,
    emailTypeSampleId,
    applicationType,
    type: DELETE_EMAIL_TYPE_SAMPLE,
    promise: client.delete(
      `${urlPrefix}email/types/${emailTypeId}/samples/${emailTypeSampleId}`,
    ),
  };
}
