import { Field } from 'react-final-form';
import { Map } from 'immutable';
import { Renderer, defaultBlocks } from '../../../lib/draftjs-editor';
import PropTypes from 'prop-types';
import ReduxFormsField from '../../forms/ReduxFormsField';

const draftRenderer = new Renderer(defaultBlocks);

const AdvanceNotesSection = ({ className, section }) => (
  <section className={className}>
    <header>
      <div className={`${className}--title`}>Notes</div>
    </header>

    <article>
      <div className={`${className}--desc`}>
        <div
          className="draftjs-output"
          dangerouslySetInnerHTML={{
            __html: draftRenderer.convertRawToHTML(
              section.get('content').toJS(),
            ),
          }}
        />
      </div>

      <Field
        name="credentialRequestsNotes"
        component={ReduxFormsField}
        placeholder="Notes"
      >
        <textarea />
      </Field>
    </article>
  </section>
);

AdvanceNotesSection.propTypes = {
  className: PropTypes.string.isRequired,
  section: PropTypes.instanceOf(Map).isRequired,
};

export default AdvanceNotesSection;
