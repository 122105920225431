module.exports = {
  artist: 'Microphone',
  food_vendor: 'Store',
  guestlist: 'Guestlist',
  intern: 'Intern',
  media: 'Camera',
  production: 'People',
  sponsor: 'Star',
  vendor: 'Truck',
};
