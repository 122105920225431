import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../common/icons/Icon';

const Input = styled.input`
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const EditCredentialQuantity = ({
  handleAddition,
  handleSubtraction,
  handleChange,
  value,
  hideMinusSign = false,
  hidePlusSign = false,
}) => (
  <div className="quantity-field">
    {hideMinusSign ? (
      <span className="quantity-button__spacer"></span>
    ) : (
      <button
        type="button"
        className="quantity-button"
        onClick={handleSubtraction}
      >
        <Icon icon="Minus" />
      </button>
    )}
    <Input
      type="number"
      value={value}
      onFocus={e => e.target.select()}
      onChange={handleChange}
    />
    {hidePlusSign ? (
      <span className="quantity-button__spacer"></span>
    ) : (
      <button
        type="button"
        className="quantity-button"
        onClick={handleAddition}
      >
        <Icon icon="Plus" />
      </button>
    )}
  </div>
);

EditCredentialQuantity.propTypes = {
  handleAddition: PropTypes.func.isRequired,
  handleSubtraction: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  hideMinusSign: PropTypes.bool,
  hidePlusSign: PropTypes.bool,
};

export default EditCredentialQuantity;
